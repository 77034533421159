import React, { PureComponent } from "react";
import { QuopinionTheme } from "../../../../constants/Theme";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import LogoBarParticipant from "../../../../common/Layout/LogoBarParticipant";
import CategoryQuestions from "./CategoryQuestions";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { translations } from "../../../../constants/lang/translation";
import Container from "../../../../common/Layout/Container";
import { RootState } from "../../../../modules";
import { connect } from "react-redux";
import {
  getIndividualAttributes,
  getUserAttributes,
  fetchDailyScreener,
} from "../../../../modules/attribute";
import { fetchPersonalData } from "../../../../modules/user";
import { Screener } from "../../../../entities/Screener";
import LoadingOverlay from "../../../../common/LoadingOverlay";

interface OwnProps {
  level: string;
  categoryTitle: string;
}

interface StateProps {
  screenerCollection: Screener[];
  isLoading: boolean;
  currentLevel: number | undefined;
}

interface DispatchProps {
  getIndividualAttributes: () => Promise<void>;
  getUserAttributes: () => Promise<void>;
  fetchPersonalData: () => Promise<void>;
  fetchDailyScreener: () => Promise<void>;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    attributeList: {
      paddingBottom: theme.spacing(4),
    },
  });

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps<{ category: string }>;

export const route: string = "/participant/attributes/:category";

class CategoryLevelOverview extends PureComponent<Props> {
  async componentDidMount() {
    if (!Object.entries(this.props.screenerCollection).length) {
      await this.props.fetchPersonalData();
      await this.props.getIndividualAttributes();
      await this.props.getUserAttributes();
      await this.props.fetchDailyScreener();
    }
  }

  render() {
    const { t, classes, screenerCollection, isLoading, match, currentLevel } = this.props;
    if (isLoading || Object.keys(this.props.screenerCollection).length === 0) {
      return <LoadingOverlay />;
    }

    return (
      <>
        <LogoBarParticipant hideLogo={true} backButton={true} />
        <Container centered={true}>
          <div className={classes.attributeList}>
            <CategoryQuestions
              level={`${t(translations.participant.screeners.level.title)} ${currentLevel}`}
              screenerObject={generateCategoryObject(screenerCollection, match.params.category)}
              category={match.params.category}
            />
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ attribute, user }: RootState) => ({
  screenerCollection: attribute.screenerCollection,
  isLoading: attribute.isLoading,
  currentLevel: user.currentLevel,
});

const mapDispatchToProps = {
  getIndividualAttributes,
  getUserAttributes,
  fetchPersonalData,
  fetchDailyScreener,
};

export default compose<Props, OwnProps>(
  withRouter,
  withStyles(styles),
  withTranslation(),
  connect<StateProps, any, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(CategoryLevelOverview);

export const generateCategoryObject = (
  currentLevelScreenerArray: any,
  selectedCategoryName: string
) => {
  return currentLevelScreenerArray[selectedCategoryName];
};
