import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { QuopinionTheme, blueGradient, primary } from "../../../constants/Theme";
import Slider from "@material-ui/core/Slider/";
import { compose } from "recompose";
import { SliderProps } from "@material-ui/core/Slider";

interface OwnProps extends SliderProps {
  marksVisible?: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    radioElement: {
      color: theme.palette.grey[100],
      marginRight: 14,
    },
    track: {
      height: 8,
      borderRadius: 4,
      backgroundColor: theme.palette.grey[100],
    },
    trackBefore: {
      background: blueGradient,
      height: 8,
      borderRadius: 4,
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(140, 140, 140, 0.3), 0 -2px 4px 0 rgba(140, 140, 140, 0.2)",
    },
    container: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(12),
      marginBottom: 0,
    },
    valueLabel: {
      left: "calc(-50%)",
      top: "-25px",
      "& span": {
        borderRadius: 4,
        transform: "none",
        background: blueGradient,
        paddingRight: theme.spacing(2.75),
        paddingLeft: theme.spacing(2.75),
        padding: theme.spacing(1.25),
        width: "auto",
        height: "auto",
        "& span": {
          background: "transparent",
          padding: 0,
        },
      },
      "&:after": {
        content: "' '",
        position: "absolute",
        bottom: "-9px",
        left: "calc(50% - 5px)",
        height: 10,
        width: 5,
        borderWidth: "3px 5px 0 5px",
        borderTopColor: theme.palette.primary.main,
        borderColor: "transparent",
        borderStyle: "solid",
      },
    },
    mark: {
      height: 8,
      backgroundColor: (props: OwnProps) => (props.marksVisible ? primary : "transparent"),
    },
    markActive: {
      backgroundColor: (props: OwnProps) => (props.marksVisible ? "#fff" : "transparent"),
    },
    markLabel: {
      color: "#6d7278",
      top: "65px",
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

const StepSlider: React.FunctionComponent<Props> = ({ classes, marksVisible, ...props }) => {
  return (
    <Slider
      {...props}
      classes={{
        rail: classes.track,
        track: classes.trackBefore,
        thumb: classes.thumb,
        root: classes.container,
        valueLabel: classes.valueLabel,
        mark: classes.mark,
        markLabel: classes.markLabel,
        markActive: classes.markActive,
      }}
    />
  );
};

export default compose<Props, OwnProps>(withStyles(styles))(StepSlider);
