import React, { PureComponent } from "react";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import { Grid, Typography } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";
import { compose } from "recompose";
import { WithStyles, createStyles, withStyles } from "@material-ui/styles";
import { QuopinionTheme } from "../../../constants/Theme";
import Button from "../../../common/Layout/components/Button";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RootState } from "../../../modules";
import { connect } from "react-redux";
import RegisterForm from "./RegisterForm";
import { route as ResearcherLoginRoute } from "../Login";
interface OwnProps {}

interface DispatchProps {}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(20)}px 0 ${theme.spacing(12)}px 8.33%`, //ToDo check with Verena if better consistent padding or individual here as 53px
      "@media (max-width: 767px)": {
        padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      },
    },
    headline: {
      marginBottom: theme.spacing(2),
    },
  });

type Props = OwnProps &
  DispatchProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps;

export const route: string = "/market-research/registration";
class ResearcherRegisterPage extends PureComponent<Props> {
  goToLogin = () => {
    const { history } = this.props;
    history.push(ResearcherLoginRoute);
  };

  render() {
    const { t, classes } = this.props;
    return (
      <>
        <LogoBarResearcher
          backButton={true}
          children={
            <Button contained={true} size="medium" color="primary" onClick={this.goToLogin}>
              {t(translations.action.login)}
            </Button>
          }
        />
        <ContentWrapper>
          <Grid item={true} sm={6} className={classes.root}>
            <Typography variant="h2" color="primary" className={classes.headline}>
              {t(translations.register.headline)}
            </Typography>
            <Typography variant="body1" className={classes.headline}>
              {t(translations.register.description)}
            </Typography>
            <RegisterForm />
          </Grid>
        </ContentWrapper>
      </>
    );
  }
}

const mapStateToProps = ({ session }: RootState) => ({});

export default compose<Props, OwnProps>(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect<{}, DispatchProps, OwnProps, RootState>(mapStateToProps)
)(ResearcherRegisterPage);
