import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Formik } from "formik";
import { WithStyles, createStyles } from "@material-ui/styles";
import { Typography, TextField, withStyles } from "@material-ui/core";
import * as Yup from "yup";

import { QuopinionTheme } from "../../../constants/Theme";
import { translations } from "../../../constants/lang/translation";
import Researcher from "../../../entities/Researcher";
import { RootState } from "../../../modules";
import { compose } from "recompose";
import { connect } from "react-redux";
import Button from "../../../common/Layout/components/Button";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { denyReleaseOfSurvey } from "../../../modules/survey";
import ID from "../../../entities/ID";
import { route as ResearcherDashboardRoute } from "./index";
import { RouteComponentProps, withRouter } from "react-router";

export const route = "/market-research/billing-data";

interface OwnProps {
  isPublishing: boolean;
}

interface DispatchProps {
  denyReleaseOfSurvey: (id: ID, reason: string) => Promise<void>;
}

interface StateProps {
  currentResearcher: Researcher;
  isLoading: boolean;
  surveyId: ID;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    input: {
      width: "100%",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(13),
    },
    buttonLine: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
    },
    headline: {
      textTransform: "uppercase",
    },
  });

interface State {
  error: boolean;
  success: boolean;
}

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{}>;

class DenySurvey extends PureComponent<Props, State> {
  state: State = {
    error: false,
    success: false,
  };
  initialValues = {
    reason: "",
  };

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      reason: Yup.string()
        .trim()
        .min(1, t(translations.general.requiredLength))
        .required(t(translations.general.requieredInput)),
    });
  };

  handleDenySurveyClick = (reason: string) => {
    const { denyReleaseOfSurvey, surveyId } = this.props;
    denyReleaseOfSurvey(surveyId, reason)
      .then(() => {
        this.props.history.push(ResearcherDashboardRoute);
        this.setState({
          error: false,
        });
      })
      .catch((err: any) => {
        this.setState({
          error: true,
        });
      });
  };

  render() {
    const { classes, t, isLoading, isPublishing } = this.props;
    if (isLoading) {
      return <LoadingOverlay />;
    }

    return (
      <div>
        <Formik
          initialValues={this.initialValues}
          validationSchema={this.validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            this.handleDenySurveyClick(values.reason);
            setSubmitting(false);
          }}
        >
          {(formikProps) => {
            const {
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
              isSubmitting,
            } = formikProps;

            return (
              <form onSubmit={handleSubmit}>
                <Typography variant="body2" className={classes.headline}>
                  {t(translations.backOffice.denyReason)}
                </Typography>
                {/*Deny Reason*/}
                <TextField
                  name={"reason"}
                  required={false}
                  className={classes.input}
                  placeholder={"Ablehnungsgrund"}
                  value={values.reason}
                  onChange={handleChange("reason")}
                  onBlur={handleBlur("reason")}
                  error={touched.reason && Boolean(errors.reason)}
                  helperText={errors.reason && touched.reason ? `${errors.reason}` : ""}
                  multiline={true}
                />

                <div className={classes.buttonLine}>
                  <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting || isPublishing}
                    color={"quarternary"}
                    size="big"
                    contained={true}
                    type="submit"
                  >
                    {t(translations.action.denySurvey)}
                  </Button>
                  {this.state.error && (
                    <Typography> {t(translations.general.anErrorOccured)}</Typography>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = ({ user, survey }: RootState) => ({
  currentResearcher: user.currentResearcher,
  isLoading: user.isLoading,
  surveyId: survey.id,
});

const mapDispatchToProps = {
  denyReleaseOfSurvey,
};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect<{}, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withRouter
)(DenySurvey);
