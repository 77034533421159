import React, { PureComponent, ReactNode } from "react";
import { Typography, Grid, createStyles } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/styles";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";
import ContentWrapper from "../../../common/Layout/ContentWrapper";

interface IntroductionProps {
  title: string;
  description: string;
  onParticipantDashboard?: boolean;
  // display image or specific information about current page
  customElement?: ReactNode;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      backgroundColor: (props: IntroductionProps) =>
        props.onParticipantDashboard ? "transparent" : "#fff",
      borderBottom: (props: IntroductionProps) =>
        props.onParticipantDashboard ? `1px solid ${theme.palette.grey[100]}` : "none",
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(12),
    },
    textArea: {
      paddingRight: theme.spacing(27.75),
      paddingLeft: "8.33%",
      "@media(max-width: 960px)": {
        paddingRight: theme.spacing(4),
      },
    },
    headline: {
      marginBottom: theme.spacing(8),
    },
    detailedFacts: {
      "@media(max-width: 960px)": {
        paddingLeft: "8.33%",
      },
    },
    email: {
      "& a": {
        color: "inherit",
      },
    },
  });

type Props = IntroductionProps & WithStyles<typeof styles>;
class Introduction extends PureComponent<Props> {
  render() {
    const { title, description, classes, onParticipantDashboard, customElement } = this.props;
    return (
      <Grid container={true} direction="column" className={classes.root} justifyContent="center">
        <ContentWrapper>
          <Grid item={true} xs={12} sm={8} className={classes.textArea}>
            <Typography variant={onParticipantDashboard ? "h1" : "h3"} className={classes.headline}>
              {title}
            </Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: description }}
              className={classes.email}
            />
          </Grid>
          <Grid item={true} xs={12} md={4} className={classes.detailedFacts}>
            {customElement}
          </Grid>
        </ContentWrapper>
      </Grid>
    );
  }
}

export default compose<Props, IntroductionProps>(withStyles(styles))(Introduction);
