import ID from "./ID";

/**
 * Domain entity class Media
 * which represents a media file that can be used e.g. in Question or AnswerOption
 */
export default class Media {
  readonly id: ID;
  readonly mimeType: string;
  readonly url: string;
  readonly length: number;

  constructor(obj: any) {
    this.id = obj.backReference || obj.id;
    this.mimeType = obj.mimeType;
    this.url = obj.url;
    this.length = obj.length;
  }
}

export interface MediaReference {
  questionId?: ID;
  answerId?: ID;
}
