import React, { PureComponent, ReactNode } from "react";
import { createStyles, withStyles, WithStyles, Typography } from "@material-ui/core";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../constants/Theme";
import Button from "./Button";

interface OwnProps {
  headline: string;
  description: ReactNode;
  buttonFunction: () => void;
  buttonTitle: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    noSurveyCard: {
      borderRadius: 10,
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(6),
      margin: `${theme.spacing(15)}px 0`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
    },
    description: {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(6)}px`,
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class NotAvailableCard extends PureComponent<Props> {
  render() {
    const { headline, description, buttonFunction, buttonTitle, classes } = this.props;
    return (
      <div className={classes.noSurveyCard}>
        <Typography variant="h3" color="primary">
          {headline}
        </Typography>
        <Typography variant="body1" color="primary" className={classes.description}>
          {description}
        </Typography>
        <Button color="primary" white={true} size="medium" onClick={buttonFunction}>
          {buttonTitle}
        </Button>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(NotAvailableCard);
