import React, { PureComponent } from "react";
import { QuopinionTheme } from "../../../constants/Theme";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    container: {
      position: "relative",
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(5)}px 0px`,
      maxWidth: 320,
    },
    video: {
      width: "100%",
      maxHeight: 70,
      borderRadius: 4,
    },
    play: {
      position: "absolute",
      height: 48,
      width: 48,
      left: "calc(50% - 24px)",
      top: "calc(50% - 24px)",
      zIndex: 1,
      padding: `${theme.spacing(0)}px ${theme.spacing(1)}px ${theme.spacing(1.5)}px ${theme.spacing(
        2
      )}px`,
    },
  });
interface State {
  videoPlaying: boolean;
  videoLoaded: boolean;
}
interface OwnProps {
  video: string;
  type: string;
}

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class VideoPlayer extends PureComponent<Props, State> {
  video: React.RefObject<HTMLVideoElement>;

  constructor(props: Props) {
    super(props);
    this.video = React.createRef();
    this.state = {
      videoPlaying: false,
      videoLoaded: false,
    };
  }

  componentDidMount() {
    this.video.current !== null &&
      this.video.current!.addEventListener("loadeddata", this.onVideoLoaded);
  }

  playVideo = () => {
    if (this.video.current) {
      this.video.current.play();
      this.video.current.requestFullscreen();
      this.setState({
        videoPlaying: true,
      });
    }
  };

  onVideoLoaded = () => {
    if (this.video.current && this.video.current.readyState >= 2) {
      this.setState({
        videoLoaded: true,
      });
    }
  };

  render() {
    const { video, classes, type, t } = this.props;
    return (
      <div className={classes.container}>
        <video ref={this.video} src={video} controls className={classes.video}>
          <source src={video} type={type} />
          <div>{t(translations.questionnaire.videoError)}</div>
        </video>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(VideoPlayer);
