/**
 * Domain entity class SurveyMetaData
 * which represents a calculated Questionnaire Price
 */

export default class SurveyMetaData {
  errors: any;
  constructor(obj: any = {}) {
    this.errors = obj.errors || null;
  }
}
