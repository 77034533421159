import React, {CSSProperties, PureComponent, ReactNode} from "react";
import { createStyles, WithStyles, withStyles, Typography } from "@material-ui/core";
import theme, { green, QuopinionTheme, red } from "../../../constants/Theme";
import { compose } from "recompose";

const colorMap: { [priority: string]: string } = {
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
  tertiary: "#fff",
  quarternary: red,
  black: "#313131",
  green,
};

const setBackgroundColor = (props: ButtonProps) => {
  if (props.contained) {
    if (props.disabled) {
      return theme.palette.grey[100];
    } else {
      return colorMap[props.color];
    }
  } else if (props.disabled) {
    return theme.palette.grey[100];
  } else if (props.white) {
    return colorMap.tertiary;
  } else {
    return "transparent";
  }
};

const setColor = (props: ButtonProps) => {
  if (props.contained) {
    return colorMap[props.color];
  } else {
    return colorMap[props.color];
  }
};

interface ButtonProps {
  color: string;
  // if contained = true passed color will be background color
  // if contained = false passed color will be font and text-decoration color
  contained?: boolean;
  underline?: boolean;
  outlined?: boolean;
  title?: string;
  size: "big" | "medium" | "small";
  onClick?: (arg?: any) => void;
  disabled?: boolean;
  children?: ReactNode;
  fullWidth?: boolean;
  icon?: ReactNode;
  white?: boolean;
  className?: any;
  type?: "submit" | "button" | "reset";
  style?: CSSProperties;
  demo?: boolean;
  id?: string;
  column?: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      outline: "none",
      alignItems: "center",
      backgroundColor: (props: ButtonProps) => setBackgroundColor(props),
      borderRadius: 4,
      color: (props: ButtonProps) => setColor(props),
      cursor: "pointer",
      display: "flex",
      height: "fit-content",
      justifyContent: "center",
      width: (props: ButtonProps) => (props.fullWidth ? "100%" : "fit-content"),
      padding: (props: ButtonProps) =>
        props.size === "big"
          ? `${theme.spacing(4)}px ${theme.spacing(12)}px`
          : props.size === "medium"
          ? `${theme.spacing(3)}px ${theme.spacing(4)}px`
          : props.icon
          ? `${theme.spacing(2)}px ${theme.spacing(2.5)}px`
          : `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      pointerEvents: (props: ButtonProps) => (props.disabled ? "none" : "all"),
      userSelect: "none",
      border: (props: ButtonProps) =>
        (props.contained || props.outlined) && !props.disabled
          ? `solid 1px ${setColor(props)}`
          : "none",
    },
    buttonText: {
      textDecoration: (props: ButtonProps) => {
        if (props.underline) {
          return "underline";
        }
        if (props.demo) {
          return "line-through";
        }
        return "none";
      },
      textDecorationColor: (props: ButtonProps) => colorMap[props.color],
      color: (props: ButtonProps) => (props.contained ? colorMap.tertiary : colorMap[props.color]),
      display: "flex",
      alignItems: "center",
      fontSize: (props: ButtonProps) => (props.size === "big" ? "14px" : "11px"),
      fontWeight: 600,
    },
    buttonIcon: {
      marginRight: (props: ButtonProps) => (props.children ? theme.spacing(2.5) : 0),
      lineHeight: "11px",
    },
    composedButtonIcon: {
      marginRight: (props: ButtonProps) => (props.children ? theme.spacing(2.5) : 0),
      lineHeight: "11px",
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
    },
  });

type Props = ButtonProps & WithStyles<typeof styles>;

class Button extends PureComponent<Props> {
  render() {
    const { classes, children, icon, onClick, className, style, type, id, column } = this.props;
    const classNames = `${classes.root} ${className}`;

    return (
      <button
        className={classNames}
        onClick={
          onClick
            ? (arg) => onClick(arg)
            : () => {
                return;
              }
        }
        style={style || {}}
        type={type}
      >
        {icon && (
          <div className={column ? classes.composedButtonIcon : classes.buttonIcon}>{icon}</div>
        )}
        <Typography color="inherit" variant="button" className={classes.buttonText} id={id}>
          {children}
        </Typography>
      </button>
    );
  }
}

export const ButtonComponent = Button;

export default compose<Props, ButtonProps>(withStyles(styles))(Button);
