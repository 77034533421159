import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, withStyles, WithStyles, Grid } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";

import { QuopinionTheme } from "../../../constants/Theme";

interface OwnProps {
  imageNameSmall: string;
  imageNameMedium: string;
  imageNameBig: string;
}
interface State {
  imageIndex: number;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(12)}px`,
      display: "flex",
      justifyContent: "center",
      "@media(min-width: 960px)": {
        display: "unset",
        justifyContent: "unset",
      },
      "@media (min-width: 1024px)": {
        padding: `${theme.spacing(14)}px ${theme.spacing(0)}px ${theme.spacing(
          10
        )}px ${theme.spacing(0)}px`,
      },
    },
    description: {
      color: theme.palette.grey[400],
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(6)}px`,
      "@media (min-width: 600px)": {
        margin: `${theme.spacing(6)}px 0 ${theme.spacing(6)}px`,
      },
      maxWidth: theme.spacing(95),
      height: theme.spacing(31.25),
    },
    headline: {
      fontSize: "40px",
      fontWeight: 600,
      lineHeight: 1.1,
      letterSpacing: "normal",
      fontStretch: "normal",
      maxWidth: theme.spacing(106.25),
    },
    image: {
      maxHeight: 532,
      maxWidth: 662,
      width: 314,
      "@media(min-width:450px)": {
        width: theme.spacing(112.5),
      },
      "@media(min-width:630px)": {
        width: "unset",
      },
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class CarouselPictureItem extends PureComponent<Props, State> {
  render() {
    const { classes, imageNameSmall, imageNameMedium, imageNameBig } = this.props;
    return (
      <Grid item={true} xs={12} sm={7} md={6} className={classes.root}>
        <picture>
          <source media="(min-width: 1024px)" srcSet={imageNameBig} />
          <source media="(min-width: 768px)" srcSet={imageNameMedium} />
          <img className={classes.image} src={imageNameSmall} alt="" />
        </picture>
      </Grid>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(CarouselPictureItem);
