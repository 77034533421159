import { QuopinionTheme } from "../../../constants/Theme";
import { Typography, styled } from "@material-ui/core";

const CategoryTitle = styled(Typography)(({ theme }: { theme: QuopinionTheme }) => ({
  fontSize: 16,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  textTransform: "capitalize",
}));

export default CategoryTitle;
