import React, { PureComponent, ChangeEvent } from "react";
import { WithStyles, createStyles, withStyles } from "@material-ui/styles";
import { QuopinionTheme } from "../../../constants/Theme";
import { InputAdornment, Typography, TextField } from "@material-ui/core";
import Tooltip from "./Tooltip";
import { translations } from "../../../constants/lang/translation";
import { WithTranslation, withTranslation } from "react-i18next";
import MediaUpload from "./MediaUpload";
import { compose } from "recompose";
import Media, { MediaReference } from "../../../entities/Media";

interface OwnProps {
  id: string;
  disabled?: boolean;
  value: string;
  placeholder: string;
  onBlur: (event: any) => void;
  onMediaUploaded: (media: Media) => void;
  onMediaUploadFailed: (reason: string) => void;
  required?: boolean;
  className?: any;
  mediaReference: MediaReference;
  uploadError: string | null;
  preventMediaUpload?: boolean;
  multiline: boolean;
}

interface State {
  mediaTooltipOpen: boolean;
  value: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    questionInput: {
      flexGrow: 1,
      fontSize: "14px",
      "& div": {
        borderColor: "#fff",
        padding: theme.spacing(3.75),
      },
    },
    tooltipIcon: {
      padding: "0px !important",
      "&:focus": {
        outline: "none",
      },
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

class TextFieldWithMediaUpload extends PureComponent<Props, State> {
  state: State = {
    mediaTooltipOpen: false,
    value: this.props.value,
  };
  toggleMediaTooltip = () => {
    this.setState({
      mediaTooltipOpen: !this.state.mediaTooltipOpen,
    });
  };

  hideMediaTooltip = () => {
    this.setState({
      mediaTooltipOpen: false,
    });
  };

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      value: event.target.value,
    });
  };

  showMediaTooltip = () => {
    this.setState({
      mediaTooltipOpen: true,
    });
  };

  render() {
    const {
      disabled,
      placeholder,
      required,
      classes,
      t,
      className,
      onBlur,
      onMediaUploaded,
      mediaReference,
      onMediaUploadFailed,
      uploadError,
      preventMediaUpload,
      id,
      multiline,
    } = this.props;

    const { value: localValue } = this.state;

    return (
      <TextField
        id={id}
        disabled={disabled}
        value={localValue}
        placeholder={placeholder}
        onChange={this.handleInputChange}
        onBlur={(event) => {
          onBlur(event);
        }}
        required={required}
        classes={{ root: className ? className : classes.questionInput }}
        multiline={multiline}
        InputProps={
          preventMediaUpload
            ? {}
            : {
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      open={this.state.mediaTooltipOpen}
                      title={
                        <>
                          <Typography>{t(translations.questionnaire.mediaUpload)}</Typography>
                          {uploadError !== null && (
                            <Typography color="error">{uploadError}</Typography>
                          )}
                        </>
                      }
                      placement="bottom"
                      children={
                        <div
                          className={classes.tooltipIcon}
                          onClick={this.toggleMediaTooltip}
                          onMouseEnter={this.showMediaTooltip}
                          onMouseLeave={this.hideMediaTooltip}
                        >
                          <MediaUpload
                            onMediaUploadFailed={onMediaUploadFailed}
                            onMediaUploaded={onMediaUploaded}
                            reference={mediaReference}
                          />
                        </div>
                      }
                    />
                  </InputAdornment>
                ),
              }
        }
      />
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(TextFieldWithMediaUpload);
