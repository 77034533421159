import ID from "./ID";
import { Gender } from "./Panel";
import { UsableForQuotasStudies } from "../modules/session";

export interface PersonalData {
  firstName: string;
  lastName: string;
  street?: string;
  streetNumber?: string;
  zipCode: string;
  city: string;
  countryCode: string;
  birthdayYear: number;
  birthdayMonth: number;
  birthdayDayOfMonth: number;
  usableForQuotasStudies?: UsableForQuotasStudies;
}

export interface CreditData {
  credit: number;
  credits: CreditEntry[];
}

export class CreditEntry {
  readonly accountId: string;
  readonly amount: number;
  readonly createdAt: Date;
  readonly currency: string;
  readonly id: string;
  readonly status: string;
  readonly surveyId: string;
  readonly reason: string;
  readonly type: "TRANSFER" | "SURVEY_COMPLETION" | "PAYOUT";

  constructor(obj: any) {
    this.accountId = obj.accountId;
    this.amount = obj.amount;
    this.createdAt = new Date(obj.createdAt);
    this.currency = obj.currency;
    this.id = obj.id;
    this.status = obj.status;
    this.surveyId = obj.surveyId;
    this.reason = obj.reason;
    this.type = obj.type;
  }
}

interface RoleInOrganization {
  type: string;
  organizationId: ID;
}

export enum UserRole {
  RESEARCHER = "researcher",
  PARTICIPANT = "participant",
  ADMIN = "admin",
}

/**
 * Domain entity class User
 * which represents a user account and its attributes
 */
export default class User {
  readonly id: ID;
  salutation: { label: string; value: string };
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  password: string;
  gender?: Gender;
  newsLetterSubscription?: boolean;
  roles?: any;

  constructor(obj: any) {
    this.id = obj.id;
    this.salutation = obj.salutation;
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.email = obj.emailAddress;
    this.phone = obj.phone;
    this.password = obj.password;
    this.gender = obj.gender;
    this.roles =
      obj.roles &&
      obj.roles.map((role: RoleInOrganization) => ({
        type: role.type,
        organizationId: role.organizationId,
      }));
  }

  isAdmin(): boolean {
    return this.roles
      ? Boolean(this.roles.find((userRole: RoleInOrganization) => userRole.type === UserRole.ADMIN))
      : false;
  }
}
