import React, { ChangeEvent, PureComponent } from "react";
import { TextField, Typography, createStyles, WithStyles, withStyles } from "@material-ui/core";
import * as Yup from "yup";
import { translations } from "../../../../../constants/lang/translation";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";
import { QuestionTypeProps, QuestionProps } from "./Question";
import Question from "../../../../../entities/Question";
import QuestionPreview from "../../../../../common/Preview/QuestionPreview";
import { QuopinionTheme } from "../../../../../constants/Theme";
import { connect } from "react-redux";
import Researcher from "../../../../../entities/Researcher";
import { RootState } from "../../../../../modules";

const CHARACTER_LIMIT = 500;
const CHARACTER_LIMIT_URL = 1000;

const styles = (theme: QuopinionTheme) =>
  createStyles({
    greyText: {
      color: theme.palette.grey[400],
    },
  });

export const QuestionValidationInstruction = ({ t }: QuestionProps) => {
  return Yup.object({
    body: Yup.object().shape({
      text: Yup.string()
        .required(t(translations.questionnaire.validation.instruction.requirement))
        .max(CHARACTER_LIMIT, t(translations.questionnaire.validation.instruction.maxLength)),
      link: Yup.string()
        .url()
        .max(CHARACTER_LIMIT_URL, t(translations.questionnaire.validation.instruction.url)),
    }),
  });
};

interface State {
  value: string;
  link: string;
}

interface StateProps {
  currentResearcher: Researcher;
}

type Props = QuestionTypeProps & StateProps & WithStyles<typeof styles> & WithTranslation;
class QuestionTypeInstructionCore extends PureComponent<Props, State> {
  state = {
    value: this.props.body.text || "",
    link: this.props.body.link || "",
  };

  handleInputBlur = () => {
    this.props.handleChange({
      ...this.props.body,
      text: this.state.value,
    });
  };

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      value: event.target.value,
    });
  };

  handleLinkInputBlur = () => {
    this.props.handleChange({
      ...this.props.body,
      link: this.state.link,
    });
  };

  handleLinkInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      link: event.target.value,
    });
  };

  render() {
    const { t, classes, currentResearcher } = this.props;
    return (
      <div>
        <TextField
          id="standard-name"
          value={this.state.value}
          placeholder={t(translations.questionnaire.placeholder.instructionTextField)}
          onChange={this.handleInputChange}
          onBlur={this.handleInputBlur}
          margin="normal"
          fullWidth={true}
          multiline={true}
          rows={5}
          required={true}
          helperText={
            <Typography variant="caption" className={classes.greyText}>
              {this.state.value.length}/{CHARACTER_LIMIT}{" "}
              {t(translations.questionnaire.validation.characters)}
            </Typography>
          }
          inputProps={{ maxLength: CHARACTER_LIMIT }}
        />
        {currentResearcher.isAdmin() && (
          <TextField
            id="link"
            value={this.state.link}
            placeholder={t(translations.questionnaire.placeholder.instructionTextField)} //change
            onChange={this.handleLinkInputChange}
            onBlur={this.handleLinkInputBlur}
            margin="normal"
            fullWidth={true}
            helperText={
              <Typography variant="caption" className={classes.greyText}>
                {t(translations.questionnaire.validation.instruction.url)}
              </Typography>
            }
            inputProps={{ maxLength: CHARACTER_LIMIT }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user }: RootState) => ({
  currentResearcher: user.currentResearcher,
});

export const QuestionTypeInstruction = compose<Props, QuestionTypeProps>(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(QuestionTypeInstructionCore);

export class QuestionPreviewInstruction extends PureComponent<Omit<Question, "toDataJson">> {
  render() {
    const { body, text: title, type, media } = this.props;
    return (
      <QuestionPreview
        title={title}
        body={body}
        type={type}
        media={media}
        questionNumber={1}
        totalQuestionNumber={1}
      />
    );
  }
}
