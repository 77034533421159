import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, withStyles, WithStyles, Grid, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";

import { QuopinionTheme } from "../../constants/Theme";
import { ButtonChild } from "./ButtonChild";

interface OwnProps {
  title: string;
  description: React.ReactNode;
  backgroundColor: string;
  buttonText: string;
  buttonLink: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
      "@media(max-width: 599px)": {
        display: "flex",
        flexDirection: "column",
        order: 1,
        zIndex: 1,
      },
      "@media(min-width:600px)": {
        height: "unset",
        padding: `${theme.spacing(8)}px ${theme.spacing(0)}px ${theme.spacing(
          20
        )}px ${theme.spacing(0)}px`,
        zIndex: 0,
      },
      "@media(min-width: 990px)": {
        paddingLeft: theme.spacing(10),
      },
      "@media (min-width: 1144px)": {
        paddingLeft: theme.spacing(27.5),
      },
    },
    description: {
      color: theme.palette.grey[400],
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(6)}px`,
      "@media (min-width: 600px)": {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(6)}px`,
      },
      maxWidth: theme.spacing(95),
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.71,
    },
    wrapper: {
      backgroundColor: (props: OwnProps) => props.backgroundColor,
      position: "relative",
    },

    headline: {
      fontFamily: "catamaranBold",
      fontSize: "32px",
      fontWeight: 200,
      lineHeight: 1.25,
      textAlign: "start",
      maxWidth: theme.spacing(100),
      "@media(min-width: 600px)": {
        textAlign: "end",
      },
      "@media(min-width: 1024px)": {
        fontSize: "40px",
        fontWeight: 200,
        lineHeight: 1.1,
        maxWidth: theme.spacing(102.5),
      },
      letterSpacing: "normal",
    },
    childItem: {
      position: "relative",
      marginBottom: 0,
      display: "flex",
      "@media(max-width: 599px)": {
        order: 2,
        marginBottom: theme.spacing(12),
      },
      "@media(min-width: 600px)": {
        justifyContent: "baseline",
      },
    },
    itemWrapper: {
      "@media (min-width: 600px)": {
        marginRight: theme.spacing(10),
        marginLeft: theme.spacing(10),
      },
      "@media (min-width:785px)": {
        marginRight: theme.spacing(30),
        marginLeft: "unset",
      },
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class LandingpagePictureTextItem extends PureComponent<Props> {
  render() {
    const { title, description, classes, children, buttonText, buttonLink } = this.props;

    return (
      <Grid container={true} className={classes.wrapper}>
        {/*child left element*/}
        <Grid item={true} xs={12} sm={6} className={classes.childItem}>
          {children && children}
        </Grid>
        {/*right side text*/}
        <Grid item={true} xs={12} sm={6} md={6} className={classes.root}>
          <div className={classes.itemWrapper}>
            <Typography className={classes.headline} color="primary">
              {title}
            </Typography>
            <Typography variant="body1" color="inherit" className={classes.description}>
              {description}
            </Typography>
            <ButtonChild buttonText={buttonText} buttonLink={buttonLink} />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(LandingpagePictureTextItem);
