import * as React from "react";
import {Grid, IconButton} from "@material-ui/core";
import {blueGradient, QuopinionTheme} from "../../../constants/Theme";
import {createStyles, WithStyles, withStyles} from "@material-ui/core/styles";

import {compose} from "recompose";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router";

import {connect} from "react-redux";
import {RootState} from "../../../modules";
import {completeParticipation, getParticipation} from "../../../modules/participation";
import {removeError} from "../../../modules/uiState";

import {route as dashboardRoute} from "../Dashboard";
import Question, {QuestionCondition, QuestionTypes} from "../../../entities/Question";
import {ArrowBack, Cross} from "../../../assets/icon";
import DialogComponent from "../../../common/Layout/components/Dialog";
import {translations} from "../../../constants/lang/translation";
import Questionnaire from "../../../entities/Questionnaire";
import FinishedQuestionnaire from "./finishedQuestionnaire";
import RejectedQuestionnaire from "./rejectedQuestionnaire";
import QuestionContainer from "./QuestionContainer";
import ID from "../../../entities/ID";
import LoadingOverlay from "../../../common/LoadingOverlay";
import {SnackbarComponent} from "../../../common/Layout/components/Snackbar";
import SurveyAnswers, {ChoiceAnswer} from "../../../entities/SurveyAnswers";
import AnswerOption from "../../../entities/AnswerOption";
import Survey from "../../../entities/Survey";
import {ParticipationSurveyState} from "../../../services/Survey";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    offlineSendingError: {
      marginTop: 50,
    },
    stepButton: {
      color: "#fff",
      margin: "50px 0px 50px 0px",
      width: "100%",
    },
    closeIcon: {
      position: "fixed",
      top: 31,
      right: 16,
      padding: theme.spacing(1),
      width: 40,
      height: 40,
      cursor: "pointer",
      zIndex: 999,
    },
    backIcon: {
      position: "fixed",
      top: 31,
      left: 4,
      padding: 0,
      width: 40,
      height: 40,
      cursor: "pointer",
      zIndex: 999,
    },
    navigation: {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(-6),
    },
    finalScreen: {
      background: blueGradient,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(4),
    },
    finalText: {
      margin: `${theme.spacing(4)}px 0`,
    },
  });

interface OwnProps {}

interface StateProps {
  questionnaire: Questionnaire;
  acceptedSurveys: any[];
  isOffline: boolean;
  expiredAccess: boolean;
  sent: boolean;
  isLoading: boolean;
  showError: boolean;
  surveyAnswers: SurveyAnswers;
  survey: Survey;
  participationState: ParticipationSurveyState;
}

interface State {
  dialogOpen: boolean;
  skipToQuestionId?: ID;
  completedParticipationRequestSend: boolean;
  questionsWithUnmetConditionsCount: number;
  finalQuestionnaire?: Questionnaire;
}

interface DispatchProps {
  removeError: typeof removeError;
  getParticipation: typeof getParticipation;
  completeParticipation: typeof completeParticipation;
}

type Props = OwnProps &
  StateProps &
  WithTranslation &
  DispatchProps &
  WithStyles<typeof styles> &
  RouteComponentProps<{ id: ID; questionID: ID }>;

export const route: string = "/participant/questionnaire";

class QuestionnairePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {getParticipation, match} = this.props;
    getParticipation(match.params.id);
    this.state = {
      dialogOpen: false,
      completedParticipationRequestSend: false,
      questionsWithUnmetConditionsCount: 0,
      finalQuestionnaire: undefined,
    };
  }

  shuffle = (array: AnswerOption[]): AnswerOption[] => {
    const arrayToReturn = array.slice(0);
    for (let i = arrayToReturn.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arrayToReturn[i], arrayToReturn[j]] = [arrayToReturn[j], arrayToReturn[i]];
    }
    return arrayToReturn;
  };

  randomize = (questionnaire: Questionnaire) => {
    questionnaire.questions.map((question: Question) => {
      if (question.body.randomizeAnswerOptions) {
        question.body.answerOptions = this.shuffle(question.body.answerOptions);
      }
      return question;
    });
    return questionnaire;
  };

  componentDidUpdate = () => {
    const {match, survey, questionnaire, completeParticipation} = this.props;

    if (!this.state.completedParticipationRequestSend) {
      if (questionnaire && this.getCurrentQuestionIndex() === questionnaire.questions.length) {
        this.setState(
          {
            completedParticipationRequestSend: true,
          },
          () => completeParticipation(match.params.id)
        );
      }
    }

    if (survey && this.props.match.params.id === survey.id) {
      if (questionnaire && !match.params.questionID) {
        this.props.history.push(
          `/participant/questionnaire/${match.params.id}/${questionnaire.questions[0].id}`
        );
      }
    }

    if (questionnaire && questionnaire.questions) {
      if (this.state.finalQuestionnaire) {
        if (questionnaire.id !== this.state.finalQuestionnaire.id) {
          this.setState({
            finalQuestionnaire: this.randomize(questionnaire),
          });
        }
      } else {
        this.setState({
          finalQuestionnaire: this.randomize(questionnaire),
        });
      }
    }
  };

  getQuestionnaire = () => {
    const { finalQuestionnaire } = this.state;

    return finalQuestionnaire;
  };

  getCurrentQuestionIndex = () => {
    const { match, questionnaire } = this.props;
    if (match.params.questionID === "end") {
      // questionnaire is finished, so we return the largest index + 1
      return this.props.questionnaire.questions.length;
    }

    return questionnaire.questions.findIndex((question) => question.id === match.params.questionID);
  };

  setSkipQuestionId = (id: ID | undefined) => {
    this.setState({
      skipToQuestionId: id,
    });
  };

  resetSkipQuestionId = () => {
    this.setState({
      skipToQuestionId: undefined,
    });
  };

  routeToNextQuestion = (nextQuestion: Question) => {
    this.props.history.push(
      `/participant/questionnaire/${this.props.match.params.id}/${nextQuestion.id}`
    );
  };

  routeToCompletedPage = () => {
    this.props.history.push(`/participant/questionnaire/${this.props.match.params.id}/end`);
  };

  getNextQuestion = () => {
    return this.props.questionnaire.questions[
    this.getCurrentQuestionIndex() + 1 + this.state.questionsWithUnmetConditionsCount
      ];
  };

  goToNextSide = () => {
    //finding next question via unmetCount when next question needs to be skipped as its condition is not met
    const nextQuestion = this.getNextQuestion();
    if (nextQuestion !== undefined) {
      this.goToNextQuestion(nextQuestion);
    } else {
      this.routeToCompletedPage();
    }
  };

  checkForFullfilledConditions = (nextQuestion: Question) => {
    let convertedFullfilledConditions: ID[] = [];

    const fullfilledConditions = nextQuestion.conditions.map((conditionItem: QuestionCondition) => {
      const answersCorrelatingByQuestions = this.props.surveyAnswers.answers.find(
        (givenAnswer) => givenAnswer.questionID === conditionItem.questionId
      );
      return conditionItem.answers.filter(
        (answerId) =>
          (answersCorrelatingByQuestions as ChoiceAnswer) &&
          (answersCorrelatingByQuestions as ChoiceAnswer).selectedOptions.includes(answerId)
      );
    });

    fullfilledConditions?.map(
      (arrItem) =>
        (convertedFullfilledConditions = [...convertedFullfilledConditions, ...arrItem])
    );
    return convertedFullfilledConditions;
  };

  continueNextQuestionSearch = () => {
    this.setState(
      {
        questionsWithUnmetConditionsCount: this.state.questionsWithUnmetConditionsCount + 1,
      },
      () => this.goToNextSide()
    );
  };

  successfullyRouteToNextQuestion = (nextQuestion: Question) => {
    this.setState({
      questionsWithUnmetConditionsCount: 0,
    });
    if (
      (nextQuestion &&
        this.props.questionnaire.questions.findIndex(
          (question) => question.id === nextQuestion.id
        ) === this.props.questionnaire.questions.length) ||
      nextQuestion === undefined
    ) {
      this.routeToCompletedPage();
    } else {
      this.routeToNextQuestion(nextQuestion);
    }
  };

  goToNextQuestion = async (nextQ?: Question) => {
    const { skipToQuestionId } = this.state;
    const currentQuestionIndex = this.getCurrentQuestionIndex();

    if (currentQuestionIndex === this.props.questionnaire.questions.length - 1) {
      this.routeToCompletedPage();
    } else {
      if (skipToQuestionId && skipToQuestionId !== this.props.match.params.questionID) {
        if (skipToQuestionId === "end") {
          this.routeToCompletedPage();
        } else {
          if (
            currentQuestionIndex !== 0 &&
            this.props.questionnaire.questions[currentQuestionIndex].type ===
            QuestionTypes.INSTRUCTION &&
            this.props.questionnaire.questions[this.getCurrentQuestionIndex() - 1].type ===
              QuestionTypes.INSTRUCTION
          ) {
            this.resetSkipQuestionId();
            this.setAndRouteToNextQuestion(nextQ);
          } else {
            const index = this.props.questionnaire.questions.findIndex(
              (question) => question.id === skipToQuestionId
            );
            const nextQuestion = this.props.questionnaire.questions[index];
            this.routeToNextQuestion(nextQuestion);
          }
        }
      } else {
        this.setAndRouteToNextQuestion(nextQ);
      }
    }
  };

  setAndRouteToNextQuestion = (nextQ?: Question) => {
    const nextQuestion = nextQ ? nextQ : this.getNextQuestion();
    if (
      nextQuestion === undefined ||
      (nextQuestion &&
        this.props.questionnaire.questions.findIndex(
          (question) => question.id === nextQuestion.id
        ) === this.props.questionnaire.questions.length)
    ) {
      this.routeToCompletedPage();
    } else if (nextQuestion.conditions.length) {
      this.checkForFullfilledConditions(nextQuestion).length > 0
        ? this.successfullyRouteToNextQuestion(nextQuestion)
        : this.continueNextQuestionSearch();
    } else {
      this.successfullyRouteToNextQuestion(nextQuestion);
    }
  };

  goToPreviousQuestion = () => {
    if (this.getCurrentQuestionIndex() > 0) {
      this.props.history.goBack();
    }
  };

  // open Dialog for user to confirm exit
  toggleDialog = () => {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
    });
  };

  exitQuestionnaire = () => {
    const { history } = this.props;

    history.push(dashboardRoute);
  };

  render() {
    const {classes, t, showError, participationState} = this.props;
    const {dialogOpen} = this.state;

    if (this.props.isLoading || !this.getQuestionnaire()) {
      return <LoadingOverlay/>;
    }
    return (
      <>
        <DialogComponent
          open={dialogOpen}
          closeFunction={this.toggleDialog}
          confirmFunction={this.exitQuestionnaire}
          title={t(translations.pages.Questionnaire.cancelDialog.title)}
          description={t(translations.pages.Questionnaire.cancelDialog.description)}
          buttonText={t(translations.action.stopQuestionnaire)}
        />
        {this.props.showError && (
          <>
            <SnackbarComponent
              title={t(translations.pages.Questionnaire.warning.headline)}
              message={t(translations.pages.Questionnaire.warning.description, {
                seconds: "5",
              })}
              type="error"
              onClose={() => {
                this.props.removeError();
              }}
            />
            {setTimeout(() => {
              this.props.removeError();
              this.exitQuestionnaire();
            }, 5000)}
          </>
        )}

        <Grid container={true} justifyContent="space-between" className={classes.navigation}>
          {this.getCurrentQuestionIndex() > 0 && (
            <IconButton onClick={this.goToPreviousQuestion} className={classes.backIcon}>
              <ArrowBack fill={"#fff"} width={24} height={24} />
            </IconButton>
          )}
          <IconButton onClick={this.exitQuestionnaire} className={classes.closeIcon}>
            <Cross fill={"#fff"} width={24} height={24} />
          </IconButton>
        </Grid>
        {/* answerTypes: choice, rating, text */}
        <div style={{height: "100vh"}}>
          {/*  questionnaire is finished, so we show the final screen */}
          {participationState.state === "PARTICIPATION_REJECTED" ? (<RejectedQuestionnaire/>) :
            this.getCurrentQuestionIndex() === this.getQuestionnaire()!.questions.length ? (
              <FinishedQuestionnaire/>
            ) : (
              this.getQuestionnaire()!
                .questions.filter(
                (question: Question) => question.id === this.props.match.params.questionID
              )
                .map((question: Question) => (
                  <QuestionContainer
                    key={question.id}
                    question={question}
                    onNextClick={this.goToNextQuestion}
                    currentQuestionnaireStep={this.getCurrentQuestionIndex() + 1}
                    totalQuestionnaireSteps={this.getQuestionnaire()!.questions.length}
                    setSkipQuestionId={this.setSkipQuestionId}
                    error={showError}
                  />
                ))
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  getParticipation,
  completeParticipation,
  removeError,
};

const mapStateToProps = ({ error, participation, uiState }: RootState, ownProps: Props) => {
  return {
    isOffline: Boolean(error.offline),
    expiredAccess: Boolean(participation.expiredAccess),
    sent: Boolean(participation.sent),
    questionnaire: participation.currentSurvey && participation.currentSurvey.questionnaire,
    survey: participation.currentSurvey,
    participationState: participation.participationState,
    acceptedSurveys: participation.acceptedSurveys,
    isLoading: participation.isLoading,
    surveyAnswers: participation.surveyAnswers,
    showError: uiState.errors,
  };
};
export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(QuestionnairePage);
