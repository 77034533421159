import * as React from "react";
import Navigation from "../common/Layout/Navigation";
import { Typography } from "@material-ui/core";
import { QuopinionTheme } from "../constants/Theme";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";

import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";

import { connect } from "react-redux";
import { RootState } from "../modules";
import { translations } from "../constants/lang/translation";

interface OwnProps {}
interface StateProps {
  isOffline: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    actionButton: {
      width: 136,
      color: "#fff",
    },
  });

type Props = OwnProps & StateProps & WithTranslation & WithStyles<typeof styles>;

class ErrorOffline extends React.Component<Props> {
  static route = "/offlineError";

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { isOffline, t } = this.props;
    return (
      /*  to add as Snackbar Error */
      <>
        <div>
          {isOffline ? (
            <Typography variant="body1">{t(translations.general.offlineError)}</Typography>
          ) : (
            ""
          )}
        </div>
        <Navigation />
      </>
    );
  }
}

const mapStateToProps = ({ error }: { error: any }) => ({
  isOffline: Boolean(error.offline),
});

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect<OwnProps, {}, StateProps, RootState>(mapStateToProps)
)(ErrorOffline);
