import ID from "./ID";
import {v4 as uuidv4} from "uuid";
import Media from "./Media";

/**
 * Domain entity class MatrixSubQuestion
 * which represents an question part for matrix questions
 */
export default class MatrixSubQuestion {
  readonly id: ID;
  label: string;
  media: Media[];

  constructor(obj: any = {}) {
  //@ts-ignore
    this.id = obj.id || uuidv4();
    this.label = obj.label || "";
    this.media = obj.media || [];
  }
}
