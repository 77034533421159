import React from "react";
import { Typography, styled } from "@material-ui/core";
import { SurveyAdminInformation } from "../../../entities/Survey";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";
import { QuopinionTheme } from "../../../constants/Theme";

const AuthorMail = styled("a")((props: { theme: QuopinionTheme }) => ({
  opacity: 0.8,
  textDecoration: "none",
  color: props.theme.palette.text.primary,
}));

type Props = {
  information: SurveyAdminInformation;
} & WithTranslation;

const AdditionalSurveyInformation = withTranslation()((props: Props) => {
  const { name: organizationName } = props.information.organization.details;
  const { authorMail } = props.information;

  return (
    <Typography variant="body2">
      {props.t(translations.pages.Survey.Admin.createdBy)}
      {organizationName} - <AuthorMail href={`mailTo:${authorMail}`}>{authorMail}</AuthorMail>
    </Typography>
  );
});

export default AdditionalSurveyInformation;
