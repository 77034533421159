import React from "react";
import { createStyles, withStyles, WithStyles, Grid } from "@material-ui/core";
import { QuopinionTheme } from "../../constants/Theme";

interface OwnProps {
  blockDisplay?: boolean;
  className?: any;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      maxWidth: 1280,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: theme.spacing(16),
      display: (props: OwnProps) => (props.blockDisplay ? "block" : "inherit"),
      // IE
      "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
        justifyContent: "center",
      },
    },
  });
type Props = OwnProps & WithStyles<typeof styles>;

const ContentWrapper: React.FC<Props> = ({ children, classes, className }) => {
  const classNames = `${classes.root} ${className}`;
  return (
    <Grid container={true} className={classNames} id={"content-wrapper"}>
      {children}
    </Grid>
  );
};

export default withStyles(styles)(ContentWrapper);
