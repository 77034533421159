import Client from "./Client";

export default class Questionnaire {
  readonly httpClient: Client;

  constructor(httpClient: Client) {
    this.httpClient = httpClient;
  }

  async getPanelConfiguration(): Promise<any> {
    return await this.httpClient.get(`/attributes/_panel`).then((response) => {
      return response;
    });
  }
}
