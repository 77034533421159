import React, { PureComponent, ReactNode } from "react";
import enrichedTheme, { QuopinionTheme, red } from "../../../constants/Theme";
import { WithStyles, withStyles, createStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { DropdownArrowGrey } from "../../../assets/icon";
import { MenuItem, Select, FormControl, InputLabel, Checkbox } from "@material-ui/core";
import { translations } from "../../../constants/lang/translation";
import { withTranslation, WithTranslation } from "react-i18next";
import { SelectProps } from "@material-ui/core/Select";
import flatten from "lodash.flatten";

export interface OptionsType {
  label: string;
  value: string;
}

export interface AttributeSelectOwnProps extends SelectProps {
  children?: ReactNode[]; //only optional as far as individual attributes are not implemented yet
  selectOptions: OptionsType[];
  selectedValues: string[];
  multiple?: boolean;
  disabled?: boolean;
  customValue?: ReactNode;
  smallFontSize?: boolean;
  handleSelection: (values: string[]) => void;
  placeholder?: string;
  allowSelectAll?: boolean;
  name?: string;
}

interface State {
  selectOptions?: OptionsType[];
  selectAll?: boolean;
}

const SELECT_ALL = "SELECT_ALL";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    select: {
      height: "18.75px",
      textTransform: "initial",
      border: "1px solid",
      borderColor: (props: AttributeSelectOwnProps) =>
        props.error ? red : theme.palette.grey[100],
      backgroundColor: "#fff",
      padding: theme.spacing(4),
      borderRadius: 0,
      "&:active": {
        backgroundColor: "#fff",
      },
      "&:focus": {
        backgroundColor: "#fff",
      },
      lineHeight: (props: AttributeSelectOwnProps) => (props.smallFontSize ? 1.14 : "unset"),
      fontSize: (props: AttributeSelectOwnProps) => (props.smallFontSize ? "14px" : "inherit"),
    },
    selectRoot: {
      width: "100%",
      position: "relative",
      border: "none",
      padding: 0,
      "& svg": {
        width: 50,
        top: 1,
        right: 0,
        borderLeft: "1px solid",
        borderColor: theme.palette.grey[100],
        position: "absolute",
        height: "calc(100% - 2px)",
        padding: theme.spacing(3),
        pointerEvents: "none",
      },
    },
    selectWrapper: {
      width: "100%",
    },
    label: {
      color: "#A8A8A8",
      fontSize: "16px",
      top: 13,
      left: 16,
      zIndex: 10,
    },
  });

type Props = AttributeSelectOwnProps & WithStyles<typeof styles> & WithTranslation;

class AttributeSelect extends PureComponent<Props, State> {
  state = { selectAll: false };

  componentDidMount = () => {
    this.setState({
      selectAll:
        this.props.selectOptions.length === this.props.selectedValues.length ? true : false,
    });
  };

  handleSelectItem = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
    const { multiple = true, allowSelectAll, selectOptions, selectedValues } = this.props;

    if (allowSelectAll && event.target.value.includes("SELECT_ALL")) {
      const values = selectOptions.map((option) => option.value);

      this.setState({
        selectAll: !this.state.selectAll,
      });

      values.length === selectedValues.length
        ? this.props.handleSelection([])
        : this.props.handleSelection(values);
    } else {
      if (multiple) {
        this.props.handleSelection([...flatten(event.target.value as string[])]);
        this.setState({
          selectAll: false,
        });
      } else {
        this.props.handleSelection([event.target.value]);
      }
    }
  };

  renderValues = (selected: any) => {
    if (selected && typeof selected === "object" && selected instanceof Array) {
      return (selected as string[])
        .map((selectedValue) => {
          const selectedOption = this.props.selectOptions.find(
            (selectOption) => selectOption.value === selectedValue
          );
          if (!selectedOption) {
            console.warn(
              'AttributeSelector: prop "selectedValues" must consist of selectOptions.value\'s, but one was: ' +
                JSON.stringify(selectedValue)
            );
            return selectedValue;
          }
          return selectedOption.label;
        })
        .join(", ");
    } else {
      console.warn(
        'AttributeSelector: prop "selectedValues" should be an array, but something else was provided :( Please check!'
      );
      return "";
    }
  };

  render() {
    const {
      classes,
      t,
      selectOptions,
      selectedValues,
      multiple = true,
      disabled = false,
      placeholder,
      children,
      customValue,
      allowSelectAll,
    } = this.props;

    return (
      <FormControl className={classes.selectWrapper}>
        <InputLabel className={classes.label}>
          {placeholder ? placeholder : t(translations.questionnaire.placeholder.pleaseSelect)}
        </InputLabel>
        <Select
          name={this.props.name}
          multiple={multiple}
          value={customValue || selectedValues}
          className={classes.selectRoot}
          classes={{
            select: classes.select,
          }}
          IconComponent={CustomDropdownArrowGrey}
          onChange={this.handleSelectItem}
          onBlur={this.props.onBlur}
          displayEmpty={true}
          placeholder={
            placeholder ? placeholder : t(translations.questionnaire.placeholder.pleaseSelect)
          }
          error={this.props.error}
          disabled={disabled}
          renderValue={this.renderValues}
        >
          {children}
          {!children &&
            selectOptions.length > 0 &&
            selectOptions.map((selectionOption: OptionsType) => (
              <MenuItem key={selectionOption.value} value={selectionOption.value}>
                {multiple && (
                  <Checkbox checked={selectedValues.indexOf(selectionOption.value) > -1} />
                )}
                {selectionOption.label}
              </MenuItem>
            ))}
          {allowSelectAll && (
            <MenuItem
              value={SELECT_ALL}
              style={{
                color: this.state.selectAll ? enrichedTheme.palette.secondary.main : "inherit",
              }}
            >
              <Checkbox checked={this.state.selectAll} />
              Alle
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
}

export default compose<Props, AttributeSelectOwnProps>(
  withTranslation(),
  withStyles(styles)
)(AttributeSelect);

const CustomDropdownArrowGrey = (props: any) => <DropdownArrowGrey {...props} />;
