import ID from "./ID";

export default class RequestedAttribute {
  readonly id: ID;
  readonly organizationId: ID;
  readonly surveyId: ID;
  readonly account: {
    id: ID;
    emailAddress: string;
    createdAt: string;
    firstName: string;
    lastName: string;
  };
  readonly message: string;
  readonly status: "NEW" | "PUBLISH_REQUESTED" | "ACCEPTED" | "REJECTED" | "PUBLISHED" | "ENDED";
  readonly createdAt: string;

  constructor(obj: any) {
    this.id = obj.id;
    this.organizationId = obj.organizationId;
    this.surveyId = obj.surveyId;
    this.account = obj.account;
    this.message = obj.message;
    this.status = obj.status;
    this.createdAt = obj.createdAt;
  }
}

export interface NewRequestedAttribute {
  message: string;
  surveyId: ID;
}
