import Question from "./Question";

export class EvaluatedQuestion {
  question: Omit<Question, "toDataJson">;
  results: any;
  relativeResults: any;
  additionalFreeTextResults?: any;
  additionalNumberResults?: any;
  matrixResults?: any; //type any: as IDs are object attributes and otherwise undefined check in QresultItem
  matrixRelativeResults?: any;
  rankingResults?: any;
  rankingRelativeResults?: any;

  constructor(obj: any) {
    this.question = new Question(obj.question);
    this.results = obj.results;
    this.relativeResults = obj.relativeResults;
    this.additionalFreeTextResults = obj.additionalFreeTextResults;
    this.additionalNumberResults = obj.additionalNumberResults;
    this.matrixResults = obj.matrixResults;
    this.matrixRelativeResults = obj.matrixRelativeResults;
    this.rankingResults = obj.rankingResults;
    this.rankingRelativeResults = obj.rankingRelativeResults;
  }
}

export class SurveyResult {
  panelSize: number;
  numberOfOpenParticipations: number;
  numberOfAcceptedParticipations: number;
  numberOfCompletedParticipations: number;
  numberOfDeclinedParticipations: number;
  evaluatedQuestions: EvaluatedQuestion[];

  constructor(obj: any) {
    this.panelSize = obj.panelSize;
    this.numberOfOpenParticipations = obj.numberOfOpenParticipations;
    this.numberOfAcceptedParticipations = obj.numberOfAcceptedParticipations;
    this.numberOfCompletedParticipations = obj.numberOfCompletedParticipations;
    this.numberOfDeclinedParticipations = obj.numberOfDeclinedParticipations;
    this.evaluatedQuestions = obj.evaluatedQuestions.map(
      (item: any) => new EvaluatedQuestion(item)
    );
  }
}
