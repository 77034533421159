import React, { ChangeEvent, PureComponent } from "react";
import { compose } from "recompose";
import Card from "../../../common/Layout/components/Card";
import {
  SnackbarComponent,
  SnackbarContentProps,
} from "../../../common/Layout/components/Snackbar";
import {
  createStyles,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { blueGradient, QuopinionTheme } from "../../../constants/Theme";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Button from "../../../common/Layout/components/Button";
import { Cross, DropdownArrowSmall } from "../../../assets/icon";
import PayoutSelect from "./PayoutSelect";
import DWBLogo from "../../../assets/images/payout/dwb-logo.png";
import PaypalLogo from "../../../assets/images/payout/paypal-logo.png";
import AmazonLogo from "../../../assets/images/payout/amazon-logo.jpg";
import GraffLogo from "../../../assets/images/payout/graff-logo.png";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";
import i18next from "i18next";
import { RootState } from "../../../modules";
import { connect } from "react-redux";
import CreditHistory from "./CreditHistory";
import { formatPrice } from "../../../entities/Survey";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import { CreditData } from "../../../entities/User";
import { fetchCurrentCreditData, requestPayout } from "../../../modules/user";
import * as Yup from "yup";
import LoadingOverlay from "../../../common/LoadingOverlay";

interface OwnProps {}

interface DispatchProps {
  fetchCurrentCreditData: typeof fetchCurrentCreditData;
  requestPayout: typeof requestPayout;
}

interface StateProps {
  creditData: CreditData;
}

interface State {
  payoutSelectionOpen: boolean;
  selectedOption?: number;
  paypalEmail: string;
  paypalEmailValid: boolean;
  snackbarConfig?: SnackbarContentProps;
  requestPayoutLoading: boolean;
  selectedPayoutAmountStep: number;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    card: {
      background: blueGradient,
      color: "#fff",
    },
    cardContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
    },
    buttonIcon: {
      marginLeft: theme.spacing(2),
    },
    cross: {
      fill: "#fff",
      height: 16,
    },
    headline: {
      marginBottom: theme.spacing(4),
      textTransform: "uppercase",
    },
    selectionWrapper: {
      backgroundColor: "#fff",
      color: theme.palette.grey[400],
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    },
    selectionItem: {
      padding: `0px ${theme.spacing(1)}px`,
      margin: `${theme.spacing(6)}px 0`,
      "&:first-of-type": {
        padding: `0px ${theme.spacing(2)}px 0px 0px`,
      },
      "&:last-of-type": {
        padding: `0px 0px 0px ${theme.spacing(2)}px`,
      },
      maxWidth: 250,
    },
    infoBox: {
      marginBottom: `${theme.spacing(6)}px`,
    },
    buttonLine: {},
    title: {
      marginBottom: theme.spacing(2),
    },
    contentWrapper: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      "@media (min-width: 992px)": {
        padding: `${theme.spacing(10)}px ${theme.spacing(6)}px`,
      },
    },
    input: {
      width: 250,
    },
    payoutRequestWrapper: {
      backgroundColor: "#fff",
      color: theme.palette.grey[400],
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    },
    selectRoot: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    selectIcon: {
      top: "unset",
    },
    amountSelectionWrapper: {
      display: "flex",
      alignItems: "baseline",
    },
    payoutAmount: {
      marginBottom: `${theme.spacing(6)}px`,
    },
  });

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles> & WithTranslation;

export const route: string = "/participant/account";

export interface PayoutOption {
  name: string;
  kind: string;
  image: string;
  backendKey: string;
}

const idPayOutAerzte = 0;
const idPayOutPaypal = 1;
const idPayOutAmazon = 2;
const idPayOutGraff = 3;
// if the order of payout options is changed, change the payout ids accordingly!
const payoutOptions: PayoutOption[] = [
  {
    name: i18next.t(translations.payoutPage.payout.options.doctor.title),
    kind: i18next.t(translations.payoutPage.payout.donation),
    image: DWBLogo,
    backendKey: "aerzteohnegrenzen",
  },
  {
    name: i18next.t(translations.payoutPage.payout.options.paypal.title),
    kind: i18next.t(translations.payoutPage.payout.title),
    image: PaypalLogo,
    backendKey: "paypal",
  },
  {
    name: i18next.t(translations.payoutPage.payout.options.amazon.title),
    kind: i18next.t(translations.payoutPage.payout.voucher),
    image: AmazonLogo,
    backendKey: "amazon",
  },
  {
    name: i18next.t(translations.payoutPage.payout.options.graff.title),
    kind: i18next.t(translations.payoutPage.payout.bookVoucher),
    image: GraffLogo,
    backendKey: "graff",
  },
];

const payoutAmountSteps = [5, 10, 15, 20, 50];

class ParticipantAccount extends PureComponent<Props, State> {
  state: State = {
    payoutSelectionOpen: false,
    paypalEmail: "",
    paypalEmailValid: false,
    requestPayoutLoading: false,
    selectedPayoutAmountStep: 5,
  };

  componentDidMount() {
    const { fetchCurrentCreditData } = this.props;
    fetchCurrentCreditData();
  }

  togglePayoutSelection = () => {
    this.setState({
      payoutSelectionOpen: !this.state.payoutSelectionOpen,
    });
  };

  choosePayoutOption = (value: number) => {
    this.setState({
      selectedOption: value,
    });
  };

  generateButtonTitle = () => {
    const { t } = this.props;
    switch (this.state.selectedOption) {
      case idPayOutAerzte:
        return t(translations.payoutPage.payout.options.doctor.buttonText);
      case idPayOutPaypal:
        return t(translations.payoutPage.payout.options.paypal.buttonText);
      case idPayOutAmazon:
        return t(translations.payoutPage.payout.options.amazon.buttonText);
      case idPayOutGraff:
        return t(translations.payoutPage.payout.options.graff.buttonText);
      default:
        return t(translations.questionnaire.placeholder.pleaseSelect);
    }
  };

  closeSnackbar = () => {
    this.setState({
      snackbarConfig: undefined,
    });
  };

  requestPayout = async () => {
    const { paypalEmail, selectedOption, selectedPayoutAmountStep } = this.state;
    const { requestPayout, t } = this.props;

    try {
      this.setState({ requestPayoutLoading: true });
      await requestPayout(
        selectedPayoutAmountStep,
        payoutOptions[selectedOption!].backendKey,
        selectedOption === idPayOutPaypal ? paypalEmail : undefined
      );
      this.togglePayoutSelection();
      this.setState({
        requestPayoutLoading: false,
        snackbarConfig: {
          title: t(translations.payoutPage.payout.success.title),
          message: t(
            selectedOption === idPayOutGraff
              ? translations.payoutPage.payout.success.messageStartOfMonth
              : translations.payoutPage.payout.success.message
          ),
          type: "success",
          onClose: this.closeSnackbar,
        },
        selectedPayoutAmountStep: 5,
      });
    } catch (e) {
      this.setState({
        requestPayoutLoading: false,
        snackbarConfig: {
          title: t(translations.payoutPage.payout.failure.title),
          message: t(translations.payoutPage.payout.failure.message),
          type: "error",
          onClose: this.closeSnackbar,
        },
      });
    }
  };

  renderPayPalForm() {
    const { t, classes } = this.props;
    const { paypalEmail, paypalEmailValid } = this.state;
    return (
      <div className={classes.infoBox}>
        <Typography variant="h4" color="primary">
          {t(translations.payoutPage.payout.options.paypal.email)}
        </Typography>
        <TextField
          name="paypalEmail"
          placeholder={t(translations.payoutPage.payout.pleaseEnter)}
          className={classes.input}
          error={!paypalEmailValid}
          value={paypalEmail}
          onChange={async (event) => {
            this.setState({
              paypalEmail: event.target.value,
              paypalEmailValid: await Yup.string()
                .trim()
                .email()
                .required()
                .isValid(event.target.value),
            });
          }}
          required={true}
        />
      </div>
    );
  }

  setAmountChoice(selectedAmount: number) {
    this.setState({
      selectedPayoutAmountStep: selectedAmount,
    });
  }

  renderAmountSelection() {
    const { classes, creditData, t } = this.props;
    const { selectedPayoutAmountStep } = this.state;
    const allowedAmountSteps = payoutAmountSteps.filter(
      (amountStep: number) => creditData.credit >= amountStep
    );

    return (
      <div className={classes.payoutAmount}>
        <Typography variant="h4" color="primary">
          {t(translations.payoutPage.payout.payoutAmount)}
        </Typography>

        {creditData.credit >= 5 ? (
          <div className={classes.amountSelectionWrapper}>
            <Select
              value={selectedPayoutAmountStep}
              onChange={(event: ChangeEvent<any>) => {
                this.setAmountChoice(event.target.value);
              }}
              className={classes.selectRoot}
              classes={{ icon: classes.selectIcon }}
            >
              {allowedAmountSteps.map((amountStep: number) => {
                return (
                  <MenuItem key={amountStep.toString()} value={amountStep}>
                    {amountStep}
                  </MenuItem>
                );
              })}
            </Select>
            <Typography>{t(translations.payoutPage.payout.currency)}</Typography>
          </div>
        ) : (
          <Typography>{t(translations.payoutPage.payout.amountChoiceRequirement)}</Typography>
        )}
      </div>
    );
  }

  render() {
    const { classes, t, creditData } = this.props;
    const {
      payoutSelectionOpen,
      selectedOption,
      snackbarConfig,
      requestPayoutLoading,
      selectedPayoutAmountStep,
    } = this.state;

    return (
      <div>
        {requestPayoutLoading && <LoadingOverlay />}
        {snackbarConfig && <SnackbarComponent {...snackbarConfig} />}

        <LogoBarParticipant backButton={true} hideLogo={true} />
        <ContentWrapper className={classes.contentWrapper}>
          <Typography variant="body2" className={classes.headline}>
            {t(translations.payoutPage.yourAccount)}
          </Typography>
          {creditData.credit >= 50 && (
            <div className={classes.payoutRequestWrapper}>
              <Typography variant="subtitle1" color={"inherit"}>
                {t(translations.payoutPage.payoutRequest)}
              </Typography>
            </div>
          )}
          <Card className={classes.card} isRaised={false} dark={true}>
            <div className={classes.cardContent}>
              <div>
                <Typography variant="body2">{t(translations.payoutPage.currentBalance)}</Typography>
                <Typography variant="h1">{formatPrice(creditData.credit)}</Typography>
              </div>
              <Button
                contained={true}
                size="medium"
                color="primary"
                onClick={this.togglePayoutSelection}
              >
                {!payoutSelectionOpen && (
                  <>
                    {t(translations.payoutPage.payout.title)}
                    <DropdownArrowSmall className={classes.buttonIcon} />
                  </>
                )}
                {payoutSelectionOpen && <Cross className={classes.cross} />}
              </Button>
            </div>
            {/* area to select payout */}
            {payoutSelectionOpen && (
              <div className={classes.selectionWrapper}>
                <Typography variant="h3" color="primary">
                  {t(translations.payoutPage.payout.title)}
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  {t(translations.payoutPage.payout.choosePayout)}
                </Typography>
                <Grid container={true} style={{ display: "flex", justifyContent: "space-evenly" }}>
                  {payoutOptions.map((option, idx) => (
                    <Grid item={true} xs={4} key={option.name} className={classes.selectionItem}>
                      <PayoutSelect
                        value={idx}
                        option={option}
                        selectOption={this.choosePayoutOption}
                        selected={idx === selectedOption}
                      />
                    </Grid>
                  ))}
                </Grid>
                {/* For PayPal we need the PayPal email */}
                {typeof selectedOption === "number" && (
                  <>
                    {selectedOption === idPayOutPaypal && this.renderPayPalForm()}
                    {/* add dropdown for amount selection */}
                    {this.renderAmountSelection()}
                    <div className={classes.infoBox}>
                      <Typography variant="h4" color="primary">
                        {t(translations.payoutPage.payout.summary)}
                      </Typography>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <Typography variant="body1" color="inherit">
                                {t(translations.payoutPage.payout.payoutMethod)}
                              </Typography>
                            </td>
                            {selectedOption === idPayOutGraff && (
                              <td>
                                <Typography variant="body2" color="inherit">
                                  {`${i18next.t(
                                    translations.payoutPage.payout.options.graff.linkText
                                  )}`}
                                  <Link href="https://www.graff.de" color="inherit">
                                    www.graff.de
                                  </Link>
                                </Typography>
                              </td>
                            )}
                            {selectedOption !== idPayOutGraff && (
                              <td>
                                <Typography variant="body2" color="inherit">
                                  {`${payoutOptions[selectedOption].name} (${payoutOptions[selectedOption].kind})`}
                                </Typography>
                              </td>
                            )}
                          </tr>

                          {selectedOption === idPayOutPaypal && (
                            <tr>
                              <td>
                                <Typography variant="body1" color="inherit">
                                  {t(translations.payoutPage.payout.options.paypal.email)}
                                </Typography>
                              </td>
                              <td>
                                <Typography variant="body2" color="inherit">
                                  {this.state.paypalEmail}
                                </Typography>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <Typography variant="body1" color="inherit">
                                {t(translations.payoutPage.payout.amount)}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2" color="inherit">
                                {selectedPayoutAmountStep}{" "}
                                {t(translations.payoutPage.payout.currency)}
                              </Typography>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <Typography variant="body1" color="inherit">
                                {t(translations.payoutPage.payout.bookingTimestamp)}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2" color="inherit">
                                {t(translations.payoutPage.payout.immediately)}
                              </Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Typography style={{ fontSize: 12, opacity: 0.8 }}>
                        {t(
                          selectedOption === idPayOutGraff
                            ? translations.payoutPage.payout.startOfMonthDisclaimer
                            : translations.payoutPage.payout.immediatelyDisclaimer
                        )}
                      </Typography>
                    </div>
                  </>
                )}
                <div className={classes.buttonLine}>
                  <Button
                    contained={true}
                    color="secondary"
                    size="medium"
                    disabled={
                      typeof selectedOption !== "number" ||
                      creditData.credit < 5 ||
                      (selectedOption === idPayOutPaypal && !this.state.paypalEmailValid)
                    }
                    onClick={this.requestPayout}
                  >
                    {this.generateButtonTitle()}
                  </Button>
                </div>
              </div>
            )}
          </Card>
          <CreditHistory credits={creditData.credits} />
        </ContentWrapper>
      </div>
    );
  }
}

const mapStateToProps = ({ user }: RootState) => ({
  creditData: user.creditData,
});

const mapDispatchToProps = { fetchCurrentCreditData, requestPayout };

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(ParticipantAccount);
