import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, Grid, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";

import { QuopinionTheme } from "../../../constants/Theme";

import CarouselTextItem from "./CarouselTextItem";
import CarouselPictureItem from "./CarouselPictureItem";

interface OwnProps {
  stepObject: {
    title: string;
    description: string;
    imageS: string;
    imageM: string;
    imageL: string;
  };
  backgroundColor: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      backgroundColor: (props: OwnProps) => props.backgroundColor,
      position: "relative",
      justifyContent: "center",
      "@media(min-width: 960px)": {
        justifyContent: "unset",
      },
      // IE
      "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
        alignItems: "center",
      },
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class StepItem extends PureComponent<Props> {
  render() {
    const { classes, stepObject } = this.props;
    return (
      <Grid container={true} className={classes.wrapper}>
        {/*picture*/}
        <CarouselPictureItem
          imageNameSmall={stepObject.imageS}
          imageNameMedium={stepObject.imageM}
          imageNameBig={stepObject.imageL}
        />
        {/*side text*/}
        <CarouselTextItem title={stepObject.title} description={stepObject.description} />
      </Grid>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(StepItem);
