import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, withStyles, WithStyles, Grid, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { QuopinionTheme } from "../../constants/Theme";
import { Link } from "react-router-dom";
import Button from "../Layout/components/Button";
import QuopinionLogo from "../../assets/logo.png";

interface OwnProps {
  title: string;
  description: React.ReactNode;
  backgroundColor: string;
  video: string;
  videoTitle: React.ReactNode;
  videoButtonTitle: string;
  videoButtonLink: string;
  logoRouteAuthenticated: string;
  logoRouteNotAuthenticated: string;
  isAuthenticated: boolean | undefined;
  posterImage: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
      "@media (min-width: 1024px)": {
        padding: `${theme.spacing(8)}px ${theme.spacing(0)}px ${theme.spacing(
          20
        )}px ${theme.spacing(10.5)}px`,
      },
      "@media(max-width: 599px)": {
        display: "flex",
        order: 2,
        flexDirection: "column",
        marginTop: theme.spacing(40),
      },
    },
    description: {
      color: theme.palette.grey[400],
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(6)}px`,
      "@media (min-width: 600px)": {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(6)}px`,
      },
      maxWidth: theme.spacing(82.75),
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.71,
    },
    logo: {
      width: 170,
      height: 90,
      "@media(min-width: 600px)": {
        marginBottom: theme.spacing(6),
      },
    },
    wrapper: {
      backgroundColor: (props: OwnProps) => props.backgroundColor,
      position: "relative",
    },
    headline: {
      fontFamily: "catamaranBold",
      fontSize: "32px",
      fontWeight: 200,
      lineHeight: 1.25,
      "@media(min-width: 1024px)": {
        fontSize: "40px",
        fontWeight: 200,
        lineHeight: 1.1,
      },
      letterSpacing: "normal",
      maxWidth: theme.spacing(106.25),
    },
    childItem: {
      "@media(max-width: 599px)": {
        display: "flex",
        flexDirection: "column",
        order: 1,
      },
    },
    videoWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "@media(min-width:600px)": {
        display: "block",
        justifyContent: "unset",
      },
    },
    videoItem: {
      width: 280,
      "@media(min-width:400px)": {
        width: 320,
      },
    },
    buttonContainer: {
      display: "flex",
      margin: `0px ${theme.spacing(-2)}px`,
      "& button": {
        margin: `0px ${theme.spacing(2)}px`,
      },
      flexDirection: "column",
      height: 154,
      marginBottom: theme.spacing(10),
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      "@media(min-width:600px)": {
        justifyContent: "unset",
        flexDirection: "row",
        height: "unset",
      },
    },
    link: {
      textDecoration: "none",
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class LandingpageHeaderParticipant extends PureComponent<Props> {
  render() {
    const {
      title,
      description,
      classes,
      children,
      video,
      videoTitle,
      posterImage,
      isAuthenticated,
      logoRouteAuthenticated,
      logoRouteNotAuthenticated,
      videoButtonTitle,
      videoButtonLink,
    } = this.props;
    return (
      <Grid container={true} className={classes.wrapper}>
        {/*left side text*/}
        <Grid item={true} xs={12} sm={6} className={classes.root}>
          <a href={isAuthenticated ? logoRouteAuthenticated : logoRouteNotAuthenticated}>
            <img src={QuopinionLogo} alt="Quopinion Logo" className={classes.logo} />
          </a>

          <Typography className={classes.headline} color="primary">
            {title}
          </Typography>
          <Typography variant="body1" color="inherit" className={classes.description}>
            {description}
          </Typography>
          <div className={classes.videoWrapper}>
            <video
              height="182"
              controls
              poster={posterImage}
              autoPlay={false}
              className={classes.videoItem}
            >
              <source src={video} type="video/mp4" />
            </video>
            <Typography variant="body1" color="inherit" className={classes.description}>
              {videoTitle}
            </Typography>
          </div>
          <div className={classes.buttonContainer}>
            <Link to={videoButtonLink} className={classes.link}>
              <Button size="medium" color="secondary" contained={true}>
                {videoButtonTitle}
              </Button>
            </Link>
          </div>
        </Grid>
        {/*child right element*/}
        <Grid item={true} xs={12} sm={6} className={classes.childItem}>
          {children && children}
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(LandingpageHeaderParticipant);
