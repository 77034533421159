import React, { PureComponent } from "react";
import Media from "../../../entities/Media";
import VideoPlayer from "./VideoPlayer";
import { WithStyles, createStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";
import AudioPlayer from "./AudioPlayer";
import ImageWithOverZoom from "./ImageWithOverZoom";

interface OwnProps {
  media: Media[];
  answerOption?: boolean;
  withoutMediaMargin?: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    image: {
      width: (props: OwnProps) => (props.answerOption ? 260 : 320),
      marginLeft: (props: OwnProps) =>
        props.answerOption
          ? props.withoutMediaMargin
            ? theme.spacing(0)
            : theme.spacing(9)
          : theme.spacing(0),
      "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
        marginLeft: theme.spacing(3),
        width: 260,
      },
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class MediaDisplay extends PureComponent<Props> {
  render() {
    const { media, answerOption, withoutMediaMargin } = this.props;
    return (
      <div>
        {media[0].mimeType.includes("video") && (
          <VideoPlayer video={media[0].url} type={media[0].mimeType} />
        )}
        {media[0].mimeType.includes("image") && (
          <ImageWithOverZoom
            media={media[0]}
            answerOption={answerOption}
            withoutMediaMargin={withoutMediaMargin}
          />
        )}
        {media[0].mimeType.includes("audio") && (
          <AudioPlayer audio={media[0].url} type={media[0].mimeType} />
        )}
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(MediaDisplay);
