import React, { PureComponent } from "react";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";
import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import SidebarContainer from "../../../common/Layout/SidebarContainer";
import SupportContainer from "../../../common/Layout/SupportContainer";
import { SurveyInformation, SurveyStatus } from "../../../entities/Survey";
import { QuopinionTheme } from "../../../constants/Theme";
import SurveyCard from "./components/SurveyCard";
import { RootState } from "../../../modules";
import { translations } from "../../../constants/lang/translation";
import { DropdownArrowGrey } from "../../../assets/icon";
import LoadingOverlay from "../../../common/LoadingOverlay";
import User from "../../../entities/User";
import { surveyDateComparator } from ".";

interface OwnProps {}

interface DispatchProps {}

interface StateProps {
  researcherSurveys: SurveyInformation[];
  isLoadingSurveys: boolean;
  isAuthenticated: boolean;
  currentUser: User;
}

interface State {
  selectedFilter: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.spacing(4),
    },
    title: {
      textTransform: "uppercase",
    },
    selectMenu: {
      width: 100,
      "&$active": {
        borderRadius: "4px",
      },
      "&:focus": {
        borderRadius: "4px",
      },
    },
    select: {
      position: "relative",
      "& svg": {
        width: 20,
        top: 1,
        right: 8,
        position: "absolute",
        height: "calc(100% - 2px)",
        pointerEvents: "none",
      },
    },
    loadingContainer: {
      position: "relative",
    },
  });

type Props = OwnProps & DispatchProps & StateProps & WithTranslation & WithStyles<typeof styles>;

class SurveyBoard extends PureComponent<Props, State> {
  state: State = {
    selectedFilter: "all",
  };

  setSurveyFilter = (event: any) => {
    this.setState({
      selectedFilter: event.target.value,
    });
  };

  filterSurveys = () => {
    const { selectedFilter } = this.state;
    let surveys;
    if (selectedFilter === "current") {
      surveys = this.props.researcherSurveys
        .filter((survey) => survey.status === SurveyStatus.PUBLISHED)
        .sort(surveyDateComparator.compareByStartDate);
    } else if (selectedFilter === "done") {
      surveys = this.props.researcherSurveys
        .filter((survey) => survey.status === SurveyStatus.ENDED)
        .sort(surveyDateComparator.compareByStartDate);
    } else if (selectedFilter === "check") {
      surveys = this.props.researcherSurveys
        .filter((survey) => survey.status === SurveyStatus.PUBLISH_REQUESTED)
        .sort(surveyDateComparator.compareByStartDate);
    } else if (selectedFilter === "draft") {
      surveys = this.props.researcherSurveys
        .filter((survey) => survey.status === SurveyStatus.NEW)
        .sort(surveyDateComparator.compareByCreatedAtDate);
    } else if (selectedFilter === "rejected") {
      surveys = this.props.researcherSurveys
        .filter((survey) => survey.status === SurveyStatus.REJECTED)
        .sort(surveyDateComparator.compareByCreatedAtDate);
    } else {
      // Default sort if no filter is applied: sort by createdAt date
      surveys = this.props.researcherSurveys.sort(surveyDateComparator.compareByCreatedAtDate);
    }
    return surveys;
  };

  render() {
    const { classes, t, isAuthenticated, isLoadingSurveys, currentUser } = this.props;
    const surveys = this.filterSurveys();
    return (
      <Grid container={true}>
        <ContentWrapper>
          <Container>
            {isAuthenticated && (
              <>
                <div className={classes.header}>
                  <Typography variant="body2" className={classes.title}>
                    {t(translations.pages.dashboard.researcher.yourSurveys)}
                  </Typography>
                  {/* select displayed surveys */}
                  <Select
                    value={this.state.selectedFilter}
                    onChange={this.setSurveyFilter}
                    className={classes.select}
                    classes={{ selectMenu: classes.selectMenu }}
                    IconComponent={CustomDropdownArrowGrey}
                    name={"surveyFilter"}
                  >
                    <MenuItem value="all">{t(translations.headerSurvey.all)}</MenuItem>
                    <MenuItem value="draft">
                      {t(translations.pages.dashboard.researcher.filter.draft)}
                    </MenuItem>
                    <MenuItem value="check">{t(translations.headerSurvey.check)}</MenuItem>
                    <MenuItem value="current">{t(translations.headerSurvey.current)}</MenuItem>
                    <MenuItem value="rejected">
                      {t(translations.pages.dashboard.researcher.filter.rejected)}
                    </MenuItem>
                    <MenuItem value="done">{t(translations.headerSurvey.done)}</MenuItem>
                  </Select>
                </div>
                {isLoadingSurveys && (
                  <div className={classes.loadingContainer}>
                    <LoadingOverlay />
                  </div>
                )}
              </>
            )}
            {surveys.map((survey) => (
              <SurveyCard
                key={survey.id}
                survey={survey}
                status={survey.status}
                withDetailsButton={true}
                additionalInformation={
                  survey.information &&
                  currentUser &&
                  currentUser.email !== survey.information.authorMail
                    ? survey.information
                    : undefined
                }
              />
            ))}
          </Container>
          <SidebarContainer>
            <SupportContainer />
          </SidebarContainer>
        </ContentWrapper>
      </Grid>
    );
  }
}

const mapStateToProps = ({ session, user }: RootState) => ({
  researcherSurveys: user.researcherSurveys,
  isLoadingSurveys: user.isLoadingSurveys,
  isAuthenticated: session.authenticated,
  currentUser: user.current,
});

const mapDispatchToProps = {};

export default compose<Props, OwnProps>(
  connect<{}, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles)
)(SurveyBoard);

const CustomDropdownArrowGrey = (props: any) => <DropdownArrowGrey {...props} />;
