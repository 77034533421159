import { Dispatch } from "redux";
import SurveyAnswers from "../entities/SurveyAnswers";

const SET_USER_ANSWERS = "preview/SET_USER_ANSWERS";

interface SurveyPreviewState {
  surveyAnswers: SurveyAnswers;
}

const defaultState: SurveyPreviewState = {
  surveyAnswers: new SurveyAnswers(),
};

interface SurveyPreviewAction {
  type: string;
  surveyAnswers: SurveyAnswers;
}

export const setUserAnswersInPreview = (surveyAnswers: SurveyAnswers) => ({
  type: SET_USER_ANSWERS,
  surveyAnswers,
});

export const setPreviewAnswers = (surveyAnswers: SurveyAnswers) => {
  return async (dispatch: Dispatch) => {
    dispatch(setUserAnswersInPreview(surveyAnswers));
  };
};

export const resetPreviewAnswers = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setUserAnswersInPreview(new SurveyAnswers()));
  };
};

export const reducer = (
  state: SurveyPreviewState = defaultState,
  action: SurveyPreviewAction
): SurveyPreviewState => {
  switch (action.type) {
    case SET_USER_ANSWERS:
      return {
        ...state,
        surveyAnswers: action.surveyAnswers,
      };
    default:
      return state;
  }
};
