import * as React from "react";
import { resetPassword } from "../../../modules/session";
import { connect } from "react-redux";
import { compose, shallowEqual } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";
import * as Yup from "yup";
import {
  TextField,
  Typography,
  WithStyles,
  createStyles,
  Grid,
  withStyles,
} from "@material-ui/core";
import Button from "../../../common/Layout/components/Button";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import { SnackbarComponent } from "../../../common/Layout/components/Snackbar";
import Container from "../../../common/Layout/Container";
import { Formik } from "formik";
import { QuopinionTheme } from "../../../constants/Theme";
import { RouteComponentProps, withRouter } from "react-router";
import PasswordStrength from "../../../common/PasswordStrength";
import ContentWrapper from "../../../common/Layout/ContentWrapper";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      "@media(min-width: 993px)": {
        padding: `${theme.spacing(10)}px 0 `,
        flexDirection: "column",
        alignItems: "center",
      },
    },
    description: {
      margin: `${theme.spacing(4)}px 0`,
      maxWidth: 500,
      textAlign: "center",
    },
    input: {
      width: "100%",
      margin: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(6),
    },
    emailWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(6),
    },
    form: {
      width: "100%",
    },
    notificationContainer: {
      marginTop: theme.spacing(4),
      opacity: 0.9,
    },
  });

interface OwnProps {}

interface StateProps {}

interface DispatchProps {
  resetPassword: (password: string, token: string) => Promise<void>;
}

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{ token: string }>;

interface PasswordResetPageState {
  showError: boolean;
}

export const route = "/resetPassword/:token";

class PasswordResetPage extends React.Component<Props, PasswordResetPageState> {
  state = { showError: false };

  readonly initialValues = {
    password: "",
    passwordRepeat: "",
  };

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      password: Yup.string()
        .min(8, t(translations.validation.login.passwordMinLength))
        .required(t(translations.validation.login.passwordRequired)),
      passwordRepeat: Yup.string()
        .oneOf(
          [Yup.ref("password"), ""],
          t(translations.register.loginDetails.passwordRepeatRequired)
        )
        .required(t(translations.register.loginDetails.passwordRepeatRequired)),
    });
  };

  toggleError = () => {
    this.setState({
      showError: !this.state.showError,
    });
  };

  render() {
    const { classes, t, match } = this.props;

    if (!match.params.token) {
      this.props.history.push("/participant/login");
    }

    return (
      <>
        {this.state.showError && (
          <SnackbarComponent
            title={"Es ist ein Fehler aufgetreten"}
            message={"Versuche es zu einem späteren Zeitpunkt noch einmal"}
            type="error"
            onClose={() => {
              this.toggleError();
            }}
          />
        )}
        <LogoBarParticipant />
        <ContentWrapper>
          <Container centered={true}>
            <Grid container={true} className={classes.wrapper}>
              <Grid item={true} xs={12} sm={6}>
                <Typography variant="h2" color="secondary">
                  Trage ein neues Passwort ein
                </Typography>
                <Typography variant="subtitle1" className={classes.description}>
                  Wähle ein neues Passwort
                </Typography>
              </Grid>
              <Grid item={true} xs={12} sm={6} justifyContent="center">
                <div className={classes.emailWrapper}>
                  <Formik
                    initialValues={this.initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      const { match } = this.props;
                      this.props
                        .resetPassword(values.password, match.params.token)
                        .then(() => {
                          setSubmitting(false);
                          setTimeout(() => {
                            this.props.history.push("/participant/login");
                          }, 1000);
                        })
                        .catch(() => {
                          setSubmitting(false);
                          this.toggleError();
                        });
                    }}
                  >
                    {(formikProps) => {
                      const {
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                        errors,
                        touched,
                        isSubmitting,
                      } = formikProps;
                      return (
                        <form className={classes.form} onSubmit={handleSubmit}>
                          <TextField
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.password && Boolean(errors.password)}
                            helperText={
                              touched.password && errors.password ? `${errors.password}` : ""
                            }
                            type="password"
                            placeholder={"Passwort"}
                            className={classes.input}
                          />
                          <TextField
                            name="passwordRepeat"
                            value={values.passwordRepeat}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.passwordRepeat && Boolean(errors.passwordRepeat)}
                            type="password"
                            helperText={
                              touched.passwordRepeat && errors.passwordRepeat
                                ? `${errors.passwordRepeat}`
                                : ""
                            }
                            placeholder={t(translations.register.loginDetails.repeatPassword)}
                            className={classes.input}
                          />
                          <PasswordStrength password={values.password} />
                          <div className={classes.buttonWrapper}>
                            <Button
                              contained={true}
                              color="secondary"
                              size="big"
                              onClick={handleSubmit}
                              disabled={shallowEqual(this.initialValues, values) || isSubmitting}
                              type="submit"
                            >
                              {t(translations.action.send)}
                            </Button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </Grid>
            </Grid>
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  withRouter,
  withStyles(styles),
  withTranslation(),
  connect(undefined, { resetPassword })
)(PasswordResetPage);
