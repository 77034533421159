import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { IconButton, withStyles, WithStyles, createStyles, Badge } from "@material-ui/core";
import { compose } from "recompose";
import { connect } from "react-redux";

import { QuopinionTheme, secondary } from "../../constants/Theme";
import { Menu, Attribute, Questionnaire } from "../../assets/icon";
import { actions as uiStateActions } from "../../modules/uiState";

import { route as participantDashboardRoute } from "../../pages/participant/Dashboard/";
import { route as participantAttributesRoute } from "../../pages/participant/Attributes/";
import { RootState } from "../../modules";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      display: "block",
      justifyContent: "flex-end",
    },
    icon: {
      width: 24,
      color: theme.palette.common.white,
    },
    action: {
      paddingTop: "0px !important",
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-around",
      margin: "0px auto",
      maxWidth: 1280,
    },
  });

interface OwnProps {
  openMenu?: () => void;
  closeMenu?: () => void;
  onMenu?: boolean;
}

interface StateProps {
  dailyScreenerLeft: number | undefined;
}

interface DispatchProps {
  openMobileMenu: typeof uiStateActions.showMobileMenu;
}

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithStyles<typeof styles> &
  RouteComponentProps;

class Navigation extends React.Component<Props> {
  getIconColor = (path: string): string => {
    const { location } = this.props;

    return location.pathname.includes(path) ? secondary : "#fff";
  };

  render() {
    const { classes, dailyScreenerLeft, openMobileMenu } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <NavigationButton link={participantDashboardRoute}>
            <Questionnaire
              className={classes.icon}
              fill={this.getIconColor(participantDashboardRoute)}
            />
          </NavigationButton>

          <Link to={"/participant/attributes"}>
            <IconButton id={"toAttributesPage"}>
              <Badge color="error" badgeContent={dailyScreenerLeft}>
                <Attribute
                  className={classes.icon}
                  fill={this.getIconColor(participantAttributesRoute)}
                />
              </Badge>
            </IconButton>
          </Link>
          <IconButton id={"openMenu"} onClick={openMobileMenu}>
            <Menu className={classes.icon} />
          </IconButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ attribute }: RootState) => ({
  dailyScreenerLeft: attribute.dailyScreenerLeftAmount,
});

const mapDispatchToProps = {
  openMobileMenu: uiStateActions.showMobileMenu,
};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Navigation);

const NavigationButton: React.FunctionComponent<{
  link?: string;
  openMenu?: () => void;
  closeMenu?: () => void;
}> = ({ openMenu, link, children }) => {
  if (!link) {
    return <IconButton onClick={openMenu}>{children}</IconButton>;
  }

  return (
    <Link to={link}>
      <IconButton style={{ height: "100%" }}>{children}</IconButton>
    </Link>
  );
};
