import React, {PureComponent} from "react";
import {createStyles, Typography, withStyles, WithStyles} from "@material-ui/core";
import {compose} from "recompose";

import {blueGradient, QuopinionTheme, red} from "../../../constants/Theme";
import {WithTranslation, withTranslation} from "react-i18next";
import {translations} from "../../../constants/lang/translation";
import Button from "../../../common/Layout/components/Button";
import {Cross} from "../../../assets/icon";
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {RootState} from "../../../modules";
import LoadingOverlay from "../../../common/LoadingOverlay";

interface OwnProps {
}

interface StateProps {
  isLoading: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      background: blueGradient,
      padding: theme.spacing(6),
    },
    checkBadge: {
      backgroundColor: red,
      position: "relative",
      width: 74,
      height: 74,
      borderRadius: "50%",
      marginBottom: theme.spacing(8),
    },
    check: {
      position: "absolute",
      top: "calc(50% - 18px)",
      left: "calc(50% - 18px)",
      width: 36,
      height: 36,
      fill: "#fff",
    },
    earnedMoney: {
      margin: `${theme.spacing(9)}px 0 ${theme.spacing(25)}px`,
      padding: theme.spacing(4),
      backgroundColor: theme.palette.grey[200],
      width: "50%",
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    header: {
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
      color: theme.palette.grey[400],
      marginBottom: theme.spacing(3),
    },
    icon: {
      marginRight: theme.spacing(1.5),
    },
  });

type Props = OwnProps &
  WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps &
  StateProps;

class RejectedQuestionnaire extends PureComponent<Props> {
  directToDashboard = () => {
    this.props.history.push("/dashboard");
  };

  render() {
    const {classes, t, isLoading} = this.props;

    if (isLoading) {
      return <LoadingOverlay/>;
    }

    return (
      <div className={classes.root}>
        <div className={classes.checkBadge}>
          <Cross className={classes.check}/>
        </div>
        <Typography variant="h2" color="textSecondary">
          {t(translations.questionnaire.questionnaireRejectedHeadline)}
        </Typography>
        <Button
          contained={true}
          color="secondary"
          size="big"
          fullWidth={true}
          onClick={this.directToDashboard}
        >
          {t(translations.action.toDashboard)}
        </Button>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(
  connect(({participation}: RootState) => ({
    isLoading: participation.isLoading,
  })),
  withRouter,
  withTranslation(),
  withStyles(styles)
)(RejectedQuestionnaire);
