const IndustryOptions = [
  {
    label: "Administration, Sachbearbeitung, Verwaltung",
    value: "Administration, Sachbearbeitung, Verwaltung",
  },
  { label: "Analyse, Statistik", value: "Analyse, Statistik" },
  { label: "Beratung", value: "Beratung" },
  { label: "Bildung, Erziehung", value: "Bildung, Erziehung" },
  { label: "Controlling, Planung", value: "Controlling, Planung" },
  {
    label: "Einkauf, Materialwirtschaft, Logistik",
    value: "Einkauf, Materialwirtschaft, Logistik",
  },
  { label: "Einzelhandel", value: "Einzelhandel" },
  {
    label: "Finanzen, Rechnungswesen, Controlling",
    value: "Finanzen, Rechnungswesen, Controlling",
  },
  { label: "Forschung, Lehre, Entwicklung", value: "Forschung, Lehre, Entwicklung" },
  { label: "Gastronomie", value: "Gastronomie" },
  {
    label: "Gesundheit, Medizin, Soziales, Pflege",
    value: "Gesundheit, Medizin, Soziales, Pflege",
  },
  { label: "Grafik, Design, Architektur", value: "Grafik, Design, Architektur" },
  { label: "Immobilien", value: "Immobilien" },
  { label: "Ingenieurswesen, techn. Berufe", value: "Ingenieurswesen, techn. Berufe" },
  { label: "IT, Softwareentwicklung", value: "IT, Softwareentwicklung" },
  { label: "Kundenbetreuung / Kundenservice", value: "Kundenbetreuung / Kundenservice" },
  { label: "Landwirtschaft", value: "Landwirtschaft" },
  { label: "Management, Unternehmensführung", value: "Management, Unternehmensführung" },
  { label: "Marketing, Werbung", value: "Marketing, Werbung" },
  { label: "Marktforschung", value: "Marktforschung" },
  { label: "Öffentlicher Dienst", value: "Öffentlicher Dienst" },
  { label: "Personalwesen", value: "Personalwesen" },
  {
    label: "PR, Öffentlichkeitsarbeit, Journalismus",
    value: "PR, Öffentlichkeitsarbeit, Journalismus",
  },
  { label: "Produktion, Handwerk", value: "Produktion, Handwerk" },
  { label: "Produktmanagement", value: "Produktmanagement" },
  { label: "Projektmanagement", value: "Projektmanagement" },
  { label: "Prozessplanung, Qualitätssicherung", value: "Prozessplanung, Qualitätssicherung" },
  { label: "Recht", value: "Recht" },
  { label: "Sicherheitsdienst", value: "Sicherheitsdienst" },
  { label: "Transport und Verkehr", value: "Transport und Verkehr" },
  { label: "Versicherung", value: "Versicherung" },
  { label: "Vertrieb, Handel", value: "Vertrieb, Handel" },
  { label: "Sonstiges", value: "not_set" },
];

export default IndustryOptions;
