import Client from "./Client";
import Category, { HydratedCategory, FlatCategory } from "../entities/Category";

export default class Categories {
  readonly httpClient: Client;
  readonly route = "/categories";

  constructor(httpClient: Client) {
    this.httpClient = httpClient;
  }

  // fetch all Categories
  async fetchCategories(): Promise<Category[]> {
    return (await this.httpClient.get(this.route + "/all")) as Category[];
  }

  async fetchFlattenedCategories(): Promise<FlatCategory[]> {
    const categories = await this.fetchCategories();

    const flattenCategory = (categories: FlatCategory[], category: Category): FlatCategory[] => {
      if (category.subCategories.length) {
        return [...categories, ...category.subCategories.reduce(flattenCategory, categories)];
      } else {
        delete category.subCategories;
        return [...categories, category as FlatCategory];
      }
    };

    const flattenedCategories = categories.reduce(flattenCategory, []);

    const removeDuplicates = (
      categories: FlatCategory[],
      nextCategory: FlatCategory
    ): FlatCategory[] => {
      if (!categories.find((category) => category.id === nextCategory.id)) {
        return [...categories, nextCategory];
      }
      return categories;
    };

    return flattenedCategories.reduce(removeDuplicates, []);
  }

  // fetch all Categories with contained Attributes
  async fetchHydratedCategories(): Promise<HydratedCategory[]> {
    return (await this.httpClient.get(this.route + "/all", {
      hydrate: true,
    })) as HydratedCategory[];
  }
}
