import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  createStyles,
  withStyles,
  WithStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import {
  bronze,
  gold,
  lightBlue,
  paleOrange,
  primary,
  QuopinionTheme,
  secondary,
  silver,
} from "../../../../constants/Theme";
import Survey from "../../../../entities/Survey";
import { RootState } from "../../../../modules";
import HorizontalBarDiagram from "./components/HorizontalBarDiagram";
import DataTable from "./components/DataTable";
import LineChart from "./components/LineChart";
import PieChart from "./components/PieChart";
import BarDiagram from "./components/BarDiagram";
import { EvaluatedQuestion } from "../../../../entities/SurveyResult";
import { AdditionalFieldItem, DataItem, NumberDataItem } from "./components/QuestionResultItem";
import { translations } from "../../../../constants/lang/translation";
import StackedBarDiagram from "./components/StackedBarDiagram";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
    filterBar: {
      display: "flex",
      justifyContent: "space-between",
      padding: `${theme.spacing(6)}px 0`,
    },
    hint: {
      marginBottom: theme.spacing(6),
    },
    freeTextBlockTitle: {
      color: primary,
      fontSize: 11,
      fontWeight: 600,
      textTransform: "uppercase",
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(4),
    },
    freeTextBlockSubtitle: {
      color: primary,
      fontSize: 11,
      textTransform: "uppercase",
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(4),
    },
    freeTextTable: {
      marginTop: theme.spacing(10),
    },
    freeTextTableTitle: {
      marginLeft: theme.spacing(4),
      color: theme.palette.primary.main,
    },
  });

interface OwnProps {
  relativeData: DataItem[];
  absoluteData: DataItem[];
  additionalFreeTextData: AdditionalFieldItem[];
  additionalNumberData: AdditionalFieldItem[];
  question: EvaluatedQuestion["question"];
  rankingAbsoluteData?: any[];
  rankingRelativeData?: any[];
}

interface StateProps {
  transientSurvey: Survey;
}

interface DispatchProps {}

interface State {
  selectedDisplay: string;
  selectedNumbers: string;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WithTranslation;

class QuestionResult extends PureComponent<Props, State> {
  state: State = {
    selectedDisplay: "",
    selectedNumbers: "absolute",
  };

  componentDidMount() {
    if (this.props.question.type === "stepRating") {
      this.setState({
        selectedDisplay: "line",
      });
    } else {
      this.setState({
        selectedDisplay: "bar",
      });
    }
  }

  changeDisplayOfData = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    this.setState({
      selectedDisplay: event.target.value as string,
    });
  };

  changeNumbersOfData = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    this.setState({
      selectedNumbers: event.target.value as string,
    });
  };

  getPercentageSum = (relativeData: DataItem[]) => {
    const sum = relativeData.map((dataItem) => {
      return dataItem.value;
    });
    if (sum.length > 0) {
      sum.reduce((a, b) => {
        return a + b;
      });
      return sum;
    } else {
      return 100;
    }
  };

  render() {
    const {
      classes,
      absoluteData,
      relativeData,
      t,
      question,
      additionalFreeTextData,
      additionalNumberData,
      rankingAbsoluteData,
      rankingRelativeData,
    } = this.props;

    const { selectedDisplay, selectedNumbers } = this.state;
    const COLORS = [
      primary,
      secondary,
      paleOrange,
      "#a8dadc",
      lightBlue,
      "#5b85aa",
      silver,
      "#fcde9c",
      "#6d7278",
      "#e8e8e8",
      gold,
      bronze,
    ]; //5 more to seperate stackbar

    const data = selectedNumbers === "relative" ? relativeData : absoluteData;

    const rankingData =
      selectedNumbers === "relative" && question.type === "ranking"
        ? rankingRelativeData
        : rankingAbsoluteData;

    const { scale, labels } = question.body;

    const getSortedNumberData = () => {
      const dataConverted = data.map((item) => {
        return {
          name: parseInt(item.name, 10),
          value: item.value,
        };
      });
      const sortedNumberData = dataConverted.sort((a, b) => a.name - b.name);
      return sortedNumberData;
    };

    const getSortedStringData = (numberData: NumberDataItem[]) => {
      const sortedStrings = numberData.map((item: NumberDataItem, index) => {
        return {
          name: item.name.toString(),
          value: item.value,
          key: index,
        };
      });
      return sortedStrings;
    };

    const sortedData = data;
    return (
      <div className={classes.root}>
        {question.type !== "freeText" &&
          question.type !== "instruction" &&
          question.type !== "password" && (
            <>
              <div className={classes.filterBar}>
                <Select
                  value={selectedDisplay}
                  displayEmpty={true}
                  onChange={this.changeDisplayOfData}
                  disabled={
                    question.type === "stepRating" ||
                    question.type === "starRating" ||
                    question.type === "ranking"
                  }
                >
                  <MenuItem value="bar"> Säulendiagramm</MenuItem>
                  <MenuItem value="verticalBar">Balkendiagramm</MenuItem>
                  <MenuItem value="circle">Kreisdiagramm</MenuItem>
                  {question.type === "stepRating" && (
                    <MenuItem value="line">Liniendiagramm</MenuItem>
                  )}
                </Select>
                <Select
                  value={selectedNumbers}
                  onChange={this.changeNumbersOfData}
                  displayEmpty={true}
                >
                  <MenuItem value="absolute">Absolute Zahlen</MenuItem>
                  <MenuItem value="relative">Relative Zahlen</MenuItem>
                </Select>
              </div>
              {question.type === "multipleChoice" && (
                <Typography variant="subtitle1" color="secondary" className={classes.hint}>
                  Hinweis: Mehrfachauswahl durch den Teilnehmer möglich
                </Typography>
              )}
              {selectedDisplay === "bar" && question.type === "starRating" ? (
                <BarDiagram data={getSortedStringData(getSortedNumberData())} colors={COLORS} />
              ) : selectedDisplay === "bar" &&
                question.type !== "stepRating" &&
                question.type !== "ranking" ? (
                <BarDiagram data={sortedData} colors={COLORS} />
              ) : (
                rankingData && <StackedBarDiagram data={rankingData} colors={COLORS} />
              )}
              {selectedDisplay === "verticalBar" && (
                <HorizontalBarDiagram data={sortedData} colors={COLORS} />
              )}
              {selectedDisplay === "circle" && <PieChart data={sortedData} colors={COLORS} />}
              {question.type === "stepRating" && (
                <>
                  <LineChart data={getSortedNumberData()} scale={scale} labels={labels} />
                  <Typography variant="subtitle1" color="secondary" className={classes.hint}>
                    {t(translations.questionnaire.questionTypes.stepRating)}: {scale.min} ={" "}
                    {labels.left}, {scale.max} = {labels.right}
                  </Typography>
                </>
              )}
              {selectedNumbers === "relative" && (
                <Typography variant="subtitle1" color="secondary" className={classes.hint}>
                  {t(translations.pages.surveyResults.inPercent)}
                </Typography>
              )}
              {this.getPercentageSum(relativeData) !== 100 && selectedNumbers === "relative" && (
                <Typography variant="subtitle1" color="secondary" className={classes.hint}>
                  {t(translations.pages.surveyResults.deviationFrom100)}
                </Typography>
              )}
              {question.type === "stepRating" || question.type === "starRating" ? (
                <DataTable
                  data={getSortedStringData(getSortedNumberData())}
                  relativeData={selectedNumbers === "relative"}
                />
              ) : (
                question.type !== "ranking" && (
                  <DataTable data={sortedData} relativeData={selectedNumbers === "relative"} />
                )
              )}
            </>
          )}
        {(question.type === "freeText" || question.type === "password") && (
          <DataTable data={data.sort((a, b) => b.value - a.value)} forFreetext={true} />
        )}
        {/*  additionalFreeTextAnswers*/}
        {(question.type === "multipleChoice" || question.type === "singleChoice") &&
        additionalFreeTextData !== null &&
        additionalFreeTextData !== undefined &&
        additionalFreeTextData.length ? (
          <>
            <Typography className={classes.freeTextBlockTitle}>
              {t(translations.pages.surveyResults.freeTexts)}
            </Typography>
            {additionalFreeTextData.map((item: AdditionalFieldItem, index) => {
              return (
                <div className={classes.freeTextTable} id={index.toString(2)}>
                  <Typography className={classes.freeTextTableTitle}>
                    {`${t(translations.pages.surveyResults.answerOption)}: ${
                      item.answerOptionName
                    }`}
                  </Typography>
                  <DataTable additionalFreeTextData={item.data.sort((a, b) => b.value - a.value)} />
                </div>
              );
            })}
          </>
        ) : null}
        {/*  additionalNumberAnswers*/}
        {(question.type === "multipleChoice" || question.type === "singleChoice") &&
        additionalNumberData !== null &&
        additionalNumberData !== undefined &&
        additionalNumberData.length ? (
          <>
            <Typography className={classes.freeTextBlockTitle}>
              {t(translations.pages.surveyResults.numericalAnswers)}
            </Typography>
            {additionalNumberData.map((item: AdditionalFieldItem, index) => {
              return (
                <div className={classes.freeTextTable} id={index.toString(2)}>
                  <Typography className={classes.freeTextTableTitle}>
                    {`${t(translations.pages.surveyResults.answerOption)}: ${
                      item.answerOptionName
                    }`}
                  </Typography>
                  <DataTable additionalNumberData={item.data.sort((a, b) => b.value - a.value)} />
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ survey }: RootState) => {
  return {
    transientSurvey: survey,
  };
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect<{}, DispatchProps, OwnProps, RootState>(mapStateToProps)
)(QuestionResult);
