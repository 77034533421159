import AnswerOption from "./AnswerOption";
import { ScreenerValueType } from "./Screener";
import Question from "./Question";
import { FlatCategory } from "./Category";
import ID from "./ID";
import {v4 as uuidv4} from "uuid";

export type AttributeType = "SCREENER" | "DEFAULT";

export default class Attribute {
  readonly id: ID;
  categories: FlatCategory[] | string[];
  createdAt: Date;
  description: string;
  level: ScreenerValueType;
  priority: number;
  type: AttributeType;
  name: string;
  question: Question;
  enabled: boolean;
  usageCount: number;
  organizationId?: ID;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.categories = obj.categories || [];
    this.createdAt = obj.createdAt || null;
    this.description = obj.description || "";
    this.level = obj.level || "BRONZE";
    this.name = obj.name || "";
    this.priority =
      typeof obj.priority === "number"
        ? obj.priority
        : obj.priority
        ? parseInt(obj.priority, 10)
        : 100;
    this.type = obj.type || "SCREENER";
    this.question = new Question(obj.question);
    this.enabled = typeof obj.enabled === "boolean" ? obj.enabled : true;
    this.usageCount = obj.usageCount || 0;
    this.organizationId = obj.organizationId && obj.organizationId;
  }

  toDataJson() {
    const data = {
      id: this.id,
      categories: (this.categories as any[]).map((category: FlatCategory | string) =>
        typeof category === "string" ? category : (category as FlatCategory).id
      ),
      name: this.name,
      organizationId: this.organizationId && this.organizationId,
      description: this.description,
      level: this.level,
      priority: this.priority,
      type: this.type,
      question: this.question.toDataJson(),
      //
      panelOptions: {},
    };

    return data;
  }
}

export class AttributeAnswer {
  readonly id: ID;
  readonly text: string;
  readonly answers: AnswerOption[];

  constructor(obj: any = {}) {
  //@ts-ignore
    this.id = obj.id || uuidv4();
    this.text = obj.text;
    this.answers = obj.answers || [new AnswerOption()];
  }
}
