import React, { PureComponent } from "react";
import {
  FormControl,
  FormControlLabel,
  WithStyles,
  createStyles,
  withStyles,
  Typography,
} from "@material-ui/core";
import { FormControlProps } from "@material-ui/core/FormControl";
import CheckboxElement from "./Checkbox";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";

interface OwnProps extends FormControlProps {
  label: React.ReactNode;
  answerValue: string;
  checked: boolean;
  onChange: (arg1?: any) => void;
  value: boolean;
  helperText: string;
  required: boolean;
  smallLabel?: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    checkbox: {
      padding: `0 0 0 ${theme.spacing(1)}px`,
      marginTop: theme.spacing(8),
      color: theme.palette.grey[400],
      backgroundColor: "transparent",
      fontWeight: 400,
      "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
        width: "100%",
      },
    },
    controlLabel: {
      padding: `0 ${theme.spacing(2)}px`,
      fontSize: (props: OwnProps) => (props.smallLabel ? "16px" : "inherit"),
    },
    root: {
      "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
        width: "100%",
      },
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class FormCheckbox extends PureComponent<Props> {
  render() {
    const {
      label,
      classes,
      error,
      answerValue,
      checked,
      onChange,
      helperText,
      required,
      smallLabel,
      ...props
    } = this.props;
    return (
      <FormControl {...props} required={required} error={error} className={classes.root}>
        <FormControlLabel
          label={""}
          control={
            <CheckboxElement
              label={label}
              answerValue={answerValue}
              checked={checked}
              onChange={onChange}
              key="data"
              className={classes.checkbox}
              smallLabel={smallLabel}
              headerAlignItems={"flex-start"}
            />
          }
          className={classes.controlLabel}
        />
        {helperText && <Typography color="error">{helperText}</Typography>}
      </FormControl>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(FormCheckbox);
