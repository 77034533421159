import React, { PureComponent } from "react";
import { QuopinionTheme } from "../../../constants/Theme";
import {
  createStyles,
  FormControl,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { translations } from "../../../constants/lang/translation";
import { Field, Formik } from "formik";
import Button from "../../../common/Layout/components/Button";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose, shallowEqual } from "recompose";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { RootState } from "../../../modules";
import { DataGroup } from "./DataGroup";
import { AttributesList, updateCurrentUserAttributesAndPersonalData } from "../../../modules/user";
import RadioElement from "../../../common/Preview/components/RadioButton";
import { validationSchema } from "./profileFormValidationSchema";
import User, { PersonalData } from "../../../entities/User";
import AttributeSelect from "../../../common/Layout/components/AttributeSelect";
import { AnsweredChoiceAttribute } from "../../../services/Accounts";
import { isValid } from "date-fns";
import { panelConfigAttributeIds } from "../../../modules/util";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(6),
      width: "100%",
    },
    buttonLine: {
      display: "flex",
      justifyContent: "space-between",
    },
    radioWithText: {
      display: "flex",
      alignItems: "center",
      color: "#313131",
      backgroundColor: "#fff",
      margin: `0 ${theme.spacing(4)}px ${theme.spacing(0.5)}px 0`,
      padding: `${theme.spacing(3)}px 0`,
      width: "100%",
      "@media(max-width: 600px)": {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    categoryHeadline: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(8),
      color: "#313131",
    },
    ageHint: {},
    wrapper: {
      position: "relative",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    ageInput: {},
    categoryHeadlineForDropdown: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(8),
      color: "#313131",
    },
    bigInput: {
      width: "100%",
    },
    radio: {
      color: "#313131",
    },
  });

interface OwnProps {
  handleViewMode: () => void;
}

interface StateProps {
  currentUser: User;
  personalData: PersonalData;
  attributes: AttributesList;
  registrationConfiguration: [];
}

interface DispatchProps {
  updateCurrentUserAttributesAndPersonalData: (
    personalData: PersonalData,
    answeredAttributes: AttributesList
  ) => Promise<void>;
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation & WithStyles<typeof styles>;

class ProfileValues extends PureComponent<Props> {
  readonly usableForStudies = {
    answerOptions: [
      {
        label: this.props.t(
          translations.register.participant.data.usableForQuotasStudies.value.yes
        ),
        value: "YES",
        type: "radio",
      },
      {
        label: this.props.t(translations.register.participant.data.usableForQuotasStudies.value.no),
        value: "NO",
        type: "radio",
      },
      {
        label: this.props.t(
          translations.register.participant.data.usableForQuotasStudies.value.alreadyTn
        ),
        value: "ALREADY_TN",
        type: "radio",
      },
    ],
  };
  readonly initialValues = {
    personalData: {
      firstName: this.props.personalData.firstName ? this.props.personalData.firstName : "",
      lastName: this.props.personalData.lastName ? this.props.personalData.lastName : "",
      zipCode: this.props.personalData.zipCode ? this.props.personalData.zipCode : "",
      city: this.props.personalData.city ? this.props.personalData.city : "",
      countryCode: "DEU",
      birthdayYear: this.props.personalData.birthdayYear
        ? this.props.personalData.birthdayYear
        : 1990,
      birthdayMonth: this.props.personalData.birthdayMonth
        ? this.props.personalData.birthdayMonth
        : 5,
      birthdayDayOfMonth: this.props.personalData.birthdayDayOfMonth
        ? this.props.personalData.birthdayDayOfMonth
        : 23,
      birthdayDate: new Date(
        this.props.personalData.birthdayYear,
        this.props.personalData.birthdayMonth - 1,
        this.props.personalData.birthdayDayOfMonth
      ),
      usableForQuotasStudies: this.props.personalData.usableForQuotasStudies,
    },
    gender: (this.props.attributes.find(
      (attribute) => attribute.attributeId === panelConfigAttributeIds.gender
    ) as AnsweredChoiceAttribute) || {
      attributeId: "",
      answer: { questionId: "", selectedOptions: [], type: "choice" },
    },
    household: (this.props.attributes.find(
      (attribute) => attribute.attributeId === panelConfigAttributeIds.household
    ) as AnsweredChoiceAttribute) || {
      attributeId: "",
      answer: { questionId: "", selectedOptions: [], type: "choice" },
    },
    income: (this.props.attributes.find(
      (attribute) => attribute.attributeId === panelConfigAttributeIds.income
    ) as AnsweredChoiceAttribute) || {
      attributeId: "",
      answer: { questionId: "", selectedOptions: [], type: "choice" },
    },
    education: (this.props.attributes.find(
      (attribute) => attribute.attributeId === panelConfigAttributeIds.education
    ) as AnsweredChoiceAttribute) || {
      attributeId: "",
      answer: { questionId: "", selectedOptions: [], type: "choice" },
    },
    familyStatus: (this.props.attributes.find(
      (attribute) => attribute.attributeId === panelConfigAttributeIds.family
    ) as AnsweredChoiceAttribute) || {
      attributeId: "",
      answer: { questionId: "", selectedOptions: [], type: "choice" },
    },
    children: (this.props.attributes.find(
      (attribute) => attribute.attributeId === panelConfigAttributeIds.children
    ) as AnsweredChoiceAttribute) || {
      attributeId: "",
      answer: { questionId: "", selectedOptions: [] },
    },
  };

  render() {
    const { classes, t, handleViewMode, updateCurrentUserAttributesAndPersonalData } = this.props;
    return (
      <div>
        <Formik
          initialValues={this.initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            const { personalData, ...answeredAttributes } = values;
            //attention: email should not be part of attributes as then BE does not recognize it, separate change
            const attributes = Object.values(answeredAttributes);
            handleViewMode();
            //attention: email is not part of attributes and should not be added here
            updateCurrentUserAttributesAndPersonalData(personalData, attributes).then(() => {
              setSubmitting(false);
            });
          }}
          onReset={handleViewMode}
        >
          {(formikProps) => {
            const {
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
            } = formikProps;

            return (
              <form onSubmit={handleSubmit}>
                {/*so far as no action changing new email field*/}
                {/*email field does not be part of attributes, thus different modeling, when edit implementation*/}
                {/*   <DataGroup headline={t(translations.participant.profile.loginData)}>
                   E-Mail*
                  <label>{t(translations.participant.profile.edit.email)}</label>
                  <TextField
                    name={"email"}
                    required={true}
                    className={classes.input}
                    placeholder={t(translations.participant.profile.edit.email)}
                    value={values.email}
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    error={touched.email && Boolean(errors.email)}
                    helperText={
                      errors.email && touched.email ? (
                        <Typography variant="body1">{errors.email}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                </DataGroup>*/}

                <DataGroup
                  headline={t(translations.participant.profile.personalData)}
                  key={"personal data"}
                >
                  {/* first name */}
                  <Typography variant="body2" className={classes.categoryHeadline}>
                    Vorname
                  </Typography>
                  <TextField
                    key={"personalData.firstName"}
                    className={classes.bigInput}
                    placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                    name="personalData.firstName"
                    value={values.personalData.firstName}
                    onBlur={handleBlur("personalData.firstName")}
                    onChange={handleChange("personalData.firstName")}
                    required={true}
                    helperText={
                      errors.personalData &&
                      errors.personalData.firstName &&
                      touched.personalData &&
                      touched.personalData.firstName ? (
                        <Typography variant="body1" component="span">
                          {errors.personalData.firstName}
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                    error={
                      Boolean(errors.personalData?.firstName) &&
                      Boolean(touched.personalData?.firstName)
                    }
                  />
                  {/* last name */}
                  <Typography
                    variant="body2"
                    className={classes.categoryHeadline}
                    key={"title-lastName"}
                  >
                    Nachname
                  </Typography>
                  <TextField
                    key={"personalData.lastName"}
                    className={classes.bigInput}
                    placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                    name="personalData.lastName"
                    value={values.personalData.lastName}
                    onBlur={handleBlur("personalData.lastName")}
                    onChange={handleChange("personalData.lastName")}
                    required={true}
                    helperText={
                      errors.personalData &&
                      errors.personalData.lastName &&
                      touched.personalData &&
                      touched.personalData.lastName ? (
                        <Typography variant="body1" component="span">
                          {errors.personalData.lastName}
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                    error={
                      Boolean(errors.personalData?.lastName) &&
                      Boolean(touched.personalData?.lastName)
                    }
                  />

                  {/* zip code */}
                  <Typography
                    variant="body2"
                    className={classes.categoryHeadline}
                    key={"title-zipCode"}
                  >
                    Postleitzahl
                  </Typography>
                  <TextField
                    key={"personalData.zipCode"}
                    className={classes.bigInput}
                    placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                    name="personalData.zipCode"
                    value={values.personalData.zipCode}
                    onBlur={handleBlur("personalData.zipCode")}
                    onChange={handleChange("personalData.zipCode")}
                    required={true}
                    helperText={
                      errors.personalData?.zipCode && touched.personalData?.zipCode ? (
                        <Typography variant="body1" component="span">
                          {errors.personalData.zipCode}
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                    error={
                      Boolean(errors.personalData?.zipCode) &&
                      Boolean(touched.personalData?.zipCode)
                    }
                  />

                  {/* last name */}
                  <Typography
                    variant="body2"
                    className={classes.categoryHeadline}
                    title={"title-city"}
                  >
                    Wohnort
                  </Typography>
                  <TextField
                    key={"city"}
                    className={classes.bigInput}
                    placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                    name="personalData.city"
                    value={values.personalData.city}
                    onBlur={handleBlur("personalData.city")}
                    onChange={handleChange("personalData.city")}
                    required={true}
                    helperText={
                      errors.personalData?.city && touched.personalData?.city ? (
                        <Typography variant="body1" component="span">
                          {errors.personalData.city}
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                    error={
                      Boolean(errors.personalData?.city) && Boolean(touched.personalData?.city)
                    }
                  />

                  <>
                    <Typography
                      variant="body2"
                      className={classes.categoryHeadline}
                      key={"title-birthday-date"}
                    >
                      Geburtsdatum
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar={true}
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date picker inline"
                        value={values.personalData.birthdayDate}
                        onChange={(date, value) => {
                          if (!isValid(date) && value) {
                            const [day, month, year] = value.split(".");
                            setFieldValue("personalData.birthdayDayOfMonth", parseInt(day, 10));
                            setFieldValue("personalData.birthdayMonth", parseInt(month, 10));
                            setFieldValue("personalData.birthdayYear", parseInt(year, 10));
                          } else {
                            setFieldValue(
                              "personalData.birthdayDayOfMonth",
                              date && date.getDate()
                            );
                            setFieldValue(
                              "personalData.birthdayMonth",
                              date && date.getMonth() + 1
                            );
                            setFieldValue("personalData.birthdayYear", date && date.getFullYear());
                            setFieldValue("personalData.birthdayDate", date && new Date(date));
                          }
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </>

                  <>
                    <Typography variant="body2" className={classes.categoryHeadline}>
                      {t(translations.register.participant.data.usableForQuotasStudies.shortTitle)}
                    </Typography>
                    <Typography variant="body2" className={classes.radioWithText}>
                      {t(translations.register.participant.data.usableForQuotasStudies.info)}
                    </Typography>
                    <FormControl component="fieldset" required={true} style={{ width: "100%" }}>
                      <RadioGroup
                        aria-label=".value"
                        name="personalData.usableForQuotasStudies"
                        value={values.personalData.usableForQuotasStudies}
                        onChange={handleChange("personalData.usableForQuotasStudies")}
                      >
                        {this.usableForStudies.answerOptions.map((answer) => (
                          <>
                            {answer.type === "radio" && (
                              <RadioElement
                                name="usableForQuotasStudies"
                                onChange={() => {
                                  setFieldValue(
                                    "personalData.usableForQuotasStudies",
                                    answer.value
                                  );
                                }}
                                value={answer.value}
                                key={answer.value}
                                label={answer.label}
                                className={classes.radio}
                                control={<Radio />}
                              />
                            )}
                          </>
                        ))}
                      </RadioGroup>
                      {touched.personalData?.usableForQuotasStudies &&
                        errors.personalData?.usableForQuotasStudies && (
                          <Typography variant="body1" component="span" color="error">
                            {errors.personalData.usableForQuotasStudies}
                          </Typography>
                        )}
                    </FormControl>
                  </>
                </DataGroup>

                <DataGroup
                  headline={t(translations.participant.profile.furtherData)}
                  key={"attributes-group"}
                >
                  {this.props.registrationConfiguration.map((attribute: any) => {
                    if (attribute.name === "Geschlecht") {
                      const { question } = attribute;
                      const setAnswer = this.props.attributes.find(
                        (item) => item.attributeId === attribute.id
                      ) as AnsweredChoiceAttribute;

                      if (!Boolean(values.gender.attributeId)) {
                        setFieldValue("gender", setAnswer);
                      }

                      return (
                        <>
                          <FormControl
                            component="fieldset"
                            required={true}
                            style={{ width: "100%" }}
                            key={attribute.id}
                          >
                            <RadioGroup
                              aria-label="gender"
                              name="gender"
                              value={values.gender.answer!.selectedOptions[0]}
                              onChange={(ev, value) => {
                                setFieldValue("gender", {
                                  attributeId: attribute.id,
                                  answer: {
                                    questionId: question.id,
                                    selectedOptions: [value],
                                    type: "choice",
                                  },
                                });
                              }}
                            >
                              {question.answerOptions.map((option: any) => (
                                <RadioElement
                                  name="genderElement"
                                  value={option.id}
                                  key={option.id}
                                  label={option.label}
                                  className={classes.radio}
                                  control={<Radio />}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                          {errors.gender && (touched.gender ? `${errors.gender.answer}` : "")}
                        </>
                      );
                    }

                    if (attribute.name === "Haushaltsgröße") {
                      const { question } = attribute;
                      const setAnswer = this.props.attributes.find(
                        (item) => item.attributeId === attribute.id
                      );

                      if (!Boolean(values.household.attributeId)) {
                        setFieldValue("household", setAnswer);
                      }

                      return (
                        <>
                          {/* household */}
                          <Typography variant="body2" className={classes.categoryHeadline}>
                            {attribute.name}
                          </Typography>
                          <Field name="household">
                            {({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.household.answer.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) =>
                                  setFieldValue("household", {
                                    attributeId: attribute.id,
                                    answer: {
                                      questionId: question.id,
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  })
                                }
                                onBlur={handleBlur("household.answer")}
                                required={true}
                                multiple={false}
                                error={touched.household && Boolean(errors.household)}
                              />
                            )}
                          </Field>
                          {errors.household &&
                            (touched.household ? (
                              <Typography color="error" component="span">
                                {errors.household.answer!.selectedOptions}
                              </Typography>
                            ) : (
                              ""
                            ))}
                        </>
                      );
                    }

                    if (attribute.name === "Haushaltseinkommen") {
                      const { question } = attribute;
                      const setAnswer = this.props.attributes.find(
                        (item) => item.attributeId === attribute.id
                      );

                      if (!Boolean(values.income.attributeId)) {
                        setFieldValue("income", setAnswer);
                      }
                      return (
                        <div key={attribute.id}>
                          <Typography
                            variant="body2"
                            className={classes.categoryHeadlineForDropdown}
                          >
                            {question.text}
                          </Typography>
                          <Field name="income">
                            {({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.income.answer!.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) => {
                                  setFieldValue("income", {
                                    attributeId: attribute.id,
                                    answer: {
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  });
                                }}
                                onBlur={handleBlur("income")}
                                required={true}
                                multiple={false}
                                disabled={isSubmitting}
                                error={touched.income && Boolean(errors.income)}
                              />
                            )}
                          </Field>
                          {errors.income &&
                            (touched.income
                              ? `${errors.income.answer && errors.income.answer.selectedOptions}`
                              : "")}
                        </div>
                      );
                    }

                    if (attribute.name === "Bildungsstatus") {
                      const { question } = attribute;

                      const setAnswer = this.props.attributes.find(
                        (item) => item.attributeId === attribute.id
                      );

                      if (!Boolean(values.education.attributeId)) {
                        setFieldValue("education", setAnswer);
                      }

                      return (
                        <div key={attribute.id}>
                          {/* education */}
                          <Typography
                            variant="body2"
                            className={classes.categoryHeadlineForDropdown}
                          >
                            {question.text}
                          </Typography>
                          <Field name="education">
                            {({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.education.answer.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) =>
                                  setFieldValue("education", {
                                    attributeId: attribute.id,
                                    answer: {
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  })
                                }
                                onBlur={handleBlur("education")}
                                required={true}
                                multiple={false}
                                disabled={isSubmitting}
                                error={touched.education && Boolean(errors.education)}
                              />
                            )}
                          </Field>
                          {errors.education && (
                            <Typography color="error" component="span">
                              {touched.education
                                ? `${errors.education.answer!.selectedOptions}`
                                : ""}
                            </Typography>
                          )}
                        </div>
                      );
                    }

                    if (attribute.name === "Familienstand") {
                      const { question } = attribute;

                      const setAnswer = this.props.attributes.find(
                        (item) => item.attributeId === attribute.id
                      );

                      if (!Boolean(values.familyStatus.attributeId)) {
                        setFieldValue("familyStatus", setAnswer);
                      }
                      return (
                        <div key={attribute.id}>
                          {/* family status */}
                          <Typography
                            variant="body2"
                            className={classes.categoryHeadlineForDropdown}
                          >
                            {question.text}
                          </Typography>
                          <Field name="familyStatus">
                            {({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.familyStatus.answer!.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) =>
                                  setFieldValue("familyStatus", {
                                    attributeId: attribute.id,
                                    answer: {
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  })
                                }
                                onBlur={handleBlur("familyStatus")}
                                required={true}
                                multiple={false}
                                disabled={isSubmitting}
                                error={touched.familyStatus && Boolean(errors.familyStatus)}
                              />
                            )}
                          </Field>
                          {errors.familyStatus && (
                            <Typography color="error" component="span">
                              {touched.familyStatus ? `${errors.familyStatus.answer}` : ""}
                            </Typography>
                          )}
                        </div>
                      );
                    }

                    if (attribute.name === "Kinder") {
                      const { question } = attribute;

                      const setAnswer = this.props.attributes.find(
                        (item) => item.attributeId === attribute.id
                      );

                      if (!Boolean(values.children.attributeId)) {
                        setFieldValue("children", setAnswer);
                      }

                      return (
                        <div key={attribute.id}>
                          {/* family status */}
                          <Typography
                            variant="body2"
                            className={classes.categoryHeadlineForDropdown}
                          >
                            {question.text} {t(translations.summary.panel.under18)}
                          </Typography>
                          <Field name="children">
                            {({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.children.answer.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) =>
                                  setFieldValue("children", {
                                    attributeId: attribute.id,
                                    answer: {
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  })
                                }
                                onBlur={handleBlur("children")}
                                required={true}
                                multiple={false}
                                disabled={isSubmitting}
                                error={touched.familyStatus && Boolean(errors.children)}
                              />
                            )}
                          </Field>
                          {errors.familyStatus && (
                            <Typography color="error" component="span">
                              {touched.familyStatus ? `${errors.familyStatus.answer}` : ""}
                            </Typography>
                          )}
                        </div>
                      );
                    }

                    return null;
                  })}
                </DataGroup>

                <div className={classes.buttonLine} key={"button-line"}>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={handleReset}
                    type="button"
                    key={"button-cancel"}
                  >
                    {t(translations.action.cancel)}
                  </Button>
                  <Button
                    contained={true}
                    size="small"
                    color={"secondary"}
                    onClick={handleSubmit}
                    disabled={shallowEqual(values, this.initialValues) || isSubmitting}
                    type="submit"
                    key={"button-submit"}
                  >
                    {t(translations.action.save)}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateCurrentUserAttributesAndPersonalData,
};

const mapStateToProps = ({ session, user }: RootState) => ({
  currentUser: user.current,
  personalData: user.personalData,
  attributes: user.attributes.answeredAttributes,
  registrationConfiguration: session.registrationConfiguration && session.registrationConfiguration,
});

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ProfileValues);
