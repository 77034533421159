import React, { PureComponent } from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../../constants/Theme";
import { compose } from "recompose";
import Card from "../../../../common/Layout/components/Card";
import { WithTranslation, withTranslation } from "react-i18next";
import OrderSummaryItem from "./OrderSummaryItem";
import { translations } from "../../../../constants/lang/translation";
import Survey, { SurveyInformation, SurveyStatus } from "../../../../entities/Survey";
import { connect } from "react-redux";
import { RootState } from "../../../../modules";

interface OwnProps {}

interface DispatchProps {}

interface StateProps {
  survey: Survey;
  researcherSurveys: SurveyInformation[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    header: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
      textTransform: "uppercase",
    },
    card: {
      width: "100%",
    },
  });

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles> & WithTranslation;

class OrderSummary extends PureComponent<Props> {
  render() {
    const { classes, t, researcherSurveys } = this.props;
    return (
      <Card className={classes.card}>
        <div className={classes.header}>
          {t(translations.pages.dashboard.researcher.account.orderHeadline)}
        </div>
        {researcherSurveys
          .filter((survey) => survey.status === SurveyStatus.ENDED)
          .map((survey: SurveyInformation) => (
            <OrderSummaryItem key={survey.id} survey={survey} />
          ))}
      </Card>
    );
  }
}

const mapStateToProps = ({ survey, user }: RootState) => ({
  survey,
  researcherSurveys: user.researcherSurveys,
});

const mapDispatchToProps = {};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    // @ts-ignore
    mapStateToProps,
    mapDispatchToProps
  )
)(OrderSummary);
