import { Dispatch } from "redux";
import { onlineStatus, checkOfflineError } from "./error";
import Region from "../entities/Region";
import { httpClient } from "../services/httpClient";
import i18next from "i18next";
import { translations } from "../constants/lang/translation";
import IndividualAttribute from "../entities/IndividualAttribute";
import { createAsyncAction, getType, ActionType } from "typesafe-actions";
import Services from "../services/Services";
import Attribute from "../entities/Attribute";

const DELETE_ATTRIBUTE = "attribute/DELETE_ATTRIBUTE";
const NEGATE_ATTRIBUTE_VALUE = "attribute/NEGATE_ATTRIBUTE_VALUE";
const AFFIRM_ATTRIBUTE_VALUE = "attribute/AFFIRM_ATTIRBUTE_VALUE";
const SEND_MODIFICATIONS = "attribute/SEND_MODIFICATIONS";

interface AttributeState {
  region: Region[];
  language: string[];
  educationStatus: string[];
  familyStatus: string[];
  children: [];
  sentModification: boolean;
  deleted: boolean;
  value: string;
  attributes: IndividualAttribute[];
  isLoading: boolean;
}

const defaultState: AttributeState = {
  region: [],
  language: [],
  educationStatus: [],
  familyStatus: [],
  children: [],
  sentModification: false,
  deleted: false,
  value: "",
  attributes: [],
  isLoading: true,
};

const actions = {
  getIndividualAttributes: createAsyncAction(
    "attribute/GET_INDIVIDUAL_ATTRIBUTES_REQUEST",
    "attribute/GET_INDIVIDUAL_ATTRIBUTES_SUCCESS",
    "attribute/GET_INDIVIDUAL_ATTRIBUTES_FAILURE"
  )<void, IndividualAttribute[], void>(),
};

interface AttributeAction {
  type: string;
  pathname: string;
}

export const deleteAttribute = () => ({
  type: DELETE_ATTRIBUTE,
});

export const affirmAttributeValue = () => ({
  type: AFFIRM_ATTRIBUTE_VALUE,
});

export const negateAttributeValue = () => ({
  type: NEGATE_ATTRIBUTE_VALUE,
});

export const sendModifications = () => ({
  type: SEND_MODIFICATIONS,
});

export const removeAttribute = () => {
  return async (dispatch: Dispatch) => {
    try {
      //ToDo implement deleteAttribut which may throw offline error when BE
      await fetch("https://httpbin.org/get"); //fetch only for testing online/offline connection as no BE so far
      dispatch(deleteAttribute());
      dispatch(onlineStatus());
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while requesting questionnaire.");
    }
  };
};

export const setAttributeToTrue = () => {
  return async (dispatch: Dispatch) => {
    try {
      await fetch("https://httpbin.org/get");
      dispatch(affirmAttributeValue());
      dispatch(onlineStatus());
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while modifying own data.");
    }
  };
};

export const setAttributeToFalse = () => {
  return async (dispatch: Dispatch) => {
    try {
      await fetch("https://httpbin.org/get");
      dispatch(negateAttributeValue());
      dispatch(onlineStatus());
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while modifying own data.");
    }
  };
};

export const saveAllModifications = () => {
  return async (dispatch: Dispatch) => {
    try {
      await fetch("https://httpbin.org/get");
      dispatch(sendModifications());
      dispatch(onlineStatus());
      console.log("all modifications have been saved");
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while modifying own data.");
    }
  };
};

/**
 * this is no action thunk - this is just a helper that fetches regions
 */
export const getRegions = async (): Promise<Region[]> => {
  try {
    const result = await httpClient().get("/regions/");
    if (result.data instanceof Array) {
      return result.data.map((region) => new Region(region));
    }
    return [];
  } catch (error) {
    switch (error.response.status) {
      case 413:
        throw new Error(i18next.t(translations.questionnaire.uploadErrors[413]));
      default:
        throw new Error(i18next.t(translations.questionnaire.uploadErrors.default));
    }
  }
};

export const getIndividualAttributes = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getIndividualAttributes.request());
      const result = await Services.attributes.fetchAllAttributes({ type: "ALL" });

      const individualAttributesFromResult = result.filter(
        (attribute: Attribute) => attribute.level !== "BASIC"
      );
      const individualAttributes = individualAttributesFromResult.map(
        (attribute: Attribute) => new IndividualAttribute(attribute)
      );

      dispatch(actions.getIndividualAttributes.success(individualAttributes));
      return Promise.resolve();
    } catch (error) {
      switch (error.response.status) {
        default:
          return Promise.reject(
            new Error(i18next.t(translations.questionnaire.uploadErrors.default))
          );
      }
    }
  };
};

export type Action = ActionType<typeof actions> & AttributeAction;

export const reducer = (state: AttributeState = defaultState, action: Action): AttributeState => {
  switch (action.type) {
    case getType(actions.getIndividualAttributes.request):
      return { ...state, isLoading: true };
    case getType(actions.getIndividualAttributes.success):
      return { ...state, isLoading: false, attributes: action.payload };
    default:
      return state;
  }
};
