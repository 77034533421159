import React from "react";
import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";
import { Typography, createStyles, withStyles, WithStyles } from "@material-ui/core";
import TextColumn from "./TextColumn";
import { primary, QuopinionTheme } from "../../constants/Theme";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      padding: `${theme.spacing(12)}px ${theme.spacing(4)}px`,
      "@media(min-width: 600px)": {
        padding: `${theme.spacing(14)}px ${theme.spacing(10)}px`,
      },
      backgroundColor: (props: OwnProps) => props.backgroundColor,
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
      "@media (min-width: 600px)": {
        flexDirection: "row",
      },
    },
    headline: {
      color: primary,
      display: "flex",
      textAlign: "unset",
      "@media(min-width:600px)": {
        fontSize: "32px",
        lineHeight: 1.25,
        textAlign: "center",
        justifyContent: "center",
      },
    },
    videoWrapper: {
      display: "flex",
      alignItems: "flex-start",
      "@media(min-width:600px)": {
        display: "block",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    videoItem: {
      width: 163,
      height: 92,
      "@media (min-width:310px)": {
        width: 280,
        height: 160,
      },
      "@media (min-width: 400px)": {
        width: 355,
        height: 200,
      },
      "@media (min-width: 600px)": {
        width: 144,
        height: 80,
      },
      "@media (min-width: 768px)": {
        width: 163,
        height: 92,
      },
      "@media (min-width: 950px)": {
        width: 267,
        height: 152,
      },
    },
  });

interface OwnProps {
  backgroundColor: string;
  posterImages?: string[];
  videos: string[];
  textObj: {
    title: string;
    item1: { title: string; description: string };
    item2: { title: string; description: string };
    item3: { title: string; description: string };
  };
}

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

const TargetgroupOverviewItem: React.FC<Props> = ({ classes, videos, posterImages, textObj }) => {
  const videoTag = (video: string, posterImage?: string) => (
    <div className={classes.videoWrapper}>
      <video
        controls
        poster={posterImage && posterImage}
        autoPlay={false}
        className={classes.videoItem}
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
  return (
    <div className={classes.root} id="research-teaser">
      <Typography variant="h2" className={classes.headline}>
        {textObj.title}
      </Typography>

      <div className={classes.wrapper}>
        <TextColumn
          centeredTitle={false}
          centeredDescription={false}
          title={textObj.item1.title}
          description={textObj.item1.description}
          video={videoTag(videos[0], posterImages && posterImages[0])}
        />
        <TextColumn
          centeredTitle={false}
          centeredDescription={false}
          title={textObj.item2.title}
          description={textObj.item2.description}
          video={videoTag(videos[1], posterImages && posterImages[1])}
        />
        <TextColumn
          centeredTitle={false}
          centeredDescription={false}
          title={textObj.item3.title}
          description={textObj.item3.description}
          video={videoTag(videos[2], posterImages && posterImages[2])}
        />
      </div>
    </div>
  );
};

export const TargetgroupOverview = compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(TargetgroupOverviewItem);
