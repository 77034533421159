import React, { PureComponent, ReactNode } from "react";
import { withStyles, createStyles, WithStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { compose } from "recompose";
import theme, { QuopinionTheme, red, green, gold } from "../../../constants/Theme";

const colorMap: { [priority: string]: string } = {
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
  tertiary: "#78BF38",
  quarternary: "#fff",
  quinary: red,
  senary: green,
  septenary: gold,
  eighth: theme.palette.grey[100],
};

const styles = (theme: QuopinionTheme) =>
  createStyles({
    badge: {
      backgroundColor: (props: OwnProps) => colorMap[props.color] || colorMap.primary,
      width: (props: OwnProps) => (props.width ? props.width : "auto"),
      borderRadius: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "uppercase",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    icon: {
      marginRight: (props: OwnProps) => (props.title ? theme.spacing(1) : 0),
      height: 16,
    },
  });

interface OwnProps {
  color: string;
  title?: string;
  width?: number | string;
  className?: any;
  children?: ReactNode;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  style?: React.CSSProperties;
  icon?: ReactNode;
  textId?: string;
}

type Props = OwnProps & WithStyles<typeof styles>;

class Badge extends PureComponent<Props> {
  render() {
    const {
      classes,
      title,
      className,
      children,
      onClick,
      onMouseEnter,
      onMouseLeave,
      style = {},
      icon,
      textId,
    } = this.props;
    const badgeClassNames = `${classes.badge} ${className}`;
    return (
      <div
        className={badgeClassNames}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ ...style, cursor: onClick ? "pointer" : "auto" }}
      >
        <div className={classes.icon}>{icon}</div>
        <Typography
          color={this.props.color === "eighth" ? "initial" : "textSecondary"}
          variant="body1"
          id={`badge-${textId}`}
        >
          {title}
        </Typography>
        {children && <>{children}</>}
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(Badge);
