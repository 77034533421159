import React, { PureComponent } from "react";
import { createStyles, withStyles, WithStyles, Typography } from "@material-ui/core";
import { compose } from "recompose";

import { QuopinionTheme, secondary } from "../../../constants/Theme";
import { Plus, Minus } from "../../../assets/icon";

interface OwnProps {
  question: string;
  answer: string;
  open: boolean;
  handleClick: () => void;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: (props: Props) =>
        props.open ? theme.palette.grey[50] : theme.palette.grey[200],
      borderRadius: 4,
      flexDirection: "column",
      marginBottom: theme.spacing(4),
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
      textAlign: "left",
      "& a": {
        color: "inherit",
        textDecoration: "none",
      },
      "& a:visited": {
        color: "inherit",
      },
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
    header: {
      borderRadius: 4,
      display: "flex",
      justifyContent: "space-between",
      marginBottom: (props: OwnProps) => (props.open ? theme.spacing(4) : 0),
    },
    answer: {
      lineHeight: 1.67,
    },
    iconWrapper: {
      height: 24,
      width: 24,
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class FAQComponent extends PureComponent<Props> {
  render() {
    const { question, answer, classes, open, handleClick } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.header} onClick={handleClick}>
          <Typography color="primary" variant="body2">
            {question}
          </Typography>
          <div className={classes.iconWrapper}>
            {open && <Minus fill="#bfbfbf" />}
            {!open && <Plus fill={secondary} />}
          </div>
        </div>
        {open && (
          <Typography
            variant="caption"
            className={classes.answer}
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        )}
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(FAQComponent);
