import * as React from "react";
import { QuopinionTheme, secondary } from "../../../constants/Theme";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { withTranslation, WithTranslation } from "react-i18next";
import { PureComponent } from "react";
import { compose } from "recompose";
import { translations } from "../../../constants/lang/translation";
import { RouteComponentProps } from "react-router";
import { List, ListItem, ListItemText, SwipeableDrawer, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { route as PrivacyRoute } from "../Privacy";
import Button from "../../../common/Layout/components/Button";
import { route as AccountRoute } from "../Account";
import { route as ProfileDataRoute } from "../ProfileData";
import { route as OpinionValueRoute } from "../OpinionValue";
import { route as DashboardRoute } from "../Dashboard";
import { route as AttributesRoute } from "../Attributes";
import { route as TermsOfUseRoute } from "../TermsConditions";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { unsubscribeSessionToken } from "../../../modules/session";
import { actions as uiStateActions } from "../../../modules/uiState";
import { route as ParticipantFAQRoute } from "../FAQ";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import { route as LoginParticipantRoute } from "../Login";
import { Cross } from "../../../assets/icon";

interface OwnProps {
  visible: boolean;
  closeMenu: () => void;
  openMenu: () => void;
}
interface StateProps {
  authenticated: boolean;
  showMobileMenu: boolean;
}
interface State {
  open: boolean;
}

interface DispatchProps {
  unsubscribeSessionToken: typeof unsubscribeSessionToken;
  hideMenu: typeof uiStateActions.hideMobileMenu;
  showMenu: typeof uiStateActions.showMobileMenu;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    menu: {
      // width: "50vw",
      height: "100%",
    },
    text: {
      margin: 0,
      "& span": {
        color: theme.palette.text.primary,
        fontSize: "18px",
      },
    },
    drawer: {
      width: "100%",
    },
    list: {
      padding: `${theme.spacing(2)}px ${theme.spacing(8)}px`,
      marginBottom: theme.spacing(18),
      "@media (max-width: 599px)": {
        paddingBottom: theme.spacing(18),
      },
      "@media (max-height: 760px)": {
        paddingBottom: theme.spacing(18),
      },
      backgroundColor: theme.palette.grey[50],
    },
    listItem: {
      padding: theme.spacing(4),
    },
    link: {
      textDecoration: "none",
    },
    line: {
      color: theme.palette.grey[100],
      borderStyle: "solid",
      borderTopWidth: 0,
      marginBlockEnd: 0,
      marginBlockStart: 0,
    },
    logoutButton: {
      margin: `${theme.spacing(6)}px 0`,
    },
    closeButtonWrapper: {
      position: "fixed",
      bottom: 0,
      left: 0,
      height: 72,
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: theme.spacing(8),
    },
  });

type Props = OwnProps &
  RouteComponentProps &
  StateProps &
  WithStyles<typeof styles> &
  WithTranslation &
  DispatchProps;

class MenuParticipant extends PureComponent<Props, State> {
  state: State = {
    open: false,
  };

  componentWillUnmount = () => {
    this.props.hideMenu();
  };

  toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    this.setState({ open: !this.state.open });
  };

  closeDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    this.setState({ open: false });
  };

  close = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    this.setState({ open: false });
  };

  openDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    this.setState({ open: true });
  };

  handleLogoutClick = () => {
    const { unsubscribeSessionToken } = this.props;
    unsubscribeSessionToken();
  };

  renderSwipebeableMenu = () => {
    const { t, classes, authenticated, closeMenu } = this.props;
    return (
      <div className={classes.menu}>
        <LogoBarParticipant />
        <List className={classes.list}>
          {[
            {
              title: t(translations.participant.menu.opinionValue),
              link: authenticated ? OpinionValueRoute : LoginParticipantRoute,
            },
            {
              title: t(translations.participant.menu.profile),
              link: ProfileDataRoute,
            },
            {
              title: t(translations.participant.menu.attributes),
              link: authenticated ? AttributesRoute : LoginParticipantRoute,
            },
            {
              title: t(translations.participant.menu.surveys),
              link: authenticated ? DashboardRoute : LoginParticipantRoute,
            },
            {
              title: t(translations.participant.menu.earnings),
              link: authenticated ? AccountRoute : LoginParticipantRoute,
            },
            { title: t(translations.participant.menu.privacy), link: PrivacyRoute },
            { title: t(translations.participant.menu.termsOfUse), link: TermsOfUseRoute },
            { title: t(translations.participant.menu.faqAndSupport), link: ParticipantFAQRoute },
          ].map((menuObject, index) => (
            <div key={`${menuObject.link}-${index}`} onClick={this.props.closeMenu}>
              <Link to={menuObject.link} className={classes.link}>
                <ListItem button={true} classes={{ root: classes.listItem }}>
                  <ListItemText className={classes.text} primary={menuObject.title} />
                </ListItem>
              </Link>
              <hr className={classes.line} />
            </div>
          ))}
          {authenticated ? (
            <Button
              size="big"
              contained={true}
              color="secondary"
              className={classes.logoutButton}
              onClick={this.handleLogoutClick}
            >
              {t(translations.action.logout)}
            </Button>
          ) : (
            <>
              <Link to="/participant/login" style={{ textDecoration: "none" }}>
                <Button
                  size="big"
                  contained={true}
                  color="secondary"
                  className={classes.logoutButton}
                >
                  {t(translations.action.login)}
                </Button>
              </Link>
              <Link to="/participant/registration" style={{ textDecoration: "none" }}>
                <Button size="small" color="secondary" className={classes.logoutButton}>
                  {t(translations.action.registerNow)}
                </Button>
              </Link>
            </>
          )}
        </List>
        <div className={classes.closeButtonWrapper}>
          <IconButton onClick={closeMenu}>
            <Cross fill={secondary} width="24" />
          </IconButton>
        </div>
      </div>
    );
  };

  render() {
    const { classes, closeMenu, openMenu, showMobileMenu } = this.props;

    return (
      <>
        <SwipeableDrawer
          onClose={closeMenu}
          onOpen={openMenu}
          open={showMobileMenu}
          classes={{ paper: classes.drawer }}
        >
          {this.renderSwipebeableMenu()}
        </SwipeableDrawer>
      </>
    );
  }
}

const mapStateToProps = ({ session, uiState }: RootState) => ({
  authenticated: session.authenticated,
  showMobileMenu: uiState.showMobileMenu,
});

export default compose<Props, OwnProps>(
  connect<{}, DispatchProps, Props, RootState>(mapStateToProps, {
    unsubscribeSessionToken,
    hideMenu: uiStateActions.hideMobileMenu,
    showMenu: uiStateActions.showMobileMenu,
  }),
  withTranslation(),
  withStyles(styles)
)(MenuParticipant);
