import React, { ReactNode } from "react";
import { createStyles, withStyles, WithStyles, FormControlLabel } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { FormControlLabelProps } from "@material-ui/core/FormControlLabel";

interface Props extends FormControlLabelProps {
  key: string;
  children?: ReactNode;
  className?: any;
  wrapperClass?: any;
  withoutMargin?: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    radioElement: {
      marginRight: (props: Props) => (props.withoutMargin ? "0px" : theme.spacing(3.5)),
    },
    answerItem: {
      display: "flex",
      alignItems: "flex-start",
      backgroundColor: "#fff",
      padding: theme.spacing(4),
      marginLeft: 0,
      marginBottom: theme.spacing(0.5),
      "& svg": {
        width: 24,
        height: 24,
      },
      marginRight: (props: Props) => (props.withoutMargin ? "0px" : "initial"),
    },
    answerItemHeader: {
      display: "flex",
      width: "auto",
      marginRight: 0,
      wordBreak: "break-word",
    },
    body: {
      height: 100,
      width: "100%",
    },
    wrapper: {
      marginBottom: theme.spacing(0.5),
      backgroundColor: "#fff",
      marginRight: (props: Props) => (props.withoutMargin ? "0px" : theme.spacing(4)),
    },
  });

//for Single Choice questions and Matrix mobile view only

const RadioElement: React.FunctionComponent<Props & WithStyles<typeof styles>> = ({
  classes,
  children,
  className,
  wrapperClass,
  ...props
}) => {
  const classNames = `${classes.answerItem} ${className}`;
  let wrapperClassNames;
  if (wrapperClass) {
    wrapperClassNames = `${classes.wrapper} ${wrapperClass}`;
  } else {
    wrapperClassNames = `${classes.wrapper}`;
  }
  return (
    <div className={wrapperClassNames} onClick={() => props.onChange}>
      <FormControlLabel
        {...props}
        value={props.value}
        className={classNames}
        label={props.label}
        labelPlacement="end"
        classes={{ label: classes.answerItemHeader }}
      />
      {children && children}
    </div>
  );
};

export default withStyles(styles)(RadioElement);
