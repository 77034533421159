import { combineReducers, Action } from "redux";
import { StateType, ActionType } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { reducer as session } from "./session";
import { reducer as participation } from "./participation";
import { reducer as error } from "./error";
import { reducer as attribute } from "./attribute";
import { reducer as navigation } from "./navigation";
import { reducer as survey } from "./survey";
import { reducer as definePanel } from "./definePanel";
import { reducer as uiState } from "./uiState";
import { reducer as opinionValue } from "./opinionValue";
import { reducer as researcherProfile } from "./researcherProfile";
import { reducer as user } from "./user";
import { reducer as surveyError } from "./surveyErrors";
import { reducer as surveyPreview } from "./surveyPreview";
import { reducer as mailTemplates } from "./mailTemplates";
import rootAction from "./rootAction";

const rootReducer = combineReducers({
  session,
  participation,
  error,
  attribute,
  navigation,
  survey,
  opinionValue,
  uiState,
  researcherProfile,
  definePanel,
  user,
  surveyError,
  surveyPreview,
  mailTemplates,
});

export default rootReducer;
export type RootState = StateType<typeof rootReducer>;
export type RootAction = ActionType<typeof rootAction>;
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action>;
