import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, Typography, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { PayoutOption } from "./index";

interface OwnProps {
  value: number;
  option: PayoutOption;
  selected: boolean;
  selectOption: (value: number) => void;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    image: {
      width: "100%",
    },
    imageWrapper: {
      alignItems: "center",
      backgroundColor: (props: OwnProps) => (props.option.name === "Amazon" ? "#000" : "#fff"),
      border: (props: OwnProps) =>
        props.selected ? `2px solid ${theme.palette.secondary.main}` : "2px solid transparent",
      borderRadius: 3,
      boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.3)",
      display: "flex",
      marginBottom: theme.spacing(2),
      height: 72,
      "@media (max-width: 450px)": {
        minWidth: 120,
      },
      width: "100%",
      "@media (min-width: 450px)": {
        flexGrow: 1,
        minHeight: 72,
        height: "auto",
      },
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      hyphens: "auto",
      color: theme.palette.grey[400],
      height: "100%",
    },
  });
type Props = OwnProps & WithStyles<typeof styles>;

class PayoutSelect extends PureComponent<Props> {
  render() {
    const { value, option, classes, selectOption } = this.props;
    return (
      <div className={classes.wrapper} onClick={selectOption.bind(this, value)}>
        <div className={classes.imageWrapper}>
          <img alt={option.name} src={option.image} className={classes.image} />
        </div>
        <Typography color="primary" variant="body2">
          {option.name}
        </Typography>
        <Typography variant="body1" color="inherit">
          {option.kind}
        </Typography>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(PayoutSelect);
