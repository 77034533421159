import * as React from "react";

import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import { WithStyles, withStyles, createStyles, Typography } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import Container from "../../../common/Layout/Container";

import { translations } from "../../../constants/lang/translation";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { connect } from "react-redux";

import { LoginForm } from "./LoginForm";
import { RootState } from "../../../modules";
import { passNextPage } from "../../../modules/navigation";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100vh",
      alignItems: "center",
    },
    newText: {
      textAlign: "center",
      marginTop: 14,
    },
    invalidCredentials: {
      color: theme.palette.secondary.main,
    },
    offlineError: {
      color: theme.palette.secondary.main,
    },
  });

interface OwnProps {}

interface StateProps {
  invalidCredentials: boolean;
  isOffline: boolean;
}
type Props = OwnProps & StateProps & WithTranslation & WithStyles<typeof styles>;

interface LoginPageState {
  user: string;
  disabled: boolean;
}

export const route: string = "/market-research/login";

class LoginPage extends React.Component<Props, LoginPageState> {
  render() {
    const { classes, t, invalidCredentials, isOffline } = this.props;

    return (
      <>
        <LogoBarResearcher />
        <Container>
          <div className={classes.wrapper}>
            <div>
              <div>
                {isOffline ? (
                  <Typography className={classes.offlineError}>
                    {t(translations.general.offlineError)}
                  </Typography>
                ) : (
                  ""
                )}
              </div>
              <Typography variant="h1" color="primary">
                {t(translations.login.headline)}
              </Typography>
              {!invalidCredentials ? (
                <Typography variant="body1">{t(translations.login.textResearcher)}</Typography>
              ) : (
                <Typography variant="body1" className={classes.invalidCredentials}>
                  {t(translations.login.invalidCredentials)}
                </Typography>
              )}
              <LoginForm onLoginPage={true} />
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ error }: { error: any }) => ({
  invalidCredentials: Boolean(error.invalidCredentials),
  isOffline: Boolean(error.offline),
});

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps, { passNextPage }, null, {
    pure: false,
  })
)(LoginPage);
