import * as React from "react";
import { createStyles, Typography, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../../constants/lang/translation";
import { QuopinionTheme } from "../../../../constants/Theme";
import { compose } from "recompose";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      "@media(max-width: 599px)": {
        paddingRight: theme.spacing(4),
      },
    },
    itemWrapper: {
      marginBottom: theme.spacing(6),
      "@media(max-width: 772px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      },
      "@media(max-width: 599px)": {
        alignItems: "center",
      },
    },
    itemText: {
      marginLeft: 38,
      width: 180,
      "@media(max-width: 599px)": {
        textAlign: "left",
      },
      "@media(min-width: 600px)": {
        textAlign: "end",
        width: theme.spacing(31),
      },
      "@media(min-width: 773px)": {
        textAlign: "unset",
      },
    },
    itemBullet: {
      marginRight: 8,
    },
    itemTitle: {
      color: "#0b3b5f",
      "@media(max-width: 772px)": {
        textAlign: "end",
      },
    },
  });

interface OwnProps {}

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

const Explanation: React.FunctionComponent<Props> = (props) => {
  const { t, classes } = props;
  return (
    <div className={classes.root}>
      <ExplanationItem
        bullet={"1."}
        title={t(translations.pages.Survey.introduction.explanation.first.title)}
        text={t(translations.pages.Survey.introduction.explanation.first.descriptionShort)}
        classes={classes}
      />
      <ExplanationItem
        bullet={"2."}
        title={t(translations.pages.Survey.introduction.explanation.second.title)}
        text={t(translations.pages.Survey.introduction.explanation.second.descriptionShort)}
        classes={classes}
      />
      <ExplanationItem
        bullet={"3."}
        title={t(translations.pages.Survey.introduction.explanation.third.title)}
        text={t(translations.pages.Survey.introduction.explanation.third.descriptionShort)}
        classes={classes}
      />
      <ExplanationItem
        bullet={"4."}
        title={t(translations.pages.Survey.introduction.explanation.fourth.title)}
        text={t(translations.pages.Survey.introduction.explanation.fourth.descriptionShort)}
        classes={classes}
      />
    </div>
  );
};

const ExplanationItem: React.FunctionComponent<{
  title: string;
  text: string;
  bullet: string;
  classes: any;
}> = (props) => (
  <div className={props.classes.itemWrapper}>
    <div
      style={{
        display: "inline-flex",
        padding: "4px 16px",
        backgroundColor: "#e3e7eb",
        borderRadius: 16,
        marginBottom: 12,
      }}
    >
      <Typography variant="body2" className={props.classes.itemBullet}>
        {props.bullet}
      </Typography>
      <Typography variant="body2" className={props.classes.itemTitle}>
        {props.title}
      </Typography>
    </div>
    <div className={props.classes.itemText}>
      <Typography>{props.text}</Typography>
    </div>
  </div>
);

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(Explanation);
