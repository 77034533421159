import React, { PureComponent } from "react";
import { Grid, createStyles, WithStyles, withStyles } from "@material-ui/core";
import { compose } from "recompose";

import LogoBarResearcher from "../../../../common/Layout/LogoBarResearcher";
import ContentWrapper from "../../../../common/Layout/ContentWrapper";
import Container from "../../../../common/Layout/Container";
import SidebarContainer from "../../../../common/Layout/SidebarContainer";
import SupportContainer from "../../../../common/Layout/SupportContainer";
import { QuopinionTheme } from "../../../../constants/Theme";
import { WithTranslation, withTranslation } from "react-i18next";

import { RootState } from "../../../../modules";
import Researcher from "../../../../entities/Researcher";
import { connect } from "react-redux";
import PersonalDataForm from "./PersonalDataForm";
import EditLoginDataForm from "./EditLoginDataForm";
import { fetchCurrentResearcher } from "../../../../modules/user";
import LoadingOverlay from "../../../../common/LoadingOverlay";

export const route = "/market-research/personal-information";

interface OwnProps {}

interface DispatchProps {
  fetchCurrentResearcher: () => Promise<void>;
}

interface StateProps {
  currentResearcher: Researcher;
  isLoading: boolean;
}

const styles = (theme: QuopinionTheme) => createStyles({});

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles> & WithTranslation;

class PersonalInformation extends PureComponent<Props> {
  componentDidMount() {
    this.props.fetchCurrentResearcher();
  }
  render() {
    const { currentResearcher, isLoading } = this.props;
    return (
      <div>
        {isLoading && <LoadingOverlay />}
        <LogoBarResearcher backButton={true} linkBorders={true} />
        <Grid container={true}>
          <ContentWrapper>
            <Container>
              <PersonalDataForm />
              <EditLoginDataForm researcherProfile={currentResearcher} />
            </Container>
            <SidebarContainer>
              <SupportContainer />
            </SidebarContainer>
          </ContentWrapper>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ user }: RootState) => ({
  currentResearcher: user.currentResearcher,
  isLoading: user.isLoading,
});

const mapDispatchToProps = {
  fetchCurrentResearcher,
};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(PersonalInformation);
