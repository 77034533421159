import React, { PureComponent } from "react";
import { QuopinionTheme } from "../../../constants/Theme";
import { createStyles, WithStyles, withStyles, Typography } from "@material-ui/core";
import { compose } from "recompose";
import CreditListEntry from "./CreditListEntry";
import { CreditEntry } from "../../../entities/User";
import Moment from "moment";

interface OwnProps {
  credits: CreditEntry[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(2),
    },
    wrapper: {
      marginTop: theme.spacing(4),
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class CreditHistory extends PureComponent<Props> {
  getCreditsPerMonth = (credits: CreditEntry[]): Map<string, CreditEntry[]> => {
    const creditsByMonth = new Map<string, CreditEntry[]>();
    const creditMonths: string[] = credits.map((credit) =>
      Moment(credit.createdAt).format("YYYY-MM")
    );

    const creditMonthsUnique: string[] = Array.from(new Set(creditMonths));

    creditMonthsUnique.forEach((month) => {
      const monthSurveys = credits.filter(
        (credit) => Moment(credit.createdAt).format("YYYY-MM") === month
      );
      creditsByMonth.set(month, monthSurveys);
    });
    return creditsByMonth;
  };

  render() {
    const { credits, classes } = this.props;
    const creditsByMonths = this.getCreditsPerMonth(credits);
    const months = Array.from(creditsByMonths.keys());
    return months.map((month) => {
      const creditsByMonth = creditsByMonths.get(month)!;
      return (
        <div className={classes.wrapper} key={month}>
          <Typography variant="subtitle1" color="primary" className={classes.title}>
            {Moment(month).format("MMMM")}
          </Typography>
          {creditsByMonth.map((credit: CreditEntry) => (
            <CreditListEntry key={credit.id} credit={credit} />
          ))}
        </div>
      );
    });
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(CreditHistory);
