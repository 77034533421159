import * as React from "react";
import Navigation from "../../../common/Layout/Navigation";
import SurveyItem from "./SurveyItem";
import {createStyles, MenuItem, Select, Typography, withStyles, WithStyles,} from "@material-ui/core";
import Container from "../../../common/Layout/Container";
import {QuopinionTheme} from "../../../constants/Theme";
import {connect} from "react-redux";
import {RootState} from "../../../modules";
import {getParticipations} from "../../../modules/participation";
import {fetchCurrentCredit} from "../../../modules/user";
import Badge from "../../../common/Layout/components/Badge";
import {DropdownArrowGrey} from "../../../assets/icon";
import {translations} from "../../../constants/lang/translation";
import {WithTranslation, withTranslation} from "react-i18next";
import {compose} from "recompose";
import NotAvailableCard from "../../../common/Layout/components/NotAvailableCard";
import LoadingOverlay from "../../../common/LoadingOverlay";
import {RouteComponentProps, withRouter} from "react-router";
import {route as ParticipantAttributeRoute} from "../Attributes";
import Survey, {formatPrice} from "../../../entities/Survey";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import { ParticipationState } from "../../../services/Survey";
import {withAuthorization} from "../../../common/hoc/withAuthorization";
import {CreditData, UserRole} from "../../../entities/User";
import {route as accountRoute} from "../Account";
import {fetchDailyScreener} from "../../../modules/attribute";
import {SnackbarComponent} from "../../../common/Layout/components/Snackbar";
import {removeParticipationCurrentlyNotAllowed} from "../../../modules/uiState";
import moment from "moment";

const TIMED_INFO = false;

const styles = (theme: QuopinionTheme) =>
  createStyles({
    tabContainer: {
      backgroundColor: theme.palette.grey[50],
      paddingTop: theme.spacing(5.25),
      paddingBottom: theme.spacing(2.5),
      marginBottom: 15,
    },
    starBadge: {
      height: 40,
      marginLeft: theme.spacing(3),
    },
    headline: {
      textTransform: "uppercase",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    description: {
      marginBottom: theme.spacing(1),
    },
    selectMenu: {
      width: 100,
      "&$active": {
        borderRadius: "4px",
      },
      "&:focus": {
        borderRadius: "4px",
      },
    },
    list: {
      paddingBottom: theme.spacing(8),
      "@media (min-width: 1392px)": {
        paddingBottom: theme.spacing(14),
      },
    },
    select: {
      position: "relative",
      "& svg": {
        width: 20,
        top: 1,
        right: 8,
        position: "absolute",
        height: "calc(100% - 2px)",
        pointerEvents: "none",
      },
    },
    timedInfoCard: {
      borderRadius: 10,
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(6),
      margin: `${theme.spacing(15)}px 0`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
    },
  });

interface DispatchProps {
  getParticipations: typeof getParticipations;
  fetchCurrentCredit: typeof fetchCurrentCredit;
  fetchDailyScreener: typeof fetchDailyScreener;
  removeParticipationCurrentlyNotAllowed: typeof removeParticipationCurrentlyNotAllowed;
}

interface OwnProps {}

interface StateProps {
  isLoading: boolean;
  openSurveys: Survey[];
  acceptedSurveys: Survey[];
  completedSurveys: Survey[];
  missedSurveys: Survey[];
  declinedSurveys: Survey[];
  waitlistedSurveys: Survey[];
  creditData: CreditData;
  error: boolean;
}

interface DashboardPageState {
  selectedFilter: string;
}

type Props = OwnProps &
  DashboardPageState &
  WithStyles<typeof styles> &
  WithTranslation &
  StateProps &
  RouteComponentProps &
  DispatchProps;

interface State {
  value: number;
  currentCount: number;
  doneCount: number;
  overCount: number;
  selectedFilter: string;
  menuVisible: boolean;
  timedInformation: boolean;
}

export const route = "/participant/dashboard";

class DashboardPage extends React.Component<Props, State> {
  state: State = {
    value: 0,
    currentCount: 3,
    doneCount: 2,
    overCount: 0,
    selectedFilter: "open",
    menuVisible: false,
    timedInformation: TIMED_INFO,
  };

  componentDidMount = async () => {
    const { getParticipations, fetchCurrentCredit, fetchDailyScreener } = this.props;
    getParticipations();
    fetchCurrentCredit();
    fetchDailyScreener();
  };

  handleChange = (_event: any, value: number) => {
    this.setState({value});
  };

  setSurveyFilter = (event: any) => {
    this.setState({
      selectedFilter: event.target.value,
    });
  };
  personalizeAccount = () => {
    this.props.history.push(ParticipantAttributeRoute);
  };

  openMenu = () => {
    this.setState({ menuVisible: true });
  };

  closeMenu = () => {
    this.setState({ menuVisible: false });
  };

  renderSurveyList() {
    const {
      openSurveys,
      acceptedSurveys,
      completedSurveys,
      declinedSurveys,
      missedSurveys,
      waitlistedSurveys,
      classes,
      t,
      isLoading,
    } = this.props;

    if (isLoading) {
      return <LoadingOverlay />;
    }

    const {selectedFilter, timedInformation} = this.state;

    const surveyCount =
      selectedFilter === "open"
        ? openSurveys.length + acceptedSurveys.length + waitlistedSurveys.length
        : selectedFilter === "notCompleted"
          ? missedSurveys.length
          : completedSurveys.length + declinedSurveys.length;

    const now = moment();
    const recentRejectedSurveys = declinedSurveys.filter((survey) =>
      survey.state === "PARTICIPATION_REJECTED" && moment(survey.endDate).isAfter(now)
    );
    const recentMissedOrRejectedSurveys = missedSurveys.filter((survey) =>
      moment(survey.endDate).isAfter(now)
    );
    recentMissedOrRejectedSurveys.push(...recentRejectedSurveys);

    return (
      <div className={classes.list}>
        {timedInformation && (
          <div className={classes.timedInfoCard}>
            <Typography variant="h3" color="primary">
              {t(translations.headerSurvey.timedInformation.title)}
            </Typography>
            <Typography variant="body1" color="primary" className={classes.description}>
              {t(translations.headerSurvey.timedInformation.subTitle)}
            </Typography>
          </div>
        )}
        {/* no surveys for filter available */}
        {surveyCount === 0 && (
          <NotAvailableCard
            headline={t(translations.headerSurvey.noSurveys.headline)}
            description={
              <>
                <span className={classes.description}>
                  {t(translations.headerSurvey.noSurveys.description)}
                </span>
                {missedSurveys && missedSurveys.length > 0 && (
                  <>{t(translations.headerSurvey.noSurveys.showMissedSurveys)}</>
                )}
              </>
            }
            buttonTitle={t(translations.action.personalizeNow)}
            buttonFunction={this.personalizeAccount}
          />
        )}

        {/* todo: render survey based on selected filter */}
        {(surveyCount > 0 || recentMissedOrRejectedSurveys.length > 0) && (
          <div className={classes.tabContainer}>
            {selectedFilter === "open" &&
              acceptedSurveys.map((survey) =>
                this.renderSurvey(survey.state || "PARTICIPATION_ACCEPTED" as ParticipationState, survey)
              )}
            {selectedFilter === "open" &&
              openSurveys.map((survey) =>
                this.renderSurvey(survey.state || "PARTICIPATION_OPEN" as ParticipationState, survey)
              )}
            {selectedFilter === "open" &&
              waitlistedSurveys.map((survey) =>
                this.renderSurvey(survey.state || "PARTICIPATION_WAITLISTED" as ParticipationState, survey)
              )}
            {selectedFilter === "open" &&
              recentMissedOrRejectedSurveys.map((survey) =>
                this.renderSurvey(survey.state || "PARTICIPATION_MISSED" as ParticipationState, survey)
              )}
            {selectedFilter === "completed" &&
              completedSurveys.map((survey) =>
                this.renderSurvey(survey.state || "PARTICIPATION_COMPLETED" as ParticipationState, survey)
              )}
            {selectedFilter === "completed" &&
              declinedSurveys.map((survey) =>
                this.renderSurvey(survey.state || "PARTICIPATION_DECLINED" as ParticipationState, survey)
              )}
            {selectedFilter === "completed" &&
              waitlistedSurveys.map((survey) =>
                this.renderSurvey(survey.state || "PARTICIPATION_WAITLISTED" as ParticipationState, survey)
              )}
            {selectedFilter === "notCompleted" &&
              missedSurveys.map((survey) =>
                this.renderSurvey(survey.state || "PARTICIPATION_MISSED" as ParticipationState, survey)
              )}
            {selectedFilter === "notCompleted" &&
              declinedSurveys.map((survey) =>
                this.renderSurvey(survey.state || "PARTICIPATION_DECLINED" as ParticipationState, survey)
              )}
          </div>
        )}

        {isLoading && <LoadingOverlay/>}
      </div>
    );
  }

  renderSurvey(state: ParticipationState, survey: Survey) {
    return <SurveyItem key={survey.id} survey={survey} participationState={state} />;
  }

  renderErrorMessage() {
    const {t, removeParticipationCurrentlyNotAllowed} = this.props;
    return (
      <>
        <SnackbarComponent
          title={t(translations.pages.Questionnaire.warning.headline)}
          message={t(translations.participant.surveyOverview.noAccessOnSurveyInfos)}
          type="error"
          onClose={() => {
            removeParticipationCurrentlyNotAllowed();
          }}
        />
      </>
    );
  }

  render() {
    const { menuVisible } = this.state;
    const {
      classes,
      t,
      history,
      creditData: { credit },
      error,
    } = this.props;

    return (
      <>
        {error && this.renderErrorMessage()}
        <LogoBarParticipant
          children={
            <>
              <Badge
                color="primary"
                title={formatPrice(credit)}
                onClick={() => history.push(accountRoute)}
                textId={"credit"}
              />
              {/* <Badge
                color="septenary"
                icon={<Star fill="#fff" height={14} width={14} />}
                className={classes.starBadge}
              /> */}
            </>
          }
        />
        <Container centered={true}>
          <div className={classes.header}>
            <Typography variant="body2" className={classes.headline}>
              {t(translations.headerSurvey.pl)}
            </Typography>

            {/* select displayed surveys */}
            <Select
              value={this.state.selectedFilter}
              onChange={this.setSurveyFilter}
              className={classes.select}
              classes={{ selectMenu: classes.selectMenu }}
              IconComponent={CustomDropdownArrowGrey}
            >
              <MenuItem value="open">{t(translations.headerSurvey.current)}</MenuItem>
              <MenuItem value="completed">{t(translations.headerSurvey.done)}</MenuItem>
              <MenuItem value="notCompleted">{t(translations.headerSurvey.over)}</MenuItem>
            </Select>
          </div>

          {this.renderSurveyList()}
        </Container>
        <Navigation openMenu={this.openMenu} onMenu={menuVisible} closeMenu={this.closeMenu} />
      </>
    );
  }
}

const mapStateToProps = ({ participation, user, uiState }: RootState) => {
  return {
    isLoading: participation.isLoading,
    openSurveys: participation.openSurveys,
    acceptedSurveys: participation.acceptedSurveys,
    completedSurveys: participation.completedSurveys,
    declinedSurveys: participation.declinedSurveys,
    missedSurveys: participation.missedSurveys,
    waitlistedSurveys: participation.waitlistedSurveys,
    creditData: user.creditData,
    error: uiState.participationNotAllowed,
  };
};

const mapDispatchToProps = {
  getParticipations,
  fetchCurrentCredit,
  fetchDailyScreener,
  removeParticipationCurrentlyNotAllowed,
};

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  withRouter,
  withAuthorization(UserRole.PARTICIPANT)
)(DashboardPage);

const CustomDropdownArrowGrey = (props: any) => <DropdownArrowGrey {...props} />;
