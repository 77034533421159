import React, { PureComponent } from "react";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Card from "../../../common/Layout/components/Card";
import { Typography, WithStyles, withStyles, createStyles, Grid } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../constants/Theme";
import { translations } from "../../../constants/lang/translation";
import Button from "../../../common/Layout/components/Button";
import { LoginForm } from "./LoginForm";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { route as ResearcherRegistrationRoute } from "../Register";
import { clearState } from "../../../util/localStorage";
import { SnackbarComponent } from "../../../common/Layout/components/Snackbar";

interface OwnProps {}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    card: {
      padding: theme.spacing(6),
    },
    formCard: {
      padding: theme.spacing(6),
      marginTop: theme.spacing(6),
    },
    buttonLine: {
      marginTop: theme.spacing(8),
      display: "flex",
      justifyContent: "flex-end",
    },
    headline: {
      marginBottom: theme.spacing(2),
    },
    wrapper: {
      padding: `${theme.spacing(20)}px ${theme.spacing(12)}px ${theme.spacing(
        14
      )}px ${theme.spacing(0)}px`,
      "@media(max-width:768px)": {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      "@media (min-width: 768px)": {
        paddingLeft: "72.5px", //ToDo Check with Verena if consistent distance ok or better own 53px
      },
      "@media(min-width: 1440px)": {
        paddingLeft: "unset",
      },
    },
    registerButton: {
      textDecoration: "none",
    },
    version: {
      color: "#999",
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation & RouteComponentProps;

export const route: string = "/market-research/login";

class LoginPage extends PureComponent<Props> {
  submitInnerForm: any;

  componentDidMount() {
    const surveyToken = localStorage.getItem("surveyToken");

    if (surveyToken) {
      localStorage.removeItem("surveyToken");
    }
    // clear session
    clearState();
  }

  handleSubmitInnerForm = (e: any) => {
    if (this.submitInnerForm !== null) {
      this.submitInnerForm(e);
    }
  };

  bindSubmitForm = (submitForm: any) => {
    this.submitInnerForm = submitForm;
  };

  render() {
    const {
      t,
      classes,
      location: { search },
    } = this.props;
    const emailVerified = new URLSearchParams(search).get("emailVerified");
    return (
      <>
        {emailVerified && (
          <SnackbarComponent
            title={t(translations.login.emailVerifiedMarket.title)}
            message={t(translations.login.emailVerifiedMarket.message)}
            type="success"
            onClose={() => {
              return;
            }}
          />
        )}
        <LogoBarResearcher />
        <ContentWrapper>
          <Grid container={true}>
            <Grid item={true} xs={12} sm={6} className={classes.wrapper}>
              <Card isRaised={true} className={classes.card}>
                <Typography variant="h3" color="primary" className={classes.headline}>
                  {t(translations.register.newRegister)}
                </Typography>
                <Typography variant="body1">
                  {t(translations.register.newRegisterDescription)}
                </Typography>
                <div className={classes.buttonLine}>
                  <Link to={ResearcherRegistrationRoute} className={classes.registerButton}>
                    <Button size="medium" color="primary" contained={true}>
                      {t(translations.action.registerNow)}
                    </Button>
                  </Link>
                </div>
              </Card>
            </Grid>
            <Grid item={true} xs={12} sm={6} className={classes.wrapper}>
              <Typography variant="h2" color="primary" className={classes.headline}>
                {t(translations.login.headline)}
              </Typography>
              <Typography variant="body1">{t(translations.login.textResearcher)}</Typography>
              <Card isRaised={true} className={classes.formCard}>
                <LoginForm bindSubmitForm={this.bindSubmitForm} onLoginPage={true} />
              </Card>
              <div className={classes.buttonLine}>
                <Button
                  size="big"
                  color="secondary"
                  contained={true}
                  onClick={this.handleSubmitInnerForm}
                >
                  {t(translations.action.login)}
                  {/* disabled fehlt*/}
                </Button>
              </div>
            </Grid>
          </Grid>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withRouter,
  withStyles(styles)
)(LoginPage);
