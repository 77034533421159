import React, { PureComponent } from "react";
import { createStyles, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { QuopinionTheme } from "../../../constants/Theme";

import { withAuthorization } from "../../../common/hoc/withAuthorization";
import { UserRole } from "../../../entities/User";
import { Links } from "../../marketintelligence/Dashboard";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import AccountSelect from "../../marketintelligence/Dashboard/components/AccountButton";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import AttributeListItem from "./components/AttributeListItem";
import Container from "../../../common/Layout/Container";
import {
  fetchNewExternalAttributes,
  updateNewExternalAttributes,
} from "../../../modules/attribute";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import RequestedAttribute from "../../../entities/RequestedAttribute";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { fetchCurrentResearcher } from "../../../modules/user";

export const route = "/admin/external-attribute";

interface State {}

interface OwnProps {}
interface StateProps {
  requestedAttributes: RequestedAttribute[];
  isLoading: boolean;
}

interface DispatchProps {
  fetchNewExternalAttributes(): any;
  updateNewExternalAttributes(updatedAttribute: RequestedAttribute): any;
  fetchCurrentResearcher: typeof fetchCurrentResearcher;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    customLogobar: {
      display: "flex",
    },
    header: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
      textTransform: "uppercase",
    },
    itemWrapper: {
      width: "100%",
    },
    headline: {
      marginBottom: theme.spacing(4),
    },
  });

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{ id: string }>;

class ExternalAttributeOverview extends PureComponent<Props, State> {
  componentDidMount(): void {
    this.props.fetchNewExternalAttributes();
    this.props.fetchCurrentResearcher();
  }

  renderLogoBarCustomArea = (props: Props) => {
    const { classes } = this.props;
    return (
      <div className={classes.customLogobar}>
        <AccountSelect />
      </div>
    );
  };
  onDenyClick = (externalAttributeItem: RequestedAttribute) => {
    const transientAttribute = { ...externalAttributeItem, status: "REJECTED" };
    const updatedAttribute = new RequestedAttribute(transientAttribute);
    this.props.updateNewExternalAttributes(updatedAttribute);
  };

  render() {
    const { classes, requestedAttributes, isLoading, t } = this.props;

    return (
      <div>
        <LogoBarResearcher links={Links} children={this.renderLogoBarCustomArea(this.props)} />

        <Grid container={true}>
          <ContentWrapper>
            <Container>
              <Typography variant="h2" className={classes.headline}>
                {t(translations.pages.admin.dashboard.attributes.externalScreenerRequests)}
              </Typography>
              {isLoading ? (
                <LoadingOverlay />
              ) : (
                <div className={classes.itemWrapper}>
                  {requestedAttributes &&
                    requestedAttributes.map((externalAttributeItem) => (
                      <AttributeListItem
                        key={externalAttributeItem.id}
                        externalAttribute={externalAttributeItem}
                        handleDeny={() => this.onDenyClick(externalAttributeItem)}
                      />
                    ))}
                </div>
              )}
            </Container>
          </ContentWrapper>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = ({ attribute }: RootState) => ({
  requestedAttributes: attribute.requestedAttributes,
  isLoading: attribute.isLoading,
});
const mapDispatchToProps = {
  fetchNewExternalAttributes,
  updateNewExternalAttributes,
  fetchCurrentResearcher,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  withAuthorization(UserRole.ADMIN),
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(ExternalAttributeOverview);
