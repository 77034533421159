import React from "react";
import {
  Snackbar,
  Typography,
  IconButton,
  WithStyles,
  withStyles,
  createStyles,
} from "@material-ui/core";
import theme, { green, QuopinionTheme } from "../../../constants/Theme";
import { styled } from "@material-ui/styles";
import { Cross as CrossIcon } from "../../../assets/icon";
import { compose } from "recompose";

type SnackbarType = "success" | "error" | "info";
const typeColorMap: { [type in SnackbarType]: string } = {
  success: green,
  error: theme.palette.error.main,
  info: theme.palette.secondary.main,
};
export const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      width: "100%",
      top: 0,
      "@media(max-width: 599px)": {
        left: 0,
      },
    },
    iconWrapper: {
      flexGrow: 0,
    },
    textWrapper: {
      flexGrow: 1,
    },
    title: {
      fontWeight: "bold",
    },
    contentWrapper: {
      display: "flex",
    },
  });

const SnackbarWrapper = styled("div")({
  paddingTop: theme.spacing() * 4,
  paddingBottom: theme.spacing() * 4,
  paddingLeft: theme.spacing() * 6,
  paddingRight: theme.spacing() * 6,
  width: "100%",
  color: "white",
});

export interface SnackbarContentProps {
  title: string;
  message: React.ReactNode;
  type: "success" | "error" | "info";
  onClose?: () => void;
}

const SnackbarContent: React.FC<SnackbarContentProps & WithStyles<typeof styles>> = ({
  title,
  message,
  onClose,
  classes,
}) => (
  <div className={classes.contentWrapper}>
    <div className={classes.textWrapper}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1">{message}</Typography>
    </div>
    <div className={classes.iconWrapper}>
      <IconButton onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </div>
  </div>
);

interface SnackbarProps extends SnackbarContentProps {
  style?: any;
}
type Props = SnackbarProps & WithStyles<typeof styles>;

const SnackbarItem: React.FC<Props> = ({ style, type, classes, title, message, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={true}
      autoHideDuration={6000}
      style={{ ...style }}
      className={classes.root}
    >
      <SnackbarWrapper style={{ backgroundColor: typeColorMap[type] }}>
        <SnackbarContent
          title={title}
          message={message}
          onClose={onClose}
          type={type}
          classes={classes}
        />
      </SnackbarWrapper>
    </Snackbar>
  );
};

export const SnackbarComponent = compose<Props, SnackbarProps>(withStyles(styles))(SnackbarItem);
