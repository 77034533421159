import ID from "../entities/ID";
import {Dispatch} from "redux";
import i18next from "i18next";
import { translations } from "../constants/lang/translation";

const SET_GLOBAL_PANEL_ERROR = "surveyErrors/SET_GLOBAL_PANEL_ERROR";
const SET_CRITERIA_PANEL_ERROR = "surveyErrors/SET_CRITERIA_PANEL_ERROR";
const SET_GLOBAL_QUESTIONNAIRE_ERROR = "surveyErrors/SET_GLOBAL_QUESTIONNAIRE_ERROR";
const SET_QUESTION_QUESTIONNAIRE_ERROR = "surveyErrors/SET_QUESTION_QUESTIONNAIRE_ERROR";

interface SurveyErrorState {
  globalPanelErrors: ErrorMessage[];
  criteriaPanelErrors: ErrorMessage[];
  globalQuestionnaireErrors: ErrorMessage[];
  questionQuestionnaireErrors: ErrorMessage[];
}

const defaultState: SurveyErrorState = {
  globalPanelErrors: [],
  criteriaPanelErrors: [],
  globalQuestionnaireErrors: [],
  questionQuestionnaireErrors: [],
};

type ErrorCode = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;

export interface ErrorElement {
  errorCode: number;
  elementId: ID;
  elementName?: string;
}

interface SurveyErrorAction {
  type: string;
  payload: ErrorMessage[];
  meta: boolean;
}

export interface ErrorMessage {
  message: string;
  code: ErrorCode;
  questionId?: ID;
  criterionId?: ID;
  meta?: {
    lower_range: number;
    participants_found: number;
    participants_needed: number;
    upper_range: number;
  };
  elementId: ID;
}

interface ErrorWrappingElement {
  questionnaire: {
    global: ErrorMessage[];
    questions: ErrorMessage[];
  };
  panel: {
    global: ErrorMessage[];
    criteria: ErrorMessage[];
  };
}

export const actions = {
  setGlobalPanelError: (codes: ErrorMessage[]) => ({
    type: SET_GLOBAL_PANEL_ERROR,
    payload: codes
  } as const),
  setCriteriaPanelError: (codes: ErrorMessage[]) => ({
    type: SET_CRITERIA_PANEL_ERROR,
    payload: codes
  } as const),
  setGlobalQuestionnaireError: (codes: ErrorMessage[]) => ({
      type: SET_GLOBAL_QUESTIONNAIRE_ERROR,
      payload: codes
    } as const
  ),
  setQuestionQuestionnaireError: (codes: ErrorMessage[]) => ({
    type: SET_QUESTION_QUESTIONNAIRE_ERROR,
    payload: codes
  } as const),
};

export const setGlobalPanelError = (codes: ErrorMessage[]) => {
  return {
    type: SET_GLOBAL_PANEL_ERROR,
    codes
  } as const;
};

export const setCriteriaPanelError = (codes: ErrorMessage[]) => {
  return {
    type: SET_CRITERIA_PANEL_ERROR,
    codes
  } as const;
};
export const setGlobalQuestionnaireError = (codes: ErrorMessage[]) => {
  return {
    type: SET_GLOBAL_QUESTIONNAIRE_ERROR,
    codes
  } as const;
};

export const setQuestionQuestionnaireError = (codes: ErrorMessage[]) => {
  return {
    type: SET_QUESTION_QUESTIONNAIRE_ERROR,
    codes
  } as const;
};

const getUniqueArray = (arr: any, comp: any) => {
  const unique = arr
    .map((errorMessage: any) => errorMessage[comp])
    .map((errorMessage: any, i: any, final: any) => final.indexOf(errorMessage) === i && i)
    .filter((errorMessage: any) => arr[errorMessage])
    .map((errorMessage: any) => arr[errorMessage]);
  return unique;
};

export const setErrorElementOfResponses = (
  data: ErrorWrappingElement,
  dispatch: Dispatch,
  surveyParticipantPoolSize: number
) => {
  let globalQuestionnaireErrors: ErrorMessage[] = [];
  let panelCriteriaErrors: ErrorMessage[] = [];
  let questionQuestionnaireErrors: ErrorMessage[] = [];
  let globalPanelErrors: ErrorMessage[] = [];

  if (Boolean(data.questionnaire.global.length)) {
    globalQuestionnaireErrors = data.questionnaire.global.map((item) => ({
      code: item.code,
      message: i18next.t(translations.errorhandling[item.code]),
      elementId: "",
    }));
  }
  if (Boolean(data.questionnaire.questions.length)) {
    questionQuestionnaireErrors = data.questionnaire.questions.map((item) => ({
      code: item.code,
      message: i18next.t(translations.errorhandling[item.code]),
      elementId: item.questionId!,
    }));
  }
  if (Boolean(data.panel.global.length)) {
    globalPanelErrors = data.panel.global.map((item) => ({
      code: item.code,
      message: i18next.t(translations.errorhandling[item.code], {
        amount: surveyParticipantPoolSize,
      }),
      elementId: "",
    }));
  }
  if (Boolean(data.panel.criteria.length)) {
    const uniqueErrorArray = getUniqueArray(data.panel.criteria, "elementId");
    panelCriteriaErrors = uniqueErrorArray.map((item: ErrorMessage) => {
      if (item.code === 14) {
        return {
          code: item.code,
          message: `Es wurden zu wenige passende Teilnehmer für die Altersgruppe ${
            item.meta!.lower_range
          } - ${
            item.meta!.upper_range
          } gefunden. verringern Sie die Panelgröße oder wählen Sie einen geringeren Anteil dieser Altersgruppe.`,
          elementId: item.criterionId!,
        };
      }

      return {
        code: item.code,
        message: i18next.t(translations.errorhandling[item.code]),
        elementId: item.criterionId!,
      };
    });
  }

  //@ts-ignore
  dispatch(actions.setGlobalQuestionnaireError(globalQuestionnaireErrors));
  dispatch(actions.setCriteriaPanelError(panelCriteriaErrors));
  //@ts-ignore
  dispatch(actions.setQuestionQuestionnaireError(questionQuestionnaireErrors));
  dispatch(actions.setGlobalPanelError(globalPanelErrors));
};

export const reducer = (
  state: SurveyErrorState = defaultState,
  action: SurveyErrorAction
): SurveyErrorState => {
  switch (action.type) {
    case SET_GLOBAL_PANEL_ERROR:
      return {...state, globalPanelErrors: action.payload};
    case SET_CRITERIA_PANEL_ERROR:
      return {...state, criteriaPanelErrors: action.payload};
    case SET_GLOBAL_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        globalQuestionnaireErrors: action.payload,
      };
    case SET_QUESTION_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        questionQuestionnaireErrors: action.payload,
      };
    default:
      return state;
  }
};
