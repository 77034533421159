import React, { PureComponent } from "react";
import {
  createStyles,
  Grid,
  IconButton,
  Radio,
  TextField,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Question, { QuestionBody } from "../../entities/Question";
import { blueGradient, QuopinionTheme, secondary } from "../../constants/Theme";
import { compose } from "recompose";
import RadioElement from "./components/RadioButton";
import CheckboxElement from "./components/Checkbox";
import Button from "../Layout/components/Button";
import { translations } from "../../constants/lang/translation";
import { WithTranslation, withTranslation } from "react-i18next";
import { AccordionArrow, Star } from "../../assets/icon";
import StepSlider from "../Layout/components/StepSlider";
import Card from "../Layout/components/Card";
import Media from "../../entities/Media";
import MediaDisplay from "./components/MediaDisplay";
import classNames from "classnames";
import { FieldTypes } from "../../entities/AnswerOption";

interface OwnProps {
  title: string;
  body: QuestionBody;
  type: string;
  media: Media[];
  questionNumber: number;
  totalQuestionNumber: number;
  buttonFunction?: () => void;
  buttonText?: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    header: {
      background: blueGradient,
      paddingTop: theme.spacing(18),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(6),
      paddingRight: theme.spacing(4),
      flexGrow: (props: OwnProps) => (props.type === "instruction" ? 1 : 0),
      color: theme.palette.common.white,
    },
    body: {
      backgroundColor: theme.palette.grey[100],
      paddingTop: theme.spacing(8),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(10),
      paddingRight: theme.spacing(4),
      flexGrow: 1,
    },
    navigation: {
      marginBottom: theme.spacing(6),
    },
    button: {
      borderRadius: 0,
      paddingTop: theme.spacing(5.25),
    },
    wrapper: {
      height: "100%",
      overflowY: "scroll",
      msOverflowStyle: "none",
    },
    starIcon: {
      margin: theme.spacing(3),
    },
    scaleCard: {
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    },
    scaleNaming: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "-25px",
    },
    scaleValue: {
      display: "flex",
      marginTop: theme.spacing(3),
      alignItems: "center",
      "&::-webkit-scrollbar": "none",
    },
    scaleInput: {
      width: 70,
      marginLeft: theme.spacing(4),
    },
    image: {
      width: 320,
    },
    textfield: {
      maxWidth: theme.spacing(57.25),
      "& div": {
        "& textarea": {
          overflow: "auto",
        },
      },
    },
    starGrid: {
      marginBottom: theme.spacing(4),
    },
    title: {
      overflowWrap: "break-word",
    },
    link: {
      color: secondary,
    },
    linkText: {
      overflowWrap: "break-word",
      marginTop: theme.spacing(8),
    },
    matrixAnswerTitle: {
      overflowWrap: "break-word",
      fontWeight: 400,
    },
    matrixAnswerTitleWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(2.5),
      "&:first-of-type": {
        marginTop: theme.spacing(0),
      },
    },
    questionBodyToggle: {
      color: "#fff",
      height: 32,
      width: 32,
      padding: theme.spacing(1),
      transition: "transform 0.3s ease-in-out",
      marginLeft: theme.spacing(4),
      "@media(max-width:414px)": {
        marginLeft: theme.spacing(2.5),
      },
    },
    toggleIcon: {
      width: 16,
    },
    questionBodyOpen: {
      transform: "rotate(180deg)",
    },
    rankingItem: {
      backgroundColor: "#fff",
      padding: theme.spacing(4),
      marginLeft: theme.spacing(0),
      marginBottom: theme.spacing(0.5),
      lineHeight: 1.71,
      fontWeight: 400,
    },
  });

type Props = OwnProps & Question & WithStyles<typeof styles> & WithTranslation;

class QuestionPreview extends PureComponent<Props> {
  render() {
    const {
      title,
      classes,
      type,
      body,
      t,
      media,
      questionNumber,
      totalQuestionNumber,
      buttonFunction,
      buttonText,
    } = this.props;

    return (
      <Grid container={true} direction="column" className={classes.wrapper} wrap="nowrap">
        <div className={classes.header}>
          <Typography variant="body1" color="secondary">
            {questionNumber}/ {totalQuestionNumber}
          </Typography>
          {type !== "instruction" && (
            <Typography variant="h2" className={classes.title}>
              {title}
            </Typography>
          )}
          {type === "instruction" && (
            <Typography variant="h2" className={classes.title}>
              {body.text}
            </Typography>
          )}
          {type === "instruction" && body.link !== null && body.link && body.link.length > 0 && (
            <a href={body.link} target="_blank" rel="noopener noreferrer" className={classes.link}>
              <Typography variant="h2" className={classes.linkText}>
                {body.link}
              </Typography>
            </a>
          )}
          {media.length > 0 && <MediaDisplay media={media} />}
        </div>
        {type !== "instruction" && (
          <div className={classes.body}>
            {type === "singleChoice" && (
              <>
                {body.answerOptions &&
                  body.answerOptions.map((answer) => (
                    <RadioElement
                      key={answer.id}
                      value={answer.id}
                      label={answer.label}
                      control={<Radio />}
                      // Checkbox is always false, state is not changeable
                      // mf should not interact
                      checked={false}
                      onChange={() => {
                        return;
                      }}
                      children={
                        <>
                          {answer.media.length > 0 && (
                            <MediaDisplay media={answer.media} answerOption={true} />
                          )}
                          {answer.additionalFieldType === FieldTypes.TEXT && (
                            <TextField
                              id="standard-name"
                              placeholder={t(translations.questionnaire.freeText.infoBodyText)}
                              value={""}
                              margin="normal"
                              fullWidth={true}
                              multiline={true}
                              rows={5}
                              disabled={true}
                            />
                          )}
                          {answer.additionalFieldType === FieldTypes.NUMBER && (
                            <TextField
                              id="standard-name"
                              placeholder={t(translations.questionnaire.choice.numericalField)}
                              value={""}
                              margin="normal"
                              fullWidth={true}
                              disabled={true}
                            />
                          )}
                        </>
                      }
                      withoutMargin={true}
                    />
                  ))}
                {body.additionalAnswerEnabled && (
                  <>
                    <RadioElement
                      key={"answer.id"}
                      value={"other"}
                      label={
                        <div>
                          Sonstiges
                          <div>
                            <TextField
                              placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                              fullWidth={true}
                              multiline={true}
                              rows={5}
                              style={{ marginTop: 16 }}
                              disabled={true}
                              className={classes.textfield}
                            />
                          </div>
                        </div>
                      }
                      control={<Radio />}
                      // Radio is always false, state is not changeable
                      // mf should not interact
                      checked={false}
                      onChange={() => {
                        return;
                      }}
                      withoutMargin={true}
                    />
                  </>
                )}
              </>
            )}
            {type === "multipleChoice" && (
              <>
                {body.answerOptions &&
                  body.answerOptions.map((answer) => (
                    <CheckboxElement
                      key={answer.id}
                      answerValue={answer.id}
                      label={answer.label}
                      // Checkbox is always false, state is not changeable
                      // mf should not interact
                      checked={false}
                      onChange={() => {
                        return;
                      }}
                      children={
                        <>
                          {answer.media.length > 0 && (
                            <MediaDisplay media={answer.media} answerOption={true} />
                          )}
                          {answer.additionalFieldType === FieldTypes.TEXT && (
                            <TextField
                              id="standard-name"
                              placeholder={t(translations.questionnaire.freeText.infoBodyText)}
                              value={""}
                              margin="normal"
                              fullWidth={true}
                              multiline={true}
                              rows={5}
                              disabled={true}
                            />
                          )}
                          {answer.additionalFieldType === FieldTypes.NUMBER && (
                            <TextField
                              id="standard-name"
                              placeholder={t(translations.questionnaire.choice.numericalField)}
                              value={""}
                              margin="normal"
                              fullWidth={true}
                              disabled={true}
                            />
                          )}
                        </>
                      }
                      headerAlignItems={"center"}
                    />
                  ))}
                {body.additionalAnswerEnabled && (
                  <>
                    <CheckboxElement
                      key={"answer.id"}
                      answerValue={"other"}
                      label={"Sonstiges"}
                      // Checkbox is always false, state is not changeable
                      // mf should not interact
                      checked={false}
                      onChange={() => {
                        return;
                      }}
                      children={
                        <TextField
                          placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                          fullWidth={true}
                          multiline={true}
                          rows={5}
                          style={{ marginTop: 16 }}
                          disabled={true}
                        />
                      }
                      headerAlignItems={"center"}
                    />
                  </>
                )}
              </>
            )}
            {(type === "freeText" || type === "password") && (
              <TextField
                placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                fullWidth={true}
                multiline={true}
                rows={5}
                autoFocus={true}
                disabled={true}
              />
            )}
            {type === "starRating" && (
              <Grid container={true} justify="center" className={classes.starGrid}>
                {Array.from(new Array(body.starCount)).map((star, index) => (
                  <Star
                    fill="#bfbfbf"
                    key={index}
                    height={32}
                    width={32}
                    className={classes.starIcon}
                  />
                ))}
              </Grid>
            )}
            {type === "stepRating" && (
              <Card isRaised={true} className={classes.scaleCard}>
                <StepSlider
                  min={body.scale.min}
                  max={body.scale.max}
                  value={Math.round((body.scale.min + body.scale.max) / 2)}
                />
                <div className={classes.scaleNaming}>
                  <Typography>{body.labels.left}</Typography>
                  <Typography>{body.labels.middle}</Typography>
                  <Typography>{body.labels.right}</Typography>
                </div>
                {body.showNumericFeedback && (
                  <div className={classes.scaleValue}>
                    <Typography>Gewählter Wert:</Typography>
                    <TextField
                      value={Math.round((body.scale.min + body.scale.max) / 2)}
                      classes={{ root: classes.scaleInput }}
                    />
                  </div>
                )}
              </Card>
            )}
            {type === "matrix" && (
              <>
                {body.subQuestions &&
                  body.subQuestions.map((answerItem) => (
                    <div key={answerItem.id}>
                      <div className={classes.matrixAnswerTitleWrapper}>
                        <Typography variant="h2" className={classes.matrixAnswerTitle}>
                          {answerItem.label}
                        </Typography>

                        <IconButton
                          aria-label="up"
                          className={classNames({
                            [classes.questionBodyToggle]: true,
                            [classes.questionBodyOpen]: true,
                          })}
                          classes={{ label: classes.toggleIcon }}
                        >
                          <AccordionArrow fill="#6d7278" height={25} width={32} />
                        </IconButton>
                      </div>
                      {body.answerOptions &&
                        body.answerOptions.map((answer) => (
                          <RadioElement
                            key={answer.id}
                            value={answer.id}
                            label={answer.label}
                            control={<Radio />}
                            // Radiobutton is always false, state is not changeable
                            // Mf should not interact
                            checked={false}
                            onChange={() => {
                              return;
                            }}
                            withoutMargin={true}
                          />
                        ))}
                    </div>
                  ))}
              </>
            )}
            {type === "ranking" && (
              <>
                {body.answerOptions &&
                  body.answerOptions.map((answer) => (
                    <div className={classes.rankingItem} key={answer.id}>
                    {answer.label}
                    {answer.media.length > 0 && <MediaDisplay media={answer.media} answerOption={true} withoutMediaMargin={true} /> }
                    </div>
                  ))}
              </>
            )}
          </div>
        )}
        <Button
          color="secondary"
          contained={true}
          fullWidth={true}
          size="big"
          className={classes.button}
          onClick={buttonFunction}
        >
          {buttonText ? buttonText : t(translations.action.next)}
        </Button>
      </Grid>
    );
  }
};

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(QuestionPreview);
