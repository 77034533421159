import React from "react";
import { BarChart, XAxis, YAxis, Bar, Tooltip, Cell, ResponsiveContainer } from "recharts";
import { DataItem } from "./QuestionResultItem";
import YAxisValueTooltip from "./YAxisValueTooltip";

interface Props {
  data: DataItem[];
  colors: string[];
}

const HorizontalBarDiagram: React.FC<Props> = ({ data, colors }: Props) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data} barCategoryGap="15%" layout="vertical" margin={{ left: 20 }}>
        <XAxis type="number" />
        {/*//@ts-ignore*/}
        <YAxis type="category" dataKey="name" tick={YAxisValueTooltip} />
        <Tooltip />
        <Bar background={true} label={false} dataKey="value">
          {data.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBarDiagram;
