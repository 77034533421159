import * as React from "react";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { RootState } from "../../../modules";
import {
  fetchPanelConfigurationSettingIds,
  setSurveyTitle,
  fetchTransientSurvey,
  fetchTransientSurveyWithSurveyToken,
  actions,
  updateTransientSurvey,
} from "../../../modules/survey";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import StepStart from "./StepStart";
import StepPanel from "./StepPanel/";
import StepQuestionnaire from "./StepQuestionnaire";
import SurveyIntroduction from "./StepIntroduction";
import StepSummary from "./StepSummary/";
import Questionnaire from "../../../entities/Questionnaire";
import i18next from "i18next";
import { translations } from "../../../constants/lang/translation";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { primary, QuopinionTheme } from "../../../constants/Theme";
import { ChangeEvent } from "react";
import SurveyTitelModification from "./SurveyTitelModification";
import ID from "../../../entities/ID";
import { withAuthorization } from "../../../common/hoc/withAuthorization";
import { UserRole } from "../../../entities/User";
import Panel from "../../../entities/Panel";
import Survey from "../../../entities/Survey";
import isEqual from "lodash/isEqual";

export interface CreationStep {
  title: string;
  description: string;
  link: string;
}

interface OwnProps {}

interface StateProps {
  transientQuestionnaire: Questionnaire;
  transientSurveyId: ID;
  surveyTitle: string;
  isAuthenticated: boolean | undefined;
  token: string;
  panel: Panel;
  panelConfiguration: [];
}

interface DispatchProps {
  fetchPanelConfigurationSettingIds: () => Promise<void>;
  setSurveyTitle: typeof setSurveyTitle;
  fetchTransientSurvey: typeof fetchTransientSurvey;
  fetchTransientSurveyWithSurveyToken: typeof fetchTransientSurveyWithSurveyToken;
  resetSurveyStore: typeof actions.resetSurveyStore;
  updateTransientSurvey: (transientSurvey: any) => Promise<void>;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    textInput: {
      color: primary,
      fontWeight: 600,
      "&:focus": {
        color: `${primary} !important`,
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
      },
    },
    inputRoot: {
      paddingLeft: theme.spacing(8),
      flexGrow: 1,
    },
    wrapperStepQuestionnaire: {
      display: "flex",
      flexDirection: "column",
    },
    conditionPositioning: {
      alignSelf: "center",
    },
  });

const creationSteps = [
  {
    title: "1. " + i18next.t(translations.pages.Survey.introduction.explanation.first.title),
    description: i18next.t(translations.pages.Survey.introduction.explanation.first.description, {
      break: "<br/><br/>",
    }),
    link: "/market-research/survey-creator/panel",
    disabledIndicator: "panel",
  },
  {
    title: "2. " + i18next.t(translations.pages.Survey.introduction.explanation.second.title),
    description: i18next.t(translations.pages.Survey.introduction.explanation.second.description, {
      break: "<br/><br/>",
    }),
    link: "/market-research/survey-creator/questionnaire/",
    disabledIndicator: "questionnaire",
  },
  {
    title: "3. " + i18next.t(translations.pages.Survey.introduction.explanation.third.title),
    description: i18next.t(translations.pages.Survey.introduction.explanation.third.description),
    link: "/market-research/survey-creator/summary",
    disabledIndicator: "summary",
  },
];

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithTranslation &
  RouteComponentProps<{
    step: string;
    surveyId: string;
  }> &
  WithStyles<typeof styles>;

export const route: string = "/market-research/survey-creator";

class SurveyCreatorPage extends React.Component<Props> {
  titleChangeTimeout: number = 0;

  constructor(props: Props) {
    super(props);
    if (
      this.props.match.url.includes("panel") ||
      this.props.match.url.includes("questionnaire") ||
      this.props.match.url.includes("summary")
    ) {
      this.props.fetchPanelConfigurationSettingIds().then(() => {
        if (!this.props.match.url.includes("intro") && this.props.match.url !== route) {
          const surveyToken = localStorage.getItem("surveyToken");
          if (this.props.isAuthenticated && Boolean(this.props.match.params.surveyId)) {
            this.props.fetchTransientSurvey(this.props.match.params.surveyId);
          }
          if (!this.props.isAuthenticated && surveyToken) {
            this.props.fetchTransientSurveyWithSurveyToken(
              this.props.match.params.surveyId,
              surveyToken
            );
          }
        }
      });
    }
  }

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !isEqual(nextProps, this.props);
  }

  generateRouteForStep(step: string): string {
    const { transientSurveyId } = this.props;

    if (!this.props.match.params.surveyId) {
      if (this.props.location.pathname.endsWith("/")) {
        return (
          this.props.location.pathname.replace(`/${this.props.match.params.step}`, "") +
          step +
          "/" +
          transientSurveyId
        );
      }
      return (
        this.props.location.pathname.replace(`/${this.props.match.params.step}`, "") +
        "/" +
        step +
        "/" +
        transientSurveyId
      );
    } else {
      if (this.props.location.pathname.endsWith("/")) {
        return (
          this.props.location.pathname.replace(
            `/${this.props.match.params.step}/${this.props.match.params.surveyId}`,
            ""
          ) +
          step +
          "/" +
          transientSurveyId
        );
      }
      return (
        this.props.location.pathname.replace(
          `/${this.props.match.params.step}/${this.props.match.params.surveyId}`,
          ""
        ) +
        "/" +
        step +
        "/" +
        transientSurveyId
      );
    }
  }

  handlePrev = () => {
    switch (this.props.match.params.step) {
      case "start":
        return this.props.history.push(this.generateRouteForStep("intro"));
      case "summary":
        return this.props.history.push(this.generateRouteForStep("questionnaire"));
      case "questionnaire":
        return this.props.history.push(this.generateRouteForStep("panel"));
      case "panel":
        return this.props.history.push(this.generateRouteForStep("start"));
    }
  };

  handleNext = () => {
    switch (this.props.match.params.step) {
      case "intro":
        return this.props.history.push(this.generateRouteForStep("start"));
      case "start":
        return this.props.history.push(this.generateRouteForStep("panel"));
      case "panel":
        return this.props.history.push(this.generateRouteForStep("questionnaire"));
      case "questionnaire":
        return this.props.history.push(this.generateRouteForStep("summary"));
    }
  };

  renderStep(props: Props) {
    switch (this.props.match.params.step) {
      case "intro":
        return <SurveyIntroduction handleNext={this.handleNext} />;
      case "start":
        return <StepStart handleNext={this.handleNext} />;
      case "panel":
        return (
          this.props.panel.id !== undefined &&
          this.props.panel.age.attributeId !== null &&
          this.props.panel.age.attributeId.trim().length > 0 && (
            <StepPanel
              handlePrev={this.handlePrev}
              handleNext={this.handleNext}
              stepConfiguration={creationSteps.find((step) => step.link.includes("panel"))}
            />
          )
        );
      case "questionnaire":
        return (
          this.props.panel.id !== undefined &&
          this.props.transientQuestionnaire.id !== undefined && (
            <div className={props.classes.wrapperStepQuestionnaire}>
              <div id={"stylingWrapper"} className={props.classes.conditionPositioning}></div>
              <StepQuestionnaire
                handlePrev={this.handlePrev}
                handleNext={this.handleNext}
                stepConfiguration={creationSteps.find((step) =>
                  step.link.includes("questionnaire")
                )}
              />
            </div>
          )
        );
      case "summary":
        return (
          <StepSummary
            handlePrev={this.handlePrev}
            stepConfiguration={creationSteps.find((step) => step.link.includes("summary"))}
          />
        );
      default:
        return <Redirect to={this.generateRouteForStep("intro")} />;
    }
  }

  handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      updateTransientSurvey,
      transientQuestionnaire,
      panel,
      transientSurveyId,
      token,
    } = this.props;
    const title = event.target.value;

    this.props.setSurveyTitle(title);
    updateTransientSurvey(
      new Survey({
        id: transientSurveyId,
        title,
        questionnaire: transientQuestionnaire,
        panel,
        token,
      }).toDataJson()
    );
  };

  render() {
    if (
      !this.props.match.params.surveyId &&
      Boolean(this.props.match.params.step) &&
      this.props.match.params.step !== "intro"
    ) {
      if (this.props.match.params.step !== "start") {
        return <Redirect to="/market-research/dashboard" />;
      }
    }

    const creationStepsInternal = [
      {
        title: "1. " + i18next.t(translations.pages.Survey.introduction.explanation.first.title),
        description: i18next.t(
          translations.pages.Survey.introduction.explanation.first.description,
          {
            break: "<br/><br/>",
          }
        ),
        link: `/market-research/survey-creator/panel/${this.props.transientSurveyId}`,
        disabledIndicator: "panel",
      },
      {
        title: "2. " + i18next.t(translations.pages.Survey.introduction.explanation.second.title),
        description: i18next.t(
          translations.pages.Survey.introduction.explanation.second.description,
          {
            break: "<br/><br/>",
          }
        ),
        link: `/market-research/survey-creator/questionnaire/${this.props.transientSurveyId}`,
        disabledIndicator: "questionnaire",
      },
      {
        title: "3. " + i18next.t(translations.pages.Survey.introduction.explanation.third.title),
        description: i18next.t(
          translations.pages.Survey.introduction.explanation.third.description
        ),
        link: `/market-research/survey-creator/summary/${this.props.transientSurveyId}`,
        disabledIndicator: "summary",
      },
    ];

    return (
      <>
        {this.props.match.params.step === "intro" ? (
          <LogoBarResearcher backButton={true} linkBorders={true} />
        ) : this.props.match.params.step === "start" ? (
          <LogoBarResearcher
            links={creationSteps}
            linkBorders={true}
            backButton={true}
            flexStartView={true}
          />
        ) : (
          <LogoBarResearcher
            hideOnMobile={true}
            links={creationStepsInternal}
            linkBorders={true}
            backButton={true}
            children={
              <SurveyTitelModification
                savedSurveyTitle={this.props.surveyTitle}
                handleTitleChange={this.handleTitleChange}
              />
            }
            flexStartView={true}
          />
        )}

        {this.renderStep(this.props)}
      </>
    );
  }
}

const mapStateToProps = ({ survey, session }: RootState, ownProps: any) => {
  return {
    isAuthenticated: session.authenticated,
    transientQuestionnaire: survey.questionnaire,
    transientSurveyId: survey.id,
    surveyTitle: survey.title,
    panel: survey.panel,
    token: survey.token && survey.token,
    panelConfiguration: survey.panelConfiguration,
  };
};

const mapDispatchToProps = {
  setSurveyTitle,
  fetchPanelConfigurationSettingIds,
  fetchTransientSurvey,
  fetchTransientSurveyWithSurveyToken,
  resetSurveyStore: actions.resetSurveyStore,
  updateTransientSurvey,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  withAuthorization(UserRole.RESEARCHER),
  connect(mapStateToProps, mapDispatchToProps)
)(SurveyCreatorPage);
