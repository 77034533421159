import React from "react";
import { createStyles, Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../constants/Theme";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { UspItem } from "./UspItem";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    text: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
      "@media(max-width: 600px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      "@media (min-width: 600px)": {
        padding: `${theme.spacing(8)}px ${theme.spacing(0)}px ${theme.spacing(
          10
        )}px ${theme.spacing(33.25)}px`,
      },
      "@media (min-width: 960px)": {
        padding: `${theme.spacing(8)}px ${theme.spacing(0)}px ${theme.spacing(
          10
        )}px ${theme.spacing(25)}px`,
      },
    },
    description: {
      color: theme.palette.grey[400],
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(6)}px`,
      textAlign: "center",
      "@media (min-width: 600px)": {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(6)}px`,
        maxWidth: "unset",
        textAlign: "unset",
      },
      maxWidth: theme.spacing(95),
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.71,
    },
    wrapper: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      maxWidth: theme.spacing(250),
      marginTop: theme.spacing(15),
      "@media(min-width: 600px)": {
        marginTop: "unset",
      },
    },
    headline: {
      fontFamily: "catamaranBold",
      fontSize: "24px",
      fontWeight: 200,
      lineHeight: 1.25,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "normal",
      maxWidth: 360,
      marginBottom: theme.spacing(3),
      "@media(min-width: 600px)": {
        textAlign: "unset",
        maxWidth: theme.spacing(50),
        marginBottom: "unset",
      },
      "@media(min-width: 725px)": {
        maxWidth: theme.spacing(75),
      },
      "@media(min-width: 960px)": {
        maxWidth: theme.spacing(137.5),
      },
      "@media(min-width: 1024px)": {
        fontWeight: 200,
        lineHeight: 1.1,
      },
    },
    children: {
      "@media(max-width: 599px)": {
        display: "flex",
        justifyContent: "center",
      },
    },
    uspRow: {
      display: "flex",
      "@media(max-width: 599px)": {
        alignItems: "center",
        flexDirection: "column",
      },
    },
  });

interface OwnProps {
  title: string;
  subtitle: string;
  number: string;
  color: string;
  children: React.ReactNode;
}
type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const UspComponent: React.FC<Props> = ({ classes, title, subtitle, number, color, children }) => {
  return (
    <Grid container={true} className={classes.wrapper}>
      {/*child left element*/}
      <Grid item={true} xs={12} sm={2} md={2} lg={2} className={classes.children}>
        {children && children}
      </Grid>
      {/*right side text*/}
      <Grid item={true} xs={12} sm={10} md={10} lg={10} className={classes.text}>
        <div className={classes.uspRow}>
          <UspItem number={number} color={color} />
          <Typography variant="h2" className={classes.headline} color="primary">
            {title}
          </Typography>
        </div>
        <Typography variant="body1" color="inherit" className={classes.description}>
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const UspRow = compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(UspComponent);
