import React from "react";
import { createStyles, Typography, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme, red } from "../../../../constants/Theme";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";
import Button from "../../../../common/Layout/components/Button";
import { translations } from "../../../../constants/lang/translation";
import * as Table from "../../../../common/Layout/components/CardTable";
import { Play } from "../../../../assets/icon";
import Card from "../../../../common/Layout/components/Card";
import QuestionResultItem from "./components/QuestionResultItem";
import { RootState } from "../../../../modules";
import { connect } from "react-redux";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import { SurveyResult, EvaluatedQuestion } from "../../../../entities/SurveyResult";
import ResultFilter from "./components/ResultFilter";
import Services from "../../../../services/Services";

interface OwnProps {
  urlSurveyId: string;
}

interface State {
  csvDownloadSuccess: boolean;
  csvDownloadError: boolean;
  csvDownloadRequesting: boolean;
  printButtonDisabled: boolean;
  csvPanelInfoRequesting: boolean;
  csvPanelInfoSuccess: boolean;
  csvPanelInfoError: boolean;
}

interface DispatchProps {}

interface StateProps {
  surveyResults: SurveyResult;
  isLoading: boolean;
  surveyId: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    headlineRow: {
      display: "flex",
      justifyContent: "space-between",
      width: theme.spacing(100),
      "@media (max-width: 768px)": {
        width: theme.spacing(29),
      },
    },
    selectMenu: {
      width: 100,
      "@media (max-width: 768px)": {
        width: theme.spacing(13),
      },
      "&$active": {
        borderRadius: "4px",
      },
      "&:focus": {
        borderRadius: "4px",
      },
    },
    select: {
      position: "relative",
      "& svg": {
        width: 20,
        top: 1,
        right: 8,
        position: "absolute",
        height: "calc(100% - 2px)",
        pointerEvents: "none",
      },
      textTransform: "initial",
    },
    headlineItemsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: theme.spacing(142.5),
      alignItems: "flex-end",
      textTransform: "capitalize",
      "@media (max-width: 890px)": {
        flexDirection: "column",
        paddingLeft: "19px",
        width: theme.spacing(29),
        height: theme.spacing(45),
        alignItems: "flex-end",
      },
    },
    link: {
      textDecoration: "none",
    },
    errorTextWrapper: {
      marginTop: 20,
      textTransform: "initial",
      textAlign: "end",
      color: red,
    },
  });

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles> & WithTranslation;

class SurveyResults extends React.Component<Props, State> {
  state = {
    csvDownloadSuccess: false,
    csvDownloadError: false,
    csvDownloadRequesting: false,
    printButtonDisabled: false,
    csvPanelInfoRequesting: false,
    csvPanelInfoSuccess: false,
    csvPanelInfoError: false,
  };
  getCSVSurveyResult = (surveyId: string) => {
    this.setState({
      csvDownloadRequesting: true,
    });
    Services.survey
      .getCSVResultData(surveyId, {}) //body empty as decision: CSV export for all data and no filter
      .then(() =>
        this.setState({
          csvDownloadSuccess: true,
          csvDownloadRequesting: false,
          csvDownloadError: false,
        })
      )
      .catch(() => {
        this.setState({
          csvDownloadError: true,
          csvDownloadRequesting: false,
        });
      });
  };

  getCSVPanelInformation = (surveyId: string) => {
    this.setState({
      csvPanelInfoRequesting: true,
    });
    Services.survey
      .getCSVPanelData(surveyId, {})
      .then(() =>
        this.setState({
          csvPanelInfoSuccess: true,
          csvPanelInfoRequesting: false,
          csvPanelInfoError: false,
        })
      )
      .catch(() => {
        this.setState({
          csvPanelInfoError: true,
          csvPanelInfoRequesting: false,
        });
      });
  };

  openPrintModule = () => {
    this.setState({
      printButtonDisabled: true,
    });
    var event = new CustomEvent("quopinionResultsPrint");
    window.dispatchEvent(event);
    setTimeout(() => {
      window.print();
      this.setState({
        printButtonDisabled: false,
      });
    }, 1000);
  };

  renderHeader = (props: Props) => {
    const { t, classes, urlSurveyId } = this.props;
    const {
      printButtonDisabled,
      csvDownloadRequesting,
      csvDownloadError,
      csvPanelInfoRequesting,
      csvPanelInfoError,
    } = this.state;
    return (
      <Table.HeaderRow
        title={t(translations.questionnaire.yourQuestionnaire)}
        actionArea={
          <>
            <div className={classes.headlineItemsWrapper}>
              <ResultFilter />
              {/* panel information */}
              <Button
                contained={true}
                size="small"
                color="primary"
                onClick={this.getCSVPanelInformation.bind(this, urlSurveyId)}
                disabled={csvPanelInfoRequesting}
                icon={<Play fill="#fff" width={12} height={12} />}
              >
                {t(translations.action.panelInfos)}
              </Button>
              {/* csv download */}
              <Button
                contained={true}
                size="small"
                color="primary"
                onClick={this.getCSVSurveyResult.bind(this, urlSurveyId)}
                disabled={csvDownloadRequesting}
                icon={<Play fill="#fff" width={12} height={12} />}
              >
                {t(translations.action.csvDownload)}
              </Button>
              {/* print */}
              <Button
                contained={true}
                disabled={printButtonDisabled}
                size="small"
                color="primary"
                onClick={this.openPrintModule.bind(this)}
              >
                {t(translations.action.print)}
              </Button>
            </div>
            {(csvPanelInfoError || csvDownloadError) && (
              <Typography className={classes.errorTextWrapper} variant={"body1"}>
                {t(translations.questionnaire.downloadError)}
              </Typography>
            )}
          </>
        }
      />
    );
  };
  render() {
    const { isLoading, surveyResults } = this.props;
    return (
      <Card isRaised={false}>
        {isLoading && <LoadingOverlay />}
        {this.renderHeader(this.props)}
        {!isLoading &&
          surveyResults.evaluatedQuestions.length > 0 &&
          surveyResults.evaluatedQuestions.map((question: EvaluatedQuestion, index: number) => (
            <QuestionResultItem
              key={question.question.id}
              question={question}
              totalCompletedParticipations={surveyResults.numberOfCompletedParticipations}
              index={index}
            />
          ))}
      </Card>
    );
  }
}

const mapStateToProps = ({ survey }: RootState) => ({
  surveyResults: survey.surveyResults,
  isLoading: survey.isLoading,
  surveyId: survey.id,
});

const mapDispatchToProps = {};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect<{}, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(SurveyResults);
