import React, { useState } from "react";
import Question from "../../../../entities/Question";
import MediaDisplay from "../../../../common/Preview/components/MediaDisplay";
import {
  createStyles,
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import RadioElement from "../../../../common/Preview/components/RadioButton";
import { withTranslation, WithTranslation } from "react-i18next";
import { MatrixAnswer } from "../../../../entities/SurveyAnswers";
import { isChecked } from "../isChecked";
import ID from "../../../../entities/ID";
import classNames from "classnames";
import { AccordionArrow } from "../../../../assets/icon";
import { compose } from "recompose";
import theme, { QuopinionTheme } from "../../../../constants/Theme";
import MatrixSubQuestion from "../../../../entities/MatrixSubQuestion";

interface OwnProps {
  question: Question;
  currentAnswer?: MatrixAnswer;
  handleUserAnswer: (questionID: ID, answer?: MatrixAnswer) => void;
  previewMobileView?: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    matrixAnswerTitle: {
      overflowWrap: "break-word",
      fontWeight: 400,
      maxWidth: 220,
      "@media(min-width:320px)": {
        maxWidth: 230,
      },
      "@media(min-width:360px)": {
        maxWidth: 270,
      },
      "@media(min-width:375px)": {
        maxWidth: 285,
      },
      "@media(min-width:414px)": {
        maxWidth: 324,
      },
      "@media(min-width:550px)": {
        maxWidth: 454,
      },
      "@media(min-width:600px)": {
        maxWidth: 503,
      },
      "@media(min-width:768px)": {
        maxWidth: 670,
      },
      "@media(min-width:870px)": {
        maxWidth: 774,
      },
    },
    matrixAnswerTitleWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(2.5),
      "&:first-of-type": {
        marginTop: theme.spacing(0),
      },
    },
    subQuestionWrapper: {
      marginTop: theme.spacing(2.5),
      "&:first-of-type": {
        marginTop: theme.spacing(0),
      },
    },
    questionBodyToggle: {
      color: "#fff",
      height: 32,
      width: 32,
      padding: theme.spacing(1),
      transition: "transform 0.3s ease-in-out",
      marginLeft: theme.spacing(4),
      "@media(max-width:414px)": {
        marginLeft: theme.spacing(2.5),
      },
      marginRight: theme.spacing(4),
    },
    toggleIcon: {
      width: 16,
    },
    questionBodyOpen: {
      transform: "rotate(180deg)",
    },
    answerItem: {
      "& svg": {
        width: 24,
        height: 24,
      },
    },
    firstColumn: {
      maxWidth: 175,
      wordBreak: "break-word",
    },
    tableColumn: {
      maxWidth: 100,
      wordBreak: "break-word",
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

const Matrix: React.FC<Props> = ({
  question,
  t,
  handleUserAnswer,
  currentAnswer,
  classes,
  previewMobileView,
}) => {
  const [questionBodyClosed, setQuestionBodyClose] = useState<ID[]>([]);
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const toggleQuestionBody = (subQuestionItem: MatrixSubQuestion) => {
    questionBodyClosed.includes(subQuestionItem.id)
      ? setQuestionBodyClose((prevState) => [
          ...prevState.filter((closedQuestionId: ID) => closedQuestionId !== subQuestionItem.id),
        ])
      : setQuestionBodyClose((prevState) => [...prevState, subQuestionItem.id]);
  };

  const subQuestionBodyOpen = (subQuestionItem: MatrixSubQuestion) =>
    !questionBodyClosed.includes(subQuestionItem.id);

  const renderDesktopView = () => (
    <TableContainer component={Paper}>
      <Table aria-label={"simple table"}>
        <TableHead>
          <TableRow>
            <TableCell align={"right"} size={"small"} className={classes.firstColumn}></TableCell>
            {question.body.answerOptions.map((answer) => (
              <TableCell align={"center"} className={classes.tableColumn}>
                {answer.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {question.body.subQuestions.map((subQuestion) => (
            <TableRow key={subQuestion.label}>
              <TableCell
                component={"th"}
                scope={"row"}
                size={"small"}
                className={classes.firstColumn}
              >
                {subQuestion.label}
              </TableCell>
              {question.body.answerOptions.map((answer) => (
                <TableCell align={"center"} className={classes.tableColumn}>
                  <Radio
                    id={`checked-${answer.id}-${isChecked(
                      answer.id,
                      currentAnswer as MatrixAnswer
                    )}`}
                    key={answer.id}
                    value={answer.id}
                    className={classes.answerItem}
                    checked={isChecked(answer.id, currentAnswer as MatrixAnswer, subQuestion.id)}
                    onChange={(_, checked) => {
                      //it is not the first answer, a current answer already exists
                      if (currentAnswer) {
                        if (checked) {
                          const subQuestionAlreadyAnswered = currentAnswer.subAnswers.find(
                            (matrixAnswerItem) => matrixAnswerItem.subQuestionId === subQuestion.id
                          );
                          if (subQuestionAlreadyAnswered !== undefined) {
                            //another answer for this subquestion is already there, keep other subquestion answers and add new item for
                            //this subquestion
                            const remainingSubAnswers = currentAnswer.subAnswers.filter(
                              (matrixAnswerItem) =>
                                matrixAnswerItem.subQuestionId !== subQuestion.id
                            );
                            handleUserAnswer(question.id, {
                              questionID: question.id,
                              type: "matrix",
                              subAnswers: [
                                ...remainingSubAnswers,
                                { subQuestionId: subQuestion.id, selectedAnswers: [answer.id] },
                              ],
                            });
                          } else {
                            //subquestion not answered so far
                            handleUserAnswer(question.id, {
                              questionID: question.id,
                              type: "matrix",
                              subAnswers: [
                                ...currentAnswer.subAnswers,
                                { subQuestionId: subQuestion.id, selectedAnswers: [answer.id] },
                              ],
                            });
                          }
                        } else {
                          // we need to remove the whole answer
                          handleUserAnswer(question.id);
                        }
                      } else if (checked) {
                        // subquestion wasn't answered before
                        //we need to add the initial answer
                        handleUserAnswer(question.id, {
                          questionID: question.id,
                          type: "matrix",
                          subAnswers: [
                            { subQuestionId: subQuestion.id, selectedAnswers: [answer.id] },
                          ],
                        });
                      }
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderMobileView = () =>
    question.body.subQuestions.map((subQuestion) => (
      <div key={subQuestion.id} className={classes.subQuestionWrapper}>
        <div className={classes.matrixAnswerTitleWrapper}>
          <Typography variant={"h2"} className={classes.matrixAnswerTitle}>
            {subQuestion.label}
          </Typography>
          <IconButton
            aria-label="up"
            className={classNames({
              [classes.questionBodyToggle]: true,
              [classes.questionBodyOpen]: subQuestionBodyOpen(subQuestion),
            })}
            onClick={() => toggleQuestionBody(subQuestion)}
            classes={{ label: classes.toggleIcon }}
          >
            <AccordionArrow fill="#6d7278" height={25} width={32} />
          </IconButton>
        </div>
        {subQuestionBodyOpen(subQuestion) &&
          question.body.answerOptions.map((answer) => (
            <RadioElement
              id={`checked-${answer.id}-${isChecked(answer.id, currentAnswer as MatrixAnswer)}`}
              key={answer.id}
              value={answer.id}
              label={answer.label}
              control={<Radio />}
              checked={isChecked(answer.id, currentAnswer as MatrixAnswer, subQuestion.id)}
              onChange={(_, checked) => {
                //it is not the first answer, a current answer already exists
                if (currentAnswer) {
                  if (checked) {
                    const subQuestionAlreadyAnswered = currentAnswer.subAnswers.find(
                      (matrixAnswerItem) => matrixAnswerItem.subQuestionId === subQuestion.id
                    );
                    if (subQuestionAlreadyAnswered !== undefined) {
                      //another answer for this subquestion is already there, keep other subquestion answers and add new item for
                      //this subquestion
                      const remainingSubAnswers = currentAnswer.subAnswers.filter(
                        (matrixAnswerItem) => matrixAnswerItem.subQuestionId !== subQuestion.id
                      );
                      handleUserAnswer(question.id, {
                        questionID: question.id,
                        type: "matrix",
                        subAnswers: [
                          ...remainingSubAnswers,
                          { subQuestionId: subQuestion.id, selectedAnswers: [answer.id] },
                        ],
                      });
                    } else {
                      //subquestion not answered so far
                      handleUserAnswer(question.id, {
                        questionID: question.id,
                        type: "matrix",
                        subAnswers: [
                          ...currentAnswer.subAnswers,
                          { subQuestionId: subQuestion.id, selectedAnswers: [answer.id] },
                        ],
                      });
                    }
                  } else {
                    // we need to remove the whole answer
                    handleUserAnswer(question.id);
                  }
                } else if (checked) {
                  // subquestion wasn't answered before
                  //we need to add the initial answer
                  handleUserAnswer(question.id, {
                    questionID: question.id,
                    type: "matrix",
                    subAnswers: [{ subQuestionId: subQuestion.id, selectedAnswers: [answer.id] }],
                  });
                }
              }}
              //not yet possible
              children={
                <>
                  {answer.media.length > 0 && (
                    <MediaDisplay media={answer.media} answerOption={true} />
                  )}
                </>
              }
            />
          ))}
      </div>
    ));

  return <>{!desktop || previewMobileView ? renderMobileView() : renderDesktopView()}</>;
};

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(Matrix);
