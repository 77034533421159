import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

import i18n from "./constants/i18n";
import theme from "./constants/Theme";
import { saveState } from "./util/localStorage";
import "moment/locale/de";
import "url-search-params-polyfill";
import "intersection-observer";

import App from "./App";

import "./styles.css";

// persist current state
store.subscribe(() => {
  saveState({
    session: store.getState().session,
  });
});

const root = (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </Provider>
);

ReactDOM.render(root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
