import React, { PureComponent } from "react";
import * as Yup from "yup";
import { translations } from "../../../../../constants/lang/translation";
import { QuestionTypeProps, QuestionProps } from "./Question";
import AnswerOption, {FieldTypes} from "../../../../../entities/AnswerOption";
import Question from "../../../../../entities/Question";
import QuestionPreview from "../../../../../common/Preview/QuestionPreview";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import FormInput from "./FormInput";
import { Typography } from "@material-ui/core";
import MatrixSubQuestion from "../../../../../entities/MatrixSubQuestion";

export const QuestionValidationMatrix = ({ t }: QuestionProps) => {
  return Yup.object().shape({
    text: Yup.string()
      .required(t(translations.questionnaire.validation.title))
      .max(240, t(translations.questionnaire.validation.titleMaxLength)),
    body: Yup.object().shape({
      subQuestions: Yup.array()
        .required(t(translations.questionnaire.validation.answers.minCount))
        .min(2, t(translations.questionnaire.validation.answers.minCount))
        .max(12, t(translations.questionnaire.validation.answers.maxCount))
        .of(
          Yup.object().shape({
            label: Yup.string()
              .required(t(translations.questionnaire.validation.answers.requiredTitle))
              .max(90, t(translations.questionnaire.validation.answers.length)),
          })
        ),
      answerOptions: Yup.array()
        .required(t(translations.questionnaire.validation.answers.minCount))
        .min(2, t(translations.questionnaire.validation.answers.minCount))
        .max(12, t(translations.questionnaire.validation.answers.maxCount))
        .of(
          Yup.object().shape({
            label: Yup.string()
              .required(t(translations.questionnaire.validation.answers.requiredTitle))
              .max(90, t(translations.questionnaire.validation.answers.length)),
          })
        ),
    }),
  });
};

type Props = QuestionTypeProps & WithTranslation;
class QuestionFormMatrixCore extends PureComponent<Props> {
  handleInputChange(index: number, newAnswerOption: AnswerOption) {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.props.body.answerOptions.map((answerOption, answerOptionIndex) =>
        answerOptionIndex === index ? newAnswerOption : answerOption
      ),
    });
  }
  handleInputChangeSubQuestions(index: number, newSubQuestion: MatrixSubQuestion) {
    this.props.handleChange({
      ...this.props.body,
      subQuestions: this.props.body.subQuestions.map((subQuestion, subQuestionIndex) =>
        subQuestionIndex === index ? newSubQuestion : subQuestion
      ),
    });
  }

  addAnswerItem = (answerOptions: AnswerOption[], index: number) => {
    answerOptions.splice(index + 1, 0, new AnswerOption());
    return answerOptions;
  };

  addSubQuestionItem = (subQuestions: MatrixSubQuestion[], index: number) => {
    subQuestions.splice(index + 1, 0, new MatrixSubQuestion());
    return subQuestions;
  };

  handleAddAnswer = (index: number) => {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.addAnswerItem([...this.props.body.answerOptions], index),
    });
  };
  handleAddSubQuestion = (index: number) => {
    this.props.handleChange({
      ...this.props.body,
      subQuestions: this.addSubQuestionItem([...this.props.body.subQuestions], index),
    });
  };

  handleDeleteAnswer = (indexToDelete: number) => {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.props.body.answerOptions.filter(
        (answerOption, answerOptionIndex) => answerOptionIndex !== indexToDelete
      ),
    });
  };
  handleDeleteSubQuestion = (indexToDelete: number) => {
    this.props.handleChange({
      ...this.props.body,
      subQuestions: this.props.body.subQuestions.filter(
        (subQuestion, subQuestionIndex) => subQuestionIndex !== indexToDelete
      ),
    });
  };

  render() {
    const { id, t } = this.props;
    return (
      <>
        <Typography> {t(translations.questionnaire.matrix.rowValues)}</Typography>
        {this.props.body.subQuestions.map((subQuestion, index) => {
        const transformedAnswerOption = {id:subQuestion.id , label: subQuestion.label, media: subQuestion.media, additionalFieldType: FieldTypes.NONE } ;
          return <FormInput
            answerOption={transformedAnswerOption}
            otherQuestions={this.props.otherQuestions}
            questionIndex={this.props.questionIndex}
            handleChange={this.handleInputChangeSubQuestions.bind(this, index)}
            index={index}
            key={`${index}:${subQuestion.id}`}
            deleteAnswer={this.handleDeleteSubQuestion.bind(this, index)}
            addAnswer={this.handleAddSubQuestion.bind(this, index)}
            amountOfAnswers={this.props.body.subQuestions.length}
            addAdditionalFreetext={() => {}}
            type={this.props.type}
            questionId={id}
            preventMediaUpload={true}
            preventAlterationOfAnswerCount={this.props.preventAlterationOfAnswerCount}
            preventQuestionSkip={this.props.preventQuestionSkip}
            preventAdditionalFreeText={true}
            multiline={true}
            conditions={this.props.conditions}
            addAdditionalNumericalField={() => {}}
          />
        })}
        <div>
          <Typography> {t(translations.questionnaire.matrix.columnValues)}</Typography>
          {this.props.body.answerOptions.map((answerOption, index) => (
            <FormInput
              answerOption={answerOption}
              otherQuestions={this.props.otherQuestions}
              questionIndex={this.props.questionIndex}
              handleChange={this.handleInputChange.bind(this, index)}
              index={index}
              key={`${index}:${answerOption.id}`}
              deleteAnswer={this.handleDeleteAnswer.bind(this, index)}
              addAnswer={this.handleAddAnswer.bind(this, index)}
              amountOfAnswers={this.props.body.answerOptions.length}
              addAdditionalFreetext={() => {}}
              type={this.props.type}
              questionId={id}
              preventMediaUpload={true}
              preventAlterationOfAnswerCount={this.props.preventAlterationOfAnswerCount}
              preventQuestionSkip={this.props.preventQuestionSkip}
              preventAdditionalFreeText={true}
              multiline={true}
              conditions={this.props.conditions}
              addAdditionalNumericalField={() => {}}
            />
          ))}
        </div>
      </>
    );
  }
}

export const QuestionFormMatrix = compose<Props, QuestionTypeProps>(withTranslation())(
  QuestionFormMatrixCore
);
export class QuestionPreviewMatrix extends PureComponent<Omit<Question, "toDataJson">> {
  render() {
    const { text: title, body, type, media } = this.props;
    return (
      <QuestionPreview
        title={title}
        body={body}
        type={type}
        media={media}
        questionNumber={1}
        totalQuestionNumber={1}
      />
    );
  }
}
