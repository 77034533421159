import React, { PureComponent } from "react";
import { TextField } from "@material-ui/core";
import * as Yup from "yup";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../../../constants/lang/translation";
import { compose } from "recompose";
import { QuestionTypeProps, QuestionProps } from "./Question";
import Question from "../../../../../entities/Question";
import QuestionPreview from "../../../../../common/Preview/QuestionPreview";

export const QuestionValidationFreeText = ({ t }: QuestionProps) => {
  return Yup.object({
    text: Yup.string()
      .required(t(`${translations.questionnaire.validation.title}`))
      .max(240, t(`${translations.questionnaire.validation.titleMaxLength}`)),
  });
};

type Props = QuestionTypeProps & WithTranslation;
class QuestionFormFreeTextCore extends PureComponent<Props> {
  render() {
    const { t } = this.props;
    return (
      <div>
        <TextField
          id="standard-name"
          placeholder={t(translations.questionnaire.freeText.infoBodyText)}
          value={""}
          margin="normal"
          fullWidth={true}
          multiline={true}
          rows={5}
          disabled={true}
        />
      </div>
    );
  }
}
export const QuestionFormFreeText = compose<Props, QuestionTypeProps>(withTranslation())(
  QuestionFormFreeTextCore
);

type PreviewProps = Omit<Question, "toDataJson"> & WithTranslation;
class QuestionPreviewFreeTextCore extends PureComponent<PreviewProps> {
  render() {
    const { text: title, body, type, media } = this.props;
    return (
      <QuestionPreview
        title={title}
        body={body}
        type={type}
        media={media}
        questionNumber={1}
        totalQuestionNumber={1}
      />
    );
  }
}

export const QuestionPreviewFreeText = compose<PreviewProps, Omit<Question, "toDataJson">>(
  withTranslation()
)(QuestionPreviewFreeTextCore);
