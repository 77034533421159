import { styled } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";

const LevelCompletedBanner = styled("div")(({ theme }: { theme: QuopinionTheme }) => ({
  padding: theme.spacing(6),
  backgroundColor: theme.palette.grey[200],
  position: "relative",
}));

export default LevelCompletedBanner;
