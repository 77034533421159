import React, { PureComponent } from "react";
import { primary, QuopinionTheme } from "../../../../constants/Theme";
import { createStyles, Typography, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import ContentWrapper from "../../../../common/Layout/ContentWrapper";
import { translations } from "../../../../constants/lang/translation";
import ScreenerQuestionItem from "../ScreenerQuestionItem";
import { withRouter, RouteComponentProps } from "react-router";
import { AnsweredAttribute } from "../../../../services/Accounts";
import { RootState } from "../../../../modules";
import { connect } from "react-redux";
import AttributeQuestion from "../../../../entities/AttributeQuestion";
import ID from "../../../../entities/ID";

interface OwnProps {
  level: string;
  screenerObject: any;
  category: string;
}

interface DispatchProps {}

interface StateProps {
  screenerAnswers: AnsweredAttribute[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    level: {
      color: primary,
      lineHeight: "1.5",
    },
    screenerText: {
      color: primary,
      fontSize: "14px",
      lineHeight: "1.14",
      marginLeft: theme.spacing(4),
    },
    header: {
      backgroundColor: theme.palette.grey[200],
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      height: theme.spacing(23),
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(6),
      marginBottom: theme.spacing(0.5),
    },
    categoryText: {
      paddingTop: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },
    playIcon: {
      color: theme.palette.grey[50],
    },
    playButton: {
      width: "30px",
      height: "32px",
    },
    question: {
      lineHeight: 1.5,
      fontSize: "16px",
      marginRight: theme.spacing(7.5),
    },
    questionWrapper: {
      display: "flex",
      alignItems: "center",
    },
    questionCard: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    },
    itemWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    buttonWrapper: {
      display: "flex",
      alignItems: "center",
      width: "30px",
    },
    root: {
      marginBottom: theme.spacing(19),
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps;

class CategoryQuestions extends PureComponent<Props> {
  isAnswered = (attributeID: ID) =>
    this.props.screenerAnswers.some((screener) => screener.attributeId === attributeID);

  render() {
    const { level, classes, t, screenerObject, location, category } = this.props;

    return (
      <ContentWrapper className={classes.root}>
        <Typography className={classes.level} variant={"subtitle1"}>
          {level}
        </Typography>
        <div className={classes.header}>
          <Typography className={classes.screenerText}>
            {t(translations.participant.screeners.screener)}
          </Typography>
          <Typography variant={"h3"} className={classes.categoryText}>
            {category}
          </Typography>
        </div>

        {screenerObject.questions.map((question: AttributeQuestion) => (
          <ScreenerQuestionItem
            key={question.id}
            question={question.text}
            link={`${location.pathname}/${question.id}`}
            answered={this.isAnswered(question.attributeID)}
            attributeId={question.attributeID}
          />
        ))}
      </ContentWrapper>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ attribute }: RootState, ownProps: OwnProps) => ({
  screenerAnswers: [...attribute.screenerAnswers, ...attribute.answeredDailyScreeners],
});

export default compose<Props, OwnProps>(
  withRouter,
  withStyles(styles),
  withTranslation(),
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(CategoryQuestions);
