import React, {PureComponent} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {compose} from "recompose";
import {createStyles, Typography, withStyles, WithStyles} from "@material-ui/core";
import {QuopinionTheme} from "../../../constants/Theme";
import Container from "../../../common/Layout/Container";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import {translations} from "../../../constants/lang/translation";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";

interface OwnProps {
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(5),
      textTransform: "uppercase",
    },
    subtitle: {
      marginBottom: theme.spacing(4.5),
    },
    subtitleText: {
      marginBottom: theme.spacing(5),
      "& a": {
        textDecoration: "underline",
      },
    },
    subheadline: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(3),
    },
    wrapper: {
      "& a": {
        color: "inherit",
      },
      "& a:visited": {
        color: "inherit",
      },
    },
    list: { marginBottom: theme.spacing(8) },
    listItem: {
      marginBottom: theme.spacing(2),
      fontWeight: 400,
    },
    link: {
      textDecoration: "underline",
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

export const route = "/market-research/privacy";

class DataProtectionLongResearcher extends PureComponent<Props> {
  render() {
    const { classes, t } = this.props;
    return (
      <>
        <LogoBarResearcher backButton={true} />
        <ContentWrapper>
          <Container centered={true}>
            <div className={classes.wrapper}>
              <Typography variant="h1" style={{ marginBottom: 40 }} color="secondary">
                {t(translations.pages.dataProtection.title)}{" "}
                {t(translations.pages.dataProtection.marketplace)}{" "}
                {t(translations.pages.dataProtection.researcher.short.bodyTitle)}
              </Typography>
              <Typography variant="h3">Inhaltsverzeichnis:</Typography>
              <ol className={classes.list}>
                <li className={classes.listItem}>
                  <Typography variant="body1">Über uns</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Begriffsklärung</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Arten personenbezogener Daten</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Zwecke der Verarbeitung </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Rechtsgrundlage</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Einsatz von Cookies</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Dauer der Daten-Speicherung</Typography>
                </li>{" "}
                <li className={classes.listItem}>
                  <Typography variant="body1">Externe Dienstleister</Typography>
                </li>{" "}
                <li className={classes.listItem}>
                  <Typography variant="body1">Datenweitergabe und Fremdeinladungen</Typography>
                </li>{" "}
                <li className={classes.listItem}>
                  <Typography variant="body1">Öffentliche Stellen</Typography>
                </li>{" "}
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Besondere Kategorien personenbezogener Daten
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Verarbeitung personenbezogener Daten von Kindern
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Sicherheit</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Rechtebelehrung</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Einwilligungserklärung</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Kontaktdaten</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Datenschutzbeauftragter</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Datenschutzbestimmungen von Drittanbietern
                  </Typography>
                </li>
              </ol>
              <Typography variant="h3" className={classes.subheadline}>
                1. Über uns
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Quopinion ist ein Projekt der Quotas GmbH und verstärkt die Bereiche der empirischen
                Sozialforschung des Quotas GmbH um den Bereich der strategischen Beratung und
                Marktforschung.
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Die Quotas GmbH bietet strategische Beratung und Marktforschung im klassischen
                Sinne. Wir sind die Betreiber des Quopinion Marktplatzes und daher Vertragspartner
                beim Abschluss von Studienprojekten und Verantwortlicher für alle Teilnehmer, die an
                Umfragen teilnehmen. Wir verarbeiten Ihre personenbezogenen Daten strikt unter
                Einhaltung der Bestimmungen der DSGVO. Selbstverständlich berücksichtigen wir in
                unserer Tätigkeit den branchenüblichen Kodex der ESOMAR und sind Mitglieder von
                ESOMAR. Für die Zwecke dieser Richtlinie umfasst der Quopinion Marktplatz auch die
                Verwendung unserer mobilen Anwendung.
              </Typography>

              <Typography variant="h3" className={classes.subheadline}>
                2. Begriffserklärung
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Unter „personenbezogenen Daten“ versteht man Einzelangaben über persönliche oder
                sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person wie
                z.B. Name, Adresse, Geburtsdatum, Telefonnummer, etc., alle Daten mittels derer man
                auf eine bestimmte Person rückschließen kann.
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                „Verarbeitung“ bezeichnet jeden mit oder ohne Hilfe automatisierter Verfahren
                ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                personenbezogenen Daten wie z.B. das Erheben, das Erfassen, die Organisation, das
                Ordnen, die Speicherung, das Auslesen, das Selektieren, das Übermitteln, das Löschen
                etc. etc. Der Begriff ist ausgesprochen weitreichend und umfasst praktisch jede Art
                des Umgangs mit Daten.
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Der „Verantwortliche“ ist die natürliche oder juristische Person, Behörde,
                Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die
                Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Ein „Auftragsverarbeiter“ ist eine natürliche oder juristische Person, Behörde,
                Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des
                Verantwortlichen verarbeitet.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                3. Arten personenbezogener Daten
              </Typography>

              <Typography variant="body1" className={classes.subtitleText}>
                Bei den von uns verarbeiteten personenbezogenen Daten handelt es sich um die
                freiwillig von Ihnen zur Verfügung gestellten Daten im Rahmen der:
              </Typography>
              <ul>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Registrierung (z.B. Name, Adresse, Telefonnummer, E-Mail etc.)
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Umfrageprojekte (z.B. Selektion von Antwortmöglichkeiten, Texteingaben,
                    Fotografien)
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Nutzung unseres Quopinion Marktplatzes (z.B. besuchte Webseiten und Plattformen,
                    Zugriffszeiten)
                  </Typography>
                </li>
              </ul>
              <Typography variant="body1" className={classes.subtitleText}>
                Beim Besuch unserer Quopinion Marktplatzes wird jeder Seitenaufruf mit IP-Adresse,
                Zeitstempel und Browserkennung im Webserverlog gespeichert. Die Serverlogs werden
                routinemäßig 30 Tage vorgehalten und anschließend gelöscht. Aus der IP wird das Land
                ermittelt, um Zugriffe aus nicht freigegebenen Ländern zu unterbinden. Eine weitere
                regionale Auswertung wird nicht vorgenommen. Bei der Registrierung im Panel wird die
                IP erfasst und in der Teilnehmer-Historie gespeichert. Zudem wird jeder Login in der
                Historie vermerkt.
              </Typography>

              <Typography variant="h3" className={classes.subheadline}>
                4. Zwecke der Verarbeitung
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Die von uns gespeicherten personenbezogenen Daten werden ausschließlich zu folgenden
                Zwecken erhoben:
              </Typography>
              <ul>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Zurverfügungstellung des Quopinion Marktplatzes, seiner Funktionen und Inhalte
                    (Archiv aller registrierten Teilnehmer inkl. deren Daten)
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Beantwortung von Anfragen und Kommunikation
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Abwicklung von Studienprojekten</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">Zahlungsabwicklung</Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Qualitätssicherung und Verbesserung der Leistung und Effizienz
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Sicherheitsmaßnahmen, Zwecke der Bestätigung der Identität oder der
                    Kontaktdaten, ggf. für Zwecke der Betrugserkennung
                  </Typography>
                </li>
              </ul>
              <Typography variant="h3" className={classes.subheadline}>
                5. Rechtsgrundlage
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Die rechtliche Grundlage für die Verarbeitung Ihrer personenbezogenen Daten beruht
                auf Ihrer Einwilligung (gemäß Art. 6 Abs 1 lit. a DSGVO) und basiert auf unserem
                überwiegenden berechtigten Interesse (gemäß Art. 6 Abs. 1 lit. f DSGVO), welches
                darin besteht, die oben genannten Zwecke zu erreichen.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                6. Einsatz von Cookies
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Auf unserem Quopinion Marktplatz werden zur Wiedererkennung
                Sitzungscookies/Sessioncookies eingesetzt. In diesem Cookie werden keine
                personenbezogenen Daten wie Namen, Benutzernamen, E-Mail-Adressen oder IP-Adressen
                gespeichert. Auf dem Gerät des Anwenders wird in dem Cookie nur eine zufällige
                Session-ID gespeichert. Der Sessioncookie wird nach maximal zwei Stunden Inaktivität
                des Anwenders automatisch auf dem Server gelöscht. Beim vollständigen Schließen des
                Browsers auf dem Gerät des Anwenders wird der Sessioncookie ebenfalls gelöscht. Es
                befindet sich kein Webanalyse-Tool im Einsatz.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                7. Dauer der Datenspeicherung
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Wir speichern die von unseren Panel-Mitgliedern erhobenen personenbezogenen Daten so
                lange die Mitgliedschaft besteht und solange dies aus rechtlichen Verpflichtungen
                erforderlich ist (z.B. gesetzliche Aufbewahrungspflichten aus steuerlichen Gründen
                oder sonstige gesetzliche Erfordernisse).
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Als Auftraggeber können Sie jederzeit Ihre Teilnahme beenden. Im Falle der
                Beendigung Ihrer Teilnahme speichern und verwenden wir Ihre persönlichen Daten für
                keine anderen Zwecke als im vorangegangenen Absatz beschrieben. Wir können
                Protokolldateien mit anonymisierten Informationen für interne Analysezwecke
                speichern. Diese Protokolldateien werden in der Regel für eine kurze Zeit
                aufbewahrt, außer in Fällen, in denen sie für die Sicherheit der Site verwendet
                werden, sowie um die Funktionalität der Site zu verbessern.
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Bitte beachten Sie bei Beendigung Ihrer Mitgliedschaft, dass Ihre Daten
                unwiderruflich gelöscht werden, bis zu diesem Zeitpunkt gesammelten und noch nicht
                eingelöstes Guthaben verfällt. Alle etwaigen Ansprüche auf Vergütungen oder Prämien,
                die die Quotas GmbH den Mitgliedern des Quopinion Marktplatzes angeboten hat, gehen
                verloren.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                8. Externe Dienstleister
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Im Rahmen unserer Tätigkeit kann es vorkommen, dass wir andere Unternehmen oder
                Personen mit der Ausübung bestimmter Aufgaben betrauen, die zu unseren
                Dienstleistungen beitragen, dies erfolgt im Rahmen von
                Auftragsverarbeitungsverträgen. Wir geben ausschließlich nur in dem Umfang Daten
                weiter oder gewähren Zugriff darauf, wie dies unabdingbar erforderlich ist um die
                gegenständliche Dienstleistung zu erbringen. Unsere externen Dienstleister sind
                ebenso vertraglich verpflichtet, die Vertraulichkeit zu wahren und die Daten in
                keinerlei zweckentfremdetem Zusammenhang oder für eigene Zwecke zu nutzen oder an
                Dritte weiterzugeben.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                9. Datenweitergabe und Fremdeinladungen
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Grundsätzlich geben wir die von den Panelmitgliedern gesammelten personenbezogenen
                Daten niemals an Dritte weiter. Gelegentlich kann es vorkommen, dass wir über unser
                Online-Panel Panel-Mitglieder einladen, an Markt- und Meinungsforschungsprojekten
                anderer Anbieter teilzunehmen. In derartigen Fällen werden Sie ausdrücklich im
                Einladungsschreiben darauf hingewiesen und es kommt dabei niemals zu einer
                Weitergabe von Daten, sondern Sie werden lediglich eingeladen, an Fremdprojekten
                teilzunehmen, ohne dass Ihre persönlichen Daten weitergegeben werden – Sie geben nur
                jene Daten bekannt, die Sie im Fragebogen preisgeben.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                10. Öffentliche Stellen
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Sind wir aufgrund gesetzlicher Vorgaben oder gerichtlicher Aufforderung
                verpflichtet, Ihre personen-bezogenen Daten gegenüber öffentlichen Stellen offen zu
                legen, wie z.B. Gerichten, Strafverfolgungsbehörden, Regulierungsbehörden, etc., so
                müssen wir dieser Aufforderung nachkommen.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                11. Besondere Kategorien personenbezogener Daten
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Es gibt einige Kategorien von personenbezogenen Daten, die einem ganz besonderen
                Schutz unterliegen, das sind die sogenannten Daten besonderer Kategorien, dazu
                zählen z.B. Daten über die Herkunft, politische Meinungen, religiöse oder
                weltanschauliche Überzeugungen, die Gesundheit, das Sexualleben oder die sexuelle
                Orientierung sowie genetische oder biometrische Daten.
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                In bestimmten Fällen und zu den angeführten Zwecken, kann es u.a. im Rahmen unserer
                Projekte vorkommen, dass wir auch personenbezogene Daten besonderer Kategorien
                verarbeiten. Dies könnte sein, wenn Sie diese Daten vorab selbst bekannt gemacht
                haben oder auch um Rechtsansprüche geltend zu machen, auszuüben oder zu verteidigen.
                Im Rahmen unserer Studien können derartige Themen auch angesprochen werden. Diese
                Daten können wir verarbeiten, wenn Sie uns dazu vorab freiwillig und ausdrücklich
                Ihre Einwilligung in einem bestimmten Zusammenhang und für einen definierten Zweck
                erteilt haben, wie z.B. die Teilnahme an unseren Studien.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                12. Verarbeitung personenbezogener Daten von Kindern
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Die Teilnahme an unserem Onlinepanel ist ab einem Alter von 16 Jahren möglich. Wir
                erheben oder verarbeiten keine personenbezogenen Daten von Kindern unter 16 Jahren
                ohne vorherige Einwilligung der Erziehungsberechtigten gemäß geltendem Recht. Sollte
                uns eine unrechtmäßige Teilnahme eines Kindes unter 16 Jahren bekannt werden,
                löschen wir dessen Datensätze umgehend.
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Es kann vorkommen, dass wir im Rahmen eines Projektes um die Teilnahme jüngerer
                Kinder im Haushalt ersuchen. Wir senden in einem derartigen Fall einen
                Fragebogen-Link mit der Bitte zu, das jeweilige Kind möge den Fragebogen nur unter
                Aufsicht der Eltern/Erziehungsberechtigten ausfüllen. Die Entscheidung des
                Erziehungsberechtigten zur Teilnahme des Kindes an der Umfrage erteilt implizit die
                Zustimmung, dass die Meinung des Kindes in die Umfrage mit einbezogen werden darf.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                13. Sicherheit
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Wir haben umfangreiche technische und betriebliche Schutzvorkehrungen getroffen, um
                Ihre Daten vor zufälligen oder vorsätzlichen Manipulationen, Verlust, Zerstörung
                oder dem Zugriff unberechtigter Personen zu schützen. Unsere Informationssicherheit
                orientiert sich an international anerkannten Standards, unsere Verfahren im Bereich
                der Datensicherheit werden regelmäßig überprüft und dem technologischen Fortschritt
                angepasst.
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Im Falle einer Verletzung der Datensicherheit, von der auch personenbezogene Daten
                betroffen sind, halten wir uns an alle geltenden gesetzlichen Bestimmungen bezüglich
                der Mitteilungspflicht im Falle einer Verletzung des Schutzes personenbezogener
                Daten.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                14. Rechtebelehrung
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Als betroffene Person stehen Ihnen die Rechte auf Auskunft, Berichtigung, Löschung,
                Einschränkung, Datenübertragbarkeit und Widerspruch zu. Wenn Sie glauben, dass die
                Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
                datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können
                Sie sich an die zuständige Aufsichtsbehörde{" "}
                <a className={classes.link} href="https://datenschutz-hamburg.de/">
                  Aufsichtsbehörde
                </a>{" "}
                wenden.
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Zur Ausübung Ihrer Rechte wenden Sie sich bitte an die u.a. Kontaktadressen.
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                15. Einwilligungserklärung
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Wenn Sie an dem Quopinion Marktplatz teilnehmen möchten, ist es erforderlich unsere
                Datenschutzrichtlinie zu akzeptieren. Mit Ihrem Einverständnis erteilen Sie die
                Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten für die
              </Typography>
              <ul>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    generelle Teilnahme unter den in unserer Datenschutzrichtlinie dargelegten
                    Rahmenbedingungen und
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    gegebenenfalls in die Verarbeitung sensibler Daten.
                  </Typography>
                </li>
              </ul>
              <Typography variant="body1" className={classes.subtitleText}>
                Indem Sie die Schaltfläche bei „Ich akzeptiere die Datenschutzrichtlinien“
                anklicken, erklären Sie Folgendes:
              </Typography>
              <ul>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Ich bestätige, dass ich mindestens 16 Jahre alt bin.
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Ich gestatte der Quotas GmbH die Verarbeitung meiner personenbezogenen Daten im
                    Rahmen des Quopinion Projekts in dem in dieser Einwilligungserklärung und der
                    Datenschutzrichtlinie festgelegten Umfang.
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Ich wurde über meine Rechte informiert; ich kann meine Zustimmung jederzeit
                    widerrufen. Im Fall des Widerrufs verliert diese Einverständniserklärung mit
                    sofortiger Wirkung ihre Gültigkeit. Meine Teilnahme am Quopinion Marktplatz
                    endet damit und ich habe keine weiteren Ansprüche auf Vergütungen oder Prämien.
                  </Typography>
                </li>
              </ul>
              <Typography variant="h3" className={classes.subheadline}>
                16. Kontaktdaten
              </Typography>
              <Typography variant="body1">
                Sie erreichen uns unter folgenden Kontaktdaten
              </Typography>
              <Typography variant="body1">
                {t(translations.pages.Impressum.address.companyName)}
              </Typography>
              <Typography variant="body1">
                {t(translations.pages.Impressum.address.street)}
              </Typography>
              <Typography variant="body1">
                {t(translations.pages.Impressum.address.city)}
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                {t(translations.pages.Impressum.address.country)}
              </Typography>
              <Typography variant="body1">
                E-Mail:&nbsp;
                <a style={{ textDecoration: "underline" }} href="mailto:d.sossong@quopinion.de">
                  d.sossong@quopinion.de
                </a>
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Telefon:&nbsp;
                <a style={{ textDecoration: "underline" }} href="tel:+494041096982">
                  +49 40 410 969 82
                </a>
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                17. Datenschutzbeauftragter
              </Typography>
              <Typography variant="body1" className={classes.subtitleText}>
                Unsere Datenschutzbeauftragte erreichen Sie unter:&nbsp;
                <a style={{ textDecoration: "underline" }} href="mailto:datenschutz@quopinion.de">
                  datenschutz@quopinion.de
                </a>
              </Typography>
              <Typography variant="h3" className={classes.subheadline}>
                18. Datenschutzbestimmungen von Drittanbietern
              </Typography>

              <ol type="a" className={classes.list}>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Datenschutzbestimmungen zu Einsatz und Verwendung von Facebook
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
                    Komponenten des Unternehmens Facebook integriert. Facebook ist ein soziales
                    Netzwerk.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Ein soziales Netzwerk ist ein im Internet betriebener sozialer Treffpunkt, eine
                    Online-Gemeinschaft, die es den Nutzern in der Regel ermöglicht, untereinander
                    zu kommunizieren und im virtuellen Raum zu interagieren. Ein soziales Netzwerk
                    kann als Plattform zum Austausch von Meinungen und Erfahrungen dienen oder
                    ermöglicht es der Internetgemeinschaft, persönliche oder unternehmensbezogene
                    Informationen bereitzustellen. Facebook ermöglicht den Nutzern des sozialen
                    Netzwerkes unter anderem die Erstellung von privaten Profilen, den Upload von
                    Fotos und eine Vernetzung über Freundschaftsanfragen.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Betreibergesellschaft von Facebook ist die Facebook, Inc., 1 Hacker Way, Menlo
                    Park, CA 94025, USA. Für die Verarbeitung personenbezogener Daten
                    Verantwortlicher ist, wenn eine betroffene Person außerhalb der USA oder Kanada
                    lebt, die Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
                    Dublin 2, Ireland.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den
                    für die Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
                    Facebook-Komponente (Facebook-Plug-In) integriert wurde, wird der
                    Internetbrowser auf dem informationstechnologischen System der betroffenen
                    Person automatisch durch die jeweilige Facebook-Komponente veranlasst, eine
                    Darstellung der entsprechenden Facebook-Komponente von Facebook herunterzuladen.
                    Eine Gesamtübersicht über alle Facebook-Plug-Ins kann unter &nbsp;
                    <a
                      className={classes.link}
                      href={"https://developers.facebook.com/docs/plugins/?locale=de_DE"}
                    >
                      https://developers.facebook.com/docs/plugins/?locale=de_DE
                    </a>
                    {""} abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält Facebook
                    Kenntnis darüber, welche konkrete Unterseite unserer Internetseite durch die
                    betroffene Person besucht wird.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Sofern die betroffene Person gleichzeitig bei Facebook eingeloggt ist, erkennt
                    Facebook mit jedem Aufruf unserer Internetseite durch die betroffene Person und
                    während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer
                    Internetseite, welche konkrete Unterseite unserer Internetseite die betroffene
                    Person besucht. Diese Informationen werden durch die Facebook-Komponente
                    gesammelt und durch Facebook dem jeweiligen Facebook-Account der betroffenen
                    Person zugeordnet. Betätigt die betroffene Person einen der auf unserer
                    Internetseite integrierten Facebook-Buttons, beispielsweise den „Gefällt
                    mir“-Button, oder gibt die betroffene Person einen Kommentar ab, ordnet Facebook
                    diese Information dem persönlichen Facebook-Benutzerkonto der betroffenen Person
                    zu und speichert diese personenbezogenen Daten.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Facebook erhält über die Facebook-Komponente immer dann eine Information
                    darüber, dass die betroffene Person unsere Internetseite besucht hat, wenn die
                    betroffene Person zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig
                    bei Facebook eingeloggt ist; dies findet unabhängig davon statt, ob die
                    betroffene Person die Facebook-Komponente anklickt oder nicht. Ist eine
                    derartige Übermittlung dieser Informationen an Facebook von der betroffenen
                    Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass sie
                    sich vor einem Aufruf unserer Internetseite aus ihrem Facebook-Account ausloggt.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Die von Facebook veröffentlichte Datenrichtlinie, die unter &nbsp;
                    <a className={classes.link} href="https://de-de.facebook.com/about/privacy/">
                      https://de-de.facebook.com/about/privacy/
                    </a>{" "}
                     abrufbar ist, gibt Aufschluss über die Erhebung, Verarbeitung und Nutzung
                    personenbezogener Daten durch Facebook. Ferner wird dort erläutert, welche
                    Einstellungsmöglichkeiten Facebook zum Schutz der Privatsphäre der betroffenen
                    Person bietet. Zudem sind unterschiedliche Applikationen erhältlich, die es
                    ermöglichen, eine Datenübermittlung an Facebook zu unterdrücken. Solche
                    Applikationen können durch die betroffene Person genutzt werden, um eine
                    Datenübermittlung an Facebook zu unterdrücken.
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Datenschutzbestimmungen zu Einsatz und Verwendung von LinkedIn
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
                    Komponenten der LinkedIn Corporation integriert. LinkedIn ist ein
                    Internetbasiertes soziales Netzwerk, das eine Konnektierung der Nutzer mit
                    bestehenden Geschäftskontakten sowie das Knüpfen von neuen Businesskontakten
                    ermöglicht. Über 400 Millionen registrierte Personen nutzen LinkedIn in mehr als
                    200 Ländern. Damit ist LinkedIn derzeit die größte Plattform für
                    Businesskontakte und eine der meistbesuchten Internetseiten der Welt.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Betreibergesellschaft von LinkedIn ist die LinkedIn Corporation, 2029 Stierlin
                    Court Mountain View, CA 94043, USA. Für Datenschutzangelegenheiten außerhalb der
                    USA ist LinkedIn Ireland, Privacy Policy Issues, Wilton Plaza, Wilton Place,
                    Dublin 2, Ireland, zuständig.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Bei jedem einzelnen Abruf unserer Internetseite, die mit einer
                    LinkedIn-Komponente (LinkedIn-Plug-In) ausgestattet ist, veranlasst diese
                    Komponente, dass der von der betroffenen Person verwendete Browser eine
                    entsprechende Darstellung der Komponente von LinkedIn herunterlädt. Weitere
                    Informationen zu den LinkedIn-Plug-Ins können unter
                    https://developer.linkedin.com/plugins abgerufen werden. Im Rahmen dieses
                    technischen Verfahrens erhält LinkedIn Kenntnis darüber, welche konkrete
                    Unterseite unserer Internetseite durch die betroffene Person besucht wird.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Sofern die betroffene Person gleichzeitig bei LinkedIn eingeloggt ist, erkennt
                    LinkedIn mit jedem Aufruf unserer Internetseite durch die betroffene Person und
                    während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer
                    Internetseite, welche konkrete Unterseite unserer Internetseite die betroffene
                    Person besucht. Diese Informationen werden durch die LinkedIn-Komponente
                    gesammelt und durch LinkedIn dem jeweiligen LinkedIn-Account der betroffenen
                    Person zugeordnet. Betätigt die betroffene Person einen auf unserer
                    Internetseite integrierten LinkedIn-Button, ordnet LinkedIn diese Information
                    dem persönlichen LinkedIn-Benutzerkonto der betroffenen Person zu und speichert
                    diese personenbezogenen Daten.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    LinkedIn erhält über die LinkedIn-Komponente immer dann eine Information
                    darüber, dass die betroffene Person unsere Internetseite besucht hat, wenn die
                    betroffene Person zum Zeitpunkt des Aufrufes unserer Internetseite gleichzeitig
                    bei LinkedIn eingeloggt ist; dies findet unabhängig davon statt, ob die
                    betroffene Person die LinkedIn-Komponente anklickt oder nicht. Ist eine
                    derartige Übermittlung dieser Informationen an LinkedIn von der betroffenen
                    Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass sie
                    sich vor einem Aufruf unserer Internetseite aus ihrem LinkedIn-Account ausloggt.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    LinkedIn bietet unter&nbsp;
                    <a
                      className={classes.link}
                      href="https://www.linkedin.com/psettings/guest-controls"
                    >
                      https://www.linkedin.com/psettings/guest-controls
                    </a>{" "}
                    die Möglichkeit, E-Mail-Nachrichten, SMS-Nachrichten und zielgerichtete Anzeigen
                    abzubestellen sowie Anzeigen-Einstellungen zu verwalten. LinkedIn nutzt ferner
                    Partner wie Quantcast, Google Analytics, BlueKai, DoubleClick, Nielsen,
                    Comscore, Eloqua und Lotame, die Cookies setzen können. Solche Cookies können
                    unter&nbsp;
                    <a className={classes.link} href="https://www.linkedin.com/legal/cookie-policy">
                      https://www.linkedin.com/legal/cookie-policy
                    </a>{" "}
                    abgelehnt werden. Die geltenden Datenschutzbestimmungen von LinkedIn sind
                    unter&nbsp;
                    <a
                      className={classes.link}
                      href="https://www.linkedin.com/legal/privacy-policy"
                    >
                      https://www.linkedin.com/legal/privacy-policy
                    </a>{" "}
                    abrufbar. Die Cookie-Richtlinie von LinkedIn ist unter &nbsp;
                    <a className={classes.link} href="https://www.linkedin.com/legal/cookie-policy">
                      https://www.linkedin.com/legal/cookie-policy
                    </a>
                    {""} abrufbar.
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    {" "}
                    Datenschutzbestimmungen zu Einsatz und Verwendung von Twitter
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
                    Komponenten von Twitter integriert. Twitter ist ein multilingualer öffentlich
                    zugänglicher Mikroblogging-Dienst, auf welchem die Nutzer sogenannte Tweets,
                    also Kurznachrichten, die auf 140 Zeichen begrenzt sind, veröffentlichen und
                    verbreiten können. Diese Kurznachrichten sind für jedermann, also auch für nicht
                    bei Twitter angemeldete Personen abrufbar. Die Tweets werden aber auch den
                    sogenannten Followern des jeweiligen Nutzers angezeigt. Follower sind andere
                    Twitter-Nutzer, die den Tweets eines Nutzers folgen. Ferner ermöglicht Twitter
                    über Hashtags, Verlinkungen oder Retweets die Ansprache eines breiten Publikums.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Betreibergesellschaft von Twitter ist die Twitter, Inc., 1355 Market Street,
                    Suite 900, San Francisco, CA 94103, USA.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den
                    für die Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
                    Twitter-Komponente (Twitter-Button) integriert wurde, wird der Internetbrowser
                    auf dem informationstechnologischen System der betroffenen Person automatisch
                    durch die jeweilige Twitter-Komponente veranlasst, eine Darstellung der
                    entsprechenden Twitter-Komponente von Twitter herunterzuladen. Weitere
                    Informationen zu den Twitter-Buttons sind unter
                    https://about.twitter.com/de/resources/buttons abrufbar. Im Rahmen dieses
                    technischen Verfahrens erhält Twitter Kenntnis darüber, welche konkrete
                    Unterseite unserer Internetseite durch die betroffene Person besucht wird. Zweck
                    der Integration der Twitter-Komponente ist es, unseren Nutzern eine
                    Weiterverbreitung der Inhalte diese Internetseite zu ermöglichen, diese
                    Internetseite in der digitalen Welt bekannt zu machen und unsere Besucherzahlen
                    zu erhöhen.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Sofern die betroffene Person gleichzeitig bei Twitter eingeloggt ist, erkennt
                    Twitter mit jedem Aufruf unserer Internetseite durch die betroffene Person und
                    während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer
                    Internetseite, welche konkrete Unterseite unserer Internetseite die betroffene
                    Person besucht. Diese Informationen werden durch die Twitter-Komponente
                    gesammelt und durch Twitter dem jeweiligen Twitter-Account der betroffenen
                    Person zugeordnet. Betätigt die betroffene Person einen der auf unserer
                    Internetseite integrierten Twitter-Buttons, werden die damit übertragenen Daten
                    und Informationen dem persönlichen Twitter-Benutzerkonto der betroffenen Person
                    zugeordnet und von Twitter gespeichert und verarbeitet.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Twitter erhält über die Twitter-Komponente immer dann eine Information darüber,
                    dass die betroffene Person unsere Internetseite besucht hat, wenn die betroffene
                    Person zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Twitter
                    eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene Person die
                    Twitter-Komponente anklickt oder nicht. Ist eine derartige Übermittlung dieser
                    Informationen an Twitter von der betroffenen Person nicht gewollt, kann diese
                    die Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer
                    Internetseite aus ihrem Twitter-Account ausloggt.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Die geltenden Datenschutzbestimmungen von Twitter sind unter &nbsp;
                    <a className={classes.link} href={"https://twitter.com/privacy?lang=de"}>
                      https://twitter.com/privacy?lang=de
                    </a>
                    {""} abrufbar.
                  </Typography>
                </li>{" "}
                <li className={classes.listItem}>
                  <Typography variant="body1">
                    Datenschutzbestimmungen zu Einsatz und Verwendung von Xing
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
                    Komponenten von Xing integriert. Xing ist ein Internetbasiertes soziales
                    Netzwerk, das die Konnektierung der Nutzer mit bestehenden Geschäftskontakten
                    sowie das Knüpfen von neuen Businesskontakten ermöglicht. Die einzelnen Nutzer
                    können bei Xing ein persönliches Profil von sich anlegen. Unternehmen können
                    beispielsweise Unternehmensprofile erstellen oder Stellenangebote auf Xing
                    veröffentlichen.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Betreibergesellschaft von Xing ist die XING SE, Dammtorstraße 30, 20354 Hamburg,
                    Deutschland.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den
                    für die Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
                    Xing-Komponente (Xing-Plug-In) integriert wurde, wird der Internetbrowser auf
                    dem informationstechnologischen System der betroffenen Person automatisch durch
                    die jeweilige Xing-Komponente veranlasst, eine Darstellung der entsprechenden
                    Xing-Komponente von Xing herunterzuladen. Weitere Informationen zum den
                    Xing-Plug-Ins können unter &nbsp;
                    <a className={classes.link} href={"https://dev.xing.com/plugins"}>
                      https://dev.xing.com/plugins
                    </a>
                    {""} abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält Xing
                    Kenntnis darüber, welche konkrete Unterseite unserer Internetseite durch die
                    betroffene Person besucht wird.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Sofern die betroffene Person gleichzeitig bei Xing eingeloggt ist, erkennt Xing
                    mit jedem Aufruf unserer Internetseite durch die betroffene Person und während
                    der gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche
                    konkrete Unterseite unserer Internetseite die betroffene Person besucht. Diese
                    Informationen werden durch die Xing-Komponente gesammelt und durch Xing dem
                    jeweiligen Xing-Account der betroffenen Person zugeordnet. Betätigt die
                    betroffene Person einen der auf unserer Internetseite integrierten Xing-Buttons,
                    beispielsweise den „Share“-Button, ordnet Xing diese Information dem
                    persönlichen Xing-Benutzerkonto der betroffenen Person zu und speichert diese
                    personenbezogenen Daten.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Xing erhält über die Xing-Komponente immer dann eine Information darüber, dass
                    die betroffene Person unsere Internetseite besucht hat, wenn die betroffene
                    Person zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Xing
                    eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene Person die
                    Xing-Komponente anklickt oder nicht. Ist eine derartige Übermittlung dieser
                    Informationen an Xing von der betroffenen Person nicht gewollt, kann diese die
                    Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer
                    Internetseite aus ihrem Xing-Account ausloggt.
                  </Typography>
                  <Typography variant="body1" className={classes.subtitleText}>
                    Die von Xing veröffentlichten Datenschutzbestimmungen, die unter&nbsp;
                    <a className={classes.link} href={"https://www.xing.com/privacy"}>
                      https://www.xing.com/privacy
                    </a>
                    abrufbar sind, geben Aufschluss über die Erhebung, Verarbeitung und Nutzung
                    personenbezogener Daten durch Xing. Ferner hat Xing unter &nbsp;
                    <a
                      className={classes.link}
                      href={"https://www.xing.com/app/share?op=data_protection"}
                    >
                      https://www.xing.com/app/share?op=data_protection
                    </a>
                     Datenschutzhinweise für den XING-Share-Button veröffentlicht.
                  </Typography>
                </li>{" "}
              </ol>
            </div>
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(DataProtectionLongResearcher);
