import React, {Component} from "react";
import {createStyles, Typography, withStyles} from "@material-ui/core";
import {QuopinionTheme} from "../../constants/Theme";

interface Props {
  icon?: any;
  title: string;
  value: string;
  classes: any;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    overviewTitleWrapper: {
      display: "flex",
      padding: `0 0 ${theme.spacing(2)}px`,
      color: theme.palette.grey[400],
    },
    overviewItemIcon: {
      color: theme.palette.grey[400],
      marginRight: theme.spacing(1.25),
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      marginTop: theme.spacing(4),
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      paddingBottom: theme.spacing(2),
    },
    headline: {
      textTransform: "uppercase",
    },
  });

class OverviewItem extends Component<Props> {
  render() {
    const { icon, title, value, classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <div className={classes.overviewTitleWrapper}>
          <div className={classes.overviewItemIcon}>{icon}</div>
          <Typography variant="body1" color="inherit" className={classes.headline}>
            {title}
          </Typography>
        </div>
        <Typography variant="h3">{value}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(OverviewItem);
