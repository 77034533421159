import React from "react";
import { Checkbox, withStyles } from "@material-ui/core";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import { green, red, grey } from "@material-ui/core/colors";
import { CrossCheckbox, ArrowCheckbox } from "../../../assets/icon";

export const PositiveCheckBox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => (
  <Checkbox
    color="default"
    {...props}
    icon={<ArrowCheckbox fill={grey[400]} />}
    checkedIcon={<ArrowCheckbox fill={green[400]} />}
  />
));

export const NegativeCheckBox = withStyles({
  root: {
    color: red[400],
    "&$checked": {
      color: red[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => (
  <Checkbox
    color="default"
    {...props}
    icon={<CrossCheckbox fill={grey[400]} />}
    checkedIcon={<CrossCheckbox fill={red[400]} />}
  />
));
