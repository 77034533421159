import {Dispatch} from "redux";
import {checkOfflineError} from "./error";
import SurveyAnswers from "../entities/SurveyAnswers";
import Survey from "../entities/Survey";
import {ParticipationSurveyState, ParticipationType} from "../services/Survey";
import {recreateUserSession} from "./session";
import Services from "../services/Services";
import ID from "../entities/ID";
import {RootState} from ".";
import {setError, setParticipationCurrentlyNotAllowed} from "./uiState";

const REACH_SENDING_DEADLINE = "questionnaire/REACH_SENDING_DEADLINE";
const SHOW_PARTICIPATION_INTENTION = "questionnaire/SHOW_PARTICIPATION_INTENTION";
const CLEAR_PARTICIPATION_INTENTION = "questionnaire/CLEAR_PARTICIPATION_INTENTION";
const SET_LOADING = "questionnaire/SET_LOADING";
const SET_USER_ANSWERS = "questionnaire/SET_USER_ANSWERS";
const START_QUESTIONNAIRE = "questionnaire/START_QUESTIONNAIRE";

const SET_SURVEYS = "survey/SET_SURVEYS";
const SET_CURRENT_EARNING = "survey/SET_CURRENT_EARNING";
const SET_CURRENT_SURVEY = "survey/SET_CURRENT_SURVEY";
const SET_PARTICIPATION_STATE = "survey/SET_PARTICIPATION_STATE";

interface ParticipationState {
  completedSample: boolean;
  expiredAccess: boolean;
  sent: boolean;
  participationIntention: boolean;
  filledAllAnswers: boolean;
  isLoading: boolean;
  currentEarning: number;

  surveyAnswers: SurveyAnswers;
  openSurveys: Survey[];
  acceptedSurveys: Survey[];
  completedSurveys: Survey[];
  declinedSurveys: Survey[];
  missedSurveys: Survey[];
  waitlistedSurveys: Survey[];
  currentSurvey?: Survey;
  participationState?: ParticipationSurveyState;
}

const defaultState: ParticipationState = {
  completedSample: false,
  expiredAccess: false,
  sent: false,
  participationIntention: false,
  filledAllAnswers: false,
  isLoading: false,
  currentEarning: 0,

  surveyAnswers: new SurveyAnswers(),
  openSurveys: [],
  acceptedSurveys: [],
  completedSurveys: [],
  declinedSurveys: [],
  missedSurveys: [],
  waitlistedSurveys: [],
  currentSurvey: undefined,
  participationState: undefined,
};

interface ParticipationAction {
  type: string;

  currentEarning: number;
  isLoading: boolean;
  surveyAnswers: SurveyAnswers;
  openSurveys: Survey[];
  acceptedSurveys: Survey[];
  completedSurveys: Survey[];
  declinedSurveys: Survey[];
  missedSurveys: Survey[];
  waitlistedSurveys: Survey[];
  currentSurvey: Survey;
  participationState: ParticipationSurveyState;
}

export const setParticipations = (payload: { [key in ParticipationType]: Survey[] }) => {
  return {
    type: SET_SURVEYS,
    ...payload,
  };
};

export const setCurrentParticipation = (currentSurvey: Survey) =>
  ({
    type: SET_CURRENT_SURVEY,
    currentSurvey,
  } as const);

export const setCurrentEarnings = (price: string) =>
  ({
    type: SET_CURRENT_EARNING,
    currentEarning: price,
  } as const);

export const setParticipationState = (participationState: ParticipationSurveyState) =>
  ({
    type: SET_PARTICIPATION_STATE,
    participationState,
  } as const);

export const setLocalUserAnswers = (surveyAnswers: SurveyAnswers) => ({
  type: SET_USER_ANSWERS,
  surveyAnswers,
});

export const reachSendingDeadline = () => ({
  type: REACH_SENDING_DEADLINE,
});

export const showParticipationIntention = () => ({
  type: SHOW_PARTICIPATION_INTENTION,
});

export const clearParticipationIntention = () => ({
  type: CLEAR_PARTICIPATION_INTENTION,
});

export const setLoading = (isLoading: boolean) => ({
  type: SET_LOADING,
  isLoading,
});

/**
 * fetch the actual participations and dispatch the list
 */
export const getParticipations = () => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const participations = await Services.survey.getSurveyParticipationList();

      dispatch(setParticipations(participations));
      dispatch(setLoading(false));
      return Promise.resolve();
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while getting survey participation.");

      const { session } = getState();

      // @TODO: Warum machen wir das hier und sonst nicht? Das sollte eigentlich zentral passieren!
      // @TODO Adam: we could be better with Typing Redux here
      if (session.token) {
        dispatch(recreateUserSession(session.token) as any);
      }

      dispatch(setLoading(false));
      return Promise.reject(err);
    }
  };
};

export const intendParticipation = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoading(true));
    try {
      dispatch(showParticipationIntention());
      dispatch(setLoading(false));
      return Promise.resolve();
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while intending to start survey participation.");
      dispatch(setLoading(false));
      return Promise.reject(err);
    }
  };
};

/**
 * die Umfrage initialisieren
 */
export const getParticipation = (id: ID) => {
  return async (dispatch: Dispatch): Promise<ParticipationSurveyState> => {
    dispatch(setLoading(true));
    try {
      const survey = await Services.survey.getSurvey(id);
      dispatch(setCurrentParticipation(survey));
      const participationState = await Services.survey.getSurveyParticipationState(id);
      dispatch(setParticipationState(participationState));
      const surveyAnswers = new SurveyAnswers(participationState.givenAnswers);
      dispatch(setLocalUserAnswers(surveyAnswers));
      dispatch(setLoading(false));
      return Promise.resolve(participationState);
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while getting survey participation.");
      dispatch(setLoading(false));
      return Promise.reject(err);
    }
  };
};

/**
 * Status Teilnahme setzen und als Nebeneffekt die Umfrage initialisieren
 */
export const startParticipation = (id: ID, answers: any) => {
  return async (dispatch: Dispatch): Promise<ParticipationSurveyState> => {
    dispatch(setLoading(true));
    try {
      const survey = await Services.survey.getSurvey(id);
      dispatch(setCurrentParticipation(survey));
      const participationState = await Services.survey.getSurveyParticipationState(id);
      dispatch(setParticipationState(participationState));
      const surveyAnswers = new SurveyAnswers(participationState.givenAnswers);
      dispatch(setLocalUserAnswers(surveyAnswers));
      switch (participationState.state) {
        case "PARTICIPATION_OPEN":
          try {
            const acceptance = await Services.survey.acceptSurveyParticipation(id, answers);
            dispatch(setLoading(false));
            return Promise.resolve(acceptance);
          } catch (err) {
            dispatch(setLoading(false));
            dispatch(setParticipationCurrentlyNotAllowed());
            return Promise.reject(new Error(err));
          }
        case "PARTICIPATION_REJECTED":
        case "PARTICIPATION_ACCEPTED":
          // already accepted - this is fine
          break;
        case "PARTICIPATION_COMPLETED":
        case "PARTICIPATION_DECLINED":
          dispatch(setLoading(false));
          return Promise.reject(participationState.state);
      }
      dispatch(setLoading(false));
      return Promise.resolve(participationState);
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while starting survey participation.");
      dispatch(setLoading(false));
      return Promise.reject(err);
    }
  };
};

export const declineParticipation = (id: ID) => {
  return async (dispatch: Dispatch): Promise<ParticipationSurveyState> => {
    dispatch(setLoading(true));
    try {
      const survey = await Services.survey.getSurvey(id);
      dispatch(setCurrentParticipation(survey));
      const participationState = await Services.survey.getSurveyParticipationState(id);
      dispatch(setParticipationState(participationState));
      switch (participationState.state) {
        case "PARTICIPATION_OPEN":
          await Services.survey.declineSurveyParticipation(id);
          dispatch(setLoading(false));
          break;
        case "PARTICIPATION_DECLINED":
          // already declined - this is fine
          break;
        case "PARTICIPATION_REJECTED":
        case "PARTICIPATION_ACCEPTED":
        case "PARTICIPATION_COMPLETED":
          dispatch(setLoading(false));
          return Promise.reject(participationState.state);
      }
      dispatch(setLoading(false));
      return Promise.resolve(participationState);
    } catch (err) {
      console.error(err);
      checkOfflineError(dispatch, err, "Error while declining survey participation.");
      dispatch(setLoading(false));
      return Promise.reject(err);
    }
  };
};

export const setUserAnswers = (surveyAnswers: SurveyAnswers) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLocalUserAnswers(surveyAnswers));

      // save current answers to server
      await Services.survey.updateAnswers(
        getState().participation.currentSurvey!.id,
        surveyAnswers.toDataJSON()
      );

      return Promise.resolve();
    } catch (error) {
      if (
        error.error &&
        error.error.response !== undefined &&
        error.error.response.status !== 422
      ) {
        dispatch(setError());
      }
      return Promise.reject();
    }
  };
};

export const completeParticipation = (surveyID: ID) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      // complete participation

      const participationState = await Services.survey.getSurveyParticipationState(surveyID);
      dispatch(setParticipationState(participationState));

      switch (participationState.state) {
        case "PARTICIPATION_ACCEPTED":
          const newParticipationState = await Services.survey.completeSurveyParticipation(surveyID);
          dispatch(setParticipationState(newParticipationState));
          dispatch(setLoading(false));
          return Promise.resolve(newParticipationState);
        case "PARTICIPATION_COMPLETED":
          dispatch(setLoading(false));
          return Promise.resolve();
        case "PARTICIPATION_OPEN":
        case "PARTICIPATION_REJECTED":
        case "PARTICIPATION_DECLINED":
          dispatch(setLoading(false));
          return Promise.reject(participationState.state);
        default:
          break;
      }
      dispatch(setLoading(false));
      return Promise.resolve(participationState);
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while completing survey participation.");
    }
  };
};

export const getExpiredAccess = () => {
  return async (dispatch: Dispatch) => {
    dispatch(reachSendingDeadline());
  };
};

export const reducer = (
  state: ParticipationState = defaultState,
  action: ParticipationAction
): ParticipationState => {
  switch (action.type) {
    case SET_SURVEYS:
      return {
        ...state,
        openSurveys: action.openSurveys,
        acceptedSurveys: action.acceptedSurveys,
        completedSurveys: action.completedSurveys,
        missedSurveys: action.missedSurveys,
        declinedSurveys: action.declinedSurveys,
        waitlistedSurveys: action.waitlistedSurveys,
      };
    case SET_CURRENT_SURVEY:
      return {
        ...state,
        currentSurvey: action.currentSurvey,
      };
    case SET_PARTICIPATION_STATE:
      return {
        ...state,
        participationState: action.participationState,
      };
    case SET_CURRENT_EARNING:
      return {
        ...state,
        currentEarning: action.currentEarning,
      };

    ////

    case REACH_SENDING_DEADLINE:
      return {...state, expiredAccess: true};
    case SHOW_PARTICIPATION_INTENTION:
      return {...state, participationIntention: true};
    case CLEAR_PARTICIPATION_INTENTION:
      return {...state, participationIntention: false};
    case SET_LOADING:
      return {...state, isLoading: action.isLoading};
    case START_QUESTIONNAIRE:
      return {...state, surveyAnswers: new SurveyAnswers()};
    case SET_USER_ANSWERS:
      return {
        ...state,
        surveyAnswers: action.surveyAnswers,
      };
    default:
      return state;
  }
};
