import React, { PureComponent } from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";

import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import AccountSelect from "../../marketintelligence/Dashboard/components/AccountButton";
import { QuopinionTheme } from "../../../constants/Theme";

import { fetchUser, fetchCurrentResearcher } from "../../../modules/user";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { withAuthorization } from "../../../common/hoc/withAuthorization";
import { UserRole } from "../../../entities/User";
import { Links } from "../../marketintelligence/Dashboard";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import AttributeCard from "./components/AttributeCard";
import { fetchAttributes } from "../../../modules/attribute";
import Attribute, { AttributeType } from "../../../entities/Attribute";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { translations } from "../../../constants/lang/translation";
import { DropdownArrowGrey } from "../../../assets/icon";

import moment from "moment";

export const route = "/admin/attributes/";

interface State {
  selectedSort: string;
}

interface OwnProps {}

interface DispatchProps {
  fetchUser: typeof fetchUser;
  fetchCurrentResearcher: typeof fetchCurrentResearcher;
  fetchAttributes: typeof fetchAttributes;
}

interface StateProps {
  isAuthenticated: boolean | undefined;
  isLoadingAttributes: boolean;
  attributes: Attribute[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    customLogobar: {
      display: "flex",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.spacing(4),
    },
    card: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: theme.spacing(6),
    },
    cardTitle: {
      textTransform: "uppercase",
    },
    cardRow: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: theme.spacing(6),
      },
    },
    selectMenu: {
      width: 100,
      "&$active": {
        borderRadius: "4px",
      },
      "&:focus": {
        borderRadius: "4px",
      },
    },
    select: {
      position: "relative",
      "& svg": {
        width: 20,
        top: 1,
        right: 8,
        position: "absolute",
        height: "calc(100% - 2px)",
        pointerEvents: "none",
      },
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{ type: string }>;

class AttributeList extends PureComponent<Props, State> {
  state: State = {
    selectedSort: "date",
  };

  componentDidMount() {
    const {
      match: {
        params: { type },
      },
    } = this.props;
    if (this.props.isAuthenticated) {
      this.props.fetchUser();
      this.props.fetchCurrentResearcher();
      this.props.fetchAttributes({ type: type as AttributeType });
    }
    this.setState({
      selectedSort: type === "SCREENER" ? "date" : "abc",
    });
  }

  setSort = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    this.setState({
      selectedSort: event.target.value as string,
    });
  };

  renderLogoBarCustomArea = (props: Props) => {
    const { classes } = this.props;
    return (
      <div className={classes.customLogobar}>
        <AccountSelect />
      </div>
    );
  };

  renderAttributeList() {
    const { attributes } = this.props;

    if (!attributes.length) {
      return <Typography>Keine entsprechenden Attribute vorhanden.</Typography>;
    } else {
      if (this.state.selectedSort === "date") {
        return attributes
          .sort((a, b) =>
            moment(a.createdAt).isAfter(b.createdAt)
              ? -1
              : moment(b.createdAt).isAfter(a.createdAt)
              ? 1
              : 0
          )
          .map(this.renderAttribute);
      } else {
        return attributes.sort((a, b) => a.name.localeCompare(b.name)).map(this.renderAttribute);
      }
    }
  }

  renderAttribute = (attribute: Attribute) => {
    return <AttributeCard key={attribute.id} attribute={attribute} />;
  };

  renderContent() {
    const {
      classes,
      isLoadingAttributes,
      match: {
        params: { type },
      },
      t,
    } = this.props;
    const { selectedSort } = this.state;
    return (
      <Grid container={true}>
        <ContentWrapper>
          <Container>
            <div className={classes.header}>
              <Typography variant="h2">
                {type === "SCREENER" && t(translations.pages.admin.dashboard.attributes.dailies)}
                {type === "DEFAULT" && t(translations.pages.admin.dashboard.attributes.general)}
              </Typography>
              <Select
                value={selectedSort}
                onChange={this.setSort}
                className={classes.select}
                classes={{ selectMenu: classes.selectMenu }}
                IconComponent={DropdownArrowGrey}
                name={"selectedSort"}
              >
                <MenuItem value="date">
                  {t(translations.pages.admin.dashboard.attributes.sort.date)}
                </MenuItem>
                <MenuItem value="abc">
                  {t(translations.pages.admin.dashboard.attributes.sort.alphabetically)}
                </MenuItem>
              </Select>
            </div>
            {isLoadingAttributes && <LoadingOverlay />}
            {!isLoadingAttributes && this.renderAttributeList()}
          </Container>
        </ContentWrapper>
      </Grid>
    );
  }

  render() {
    return (
      <div>
        <LogoBarResearcher links={Links} children={this.renderLogoBarCustomArea(this.props)} />
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = ({ session, attribute }: RootState) => ({
  isAuthenticated: session.authenticated,
  attributes: attribute.attributes,
  isLoadingAttributes: attribute.isLoadingAttributes,
});

const mapDispatchToProps = {
  fetchUser,
  fetchCurrentResearcher,
  fetchAttributes,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  withAuthorization(UserRole.ADMIN),
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(AttributeList);
