import React, { PureComponent } from "react";
import { createStyles, Typography, withStyles, WithStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";

interface OwnProps {}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    title: {
      "@media screen and (max-width: 400px)": {
        fontSize: "24px",
      },
    },
    subtitleText: {
      marginBottom: theme.spacing(5),
      "& a": {
        textDecoration: "underline",
      },
    },
    subheadline: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(3),
    },
    wrapper: {
      "& a": {
        color: "inherit",
      },
      "& a:visited": {
        color: "inherit",
      },
    },
    link: {
      textDecoration: "underline",
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

export const route = "/participant/privacy";

class TermsOfUse extends PureComponent<Props> {
  render() {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.wrapper}>
          <Typography
            variant="h1"
            style={{ marginBottom: 40 }}
            color="secondary"
            className={classes.title}
          >
            {t(translations.pages.termsConditions.participant.title)}{" "}
          </Typography>
          <Typography variant="h3" className={classes.subheadline}>
            1. Leistungsumfang: &nbsp; Was bieten wir?
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            Die Quotas GmbH betreibt unter{" "}
            <a className={classes.link} href="https://www.quopinion.de">
              www.quopinion.de
            </a>{" "}
            ein Online-Panel. Die Quotas GmbH bietet mit Mitgliedern die Möglichkeit, an
            Online-Meinungs- bzw. Marktforschungsumfragen teilzunehmen.
          </Typography>
          <Typography variant="h3" className={classes.subheadline}>
            2. Mitgliedschaft
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.1 Für die Mitgliedschaft ist eine einmalige Registrierung erforderlich. Die
            Mitgliedschaft ist kostenlos.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.2 Mitglied kann jede volljährige natürliche Person werden. Minderjährige ab 14 Jahre
            bedürfen die Zustimmung Ihrer gesetzlichen Vertreter. Mitglieder unter 14 Jahren werden
            unverzüglich und ohne Benachrichtigung gelöscht. Das gesamte Guthaben verfällt.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.3 Jedes Mitglied kann sich nur einmal anmelden. Das Mitglied ist verpflichtet, die zur
            Anmeldung erforderlichen Angaben vollständig und richtig zu machen. Das Mitglied
            verpflichtet sich ferner, seine Kontaktdaten, aktuell zu halten. Eine Änderung der
            E-Mailadresse ist möglich, diese muss aber telefonisch durch einen unserer
            Service-Mitarbeiter erfolgen.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.4 Die Mitgliedschaft ist nicht übertragbar. Jedes Mitglied ist verpflichtet seine
            Zugangsdaten geheim zu halten und nicht an Dritte weiterzugeben.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.5 Jedes Mitglied ist verpflichtet, die Befragungen nach bestem Wissen und Gewissen zu
            beantworten.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.6 Bei Verstößen gegen die Verpflichtungen aus Ziffern 2.3-2.5 behalten wir uns eine
            Sperrung des Kontos sowie eine Kündigung des Nutzungsvertrages vor.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.7 Sollte die im Rahmen der Anmeldung angegebene E-Mail-Adresse nicht erreichbar sein,
            versucht die Quotas GmbH, dieses Mitglied unter den übrigen, während der Anmeldung
            angegebenen Kontaktmöglichkeiten zu erreichen. Sollte auch dies fehlschlagen, werden die
            Datensätze dieses Mitglieds einschließlich etwaiger Gutschriften gelöscht. Das Mitglied
            kann sich erneut anmelden, hat aber keinen Anspruch auf die gelöschten Gutschriften.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.8 Der Panel-Teilnehmer sichert zu, dass seine Angaben der Wahrheit entsprechen. Der
            Betreiber des Panels ist berechtigt, Panel-Teilnehmer, die bei ihrer Registrierung
            falsche oder irreführende Angaben machen, von der Mitgliedschaft auszuschließen. Bei
            einer endgültigen Sperre besteht kein Anspruch auf Wiederherstellung des gesperrten
            Teilnehmerkontos. Sobald ein Teilnehmer gesperrt wurde, darf sich dieser auch mit
            anderen E-Mail Adressen nicht wieder registrieren. Der Betreiber behält sich das Recht
            vor, Teilnehmerkonten mit nicht vollständig durchgeführten Registrierungen zu löschen
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.9 Jedes Mitglied verpflichtet sich, die Versteuerung der von uns vergüteten Beträge
            selber vorzunehmen.
          </Typography>
          <Typography variant="h3" className={classes.subheadline}>
            3. Ablauf von Online-Befragungen
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.1 Panel-Mitglieder werden in unregelmäßigen Zeitabständen via E-Mail/Push-Nachrichten
            oder auf anderem Weg eingeladen, um an Online-Befragungen teilzunehmen. Ein Anspruch auf
            eine Teilnahme besteht nicht. Durch die Mitgliedschaft im Quopinion-Panel entsteht keine
            Teilnahmeverpflichtung. Jedes Mitglied entscheidet selbst, ob es die Einladung, an einer
            Online-Umfrage teilzunehmen, annimmt.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.2 Für jede vollständig ausgefüllte Umfrage erhält das Mitglied den von ihm in seinem
            Profil festgelegten Betrag auf seinem Quopinion-Konto gutgeschrieben. Dieser Betrag wird
            vor dem Start der Befragung angezeigt.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.3 Neben bezahlten Online-Umfragen erhalten Mitglieder Einladungen zu profilierenden
            Umfragen (Screenings) zur Erhöhung der Zielgruppen-Genauigkeit und somit die
            Möglichkeit, an mehr Studien teilnehmen zu können. Für die Teilnahme an Screenings
            werden keine Beträge gutgeschrieben.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.4 Bricht das Mitglied eine Befragung ab, so entsteht kein Anspruch auf eine
            Gutschrift, es sei denn, der Abbruch ist durch die Quotas GmbH zu vertreten.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.5 Ein Guthaben kann ab einem Betrag von 5 Euro jederzeit ausgezahlt werden. Geben Sie
            dazu in Ihrem Benutzerkonto die gewünschte Transaktionsmethode an und folgen Sie den
            Anweisungen.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.6 Jedes Mitglied kann jederzeit seinen Kontostand über{" "}
            <a className={classes.link} href="https://www.quopinion.de">
              www.quopinion.de
            </a>{" "}
            einsehen.
          </Typography>
          <Typography variant="h3" className={classes.subheadline}>
            4. Kündigung
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            4.1 Sie können Ihr Konto jederzeit kündigen, indem Sie uns unter panel@quopinion.de
            kontaktieren. Unmittelbar bei der Löschung Ihres Kontos wird Ihr Recht auf den Zugriff
            sowie Ihr Quopinion-Panelguthaben vollständig verfallen.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            4.2 Die Quotas GmbH kann Ihr Konto jederzeit aus beliebigem Grund kündigen, dabei
            verfällt das Quopinion-Panelguthaben unwiderruflich. Ebenso gilt dieses für langfristig
            nicht zu erreichende Emailadressen (max. 3 Kontaktversuche) und brachliegende
            Quopinion-Panelkonten.
          </Typography>

          <Typography variant="h3" className={classes.subheadline}>
            5. Rechte an den Befragungsergebnissen
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            5.1 Die Rechte an den Befragungsergebnissen liegen ausschließlich bei der Quotas GmbH.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            5.2 Die Veröffentlichung oder Vervielfältigung des Inhalts der Umfragen durch Mitglieder
            des Quopinion-Panels sind untersagt.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            5.3 Jedes Mitglied verpflichtet sich, durch Umfragen erlangte Informationen weder zu
            speichern noch Dritten in irgendeiner Weise zugänglich zu machen.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            5.4 Von Zeit zu Zeit können sich die Funktionen der Website ändern, dies kann zu
            Veränderungen der Nutzungsbestimmungen führen. Dabei ist eine angemessene Frist zum
            Wirksamwerden der Änderungen einzuhalten (4 Wochen). Sofern der Teilnehmer nicht
            widerspricht oder seine Teilnahme beendet, wird sein Verhalten als Zustimmung zu den
            neuen Teilnahmeregeln gewertet.
          </Typography>

          <Typography variant="body1" className={classes.subtitleText}>
            Änderungen werden den Teilnehmern insbesondere durch Publikation auf der Website oder
            Hinweise in E-Mails mitgeteilt. Sie sollten die jeweilige Seite regelmäßig besuchen, um
            sich über solche Änderungen zu informieren. Durch Fortsetzung der Nutzung der Webseite
            und durch Ihre Teilnahme an Umfragen nach Vornahme solcher Änderungen erklären Sie sich
            mit diesen Änderungen einverstanden.
          </Typography>

          <Typography variant="h3" className={classes.subheadline}>
            6. Abschließende Bestimmungen
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            Diese Nutzungsbestimmungen unterliegen ausschließlich deutschem Recht.
          </Typography>
        </div>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(TermsOfUse);
