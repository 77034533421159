import { Dispatch } from "redux";
import Services from "../services/Services";
import { setLoading } from "./participation";

interface MailTemplateAction {
  type: string;
  invitationTemplates: String[];
  isLoading: boolean;
}

export interface MailTemplateState {
  surveyInvitation: String[];
  isLoading: boolean;
}

const defaultState: MailTemplateState = {
  surveyInvitation: [],
  isLoading: false,
};

const SET_SURVEY_INVITATION_TEMPLATES = "mailTemplates/SET_SURVEY_INVITATION_TEMPLATES";
const SET_IS_LOADING = "mailTemplates/SET_IS_LOADING";

//general
export const setIsLoading = (isLoading: boolean) => ({
  type: SET_IS_LOADING,
  isLoading,
});

export const setSurveyInvitationTemplates = (invitationTemplates: String[]) => {
  return {
    type: SET_SURVEY_INVITATION_TEMPLATES,
    invitationTemplates,
  };
};

export const fetchSurveyInvitationTemplates = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const invitationTemplates = await Services.mailTemplate.fetchSurveyInvitationMailTemplates();
      dispatch(setSurveyInvitationTemplates(invitationTemplates));
      dispatch(setIsLoading(false));
      return Promise.resolve();
    } catch (error) {
      dispatch(setLoading(false));
      return Promise.reject(error);
    }
  };
};

export const reducer = (
  state: MailTemplateState = defaultState,
  action: MailTemplateAction
): MailTemplateState => {
  switch (action.type) {
    case SET_SURVEY_INVITATION_TEMPLATES:
      return { ...state, surveyInvitation: action.invitationTemplates };
    default:
      return state;
  }
};
