import React from "react";
import { styled } from "@material-ui/styles";
import { PlayArrow } from "@material-ui/icons";
import { QuopinionTheme } from "../../../../constants/Theme";

const Root = styled("div")((props: { theme: QuopinionTheme }) => ({
  height: 32,
  width: 30,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: props.theme.palette.secondary.main,
  borderRadius: 3,
}));

const Play = styled(PlayArrow)((props: { theme: QuopinionTheme }) => ({
  color: props.theme.palette.common.white,
}));

const StartScreener = () => (
  <Root>
    <Play />
  </Root>
);

export default StartScreener;
