import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, withStyles, WithStyles, Grid, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";

import { QuopinionTheme } from "../../../constants/Theme";

interface OwnProps {
  title: string;
  description: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      textAlign: "center",
      padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px ${theme.spacing(
        4
      )}px`,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "@media (min-width: 960px)": {
        textAlign: "unset",
        display: "initial",
        alignItems: "unset",
        flexDirection: "unset",
        padding: `${theme.spacing(31)}px ${theme.spacing(4)}px ${theme.spacing(
          12
        )}px ${theme.spacing(37.75)}px`,
      },
      "@media (min-width: 1024px)": {
        padding: `${theme.spacing(49.5)}px ${theme.spacing(0)}px ${theme.spacing(
          20
        )}px ${theme.spacing(25.5)}px`,
      },
    },
    description: {
      color: theme.palette.grey[400],
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(6)}px`,
      "@media (min-width: 600px)": {
        margin: `${theme.spacing(6)}px 0 ${theme.spacing(6)}px`,
      },
      maxWidth: theme.spacing(95),
      height: theme.spacing(31.25),
    },
    headline: {
      fontSize: "40px",
      fontWeight: 600,
      lineHeight: 1.1,
      letterSpacing: "normal",
      fontStretch: "normal",
      maxWidth: theme.spacing(106.25),
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class CarouselTextItem extends PureComponent<Props> {
  render() {
    const { title, description, classes } = this.props;
    return (
      <Grid item={true} xs={12} sm={7} md={6} className={classes.root}>
        <Typography className={classes.headline} color="primary">
          {title}
        </Typography>
        <Typography variant="body1" color="inherit" className={classes.description}>
          {description}
        </Typography>
      </Grid>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(CarouselTextItem);
