import React, { PureComponent } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  createStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog";
import Button from "./Button";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../constants/Theme";

interface OwnProps extends DialogProps {
  title: string;
  description?: string;
  closeFunction: () => void;
  confirmFunction: () => void;
  buttonText: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;
class DialogComponent extends PureComponent<Props> {
  render() {
    const {
      title,
      description,
      t,
      confirmFunction,
      closeFunction,
      open,
      classes,
      buttonText,
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={closeFunction}
        disableAutoFocus={true}
        classes={{ paper: classes.root }}
      >
        <DialogContent>
          <Typography variant="h3">{title}</Typography>
          <Typography variant="subtitle1">{description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button white={true} size="small" color="primary" onClick={closeFunction}>
            {t(translations.action.cancel)}
          </Button>
          <Button size="small" color="primary" onClick={confirmFunction} contained={true}>
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(DialogComponent);
