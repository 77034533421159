import Client from "./Client";
import User, { PersonalData } from "../entities/User";
import ID from "../entities/ID";
import { UserAttributesResponse } from "../modules/user";
import { ResearcherRegistrationForm } from "../pages/marketintelligence/Register/RegisterForm";
import { ParticipantRegistrationForm } from "../entities/ParticipantRegistrationForm";

export type OpinionValueKeys = "low" | "medium" | "high";
export type ResultOpinionValueKeys = "small" | "medium" | "large";
export type OpinionValueRangeKeys = "small" | "medium" | "large";
export type OpinionValueItem = {
  [key in OpinionValueRangeKeys]: number;
};
export type OpinionValueResponse = {
  [key in OpinionValueKeys]: OpinionValueItem;
};

export type TransientOpinionValues = {
  [key in OpinionValueKeys]: OpinionValueRangeKeys | "" | null;
};

export type OpinionValues = {
  [key in OpinionValueKeys]: number;
};
export type ResultOpinionValues = {
  [key in ResultOpinionValueKeys]: number;
};

export default class SessionService {
  readonly httpClient: Client;
  route: string = "/sessions";

  constructor(httpClient: Client) {
    this.httpClient = httpClient;
  }

  async registerUser(
    emailAddress: string,
    password: any,
    personalData: any,
    participationPrice: any,
    attributeAnswers: { attributeId: string; answer: any }[],
    howDidYouHearAboutUs: string
  ) {
    return this.httpClient
      .post("/accounts", {
        emailAddress,
        password,
        role: "participant",
        personalData,
        participationPrice,
        attributeAnswers,
        howDidYouHearAboutUs,
      })
      .then((body) => body);
  }

  async updateUser(id: ID, userAttributes: any): Promise<User> {
    return this.httpClient.put(`/account/${id}`, userAttributes).then((body) => new User(body));
  }

  async registerResearcher(registrationData: ResearcherRegistrationForm) {
    return this.httpClient.post("/accounts/researcher", registrationData).then((body) => body);
  }

  async registerResearcherWithSurveyToken(
    registrationData: ResearcherRegistrationForm,
    surveyToken: string
  ) {
    return this.httpClient
      .post("/accounts/researcher", { ...registrationData, surveyToken })
      .then((body) => body);
  }

  async verifyUser(token: string) {
    return this.httpClient.post("/accounts/verifyEmailAddress", { token });
  }

  async getUserSession(emailAddress: string, password: any) {
    return this.httpClient.post(this.route, { emailAddress, password }).then((body) => body);
  }

  async getUserSessionWithToken(emailAddress: string, password: any, surveyToken: string) {
    return this.httpClient
      .post(this.route, { emailAddress, password, surveyToken })
      .then((body) => body);
  }

  async deleteUserSession() {
    return this.httpClient.delete(`${this.route}/_current`).then((body) => body);
  }

  async recreateUserSession(token: string) {
    return this.httpClient.post(`${this.route}/_recreate`, { token }).then((body) => body);
  }

  async fetchUser(): Promise<User> {
    return await this.httpClient.get(`/accounts/_self`).then((body) => new User(body));
  }

  async fetchParticipantRegistrationConfiguration(): Promise<ParticipantRegistrationForm> {
    return await this.httpClient.get(`/attributes/_default`).then((body) => body);
  }

  async fetchAttributesForUser(): Promise<UserAttributesResponse> {
    return await this.httpClient.get(`accounts/_self/attributes`).then((body) => body);
  }

  async fetchPersonalDataForUser(): Promise<PersonalData> {
    return await this.httpClient.get(`accounts/_self/personalData`).then((body) => body);
  }

  async fetchParticipantInfoOfUser(): Promise<any> {
    return await this.httpClient.get(`accounts/_self/participantInfo`).then((body) => body);
  }

  async resetPasswordRequest(emailAddress: string, name: string, message: string): Promise<any> {
    return await this.httpClient.post(`accounts/request-reset`, { emailAddress, name, message });
  }

  async resetPassword(password: string, token: string): Promise<any> {
    return await this.httpClient.post(`accounts/reset`, { password, token });
  }

  async fetchPotentialOpinionValues(): Promise<OpinionValueResponse> {
    // we want to deliver the same values for a unique device - this is an easy implementation
    let randomizeID: string | null = localStorage.getItem("quopinion:randomizeID");
    if (!randomizeID) {
      randomizeID = String(Math.round(Math.random() * 1000));
      localStorage.setItem("quopinion:randomizeID", randomizeID);
    }

    // return {
    //   small: { left: 10, center: 20, right: 30 },
    //   medium: { left: 50, center: 60, right: 70 },
    //   large: { left: 100, center: 120, right: 140 },
    // };
    return await this.httpClient.get(`/participants/price?s=${randomizeID}`);
  }
}
