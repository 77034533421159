import React, {CSSProperties} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {translations} from "../../../../constants/lang/translation";
import {compose} from "recompose";

const iconStyles: CSSProperties = {
  width: 30,
  height: 30,
  margin: "0 16px",
  position: "relative",
};
const operatorStyles: CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  fontWeight: "normal",
};

interface OwnProps {
}

type Props = OwnProps & WithTranslation;


 const OrOpertor: React.FC<Props> = ({t}) => {
  return <div style={iconStyles}>
    <div style={operatorStyles}>{t(translations.operator.or)}</div>
  </div>;
};

export const Or = compose<Props, OwnProps>(
  withTranslation()
)(OrOpertor);


const AndOperator: React.FC<Props> = ({t}) => {
 return <div style={iconStyles}>
    <div style={operatorStyles}>{t(translations.operator.and)}</div>
  </div>
}

export const And = compose<Props, OwnProps>(
withTranslation()
)(AndOperator);
