import ID from "./ID";

/**
 * Entity class Panel
 * that represents the the panel of a Survey
 */
export class ResultPanel {
  id: ID;
  title: string;
  size: number;
  criteria: any;
  createdAt: string;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.title = obj.title;
    this.size = obj.size || 350;
    this.criteria = obj.criteria || [];
    this.createdAt = obj.createdAt;
  }
}
