import * as React from "react";
import QuestionContainer from "../../Questionnaire/QuestionContainer";
import { AnsweredAttribute } from "../../../../services/Accounts";
import { connect } from "react-redux";
import { IconButton, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { RootState } from "../../../../modules";
import { fetchDailyScreener, updateUserAttributes } from "../../../../modules/attribute";
import { Screener } from "../../../../entities/Screener";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";
import ID from "../../../../entities/ID";
import { ArrowBack, Cross } from "../../../../assets/icon";
import { QuopinionTheme } from "../../../../constants/Theme";
import { styled } from "@material-ui/styles";
import { route as participantAttributesRoute } from "../../Attributes/index";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    closeButton: {
      position: "fixed",
      top: 31,
      right: 16,
      padding: theme.spacing(1),
      width: 40,
      height: 40,
      cursor: "pointer",
      zIndex: 999,
    },
    backButton: {
      position: "fixed",
      top: 31,
      left: 4,
      padding: 0,
      width: 40,
      height: 40,
      cursor: "pointer",
      zIndex: 999,
    },
  });

export interface OwnProps {
  screenerQuestions: [];
}

export interface DispatchProps {
  fetchDailyScreener: () => Promise<void>;
  updateUserAttributes: (newAttribute: AnsweredAttribute) => Promise<void>;
}

export interface State {
  currentQuestion: number;
  error?: string;
}

export interface StateProps {
  dailyScreener: Screener[] | undefined;
  dailyScreenerAmount: number;
}

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  RouteComponentProps<{ questionID: ID }>;

export const route: string = "/participant/daily/:questionId?";

class DailyScreenerPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentQuestion: 0,
    };
  }

  componentDidMount = async () => {
    await this.props.fetchDailyScreener();
  };

  updateAttribute = async (newAttribute: AnsweredAttribute) => {
    try {
      await this.props.updateUserAttributes(newAttribute);

      if (this.state.currentQuestion + 1 === this.props.dailyScreenerAmount) {
        this.props.history.push(participantAttributesRoute);
      } else {
        this.setState({
          currentQuestion: this.state.currentQuestion + 1,
        });
      }
    } catch (e) {
      this.setState({
        error: e.message,
      });
    }
  };

  goToPreviousQuestion = () => {
    this.setState({
      currentQuestion: this.state.currentQuestion - 1,
    });
  };

  exitQuestionnaire = () => {
    this.props.history.push("/participant/attributes");
  };

  render() {
    const { classes, dailyScreener, dailyScreenerAmount } = this.props;
    const { currentQuestion } = this.state;

    if (!dailyScreener) {
      return <LoadingOverlay />;
    }

    return (
      <Container>
        {currentQuestion > 0 && (
          <IconButton onClick={this.goToPreviousQuestion} className={classes.backButton}>
            <ArrowBack fill={"#fff"} width={24} height={24} />
          </IconButton>
        )}
        <IconButton onClick={this.exitQuestionnaire} className={classes.closeButton}>
          <Cross fill={"#fff"} width={24} height={24} />
        </IconButton>

        {dailyScreener
          .filter((_: Screener, index: number) => index === currentQuestion)
          .map((screener) => (
            <QuestionContainer
              key={screener.id}
              question={screener.questions[0]}
              onNextClick={this.updateAttribute}
              currentQuestionnaireStep={currentQuestion + 1}
              totalQuestionnaireSteps={dailyScreenerAmount!}
              isScreener={true}
              attributeId={screener.questions[0].attributeID}
            />
          ))}
      </Container>
    );
  }
}

const mapStateToProps = ({ attribute }: RootState) => ({
  dailyScreener: attribute.dailyScreenerLeft,
  dailyScreenerAmount: attribute.dailyScreenerLeftAmount,
});
const mapDispatchToProps = {
  fetchDailyScreener,
  updateUserAttributes,
};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DailyScreenerPage);

const Container = styled("div")({
  position: "relative",
  height: "100%",
});
