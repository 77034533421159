import React, { useState } from "react";
import { Typography, Modal, createStyles, makeStyles, TextField } from "@material-ui/core";
import { QuopinionTheme } from "../../../../constants/Theme";
import { useTranslation } from "react-i18next";
import { translations } from "../../../../constants/lang/translation";
import Button from "../../../../common/Layout/components/Button";
import { styled } from "@material-ui/styles";
import { NewRequestedAttribute } from "../../../../entities/RequestedAttribute";
import ID from "../../../../entities/ID";
import { LoginForm } from "../../Login/LoginForm";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme: QuopinionTheme) =>
  createStyles({
    headline: {
      marginBottom: theme.spacing(2),
    },
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: theme.zIndex.tooltip + 1,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.grey[50],
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 4),
    },
    textField: {
      width: "100%",
      margin: `${theme.spacing(6)}px 0`,
    },
    buttonLine: {
      marginTop: theme.spacing(8),
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

interface Props {
  open: boolean;
  isSubmitting: boolean;
  surveyId: ID;
  onClose: () => void;
  requestAttribute: (newAttribute: NewRequestedAttribute) => void;
  requestSuccessful?: boolean;
  requestError?: boolean;
  requestAnotherAttribute: () => void;
  isAuthenticated: boolean;
}

const NewAttributesModal = ({
  open,
  onClose,
  isSubmitting,
  requestAttribute,
  surveyId,
  requestSuccessful,
  requestError,
  isAuthenticated,
  requestAnotherAttribute,
}: Props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [textFieldValue, setTextFieldValue] = useState({ textFieldValue: "" });
  let submitInnerForm: any;

  const handleSubmitInnerForm = (e: any) => {
    if (submitInnerForm !== null) {
      submitInnerForm(e);
    }
  };

  const bindSubmitForm = (submitForm: any) => {
    submitInnerForm = submitForm;
  };

  return (
    <Modal
      className={classes.modal}
      aria-labelledby={"label"}
      aria-describedby={"description"}
      open={open}
      onClose={onClose}
      disableAutoFocus={true}
      closeAfterTransition={true}
    >
      <div className={classes.content}>
        <Typography variant="h2" className={classes.headline}>
          {t(translations.panel.newAttributeHeadline)}
        </Typography>
        <Typography variant="body2">{t(translations.panel.newAttributeText)}</Typography>

        {requestSuccessful ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "48px 0",
            }}
          >
            <CheckCircleIcon style={{ color: "green", width: "100px", height: "100px" }} />
            <Typography variant="body2">{t(translations.panel.requestedAttribute)}</Typography>
          </div>
        ) : isAuthenticated ? (
          <TextField
            className={classes.textField}
            multiline={true}
            rows={4}
            rowsMax={4}
            value={textFieldValue.textFieldValue}
            disabled={isSubmitting}
            onChange={(event) => setTextFieldValue({ textFieldValue: event.target.value })}
          />
        ) : (
          <>
            <br />
            <Typography variant="body2">
              {t(translations.panel.generalInfo.loginRequired)}
            </Typography>
            <LoginForm bindSubmitForm={bindSubmitForm} onLoginPage={true} />
            <div className={classes.buttonLine}>
              <Button
                size="small"
                color="secondary"
                contained={true}
                onClick={handleSubmitInnerForm}
              >
                {t(translations.action.login)}
                {/* disabled fehlt*/}
              </Button>
            </div>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {requestError && <ErrorMessage>{t(translations.panel.requestError)}</ErrorMessage>}

          {requestSuccessful ? (
            <>
              <SubmitButton
                color="secondary"
                size="medium"
                onClick={() => {
                  onClose();
                }}
              >
                {t(translations.action.close)}
              </SubmitButton>
              <SubmitButton
                color="secondary"
                size="medium"
                contained={true}
                onClick={() => {
                  setTextFieldValue({ textFieldValue: "" });
                  requestAnotherAttribute();
                }}
              >
                {t(translations.action.requestFurtherItems)}
              </SubmitButton>
            </>
          ) : (
            isAuthenticated && (
              <SubmitButton
                color="secondary"
                size="medium"
                contained={true}
                disabled={!textFieldValue.textFieldValue || isSubmitting}
                onClick={() =>
                  requestAttribute({ surveyId, message: textFieldValue.textFieldValue })
                }
                style={{ marginRight: 0, marginLeft: "auto" }}
              >
                {t(translations.panel.newAttributeButton)}
              </SubmitButton>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

const SubmitButton = styled(Button)((props) => ({
  alignSelf: "flex-end",
}));

const ErrorMessage = styled(Typography)((props) => ({
  alignSelf: "flex-end",
}));

export default NewAttributesModal;
