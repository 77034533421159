import React, { PureComponent } from "react";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import SupportContainer from "../../../common/Layout/SupportContainer";
import { createStyles, Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import { translations } from "../../../constants/lang/translation";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose, shallowEqual } from "recompose";
import SurveyCard from "./components/SurveyCard";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import SidebarContainer from "../../../common/Layout/SidebarContainer";
import ButtonLink from "./components/ButtonLink";
import { RootState } from "../../../modules";
import i18next from "i18next";
import { SurveyInformation, SurveyStatus } from "../../../entities/Survey";
import Panel from "../../../entities/Panel";
import { route as BillingRoute } from "../Account/BillingData";
import { route as PersonalInfoRoute } from "../Account/PersonalInformation";
import { route as AccountRoute } from "../Account";
import { route as TermsAndConditionsResearcherRoute } from "../TermsConditions";
import Researcher from "../../../entities/Researcher";
import User from "../../../entities/User";
import { surveyDateComparator } from ".";
import LoadingOverlay from "../../../common/LoadingOverlay";

interface OwnProps {}

interface DispatchProps {}

interface StateProps {
  isLoadingSurveys: boolean;
  researcherSurveys: SurveyInformation[];
  panel: Panel;
  currentUser: User;
  currentResearcher: Researcher;
  isAuthenticated: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    details: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(4),
    },
    detailNumber: {
      fontSize: "32px",
      marginRight: theme.spacing(7),
    },
    headline: {
      marginBottom: theme.spacing(3),
    },
    introductionCustom: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      paddingTop: theme.spacing(3),
    },
    furtherLink: {
      display: "flex",
      flexDirection: "column",
      "@media(max-width: 599px)": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    title: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(4),
    },
    body: {
      position: "relative",
    },
  });

type Props = OwnProps & DispatchProps & StateProps & WithTranslation & WithStyles<typeof styles>;
// TODO: enter links when pages are created, verify links.completed
const ButtonLinks = [
  { title: i18next.t(translations.pages.dashboard.researcher.links.yourOrder), link: AccountRoute },
  {
    title: i18next.t(translations.pages.dashboard.researcher.links.personalInfo),
    link: PersonalInfoRoute,
  },
  {
    title: i18next.t(translations.pages.dashboard.researcher.links.completed),
    link: "/market-research/dashboard/surveys", // TODO check if to adapt later
  },
  { title: i18next.t(translations.pages.dashboard.researcher.links.billData), link: BillingRoute },
  {
    title: i18next.t(translations.register.loginDetails.privacy.titleTermsConditions),
    link: TermsAndConditionsResearcherRoute,
  },
];

class Dashboard extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    if (!shallowEqual(prevProps.currentUser, this.props.currentUser)) {
      const { currentUser } = this.props;
      if (currentUser && currentUser.roles && Boolean(currentUser.roles.length)) {
        // this.props.fetchCurrentResearcherSurveys();
      }
    }
  }

  renderSurveyCards = (
    surveys: SurveyInformation[],
    status: SurveyStatus,
    isLoadingSurveys: boolean
  ) => {
    const { currentUser } = this.props;
    return isLoadingSurveys ? (
      <LoadingOverlay />
    ) : (
      surveys
        .filter((survey) => survey.status === status)
        .sort(surveyDateComparator.compareByCreatedAtDate)
        .map((survey) => (
          <SurveyCard
            key={survey.id}
            survey={survey}
            status={survey.status}
            withDetailsButton={true}
            additionalInformation={
              survey.information && currentUser.email !== survey.information.authorMail
                ? survey.information
                : undefined
            }
          />
        ))
    );
  };

  render() {
    const { t, classes, currentResearcher, isAuthenticated, isLoadingSurveys } = this.props;
    return (
      <Grid container={true}>
        <ContentWrapper>
          <Container>
            {isAuthenticated && (
              <>
                <Typography variant="body2" className={classes.title}>
                  {t(translations.pages.dashboard.researcher.yourSurveys)}
                </Typography>
                <div className={classes.body} id="surveyList">
                  {this.renderSurveyCards(
                    this.props.researcherSurveys,
                    SurveyStatus.NEW,
                    isLoadingSurveys
                  )}
                  {this.renderSurveyCards(
                    this.props.researcherSurveys,
                    SurveyStatus.PUBLISHED,
                    isLoadingSurveys
                  )}
                  {this.renderSurveyCards(
                    this.props.researcherSurveys,
                    SurveyStatus.PUBLISH_REQUESTED,
                    isLoadingSurveys
                  )}
                  {this.renderSurveyCards(
                    this.props.researcherSurveys,
                    SurveyStatus.REJECTED,
                    isLoadingSurveys
                  )}
                </div>
              </>
            )}
          </Container>
          <SidebarContainer>
            {/* companyData Component*/}
            {isAuthenticated && (
              <div className={classes.furtherLink}>
                <Typography variant="h2" color="primary" className={classes.headline}>
                  {currentResearcher.organizationDetails.name}
                </Typography>
                {ButtonLinks.map((link) => (
                  <ButtonLink key={link.title} title={link.title} link={link.link} />
                ))}
              </div>
            )}
            <SupportContainer />
          </SidebarContainer>
        </ContentWrapper>
      </Grid>
    );
  }
}

const mapStateToProps = ({ survey, user, session }: RootState) => ({
  researcherSurveys: user.researcherSurveys,
  isLoadingSurveys: user.isLoadingSurveys,
  panel: survey.panel,
  currentResearcher: user.currentResearcher,
  currentUser: user.current,
  isAuthenticated: session.authenticated,
});

const mapDispatchToProps = {};

export const DashboardComponent = Dashboard;

export default compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles)
)(Dashboard);
