import React, { PureComponent } from "react";
import { QuopinionTheme } from "../../../../constants/Theme";
import { WithStyles, withStyles, createStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { translations } from "../../../../constants/lang/translation";
import { withTranslation, WithTranslation } from "react-i18next";
import PanelItem from "../../../../common/Layout/components/PanelItem";
import PanelModal from "../../../../common/Layout/components/PanelModal";
import AttributeSelect from "../../../../common/Layout/components/AttributeSelect";
import { Chip, Grid, Typography, CircularProgress, Tooltip } from "@material-ui/core";
import Button from "../../../../common/Layout/components/Button";
import IndividualAttribute, {
  IndividualAttributeOption,
} from "../../../../entities/IndividualAttribute";
import { Or, And } from "../partial/OperatorButtons";
import { Cross } from "../../../../assets/icon";
import { getIndividualAttributes } from "../../../../modules/definePanel";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import IndividualAttributeSelect from "./IndividualAttributeSelect";
import { PositiveCheckBox, NegativeCheckBox } from "../../../../common/Layout/components/Checkbox";
import { connect } from "react-redux";
import { RootState } from "../../../../modules";
import { requestNewAttribute } from "../../../../modules/attribute";
import NewAttributesModal from "./NewAttributesModal";
import { NewRequestedAttribute } from "../../../../entities/RequestedAttribute";
import ID from "../../../../entities/ID";
import classNames from "classnames";

interface OwnProps {
  handleChange: (individualAttributes: IndividualAttribute[]) => void;
  selectedOptions: IndividualAttribute[];
}

interface StateProps {
  attributes: IndividualAttribute[] | undefined;
  isLoading: boolean;
  isSubmittingAttribute: boolean;
  surveyId: ID;
  isAuthenticated: boolean;
}

interface DispatchProps {
  getIndividualAttributes: typeof getIndividualAttributes;
  requestNewAttribute: (requestedAttribute: NewRequestedAttribute) => Promise<void>;
}

interface State {
  selectedCategory: string | null;
  selectedAttribute: IndividualAttribute | null;
  selectedAnswers: IndividualAttributeOption[];
  loading: boolean;
  error: any;
  showNewAttributesModal: boolean;
  newAttributeRequestSuccess: boolean;
  newAttributeRequestFail: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    select: {
      textTransform: "initial",
      border: "1px solid",
      borderColor: theme.palette.grey[100],
      backgroundColor: "#fff",
      padding: theme.spacing(4),
      borderRadius: 0,
      "&:active": {
        backgroundColor: "#fff",
      },
      "&:focus": {
        backgroundColor: "#fff",
      },
    },
    selectRoot: {
      width: "100%",
      position: "relative",
      "& svg": {
        width: 50,
        top: 1,
        right: 0,
        borderLeft: "1px solid",
        borderColor: theme.palette.grey[100],
        position: "absolute",
        height: "calc(100% - 2px)",
        padding: theme.spacing(3),
        pointerEvents: "none",
      },
    },
    noneSelected: {
      display: "flex",
      alignItems: "center",
    },
    attributePreviewContainer: {
      border: "1px solid",
      borderColor: theme.palette.grey[200],
      padding: theme.spacing(4),
      margin: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      width: "100%",
    },
    questionTitlePreview: {
      marginRight: theme.spacing(4),
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    chipLabel: {
      whiteSpace: "normal",
      wordBreak: "break-word",
    },
    chip: {
      width: "100%",
      height: "auto",
      maxHeight: 60,
    },
    attributeSaveButton: {
      display: "flex",
      justifyContent: "space-between",
    },
  });

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WithTranslation;

class IndividualAttributesDefinition extends PureComponent<Props, State> {
  state: State = {
    selectedCategory: null,
    selectedAttribute: null,
    selectedAnswers: [],
    loading: true,
    error: null,
    showNewAttributesModal: false,
    newAttributeRequestSuccess: false,
    newAttributeRequestFail: false,
  };

  constructor(props: Props) {
    super(props);
    this.props.getIndividualAttributes();
  }

  handleSelectedCategory = (values: string[]) => {
    this.setState({
      selectedCategory: values.length ? values[0] : null,
      selectedAttribute: null,
    });
  };

  handleSelectedAttribute = (values: string[]) => {
    const attribute = values.length
      ? this.props.attributes!.find((attribute) => attribute.question === values[0])
      : null;
    // see if the question has been selected and defined before (for our panel)
    const selectedQuestion =
      attribute &&
      this.props.selectedOptions.find((option) => option.question === attribute.question);
    this.setState({
      selectedAttribute: attribute || null,
      selectedAnswers: selectedQuestion ? selectedQuestion.answerOptions : [],
    });
  };

  handleSelectedAnswers = (values: IndividualAttributeOption[]) => {
    this.setState({
      selectedAnswers: values,
    });
  };

  handleDeleteAttribute = (attributeToDelete: string) => {
    const currentSelectedOptions = this.props.selectedOptions
      .map((savedCategoryObject) => {
        const filteredOptions = savedCategoryObject.answerOptions.filter(
          (answer) => answer.id !== attributeToDelete
        );
        const newCategoryObject = { ...savedCategoryObject, answerOptions: filteredOptions };

        return new IndividualAttribute(newCategoryObject);
      })
      .filter((option) => Boolean(option.answerOptions.length));
    this.props.handleChange(currentSelectedOptions);
  };

  renderAttributeAnswerOptions = (answer: IndividualAttributeOption, index: number) => {
    const { classes } = this.props;
    return (
      <div style={{ marginTop: 5, marginBottom: 5, display: "inline-flex" }} key={answer.id}>
        {index > 0 && (answer.val ? <Or /> : <And />)}
        <Chip
          key={answer.id}
          icon={
            answer.val ? <PositiveCheckBox checked={true} /> : <NegativeCheckBox checked={true} />
          }
          label={answer.label}
          onDelete={this.handleDeleteAttribute.bind(undefined, answer.id)}
          deleteIcon={<Cross width="16px" height="16px" fill={"#313131"} />}
          classes={{
            label: classes.chipLabel,
          }}
          className={classes.chip}
        />
      </div>
    );
  };

  renderAttributeLabels = () => {
    const { t, classes } = this.props;
    return (
      <>
        {this.props.selectedOptions.map((attribute, index) => {
          return (
            <div key={attribute.id}>
              {index > 0 && <And />}
              <div className={classes.attributePreviewContainer} key={attribute.id}>
                <Typography variant="body1" className={classes.questionTitlePreview}>
                  {attribute.question}
                </Typography>
                {attribute.answerOptions
                  .sort((a, b) => {
                    if (a.val && !b.val) {
                      return -1;
                    } else if (!a.val && b.val) {
                      return 1;
                    } else if (a.label > b.label) {
                      return 1;
                    }
                    return -1;
                  })
                  .map(this.renderAttributeAnswerOptions)}
              </div>
            </div>
          );
        })}
        {!this.props.selectedOptions.length && (
          <Typography variant="body1" className={classes.noneSelected}>
            {t(translations.summary.panel.individual.noneSelected)}
          </Typography>
        )}
      </>
    );
  };

  renderCategorySelector = () => {
    const { t } = this.props;

    const allCategories: string[] = this.props.attributes!.reduce(
      (categories: string[], attribute: IndividualAttribute) => {
        return [...categories, ...attribute.categories];
      },
      []
    );

    const uniqueCategories = allCategories.reduce((categories: string[], category: string) => {
      if (categories.includes(category)) {
        return categories;
      } else {
        return [...categories, category];
      }
    }, []);
    const categories = uniqueCategories.map((catItem) => {
      // read out dynamic values from a translations object
      const translationKey: string = (translations.panel.individualAttributes as any)[
        catItem
      ] as string;
      return {
        label: t(translationKey) || catItem,
        value: catItem,
      };
    });
    const sortedCategories = categories.sort((a, b) => (a.label > b.label ? 1 : -1));

    return (
      <>
        <PanelItem
          withInfoIcon={true}
          showLine={false}
          smallSpacing={true}
          title={t(translations.panel.attributeAnswers.attributeChoice)}
          singleRow={true}
        >
          {this.renderAttributeLabels()}
        </PanelItem>
        <PanelItem
          withInfoIcon={false}
          showLine={false}
          title={t(translations.summary.panel.freeAttributes.category)}
          smallSpacing={true}
          singleRow={true}
        >
          <AttributeSelect
            name={"categorySelection"}
            multiple={false}
            handleSelection={this.handleSelectedCategory}
            selectOptions={sortedCategories}
            selectedValues={this.state.selectedCategory ? [this.state.selectedCategory] : []}
          />
        </PanelItem>
      </>
    );
  };

  renderAttributeSelector = () => {
    const { t } = this.props;
    const { selectedCategory } = this.state;

    const attributeOptions = this.props.attributes!.filter((indivAttributeObj) =>
      indivAttributeObj.categories.includes(selectedCategory!)
    );
    const labeledAttributeOptions = attributeOptions.map((attribute) => {
      return { label: attribute.question, value: attribute.question };
    });
    return (
      <div
        style={{
          opacity: !!selectedCategory ? 1 : 0.3,
          pointerEvents: !!selectedCategory ? "all" : "none",
          maxWidth: "682px",
        }}
      >
        <PanelItem
          withInfoIcon={true}
          showLine={false}
          title={t(translations.summary.panel.freeAttributes.feature)}
          modalChildren={
            <PanelModal
              title={t(translations.questions.pl)}
              description={t(translations.panel.generalInfo.multiQuestionLevel)}
            />
          }
          smallSpacing={true}
          singleRow={true}
        >
          {!this.props.attributes && <CircularProgress />}
          {this.props.attributes && (
            <>
              <AttributeSelect
                name={"propertySelection"}
                multiple={false}
                handleSelection={this.handleSelectedAttribute}
                selectOptions={labeledAttributeOptions}
                selectedValues={
                  this.state.selectedAttribute ? [this.state.selectedAttribute.question] : []
                }
              />
              {this.state.selectedAttribute && (
                <div style={{ margin: "16px 4px 0" }}>
                  <Typography variant="body1">Frage:</Typography>
                  <Typography variant="body2">
                    {this.state.selectedAttribute.questionText}
                  </Typography>
                </div>
              )}
            </>
          )}
        </PanelItem>
      </div>
    );
  };

  renderAnswerOptionsSelector = () => {
    const { t } = this.props;
    const { selectedAttribute, selectedCategory } = this.state;
    let possibleAttributes;
    let selectedQuestion;
    if (!selectedAttribute) {
      possibleAttributes = [];
      selectedQuestion = undefined;
    } else {
      possibleAttributes = this.props.attributes!.filter((attributesObj) =>
        attributesObj.categories.includes(selectedCategory!)
      )!;
      selectedQuestion = possibleAttributes.find((attribute) => {
        return attribute.id === this.state.selectedAttribute!.id;
      })!;
    }

    return (
      <div
        style={{
          opacity: !!selectedAttribute ? 1 : 0.3,
          pointerEvents: !!selectedAttribute ? "all" : "none",
          maxWidth: "682px",
        }}
      >
        <PanelItem
          withInfoIcon={true}
          orIconLabel={true}
          showLine={false}
          title={t(translations.summary.panel.freeAttributes.value)}
          modalChildren={
            <PanelModal
              title={t(translations.panel.attributeAnswers.answers)}
              description={t(translations.panel.generalInfo.multiSelect)}
            />
          }
          smallSpacing={true}
          singleRow={true}
        >
          {selectedAttribute && (
            <IndividualAttributeSelect
              name={"answerOptionSelection"}
              attributes={selectedQuestion ? selectedQuestion.answerOptions : []}
              selectedAttributes={this.state.selectedAnswers}
              handleAttributeSelection={this.handleSelectedAnswers}
              // this is necessary due to the props interface being extended from Attibute
              selectOptions={[]}
              selectedValues={[]}
              handleSelection={() => {
                return;
              }}
            />
          )}
        </PanelItem>
      </div>
    );
  };

  handleSaveAttribute = () => {
    const newAttribute = new IndividualAttribute({
      ...this.state.selectedAttribute,
      answerOptions: this.state.selectedAnswers,
    });

    let newSelectedOptions;

    if (
      this.props.selectedOptions.find((option) => option.id === this.state.selectedAttribute!.id)
    ) {
      if (!this.state.selectedAnswers.length) {
        newSelectedOptions = this.props.selectedOptions.filter(
          (selectedOption) => selectedOption.id !== this.state.selectedAttribute!.id
        );
      } else {
        newSelectedOptions = this.props.selectedOptions.map((selectedOption) =>
          selectedOption.id === this.state.selectedAttribute!.id ? newAttribute : selectedOption
        );
      }
    } else {
      if (!this.state.selectedAnswers.length) {
        return;
      }
      newSelectedOptions = [...this.props.selectedOptions, newAttribute];
    }

    this.props.handleChange(newSelectedOptions);

    this.setState({
      selectedCategory: null,
      selectedAttribute: null,
      selectedAnswers: [],
    });
  };

  showNewAttributesModal = () => {
    this.setState({
      showNewAttributesModal: true,
    });
  };

  hideNewAttributesModal = () => {
    this.setState({
      showNewAttributesModal: false,
    });
  };

  requestAttribute = async (requestedAttribute: NewRequestedAttribute) => {
    try {
      await this.props.requestNewAttribute(requestedAttribute);
      this.setState({ newAttributeRequestSuccess: true, newAttributeRequestFail: false });
    } catch (error) {
      this.setState({ newAttributeRequestFail: true });
    }
  };

  requestAnotherAttribute = () => {
    this.setState({ newAttributeRequestSuccess: false, newAttributeRequestFail: false });
  };

  renderSaveAttributeButton() {
    const { t, surveyId, isSubmittingAttribute, isAuthenticated, classes } = this.props;
    const {
      showNewAttributesModal,
      newAttributeRequestFail,
      newAttributeRequestSuccess,
    } = this.state;

    return (
      <Grid
        container={true}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classNames({
          [classes.attributeSaveButton]: !isAuthenticated,
        })}
      >
        <NewAttributesModal
          open={showNewAttributesModal}
          onClose={this.hideNewAttributesModal}
          surveyId={surveyId}
          isSubmitting={isSubmittingAttribute}
          requestAttribute={this.requestAttribute}
          requestSuccessful={newAttributeRequestSuccess}
          requestError={newAttributeRequestFail}
          isAuthenticated={isAuthenticated}
          requestAnotherAttribute={this.requestAnotherAttribute}
        />
        <Tooltip
          title={<>{t(translations.panel.attributeAnswers.furtherAttributesTooltip)}</>}
          aria-label={"preview"}
        >
          <div>
            <Button
              onClick={this.showNewAttributesModal}
              size="medium"
              color="secondary"
              outlined={true}
            >
              {t(translations.panel.attributeAnswers.furtherAttributes)}
            </Button>
          </div>
        </Tooltip>
        <Button
          onClick={this.handleSaveAttribute}
          contained={true}
          color="primary"
          size="medium"
          disabled={!this.state.selectedAnswers.length}
        >
          {t(translations.panel.attributeAnswers.saveAttribute)}
        </Button>
      </Grid>
    );
  }
  render() {
    return (
      <>
        {this.props.isLoading && <LoadingOverlay />}
        {this.renderCategorySelector()}
        {this.renderAttributeSelector()}
        {this.renderAnswerOptionsSelector()}
        {this.renderSaveAttributeButton()}
      </>
    );
  }
}

const mapStateToProps = ({ definePanel, survey, attribute, session }: RootState) => ({
  attributes: definePanel.attributes,
  isLoading: definePanel.isLoading,
  surveyId: survey.id,
  isSubmittingAttribute: attribute.isLoading,
  isAuthenticated: Boolean(session.authenticated),
});

export default compose<Props, OwnProps>(
  connect(mapStateToProps, { getIndividualAttributes, requestNewAttribute }),
  withTranslation(),
  withStyles(styles)
)(IndividualAttributesDefinition);
