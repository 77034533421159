import { createStore, applyMiddleware, compose } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import rootReducer from "./modules";
import { jwt } from "./modules/jwt";
import { loadState } from "./util/localStorage";
import { SurveyAction, State } from "./modules/survey";

const persistedState = loadState();
const enhancers = [];
const middleware = [jwt, thunk as ThunkMiddleware<State, SurveyAction>];

if (process.env.NODE_ENV === "development") {
  // @ts-ignore
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, persistedState, composedEnhancers);

export default store;
