import * as React from "react";
import Container from "../../common/Layout/Container";
import Header from "../../common/Layout/Header";
import {createStyles, IconButton, WithStyles, withStyles} from "@material-ui/core";
import OverviewItem from "./OverviewItem";
import {Event, HelpOutline} from "@material-ui/icons";
import {withTranslation, WithTranslation} from "react-i18next";
import {translations} from "../../constants/lang/translation";
import {compose} from "recompose";
import {QuopinionTheme} from "../../constants/Theme";
import {connect} from "react-redux";
import {RootState} from "../../modules";
import {
  declineParticipation,
  getParticipations,
  intendParticipation,
  setCurrentEarnings,
  startParticipation,
} from "../../modules/participation";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {route as dashboardRoute} from "../participant/Dashboard";
import Button from "../../common/Layout/components/Button";
import AnswerTime from "./AnswerTime";
import {Cross} from "../../assets/icon";
import Survey, {formatDuration} from "../../entities/Survey";
import LoadingOverlay from "../../common/LoadingOverlay";
import moment from "moment";
import {setParticipationCurrentlyNotAllowed} from "../../modules/uiState";
import SurveyPreCheck from "../SurveyPreCheck";
import {ParticipationSurveyState} from "../../services/Survey";

interface OwnProps {
  history?: any;
  match?: any;
}

interface StateProps {
  isAuthenticated: boolean;
  survey?: Survey;
  isLoading: boolean;
  selectedOpenSurvey?: Survey;
  openSurveys: Survey[];
}

interface DispatchProps {
  getParticipations: typeof getParticipations;
  setCurrentEarnings: typeof setCurrentEarnings;
  intendParticipation: typeof intendParticipation;
  declineParticipation: typeof declineParticipation;
  setParticipationCurrentlyNotAllowed: typeof setParticipationCurrentlyNotAllowed;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    itemWrapper: {
      marginTop: theme.spacing(6),
    },
  });

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{ id: string }>;

export interface OverviewPageState {}

export const route = "/participant/surveyOverview";

class SurveyOverviewPage extends React.Component<Props, OverviewPageState> {
  constructor(props: Props) {
    super(props);

    this.state = {};
    this.props.getParticipations();
  }

  async componentDidMount() {
    const { intendParticipation, history, match } = this.props;

    if (match.params.id) {
      intendParticipation();
    } else {
      history.push("/error/startParticipation:noSurveyId");
    }
  }

  takePart = async (answers: any, state: ParticipationSurveyState) => {
    const {
      history,
      match,
      selectedOpenSurvey,
    } = this.props;

    if (selectedOpenSurvey) {
      setCurrentEarnings(selectedOpenSurvey.price);
    }
    if (match.params.id) {
      try {
        const answer = startParticipation(match.params.id, answers);
        if (answer !== undefined) {
          history.push(`/participant/questionnaire/${match.params.id}`);
        } else {
          history.push(`/participant/dashboard`);
        }
      } catch (err) {
        console.log(err);
        history.push(`/participant/dashboard`);
      }
    } else {
      history.push("/error/startParticipation:noSurveyId");
    }
  };

  declineSurvey = async () => {
    const { declineParticipation, match, history } = this.props;

    if (match.params.id) {
      declineParticipation(match.params.id);
      this.props.history.push(dashboardRoute);
    } else {
      history.push("/error/startParticipation:noSurveyId");
    }
  };

  redirectOnCancel = () => {
    this.props.history.push(dashboardRoute);
  };

  doubleCheckForSurvey = async () => {
    const {selectedOpenSurvey, match, history, openSurveys} = this.props;
    getParticipations();
    if (
      !selectedOpenSurvey && !openSurveys.find((survey) => survey.id === match.params.id)
    ) {
      this.props.setParticipationCurrentlyNotAllowed();
      history.push(dashboardRoute);
    }
  };

  render() {
    const {t, classes, isLoading, selectedOpenSurvey, match, openSurveys} = this.props;

    if (!selectedOpenSurvey && selectedOpenSurvey !== undefined) {
      return <LoadingOverlay/>;
    }
    if (!Boolean(openSurveys.find((survey) => survey.id === match.params.id))) {
      setTimeout(async () => {
        await this.doubleCheckForSurvey();
      }, 2000);
      return <LoadingOverlay/>;
    }

    const date = () => {
      return selectedOpenSurvey?.endDate !== undefined ? moment(selectedOpenSurvey.endDate).format("DD.MM.YYYY") : "";
    };

    const time = () => {
      return selectedOpenSurvey?.endDate !== undefined ? moment(selectedOpenSurvey.endDate).format("HH:mm") : "";
    };

    const countLabel = t((selectedOpenSurvey?.numberOfQuestions || 1) > 1 ?
      translations.questions.question_plural : translations.questions.question);

    return (
      <>
        {isLoading && <LoadingOverlay/>}
        <Header
          infoTop={t(translations.headerSurvey.topInfo.clientSurvey)}
          headline={selectedOpenSurvey ? selectedOpenSurvey.title : ""}
          actionNode={
            <IconButton onClick={this.redirectOnCancel}>
              <Cross/>
            </IconButton>
          }
        />
        <Container centered={true}>
          <div className={classes.itemWrapper}>
            <OverviewItem
              icon={<Event />}
              title={t(translations.infoSurveyItem.deadline.title)}
              value={
                selectedOpenSurvey
                  ? `${date()} ${t(translations.headerSurvey.answerTime.at)} ${time()} ${t(
                      translations.headerSurvey.answerTime.oClock
                    )}`
                  : ""
              }
            />

            {/* <OverviewItem
              icon={<PermIdentity />}
              title={t(translations.infoSurveyItem.participants.title)}
              value={
                survey
                  ? `${survey.participantsCurrent} / ${survey.participantPoolSize}`
                  : ""
              }
            /> */}

            <OverviewItem
              icon={<HelpOutline />}
              title={t(translations.infoSurveyItem.sizeLength.title)}
              value={
                selectedOpenSurvey
                  ? `${selectedOpenSurvey.numberOfQuestions} ${countLabel} (${formatDuration(selectedOpenSurvey.duration, t, true)})`
                  : ""
              }
            />

            <OverviewItem
              title={t(translations.infoSurveyItem.earnings.title)}
              value={selectedOpenSurvey ? selectedOpenSurvey.price : ""}
            />

            {/* <FurtherInfoItem
              infoTitle={t(translations.additionalInfoItem.title)}
              infoText={t(translations.additionalInfoItem.contentPlacehoder)}
            /> */}
            <AnswerTime
              duration={selectedOpenSurvey?.durationSecondsToComplete || 0}
            />

            <SurveyPreCheck id={match.params.id} takePart={this.takePart} declineSurvey={this.declineSurvey}/>

            <Button color="secondary" size="medium" fullWidth={true} onClick={this.declineSurvey}>
              {t(translations.action.deny)}
            </Button>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ session, participation }: RootState, ownProps: OwnProps) => {
  const { id } = ownProps.match.params;

  return {
    isAuthenticated: Boolean(session.authenticated),
    survey: participation.currentSurvey,
    isLoading: participation.isLoading,
    openSurveys: participation.openSurveys,
    selectedOpenSurvey: participation.openSurveys.find((survey) => survey.id === id),
  };
};

export default compose<Props, OwnProps>(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, {
    startParticipation,
    intendParticipation,
    declineParticipation,
    getParticipations,
    setCurrentEarnings,
    setParticipationCurrentlyNotAllowed,
  })
)(SurveyOverviewPage);
