import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  WithStyles
} from "@material-ui/core";
import {styles} from "../../common/Landingpage/HeaderChildParticipant";
import {translations} from "../../constants/lang/translation";
import Button from "../../common/Layout/components/Button";
import {compose} from "recompose";
import Card from "../../common/Layout/components/Card";
import Services from "../../services/Services";
import ID from "../../entities/ID";
import {ParticipationSurveyState} from "../../services/Survey";

interface OwnProps {
  /** was soll als Zusage geschehen? */
  takePart: (answers: any, state: ParticipationSurveyState) => void;
  /** was soll als Absage geschehen? */
  declineSurvey: () => void;
  id: ID;
}

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const MALE = "47155a70-38d1-4708-ae65-a7ad73e5cfa8";
const FEMALE = "9eeb6078-a208-4416-a207-557d301c5df0";
const DIVERS = "6a410bc2-8029-4b48-b8f8-ed904c83d6a6";

const SurveyPreCheck: React.FC<Props> = (props) => {
  const {t} = props;

  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [gender, setGender] = React.useState("");
  const [age, setAge] = React.useState(0);

  //TODO (RN) hier ggf. die akt. Survey auswerten, ob und was gefragt werden soll

  const handleClickOpen = () => {
    setOpen(true);
    setChecked(!!(age && gender));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDecline = () => {
    setOpen(false);
    props.declineSurvey();
  };

  //hack, da sonst die Radiobuttons links auf dem Rand liegen
  const styleGender = {
    paddingLeft: '2em',
  };

  const handleSubmit = () => {
    const answers = {
      "updatedAnswers": [
        {
          questionId: "00000000-0000-0000-0001-000000000001",
          type: "choice",
          selectedOptions: [gender]
        },
        {
          questionId: "00000000-0000-0000-0001-000000000010",
          type: "number",
          value: age
        }
      ]
    };
    console.log("handle", props.id, answers);
    Services.survey.acceptSurveyParticipation(props.id, answers).then((state) => {
      console.log("takePart", props.id, answers, state);
      props.takePart(answers, state);
    });

  };

  const checkGender = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setGender(value);
    setChecked(!!(age && value));
  };

  const checkAge = (event: any) => {
    const value = event.target?.value ? Number.parseInt(event.target?.value, 10) : 0;
    if (value > 0) {
      setAge(value);
      setChecked(!!(value && gender));
    }
  };

  return (
    <div>
      <Button contained={true}
              color="secondary"
              size="medium"
              fullWidth={true}
              onClick={handleClickOpen}>
        {t(translations.action.takepart)}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t(translations.action.takepart)}</DialogTitle>
        <DialogContent>
          <DialogContentText color="primary">
            Bitte bestätige die folgenden Angaben:
          </DialogContentText>
          <div>
            <Card>
              Alter
              <TextField
                autoFocus={true}
                id="age"
                label="age"
                type="number"
                required={true}
                size="small"
                value={age}
                onChange={checkAge}
              />
            </Card>
            <Card>
              {t(translations.summary.panel.gender.title)}
              <div>
                <RadioGroup
                  aria-labelledby="gender-label"
                  name="radio-buttons-gender"
                  row={true}
                  onChange={checkGender}
                  style={styleGender}
                >
                  <FormControlLabel value={FEMALE} checked={gender === FEMALE} control={<Radio/>}
                                    label={t(translations.panel.gender.female)}/>
                  <FormControlLabel value={MALE} checked={gender === MALE} control={<Radio/>}
                                    label={t(translations.panel.gender.male)}/>
                  <FormControlLabel value={DIVERS} checked={gender === DIVERS} control={<Radio/>}
                                    label={t(translations.panel.gender.divers)}/>
                </RadioGroup>
              </div>
            </Card>
          </div>
        </DialogContent>
        <DialogActions>
          <Button fullWidth={true} contained={true} size="small" color="secondary"
                  onClick={handleDecline}>{t(translations.action.deny)}</Button>
          <Button fullWidth={true} contained={true} size="small" color="primary" disabled={!checked}
                  onClick={handleSubmit}>
            {t(translations.action.takepart)}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default compose<Props, OwnProps>(withTranslation())(SurveyPreCheck);
