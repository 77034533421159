export default class OpinionValue {
  priceS: number;
  priceM: number;
  priceL: number;

  constructor(obj: any = {}) {
    this.priceS = obj.priceS;
    this.priceM = obj.priceM;
    this.priceL = obj.priceL;
  }
}

export class TransientOpinionValue {
  priceS: number;
  priceM: number;
  priceL: number;

  constructor(obj: any = {}) {
    this.priceS = obj.priceS;
    this.priceM = obj.priceM;
    this.priceL = obj.priceL;
  }
}
