import ID from "../../../entities/ID";
import { ChoiceAnswer, MatrixAnswer } from "../../../entities/SurveyAnswers";
import { AnsweredAttribute } from "../../../services/Accounts";

export const isChecked = (
  currentAnswer: ID,
  answer?: ChoiceAnswer | MatrixAnswer | AnsweredAttribute,
  subQuestionId?: ID
): boolean => {
  if (!answer) {
    return false;
  } else if (answer instanceof AnsweredAttribute) {
    if (answer.answer.type === "choice") {
      return Boolean(
        answer.answer.selectedOptions.find(
          (selectedOption: string) => selectedOption === currentAnswer
        )
      );
    } else {
      return false;
    }
  } else if ((answer as ChoiceAnswer).type === "choice") {
    return Boolean(
      (answer as ChoiceAnswer).selectedOptions.find(
        (selectedOption: string) => selectedOption === currentAnswer
      )
    );
  } else if ((answer as MatrixAnswer).type === "matrix" && subQuestionId !== undefined) {
    const subQuestionIncluded =
      (answer as MatrixAnswer).subAnswers.length > 0 &&
      (answer as MatrixAnswer).subAnswers.find(
        (subAnswer) => subAnswer.subQuestionId === subQuestionId
      );
    return Boolean(
      subQuestionIncluded &&
        subQuestionIncluded.selectedAnswers.find(
          (selectedAnswer) => selectedAnswer === currentAnswer
        )
    );
  } else {
    return false;
  }
};
