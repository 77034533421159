import i18next from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {},
    resources: {
      de: { translation: require("./lang/de.json") },
      /* QD-657: Disabled use of english language file

      To use english translation file en.json when 
      auto-detecting english browser client, simply
      uncomment the following line:
      */

      // en: { translation: require("./lang/en.json") },
    },
    lng: navigator.language,
    fallbackLng: "de",
    debug: true,
  });

export default i18next;
