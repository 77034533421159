import React, { useState, useEffect } from "react";
import PanelItem from "../../../../common/Layout/components/PanelItem";
import PanelModal from "../../../../common/Layout/components/PanelModal";
import AttributeSelect, { OptionsType } from "../../../../common/Layout/components/AttributeSelect";
import { translations } from "../../../../constants/lang/translation";
import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";
import { withStyles, WithStyles, createStyles } from "@material-ui/styles";
import { QuopinionTheme } from "../../../../constants/Theme";
import { Chip, Typography } from "@material-ui/core";
import { SnackbarComponent } from "../../../../common/Layout/components/Snackbar";
import Region, { regionTypeValues, RegionType } from "../../../../entities/Region";
import Button from "../../../../common/Layout/components/Button";
import * as i18next from "i18next";
import { Or } from "../partial/OperatorButtons";
import { getRegions } from "../../../../modules/definePanel";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import { Cross } from "../../../../assets/icon";
import Panel from "../../../../entities/Panel";
import { connect } from "react-redux";

import { actions as surveyActions, setPanelRegion } from "../../../../modules/survey";
import { RootState } from "../../../../modules";

const styles = (theme: QuopinionTheme) => createStyles({});

interface OwnProps {
  handleChange: (regions: Region[]) => void;
  selectedOptions: any[];
  sectionAttributes: any[];
}

export interface StateProps {
  panel: Panel;
}

export interface DispatchProps {
  setStateAttributeId: typeof surveyActions.setStateAttributeId;
  setCountyAttributeId: typeof surveyActions.setCountyAttributeId;
  setLeitregionAttributeId: typeof surveyActions.setLeitregionAttributeId;
  setPopulationDensityAttributeId: typeof surveyActions.setPopulationDensityAttributeId;
  setTouristAreaAttributeId: typeof surveyActions.setTouristAreaAttributeId;
  setPanelRegion: typeof setPanelRegion;
}

type Props = OwnProps & DispatchProps & StateProps & WithTranslation & WithStyles<typeof styles>;

const RegionalPanelDefinition: React.FC<Props> = (props: Props) => {
  const {
    t,
    handleChange,
    panel,
    selectedOptions,
    sectionAttributes,
    setStateAttributeId,
    setCountyAttributeId,
    setLeitregionAttributeId,
    setPopulationDensityAttributeId,
    setTouristAreaAttributeId,
    setPanelRegion,
  } = props;

  const [selectedRegionType, setSelectedRegionType] = useState<RegionType | null>(null);
  const [currentlySelectedRegion, setCurrentlySelectedRegion] = useState<Region | null>(null);
  const [selectedRegions, setSelectedRegions] = useState<Region[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableRegions, setAvailableRegions] = React.useState<Region[]>([]);

  useEffect(() => {
    getRegions()
      .then((result) => {
        setAvailableRegions(result);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
      });
  }, []);

  useEffect(() => {
    // Will be run on first props update
    if (selectedOptions.length && !(selectedOptions[0] instanceof Region)) {
      let selected = selectedOptions.map((item: string) =>
        availableRegions.find((region) => region.id === parseInt(item, 10))
      );

      if (selected.length && selected[0] instanceof Region) {
        setPanelRegion(selected as Region[]);
        setSelectedRegionType(selected[0]!.typeLabel);
        setSelectedRegions(selected as Region[]);
      }
    }

    // Will be run on every subsequent props update
    if (selectedOptions.length && selectedOptions[0] instanceof Region) {
      setSelectedRegionType(selectedOptions[0].typeLabel);
      setSelectedRegions(selectedOptions);
    }
  }, [selectedOptions, availableRegions, setPanelRegion]);

  const generateRegionalCharacteristics: (t: i18next.TFunction) => OptionsType[] = (t) => {
    return regionTypeValues.map((regionType) => ({
      value: regionType,
      label: t(translations.summary.panel.region.characteristic.title[regionType]),
      key: regionType,
    }));
  };

  const handleRegionTypeSelection = (regionTypeArray: string[]) => {
    const selectedRegionType = regionTypeArray.length ? (regionTypeArray[0] as RegionType) : null;
    setSelectedRegionType(selectedRegionType);
    setCurrentlySelectedRegion(null);
  };

  const handleRegionTypeCharacteristicChange = (val: string) => {
    setCurrentlySelectedRegion(availableRegions.find((region) => region.id === Number(val))!);
  };

  const handleSaveRegion = () => {
    if (currentlySelectedRegion) {
      handleChange([...selectedOptions, currentlySelectedRegion]);
      setSelectedRegions([...selectedRegions, currentlySelectedRegion]);
      setCurrentlySelectedRegion(null);
    }
  };

  const handleDeleteRegion = (regionToDelete: Region) => {
    let selected: Region[] = selectedRegions.filter((region) => region.id !== regionToDelete.id);
    handleChange(selected);
    setSelectedRegions(selected);
  };

  const renderSelectedOptions = () => {
    return (
      <PanelItem
        withInfoIcon={false}
        showLine={false}
        title={t(translations.summary.panel.region.title)}
        singleRow={true}
        smallSpacing={true}
      >
        {selectedOptions.map((region, index) => {
          // check if the value is translatable - otherwise use the value as label
          const label =
            (translations.panel as any)[region.type] &&
            (translations.panel as any)[region.typeLabel][region.id]
              ? t((translations.panel as any)[region.typeLabel][region.name])
              : region.name;
          return (
            <>
              <div
                style={{ marginTop: 5, marginBottom: 5, display: "inline-flex" }}
                key={region.name}
              >
                {index > 0 && <Or />}
                <Chip
                  key={region.id}
                  label={`${label}`}
                  onDelete={() => handleDeleteRegion(region)}
                  deleteIcon={<Cross width="16px" height="16px" fill={"#313131"} />}
                />
              </div>
            </>
          );
        })}
        {!selectedOptions.length && (
          <Typography variant="body1">
            {t(translations.summary.panel.region.noneSelected)}
          </Typography>
        )}
      </PanelItem>
    );
  };

  /* region type*/
  const renderRegionTypeSelector = () => {
    return (
      <PanelItem
        withInfoIcon={false}
        //showLine={!this.state.selectedRegionType}
        title={t(translations.summary.panel.region.type.title)}
        singleRow={true}
        smallSpacing={true}
      >
        <AttributeSelect
          disabled={!!selectedOptions.length}
          multiple={false}
          handleSelection={handleRegionTypeSelection}
          selectOptions={generateRegionalCharacteristics(t)}
          selectedValues={selectedRegionType ? [selectedRegionType] : []}
        />
      </PanelItem>
    );
  };

  const orderItems = (a: any, b: any) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const renderRegionCharacteristicInput = () => {
    switch (selectedRegionType) {
      case "Bundesland":
      case "Reisegebiet":
      case "Leitregion":
      case "Bevölkerungsdichte":
      case "Kreis":
        return (
          <AttributeSelect
            multiple={false}
            handleSelection={(values: string[]) =>
              handleRegionTypeCharacteristicChange(values[0] || "")
            }
            selectOptions={availableRegions
              .filter(
                (region) =>
                  !selectedRegions.some((selectedRegion) => selectedRegion.id === region.id) &&
                  region.typeLabel === selectedRegionType
              )
              .sort(orderItems)
              .map((region) => ({
                value: String(region.id),
                label: region.name,
                key: region.id,
              }))}
            selectedValues={currentlySelectedRegion ? [String(currentlySelectedRegion.id)] : []}
          />
        );
      default:
        return <Typography>TODO</Typography>;
    }
  };
  /*third/last level of region type*/
  const renderRegionCharacteristicForm = () => {
    const title = t(
      translations.summary.panel.region.characteristic.title[selectedRegionType || "none"]
    );
    return (
      <>
        <PanelItem
          withInfoIcon={selectedOptions.length > 1}
          orIconLabel={true}
          title={title}
          modalChildren={
            <PanelModal title={title} description={t(translations.panel.generalInfo.multiSelect)} />
          }
          singleRow={true}
          smallSpacing={true}
        >
          {/*//last selection level*/}
          {renderRegionCharacteristicInput()}
        </PanelItem>
        <PanelItem withInfoIcon={true} showLine={true} singleRow={true} smallSpacing={true}>
          {renderSaveRegionButton()}
        </PanelItem>
      </>
    );
  };

  const renderSaveRegionButton = () => {
    return (
      <div style={{ marginBottom: 20 }}>
        <Button
          onClick={handleSaveRegion}
          contained={true}
          color="primary"
          size="medium"
          disabled={!currentlySelectedRegion}
        >
          {t(translations.summary.panel.region.save)}
        </Button>
      </div>
    );
  };

  const setAttributeIds = () => {
    // this is an odd workaround, but necessary without a huge refactoring so we'll go this way for the MVP
    sectionAttributes.forEach((sectionAttribute) => {
      switch (sectionAttribute.name) {
        case "Bundesland":
          return !panel.state.attributeId && setStateAttributeId(sectionAttribute.id);
        case "Kreis":
          return !panel.county.attributeId && setCountyAttributeId(sectionAttribute.id);
        case "Leitregion":
          return !panel.leitregion.attributeId && setLeitregionAttributeId(sectionAttribute.id);
        case "Bevölkerungsdichte":
          return (
            !panel.populationDensity.attributeId &&
            setPopulationDensityAttributeId(sectionAttribute.id)
          );
        case "Reisegebiet":
          return !panel.touristArea.attributeId && setTouristAreaAttributeId(sectionAttribute.id);
      }
      return false;
    });
  };

  setAttributeIds();

  return (
    <>
      {loading && <LoadingOverlay />}
      {error && (
        <SnackbarComponent
          title={"Leider ist ein Fehler aufgetreten!"}
          message={"Probieren Sie es später noch einmal."}
          type="error"
          onClose={() => {
            setError(null);
          }}
        />
      )}
      {renderSelectedOptions()}
      {renderRegionTypeSelector()}
      {!!selectedRegionType && renderRegionCharacteristicForm()}
    </>
  );
};

const mapDispatchToProps = {
  setStateAttributeId: surveyActions.setStateAttributeId,
  setCountyAttributeId: surveyActions.setCountyAttributeId,
  setLeitregionAttributeId: surveyActions.setLeitregionAttributeId,
  setPopulationDensityAttributeId: surveyActions.setPopulationDensityAttributeId,
  setTouristAreaAttributeId: surveyActions.setTouristAreaAttributeId,
  setPanelRegion,
};

const mapStateToProps = ({ survey }: RootState) => ({
  panel: survey.panel,
});

export default compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles)
)(RegionalPanelDefinition);
