import React, { PureComponent } from "react";
import { compose } from "recompose";
import { withStyles, WithStyles, createStyles, Typography } from "@material-ui/core";
import { QuopinionTheme } from "../../../../constants/Theme";
import Badge from "../../../../common/Layout/components/Badge";
import { OrderInfo } from "./OrderInfo";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../../constants/lang/translation";
import { SurveyInformation } from "../../../../entities/Survey";
import moment from "moment";
import Button from "../../../../common/Layout/components/Button";
import { formatDuration } from "../../../../entities/Survey";

interface OwnProps {
  survey: SurveyInformation;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      alignItems: "center",
      backgroundColor: theme.palette.grey[50],
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      display: "flex",
      justifyContent: "flex-end",
      padding: `${theme.spacing(2.5)}px ${theme.spacing(6)}px ${theme.spacing(
        1.5
      )}px ${theme.spacing(6)}px`,
      "&:nth-of-type(odd)": {
        backgroundColor: "#fff",
      },
    },
    badge: {
      color: "#fff",
      marginRight: theme.spacing(5),
    },
    infoWrapper: {
      display: "flex",
      "@media(max-width:1024px)": {
        flexDirection: "column",
        borderRight: `1px solid ${theme.palette.grey[100]}`,
      },
      flexBasis: "38%",
      flexShrink: 0,
    },
    startWrapper: {
      display: "flex",
      flexBasis: "42%",
      "@media(max-width:1024px)": {
        flexDirection: "column",
      },
      justifyContent: "flex-end",
    },
    badgeWrapper: {
      display: "flex",
      alignItems: "center",
      flexBasis: "20%",
      flexShrink: 0,
      width: "max-content",
      "@media(max-width:1024px)": {
        marginBottom: theme.spacing(2.5),
      },
    },
    titleWrapper: {
      display: "flex",
      alignItems: "center",
      flexBasis: "80%",
      flexShrink: 0,
      width: "max-content",
    },
    actionWrapper: {
      display: "flex",
      alignItems: "center",
      flexBasis: "20%",
      justifyContent: "center",
      flexShrink: 0,
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class OrderSummaryItem extends PureComponent<Props> {
  render() {
    const { classes, t, survey } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.startWrapper}>
          <div className={classes.badgeWrapper}>
            <Badge color="secondary" className={classes.badge}>
              {moment(survey.endDate).format("DD.MM.YYYY")}
            </Badge>
          </div>
          <div className={classes.titleWrapper}>
            <Typography variant="subtitle2">{survey.title}</Typography>
          </div>
        </div>
        <div className={classes.infoWrapper}>
          <OrderInfo
            title={t(translations.pages.dashboard.researcher.account.time)}
            value={`${formatDuration(this.props.survey.duration, this.props.t, true)} ${t(
              translations.pages.dashboard.researcher.account.editDuration
            )}`}
          />
          <OrderInfo
            title={t(translations.summary.panel.size.title)}
            value={t(translations.pages.dashboard.researcher.account.panelValue, {
              size: survey.panelSize,
            })}
          />
          <OrderInfo
            title={t(translations.pages.dashboard.researcher.account.runTime)}
            value={t(translations.pages.dashboard.researcher.account.runTimeValue, {
              start: moment(survey.startDate).format("DD.MM."),
              end: moment(survey.endDate).format("DD.MM.YYYY"),
            })}
          />
        </div>
        <div className={classes.actionWrapper}>
          <Button color="primary" size="small" style={{ opacity: 0.5, cursor: "not-allowed" }}>
            {t(translations.pages.dashboard.researcher.account.download)}
          </Button>
        </div>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles), withTranslation())(OrderSummaryItem);
