import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { withStyles, WithStyles, createStyles, Typography } from "@material-ui/core";
import { QuopinionTheme, primary } from "../../constants/Theme";
import { Info } from "../../assets/icon";
import { translations } from "../../constants/lang/translation";
import ModalComponent from "../../common/Layout/components/Modal";
import OverviewItem from "./OverviewItem";
import { Event } from "@material-ui/icons";
import { addSeconds, format } from "date-fns";
import { formatDuration } from "../../entities/Survey";

interface OwnProps {
  duration: number;
}

interface State {
  openModal: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
      backgroundColor: theme.palette.grey[200],
      border: `1px dashed ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(5),
    },
    text: {
      fontWeight: 600,
    },
    modal: {
      backgroundColor: theme.palette.grey[50],
      height: "100%",
      padding: theme.spacing(5),
    },
    modifyModal: {
      left: 0,
      top: 0,
      height: "100%",
      padding: 0,
    },
    modalIcon: {
      width: 32,
      height: 32,
      marginTop: theme.spacing(11),
    },
    modalHeadline: {
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(10)}px`,
    },
    modalDescription: {
      marginTop: theme.spacing(6),
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

class AnswerTime extends PureComponent<Props, State> {
  state: State = {
    openModal: false,
  };

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  calculateEndDate = () => {
    const currentDate = new Date();
    const endTime = addSeconds(currentDate, this.props.duration);
    return format(endTime, "dd.MM.yyyy");
  };

  calculateEndTime = () => {
    const currentDate = new Date();
    const endTime = addSeconds(currentDate, this.props.duration);
    return format(endTime, "HH:mm");
  };

  renderInformation = (props: Props) => {
    const { t, classes } = props;
    return (
      <div className={classes.modal}>
        <Info fill={primary} className={classes.modalIcon} />
        <Typography variant="h1" color="primary" className={classes.modalHeadline}>
          {t(translations.headerSurvey.answerTime.modal.headline)}
        </Typography>
        <OverviewItem
          icon={<Event />}
          title={t(translations.infoSurveyItem.deadline.title)}
          value={`${this.calculateEndDate()} ${t(
            translations.headerSurvey.answerTime.at
          )} ${this.calculateEndTime()} ${t(translations.headerSurvey.answerTime.oClock)}`}
        />
        <Typography variant="subtitle1" color="textPrimary" className={classes.modalDescription}>
          {t(translations.headerSurvey.answerTime.modal.description)}
        </Typography>
      </div>
    );
  };

  render() {
    const { classes, t, duration } = this.props;
    return (
      <div className={classes.root} onClick={this.toggleModal}>
        <Typography variant="caption" color="primary" className={classes.text}>
          {t(translations.headerSurvey.answerTime.yourTime)} {formatDuration(duration, t)} -{" "}
          {t(translations.headerSurvey.till)} {t(translations.headerSurvey.answerTime.today)}{" "}
          {this.calculateEndDate()}
        </Typography>
        <Info fill="currentColor" width="16px" height="16px" />
        {/* Modal with further information */}
        <ModalComponent
          label="answertime-info"
          description="open information about answer time"
          open={this.state.openModal}
          onClose={this.toggleModal}
          size="100%"
          className={classes.modifyModal}
          light={true}
        >
          {this.renderInformation(this.props)}
        </ModalComponent>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(AnswerTime);
