import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { QuopinionTheme, secondary } from "../../../constants/Theme";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import TermsConditions from "./TermsConditions";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(3),
      color: secondary,
      textTransform: "uppercase",
    },
    headlineInfo: {
      marginBottom: theme.spacing(3),
    },

    bodyItems: {
      display: "flex",
      flexDirection: "column",
      "& a": {
        color: "inherit",
        textDecoration: "none",
      },
      "& a:visited": {
        color: "inherit",
        textDecoration: "none",
      },
    },
    subheadline: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(3),
    },
    subtitleText: {
      marginBottom: theme.spacing(5),
      "& a": {
        textDecoration: "underline",
      },
    },

    list: { marginBottom: theme.spacing(8) },
    listItem: {
      marginBottom: theme.spacing(2),
      fontWeight: 400,
    },
  });

interface OwnProps {}

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;
export const route = "/market-research/terms-conditions";

class TermsConditionsResearcher extends PureComponent<Props> {
  render() {
    return (
      <>
        <LogoBarResearcher backButton={true} />
        <ContentWrapper>
          <Container centered={true}>
            <TermsConditions />
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(TermsConditionsResearcher);
