import React, { PureComponent } from "react";
import { MediaFolder } from "../../../assets/icon";
import Dropzone from "react-dropzone";
import { uploadMedia } from "../../../modules/survey";
import Media, { MediaReference } from "../../../entities/Media";
import { IconButton, CircularProgress } from "@material-ui/core";
import { red } from "../../../constants/Theme";

interface Props {
  onMediaUploaded: (media: Media) => void;
  onMediaUploadFailed: (reason: string) => void;
  reference: MediaReference;
}
interface State {
  loading: boolean;
  error: boolean;
}

class MediaUpload extends PureComponent<Props, State> {
  state: State = {
    loading: false,
    error: false,
  };
  onMediaSelected = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      this.setState({
        loading: true,
        error: false,
      });
      try {
        const uploadedMedia = await uploadMedia(acceptedFiles[0], this.props.reference);
        this.props.onMediaUploaded(uploadedMedia);
        this.setState({
          loading: false,
          error: false,
        });
      } catch (e) {
        // todo: do something: alert
        console.error(e.message);
        this.props.onMediaUploadFailed(e.message);
        this.setState({
          loading: false,
          error: true,
        });
      }
    }
  };
  render() {
    const { loading, error } = this.state;
    return (
      <>
        <Dropzone onDrop={this.onMediaSelected}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input
                {...getInputProps({
                  // let's start easy :)
                  multiple: false,
                })}
              />
              <div style={{ position: "relative", padding: 0 }}>
                <IconButton>
                  <MediaFolder fill={error ? red : "#BFBFBF"} />
                </IconButton>
                {loading && (
                  <CircularProgress size={50} style={{ position: "absolute", top: 0, left: 0 }} />
                )}
              </div>
            </div>
          )}
        </Dropzone>
      </>
    );
  }
}

export default MediaUpload;
