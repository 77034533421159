import * as React from "react";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";

import { RootState } from "../../../modules";
import {
  getIndividualAttributes,
  updateUserAttributes,
  fetchDailyScreener,
} from "../../../modules/attribute";
import { QuopinionTheme } from "../../../constants/Theme";
import AttributesStart from "./AttributesStart";
import { fetchPersonalData } from "../../../modules/user";
import { Screener } from "../../../entities/Screener";
import { withAuthorization } from "../../../common/hoc/withAuthorization";
import { UserRole } from "../../../entities/User";

interface OwnProps {}
interface StateProps {
  isOffline: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  screenerCollection: Screener[];
}

interface DispatchProps {
  getIndividualAttributes: () => Promise<void>;
  fetchPersonalData: () => Promise<void>;
  fetchDailyScreener: () => Promise<void>;
}

const styles = (theme: QuopinionTheme) => createStyles({});

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{ step: string }>;

export const route = "/participant/attributes";

class AttributePage extends React.Component<Props> {
  componentDidMount = async () => {
    await this.props.getIndividualAttributes();
    await this.props.fetchPersonalData();
    await this.props.fetchDailyScreener();
  };

  render() {
    const { isOffline, isAuthenticated, isLoading, screenerCollection } = this.props;
    return (
      <AttributesStart
        isLoading={isLoading}
        isOffline={isOffline}
        isAuthenticated={isAuthenticated}
        screenerCollection={screenerCollection}
      />
    );
  }
}

const mapStateToProps = ({ error, attribute, session }: RootState) => ({
  isOffline: Boolean(error.offline),
  isAuthenticated: Boolean(session.authenticated),
  screenerCollection: attribute.screenerCollection,
  isLoading: attribute.isLoading,
});

const mapDispatchToProps = {
  getIndividualAttributes,
  updateUserAttributes,
  fetchPersonalData,
  fetchDailyScreener,
};

export default compose<Props, OwnProps>(
  withRouter,
  withTranslation(),
  withStyles(styles),
  withAuthorization(UserRole.PARTICIPANT),
  connect<StateProps, any, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(AttributePage);
