import jwtDecode from "jwt-decode";
import { isAfter } from "date-fns";
import { refreshUserSession } from "./session";
import { clearState } from "../util/localStorage";

export function jwt({ dispatch, getState }: any) {
  return (next: any) => (action: any) => {
    if (typeof action === "function") {
      if (getState().session && getState().session.token) {
        const token: { sub: string } = jwtDecode(getState().session.token);
        const expirationDate = JSON.parse(token.sub).jwtTimeout;
        if (expirationDate && isAfter(new Date(Date.now()), new Date(expirationDate))) {
          if (!getState().session.refeshedToken) {
            return refreshUserSession(dispatch, getState().session.token)
              .then(() => next(action))
              .catch(() => {
                clearState();
              });
          } else {
            if (getState().session.refreshedToken) {
              return next(action);
            }
          }
        }
      }
    }

    return next(action);
  };
}
