import React from "react";
import { withStyles, CircularProgress } from "@material-ui/core";
import { WithStyles, createStyles } from "@material-ui/styles";
import { QuopinionTheme } from "../constants/Theme";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.common.white,
      opacity: 0.7,
    },
  });

type Props = WithStyles<typeof styles>;

const LoadingOverlay = withStyles(styles)(({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" />
    </div>
  );
});

export default LoadingOverlay;
