import AnswerOption from "./AnswerOption";
import ID from "./ID";
import Media from "./Media";
import {v4 as uuidv4} from "uuid";

import MatrixSubQuestion from "./MatrixSubQuestion";

/**
 * Type QuestionType
 * that represents the type of the question also defining the contents of the QuestionBody
 */
export type QuestionType =
  | "instruction"
  | "freeText"
  | "starRating"
  | "singleChoice"
  | "multipleChoice"
  | "stepRating"
  | "matrix"
  | "password"
  | "ranking";

export enum QuestionTypes {
  INSTRUCTION = "instruction",
  FREETEXT = "freeText",
  STARRATING = "starRating",
  SINGLECHOICE = "singleChoice",
  MULTIPLECHOICE = "multipleChoice",
  STEPRATING = "stepRating",
  MATRIX = "matrix",
  PASSWORD = "password",
  RANKING = "ranking",
}

export interface QuestionCondition {
  questionId: ID;
  answers: ID[];
}

/**
 * Domain entity class Question
 * which represents question that can be combined to a Questionnaire
 */
export default class Question {
  readonly id: ID;
  type: QuestionType;
  body: QuestionBody;
  media: Media[];
  text: string;
  conditions: QuestionCondition[];
  deprivedFromComplexity: boolean;

  constructor(obj: any = {}) {
  //@ts-ignore
    this.id = obj.id || uuidv4();
    this.type = (obj.type || "singleChoice") as QuestionType;
    this.text = obj.text;
    this.body = obj.body
      ? new QuestionBody(obj.body)
      : // the following is the backend structure
        new QuestionBody({
          text: obj.text,
          subQuestions:
            obj.subQuestions && obj.subQuestions.length >= 2
              ? obj.subQuestions.map(
                  (subQuestionData: MatrixSubQuestion) => new MatrixSubQuestion(subQuestionData)
                )
              : [new MatrixSubQuestion(), new MatrixSubQuestion()], //check in general how to modify to set [] for other than matrix, comprehensive check required
          answerOptions: obj.answerOptions,
          starCount: obj.starCount,
          labels: obj.labels,
          scale: obj.scale,
          additionalAnswerEnabled: obj.additionalAnswerEnabled,
          showNumericFeedback: obj.showNumericFeedback,
          passwords: obj.passwords || [""],
          randomizeAnswerOptions: obj.randomizeAnswers || false,
          link: obj.link,
        });
    this.media = obj.media || [];
    this.conditions = obj.conditions || [];
    this.deprivedFromComplexity = obj.deprivedFromComplexity || false;
  }

  toDataJson() {
    return {
      ...this,
      ...new QuestionBody(this.body).toDataJson(this.type),
    };
  }
}

/**
 * Domain entity class QuestionBody
 * which represents the body of the question that is specific to different question types
 */
export class QuestionBody {
  text: string;

  subQuestions: MatrixSubQuestion[];
  answerOptions: AnswerOption[];
  starCount: number;
  labels: { left: string; middle: string; right: string };
  scale: { min: number; max: number; step: number };
  additionalAnswerEnabled: boolean;
  randomizeAnswerOptions: boolean;
  showNumericFeedback: boolean;
  passwords: string[];
  link: string;

  constructor(obj: any = {}) {
    this.text = obj.text;
    this.answerOptions = obj.answerOptions
      ? obj.answerOptions.map((optionData: any) => new AnswerOption(optionData))
      : [new AnswerOption(), new AnswerOption()];
    this.subQuestions =
      obj.subQuestions && obj.subQuestions.length >= 2
        ? obj.subQuestions.map(
            (subQuestionData: MatrixSubQuestion) => new MatrixSubQuestion(subQuestionData)
          )
        : [new MatrixSubQuestion(), new MatrixSubQuestion()];
    this.starCount = obj.starCount || 5;
    this.labels = obj.labels || { left: "", middle: "", right: "" };
    this.scale = obj.scale || { min: 0, max: 100, step: 1 };
    this.additionalAnswerEnabled = obj.additionalAnswerEnabled || false;
    this.randomizeAnswerOptions = obj.randomizeAnswerOptions || false;
    this.showNumericFeedback = obj.showNumericFeedback || false;
    this.passwords = obj.passwords || [""];
    this.link = obj.link;
  }

  toDataJson(type: QuestionType) {
    if (type === QuestionTypes.SINGLECHOICE || type === QuestionTypes.MULTIPLECHOICE) {
      return {
        answerOptions: this.answerOptions,
        randomizeAnswers: this.randomizeAnswerOptions,
        additionalAnswerEnabled: this.additionalAnswerEnabled,
      };
    }
    if (type === QuestionTypes.MATRIX) {
      return {
        subQuestions:
          this.subQuestions.length >= 2
            ? this.subQuestions
            : [new MatrixSubQuestion(), new MatrixSubQuestion()],
        answerOptions: this.answerOptions,
      };
    }

    if (type === QuestionTypes.STEPRATING) {
      return {
        scale: { min: this.scale.min, max: this.scale.max, step: 1 },
        labels: this.labels,
        showNumericFeedback: this.showNumericFeedback,
      };
    }

    if (type === QuestionTypes.STARRATING) {
      return {
        starCount: this.starCount,
      };
    }

    if (type === QuestionTypes.INSTRUCTION) {
      return {
        text: this.text,
        link: this.link,
      };
    }

    if (type === QuestionTypes.PASSWORD) {
      return {
        passwords: this.passwords,
      };
    }

    return {
      answerOptions: this.answerOptions,
      randomizeAnswers: this.randomizeAnswerOptions,
      additionalAnswerEnabled: this.additionalAnswerEnabled,
    };
  }
}
