import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { createStyles, WithStyles, withStyles, Typography } from "@material-ui/core";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../constants/Theme";

interface LinkProps {
  active?: boolean;
  border?: boolean;
  link: string;
  title?: string;
  disabled?: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      alignItems: "center",
      backgroundColor: "#fff",
      borderLeft: (props: LinkProps) => (props.border ? "1px solid" : "none"),
      borderTop: "none",
      borderColor: (props: LinkProps) => (props.border ? theme.palette.grey[200] : "transparent"),
      borderRight: "none !important",
      borderBottom: (props: LinkProps) =>
        props.active
          ? `5px solid ${theme.palette.secondary.main}`
          : `1px solid ${theme.palette.grey[200]}`,
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      padding: (props: LinkProps) => (props.active ? "26px 0 17px" : "26px 0 22px"),
      pointerEvents: (props: LinkProps) => (props.disabled ? "none" : "all"),
      userSelect: "none",
      textAlign: "center",
      minWidth: "149px",
    },
    link: {
      textDecoration: "none",
      fontWeight: (props: LinkProps) => (props.active ? 600 : 400),
      fontSize: 16,
      lineHeight: 1.5,
      color: "#000",
      "&:visited": {
        color: "#000",
      },
    },
  });

type Props = LinkProps & WithStyles<typeof styles>;

class NavLink extends PureComponent<Props> {
  render() {
    const { title, link, classes } = this.props;
    return (
      <div className={classes.root}>
        <Link to={link} className={classes.link}>
          <Typography className={classes.link} variant="subtitle1">
            {title}
          </Typography>
        </Link>
      </div>
    );
  }
}

export default compose<Props, LinkProps>(withStyles(styles))(NavLink);
