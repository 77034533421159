import React, { Component, ReactNode } from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import { blueGradient, QuopinionTheme, secondary } from "../../constants/Theme";

interface OwnProps {
  infoTop?: string;
  headline: string;
  actionNode?: ReactNode;
  children?: ReactNode;
  hasLargeMarginBelowTitle?: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    headerWrapper: {
      paddingTop: theme.spacing(3.75),
      paddingBottom: theme.spacing(3.75),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      background: blueGradient,
    },
    h1: {
      wordBreak: "break-word",
      marginBottom: (props: OwnProps) =>
        props.hasLargeMarginBelowTitle ? theme.spacing(14) : theme.spacing(-1),
      color: "#fff",
    },
    text: {
      marginBottom: theme.spacing(4.5),
      color: "#ff6600",
    },
    headerIcon: {
      position: (props: OwnProps) => (props.infoTop ? "absolute" : "relative"),
      display: "flex",
      justifyContent: "flex-end",
      top: (props: OwnProps) => (props.infoTop ? 0 : theme.spacing(-13)),
      right: (props: OwnProps) => (props.infoTop ? 0 : theme.spacing(-4)),
      "@media (max-width:321px)": {
        left: theme.spacing(6),
      },
      "& svg": {
        height: theme.spacing(9),
        width: theme.spacing(9),
        color: secondary,
      },
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class Header extends Component<Props> {
  render() {
    const { infoTop, headline, classes, children, actionNode } = this.props;
    return (
      <div className={classes.headerWrapper}>
        <Typography variant="body1" className={classes.text}>
          {infoTop}
        </Typography>
        <Typography variant="h1" className={classes.h1}>
          {headline}
          {actionNode && <div className={classes.headerIcon}>{actionNode}</div>}
        </Typography>
        {children && children}
      </div>
    );
  }
}

export default withStyles(styles)(Header);
