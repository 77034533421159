import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { QuopinionTheme } from "../../../../constants/Theme";
import { compose } from "recompose";
import { RootState } from "../../../../modules";
import { translations } from "../../../../constants/lang/translation";
import { LoginForm } from "../../../marketintelligence/Login/LoginForm";
import { RadioCard } from "./RadioCard";
import RegisterForm from "../../Register/RegisterForm";
import LoadingOverlay from "../../../../common/LoadingOverlay";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    headline: {
      marginBottom: theme.spacing(2),
    },
    form: {
      padding: theme.spacing(5),
    },
    infoText: {
      paddingRight: theme.spacing(53),
      "@media(max-width: 1002px)": {
        paddingRight: "unset",
      },
      marginBottom: theme.spacing(3),
    },
    wrapper: {
      marginTop: theme.spacing(8),
    },
  });

interface OwnProps {}

interface StateProps {
  isLoading: boolean;
}

interface DispatchProps {}

interface State {
  login: boolean;
}

type Props = OwnProps &
  StateProps &
  DispatchProps &
  State &
  WithStyles<typeof styles> &
  WithTranslation;

class SummaryAuthentification extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { login: true };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleChange = () => {
    this.setState({
      login: !this.state.login,
    });
  };

  render() {
    const { classes, t } = this.props;
    const { login } = this.state;

    return (
      <>
        {this.props.isLoading && <LoadingOverlay />}
        <div className={classes.wrapper}>
          <Typography variant="h2" color="primary" className={classes.headline}>
            {t(translations.summary.authSection.headline)}
          </Typography>
          <Typography className={classes.infoText}>
            {t(translations.summary.authSection.infoText)}
          </Typography>
          <RadioCard
            title={t(translations.summary.authSection.alreadyCustomer)}
            checked={login}
            handleSelection={this.handleChange}
          >
            {login && <LoginForm withLoginButtonInside={true} onSummary={true} />}
          </RadioCard>
          <RadioCard
            title={t(translations.summary.authSection.registerNewCustomer)}
            checked={!login}
            handleSelection={this.handleChange}
          >
            {!login && <RegisterForm />}
          </RadioCard>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ survey, session }: RootState) => {
  return {
    isLoading: session.isUpdating,
  };
};

const mapDispatchToProps = {
  /** save survey */
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect<{}, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(SummaryAuthentification);
