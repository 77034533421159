import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";
import { Typography, TextField, createStyles, WithStyles, withStyles } from "@material-ui/core";
import { translations } from "../../../constants/lang/translation";
import { QuopinionTheme } from "../../../constants/Theme";
import Card from "../../../common/Layout/components/Card";
import Button from "../../../common/Layout/components/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import Services from "../../../services/Services";

interface OwnProps {}

interface State {
  error: boolean;
  success: boolean;
  send: boolean;
  isVerified: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    formWrapper: {
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing(8)}px ${theme.spacing(6)}px`,
      marginTop: theme.spacing(6),
    },
    input: {
      marginBottom: theme.spacing(6),
      width: "100%",
    },
    buttonLine: {
      display: "flex",
      justifyContent: "flex-end",
    },
    message: {
      marginTop: theme.spacing(4),
      borderRadius: 4,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[200],
    },
    numberInput: {
      "& input": {
        "&::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
    },
    backgroundHP: {
      opacity: 0,
      position: "absolute",
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      zIndex: -1
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

class ContactParticipant extends PureComponent<Props, State> {
  state = {
    error: false,
    success: false,
    send: false,
    isVerified: false,
  };

  readonly initialValues = {
    name: "",
    emailAddress: "",
    phone: "",
    subject: "",
    message: "",
    email: "",
    text: ""
  };

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      name: Yup.string()
        .min(2, t(translations.pages.contact.form.nameValidationLength))
        .required(t(translations.pages.contact.form.nameValidation)),
      emailAddress: Yup.string()
        .trim()
        .email()
        .required(t(translations.validation.login.emailRequiredParticipant)),
      phone: Yup.number().typeError(t(translations.pages.contact.form.phoneType)),
      subject: Yup.string(),
      message: Yup.string().required(t(translations.pages.contact.form.validationMessage)),
      email: Yup.string(),
      text: Yup.string()
    });
  };

  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render() {
    const { t, classes } = this.props;
    const { send } = this.state;

    return (
      <>
        <Typography variant="h1" color="secondary">
          {t(translations.pages.contact.participant.title)}
        </Typography>
        <Typography variant="subtitle1">
          {t(translations.pages.contact.participant.description)}
        </Typography>
        <Card isRaised={true} className={classes.formWrapper}>
          <Formik
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(false);
              Services.user
                .sendContactMailRequest({ ...values })
                .then(() => {
                  this.setState({ success: true, send: true });
                  resetForm();
                })
                .catch(() => {
                  this.setState({ error: true, send: false });
                });
            }}
          >
            {(formikProps) => {
              const {
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                isSubmitting,
              } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  {/* Name* */}
                  <TextField
                    name={"name"}
                    required={true}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.name)}
                    value={values.name}
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    error={touched.name && Boolean(errors.name)}
                    helperText={
                      errors.name && touched.name ? (
                        <Typography variant="body1">{errors.name}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  {/* E-Mail* */}
                  <TextField
                    name={"emailAddress"}
                    required={true}
                    className={classes.input}
                    placeholder={t(translations.register.loginDetails.eMail)}
                    value={values.emailAddress}
                    onChange={handleChange("emailAddress")}
                    onBlur={handleBlur("emailAddress")}
                    error={touched.emailAddress && Boolean(errors.emailAddress)}
                    helperText={
                      errors.emailAddress && touched.emailAddress ? (
                        <Typography variant="body1">{errors.emailAddress}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  {/* Phone */}
                  <TextField
                    name={"phone"}
                    type="tel"
                    className={`${classes.input} ${classes.numberInput}`}
                    placeholder={t(translations.pages.contact.form.phone)}
                    value={values.phone}
                    onChange={handleChange("phone")}
                    onBlur={handleBlur("phone")}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={
                      errors.phone && touched.phone ? (
                        <Typography variant="body1">{errors.phone}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  {/* subject */}
                  <TextField
                    name={"subject"}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.subject)}
                    value={values.subject}
                    onChange={handleChange("subject")}
                    onBlur={handleBlur("subject")}
                  />
                  {/* Message* */}
                  <TextField
                    name={"message"}
                    required={true}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.message)}
                    multiline={true}
                    rows={10}
                    value={values.message}
                    onChange={handleChange("message")}
                    onBlur={handleBlur("message")}
                    error={touched.message && Boolean(errors.message)}
                    helperText={
                      errors.message && touched.message ? (
                        <Typography variant="body1">{errors.message}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  <div className={classes.backgroundHP}>
                    <label htmlFor="name"/>
                    <input autoComplete="off" type="text" id="name" name="name" value={values.name}
                           onChange={handleChange("name")} placeholder="Your name here"/>
                    <label htmlFor="text"/>
                    <input autoComplete="off" type="text" id="text" name="text" value={values.text}
                           onChange={handleChange("text")} placeholder="Your text here"/>
                  </div>
                  <div className={classes.buttonLine}>
                    <Button
                      color="secondary"
                      contained={true}
                      size="big"
                      onClick={handleSubmit}
                      type="submit"
                      disabled={isSubmitting || send}
                    >
                      {t(translations.action.send)}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
          {this.state.send && (
            <div className={classes.message}>
              {this.state.success && (
                <Typography color="primary">
                  {t(translations.pages.contact.participant.success)}
                </Typography>
              )}
              {this.state.error && (
                <Typography color="primary">
                  {t(translations.pages.contact.participant.error)}
                </Typography>
              )}
            </div>
          )}
        </Card>
      </>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(ContactParticipant);
