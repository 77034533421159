import React, { PureComponent } from "react";
import { WithStyles, createStyles, withStyles, Typography } from "@material-ui/core";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";

import { primary, QuopinionTheme } from "../../constants/Theme";
import FAQComponent from "../../common/Layout/components/FAQComponent";
import { translations } from "../../constants/lang/translation";
import i18next from "i18next";

interface OwnProps {
  whiteBackgroundColor: boolean;
}

interface State {
  openFAQ: number;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      backgroundColor: (props: OwnProps) =>
        props.whiteBackgroundColor ? "transparent" : "#f1f9ff",
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(12)}px`,
      "@media (min-width: 600px)": {
        padding: `${theme.spacing(10)}px calc(16.66% + 40px) ${theme.spacing(14)}px`,
      },
      "@media (min-width: 1040px)": {
        padding: `${theme.spacing(10)}px 25% ${theme.spacing(24)}px`,
      },
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    headline: {
      fontFamily: "catamaranBold",
      fontSize: "32px",
      lineHeight: 1.25,
      fontWeight: 200,
      marginBottom: theme.spacing(6),
      color: primary,
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const questions = [
  {
    question: i18next.t(translations.pages.FAQ.researcher.question1),
    answer: i18next.t(translations.pages.FAQ.researcher.answer1, { break: "<br/>" }),
    indicator: 1,
  },
  {
    question: i18next.t(translations.pages.FAQ.researcher.question2),
    answer: i18next.t(translations.pages.FAQ.researcher.answer2, {
      link: '(<a href="https://www.quotas.de">www.quotas.de</a>)',
    }),
    indicator: 2,
  },
  {
    question: i18next.t(translations.pages.FAQ.researcher.question3),
    answer: i18next.t(translations.pages.FAQ.researcher.answer3),
    indicator: 3,
  },
  {
    question: i18next.t(translations.pages.FAQ.researcher.question4),
    answer: i18next.t(translations.pages.FAQ.researcher.answer4, { break: "<br/><br/>" }),
    indicator: 4,
  },
  {
    question: i18next.t(translations.pages.FAQ.researcher.question5),
    answer: i18next.t(translations.pages.FAQ.researcher.answer5, {
      email: '<a href="mailto:support@quopinion.de">support@quopinion.de</a>',
    }),
    indicator: 5,
  },
  {
    question: i18next.t(translations.pages.FAQ.researcher.question6),
    answer: i18next.t(translations.pages.FAQ.researcher.answer6, {
      email: '<a href="mailto:support@quopinion.de">support@quopinion.de</a>',
      break: "<br/>",
    }),
    indicator: 6,
  },
];

class FAQResearcher extends PureComponent<Props, State> {
  state: State = {
    openFAQ: 0,
  };

  toggleQuestion = (value: number) => {
    const { openFAQ } = this.state;
    if (openFAQ === value) {
      this.setState({
        openFAQ: 0,
      });
    } else {
      this.setState({
        openFAQ: value,
      });
    }
  };

  render() {
    const { classes, t } = this.props;
    const { openFAQ } = this.state;
    return (
      <div className={classes.root}>
        <Typography variant="h2" className={classes.headline}>
          {t(translations.pages.FAQ.frequentlyAskedQuestions)}
        </Typography>
        {questions.map((question) => (
          <FAQComponent
            key={question.indicator}
            question={`${question.indicator}. ${question.question}`}
            answer={question.answer}
            open={openFAQ === question.indicator}
            handleClick={() => this.toggleQuestion(question.indicator)}
          />
        ))}
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles), withTranslation())(FAQResearcher);
