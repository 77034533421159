import { QuopinionTheme } from "../../../constants/Theme";
import { createStyles, Typography, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { compose } from "recompose";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import { translations } from "../../../constants/lang/translation";

import ContactForm from "./ContactForm";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    formWrapper: {
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing(8)}px ${theme.spacing(6)}px`,
      marginTop: theme.spacing(6),
    },
    input: {
      marginBottom: theme.spacing(6),
      width: "100%",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    itemTitle: {
      fontWeight: "initial",
      fontSize: "16px",
      lineHeight: 1.33,
    },
    seperateError: {
      marginBottom: theme.spacing(6),
    },
    phoneBackWrapper: {
      marginBottom: theme.spacing(8),
    },
    message: {
      marginTop: theme.spacing(4),
      borderRadius: 4,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[200],
    },
  });

interface OwnProps {}

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

export const route = "/market-research/contact";

const ContactResearcherComponent: React.FC<Props> = ({ t }) => {
  return (
    <>
      <LogoBarResearcher backButton={true} />
      <ContentWrapper>
        <Container centered={true}>
          <Typography variant="h1" color="secondary">
            {t(translations.pages.contact.researcher.title)}
          </Typography>
          <Typography variant="subtitle1">
            {t(translations.pages.contact.researcher.description)}
          </Typography>{" "}
          <ContactForm />
        </Container>
      </ContentWrapper>
    </>
  );
};

export const ContactFormResearcher = compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(ContactResearcherComponent);
