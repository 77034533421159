import React, { PureComponent, ReactNode, ChangeEvent } from "react";
import { QuopinionTheme } from "../../../constants/Theme";
import { createStyles, WithStyles, withStyles, TextField, Typography } from "@material-ui/core";
import { compose } from "recompose";
import { Minus } from "../../../assets/icon";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";

interface OwnProps {
  share: number;
  index?: number;
  changeValue: string;
  deleteFunction: (arg1?: any) => void;
  changeShareFunction: (event: ChangeEvent<HTMLInputElement>, arg2: any, arg3?: any) => void;
  customElement: ReactNode;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(2)}px ${theme.spacing(
        2
      )}px`,
      backgroundColor: theme.palette.grey[50],
      display: "flex",
      alignItems: "center",
      width: "fit-content",
      marginBottom: theme.spacing(1),
    },
    input: {
      width: 70,
      margin: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
      "& input": {
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
    },
    icon: {
      marginLeft: theme.spacing(4),
    },
  });
type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

class WithShareContainer extends PureComponent<Props> {
  render() {
    const {
      classes,
      share,
      t,
      index,
      deleteFunction,
      changeShareFunction,
      customElement,
      changeValue,
    } = this.props;
    return (
      <div className={classes.root}>
        {customElement}
        <Typography>{t(translations.summary.panel.thereof)}</Typography>
        <TextField
          value={share.toFixed(0) || 50}
          classes={{ root: classes.input }}
          type="number"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            changeShareFunction(event, changeValue, index!)
          }
          InputProps={{ inputProps: { min: 1, max: 99 } }}
        />{" "}
        %
        <Minus fill="#bfbfbf" className={classes.icon} onClick={() => deleteFunction(index)} />
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(WithShareContainer);
