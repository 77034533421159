import React, { PureComponent } from "react";
import { Typography, createStyles, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";

interface OwnProps {
  title: string;
  description: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    headline: {
      marginBottom: theme.spacing(9),
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class PanelModal extends PureComponent<Props> {
  render() {
    const { title, description, classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="body2" color="primary" className={classes.headline}>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(PanelModal);
