import User, { CreditData, CreditEntry, PersonalData } from "../entities/User";
import Services from "../services/Services";
import { RootState, ThunkResult } from ".";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";
import { Dispatch } from "redux";
import {
  AnsweredChoiceAttribute,
  AnsweredNumberAttribute,
  AnsweredRatingAttribute,
} from "../services/Accounts";
import { fetchRegisterConfiguration, unsubscribeSessionToken } from "./session";
import { SurveyInformation } from "../entities/Survey";
import Researcher from "../entities/Researcher";

/**
 * collection of answers with different types
 */
export type AttributesList = (
  | AnsweredChoiceAttribute
  | AnsweredNumberAttribute
  | AnsweredRatingAttribute
)[];

export interface UserAttributesResponse {
  answeredAttributes: AttributesList;
  attributes: AttributesList;
}

export interface State {
  current: User;
  isLoading: boolean;
  personalData: PersonalData;
  attributes: UserAttributesResponse;
  currentLevel: number | undefined;
  participantInfo: any;
  levelUpgraded: boolean;
  researcherSurveys: SurveyInformation[];
  currentResearcher: Researcher;
  isLoadingSurveys: boolean;
  creditData: CreditData;
}

const defaultState: State = {
  current: new User({}),
  personalData: {} as PersonalData,
  attributes: {} as UserAttributesResponse,
  currentLevel: undefined,
  isLoading: true,
  participantInfo: {},
  levelUpgraded: false,
  researcherSurveys: [],
  currentResearcher: new Researcher(),
  isLoadingSurveys: false,
  creditData: {
    credit: 0,
    credits: [] as CreditEntry[],
  } as CreditData,
};

const GET_CURRENT_LEVEL_OF_USER = "user/GET_CURRENT_LEVEL_OF_USER";
const UPDATE_LEVEL_UPGRADED = "user/UPDATE_LEVEL_UPGRADED";

export const actions = {
  updateCurrentUser: createAsyncAction(
    "user/UPDATE_CURRENT_USER_REQUEST",
    "user/UPDATE_CURRENT_USER_SUCCESS",
    "user/UPDATE_CURRENT_USER_FAILURE"
  )<void, void, void>(),
  updateCurrentUserPersonalData: createAsyncAction(
    "user/UPDATE_CURRENT_USER_PERSONALDATA_REQUEST",
    "user/UPDATE_CURRENT_USER_PERSONALDATA_SUCCESS",
    "user/UPDATE_CURRENT_USER_PERSONALDATA_FAILURE"
  )<void, void, void>(),
  updateCurrentUserAttributes: createAsyncAction(
    "user/UPDATE_CURRENT_USER_ATTRIBUTES_REQUEST",
    "user/UPDATE_CURRENT_USER_ATTRIBUTES_SUCCESS",
    "user/UPDATE_CURRENT_USER_ATTRIBUTES_FAILURE"
  )<void, void, void>(),
  registerCurrentUser: createAsyncAction(
    "user/CREATE_CURRENT_USER_REQUEST",
    "user/CREATE_CURRENT_USER_SUCCESS",
    "user/CREATE_CURRENT_USER_FAILURE"
  )<void, User, void>(),
  fetchCurrentUser: createAsyncAction(
    "user/FETCH_CURRENT_USER_REQUEST",
    "user/FETCH_CURRENT_USER_SUCCESS",
    "user/FETCH_CURRENT_USER_FAILURE"
  )<void, User, void>(),
  fetchCurrentUserAttributes: createAsyncAction(
    "user/FETCH_CURRENT_USER_ATTRIBUTES_REQUEST",
    "user/FETCH_CURRENT_USER_ATTRIBUTES_SUCCESS",
    "user/FETCH_CURRENT_USER_ATTRIBUTES_FAILURE"
  )<void, UserAttributesResponse, void>(),
  fetchCurrentUserPersonalData: createAsyncAction(
    "user/FETCH_CURRENT_USER_PERSONALDATA_REQUEST",
    "user/FETCH_CURRENT_USER_PERSONALDATA_SUCCESS",
    "user/FETCH_CURRENT_USER_PERSONALDATA_FAILURE"
  )<void, PersonalData, void>(),
  fetchCurrentUserSurveys: createAsyncAction(
    "user/FETCH_CURRENT_USER_SURVEYS_REQUEST",
    "user/FETCH_CURRENT_USER_SURVEYS_SUCCESS",
    "user/FETCH_CURRENT_USER_SURVEYS_FAILURE"
  )<void, SurveyInformation[], void>(),
  fetchCurrentResearcher: createAsyncAction(
    "user/FETCH_CURRENT_RESEARCHER_REQUEST",
    "user/FETCH_CURRENT_RESEARCHER_SUCCES",
    "user/FETCH_CURRENT_RESEARCHER_FAILURE"
  )<void, Researcher, void>(),
  updateCurrentResearcher: createAsyncAction(
    "user/UPDATE_CURRENT_RESEARCHER_REQUEST",
    "user/UPDATE_CURRENT_RESEARCHER_SUCCES",
    "user/UPDATE_CURRENT_RESEARCHER_FAILURE"
  )<void, Researcher, void>(),
  fetchCurrentCredit: createAsyncAction(
    "user/FETCH_CURRENT_CREDIT_REQUEST",
    "user/FETCH_CURRENT_CREDIT_SUCCES",
    "user/FETCH_CURRENT_CREDIT_FAILURE"
  )<void, number, void>(),
  fetchCurrentCreditData: createAsyncAction(
    "user/FETCH_CURRENT_CREDIT_DATA_REQUEST",
    "user/FETCH_CURRENT_CREDIT_DATA_SUCCES",
    "user/FETCH_CURRENT_CREDIT_DATA_FAILURE"
  )<void, CreditData, void>(),
  requestPayout: createAsyncAction(
    "user/REQUEST_PAYOUT_REQUEST",
    "user/REQUEST_PAYOUT_SUCCES",
    "user/REQUEST_PAYOUT_FAILURE"
  )<void, void, void>(),
  fetchCurrentLevelOfUser: (level: string) =>
    ({
      type: GET_CURRENT_LEVEL_OF_USER,
      payload: level,
    } as const),
  fetchCurrentUserPersonalInformation: createAsyncAction(
    "user/FETCH_CURRENT_USER_PERSONALINFORMATIONS_REQUEST",
    "user/FETCH_CURRENT_USER_PERSONALINFORMATIONS_SUCCESS",
    "user/FETCH_CURRENT_USER_PERSONALINFORMATIONS_FAILURE"
  )<void, any, void>(),
  updateOpinionPrice: createAsyncAction(
    "user/UPDATE_CURRENT_USER_PRICE_REQUEST",
    "user/UPDATE_CURRENT_USER_PRICE_SUCCESS",
    "user/UPDATE_CURRENT_USER_PRICE_FAILURE"
  )<void, any, void>(),
  updateLevelUpgraded: (status: boolean) =>
    ({
      type: UPDATE_LEVEL_UPGRADED,
      payload: status,
    } as const),
};

export const hideLevelUpgraded = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.updateLevelUpgraded(false));
  };
};

export const fetchUser = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchCurrentUser.request());
      const result = await Services.session.fetchUser();
      dispatch(actions.fetchCurrentUser.success(result));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  };
};

export const fetchAttributes = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchCurrentUserAttributes.request());
      const result = await Services.session.fetchAttributesForUser();
      dispatch(actions.fetchCurrentUserAttributes.success(result));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  };
};

export const fetchPersonalData = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(actions.fetchCurrentUserPersonalData.request());
      const previousLevel = getState().user.currentLevel;

      const result = await Services.session.fetchParticipantInfoOfUser();

      dispatch(actions.fetchCurrentLevelOfUser(result.currentLevel));
      if (previousLevel) {
        let currentLevel: number;
        if (result.currentLevel === "BRONZE") {
          currentLevel = 1;
        } else if (result.currentLevel === "SILVER") {
          currentLevel = 2;
        } else {
          currentLevel = 3;
        }
        if (currentLevel > previousLevel) {
          dispatch(actions.updateLevelUpgraded(true));
        }
      }
      dispatch(actions.fetchCurrentUserPersonalData.success(result.personalData));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  };
};

export const updateCurrentUserAttributes = (userAttributes: AttributesList) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateCurrentUserAttributes.request());
      await Services.accounts.updateBasicAttributes(userAttributes);
      dispatch(actions.updateCurrentUserAttributes.success());
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};
export const updateCurrentUserPersonalData = (personalData: PersonalData) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateCurrentUserPersonalData.request());
      await Services.accounts.updatePersonalData(personalData);
      dispatch(actions.updateCurrentUserPersonalData.success());
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchParticipantInfo = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchCurrentUserPersonalInformation.request());
      const result = await Services.accounts.fetchParticipantInfo();
      console.log(result);
      dispatch(actions.fetchCurrentUserPersonalInformation.success(result));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const updateParticipantPrice = (newOpinionPrice: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateOpinionPrice.request());
      const result = await Services.accounts.updatePriceInformation(newOpinionPrice);
      dispatch(actions.updateOpinionPrice.success(result));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchCurrentResearcherSurveys = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchCurrentUserSurveys.request());
      const result = await Services.survey.getSurveys();
      dispatch(actions.fetchCurrentUserSurveys.success(result));
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateCurrentUserAttributesAndPersonalData = (
  personalData: PersonalData,
  answeredAttributes: AttributesList
): ThunkResult<void> => {
  return async (dispatch) => {
    return dispatch(updateCurrentUserPersonalData(personalData)).then(() => {
      return dispatch(updateCurrentUserAttributes(answeredAttributes)).then(() =>
        dispatch(fetchBasicAttributesAndPersonalData())
      );
    });
  };
};

export const fetchBasicAttributesAndPersonalData = (): ThunkResult<void> => {
  return async (dispatch) => {
    return dispatch(fetchAttributes()).then(() => {
      return dispatch(fetchPersonalData());
    });
  };
};

export const fetchUserAndFetchBasicAttributes = (): ThunkResult<void> => {
  return async (dispatch) => {
    return dispatch(fetchRegisterConfiguration()).then(() => {
      return dispatch(fetchUser());
    });
  };
};

export const fetchCurrentResearcher = (): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      dispatch(actions.fetchCurrentResearcher.request());

      const result = await Services.accounts.fetchCurrentResearcher();

      dispatch(actions.fetchCurrentResearcher.success(result));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const updateCurrentResearcher = (researcher: Researcher): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      dispatch(actions.updateCurrentResearcher.request());

      await Services.accounts.updateCurrentResearcher(researcher);

      dispatch(actions.updateCurrentResearcher.success(researcher));
      return Promise.resolve();
    } catch (error) {
      console.log("updateCurrentResearcher failure", error);
      return Promise.reject(error);
    }
  };
};

export const fetchCurrentCredit = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchCurrentCredit.request());
      const credit = await Services.user.fetchCurrentCredit();
      dispatch(actions.fetchCurrentCredit.success(credit));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};
export const fetchCurrentCreditData = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchCurrentCreditData.request());
      const credit = await Services.user.fetchCurrentCredit();
      const credits = await Services.user.fetchCurrentCredits();
      dispatch(actions.fetchCurrentCreditData.success({ credit, credits }));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  };
};

export const requestPayout = (
  amount: number,
  destination: string,
  email?: string
): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.requestPayout.request());
      await Services.user.requestPayout(amount, destination, email);
      dispatch(actions.requestPayout.success());
      const credit = await Services.user.fetchCurrentCredit();
      const credits = await Services.user.fetchCurrentCredits();
      dispatch(actions.fetchCurrentCreditData.success({ credit, credits }));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  };
};

export const deleteOwnParticipantAccount = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      await Services.accounts.selfDeleteCurrentParticipant().then(() => unsubscribeSessionToken());
    } catch (error) {
      return Promise.reject();
    }
  };
};

export type Action = ActionType<typeof actions>;

export const reducer = (state = defaultState, action: Action): State => {
  switch (action.type) {
    case getType(actions.fetchCurrentUser.request):
      return { ...state, isLoading: true };
    case getType(actions.fetchCurrentUser.success):
      return { ...state, isLoading: false, current: action.payload };
    case getType(actions.fetchCurrentUser.failure):
      return { ...state, isLoading: false };
    case getType(actions.fetchCurrentUserAttributes.request):
      return { ...state, isLoading: true };
    case getType(actions.fetchCurrentUserAttributes.success):
      return { ...state, isLoading: false, attributes: action.payload };
    case getType(actions.fetchCurrentUserPersonalData.request):
      return { ...state, isLoading: true };
    case getType(actions.fetchCurrentUserPersonalData.success):
      return { ...state, isLoading: false, personalData: action.payload };
    case getType(actions.fetchCurrentUserPersonalInformation.request):
      return { ...state, isLoading: true };
    case getType(actions.fetchCurrentUserPersonalInformation.success):
      return { ...state, isLoading: false, participantInfo: action.payload };
    case getType(actions.fetchCurrentUserSurveys.request):
      return { ...state, isLoadingSurveys: true };
    case getType(actions.fetchCurrentUserSurveys.success):
      return {
        ...state,
        isLoadingSurveys: false,
        researcherSurveys: action.payload.map((item) => new SurveyInformation(item)),
      };
    case getType(actions.fetchCurrentResearcher.request):
      return { ...state, isLoading: true };
    case getType(actions.fetchCurrentResearcher.success):
      return { ...state, isLoading: false, currentResearcher: action.payload };
    case getType(actions.updateCurrentResearcher.request):
      return { ...state, isLoading: true };
    case getType(actions.updateCurrentResearcher.success):
      return { ...state, isLoading: false, currentResearcher: action.payload };
    case getType(actions.fetchCurrentCredit.success):
      return { ...state, creditData: { ...state.creditData, credit: action.payload } };
    case getType(actions.fetchCurrentCreditData.success):
      return { ...state, creditData: action.payload };
    case GET_CURRENT_LEVEL_OF_USER:
      let newLevel;
      if (action.payload === "BRONZE") {
        newLevel = 1;
      } else if (action.payload === "SILVER") {
        newLevel = 2;
      } else {
        newLevel = 3;
      }
      return { ...state, currentLevel: newLevel };
    case UPDATE_LEVEL_UPGRADED:
      return { ...state, levelUpgraded: action.payload };
    default:
      return state;
  }
};
