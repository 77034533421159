import React, { PureComponent } from "react";
import { QuopinionTheme } from "../../../../constants/Theme";
import { createStyles, WithStyles, withStyles, Typography } from "@material-ui/core";
import { compose } from "recompose";
import Badge from "../../../../common/Layout/components/Badge";

interface OwnProps {
  headline: string;
  description: string;
  badgeTitle: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    description: {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(8)}px`,
    },
    badge: {
      marginBottom: theme.spacing(6),
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class StepIntro extends PureComponent<Props> {
  render() {
    const { classes, headline, badgeTitle, description } = this.props;
    return (
      <div>
        <Badge color="secondary" width={90} title={badgeTitle} className={classes.badge} />
        <Typography variant="h1">{headline}</Typography>
        <Typography variant="subtitle1" className={classes.description}>
          {description}
        </Typography>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(StepIntro);
