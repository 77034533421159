import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Formik } from "formik";
import { WithStyles, createStyles } from "@material-ui/styles";
import { Typography, TextField, Grid, withStyles } from "@material-ui/core";
import * as Yup from "yup";

import { QuopinionTheme } from "../../../constants/Theme";
import { translations } from "../../../constants/lang/translation";
import Card from "../../../common/Layout/components/Card";
import Researcher from "../../../entities/Researcher";
import { RootState } from "../../../modules";
import { compose } from "recompose";
import { connect } from "react-redux";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import Button from "../../../common/Layout/components/Button";
import { fetchCurrentResearcher, updateCurrentResearcher } from "../../../modules/user";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { Tip } from "./components/TipComponent";

export const route = "/market-research/billing-data";

interface OwnProps {}

interface DispatchProps {
  fetchCurrentResearcher: () => Promise<void>;
  updateCurrentResearcher: (researcher: Researcher) => Promise<Researcher>;
}

interface StateProps {
  currentResearcher: Researcher;
  isLoading: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    card: {
      padding: theme.spacing(6),
      marginTop: theme.spacing(6),
    },
    cardHeadline: {
      textTransform: "uppercase",
    },
    input: {
      width: "100%",
      marginTop: theme.spacing(4),
    },
    buttonLine: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
    },
    numberInput: {
      "& input": {
        "&::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
    },
  });

interface State {
  send: boolean;
  error: boolean;
  success: boolean;
}

type Props = OwnProps & DispatchProps & StateProps & WithTranslation & WithStyles<typeof styles>;

class BillingData extends PureComponent<Props, State> {
  state: State = {
    send: false,
    error: false,
    success: false,
  };

  initialValues = { ...this.props.currentResearcher.organizationDetails };

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      name: Yup.string()
        .trim()
        .min(2, t(translations.register.personalInformation.companyNameLength))
        .required(t(translations.register.personalInformation.companyNameRequired)),
      streetAndNumber: Yup.string()
        .min(2, t(translations.register.personalInformation.addressLength))
        .required(t(translations.register.personalInformation.addressDataRequired)),
      zipCode: Yup.string()
        .min(5, t(translations.register.personalInformation.postalCodeLength))
        .required(t(translations.register.personalInformation.postalCodeRequired)),
      city: Yup.string()
        .min(2, t(translations.register.personalInformation.cityLength))
        .required(t(translations.register.personalInformation.cityRequired)),
      postOfficeBox: Yup.string(),
      additionalInvoiceInformation: Yup.string(),
    });
  };

  componentDidMount = async () => {
    await this.props.fetchCurrentResearcher();
  };

  render() {
    const { classes, t, isLoading } = this.props;
    if (isLoading) {
      return <LoadingOverlay />;
    }

    this.initialValues = { ...this.props.currentResearcher.organizationDetails };

    return (
      <div>
        <LogoBarResearcher backButton={true} linkBorders={true} />
        <Grid container={true}>
          <ContentWrapper>
            <Container>
              <Formik
                initialValues={this.initialValues}
                validationSchema={this.validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const newResearcher = new Researcher({
                    ...this.props.currentResearcher,
                    organizationDetails: { ...values },
                  });
                  this.props
                    .updateCurrentResearcher(newResearcher)
                    .then(() => this.setState({ send: true, success: true }))
                    .catch(() => {
                      this.setState({
                        send: true,
                        error: true,
                      });
                    });

                  setSubmitting(false);
                }}
              >
                {(formikProps) => {
                  const {
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    isSubmitting,
                  } = formikProps;

                  return (
                    <form onSubmit={handleSubmit}>
                      <Card isRaised={true} className={classes.card}>
                        <Typography variant="body2" className={classes.cardHeadline}>
                          {t(translations.summary.authSection.billData)}
                        </Typography>
                        {/*CompanyName*/}
                        <TextField
                          name={"name"}
                          required={false}
                          className={classes.input}
                          placeholder={t(translations.pages.contact.form.researcher.companyName)}
                          value={values.name}
                          onChange={handleChange("name")}
                          onBlur={handleBlur("name")}
                          error={touched.name && Boolean(errors.name)}
                          helperText={errors.name && touched.name ? `${errors.name}` : ""}
                        />
                        {/* Street and HouseNr*/}
                        <TextField
                          name={"addressWithHouseNr"}
                          required={false}
                          className={classes.input}
                          placeholder={t(
                            translations.register.personalInformation.addressWithHouseNr
                          )}
                          value={values.streetAndNumber}
                          onChange={handleChange("streetAndNumber")}
                          onBlur={handleBlur("streetAndNumber")}
                          error={touched.streetAndNumber && Boolean(errors.streetAndNumber)}
                          helperText={
                            errors.streetAndNumber && touched.streetAndNumber
                              ? `${errors.streetAndNumber}`
                              : ""
                          }
                        />
                        <Grid container={true} spacing={5}>
                          {/* Postal code */}
                          <Grid item={true} xs={12} sm={3}>
                            <TextField
                              name={"postalCode"}
                              required={true}
                              type="number"
                              className={`${classes.input} ${classes.numberInput}`}
                              placeholder={t(translations.register.personalInformation.postalCode)}
                              value={values.zipCode}
                              onChange={handleChange("zipCode")}
                              onBlur={handleBlur("zipCode")}
                              error={touched.zipCode && Boolean(errors.zipCode)}
                              helperText={
                                errors.zipCode && touched.zipCode ? `${errors.zipCode}` : ""
                              }
                            />
                          </Grid>
                          {/* City */}
                          <Grid item={true} xs={12} sm={9}>
                            <TextField
                              name={"city"}
                              required={true}
                              className={classes.input}
                              placeholder={t(translations.participant.profile.edit.city)}
                              value={values.city}
                              onChange={handleChange("city")}
                              onBlur={handleBlur("city")}
                              error={touched.city && Boolean(errors.city)}
                              helperText={
                                errors.city && touched.city ? (
                                  <Typography variant="body1">{errors.city}</Typography>
                                ) : (
                                  ""
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                        {/* Postfach */}
                        <TextField
                          name={"postOfficeBox"}
                          required={true}
                          className={classes.input}
                          placeholder={t(translations.register.personalInformation.postBox)}
                          value={values.postOfficeBox}
                          onChange={handleChange("postOfficeBox")}
                          onBlur={handleBlur("postOfficeBox")}
                          error={touched.postOfficeBox && Boolean(errors.postOfficeBox)}
                          helperText={
                            errors.postOfficeBox && touched.postOfficeBox
                              ? `${errors.postOfficeBox}`
                              : ""
                          }
                        />
                        {/* Textfield for further bill Data */}
                        <TextField
                          name={"additionalInvoiceInformation"}
                          required={false}
                          className={classes.input}
                          placeholder={t(translations.register.personalInformation.furtherData)}
                          multiline={true}
                          rows={10}
                          value={values.additionalInvoiceInformation}
                          onChange={handleChange("additionalInvoiceInformation")}
                          onBlur={handleBlur("additionalInvoiceInformation")}
                          error={
                            touched.additionalInvoiceInformation &&
                            Boolean(errors.additionalInvoiceInformation)
                          }
                          helperText={
                            errors.additionalInvoiceInformation &&
                            touched.additionalInvoiceInformation
                              ? `${errors.additionalInvoiceInformation}`
                              : ""
                          }
                        />
                      </Card>
                      <div className={classes.buttonLine}>
                        {this.state.send && (
                          <>
                            {this.state.success && (
                              <Tip text={t(translations.confirmation.researcher.success)} />
                            )}
                            {this.state.error && (
                              <Tip text={t(translations.confirmation.researcher.failure)} />
                            )}
                          </>
                        )}
                        <Button
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                          color="secondary"
                          size="big"
                          contained={true}
                          type="submit"
                        >
                          {t(translations.action.save)}
                        </Button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Container>
          </ContentWrapper>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ user }: RootState) => ({
  currentResearcher: user.currentResearcher,
  isLoading: user.isLoading,
});

const mapDispatchToProps = {
  fetchCurrentResearcher,
  updateCurrentResearcher,
};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect<{}, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(BillingData);
