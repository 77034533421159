import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";

import ContentWrapper from "../../../common/Layout/ContentWrapper";
import IntroductionForStep from "../../marketintelligence/SurveyCreator/IntroductionForStep";
import { translations } from "../../../constants/lang/translation";
import Container from "../../../common/Layout/Container";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import ContactParticipant from "../Contact";
import { QuopinionTheme } from "../../../constants/Theme";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import FAQParticipantContent from "./FAQParticipant";

interface OwnProps {}
const styles = (theme: QuopinionTheme) =>
  createStyles({
    faqItem: {
      marginBottom: theme.spacing(13),
    },
    contactWrapper: {
      marginTop: theme.spacing(12),
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

export const route: string = "/participant/faq";
class FAQParticipant extends PureComponent<Props> {
  render() {
    const { t, classes } = this.props;
    return (
      <>
        <LogoBarParticipant backButton={true} hideLogo={true} />
        <ContentWrapper>
          <IntroductionForStep
            title={t(translations.pages.FAQ.participant.introHeadline)}
            description={t(translations.pages.FAQ.participant.introDescription, {
              email: '<a href="mailto:panel@quopinion.de">panel@quopinion.de</a>',
            })}
          />
          <Container centered={true}>
            <FAQParticipantContent blueBackgroundColor={false} />
            <div className={classes.contactWrapper}>
              <ContactParticipant />
            </div>
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(FAQParticipant);
