import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, /*Grid,*/ withStyles, WithStyles, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";

import { QuopinionTheme } from "../../../constants/Theme";
import i18next from "i18next";

import { translations } from "../../../constants/lang/translation";
import firstStepPictureL from "../../../assets/images/ResearcherLandingpage/stepPanel@3x.png";
import secondStepPictureL from "../../../assets/images/ResearcherLandingpage/stepQuestionnaire@3x.png";
import thirdStepPictureL from "../../../assets/images/ResearcherLandingpage/step_Results@3x.png";
import firstStepPictureM from "../../../assets/images/ResearcherLandingpage/stepPanelFullScreen@2x.png";
import secondStepPictureM from "../../../assets/images/ResearcherLandingpage/stepQuestionnaireFullScreen@2x.png";
import thirdStepPictureM from "../../../assets/images/ResearcherLandingpage/step_ResultsFullScreen@2x.png";
import firstStepPictureS from "../../../assets/images/ResearcherLandingpage/stepPanelFullScreen@1x.png";
import secondStepPictureS from "../../../assets/images/ResearcherLandingpage/stepQuestionnaireFullScreen@1x.png";
import thirdStepPictureS from "../../../assets/images/ResearcherLandingpage/step_ResultsFullScreen@1x.png";
import StepItem from "./StepItem";

import SwipeableViews from "react-swipeable-views";
//@ts-ignore
import { autoPlay } from "react-swipeable-views-utils";
import Pagination from "./Pagination";

interface OwnProps {
  backgroundColor: string;
}
interface State {
  index: number;
}
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      backgroundColor: (props: OwnProps) => props.backgroundColor,
      position: "relative",
    },
    paginationWrapper: {
      flexGrow: 1,
      backgroundColor: (props: OwnProps) => props.backgroundColor,
    },
    stepItemWrapper: {
      backgroundColor: (props: OwnProps) => props.backgroundColor,
    },
    container: {
      padding: `${theme.spacing(14)}px 0 0`,
      backgroundColor: (props: OwnProps) => props.backgroundColor,
    },
    headline: {
      fontFamily: "catamaranBold",
      fontSize: "32px",
      lineHeight: 1.25,
      fontWeight: 200,
      margin: "0 auto",
      width: "fit-content",
      textAlign: "center",
    },
  });
const carouselSteps = [
  {
    title: i18next.t(translations.pages.landingpage.researcher.carousel.first.titel),
    description: i18next.t(translations.pages.landingpage.researcher.carousel.first.description),
    imageS: firstStepPictureS,
    imageM: firstStepPictureM,
    imageL: firstStepPictureL,
  },
  {
    title: i18next.t(translations.pages.landingpage.researcher.carousel.second.titel),
    description: i18next.t(translations.pages.landingpage.researcher.carousel.second.description),
    imageS: secondStepPictureS,
    imageM: secondStepPictureM,
    imageL: secondStepPictureL,
  },
  {
    title: i18next.t(translations.pages.landingpage.researcher.carousel.third.titel),
    description: i18next.t(translations.pages.landingpage.researcher.carousel.third.description),
    imageS: thirdStepPictureS,
    imageM: thirdStepPictureM,
    imageL: thirdStepPictureL,
  },
];

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class SurveyStepCarousel extends PureComponent<Props, State> {
  state: State = {
    index: 0,
  };

  handleChangeIndex = (index: number) => {
    this.setState({
      index,
    });
  };

  render() {
    const { classes, t, backgroundColor } = this.props;
    const { index } = this.state;
    return (
      <div className={classes.container}>
        <Typography variant="h2" color="primary" className={classes.headline}>
          {t(translations.pages.landingpage.researcher.carousel.headline)}
        </Typography>
        <AutoPlaySwipeableViews
          index={index}
          onChangeIndex={this.handleChangeIndex}
          interval={5000}
          style={{ width: "100%", overflowX: "hidden" }}
        >
          {carouselSteps.map((step) => (
            <div key={step.title} className={classes.stepItemWrapper}>
              <StepItem stepObject={step} backgroundColor={backgroundColor} />
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <div className={classes.paginationWrapper}>
          <Pagination
            dots={carouselSteps.length}
            index={index}
            onChangeIndex={this.handleChangeIndex}
            backgroundColor={backgroundColor}
          />
        </div>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(SurveyStepCarousel);
