import {createStyles, Typography, useMediaQuery, withStyles, WithStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {translations} from "../../../constants/lang/translation";
import {DeleteBig, Logout, Mail} from "../../../assets/icon";
import Button from "../../../common/Layout/components/Button";
import * as React from "react";
import theme, {QuopinionTheme} from "../../../constants/Theme";
import {compose} from "recompose";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    headline: {
      marginBottom: theme.spacing(2),
    },
    buttonLine: {
      marginTop: theme.spacing(8),
      display: "flex",
      justifyContent: "space-between",
    },
    deleteModalStepsWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    deleteStepColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      maxWidth: "30%",
      minHeight: 116,
    },
    deleteIconText: {
      marginTop: theme.spacing(5),
      marginBottom: "auto",
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
    },
    deleteStepIcon: {
      display: "flex",
      marginBottom: theme.spacing(2.5),
    }
  });

interface OwnProps {
  closeDeleteModal: () => void;
  deleteParticipant: () => void;
  isLoadingDelete: boolean;
}

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation & RouteComponentProps;


const SelfDeleteModalContent: React.FC<Props> = ({
                                                   classes,
                                                   t,
                                                   closeDeleteModal,
                                                   deleteParticipant,
                                                   isLoadingDelete
                                                 }) => {
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const renderDektopTextLayout = () => {
    return (
      <div className={classes.deleteModalStepsWrapper}>
        <div className={classes.deleteStepColumn}>
          <div className={classes.deleteStepIcon}>
            <DeleteBig/>
          </div>

          <Typography
            className={classes.deleteIconText}>{t(translations.participant.deleteAccount.confirmDeleteText1)}</Typography>
        </div>

        <div className={classes.deleteStepColumn}>
          <div className={classes.deleteStepIcon}>
            <Logout/>
          </div>
          <Typography
            className={classes.deleteIconText}>{t(translations.participant.deleteAccount.confirmDeleteText2)}</Typography>
        </div>

        <div className={classes.deleteStepColumn}>
          <div className={classes.deleteStepIcon}>
            <Mail/>
          </div>
          <Typography
            className={classes.deleteIconText}>{t(translations.participant.deleteAccount.confirmDeleteText3)}</Typography>
        </div>

      </div>

    );
  };

  return (
    <>
      <Typography variant="h2" className={classes.headline}>
        {t(translations.participant.deleteAccount.confirmDeleteHeadline)}
      </Typography>
      <Typography variant="body2">
        {t(translations.participant.deleteAccount.confirmDeleteTitle)}
      </Typography>
      <br/>

      <br/>
      {desktop ? renderDektopTextLayout() : t(translations.participant.deleteAccount.confirmDeleteText)}
      <br/>
      <br/>
      <br/>


      <div className={classes.buttonLine}>
        <Button
          size="small"
          color="secondary"
          contained={true}
          onClick={closeDeleteModal}
          disabled={isLoadingDelete}
        >
          {t(translations.action.cancelDeleteSelfAccount)}
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={deleteParticipant}
          disabled={isLoadingDelete}
        >
          {t(translations.action.confirmDeleteSelfAccount)}
        </Button>
      </div>
    </>

  );
};

export const SelfDeleteContent = compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter
)(SelfDeleteModalContent);
