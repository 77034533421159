import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";

import QuopinionLogo from "../../assets/logo.png";
import SmallQuopinionLogo from "../../assets/logo_small.png";
import { QuopinionTheme } from "../../constants/Theme";
import NavLink from "./components/NavLink";
import { AccordionArrow } from "../../assets/icon";
import { RootState } from "../../modules";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { route as ParticipantDashboardRoute } from "../../pages/participant/Dashboard";
import { route as LandingPageParticipantRoute } from "../../pages/participant/LandingPage";

interface GenerateLinks {
  title: string;
  link: string;
  disabledIndicator?: string;
}

interface StateProps {
  visitedSteps: string[];
  authenticated: boolean;
}
interface DispatchProps {}

interface LogoBarProps {
  links?: GenerateLinks[];
  linkBorders?: boolean;
  backButton?: boolean;
  children?: React.ReactNode;
  hideLogo?: boolean;
  flexStartView?: boolean;
}

const APPBAR_HEIGHT = 75;

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      height: APPBAR_HEIGHT,
      boxShadow: "none",
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      justifyContent: (props: LogoBarProps) => (props.flexStartView ? "" : "space-between"),
      "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
        height: "auto",
      },
      "@media(max-width: 1024px)": {
        display: "block",
      },
    },
    linkComponent: {
      display: "flex",
      flexGrow: (props: LogoBarProps) => (props.flexStartView ? "unset" : 1),
      width: "100%",
    },
    spacer: theme.mixins.toolbar,
    logoWrapper: {
      borderRight: "none !important",
      height: "100%",
      paddingTop: theme.spacing(3.75),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
    logo: {
      height: "45px",
    },
    linkArea: {
      display: "flex",
      flexGrow: (props: LogoBarProps) => (props.flexStartView ? 1 : "unset"),
      height: 77,
      justifyContent: (props: LogoBarProps) => (props.flexStartView ? "flex-start" : ""),
      maxWidth: "757px",
    },
    backArrow: {
      padding: theme.spacing(2.5),
      borderColor: theme.palette.grey[200],
      borderStyle: (props: LogoBarProps) => (props.linkBorders ? "solid" : "none"),
      borderWidth: "1px 1px 0 1px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: APPBAR_HEIGHT + 1,
      width: APPBAR_HEIGHT + 1,
    },
    arrow: {
      transform: "rotate(-90deg)",
    },
    customComponent: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      padding: `${theme.spacing(2.5)} 0 ${theme.spacing(2.5)} ${theme.spacing(2.5)}`,
      borderLeft: (props: LogoBarProps) =>
        props.linkBorders ? `1px solid ${theme.palette.grey[200]}` : "none",
      flexGrow: (props: LogoBarProps) => (props.flexStartView ? 2 : "unset"),
      marginRight: theme.spacing(4),
      "@media (min-width: 768px)": {
        marginRight: theme.spacing(8),
      },
    },
    itemsWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: (props: LogoBarProps) => setJustifyContent(props),
    },
  });

const setJustifyContent = (props: LogoBarProps) => {
  if (props.flexStartView) {
    return "unset";
  }
  if (props.children && !props.links) {
    return "flex-end";
  }
  return "space-between";
};

type Props = LogoBarProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  RouteComponentProps;
class LogoBarParticipant extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      links,
      linkBorders,
      backButton,
      children,
      visitedSteps,
      hideLogo,
      authenticated,
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.linkComponent}>
          {backButton && (
            <div className={classes.backArrow} onClick={() => this.props.history.goBack()}>
              <AccordionArrow fill="#6D7278" height={24} width={24} className={classes.arrow} />
            </div>
          )}
          <div className={classes.logoWrapper}>
            {!hideLogo && (
              <Link to={authenticated ? ParticipantDashboardRoute : LandingPageParticipantRoute}>
                <img
                  className={classes.logo}
                  src={window.outerWidth < 1025 ? SmallQuopinionLogo : QuopinionLogo}
                  alt="Quopinion Logo"
                />
              </Link>
            )}
          </div>
          <div className={classes.itemsWrapper}>
            {links && (
              <div className={classes.linkArea}>
                {links.map((step) => (
                  <NavLink
                    link={step.link}
                    key={step.title}
                    title={step.title}
                    border={linkBorders ? true : false}
                    active={window.location.pathname.toLowerCase() === step.link.toLowerCase()}
                    disabled={
                      step.disabledIndicator
                        ? !visitedSteps.includes(step.disabledIndicator)
                        : false
                    }
                  />
                ))}
              </div>
            )}

            {children && <div className={classes.customComponent}>{children} </div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session, uiState }: RootState) => ({
  visitedSteps: uiState.visitedSteps,
  authenticated: Boolean(session.authenticated),
});

export default compose<Props, LogoBarProps>(
  withRouter,
  withStyles(styles),
  connect<StateProps, DispatchProps, LogoBarProps, RootState>(mapStateToProps)
)(LogoBarParticipant);
