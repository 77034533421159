import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import LoadingOverlay from "../common/LoadingOverlay";
import { verifyUser } from "../modules/session";
import { compose } from "recompose";
import { RootState } from "../modules";

enum Roles {
  RESEARCHER = "RESEARCHER",
  PARTICIPANT = "PARTICIPANT",
}

interface OwnProps {}
interface StateProps {
  authenticated?: boolean;
}
interface DispatchProps {
  verifyUser: (token: string) => Promise<{ roles: string[] }>;
}

type Props = OwnProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<{ role: string; token: string }>;

export const route = "/verifyEmailAddress/:token";
class AccountVerification extends React.Component<Props> {
  async componentDidMount() {
    const {
      verifyUser,
      match: {
        params: { token },
      },
      history,
    } = this.props;

    if (token) {
      try {
        const result = await verifyUser(token);

        if (result.roles.includes(Roles.RESEARCHER)) {
          history.push(`/market-research/login?emailVerified=true`);
        } else if (result.roles.includes(Roles.PARTICIPANT)) {
          history.push(`/participant/login?emailVerified=true`);
        }
      } catch (err) {
        history.push("/error/verifyAccount");
      }
    } else {
      history.push("/error/verifyAccount");
    }
  }

  render() {
    return <LoadingOverlay />;
  }
}

const mapStateToProps = ({ session }: RootState) => ({
  authenticated: session.authenticated,
});

export default compose<Props, OwnProps>(
  withRouter,
  connect(mapStateToProps, {
    verifyUser,
  })
)(AccountVerification);
