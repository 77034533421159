import React, { PureComponent, ReactNode } from "react";
import { Checkbox, Typography, createStyles, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";
import { Household, Gender } from "../../../entities/Panel";

interface OwnProps {
  label: string | React.ReactNode;
  answerValue: string | Household | Gender;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  // onChange: React.ChangeEvent<HTMLInputElement>;
  children?: ReactNode;
  className?: any;
  boldChecked?: boolean;
  smallLabel?: boolean;
  smallText?: boolean;
  headerAlignItems: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(0.5),
      padding: theme.spacing(4),
    },
    checkbox: {
      color: theme.palette.grey[100],
      marginRight: theme.spacing(4),
      "& svg": {
        fill: "currentColor",
        width: (props: OwnProps) => (props.smallLabel ? 24 : 32),
        height: (props: OwnProps) => (props.smallLabel ? 24 : 32),
      },
      "& span": {
        backgroundColor: "#fff",
        borderRadius: "4px",
        width: 18,
        height: 18,
      },
    },
    header: {
      display: "flex",
      alignItems: (props: OwnProps) => props.headerAlignItems,
      "& span": {
        padding: 0,
      },
    },
    smallLabelText: {
      fontSize: (props: OwnProps) => (props.smallText ? "14px" : "initial"),
      fontWeight: (props: OwnProps) => (props.smallText ? 400 : "initial"),
      lineHeight: (props: OwnProps) => (props.smallText ? 1.14 : "initial"),
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class CheckboxElement extends PureComponent<Props> {
  render() {
    const {
      label,
      answerValue,
      checked,
      classes,
      onChange,
      children,
      className,
      boldChecked,
      smallLabel,
    } = this.props;
    const classNames = `${classes.root} ${className}`;
    return (
      <div className={classNames} onClick={() => onChange}>
        <div className={classes.header}>
          <Checkbox
            value={answerValue}
            checked={checked}
            classes={{ root: classes.checkbox }}
            onChange={onChange}
          />
          <Typography
            variant={checked && boldChecked ? "h5" : smallLabel ? "subtitle1" : "h6"}
            color="inherit"
            className={classes.smallLabelText}
          >
            {label}
          </Typography>
        </div>
        {children}
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(CheckboxElement);
