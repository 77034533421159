import React, { PureComponent } from "react";
import { WithStyles, createStyles, withStyles, Typography } from "@material-ui/core";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";

import { primary, QuopinionTheme } from "../../../constants/Theme";
import FAQComponent from "../../../common/Layout/components/FAQComponent";
import { translations } from "../../../constants/lang/translation";
import i18next from "i18next";

interface OwnProps {
  blueBackgroundColor: boolean;
}

interface State {
  openFAQ: number;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      backgroundColor: (props: OwnProps) => (props.blueBackgroundColor ? "#f1f9ff" : "transparent"),
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(12)}px`,
      "@media (min-width: 600px)": {
        padding: `${theme.spacing(10)}px calc(16.66% + 40px) ${theme.spacing(14)}px`,
      },
      "@media (min-width: 1040px)": {
        padding: `${theme.spacing(10)}px 25% ${theme.spacing(24)}px`,
      },
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    headline: {
      fontFamily: "catamaranBold",
      fontSize: "32px",
      lineHeight: 1.25,
      fontWeight: 200,
      marginBottom: theme.spacing(6),
      color: primary,
    },
    questionBlockTitle: {
      marginTop: theme.spacing(6),
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const questions = [
  {
    title: i18next.t(translations.pages.FAQ.participant.title1),
    questions: [
      {
        question: i18next.t(translations.pages.FAQ.participant.question1),
        answer: i18next.t(translations.pages.FAQ.participant.answer1),
        indicator: 1,
      },
       {
        title: "",
        question: i18next.t(translations.pages.FAQ.participant.question2),
        answer: i18next.t(translations.pages.FAQ.participant.answer2, {
          break: "<br/><br/>",
          linkAndroid:
            '<a href="https://praxistipps.chip.de/android-lesezeichen-auf-dem-homescreen-ablegen-so-klappts_27640">https://praxistipps.chip.de/android-lesezeichen-auf-dem-homescreen-ablegen-so-klappts_27640</a>',
          linkIphone:
            '<a href="https://iphone-tricks.de/anleitung/2964-website-als-icon-am-homescreen">https://iphone-tricks.de/anleitung/2964-website-als-icon-am-homescreen</a>',
        }),
        indicator: 2,
      },
      {
        question: i18next.t(translations.pages.FAQ.participant.question3),
        answer: i18next.t(translations.pages.FAQ.participant.answer3, { break: "<br/><br/>"}),
        indicator: 3,
      },
    ],
    index: 1,
  },
  {
    title: i18next.t(translations.pages.FAQ.participant.title2),
    questions: [
      {
        question: i18next.t(translations.pages.FAQ.participant.question4),
        answer: i18next.t(translations.pages.FAQ.participant.answer4),
        indicator: 4,
      },
      {
        title: "",
        question: i18next.t(translations.pages.FAQ.participant.question5),
        answer: i18next.t(translations.pages.FAQ.participant.answer5),
        indicator: 5,
      },
      {
        title: "",
        question: i18next.t(translations.pages.FAQ.participant.question6),
        answer: i18next.t(translations.pages.FAQ.participant.answer6),
        indicator: 6,
      },
      {
        title: "",
        question: i18next.t(translations.pages.FAQ.participant.question7),
        answer: i18next.t(translations.pages.FAQ.participant.answer7),
        indicator: 7,
      },
      {
        title: "",
        question: i18next.t(translations.pages.FAQ.participant.question8),
        answer: i18next.t(translations.pages.FAQ.participant.answer8),
        indicator: 8,
      },
      {
        title: "",
        question: i18next.t(translations.pages.FAQ.participant.question9),
        answer: i18next.t(translations.pages.FAQ.participant.answer9),
        indicator: 9,
      },
    ],
    index: 2,
  },
  {
    title: i18next.t(translations.pages.FAQ.participant.title3),
    questions: [
      {
        question: i18next.t(translations.pages.FAQ.participant.question10),
        answer: i18next.t(translations.pages.FAQ.participant.answer10),
        indicator: 10,
      },
      {
        title: "",
        question: i18next.t(translations.pages.FAQ.participant.question11),
        answer: i18next.t(translations.pages.FAQ.participant.answer11),
        indicator: 11,
      },
    ],
    index: 3,
  },
];

class FAQParticipantContent extends PureComponent<Props, State> {
  state: State = {
    openFAQ: 0,
  };

  toggleQuestion = (value: number) => {
    const { openFAQ } = this.state;
    if (openFAQ === value) {
      this.setState({
        openFAQ: 0,
      });
    } else {
      this.setState({
        openFAQ: value,
      });
    }
  };

  render() {
    const { classes, t } = this.props;
    const { openFAQ } = this.state;
    return (
      <div className={classes.root}>
        <Typography variant="h3" className={classes.headline}>
          {t(translations.pages.FAQ.frequentlyAskedQuestions)}
        </Typography>
        {questions.map((questionObj) => (
          <div key={questionObj.index}>
            <Typography
              color="primary"
              variant={"subtitle2"}
              className={classes.questionBlockTitle}
            >
              {questionObj.title}
            </Typography>

            {questionObj.questions.map((question) => (
              <FAQComponent
                key={question.indicator}
                question={`${question.indicator}. ${question.question}`}
                answer={question.answer}
                open={openFAQ === question.indicator}
                handleClick={() => this.toggleQuestion(question.indicator)}
              />
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(FAQParticipantContent);
