import React, { ChangeEvent, PureComponent } from "react";
import {
  createStyles,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { QuopinionTheme } from "../../../../../constants/Theme";
import { compose } from "recompose";
import Question, { QuestionCondition, QuestionType } from "../../../../../entities/Question";
import AnswerOption, { FieldTypes } from "../../../../../entities/AnswerOption";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../../../constants/lang/translation";
import Button from "../../../../../common/Layout/components/Button";
import { FurtherFreetextAnswer, Minus, Plus, SkipLogic } from "../../../../../assets/icon";
import UploadedMediaItem from "../../../../../common/Layout/components/UploadedMediaItem";
import TextFieldWithMediaUpload from "../../../../../common/Layout/components/TextFieldWithMediaUpload";
import ID from "../../../../../entities/ID";
import Media from "../../../../../entities/Media";
import AddIcon from "@material-ui/icons/Add";

interface OwnProps {
  handleChange: (answer: AnswerOption) => void;
  deleteAnswer: (index: number) => void;
  addAnswer: () => void;
  addAdditionalFreetext: (index: number) => void;
  answerOption: AnswerOption; //so far type MatrixSubAnswer has same obligatory fields
  index: number;
  amountOfAnswers: number;
  otherQuestions: Question[];
  questionIndex: number;
  type: QuestionType;
  questionId: string;
  preventMediaUpload?: boolean;
  preventAlterationOfAnswerCount?: boolean;
  preventQuestionSkip?: boolean;
  preventAdditionalFreeText?: boolean;
  multiline: boolean;
  conditions: QuestionCondition[];
  addAdditionalNumericalField: (index: number) => void;
}

interface State {
  skipMenuOpen: boolean;
  uploadError: string | null;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    inputWrapper: {
      display: "flex",
      marginBottom: 20,
      alignItems: "center",
    },
    check: {
      border: "1px solid",
      borderColor: theme.palette.grey[100],
      borderRadius: (props: OwnProps) =>
        props.type === "singleChoice" || props.type === "matrix" ? "50%" : "4px",
      height: 24,
      marginRight: theme.spacing(3),
      width: 24,
    },
    input: {
      flexGrow: 1,
    },
    buttonWrapper: {
      display: "flex",
    },
    additionalFreeTextButton: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      width: 44,
      height: 41,
    },
    tooltipIcon: {
      padding: 0,
    },
    mediaUploads: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      paddingLeft: theme.spacing(9),
    },
    numberFieldIcon: {
      color: theme.palette.grey[400],
    },
    numberFieldIconSelected: {
      color: theme.palette.grey[50],
    },
    addIcon: {
      color: theme.palette.grey[400],
      height: 13,
      width: 13,
    },
    addIconSelected: {
      color: theme.palette.grey[50],
      height: 13,
      width: 13,
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class FormInput extends PureComponent<Props, State> {
  static MAX_ANSWER_AMOUNT = 12;
  state: State = {
    skipMenuOpen: false,
    uploadError: null,
  };
  anchorEl?: HTMLDivElement;

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.handleChange({ ...this.props.answerOption, label: event.target.value });
  };

  handleMediaUploaded = (media: Media) => {
    this.props.handleChange({ ...this.props.answerOption, media: [media] });
  };

  handleMediaUploadFail = (reason: string) => {
    this.setState({
      uploadError: reason,
    });
  };

  handleMediaRemove = () => {
    this.props.handleChange({ ...this.props.answerOption, media: [] });
  };

  handleSkipChange = (questionID?: ID) => {
    this.toggleSkipMenu();
    this.props.handleChange({
      ...this.props.answerOption,
      skipTo: questionID,
    });
  };

  toggleSkipMenu = (event?: any) => {
    this.setState({
      skipMenuOpen: !this.state.skipMenuOpen,
    });
  };

  renderSkipToIcon() {
    const { answerOption, otherQuestions, questionIndex, t, type, conditions } = this.props;
    if (type !== "singleChoice" || !otherQuestions.length) {
      return null;
    }

    const conditionInfo = otherQuestions.filter(
      (question: Question) => question.conditions.length > 0
    );

    return (
      <Tooltip
        title={<>{t(translations.questionnaire.toolTip.skipToLogic)}</>}
        aria-label="preview"
        placement={"left"}
      >
        <div ref={(ref: HTMLDivElement) => (this.anchorEl = ref)}>
          <Button
            contained={!!answerOption.skipTo}
            size="small"
            onClick={this.toggleSkipMenu}
            white={true}
            color="primary"
            icon={<SkipLogic fill={answerOption.skipTo ? "#fff" : "#6d7278"} />}
          />
          <Menu
            open={this.state.skipMenuOpen}
            onClose={this.toggleSkipMenu}
            anchorEl={this.anchorEl}
          >
            {!!answerOption.skipTo && (
              <MenuItem
                value={""}
                key={answerOption.id + "-none"}
                onClick={this.handleSkipChange.bind(this, undefined)}
              >
                {t(translations.questionnaire.singleChoice.skipTo.removeSkipTo)}
              </MenuItem>
            )}

            {conditionInfo.length === 0 &&
              conditions.length === 0 &&
              otherQuestions
                // only show following questions
                .filter((question, index) => index >= questionIndex)
                .map((question) => {
                  return (
                    <MenuItem
                      value={question.id}
                      key={answerOption.id + "-" + question.id}
                      onClick={this.handleSkipChange.bind(this, question.id)}
                      style={{
                        ...(question.id === answerOption.skipTo
                          ? { backgroundColor: "lightgrey" }
                          : {}),
                      }}
                    >
                      {t(translations.questionnaire.question)}{" "}
                      {otherQuestions.indexOf(question) + 2}: {question.text}
                    </MenuItem>
                  );
                })}

            <MenuItem
              value={"end"}
              key={answerOption.id + "-end"}
              onClick={this.handleSkipChange.bind(this, "end")}
              style={{
                ...(answerOption.skipTo === "end" ? { backgroundColor: "lightgrey" } : {}),
              }}
            >
              {t(translations.questionnaire.singleChoice.skipTo.endQuestionnaire)}
            </MenuItem>
          </Menu>
        </div>
      </Tooltip>
    );
  }

  render() {
    const {
      classes,
      index,
      deleteAnswer,
      addAnswer,
      addAdditionalFreetext,
      amountOfAnswers,
      answerOption,
      t,
      questionId,
      type,
      addAdditionalNumericalField,
    } = this.props;

    const additionalNumberField = answerOption.additionalFieldType === FieldTypes.NUMBER;

    return (
      <Grid container={true} direction="column" className={classes.inputWrapper}>
        <Grid container={true} alignItems="center">
          {type !== "ranking" && <div className={classes.check} />}
          <TextFieldWithMediaUpload
            id={`input-${index}`}
            value={answerOption.label}
            onBlur={this.handleInputChange}
            onMediaUploaded={this.handleMediaUploaded}
            placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
            className={classes.input}
            mediaReference={{ questionId, answerId: answerOption.id }}
            onMediaUploadFailed={this.handleMediaUploadFail}
            uploadError={this.state.uploadError}
            preventMediaUpload={this.props.preventMediaUpload}
            multiline={this.props.multiline}
          />
          {!this.props.preventAlterationOfAnswerCount && (
            <div className={classes.buttonWrapper}>
              {amountOfAnswers < FormInput.MAX_ANSWER_AMOUNT && (
                <Button
                  white={true}
                  color="primary"
                  icon={<Plus fill="#6d7278" />}
                  size="small"
                  onClick={addAnswer}
                />
              )}
              <Button
                white={true}
                color="primary"
                icon={amountOfAnswers <= 2 ? <Minus fill="#fff" /> : <Minus fill="#6d7278" />}
                size="small"
                onClick={deleteAnswer.bind(index)}
                disabled={amountOfAnswers <= 2}
              />
              {(type === "singleChoice" || type === "multipleChoice") && (
                <>
                  <Tooltip
                    title={
                      <>
                        {additionalNumberField
                          ? t(translations.questionnaire.toolTip.additionalFreeTextDeactivated)
                          : t(translations.questionnaire.toolTip.additionalFreeText)}
                      </>
                    }
                    aria-label="preview"
                  >
                    <div>
                      <Button
                        contained={answerOption.additionalFieldType === FieldTypes.TEXT}
                        white={true}
                        color="primary"
                        className={classes.additionalFreeTextButton}
                        icon={
                          <FurtherFreetextAnswer
                            fill={
                              answerOption.additionalFieldType === FieldTypes.TEXT
                                ? "#fff"
                                : "#9a9da1"
                            }
                          />
                        }
                        size="small"
                        onClick={() => {
                          addAdditionalFreetext(index);
                        }}
                        disabled={this.props.preventAdditionalFreeText || additionalNumberField}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={
                      <>
                        {answerOption.additionalFieldType === FieldTypes.TEXT
                          ? t(translations.questionnaire.toolTip.additionalNumberFieldDeactivated)
                          : t(translations.questionnaire.toolTip.additionalNumberField)}
                      </>
                    }
                    aria-label="preview"
                  >
                    <div>
                      <Button
                        contained={additionalNumberField}
                        white={true}
                        color="primary"
                        className={classes.additionalFreeTextButton}
                        column={true}
                        icon={
                          <>
                            <AddIcon
                              className={
                                additionalNumberField ? classes.addIconSelected : classes.addIcon
                              }
                            />
                            <Typography
                              variant="caption"
                              className={
                                additionalNumberField
                                  ? classes.numberFieldIconSelected
                                  : classes.numberFieldIcon
                              }
                            >
                              {t(translations.questionnaire.icon.numberSubtitel)}
                            </Typography>
                          </>
                        }
                        size="small"
                        onClick={() => {
                          addAdditionalNumericalField(index);
                        }}
                        disabled={
                          this.props.preventAdditionalFreeText ||
                          answerOption.additionalFieldType === FieldTypes.TEXT
                        }
                      />
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
          )}
          {!this.props.preventQuestionSkip && this.renderSkipToIcon()}

          {!!answerOption.media.length && (
            <div className={classes.mediaUploads}>
              <UploadedMediaItem
                media={answerOption.media[0]}
                onMediaRemove={this.handleMediaRemove}
              />
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles), withTranslation())(FormInput);
