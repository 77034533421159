import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  Modal,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";
import { ModalProps } from "@material-ui/core/Modal";
import { Cross, ArrowBack } from "../../../assets/icon";
import { DesktopWindows, PhoneAndroid } from "@material-ui/icons";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    modalStylesMobile: {
      top: 100,
      left: "calc(50% - (375px/2))",
      position: "absolute",
      width: (props: OwnProps) => (props.isPreview ? 375 : props.size ? props.size : "auto"),
      padding: theme.spacing(1.75),
      height: (props: OwnProps) => (props.isPreview ? 550 : "auto"),
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    modalStylesDesktop: {
      top: 100,
      left: "calc(50% - 45vw)",
      position: "absolute",
      width: "90vw",
      padding: theme.spacing(1.75),
      height: "calc(100vh - 200px)",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    switchButton: {
      position: "absolute",
      top: 31,
      right: 56,
      padding: theme.spacing(1),
    },
    switchIcon: {
      margin: theme.spacing(1),
    },
    hidden: {
      display: "none !important;",
    },
    closeIcon: {
      position: "absolute",
      top: (props: OwnProps) => (props.smallCross ? 22 : 31),
      right: (props: OwnProps) => (props.smallCross ? 12 : 23),
      padding: theme.spacing(1),
    },
    backIcon: {
      position: "absolute",
      top: 31,
      left: 23,
      padding: 0,
    },
    navigation: {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(-6),
    },
  });

interface OwnProps {
  children: React.ReactNode;
  open: boolean;
  onClose: (arg: any) => void;
  label: string;
  description: string;
  light?: boolean;
  isPreview?: boolean;
  showArrow?: boolean;
  size?: number | string;
  className?: any;
  smallCross?: boolean;
  hideCross?: boolean;
  showDesktopPreview?: boolean;
  allowDesktopSwitch?: boolean; // If set to true, an icon is displayed in the right corner for switching between desktop and mobile preview
  onSwitchModalMode?: () => void;
}

type Props = OwnProps & ModalProps & WithTranslation & WithStyles<typeof styles>;
const ModalComponent: React.FunctionComponent<Props> = ({
  t,
  classes,
  children,
  open,
  onClose,
  label,
  description,
  light = false,
  showArrow,
  className,
  smallCross = false,
  hideCross = false,
  isPreview = false,
  showDesktopPreview = false,
  allowDesktopSwitch = false,
  onSwitchModalMode,
}) => {
  const crossSizes = () => (smallCross ? 24 : 32);

  const classNames = showDesktopPreview
    ? `${classes.modalStylesDesktop} ${className}`
    : `${classes.modalStylesMobile} ${className}`;

  return (
    <Modal
      aria-labelledby={label}
      aria-describedby={description}
      open={open}
      onClose={onClose}
      disableAutoFocus={true}
    >
      <div className={classNames}>
        <Grid container={true} justify="space-between" className={classes.navigation}>
          {showArrow && (
            <IconButton onClick={onClose} className={classes.backIcon}>
              <ArrowBack fill={light ? "#000" : "#fff"} height={32} width={32} />
            </IconButton>
          )}
          {isPreview && allowDesktopSwitch && (
            <Tooltip
              title={(showDesktopPreview
                ? t(translations.questionnaire.toolTip.toggleMobilePreview)
                : t(translations.questionnaire.toolTip.toggleDesktopPreview)
              ).toString()}
              aria-label="preview"
            >
              <IconButton className={classes.switchButton} onClick={onSwitchModalMode}>
                <PhoneAndroid
                  style={{ color: "white" }}
                  className={showDesktopPreview ? classes.switchIcon : classes.hidden}
                />
                <DesktopWindows
                  style={{ color: "white" }}
                  className={showDesktopPreview ? classes.hidden : classes.switchIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!hideCross && (
            <IconButton onClick={onClose} className={classes.closeIcon}>
              <Cross fill={light ? "#000" : "#fff"} width={crossSizes()} height={crossSizes()} />
            </IconButton>
          )}
        </Grid>
        {children}
      </div>
    </Modal>
  );
};

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(ModalComponent);
