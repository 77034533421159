import Media from "./Media";
import ID from "./ID";
import {v4 as uuidv4} from "uuid";

export enum FieldTypes {
  NONE = "NONE",
  TEXT = "TEXT",
  NUMBER = "NUMBER",
}

/**
 * Domain entity class AnswerOption
 * which represents an answer option for a single or multiple choice question
 */
export default class AnswerOption {
  readonly id: ID;
  label: string;
  media: Media[];
  additionalFieldType: FieldTypes;
  skipTo?: ID;

  constructor(obj: any = {}) {
    //@ts-ignore
    this.id = obj.id || uuidv4();
    this.label = obj.label || "";
    this.media = obj.media || [];
    this.additionalFieldType = obj.additionalFieldType ? obj.additionalFieldType : FieldTypes.NONE;
    this.skipTo = obj.skipTo;
  }
}
