import React, { PureComponent } from "react";
import {
  createStyles,
  Typography,
  withStyles,
  WithStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import { compose, shallowEqual } from "recompose";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import { QuopinionTheme } from "../../../constants/Theme";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";
import * as Yup from "yup";
import { Formik } from "formik";
import Button from "../../../common/Layout/components/Button";
import Container from "../../../common/Layout/Container";
import { requestPasswordReset } from "../../../modules/session";
import { connect } from "react-redux";
import { SnackbarComponent } from "../../../common/Layout/components/Snackbar";

interface OwnProps {}

interface DispatchProps {
  requestPasswordReset: (email: string) => Promise<void>;
}

interface State {
  showNotification: boolean;
  showError: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      "@media(min-width: 993px)": {
        padding: `${theme.spacing(10)}px 0 `,
      },
    },
    description: {
      margin: `${theme.spacing(4)}px 0`,
      maxWidth: 500,
    },
    input: {
      width: "100%",
      maxWidth: 350,
      margin: `${theme.spacing(6)}px 0 ${theme.spacing(7)}px`,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      "@media (min-width: 600px)": {
        justifyContent: "flex-end",
      },
    },
    emailWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(6),
    },
    notificationWrapper: {
      marginTop: theme.spacing(12),
      opacity: 0.9,
    },
    email: {
      "& a": {
        color: "inherit",
      },
    },
    backgroundHP: {
      opacity: 0,
      position: "absolute",
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      zIndex: -1
    },
  });

type Props = OwnProps & DispatchProps & WithStyles<typeof styles> & WithTranslation;

export const route = "/market-research/password-lost";

class ForgottenPasswordResearcher extends PureComponent<Props, State> {

  readonly initialValues = {
    email: "",
    name: "",
    message: ""
  };

  state = {
    showNotification: false,
    showError: false
  };

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      email: Yup.string()
        .trim()
        .email(t(translations.validation.login.emailNotValid))
        .required(t(translations.validation.login.emailRequired)),
    });
  };

  toggleNotification = () => {
    this.setState({
      showNotification: true,
    });
  };

  toggleError = () => {
    this.setState({
      showError: !this.state.showError,
    });
  };

  render() {
    const { classes, t } = this.props;
    const { showNotification, showError } = this.state;

    return (
      <>
        <LogoBarResearcher />
        {showError && (
          <SnackbarComponent
            title={t(translations.general.anErrorOccured)}
            message={t(translations.general.tryAgainResearcher)}
            type="error"
            onClose={() => {
              this.toggleError();
            }}
          />
        )}
        <ContentWrapper>
          <Container centered={true}>
            <Grid container={true} className={classes.wrapper}>
              <Grid item={true} xs={12} sm={6}>
                <Typography variant="h2" color="secondary">
                  {t(translations.pages.forgottenPassword.researcher.headline)}
                </Typography>
                <Typography variant="subtitle1" className={classes.description}>
                  {t(translations.pages.forgottenPassword.researcher.description)}
                </Typography>

                <Typography variant="subtitle1">
                  {t(translations.pages.forgottenPassword.researcher.service)}
                </Typography>
                <Typography
                  variant="subtitle1"
                  dangerouslySetInnerHTML={{
                    __html: '<a href="mailto:support@quopinion.de">support@quopinion.de</a>',
                  }}
                  className={classes.email}
                />
              </Grid>
              <Grid item={true} xs={12} sm={6}>
                <div className={classes.emailWrapper}>
                  <Formik
                    initialValues={this.initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      this.props
                        .requestPasswordReset(values.email)
                        .then(() => {
                          setSubmitting(false);
                          this.toggleNotification();
                          resetForm();
                        })
                        .catch(() => {
                          setSubmitting(false);
                          this.toggleError();
                        });
                    }}
                  >
                    {(formikProps) => {
                      const {
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                        errors,
                        touched,
                        isSubmitting,
                      } = formikProps;
                      return (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            {...formikProps}
                            value={values.email}
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email ? `${errors.email}` : ""}
                            placeholder={t(translations.register.loginDetails.eMail)}
                            className={classes.input}
                          />
                          <div className={classes.backgroundHP}>
                            <label htmlFor="name"/>
                            <input autoComplete="off" type="text" id="name" name="name" value={values.name}
                                   onChange={handleChange("name")} placeholder="Your name here"/>
                            <label htmlFor="message"/>
                            <input autoComplete="off" type="message" id="message" name="message" value={values.message}
                                   onChange={handleChange("message")} placeholder="Your message here"/>
                          </div>
                          <div className={classes.buttonWrapper}>
                            <Button
                              contained={true}
                              color="secondary"
                              size="big"
                              onClick={handleSubmit}
                              disabled={
                                shallowEqual(this.initialValues, values) ||
                                Boolean(errors.email) ||
                                isSubmitting
                              }
                              type="submit"
                            >
                              {t(translations.action.send)}
                            </Button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </Grid>
              <div className={classes.notificationWrapper}>
                {showNotification && (
                  <Typography variant="body1">
                    {t(translations.pages.forgottenPassword.researcher.notification)}
                    <br />
                    {t(translations.pages.forgottenPassword.researcher.spamCheck)}
                  </Typography>
                )}
              </div>
            </Grid>
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  connect(undefined, { requestPasswordReset }),
  withStyles(styles),
  withTranslation()
)(ForgottenPasswordResearcher);
