import * as React from "react";
import Question, { QuestionTypes } from "../../../entities/Question";
import SingleChoice from "./QuestionTypes/SingleChoice";
import { createStyles, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { QuopinionTheme, blueGradient, secondary } from "../../../constants/Theme";
import FreeText from "./QuestionTypes/FreeText";
import MultipleChoice from "./QuestionTypes/MultipleChoice";
import StarRating from "./QuestionTypes/StarRating";
import MediaDisplay from "../../../common/Preview/components/MediaDisplay";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { setPreviewAnswers } from "../../../modules/surveyPreview";
import Questionnaire from "../../../entities/Questionnaire";
import SurveyAnswers, {
  AdditionalNumberAnswer,
  Answer,
  ChoiceAnswer,
  MatrixAnswer,
  RankingAnswer,
  RatingAnswer,
  TextAnswer,
} from "../../../entities/SurveyAnswers";
import Button from "../../../common/Layout/components/Button";
import { translations } from "../../../constants/lang/translation";
import { withTranslation, WithTranslation } from "react-i18next";
import Scale from "./QuestionTypes/Scale";
import ID from "../../../entities/ID";
import { AnsweredAttribute } from "../../../services/Accounts";
import { RouteComponentProps, withRouter } from "react-router";
import { removeAttribute } from "../../../modules/attribute";
import Matrix from "./QuestionTypes/Matrix";
import Password from "./QuestionTypes/Password";
import Ranking from "./QuestionTypes/Ranking";
import { FieldTypes } from "../../../entities/AnswerOption";
import { checkNumberValidation } from "../../../util/numberTwoDigitsValidation";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      height: "100%",
      overflow: "scroll",
    },
    rootPreview: {
      height: "100%",
      overflowY: "auto",
    },
    header: {
      background: blueGradient,
      paddingTop: theme.spacing(18),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(6),
      paddingRight: theme.spacing(4),
      flexGrow: 1,
      color: theme.palette.common.white,
    },
    text: {
      overflowWrap: "break-word",
    },
    linkText: {
      overflowWrap: "break-word",
      marginTop: theme.spacing(8),
    },
    link: {
      color: secondary,
    },
    body: {
      backgroundColor: theme.palette.grey[100],
      paddingTop: theme.spacing(8),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(10),
      paddingRight: theme.spacing(4),
      flexGrow: 1,
    },
    button: {
      borderRadius: 0,
      paddingTop: theme.spacing(5.25),
    },
    buttonContainer: {
      flexGrow: 1,
      height: 0,
    },
    disabledNotice: {
      textAlign: "center",
      padding: theme.spacing(3),
      color: secondary,
      backgroundColor: theme.palette.grey[100],
    },
  });

interface OwnProps {
  question: Question;
  onNextClick: (answer?: AnsweredAttribute | any, skipToID?: ID | any) => void;
  currentQuestionnaireStep: number;
  totalQuestionnaireSteps: number;
  attributeId?: ID;
  setSkipQuestionId?: (id?: ID) => void;
  error?: boolean;
  isPreview?: boolean;
  showDesktopPreview?: boolean;
}

interface DispatchProps {
  setPreviewAnswers(surveyAnswers: SurveyAnswers): void;
  removeAttribute(id: string): void;
}

interface StateProps {
  questionnaire?: Questionnaire;
  screenerAnswers: AnsweredAttribute[];
  isLoading: boolean;
  previewAnswers?: SurveyAnswers;
}

interface State {}

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{ questionId: string }>;

class QuestionContainer extends React.PureComponent<Props, State> {
  componentDidMount = () => {
    const { question, setSkipQuestionId, previewAnswers } = this.props;

    const currentAnswer = previewAnswers && previewAnswers.get(question.id);

    if (
      currentAnswer &&
      (currentAnswer as ChoiceAnswer).selectedOptions &&
      (currentAnswer as ChoiceAnswer).selectedOptions.length
    ) {
      const answerId = (currentAnswer as ChoiceAnswer).selectedOptions[0];
      const answer = question.body.answerOptions.find((option) => option.id === answerId);
      const skipTo = answer && answer.skipTo;
      const additionalFreeText = answer && answer.additionalFieldType === FieldTypes.TEXT;
      const additionalNumberField = answer && answer.additionalFieldType === FieldTypes.NUMBER;

      if (setSkipQuestionId && skipTo) {
        setSkipQuestionId(skipTo);
      }
      if (
        additionalFreeText &&
        (currentAnswer as ChoiceAnswer) &&
        (currentAnswer as ChoiceAnswer).selectedOptionsFreeText === undefined
      ) {
        if ((currentAnswer as ChoiceAnswer).selectedOptions.length <= 1) {
          (currentAnswer as ChoiceAnswer).selectedOptionsFreeText = [
            {
              answerOptionID: (answer && answer.id) || "",
              freeText: "",
            },
          ];
        }
      }
      if (
        additionalNumberField &&
        (currentAnswer as ChoiceAnswer) &&
        (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput === undefined
      ) {
        if ((currentAnswer as ChoiceAnswer).selectedOptions.length <= 1) {
          (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput = [
            {
              answerOptionID: (answer && answer.id) || "",
              numberEntry: null,
            },
          ];
        }
      }
    }
  };

  onUserAnswer = (questionID: ID, answer?: Answer, skipToID?: ID) => {
    const { setPreviewAnswers, setSkipQuestionId, previewAnswers } = this.props;
    if (previewAnswers) {
      if (answer) {
        setPreviewAnswers(previewAnswers.set(answer));
        if (setSkipQuestionId) {
          setSkipQuestionId(skipToID);
        }
      } else {
        setPreviewAnswers(previewAnswers.delete(questionID));
      }
    }
  };

  renderQuestion = (question: Question) => {
    const { type } = question;
    const { previewAnswers, showDesktopPreview } = this.props;
    let currentAnswer:
      | TextAnswer
      | RatingAnswer
      | ChoiceAnswer
      | MatrixAnswer
      | RankingAnswer
      | undefined;
    currentAnswer =
      previewAnswers &&
      (previewAnswers.get(question.id) as
        | ChoiceAnswer
        | TextAnswer
        | RatingAnswer
        | MatrixAnswer
        | RankingAnswer
        | undefined);

    let passwordConsistency;
    if (
      question.type === "password" &&
      (currentAnswer as TextAnswer) &&
      question.body.passwords.length >= 1
    ) {
      passwordConsistency = question.body.passwords.filter(
        (passwordOption) => passwordOption === (currentAnswer as TextAnswer).enteredText
      );
    }

    switch (type) {
      case QuestionTypes.FREETEXT:
        return (
          <FreeText
            question={question}
            currentAnswer={currentAnswer && (currentAnswer as TextAnswer)}
            handleUserAnswer={this.onUserAnswer}
          />
        );
      case QuestionTypes.MULTIPLECHOICE:
        return (
          <MultipleChoice
            question={question}
            currentAnswer={currentAnswer && (currentAnswer as ChoiceAnswer)}
            handleUserAnswer={this.onUserAnswer}
            isScreener={false}
          />
        );
      case QuestionTypes.STEPRATING:
        return (
          <Scale
            question={question}
            currentAnswer={currentAnswer && (currentAnswer as RatingAnswer)}
            handleUserAnswer={this.onUserAnswer}
          />
        );
      case QuestionTypes.SINGLECHOICE:
        return (
          <SingleChoice
            question={question}
            currentAnswer={currentAnswer && (currentAnswer as ChoiceAnswer)}
            handleUserAnswer={this.onUserAnswer}
            isScreener={false}
          />
        );
      case QuestionTypes.STARRATING:
        return (
          <StarRating
            currentAnswer={currentAnswer && (currentAnswer as RatingAnswer)}
            handleUserAnswer={this.onUserAnswer}
            question={question}
          />
        );
      case QuestionTypes.MATRIX:
        return (
          <Matrix
            currentAnswer={currentAnswer && (currentAnswer as MatrixAnswer)}
            handleUserAnswer={this.onUserAnswer}
            question={question}
            previewMobileView={!showDesktopPreview}
          />
        );
      case QuestionTypes.PASSWORD:
        return (
          <Password
            question={question}
            currentAnswer={currentAnswer && (currentAnswer as TextAnswer)}
            handleUserAnswer={this.onUserAnswer}
            wrongPassword={!passwordConsistency}
          />
        );
      case QuestionTypes.RANKING:
        return (
          <Ranking
            question={question}
            currentAnswer={currentAnswer && (currentAnswer as RankingAnswer)}
            handleUserAnswer={this.onUserAnswer}
          />
        );
      default:
        return <div />;
    }
  };

  buttonFunction = (answer?: AnsweredAttribute | ID) => {
    const { onNextClick } = this.props;

    onNextClick();
  };

  renderButton() {
    const {
      question,
      classes,
      t,
      currentQuestionnaireStep,
      totalQuestionnaireSteps,
      error,
      previewAnswers,
    } = this.props;

    const isLastQuestion = currentQuestionnaireStep === totalQuestionnaireSteps;
    let currentAnswer:
      | TextAnswer
      | RatingAnswer
      | ChoiceAnswer
      | MatrixAnswer
      | RankingAnswer
      | undefined;
    currentAnswer = previewAnswers!.get(question.id) as
      | ChoiceAnswer
      | TextAnswer
      | RatingAnswer
      | MatrixAnswer
      | RankingAnswer
      | undefined;
    //for disabling button to go to next question when no entered text
    let freetextRequired: boolean = false;

    //for disabling button to go to next question when no entered number
    let numberRequired: boolean = false;
    //for disabling button when nr requirements not met
    let numberNotValid: boolean = false;

    if ((currentAnswer as ChoiceAnswer) && currentAnswer !== undefined) {
      if ((currentAnswer as ChoiceAnswer).selectedOptionsFreeText !== undefined) {
        //@ts-ignore
        freetextRequired = (currentAnswer as ChoiceAnswer).selectedOptionsFreeText.find(
          (item) => item.freeText.length <= 0
        );
      }

      if ((currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput !== undefined) {
        //@ts-ignore
        numberRequired = (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput.find(
          (item: AdditionalNumberAnswer) => item.numberEntry === null
        );

        //@ts-ignore
        numberNotValid = (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput.filter(
          (item: AdditionalNumberAnswer) =>
            item.numberEntry !== null && checkNumberValidation(item.numberEntry.toString())
        ).length;
      }
    }
    let check;
    if ((currentAnswer as MatrixAnswer) && (currentAnswer as MatrixAnswer).type === "matrix") {
      const subQuestionsCount = question.body.subQuestions.length;
      check = (currentAnswer as MatrixAnswer).subAnswers.length === subQuestionsCount;
    }

    let passwordConsistency;
    if (
      question.type === "password" &&
      (currentAnswer as TextAnswer) &&
      question.body.passwords.length >= 1
    ) {
      passwordConsistency = question.body.passwords.find(
        (passwordOption) => passwordOption === (currentAnswer as TextAnswer).enteredText
      );
    }

    let isDisabled =
      (!currentAnswer && question.type !== QuestionTypes.INSTRUCTION) ||
      error ||
      freetextRequired ||
      numberRequired ||
      numberNotValid ||
      (question.type === QuestionTypes.MATRIX && !check) ||
      (question.type === QuestionTypes.PASSWORD && !passwordConsistency);

    let notFullyAnswered = isDisabled && currentAnswer !== undefined;

    return (
      <div className={classes.buttonContainer}>
        {notFullyAnswered && (
          <div className={classes.disabledNotice}>
            {t(translations.questionnaire.validation.allQuestionsAnswered)}
          </div>
        )}
        <Button
          color="secondary"
          contained={true}
          fullWidth={true}
          size="big"
          className={classes.button}
          onClick={() => this.buttonFunction()}
          disabled={isDisabled}
          id={"next-or-finalize-survey"}
        >
          {t(translations.action[isLastQuestion ? "complete" : "next"])}
        </Button>
      </div>
    );
  }

  render() {
    const {
      question,
      classes,
      currentQuestionnaireStep,
      totalQuestionnaireSteps,
      isPreview,
      previewAnswers,
    } = this.props;
    const { type, body, media } = question;

    if (previewAnswers) {
      return (
        <Grid
          container={true}
          direction="column"
          className={isPreview ? classes.rootPreview : classes.root}
          wrap="nowrap"
        >
          <div className={classes.header}>
            <Typography variant="body1" color="secondary">
              {currentQuestionnaireStep}/ {totalQuestionnaireSteps}
            </Typography>
            {type !== QuestionTypes.INSTRUCTION && (
              <Typography variant="h2" className={classes.text}>
                {question.text}
              </Typography>
            )}
            {type === QuestionTypes.INSTRUCTION && (
              <Typography variant="h2" className={classes.text}>
                {body.text}
              </Typography>
            )}
            {type === QuestionTypes.INSTRUCTION &&
              body.link !== null &&
              body.link &&
              body.link.length > 0 && (
                <a
                  href={body.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  <Typography variant="h2" className={classes.linkText}>
                    {body.link}
                  </Typography>
                </a>
              )}
            {media.length > 0 && <MediaDisplay media={media} />}
          </div>
          <div className={classes.body}>{this.renderQuestion(question)}</div>
          {this.renderButton()}
        </Grid>
      );
    } else {
      console.warn("no started questionnaire available, this shouldn't happen");
      return null;
    }
  }
}

const mapDispatchToProps = {
  setPreviewAnswers,
  removeAttribute,
};
const mapStateToProps = ({ participation, attribute, surveyPreview }: RootState) => ({
  questionnaire: participation.currentSurvey && participation.currentSurvey.questionnaire,
  screenerAnswers: [...attribute.screenerAnswers, ...attribute.answeredDailyScreeners!],
  isLoading: attribute.isLoading,
  previewAnswers: surveyPreview.surveyAnswers,
});

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  withRouter,
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(QuestionContainer);
