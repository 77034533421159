import React, { PureComponent } from "react";
import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";
import { Typography, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { translations } from "../../constants/lang/translation";
import { primary, QuopinionTheme } from "../../constants/Theme";
import { UspRow } from "./UspRow";

interface OwnProps {
  uspArray: {
    title: string;
    subtitle: string;
    number: string;
    color: string;
    children: React.ReactNode;
  }[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      padding: `${theme.spacing(12)}px ${theme.spacing(4)}px`,
      "@media(min-width: 600px)": {
        padding: `${theme.spacing(24)}px ${theme.spacing(10)}px`,
      },
      backgroundColor: "#fff",
      alignItems: "center",
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
      "@media (min-width: 699px)": {},
      paddingLeft: theme.spacing(5.5),
      paddingRight: theme.spacing(5.5),
    },
    headline: {
      color: primary,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontFamily: "catamaranBold",
      fontSize: "32px",
      fontWeight: theme.spacing(50),
      lineHeight: 1.25,
      marginBottom: theme.spacing(5),
      maxWidth: theme.spacing(135),
      "@media(min-width: 600px)": {
        marginBottom: "unset",
        maxWidth: "unset",
      },
      "@media(min-width: 1024px)": {
        fontSize: "40px",
        fontWeight: 200,
        lineHeight: 1.1,
      },
      letterSpacing: "normal",
    },
    subHeadline: {
      display: "flex",
      justifyContent: "center",
      color: theme.palette.grey[400],
      textAlign: "center",
      marginTop: theme.spacing(2),
      maxWidth: theme.spacing(62.5),
      "@media(min-width:700px)": {
        marginRight: theme.spacing(20.5),
        marginLeft: theme.spacing(20.5),
        maxWidth: theme.spacing(151),
      },
    },
    subHeadlineWrapper: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "unset",
      "@media(min-width: 600px)": {
        marginBottom: theme.spacing(15),
      },
    },
    image: {
      width: theme.spacing(43.75),
      height: theme.spacing(32.5),
      "@media (min-width: 768px)": {
        width: theme.spacing(43.75),
        height: theme.spacing(32.5),
      },
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

class UspOverview extends PureComponent<Props> {
  render() {
    const { t, classes, uspArray } = this.props;

    return (
      <div className={classes.root} id="participant-ups">
        <Typography className={classes.headline}>
          {t(translations.pages.landingpage.participant.usp.headline)}
        </Typography>
        <div className={classes.subHeadlineWrapper}>
          <Typography variant="body1" className={classes.subHeadline}>
            {t(translations.pages.landingpage.participant.usp.subheadline)}
          </Typography>
        </div>
        <div className={classes.wrapper}>
          {uspArray.map((uspItem) => (
            <UspRow
              key={uspItem.number}
              title={uspItem.title}
              subtitle={uspItem.subtitle}
              number={uspItem.number}
              color={uspItem.color}
              children={uspItem.children}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(UspOverview);
