import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";

import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import ImprintContent from "../../../common/Imprint/ImprintContent";

interface OwnProps {}
const styles = (theme: QuopinionTheme) =>
  createStyles({
    faqItem: {
      marginBottom: theme.spacing(13),
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

export const route: string = "/participant/imprint";
class ImprintParticipant extends PureComponent<Props> {
  render() {
    return (
      <>
        <LogoBarParticipant backButton={true} hideLogo={true} />
        <ContentWrapper>
          <Container centered={true}>
            <ImprintContent />
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(ImprintParticipant);
