import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ReactImageMagnify from "react-image-magnify";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { QuopinionTheme } from "../../../constants/Theme";
import { createStyles } from "@material-ui/core";
import Media from "../../../entities/Media";
import { compose } from "recompose";

interface OwnProps {
  answerOption?: boolean;
  withoutMediaMargin?: boolean;
  media: Media;
}
const styles = (theme: QuopinionTheme) =>
  createStyles({
    image: {
      width: (props: OwnProps) => (props.answerOption ? 260 : 320),
      marginLeft: (props: OwnProps) =>
        props.answerOption
          ? props.withoutMediaMargin
            ? theme.spacing(0)
            : theme.spacing(9)
          : theme.spacing(0),
      "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
        marginLeft: theme.spacing(3),
        width: 260,
      },
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

const ImageWithOverZoom: React.FC<Props> = ({ classes, media }) => {
  const mediaName = media.url.substr(media.url.lastIndexOf("/") + 1);
  const imageProps = {
    smallImage: {
      alt: `image: ${mediaName}`,
      src: media.url,
      width: 320,
      height: 260,
      isFluidWidth: true,
      isEnlargedImagePortalEnabledForTouch: true,
    },
    largeImage: {
      isActivatedOnTouch: true,
      src: media.url,
      width: 1800,
      height: 1000,
    },
    enlargedImagePosition: "over",
  };
  return (
    <div className={classes.image}>
      <ReactImageMagnify {...imageProps} />
    </div>
  );
};

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(ImageWithOverZoom);
