import ID from "./ID";
import Question from "./Question";

/**
 * Domain entity class Questionnaire
 * which represents a set of questions that is used in Survey
 */
export default class Questionnaire {
  readonly id: ID;
  questions: Question[];
  title: string;
  createdAt: number;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.title = obj.title;
    this.createdAt = obj.createdAt;
    this.questions =
      obj.questions && obj.questions instanceof Array && obj.questions.length > 0
        ? obj.questions.map((question: any) => new Question(question))
        : [];
  }

  toDataJson() {
    return {
      ...this,
      questions: this.questions.map((question) => new Question(question).toDataJson()),
    };
  }
}
