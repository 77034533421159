import React, { PureComponent } from "react";
import { compose } from "recompose";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import { WithStyles, createStyles, withStyles } from "@material-ui/styles";
import { QuopinionTheme } from "../../../constants/Theme";
import RegistrationStart from "./RegistrationStart";
import RegistrationOpinion from "./RegistrationOpinion";
import RegistrationAttribute from "./RegistrationAttribute";
import { withRouter, RouteComponentProps } from "react-router";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import { connect } from "react-redux";
import { fetchRegisterConfiguration } from "../../../modules/session";
import { RootState } from "../../../modules";
import Container from "../../../common/Layout/Container";

interface OwnProps {}

interface StateProps {
  registrationConfiguration: boolean;
}

interface DispatchProps {
  fetchRegisterConfiguration: typeof fetchRegisterConfiguration;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    contentWrapper: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    },
  });

type Props = OwnProps &
  WithStyles<typeof styles> &
  DispatchProps &
  StateProps &
  RouteComponentProps<{ step: string }>;

export const route = "/participant/registration";
export const stepRoute = "/participant/registration/:step";

class ParticipantRegisterPage extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    if (!this.props.registrationConfiguration) {
      this.props.fetchRegisterConfiguration();
    }
  }

  renderStep() {
    switch (this.props.match.params.step) {
      case "opinion":
        return <RegistrationOpinion />;
      case "attributes":
        return this.props.registrationConfiguration && <RegistrationAttribute />;
      default:
        return <RegistrationStart />;
    }
  }

  render() {
    const { location } = this.props;
    return (
      <>
        <LogoBarParticipant
          hideLogo={
            location.pathname.includes("opinion") || location.pathname.includes("attributes")
          }
          backButton={
            location.pathname.includes("opinion" || "attributes") ||
            location.pathname.includes("attributes")
          }
        />
        <ContentWrapper>
          <Container centered={true}>
            {/* Render steps here */}
            {this.renderStep()}
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  connect(
    ({ session }: RootState) => ({
      registrationConfiguration: Boolean(session.registrationConfiguration),
    }),
    { fetchRegisterConfiguration }
  ),
  withRouter,
  withStyles(styles)
)(ParticipantRegisterPage);
