import React, { PureComponent } from "react";
import { createStyles, WithStyles, withStyles, IconButton } from "@material-ui/core";
import { compose } from "recompose";
import { primary, QuopinionTheme } from "../../../constants/Theme";
import { Delete } from "../../../assets/icon";
import Media from "../../../entities/Media";
import VideoPlayer from "../../Preview/components/VideoPlayer";
import AudioPlayer from "../../Preview/components/AudioPlayer";

interface OwnProps {
  media: Media;
  onMediaRemove?: () => void;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(2),
      color: theme.palette.grey[400],
      width: "100%",
    },
    mediaImage: {
      width: 40,
      height: 24,
      objectFit: "contain",
      marginRight: theme.spacing(2),
    },
    mediaName: {
      color: primary,
      minWidth: theme.spacing(75),
      maxWidth: theme.spacing(75),
    },
    mediaVideo: {
      width: 140,
    },
    deleteIcon: {
      marginLeft: theme.spacing(2),
    },
    placeholderImage: {
      width: 40,
      height: 24,
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#fff",
      borderRadius: 4,
      padding: theme.spacing(1),
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class UploadedMediaItem extends PureComponent<Props> {
  render() {
    const { media, classes, onMediaRemove } = this.props;
    const mediaName = media.url.substr(media.url.lastIndexOf("/") + 1);

    return (
      <div>
        <div className={classes.wrapper}>
          {media.mimeType.includes("video") && (
            <div className={classes.mediaVideo}>
              <VideoPlayer video={media.url} type={media.mimeType} />
            </div>
          )}
          {media.mimeType.includes("audio") && (
            <AudioPlayer audio={media.url} type={media.mimeType} />
          )}

          {media.mimeType.includes("image") && (
            <a href={media.url}>
              <img src={media.url} className={classes.mediaImage} alt={mediaName} />
            </a>
          )}
          {onMediaRemove && (
            <IconButton onClick={onMediaRemove} className={classes.deleteIcon}>
              <Delete fill="#6D7278" />
            </IconButton>
          )}
        </div>
        {/*so far not included as only generated Id shown*/}
        {/*<Typography className={classes.mediaName}>{mediaName}</Typography>*/}
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(UploadedMediaItem);
