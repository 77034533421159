import React, { PureComponent, ReactNode } from "react";
import { Grid, Typography, createStyles, WithStyles, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../constants/Theme";
import ModalComponent from "./Modal";
import { Info } from "../../../assets/icon";
import Badge from "./Badge";
import * as Icons from "../../../assets/icon";
import { translations } from "../../../constants/lang/translation";
import { WithTranslation, withTranslation } from "react-i18next";

interface OwnProps {
  title?: string;
  children: ReactNode;
  singleRow?: boolean;
  modalChildren?: ReactNode;
  selectOptions?: string[];
  showLine?: boolean;
  smallSpacing?: boolean;
  valid?: boolean;
  withShareInfo?: boolean;
  withInfoIcon: boolean;
  orIconLabel?: boolean;
}

interface State {
  infoOpen: boolean;
  tooltipOpen: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      borderBottom: (props: OwnProps) => (props.showLine ? "1px solid" : "none"),
      borderBottomColor: "#e8e8e8 !important",
      paddingTop: (props: OwnProps) => (props.smallSpacing ? 0 : theme.spacing(6)),
      paddingBottom: (props: OwnProps) => (props.smallSpacing ? 0 : theme.spacing(10)),
      marginTop: (props: OwnProps) => (props.smallSpacing ? 0 : theme.spacing(8)),
      marginBottom: (props: OwnProps) => (props.smallSpacing ? theme.spacing(4) : theme.spacing(8)),
      marginRight: (props: OwnProps) => (props.smallSpacing ? 0 : theme.spacing(8)),
      marginLeft: (props: OwnProps) => (props.smallSpacing ? 0 : theme.spacing(8)),
      width: "auto",
      maxWidth: "100%",
      "&:last-of-type": {
        borderBottom: "none",
      },
    },
    header: {
      textTransform: "uppercase",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      position: "relative",
      width: (props: OwnProps) => (props.singleRow ? "40%" : "100%"),
    },
    infoIcon: {
      width: 16,
      height: 16,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    children: {
      marginTop: theme.spacing(2),
      width: (props: OwnProps) => (props.singleRow ? "60%" : "100%"),
    },
    badge: {
      position: "absolute",
      right: 0,
    },
    infoIconWrapper: {
      borderRadius: "50px",
      backgroundColor: theme.palette.grey[100],
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(4),
      minWidth: theme.spacing(15),
      height: theme.spacing(5),
      paddingLeft: theme.spacing(2),
    },
    iconText: {
      textTransform: "lowercase",
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class PanelItem extends PureComponent<Props, State> {
  state: State = {
    infoOpen: false,
    tooltipOpen: false,
  };

  toggleInfo = () => {
    this.setState({
      infoOpen: !this.state.infoOpen,
    });
  };
  showInfo = () => {
    this.setState({
      infoOpen: true,
    });
  };
  hideInfo = () => {
    this.setState({
      infoOpen: false,
    });
  };

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  showTooltip = () => {
    this.setState({
      tooltipOpen: true,
    });
  };

  hideTooltip = () => {
    this.setState({
      tooltipOpen: false,
    });
  };

  render() {
    const {
      title,
      children,
      classes,
      singleRow,
      modalChildren,
      valid,
      withShareInfo,
      t,
      withInfoIcon,
      orIconLabel,
    } = this.props;
    const { infoOpen } = this.state;
    return (
      <Grid container={true} direction={singleRow ? "row" : "column"} className={classes.root}>
        <Grid container={true} alignItems="center" className={classes.header}>
          <Typography variant="subtitle2" color="textPrimary">
            {title}
          </Typography>
          {modalChildren && (
            <>
              {withInfoIcon &&
                (orIconLabel ? (
                  <div className={classes.infoIconWrapper}>
                    <Typography variant="caption" className={classes.iconText}>
                      {t(translations.operator.or)}
                    </Typography>
                    <Info className={classes.infoIcon} onClick={this.toggleInfo} />
                  </div>
                ) : (
                  <Info className={classes.infoIcon} onClick={this.toggleInfo} />
                ))}
              <ModalComponent
                label="panel-info"
                description="info for panel information"
                open={infoOpen}
                onClose={this.toggleInfo}
                showArrow={false}
                size={480}
                light={true}
                smallCross={true}
              >
                {modalChildren}
              </ModalComponent>
            </>
          )}
          {withShareInfo && (
            <Tooltip
              open={this.state.tooltipOpen}
              title={
                valid ? (
                  <>
                    <Typography>{t(translations.panel.generalInfo.validStatus)}</Typography>
                  </>
                ) : (
                  <>
                    <Typography>{t(translations.panel.generalInfo.distributionWarning)}</Typography>
                  </>
                )
              }
              placement="top"
              // {/* Validity */}
              children={
                <Badge
                  color={valid ? "senary" : "quinary"}
                  title={""}
                  width={40}
                  children={valid ? <Icons.Check fill={"#fff"} /> : <Icons.Flash fill={"#fff"} />}
                  onMouseEnter={this.showTooltip}
                  onMouseLeave={this.hideTooltip}
                  onClick={this.toggleTooltip}
                  className={classes.badge}
                />
              }
              onOpen={this.toggleTooltip}
            />
          )}
        </Grid>
        <Grid container={true} className={classes.children}>
          {children}
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(PanelItem);
