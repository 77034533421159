import React from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Cell,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import XAxisValueTooltip from "./XAxisValueTooltip";
import { createStyles, Typography, withStyles, WithStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../../../constants/Theme";
import { compose } from "recompose";
import {withTranslation, WithTranslation} from "react-i18next";
import {translations} from "../../../../../constants/lang/translation";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    legendWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginLeft: 20,
    },
    diagramWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    legendItem: {
      display: "flex",
    },
    colorField: {
      height: 10,
      width: 15,
      marginTop: 6,
      marginRight: 5,
    },
  });

interface OwnProps {
  data: any;
  colors: string[];
}

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

const StackedBarDiagram: React.FC<Props> = ({ data, colors, classes, t }: Props) => {
  const getVal = (obj: any, key: any) => {
    return obj[key].value;
  };

  const renderLegend = (data: any) => {
    return (
      <div className={classes.legendWrapper}>
        {data.map((item: any, index: number) => (
          <div className={classes.legendItem} key={index}>
            <div className={classes.colorField} style={{ backgroundColor: colors[index] }} />
            <Typography variant={"body2"}>{`${t(translations.pages.surveyResults.position)}: ${index+1}`}</Typography>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={classes.diagramWrapper}>
      <ResponsiveContainer width="80%" height={400}>
        <BarChart data={data} barCategoryGap="15%" margin={{ bottom: 30 }}>
          <CartesianGrid strokeDasharray={"3 3"} />
          <XAxis type="category" dataKey="name" interval={0}
          //@ts-ignore
          tick={XAxisValueTooltip} />
          <YAxis type="number" />
          <Tooltip />

          {data.map((dataItem: any, index: number) => (
            <Bar
              key={index}
              dataKey={(val) => getVal(val, index + 1)}
              stackId={"x"}
            >
              {data.map((entry: any) => {
                return <Cell key={entry} fill={colors[index]} />;
              })}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>

      {renderLegend(data)}
    </div>
  );
};

export default compose<Props, OwnProps>(withStyles(styles), withTranslation())(StackedBarDiagram);
