import { Dispatch } from "redux";
import { onlineStatus, checkOfflineError } from "./error";
import OpinionValue, { TransientOpinionValue } from "../entities/OpinionValue";

const SEND_MODIFICATIONS = "opinionValue/SEND_MODIFICATIONS";
const SET_TRANSIENT_SMALL_PRICE = "opinionValue/SET_TRANSIENT_SMALL_PRICE";
const SET_TRANSIENT_MEDIUM_PRICE = "opinionValue/SET_TRANSIENT_MEDIUM_PRICE";
const SET_TRANSIENT_LARGE_PRICE = "opinionValue/SET_TRANSIENT_LARGE_PRICE";
const CLEAR_TRANSIENT_PRICES = "opinionValue/CLEAR_TRANSIENT_PRICES";
const SAVE_PRICES = "opinionValue/SAVE_PRICES";

interface OpinionValueState {
  sentModification: boolean;
  transientSmallPrice: number;
  transientMediumPrice: number;
  transientLargePrice: number;
  priceData: OpinionValue;
}

const mockPrices = {
  priceS: 40,
  priceM: 95,
  priceL: 10000,
};

const defaultState: OpinionValueState = {
  //ToDo: the initial values for the prices will be generated automatically
  //ToDO: the saved prices will be set from BE side and update the initial values
  transientSmallPrice: 0,
  transientMediumPrice: 0,
  transientLargePrice: 0,
  sentModification: false,
  priceData: new OpinionValue(mockPrices),
};
interface OpinionValueAction {
  type: string;
  pathname: string;
  transientPrice: number;
  savedPrices: OpinionValue;
  transientSPrice: number;
  transientMPrice: number;
  transientLPrice: number;
  transientPriceData: TransientOpinionValue;
  priceData: OpinionValue;
}

export const setTransientSmallPrice = (transientPrice: number) => ({
  type: SET_TRANSIENT_SMALL_PRICE,
  transientPrice,
});

export const setTransientMediumPrice = (transientPrice: number) => ({
  type: SET_TRANSIENT_MEDIUM_PRICE,
  transientPrice,
});

export const setTransientLargePrice = (transientPrice: number) => ({
  type: SET_TRANSIENT_LARGE_PRICE,
  transientPrice,
});

export const clearTransientPrices = () => ({
  type: CLEAR_TRANSIENT_PRICES,
});

export const savePrices = (transientPriceData: TransientOpinionValue) => ({
  type: SAVE_PRICES,
  transientPriceData,
});

export const sendModifications = () => ({
  type: SEND_MODIFICATIONS,
});

export const savePriceModifications = () => {
  return async (dispatch: Dispatch) => {
    try {
      await fetch("https://httpbin.org/get");
      //ToDo: to check which price will be changed, acc to this dispatch right action, Formik does it?
      //dispatch(priceFunction()); //and parameter of savePriceModifications, when inserted err: not a function
      /* dispatch(changePriceS(23));*/
      dispatch(sendModifications());
      dispatch(onlineStatus());
      console.log("Your price was changed sucessfully");
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while modifying price.");
    }
  };
};

//ToDo Research function type @Adam
/* export const savePriceModifications = (priceFunction: any) =>
  //dispatch: Dispatch,
  // priceFunction: any
  {
    return async (dispatch: Dispatch) => {
      try {
        await fetch("https://httpbin.org/get");
        dispatch(priceFunction());
        //dispatch(changePriceS(23));
        dispatch(sendModifications());
        dispatch(onlineStatus());
        console.log("Your price was changed sucessfully");
      } catch (err) {
        checkOfflineErrorOrDefault(err);
        console.log("Error while modifying price.", err);
      }
    };
  }; */

export const reducer = (
  state: OpinionValueState = defaultState,
  action: OpinionValueAction
): OpinionValueState => {
  switch (action.type) {
    case SEND_MODIFICATIONS:
      return { ...state, sentModification: true };
    case SET_TRANSIENT_SMALL_PRICE:
      return { ...state, transientSmallPrice: action.transientPrice };
    case SET_TRANSIENT_MEDIUM_PRICE:
      return { ...state, transientMediumPrice: action.transientPrice };
    case SET_TRANSIENT_LARGE_PRICE:
      return { ...state, transientLargePrice: action.transientPrice };
    case CLEAR_TRANSIENT_PRICES:
      return {
        ...state,
        transientSmallPrice: defaultState.transientSmallPrice,
        transientMediumPrice: defaultState.transientMediumPrice,
        transientLargePrice: defaultState.transientLargePrice,
      };
    case SAVE_PRICES:
      return {
        ...state,
        priceData: action.transientPriceData,
      };
    default:
      return state;
  }
};
