import User from "./User";

export class OrganizationDetails {
  name: string;
  streetAndNumber: string;
  zipCode: string;
  city: string;
  postOfficeBox: string;
  additionalInvoiceInformation?: string;
  industry: string;

  constructor(obj: any) {
    this.name = obj.name || "";
    this.streetAndNumber = obj.streetAndNumber || "";
    this.zipCode = obj.zipCode || "";
    this.city = obj.city || "";
    this.postOfficeBox = obj.postOfficeBox || "";
    this.additionalInvoiceInformation = obj.additionalInvoiceInformation || "";
    this.industry = obj.industry || "";
  }
}

export class ResearcherDetails {
  salutation: string;
  position: string;
  constructor(obj: any) {
    this.salutation = obj.salutation || "FRAU";
    this.position = obj.position || "";
  }
}

export default class Researcher extends User {
  firstName: string;
  lastName: string;
  researcherDetails: ResearcherDetails;
  organizationDetails: OrganizationDetails;

  constructor(obj: any = {}) {
    super(obj);
    this.firstName = obj.firstName || "";
    this.lastName = obj.lastName || "";
    this.researcherDetails = new ResearcherDetails(obj.researcherDetails || {});
    this.organizationDetails = new OrganizationDetails(obj.organizationDetails || {});
  }
}
