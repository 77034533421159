import ID from "./ID";
import cloneDeep from "lodash.clonedeep";

export type Answer =
  | ChoiceAnswer
  | RatingAnswer
  | TextAnswer
  | RatingChoiceAnswer
  | MatrixAnswer
  | RankingAnswer;

export interface ChoiceAnswer {
  questionID: ID;
  type: "choice";
  selectedOptions: ID[];
  selectedOptionsFreeText?: AdditionalFreeTextAnswer[];
  selectedOptionsNumericalInput?: AdditionalNumberAnswer[];
}
export interface MatrixAnswer {
  questionID: ID;
  type: "matrix";
  subAnswers: { subQuestionId: ID; selectedAnswers: ID[] }[];
}
export interface AdditionalFreeTextAnswer {
  answerOptionID: ID;
  freeText: string;
}
export interface AdditionalNumberAnswer {
  answerOptionID: ID;
  numberEntry: number | null;
}
export interface RatingAnswer {
  questionID: ID;
  type: "rating";
  rating: number;
}
export interface TextAnswer {
  questionID: ID;
  type: "text";
  enteredText: string;
}

export interface RankingAnswer {
  questionID: ID;
  type: "ranking";
  selectedOrder: ID[];
}

// for attributes rating
export interface RatingChoiceAnswer {
  questionID: ID;
  type: "number";
  selectedOptions: ID[];
}

export default class SurveyAnswers {
  answers: Answer[];

  constructor(obj: any = {}) {
    this.answers =
      obj instanceof Array
        ? obj.map(({ questionId, ...answer }) => ({ ...answer, questionID: questionId } as Answer))
        : [];
  }

  get(questionID: ID): Answer | undefined {
    return this.answers.find((answer) => answer.questionID === questionID);
  }

  set(newAnswer: Answer) {
    const newSurveyAnswers: SurveyAnswers = cloneDeep(this);

    if (this.answers.find((answer) => answer.questionID === newAnswer.questionID)) {
      newSurveyAnswers.answers = this.answers.map((answer) => {
        return answer.questionID === newAnswer.questionID ? newAnswer : answer;
      });
    } else {
      newSurveyAnswers.answers = [...this.answers, newAnswer];
    }

    return newSurveyAnswers;
  }

  delete(questionID: ID) {
    const newSurveyAnswers: SurveyAnswers = cloneDeep(this);
    newSurveyAnswers.answers = this.answers.filter((answer) => answer.questionID !== questionID);

    return newSurveyAnswers;
  }

  toDataJSON() {
    return {
      updatedAnswers: this.answers.map(({ questionID, ...answer }) => ({
        ...answer,
        questionId: questionID,
      })),
    };
  }
}
