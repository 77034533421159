import React, { ReactNode } from "react";
import { Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(6),
      textTransform: "uppercase",
      alignItems: "center",
    },
    item: {
      display: "flex",
      border: "1px solid",
      borderColor: theme.palette.grey[200],
      borderRight: "none",
      borderLeft: "none",
      padding: theme.spacing(5),
      alignItems: "center",
    },
  });

interface HeaderOwnProps {
  actionArea?: ReactNode;
  title: string;
  className?: string;
}
type HeaderProps = HeaderOwnProps & WithStyles<typeof styles>;

const HeaderWithStyles = ({ classes, actionArea, title, className = "" }: HeaderProps) => (
  <div className={`${classes.header} ${className}`}>
    <Typography variant="body2">{title}</Typography>
    {actionArea && <div>{actionArea}</div>}
  </div>
);
export const HeaderRow = withStyles(styles)(HeaderWithStyles);

interface RowOwnProps {
  children: ReactNode;
  style?: any;
  className?: string;
}
type RowProps = RowOwnProps & WithStyles<typeof styles>;

const RowWithStyles = ({ classes, children, style, className = "" }: RowProps) => (
  <div className={`${classes.item} ${className}`} style={style}>
    {children}
  </div>
);

export const Row = withStyles(styles)(RowWithStyles);
