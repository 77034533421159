import * as Yup from "yup";
import i18next from "i18next";
import { translations } from "../../../constants/lang/translation";
import { subYears } from "date-fns";

export const validationSchema = () => {
  // todo: check zipcode validation when used international
  const today = new Date();
  const minBirthday = subYears(today, 100);
  const maxBirthday = subYears(today, 16);
  return Yup.object().shape({
    gender: Yup.object()
      .shape({
        attributeId: Yup.string(),
        answer: Yup.object().shape({
          selectedOptions: Yup.array().required(
            i18next.t(translations.register.participant.data.validationGender)
          ),
          type: Yup.string(),
        }),
      })
      .required(i18next.t(translations.register.participant.data.validationGender)),
    personalData: Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .required(i18next.t(translations.register.participant.data.validationFirstName))
        .matches(/^[\w'\-,.]*[^_!"§¡?÷¿\\+=@#$%ˆ&*/(){}|~<>;:[\]]*$/, {
          message:
            'Die Eingabe darf nicht aus Sonderzeichen wie: _!"§¡?÷¿\\+=@#$%ˆ&*/(){}|~<>;:[] bestehen.',
        }),
      lastName: Yup.string()
        .trim()
        .required(i18next.t(translations.register.participant.data.validationLastName))
        .matches(/^[\w'\-,.]*[^_!"§¡?÷¿\\+=@#$%ˆ&*/(){}|~<>;:[\]]*$/, {
          message:
            'Die Eingabe darf nicht aus Sonderzeichen wie: _!"§¡?÷¿\\+=@#$%ˆ&*/(){}|~<>;:[] bestehen.',
        }), //regex adapted by adding "§ and removing numbers from:  Deniss M.
      // https://stackoverflow.com/questions/2385701/regular-expression-for-first-and-last-name/45871742#45871742

      zipCode: Yup.string()
        .trim()
        .min(5, i18next.t(translations.register.participant.data.validationZipLengthGeneral))
        .max(5, i18next.t(translations.register.participant.data.validationZipLengthGeneral))
        .required(i18next.t(translations.register.participant.data.validationZip)),
      city: Yup.string()
        .trim()
        .required(i18next.t(translations.register.participant.data.validationHometown)),
      countryCode: Yup.string(),
      birthdayYear: Yup.number(),
      birthdayMonth: Yup.number(),
      birthdayDayOfMonth: Yup.number(),
      birthdayDate: Yup.date()
        .min(minBirthday, i18next.t(translations.register.participant.data.validationMaxAge))
        .max(maxBirthday, i18next.t(translations.register.participant.data.validationMinAge))
        .required(i18next.t(translations.register.participant.data.validationAge))
        .typeError(i18next.t(translations.register.participant.data.validationBirthdayDateType)),
    }),
    household: Yup.object().shape({
      attributeId: Yup.string(),
      answer: Yup.object().shape({
        questionId: Yup.string(),
        selectedOptions: Yup.array().required(
          i18next.t(translations.register.participant.data.validationHousehold)
        ),
      }),
    }),
    income: Yup.object().shape({
      attributeId: Yup.string(),
      answer: Yup.object().shape({
        questionId: Yup.string(),
        selectedOptions: Yup.array().required(
          i18next.t(translations.register.participant.data.validationIncome)
        ),
      }),
    }),
    education: Yup.object().shape({
      attributeId: Yup.string(),
      answer: Yup.object().shape({
        questionId: Yup.string(),
        selectedOptions: Yup.array().required(
          i18next.t(translations.register.participant.data.validationEducation)
        ),
      }),
    }),
    familyStatus: Yup.object().shape({
      attributeId: Yup.string(),
      answer: Yup.object().shape({
        questionId: Yup.string(),
        selectedOptions: Yup.array().required(
          i18next.t(translations.register.participant.data.validationFamilyStatus)
        ),
      }),
    }),
    children: Yup.object().shape({
      attributeId: Yup.string(),
      answer: Yup.object().shape({
        questionId: Yup.string(),
        selectedOptions: Yup.array().required(
          i18next.t(translations.register.participant.data.validationChildren)
        ),
      }),
    }),
  });
};
