import ID from "./ID";
import Panel from "./Panel";
import Questionnaire from "./Questionnaire";
import {ResultPanel} from "./ResultPanel";
import SurveyMetaData from "./SurveyMetaData";
import IndividualAttribute from "./IndividualAttribute";
import Region from "./Region";
import {TFunction} from "i18next";
import {translations} from "../constants/lang/translation";
import {OrganizationDetails} from "./Researcher";
import {ParticipationState} from "../services/Survey";

export class SurveyInformation {
  id: ID;
  status: SurveyStatus;
  title: string;
  questions: number;
  duration: number;
  panelSize: number;
  completedParticipants: number;
  totalPrice: string;
  totalPriceWithTaxes: string;
  totalPriceCurrencyCode: string;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  information?: SurveyAdminInformation;
  autoPricing: boolean;

  constructor(obj: any) {
    this.id = obj.id || obj.surveyId;
    this.status = obj.status;
    this.title = obj.title;
    this.questions = obj.numberOfQuestions;
    this.duration = obj.durationInSeconds;
    this.panelSize = obj.panelSize;
    this.completedParticipants = obj.numberOfCompletedParticipations;
    this.totalPrice = formatPrice(obj.totalPrice);
    this.totalPriceWithTaxes = formatPrice(obj.totalPriceWithVat);
    this.totalPriceCurrencyCode = obj.totalPriceCurrencyCode;
    this.startDate = obj.startDate;
    this.endDate = obj.surveyEndAt !== undefined ? obj.surveyEndAt : obj.endDate;
    this.createdAt = obj.createdAt;
    this.information = obj.info && new SurveyAdminInformation(obj.info);
    this.autoPricing = obj.autoPricing !== undefined ? obj.autoPricing : true;
  }
}

export class SurveyAdminInformation {
  organization: { id: ID; details: OrganizationDetails };
  authorFirstName: string;
  authorLastName: string;
  authorMail: string;

  constructor(obj: any) {
    this.organization = {
      id: obj.organization ? obj.organization.id : "",
      details: obj.organization
        ? new OrganizationDetails(obj.organization.details)
        : new OrganizationDetails({}),
    };
    this.authorFirstName = obj.authorFirstName || "";
    this.authorLastName = obj.authorLastName || "";
    this.authorMail = obj.authorEmail || "";
  }
}

export enum Complexity {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum SurveyStatus {
  NEW = "NEW",
  PUBLISH_REQUESTED = "PUBLISH_REQUESTED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PUBLISHED = "PUBLISHED",
  ENDED = "ENDED",
}

/**
 * Entity Class Survey
 * that represents a survey consisting of a Panel and a Questionnaire and some meta data
 */
export default class Survey {
  readonly id: ID;
  title: string;
  token?: string;
  panel: Panel;
  questionnaire: Questionnaire;
  meta: SurveyMetaData;
  startDate: Date;
  endDate: Date;
  duration: number;
  resultPanel?: ResultPanel;
  numberOfQuestions?: number;
  durationSecondsToComplete?: number;
  surveyStartAt?: Date;
  surveyEndedAt?: Date; // real ended time, may be before endDate
  acceptedAt?: Date;
  createdAt: Date;
  completedAt?: Date;
  state?: ParticipationState;
  autoPricing: boolean;

  readonly currency: string;
  readonly price: string;
  readonly priceWithTaxes: string;
  readonly taxes: string;
  readonly complexity?: Complexity;
  readonly participantPoolSize: number;
  status: SurveyStatus;
  participantsCurrent: number;
  totalEstimatedDuration: string;

  constructor(obj: any = {}) {
    this.id = obj.id || obj.surveyId;
    this.title = obj.title || obj.surveyTitle || "";
    this.token = obj.token;
    this.panel = new Panel(obj.panel);
    this.questionnaire = new Questionnaire(obj.questionnaire);
    this.meta = obj.meta ? new SurveyMetaData(obj.meta) : new SurveyMetaData({});
    this.startDate = obj.startDate || null;
    this.endDate = obj.surveyEndAt || null;
    this.duration = obj.durationInSeconds || obj.durationSeconds || 0;
    this.resultPanel = obj.resultPanel || new ResultPanel();
    this.numberOfQuestions = obj.numberOfQuestions;
    this.price = obj.price || 0;
    this.durationSecondsToComplete = obj.durationSecondsToComplete;
    this.surveyStartAt = obj.surveyStartAt || undefined;
    this.acceptedAt = obj.acceptedAt;
    this.surveyEndedAt = obj.surveyEndedAt || undefined;
    this.createdAt = obj.createdAt;
    this.completedAt = obj.completedAt;
    this.state = obj.state;

    this.currency = obj.currency || null;
    this.price = obj.price ? formatPrice(obj.price) : formatPrice(0);
    this.priceWithTaxes = obj.priceWithVat ? formatPrice(obj.priceWithVat) : formatPrice(0);
    this.taxes =
      obj.priceWithVat && obj.price ? formatPrice(obj.priceWithVat - obj.price) : formatPrice(0);
    this.complexity = obj.complexity || undefined;
    this.participantPoolSize = obj.poolSize;
    this.status = obj.status || SurveyStatus.NEW;
    this.participantsCurrent = obj.participantsCurrent || 0;
    this.totalEstimatedDuration = obj.duration || "0";
    this.autoPricing = obj.autoPricing !== undefined ? obj.autoPricing : true;
  }

  toDataJson = () => {
    let panelKey: keyof Panel;
    const panel = new ResultPanel();

    for (panelKey in this.panel) {
      if (this.panel.hasOwnProperty(panelKey)) {
        if (typeof this.panel[panelKey] === "object") {
          const panelField = this.panel[panelKey];

          if (!Array.isArray(panelField) && typeof panelField === "object") {
            let criteria: any;

            // @ts-ignore
            // eslint-disable-next-line
            if (typeof panelField.answer[0] === "string") {
              criteria = {
                // @ts-ignore
                // eslint-disable-next-line
                attributeId: this.panel[panelKey].attributeId!,
                percentage: 100,
                type: "textList",
                // @ts-ignore
                // eslint-disable-next-line
                value: panelField.answer.map((answer) => ({ value: answer, included: true })),
              };
              panel.criteria.push(criteria);
            } else if (panelField.answer[0] instanceof Region) {
              // @ts-ignore
              // eslint-disable-next-line

              criteria = {
                // @ts-ignore
                attributeId: this.panel[panelKey].attributeId!,
                percentage: 100,
                type: "numberList",
                // @ts-ignore
                value: panelField.answer!.map((item: Region) => item.id),
              };

              panel.criteria.push(criteria);
            } else if (panelKey === "gender" && panelField.answer.length > 0) {
              criteria = {
                attributeId: this.panel[panelKey].attributeId!,
                percentage: 100,
                type: "textList",
                // @ts-ignore
                // eslint-disable-next-line
                value: panelField.answer.map((answer) => ({
                  value: answer.answer,
                  included: true,
                })),
              };
              panel.criteria.push(criteria);
            } else {
              // @ts-ignore
              // eslint-disable-next-line
              criteria = panelField.answer!.map((item: any) => {
                return {
                  // @ts-ignore
                  attributeId: this.panel[panelKey].attributeId!,
                  percentage: item.percentage,
                  type: item.hasOwnProperty("answer") ? "textList" : "numberRange",
                  value: item.hasOwnProperty("answer")
                    ? [{ value: item.answer, included: true }]
                    : {
                        lower: item.min,
                        upper: item.max,
                      },
                };
              });
              panel.criteria.push(...criteria);
            }
          } else if (Array.isArray(panelField)) {
            // individual attributes

            //@ts-ignore
            const attributes = Array.from(panelField)
              .map((attribute: any) => {
                if (attribute instanceof IndividualAttribute) {
                  return attribute.toDataJson();
                } else {
                  return undefined;
                }
              })
              .filter(Boolean);
            panel.criteria = panel.criteria.concat(attributes);
          }
        }
      }
    }

    panel.id = this.panel.id;
    panel.title = this.panel.title;
    panel.size = this.panel.size;
    panel.createdAt = this.panel.createdAt;
    this.resultPanel = panel;
    return {
      id: this.id,
      title: this.title,
      panel: this.resultPanel,
      token: this.token,
      //vat: this.vat, one of several diff: attribute for comparison of change after put response
      questionnaire: this.questionnaire.toDataJson(),
      publishedAt: 0,
      randomizeQuestions: false,
      autoPricing: this.autoPricing,
    };
  };
}

export const formatPrice = (
  amount: number = 0,
  currency: string = "€",
  decimalPlaces: number = 2
) => {
  return `${amount.toLocaleString("de-DE", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  })} ${currency}`;
};

export const formatDuration = (seconds: number, t: TFunction, short: boolean = false) => {
  let minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds - minutes * 60;

  const secondLabel = t(translations.participant.surveyOverview.second);
  const secondsLabel = t(translations.participant.surveyOverview.seconds);

  const minuteLabel = t(translations.participant.surveyOverview.minute);
  const minutesLabel = t(translations.participant.surveyOverview.minutes);

  if (Boolean(minutes) && Boolean(remainingSeconds)) {
    if (short) {
      // For a more reasonable result we should round here, instead of Math.floor
      minutes = Math.round(seconds / 60);
      if (Boolean(minutes)) {
        // 10 Minuten
        return `${minutes} ${minutes === 1 ? minuteLabel : minutesLabel}`;
      } else {
        // 10 Sekunden
        return `${remainingSeconds} ${remainingSeconds === 1 ? secondLabel : secondsLabel}`;
      }
    } else {
      // 10 Minuten 10 Sekunden
      return `${minutes} ${minutes === 1 ? minuteLabel : minutesLabel} ${remainingSeconds} ${
        remainingSeconds === 1 ? secondLabel : secondsLabel
      }`;
    }
  } else if (Boolean(minutes)) {
    // 10 Minuten
    return `${minutes} ${minutes === 1 ? minuteLabel : minutesLabel}`;
  } else {
    // 10 Sekunden
    return `${remainingSeconds} ${remainingSeconds === 1 ? secondLabel : secondsLabel}`;
  }
};
