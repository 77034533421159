import React, { PureComponent } from "react";
import { compose } from "recompose";
import { withStyles, createStyles, WithStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../../../constants/lang/translation";
import { QuopinionTheme, secondary } from "../../../../../constants/Theme";
import Badge from "../../../../../common/Layout/components/Badge";
import Question, { QuestionTypes } from "../../../../../entities/Question";

interface PassedProps {}

interface OwnProps {
  question: Question;
  index: number;
}
interface State {
  resultsOpen: boolean;
}

const itemStyles = (theme: QuopinionTheme) =>
  createStyles({
    summaryQuestionItem: {
      display: "flex",
      border: "1px solid",
      borderColor: theme.palette.grey[200],
      borderRight: "none",
      borderLeft: "none",
      padding: theme.spacing(5),
      backgroundColor: "#fff",
      alignItems: "center",
      justifyContent: "space-between",
      "@media (max-width: 768px)": {
        flexDirection: "column",
      },
    },
    summaryQuestionInfo: {
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(7.5),
    },
    toggleWrapper: {
      display: "flex",
      alignItems: "center",
    },
    toggleIcon: {
      transform: "rotate(180deg)",
    },
    questionInfo: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
    },
    text: {
      maxWidth: theme.spacing(100),
      overflowWrap: "break-word",
    },
    linkText: {
      overflowWrap: "break-word",
      marginTop: theme.spacing(4),
    },
    link: {
      color: secondary,
    },
  });

type Props = PassedProps & OwnProps & WithStyles<typeof itemStyles> & WithTranslation;

class QuestionSummaryItem extends PureComponent<Props, State> {
  state: State = {
    resultsOpen: false,
  };
  toggleResults = () => {
    this.setState({
      resultsOpen: !this.state.resultsOpen,
    });
  };

  getAnswerOptionsDescription = () => {
    const { t, question } = this.props;

    if (
      question.type === QuestionTypes.SINGLECHOICE ||
      question.type === QuestionTypes.MULTIPLECHOICE
    ) {
      return `| ${question.body.answerOptions.length} ${t(
        translations.summary.questionnaire.replies
      )}`;
    }
    if (question.type === QuestionTypes.STARRATING) {
      return `| ${question.body.starCount} ${t(translations.summary.questionnaire.stars)}`;
    }
    if (question.type === QuestionTypes.MATRIX) {
      return `| ${question.body.subQuestions.length} ${t(
        translations.summary.questionnaire.subQuestions
      )}`;
    }

    return "";
  };

  render() {
    const { classes, index, question, t } = this.props;

    return (
      <div className={classes.wrapper}>
        <div className={classes.summaryQuestionItem}>
          <div className={classes.questionInfo}>
            <Badge title={`${t(translations.questions.sg)} ${index + 1}`} color={"secondary"} />
            <div className={classes.summaryQuestionInfo}>
              <Typography variant="subtitle2" className={classes.text}>
                {question.type === QuestionTypes.INSTRUCTION ? question.body.text : question.text}
              </Typography>
              {question.type === QuestionTypes.INSTRUCTION &&
                question.body.link !== null &&
                question.body.link &&
                question.body.link.length > 0 && (
                  <a
                    href={question.body.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    <Typography variant="subtitle2" className={classes.linkText}>
                      {question.body.link}
                    </Typography>
                  </a>
                )}
              {question.body.answerOptions.length > 1 && (
                <Typography variant="body1">
                  {`${t(
                    translations.questionnaire.questionTypes[question.type]
                  )} ${this.getAnswerOptionsDescription()} `}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(itemStyles)
)(QuestionSummaryItem);
