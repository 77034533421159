import React, { PureComponent } from "react";
import { compose } from "recompose";
import { Typography, WithStyles, createStyles, withStyles, IconButton } from "@material-ui/core";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import theme, { QuopinionTheme } from "../../../constants/Theme";
import Navigation from "../../../common/Layout/Navigation";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";
import ErrorOffline from "../../ErrorOffline";
import AttributesList from "./AttributesList";
import Container from "../../../common/Layout/Container";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { Screener } from "../../../entities/Screener";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { AnsweredAttribute } from "../../../services/Accounts";
import LevelCompletedBanner from "./LevelCompletedBanner";
import { hideLevelUpgraded } from "../../../modules/user";
import { Cross } from "../../../assets/icon";
import AttributesItem from "./AttributesItem";
import CategoryTitle from "./CategoryTitle";

interface OwnProps {
  isOffline: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  screenerCollection: Screener[];
}

interface DispatchProps {
  hideLevelUpgraded: () => void;
}

interface StateProps {
  screenerAnswers: AnsweredAttribute[];
  currentLevel: number | undefined;
  levelUpgraded: boolean;
  dailyScreenerAmount: number;
}

interface State {
  menuVisible: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    introText: { paddingBottom: theme.spacing(8) },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: `${theme.spacing(6)}px 0`,
    },
    headline: {
      fontSize: "32px",
      lineHeight: 1.25,
      marginBottom: theme.spacing(3),
    },
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    dailyScreenerCardContainer: {
      marginBottom: theme.spacing(4),
    },
    attributeList: {
      paddingBottom: theme.spacing(18),
      "@media (min-width: 1392px)": {
        paddingBottom: theme.spacing(24),
      },
    },
  });

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles> & WithTranslation;

class AttributeStart extends PureComponent<Props, State> {
  state: State = {
    menuVisible: false,
  };

  openMenu = () => {
    this.setState({ menuVisible: true });
  };

  closeMenu = () => {
    this.setState({ menuVisible: false });
  };

  renderLevelCompletedBanner = () => (
    <LevelCompletedBanner>
      <IconButton
        onClick={() => this.props.hideLevelUpgraded()}
        className={this.props.classes.closeButton}
      >
        <Cross fill={theme.palette.primary.main} />
      </IconButton>
      <Typography color="primary" variant="body2">
        {this.props.t(translations.participant.screeners.level.upgrade)}
        <br />
        {this.props.currentLevel !== 3 &&
          this.props.t(translations.participant.screeners.level.upgradeNewQuestions)}
        {/*TODO adapt to highest level*/}
      </Typography>
    </LevelCompletedBanner>
  );

  render() {
    const {
      t,
      classes,
      isLoading,
      screenerCollection,
      levelUpgraded,
      dailyScreenerAmount,
    } = this.props;
    const { menuVisible } = this.state;

    return (
      <>
        <LogoBarParticipant />
        <ContentWrapper>
          <Container centered={true}>
            <div className={classes.introText}>
              <Typography variant="h3" className={classes.headline}>
                {t(translations.participant.screeners.headline)}
              </Typography>
              <Typography>{t(translations.participant.screeners.description)}</Typography>
              {levelUpgraded && this.renderLevelCompletedBanner()}
            </div>

            <div className={classes.attributeList}>
              {!!dailyScreenerAmount && (
                <div className={classes.dailyScreenerCardContainer}>
                  <CategoryTitle>
                    {t(translations.participant.screeners.dailiesHeadline)}
                  </CategoryTitle>
                  <AttributesItem
                    title={t(translations.participant.screeners.daily)}
                    questionLength={dailyScreenerAmount}
                    link={"daily"}
                    completed={false}
                    highlight={true}
                  />
                </div>
              )}

              {isLoading ? (
                <LoadingOverlay />
              ) : (
                <AttributesList screenerCollection={screenerCollection} />
              )}
              <ErrorOffline />
            </div>
          </Container>
          <Navigation openMenu={this.openMenu} onMenu={menuVisible} closeMenu={this.closeMenu} />
        </ContentWrapper>
      </>
    );
  }
}

const mapDispatchToProps = {
  hideLevelUpgraded,
};

const mapStateToProps = ({ attribute, user }: RootState) => ({
  screenerAnswers: attribute.screenerAnswers,
  dailyScreenerAmount: attribute.dailyScreenerLeftAmount,
  currentLevel: user.currentLevel,
  levelUpgraded: user.levelUpgraded,
});

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AttributeStart);
