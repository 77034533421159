import * as React from "react";
import Introduction from "../IntroductionForStep";
import { Grid, Typography, createStyles, WithStyles } from "@material-ui/core";
import Button from "../../../../common/Layout/components/Button";
import SidebarContainer from "../../../../common/Layout/SidebarContainer";
import Container from "../../../../common/Layout/Container";
import { withStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../../constants/lang/translation";
import SelectionCard from "./SelectionCard";
import { QuopinionTheme } from "../../../../constants/Theme";
import Explanation from "./Explanation";
import ContentWrapper from "../../../../common/Layout/ContentWrapper";

export interface OwnProps {
  handleNext: () => void;
}

export interface SurveyIntroductionState {}

const style = (theme: QuopinionTheme) =>
  createStyles({
    selectionHeadline: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(6),
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(11),
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof style>;

class SurveyIntroduction extends React.Component<Props, SurveyIntroductionState> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { t, classes, handleNext } = this.props;

    return (
      <>
        <Introduction
          title={t(translations.pages.Survey.introduction.title)}
          description={t(translations.pages.Survey.introduction.description)}
        />
        <Grid container={true}>
          <ContentWrapper>
            <Container>
              <SelectionCard
                selected={true}
                title={t(translations.pages.Survey.introduction.availableSelections.basic.title)}
                description={t(
                  translations.pages.Survey.introduction.availableSelections.basic.description
                )}
              />
              <div className={classes.buttonContainer}>
                <Button contained={true} color="secondary" size="big" onClick={handleNext}>
                  {t(translations.pages.Survey.introduction.start)}
                </Button>
              </div>
            </Container>
            <SidebarContainer>
              <Explanation />
            </SidebarContainer>
            <Typography />
          </ContentWrapper>
        </Grid>
      </>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(style))(SurveyIntroduction);
