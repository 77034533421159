import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { PaginationDot } from "./PaginationDot";

interface OwnProps {
  dots: number;
  index: number;
  onChangeIndex: (index: number) => void;
  backgroundColor: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {},
    wrapper: {
      display: "flex",
      justifyContent: "center",
      flexGrow: 1,
      paddingBottom: theme.spacing(4),
      backgroundColor: (props: OwnProps) => props.backgroundColor,
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class Pagination extends PureComponent<Props> {
  handleClick = (index: number) => {
    this.props.onChangeIndex(index);
  };

  render() {
    const { index, dots, classes } = this.props;
    const children: any = [];
    for (let i = 0; i < dots; i += 1) {
      children.push(
        <PaginationDot key={i} index={i} active={i === index} onClick={() => this.handleClick(i)} />
      );
    }
    return <div className={classes.wrapper}>{children}</div>;
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(Pagination);
