import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { withStyles, createStyles, WithStyles, Typography } from "@material-ui/core";
import { compose } from "recompose";
import { QuopinionTheme } from "../../constants/Theme";
import { translations } from "../../constants/lang/translation";

interface OwnProps {}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      "& a": {
        color: "inherit",
        textDecoration: "none",
      },
      "& a:visited": {
        color: "inherit",
      },
    },
    infoRow: {
      display: "flex",
    },
    subheadline: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(3),
    },
    infoItem: {
      margin: `${theme.spacing(6)}px 0`,
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class ImprintContent extends PureComponent<Props> {
  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h1" color="secondary">
          {t(translations.pages.Impressum.introHeadline)}
        </Typography>
        {/* Adress */}
        <div className={classes.infoItem}>
          <Typography variant="h3" color="primary" className={classes.subheadline}>
            {t(translations.pages.Impressum.title)}
          </Typography>
          <Typography variant="subtitle1">
            {t(translations.pages.Impressum.address.companyName)}
          </Typography>
          <Typography variant="subtitle1">
            {t(translations.pages.Impressum.address.street)}
          </Typography>
          <Typography variant="subtitle1">
            {t(translations.pages.Impressum.address.city)}
          </Typography>
          <Typography variant="subtitle1">
            {t(translations.pages.Impressum.address.country)}
          </Typography>
        </div>

        {/* court */}
        <div className={classes.infoItem}>
          <Typography variant="h3" color="primary" className={classes.subheadline}>
            {t(translations.pages.Impressum.details.register.court.title)}
          </Typography>
          <Typography variant="subtitle1">
            {t(translations.pages.Impressum.details.register.court.relatedCourt)}
          </Typography>
          <Typography variant="subtitle1">
            {t(translations.pages.Impressum.details.register.court.registerNumberTitle)}
          </Typography>
          <Typography variant="subtitle1">
            {t(translations.pages.Impressum.details.register.court.locationTitle)}
          </Typography>
        </div>

        {/* Contact */}
        <div className={classes.infoItem}>
          <Typography variant="h3" color="primary" className={classes.subheadline}>
            {t(translations.pages.Impressum.text)}
          </Typography>
          <div className={classes.infoRow}>
            <Typography variant="subtitle1">
              {t(translations.pages.Impressum.details.contact.user)}
            </Typography>{" "}
            &nbsp;
            <a href="mailto:kontakt@quotas.de">
              {t(translations.pages.Impressum.details.email.address)}
            </a>
          </div>
          <div className={classes.infoRow}>
            <Typography variant="subtitle1">
              {t(translations.pages.Impressum.details.contact.researcher)}
            </Typography>{" "}
            &nbsp;
            <a href="mailto:kontakt@quotas.de">
              {t(translations.pages.Impressum.details.email.address)}
            </a>
          </div>
          <div className={classes.infoRow}>
            <Typography variant="subtitle1">
              {t(translations.pages.Impressum.details.phone.title)}
            </Typography>{" "}
            &nbsp;
            <a href={`tel:${t(translations.pages.Impressum.details.phone.number)}`}>
              <Typography variant="subtitle1">
                {t(translations.pages.Impressum.details.phone.number)}
              </Typography>
            </a>
          </div>
        </div>

        {/* Director */}
        <div className={classes.infoItem}>
          <Typography variant="h3" color="primary" className={classes.subheadline}>
            {t(translations.pages.Impressum.details.director.title)}
          </Typography>
          <Typography variant="subtitle1">
            {t(translations.pages.Impressum.details.director.directorOne)}
          </Typography>
          <Typography variant="subtitle1">
            {t(translations.pages.Impressum.details.director.directorTwo)}
          </Typography>
        </div>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(ImprintContent);
