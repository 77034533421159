import React from "react";
import { Grid, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { WithTranslation } from "react-i18next";
import Question from "../../../../entities/Question";
import { Star } from "../../../../assets/icon";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../../constants/Theme";
import ID from "../../../../entities/ID";
import { RatingAnswer, Answer } from "../../../../entities/SurveyAnswers";

const style = (theme: QuopinionTheme) =>
  createStyles({
    starIcon: {
      margin: theme.spacing(3),
    },
  });

interface OwnProps {
  question: Question;
  currentAnswer?: RatingAnswer;
  handleUserAnswer: (questionID: ID, answer?: Answer) => void;
}

type Props = OwnProps & WithTranslation & WithStyles<typeof style>;

const StyledRating = withStyles({
  iconFilled: {
    color: "#F7B500",
  },
  iconEmpty: {
    color: "#BFBFBF",
  },
})(Rating);

const StarRating: React.FC<Props> = ({ question, classes, handleUserAnswer, currentAnswer }) => {
  return (
    <Grid container={true} justify="center">
      <StyledRating
        value={currentAnswer ? currentAnswer.rating : null}
        name={question.id}
        onChange={(_, rating) =>
          handleUserAnswer(question.id, {
            questionID: question.id,
            type: "rating",
            rating,
          } as RatingAnswer)
        }
        max={question.body.starCount}
        size="large"
        icon={<Star fill={"currentColor"} height={32} width={32} className={classes.starIcon} />}
      />
    </Grid>
  );
};

export default compose<Props, OwnProps>(withStyles(style))(StarRating);
