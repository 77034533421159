import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: QuopinionTheme) =>
  createStyles({
    tooltip: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.grey[200],
      ...theme.typography.body1,
    },
    arrow: {
      color: theme.palette.grey[200],
    },
  })
);

function TooltipComponent(props: TooltipProps) {
  const { ...classes } = useStyles();
  const { title, open } = props;

  return <Tooltip arrow {...props} title={<>{title}</>} open={open} classes={classes} />;
}

export default TooltipComponent;

const useStylesError = makeStyles((theme: QuopinionTheme) =>
  createStyles({
    tooltip: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      ...theme.typography.body1,
    },
    arrow: {
      color: theme.palette.secondary.main,
    },
  })
);

export const ErrorToolTip = ((props: TooltipProps) => {
  const { ...classes } = useStylesError();
  const { title, open } = props;
  // we do not want to pass the dispatch function to the tool tip component
  //@ts-ignore
  const { dispatch, ...tooltipProps } = props;

  return (
    <Tooltip
      arrow
      {...tooltipProps}
      title={
        <>
          <div style={{ position: "relative" }}>
            {/* <Button
              style={{ position: "absolute", right: 0, top: "-4px" }}
              size="small"
              color="secondary"
              onClick={props.onHandleClose}
              icon={<Cross color="#fff" />}
            /> */}
            <div style={{ marginBottom: 24 }}>{title}</div>
          </div>
        </>
      }
      open={open}
      classes={classes}
    />
  );
});
