import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { Typography } from "@material-ui/core";
import { translations } from "../../../constants/lang/translation";

interface OwnProps {}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(5),
      textTransform: "uppercase",
    },
    subtitle: {
      marginBottom: theme.spacing(4.5),
    },
    subtitleText: {
      marginBottom: theme.spacing(5),
    },
    subheadline: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(3),
    },
    wrapper: {
      margin: `${theme.spacing(10)}px 0`,
      "& a": {
        color: "inherit",
        textDecoration: "none",
      },
      "& a:visited": {
        color: "inherit",
        textDecoration: "none",
      },
    },
    listItem: {
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecoration: "underline",
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

export const route: string = "/market-research/data-protection";

class DataProtectionResearcher extends PureComponent<Props> {
  render() {
    const { t, classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <Typography variant="h3" color="secondary" className={classes.subheadline}>
          {t(translations.pages.dataProtection.title)}{" "}
          {t(translations.pages.dataProtection.researcher.short.bodyTitle)}
        </Typography>
        <Typography>{t(translations.pages.dataProtection.researcher.short.text)}</Typography>
        <Typography
          variant="body1"
          className={classes.subtitle}
          dangerouslySetInnerHTML={{
            __html: t(translations.pages.dataProtection.researcher.short.bodySubtitle, {
              link: '<a style="text-decoration: underline" href="market-reserach/privacy">hier</a>',
            }),
          }}
        />
        <Typography variant="body1" className={classes.subtitleText}>
          {t(translations.pages.dataProtection.researcher.short.dataIntro)}
        </Typography>
        <ul>
          <li className={classes.listItem}>
            <Typography variant="body1">
              {t(translations.pages.dataProtection.researcher.short.dataFirst)}
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
              {t(translations.pages.dataProtection.researcher.short.dataSecond)}
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
              {t(translations.pages.dataProtection.researcher.short.dataThird)}
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" className={classes.subtitleText}>
          {t(translations.pages.dataProtection.researcher.short.reworkIntro)}
        </Typography>
        <ul>
          <li className={classes.listItem}>
            <Typography variant="body1">
              {t(translations.pages.dataProtection.researcher.short.reworkFirst)}
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
              {t(translations.pages.dataProtection.researcher.short.reworkSecond)}
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
              {t(translations.pages.dataProtection.researcher.short.reworkThird)}
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
              {t(translations.pages.dataProtection.researcher.short.reworkFourth)}
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
              {t(translations.pages.dataProtection.researcher.short.reworkFifth)}
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" className={classes.subtitle}>
          {t(translations.pages.dataProtection.researcher.short.paragraph1)}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {t(translations.pages.dataProtection.researcher.short.paragraph2)}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {t(translations.pages.dataProtection.researcher.short.paragraph3)}
        </Typography>
        <Typography
          variant="body1"
          className={classes.subtitle}
          dangerouslySetInnerHTML={{
            __html: t(translations.pages.dataProtection.researcher.short.paragraph4, {
              link:
                '<a style="text-decoration: underline" href="https://datenschutz-hamburg.de/">Aufsichtsbehörde</a>',
            }),
          }}
        />
        <Typography
          variant="body1"
          className={classes.subtitle}
          dangerouslySetInnerHTML={{
            __html: t(translations.pages.dataProtection.researcher.short.contact, {
              email: '<a href="mailto:support@quopinion.de">support@quopinion.de</a>',
              break: "<br/>",
            }),
          }}
        />
      </div>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(DataProtectionResearcher);
