import React from "react";
import { createStyles, Typography, withStyles, WithStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../constants/Theme";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    uspItem: {
      borderBottom: "1px solid",
      borderBottomColor: "#e8e8e8 !important",
      margin: `0px ${theme.spacing(5)}px`,
      marginLeft: "unset",
      width: theme.spacing(12.5),
      height: theme.spacing(12.5),
      borderRadius: 25,
      backgroundColor: (props: Props) => props.color,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "@media (max-width: 599px)": {
        marginRight: "unset",
        marginBottom: theme.spacing(5),
      },
    },
    number: {
      color: "#fff",
      fontWeight: 900,
    },
  });

interface OwnProps {
  number: string;
  color: string;
}
type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const UspBadge: React.FC<Props> = ({ classes, number }) => {
  return (
    <div className={classes.uspItem}>
      <Typography variant={"h5"} className={classes.number}>
        {number}
      </Typography>
    </div>
  );
};

export const UspItem = compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(UspBadge);
