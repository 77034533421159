import React from "react";
import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";
import { Typography, createStyles, withStyles, WithStyles } from "@material-ui/core";
import TextColumn from "./TextColumn";
import { primary, QuopinionTheme } from "../../constants/Theme";

interface OwnProps {
  backgroundColor: string;
  contentObj: {
    title: string;
    subtitle: string;
    item1: { image: string; title: string; description: string };
    item2: { image: string; title: string; description: string };
    item3: { image: string; title: string; description: string };
  };
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      padding: `${theme.spacing(12)}px ${theme.spacing(4)}px`,
      "@media(min-width: 600px)": {
        padding: `${theme.spacing(14)}px ${theme.spacing(10)}px`,
      },
      backgroundColor: (props: OwnProps) => props.backgroundColor,
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
      "@media (min-width: 600px)": {
        flexDirection: "row",
      },
      paddingLeft: theme.spacing(5.5),
      paddingRight: theme.spacing(5.5),
    },
    headline: {
      color: primary,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    subHeadline: {
      display: "flex",
      justifyContent: "center",
      color: theme.palette.grey[400],
      textAlign: "center",
      marginTop: theme.spacing(2),
      "@media(min-width:700px)": {
        marginRight: theme.spacing(20.5),
        marginLeft: theme.spacing(20.5),
      },
    },
    subHeadlineWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    image: {
      width: theme.spacing(40.75),
      height: theme.spacing(32.5),
      "@media (min-width: 600px)": {
        width: theme.spacing(36),
        height: theme.spacing(27),
      },
      "@media (min-width: 768px)": {
        width: theme.spacing(40.75),
        height: theme.spacing(32.5),
      },
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

const SurveyStepOverviewItem: React.FC<Props> = ({ classes, contentObj }) => {
  const iconStepPanel = (
    <img className={classes.image} src={contentObj.item1.image} alt="Panel Selection" />
  );
  const iconStepQuestionnaire = (
    <img className={classes.image} src={contentObj.item2.image} alt="Create Questionnaire" />
  );
  const iconStepResults = (
    <img className={classes.image} src={contentObj.item3.image} alt="Results" />
  );
  return (
    <div className={classes.root} id="research-teaser">
      <Typography variant="h1" className={classes.headline}>
        {contentObj.title}
      </Typography>
      <div className={classes.subHeadlineWrapper}>
        <Typography variant="body1" className={classes.subHeadline}>
          {contentObj.subtitle}
        </Typography>
      </div>
      <div className={classes.wrapper}>
        <TextColumn
          centeredTitle={true}
          centeredDescription={true}
          title={contentObj.item1.title}
          description={contentObj.item1.description}
          image={iconStepPanel}
        />
        <TextColumn
          centeredTitle={true}
          centeredDescription={true}
          title={contentObj.item2.title}
          description={contentObj.item2.description}
          image={iconStepQuestionnaire}
        />
        <TextColumn
          centeredTitle={true}
          centeredDescription={true}
          title={contentObj.item3.title}
          description={contentObj.item3.description}
          image={iconStepResults}
        />
      </div>
    </div>
  );
};

export const SurveyStepOverview = compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(SurveyStepOverviewItem);
