import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, Typography, WithStyles } from "@material-ui/core";
import { translations } from "../../../constants/lang/translation";
import withStyles from "@material-ui/core/styles/withStyles";
import { QuopinionTheme, secondary } from "../../../constants/Theme";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(3),
      color: secondary,
      textTransform: "uppercase",
    },
    headlineInfo: {
      marginBottom: theme.spacing(3),
    },

    bodyItems: {
      display: "flex",
      flexDirection: "column",
      "& a": {
        color: "inherit",
        textDecoration: "none",
      },
      "& a:visited": {
        color: "inherit",
        textDecoration: "none",
      },
    },
    subheadline: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(3),
    },
    subtitleText: {
      marginBottom: theme.spacing(5),
      "& a": {
        textDecoration: "underline",
      },
    },

    list: { marginBottom: theme.spacing(8) },
    listItem: {
      marginBottom: theme.spacing(2),
      fontWeight: 400,
    },
  });

interface OwnProps {}

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

class TermsConditions extends PureComponent<Props> {
  render() {
    const { t, classes } = this.props;
    return (
      <>
        <Typography variant="h1" className={classes.title}>
          {t(translations.pages.termsConditions.researcher.title)}
        </Typography>
        <Typography className={classes.headlineInfo}>
          Diese allgemeinen Geschäftsbedingungen gelten für alle Verträge, die zwischen QUOTAS GmbH,
          Holstenplatz 20, 22765 Hamburg ("QUOTAS") und ihren Partnerunternehmen ("Partner") über
          den Service auf der Plattform: Quopinion.de geschlossen werden. Für das Vertragsverhältnis
          gelten ausschließlich diese Allgemeinen Geschäftsbedingungen. Geschäftsbedingungen des
          Partners werden nicht Bestandteil dieses Vertrages, mündliche Nebenabreden bestehen nicht.
        </Typography>

        <div className={classes.bodyItems}>
          <Typography variant={"h3"} className={classes.subheadline}>
            1. Gegenstand des Vertrages
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            1.1 QUOTAS bietet einen Service an, der es Unternehmen erlaubt, Meinungsumfragen für die
            für sie relevanten Zielgruppe zu generieren. Dazu stellt QUOTAS eine Internetseite
            ("Quopinion-Plattform") zur Verfügung, über die der Partner aus unterschiedlichen
            Umfragetypen und -arten eigene Umfragen erstellen kann. Die Umfragen werden anschließend
            von QUOTAS an Panelisten der QUOTAS GmbH ausgeliefert. Der Partner erhält nach
            Beantwortung durch die Panelisten die Abstimmungsergebnisse zu seinen erstellten
            Umfragen.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            1.2 Zu diesem Zweck schließen die Parteien einen Rahmenvertrag über die Bereitstellung
            der Quopinion-Plattform sowie die Erstellung und Auslieferung von Umfragen.
          </Typography>
          <Typography variant={"h3"} className={classes.subheadline}>
            2. Zustandekommen von Verträgen
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.1 Dieser Rahmenvertrag kommt durch Angebot und Annahme in Schriftform oder durch den
            Abschluss der Registrierung des Partners bei der Quopinion-Plattform der QUOTAS GmbH
            zustande. Dazu gibt der Partner die erforderlichen Daten (Email-Adresse und Passwort) in
            das Registrierungsformular ein. Anschließend wird ihm eine E-Mail zur Bestätigung seiner
            Anmeldung geschickt.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.2 Darüber hinaus kommen jeweils kostenpflichtige Einzelverträge zustande, wenn der
            Partner Quopinion-Plattform Umfragen anlegt und diese zur Veröffentlichung freigibt. Die
            auf der Quopinion-Plattform angezeigten Preise sind Festpreise.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            Der Partner wird vor Zustandekommen des Vertrages auf die Kostenpflicht hingewiesen.
            Beim erstmaligen Anlegen einer kostenpflichtigen Umfrage wird der Partner aufgefordert,
            seine erforderlichen Daten zu vervollständigen und insbesondere neben seiner E-Mail
            Adresse weitere Kontakt- und Rechnungsdaten anzugeben.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            Sobald dies geschehen ist, und der Partner den „KOSTENPFLICHTIG VERÖFFENTLICHEN“-Button
            betätigt hat, wird die entsprechende Umfrage zur Prüfung an Quotas übersendet und im
            Falle einer Freigabe den Teilnehmer*innen zur Beantwortung übersendet. Bei allen
            nachfolgenden Umfragen ist eine erneute Eingabe der Daten des Partners nicht mehr
            erforderlich.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            2.3 Wählt der Kunde die Zahlung auf Rechnung als Zahlungsmethode und fordert er
            zusätzlich die Bereitstellung eines Angebots, kommen kostenpflichtige Einzelverträge
            über die Bereitstellungen von Umfragen abweichend von Ziff. 2.2 zustande, indem der
            Partner das von QUOTAS auf der Quopinion-Plattform für die jeweilige Umfrage
            bereitgestellte Angebot unterzeichnet und an QUOTAS auf dem Postweg, als Scan oder per
            E-Mail zurücksendet und QUOTAS daraufhin die Umfrage freischaltet.
          </Typography>
          <Typography variant={"h3"} className={classes.subheadline}>
            3. Pflichten von QUOTAS
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.1 QUOTAS stellt dem Partner kostenlos den Zugang zur Quopinion-Plattform zur
            Verfügung.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.2 QUOTAS ist bemüht, eine Verfügbarkeit der Quopinion-Plattform von 98,5% im
            Jahresmittel sicherzustellen. Ausgenommen davon sind erforderliche geplante
            Wartungsarbeiten sowie Störungen, die nicht im Einflussbereich von QUOTAS liegen,
            insbesondere höhere Gewalt, Streiks, unvorhersehbare Naturereignisse sowie Störungen bei
            Zulieferern.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.3 Erstellt der Partner eine Umfrage, wird QUOTAS diese in seinem System speichern und
            an die vom Partner definierte Zielgruppe ausliefern. Die Reichweite der auszuliefernden
            Umfragen ist abhängig von den Einstellungen, die der Partner vornimmt; eine
            Mindestreichweite kann nicht garantiert werden.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.4 Nach Auslieferung der Umfrage erfasst QUOTAS die von den Panelisten abgegebenen
            Antworten und stellt diese, je nach Einstellung und Art der Umfrage, gemeinsam mit
            anonymen demographischen und statistischen Daten, dem Partner in Form einer Auswertung
            zur Verfügung. Art und Umfang der demographischen und statistischen Daten sind abhängig
            von den Einstellungen und Angaben der Panelisten sowie der Einstellungen, die der
            Partner bei der Umfrage vorgenommen hat und können im Einzelfall variieren. Soweit auf
            der Quopinion-Plattform die Anzahl der Nutzer in einer bestimmten Zielgruppe angezeigt
            wird, sind diese Zahlen unverbindlich und geben keine Aussage über die endgültige
            Reichweite einer Umfrage.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.5 Die Auswertung der Antworten erfolgt durch QUOTAS. Der Partner hat keinen Anspruch
            auf Zugang zu den Rohdaten, wie sie von den Panelisten zur Verfügung gestellt oder von
            QUOTAS erhoben werden.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            3.6 QUOTAS behält sich vor, die Funktionalität der Quopinion-Plattform sowie der
            auszuliefernden Umfragen jederzeit zu erweitern. Aus Gründen der Datensicherheit, wegen
            zwingender technischer Gründe, zur Fehlerbehebung oder wegen einer Veränderung der
            Rechtslage durch Gesetzgebung, Rechtsprechung oder behördlicher Vorgaben kann die
            Funktionalität darüber hinaus jederzeit geändert, eingeschränkt oder teilweise
            eingestellt werden. Der Partner wird über solche Änderungen so bald wie möglich und
            zumutbar benachrichtigt.
          </Typography>
          <Typography variant={"h3"} className={classes.subheadline}>
            4. Pflichten des Partners
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            4.1 Der Partner ist verpflichtet den bei Vertragsschluss nach Ziff. 2.2 dieses Vertrages
            vereinbarten Preis entsprechend der vor der Buchung der jeweiligen Umfrage angezeigten
            Preisbestandteil für die Auslieferung der Umfragen zu entrichten. Der Endpreis ist in
            der Regel abhängig von der Anzahl der beantworteten Umfragen, der Fragen sowie der
            Zielgruppeneingrenzung. Das Berechnungsmodell ergibt sich aus den vor der Buchung
            bereitgestellten Preisangaben.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            4.2 Der Partner wird vor Abschluss des verbindlichen Vertrages über die Auslieferung von
            Umfragen auf die Kosten hingewiesen. Alle Preise verstehen sich, sofern nicht anders
            angegeben, zuzüglich der gesetzlichen deutschen Mehrwertsteuer.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            4.3 Der Partner ist verpflichtet, die Zugangsdaten zur Quopinion-Plattform sicher zu
            verwahren und nicht an Dritte weiterzugeben. Bei der Nutzung der Quopinion-Plattform hat
            der Partner sämtliche Handlungen zu unterlassen, die die Stabilität, Integrität und
            Sicherheit der technischen Systeme von QUOTAS gefährden können. Der Zugriff auf die
            Quopinion-Plattform darf nur über einen Webbrowser oder über technische Hilfsmittel
            erfolgen, die von QUOTAS bereitgestellt wurden. Insbesondere ist das Auslesen von Daten
            mittels "Screen Scraping" ohne vorherige ausdrückliche Genehmigung von QUOTAS untersagt.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            4.4 Der Partner garantiert, dass die von ihm angelegten und ausgelieferten Umfragen
            allen rechtlichen Vorgaben entsprechen, insbesondere urheber-, datenschutz-, marken-
            und/oder wettbewerbsrechtlichen Regelungen. Der Partner garantiert weiter, dass seine
            Umfragen nicht gegen die guten Sitten verstoßen, keine anzüglichen, erotischen,
            pornographischen oder gewaltverherrlichenden Inhalte enthalten, nicht den
            Plattformvorgaben von Apple, Google oder Microsoft für mobile Anwendungen widersprechen
            sowie keinen Versuch unternehmen, den Panelisten zu täuschen oder irrezuführen. QUOTAS
            ist nicht verpflichtet, Umfragen vor ihrer Auslieferung auf ihren Inhalt hin zu
            untersuchen.
          </Typography>
          <Typography variant={"h3"} className={classes.subheadline}>
            5. Schadensersatz, Haftung und Freistellung
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            5.1 QUOTAS ist zur Leistung von Schadensersatz nur bei Vorsatz, grober Fahrlässigkeit,
            schuldhafter Verletzung von Leib, Leben oder Gesundheit, leicht fahrlässiger Verletzung
            einer vertragswesentlichen Pflicht, deren Erfüllung die Erreichung des Vertragszwecks
            erst ermöglicht und auf deren Erfüllung der Kunde regelmäßig vertrauen darf
            („Kardinalpflicht“), im Falle der Verletzung einer ausdrücklich als solche bezeichneten
            Garantie und im Falle einer gesetzlich zwingenden Haftung nach dem Produkthaftungsgesetz
            verpflichtet. Im Übrigen ist eine Haftung ausgeschlossen. Bei einer leicht fahrlässigen
            Verletzung einer Kardinalpflicht ist die Haftung beschränkt auf die im Zeitpunkt des
            Vertragsschlusses vorhersehbaren, vertragstypischen Schäden.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            5.2 Der Partner stellt QUOTAS von Ansprüchen Dritter sowie den Kosten zur notwendigen
            Rechtsverteidigung, die im Zusammenhang mit der Auslieferung von Umfragen für den
            Partner geltend gemacht werden, frei. Der Partner ist verpflichtet, QUOTAS nach Treu und
            Glauben mit Informationen und Unterlagen bei der Rechtsverteidigung gegenüber Dritten zu
            unterstützen. Voraussetzung für die Freistellung ist, dass QUOTAS den Partner
            rechtzeitig von Ansprüchen Dritter verständigt, die behauptete Rechtsverletzung nicht
            anerkennt und jegliche Auseinandersetzung, einschließlich etwaiger außergerichtlicher
            Regelungen, entweder dem Partner überlässt oder nur im Einvernehmen mit ihm führt.
          </Typography>
          <Typography variant={"h3"} className={classes.subheadline}>
            6. Laufzeit und Kündigung
          </Typography>{" "}
          <Typography variant="body1" className={classes.subtitleText}>
            6.1 Der Vertrag wird für unbestimmte Zeit geschlossen.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            6.2 Beide Parteien können diesen Vertrag mit einer Frist von 2 Wochen ordentlich
            kündigen. Die Kündigung hat in Schrift- oder Textform zu erfolgen.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            6.3 Eine Kündigung aus wichtigem Grund bleibt unberührt. Als wichtiger Grund gilt
            insbesondere ein Verstoß gegen Ziff. 4.3 oder 4.4 dieser Allgemeinen
            Geschäftsbedingungen.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            6.4 QUOTAS ist darüber hinaus berechtigt, Benutzerkonten zu sperren und den Zugang zur
            Quopinion-Plattform zu verweigern, wenn der Partner mit einer Zahlung mehr als zwei
            Wochen in Verzug ist oder wenn ein wichtiger Grund im Sinne von Ziff. 6.3 vorliegt. Die
            Sperrung soll dem Partner in Textform vorab mitgeteilt werden, wenn nicht wichtige
            Gründe eine sofortige Sperre erfordern.
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            6.5 QUOTAS bleibt vorbehalten, die Funktionalität der Quopinion-Plattform und der
            auszuliefernden Umfragen aus anderen als den in Ziff. 3.6 genannten Gründen zu ändern
            oder einzuschränken. Der Partner ist darüber rechtzeitig in Textform in Kenntnis zu
            setzen. Dem Partner steht in diesem Fall ein außerordentliches Kündigungsrecht zu dem
            Termin des Inkrafttretens der Änderungen zu.
          </Typography>
          <Typography variant={"h3"} className={classes.subheadline}>
            7. Änderungen dieser Allgemeinen Geschäftsbedingungen
          </Typography>
          <Typography variant="body1" className={classes.subtitleText}>
            7.1 QUOTAS kann diese AGB mit Wirkung für die Zukunft ändern und anpassen, wenn für die
            Änderung ein triftiger Grund vorliegt und soweit die Änderungen unter Berücksichtigung
            der Interessen beider Vertragsparteien zumutbar sind. Ein triftiger Grund liegt
            insbesondere vor, wenn die Änderungen
          </Typography>
          <ol type="a" className={classes.list}>
            <li className={classes.listItem}>
              aufgrund einer für QUOTAS bei Vertragsschluss unvorhersehbaren Störung des
              Äquivalenzverhältnisses des Vertrages in nicht unbedeutendem Maße erforderlich sind
              oder
            </li>
            <li className={classes.listItem}>
              aufgrund von Rechtsprechungs- oder Gesetzesänderungen für die weitere Durchführung des
              Vertrages erforderlich sind.
            </li>
          </ol>
          <Typography variant="body1" className={classes.subtitleText}>
            Die Änderung einer Hauptleistungspflicht ist ausgeschlossen.
          </Typography>
        </div>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(TermsConditions);
