import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../modules";
import { unsubscribeSessionToken } from "../../modules/session";
import { UserRole } from "../../entities/User";
import { Redirect } from "react-router";

export const withAuthorization = (role: string) => (Comp: any) => {
  interface StateProps {
    session: any;
    user: any;
  }

  interface DispatchProps {
    unsubscribeSessionToken: () => Promise<void>;
  }

  type Props = StateProps & DispatchProps;

  class AuthorizationComponent extends React.Component<Props> {
    render() {
      if (this.props.session.authenticated) {
        if (!this.props.user.current.roles) {
          return null;
        }
        const allowed = this.props.user.current.roles.find((userRole: any) => {
          return userRole.type === role;
        });

        if (!allowed) {
          if (role === UserRole.RESEARCHER) {
            this.props.unsubscribeSessionToken();
            return <Redirect to={"/participant/login"} />;
          }
          if (role === UserRole.PARTICIPANT) {
            this.props.unsubscribeSessionToken();
            return <Redirect to={"/market-research/login"} />;
          }
          if (role === UserRole.ADMIN) {
            this.props.unsubscribeSessionToken();
            return <Redirect to={"/market-research/login"} />;
          }
        }
      }

      return <Comp {...this.props} />;
    }
  }

  const mapStateToProps = ({ session, user }: RootState) => ({
    session,
    user,
  });

  return connect(mapStateToProps, { unsubscribeSessionToken })(AuthorizationComponent);
};
