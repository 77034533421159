import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: theme.spacing(2),
      backgroundColor: (props: OwnProps) => (props.active ? "#7c9aad" : "#bbcbd6"),
      marginRight: theme.spacing(2),
    },
  });

interface OwnProps {
  key: number;
  index: number;
  active: boolean;
  onClick: () => void;
}
type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const Dot: React.FC<Props> = ({ classes, onClick }) => {
  return <div className={classes.root} onClick={onClick} />;
};

export const PaginationDot = compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(Dot);
