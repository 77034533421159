import Client from "./Client";
import ID from "../entities/ID";
import { PersonalData } from "../entities/User";
import { Answer as SurveyAnswer } from "../entities/SurveyAnswers";
import Attribute from "../entities/Attribute";
import Researcher from "../entities/Researcher";
import { Screener } from "../entities/Screener";

interface Answer {
  type: "choice" | "rating" | "text" | "number" | "ranking";
  questionId: number;
}

interface ChoiceAnswer extends Answer {
  selectedOptions: ID[];
}

interface RatingAnswer extends Answer {
  selectedOptions: ID[];
}

interface NumberAnswer extends Answer {
  value: number;
}

interface RankingAnswer extends Answer {
  selectedOrder?: ID[];
}

export interface AnsweredChoiceAttribute {
  answer: ChoiceAnswer;
  attributeId: ID;
}
export interface AnsweredRatingAttribute {
  answer: RatingAnswer;
  attributeId: ID;
}
export interface AnsweredNumberAttribute {
  answer: NumberAnswer;
  attributeId: ID;
}

export interface AnsweredRankingAttribute {
  answer: RankingAnswer;
  attributeId: ID;
}

export class AnsweredAttribute {
  answer: SurveyAnswer;
  attributeId: ID;
  constructor(obj: any) {
    this.answer = obj.answer;
    this.attributeId = obj.attributeId;
  }
}

interface UserAttributesResponse {
  answeredAttributes: Array<
    | AnsweredChoiceAttribute
    | AnsweredRatingAttribute
    | AnsweredNumberAttribute
    | AnsweredRankingAttribute
  >;
  attributes: Attribute[];
}

interface DailyScreenerAttributeResponse {
  screenerAnswers: Array<
    AnsweredChoiceAttribute | AnsweredRatingAttribute | AnsweredNumberAttribute
  >;
  screenerAttributesHistorically: Attribute[];
  screenerAttributesToday: Attribute[];
}

export interface DailyScreenerList {
  screenerAttributesToday: Screener[];
  screenerAttributesHistorically: Screener[];
  answeredScreenerAttributes: AnsweredAttribute[];
}

export default class AccountService {
  readonly httpClient: Client;
  readonly baseRoute: string = "/accounts";

  constructor(httpClient: Client) {
    this.httpClient = httpClient;
  }
  async registerParticipant(
    registrationData: { emailAddress: string; password: string },
    userAttributes: { [key: string]: {} }
  ) {
    return await this.httpClient
      .post("/accounts", {
        emailAddress: registrationData.emailAddress,
        password: registrationData.password,
        role: "participant",
        participationPrice: {
          small: 10,
          medium: 20,
          large: 33,
        },
        attributes: userAttributes,
      })
      .then((body) => body);
  }

  // fetch attributes the user already answered
  async fetchAttributesForUser(): Promise<AnsweredAttribute[]> {
    const attributeResponse: UserAttributesResponse = await this.httpClient.get(
      `${this.baseRoute}/_self/attributes`
    );

    return attributeResponse.answeredAttributes.map(
      (attribute) => new AnsweredAttribute(attribute)
    );
  }

  // fetch all attributes that the user is available til current level
  async fetchAvailableAttributesForUser(): Promise<Attribute[]> {
    const attributeResponse: UserAttributesResponse = await this.httpClient.get(
      `${this.baseRoute}/_self/attributes`
    );
    return attributeResponse.attributes.map((attribute) => new Attribute(attribute));
  }

  async updateBasicAttributes(
    newBasicAttributes: Array<
      AnsweredChoiceAttribute | AnsweredRatingAttribute | AnsweredNumberAttribute
    >
  ): Promise<void> {
    return await this.httpClient.patch(`${this.baseRoute}/_self/attributes`, newBasicAttributes);
  }

  async updatePersonalData(personalData: PersonalData): Promise<void> {
    return await this.httpClient.put(`${this.baseRoute}/_self/personalData`, personalData);
  }

  async updatePriceInformation(newOpinionPrice: any): Promise<void> {
    return await this.httpClient.put(`/participants/_self/price`, newOpinionPrice);
  }

  async fetchParticipantInfo(): Promise<any> {
    return await this.httpClient
      .get(`${this.baseRoute}/_self/participantInfo`)
      .then((body) => body);
  }

  // update attributes of user
  async updateAttributes(newBasicAttributes: AnsweredAttribute[]): Promise<void> {
    return await this.httpClient.patch(`${this.baseRoute}/_self/attributes`, newBasicAttributes);
  }

  async fetchInternalAttributes(): Promise<DailyScreenerList> {
    return await this.httpClient
      .get(`${this.baseRoute}/_self/screener`)
      .then((data: DailyScreenerAttributeResponse) => ({
        screenerAttributesToday: data.screenerAttributesToday.map(
          (attribute) => new Screener(attribute)
        ),
        screenerAttributesHistorically: data.screenerAttributesHistorically.map(
          (attribute) => new Screener(attribute)
        ),
        answeredScreenerAttributes: data.screenerAnswers.map(
          (attribute) => new AnsweredAttribute(attribute)
        ),
      }));
  }

  async fetchCurrentResearcher(): Promise<Researcher> {
    // Duplicated from Session.fetchUser()
    const userData = await this.httpClient.get(`/accounts/_self`);

    const researcherData = await this.httpClient.get(`${this.baseRoute}/_self/researcher`);
    return new Researcher({
      ...userData,
      ...researcherData,
    });
  }

  async updateCurrentResearcher(researcher: Researcher): Promise<void> {
    return await this.httpClient.put(`${this.baseRoute}/_self/researcher`, researcher);
  }

  async selfDeleteCurrentParticipant(){
    return await this.httpClient.delete(`${this.baseRoute}/_self`)
  }
}
