import React from "react";
import Container from "../../../../common/Layout/Container";
import Button from "../../../../common/Layout/components/Button";
import Introduction from "../IntroductionForStep";
import { CreationStep } from "../index";
import SupportContainer from "../../../../common/Layout/SupportContainer";
import { Grid, createStyles, WithStyles, withStyles, Typography } from "@material-ui/core";
import SidebarContainer from "../../../../common/Layout/SidebarContainer";
import {
  updateTransientSurvey,
  setSurveyTitle,
  setPanelRegion,
  setPanelFamilyStatus,
  setPanelEducation,
  setPanelChildren,
  setIndividualAttributes, fetchPanelConfiguration,
} from "../../../../modules/survey";
import { connect } from "react-redux";
import { compose } from "recompose";
import Card from "../../../../common/Layout/components/Card";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../../constants/lang/translation";
import { QuopinionTheme, blueGradient } from "../../../../constants/Theme";
import { OptionsType } from "../../../../common/Layout/components/AttributeSelect";
import { actions as uiStateActions } from "../../../../modules/uiState";
import ContentWrapper from "../../../../common/Layout/ContentWrapper";
import Panel, { Children, Education, Family } from "../../../../entities/Panel";
import DemographicsCard from "./DemographicsCard";
import RegionalPanelDefinition from "./RegionalPanelDefinition";
import LivePriceContainer from "../partial/LivePriceContainer";
import IndividualAttributesDefinition from "./IndividualAttributesDefinition";
import Region from "../../../../entities/Region";
import IndividualAttribute from "../../../../entities/IndividualAttribute";
import { RootState } from "../../../../modules";
import StaticAttributesCard from "./StaticAttributesCard";
import ID from "../../../../entities/ID";
import Survey from "../../../../entities/Survey";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import { ErrorToolTip } from "../../../../common/Layout/components/Tooltip";
import { ErrorMessage } from "../../../../modules/surveyErrors";
import isEqual from "lodash/isEqual";

interface OwnProps {
  dispatch?: any;
  stepConfiguration?: CreationStep;
  handlePrev(): void;
  handleNext(): void;
}

interface DispatchProps {
  updateTransientSurvey: (transientSurvey: any) => Promise<void>;
  editQuestionnaireTitle: (title: string) => { type: string; title: string };
  setPanelFamilyStatus: (attributeFam: Family[]) => { type: string; attributeFam: Family[] };
  setPanelEducation: (
    attributeEducation: Education[]
  ) => { type: string; attributeEducation: Education[] };
  setPanelChildren: (
    attributeChildren: Children[]
  ) => { type: string; attributeChildren: Children[] };
  setVisitedStep: typeof uiStateActions.setVisitedStep;
  setPanelRegion: (regions: Region[]) => void;
  setIndividualAttributes: (individualAttributes: IndividualAttribute[]) => void;
}

interface StateProps {
  panel: Panel;
  token: string;
  visitedSteps: string[];
  panelConfiguration: [];
  isLoading: boolean;
  transientQuestionnaire: Survey;
  surveyTitle: string;
  surveyId: ID;
  isAuthenticated: boolean;
  globalPanelErrors: ErrorMessage[];
  criteriaPanelErrors: ErrorMessage[];
  globalQuestionnaireErrors: ErrorMessage[];
  questionQuestionnaireErrors: ErrorMessage[];
  autoPricing: boolean;
}

interface State {
  fixedContainer: boolean;
  sidebarWidth: number;
}

export type AttributeType = "household" | "educationLevel" | "familyStatus" | "children";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    checkBox: {
      background: blueGradient,
      borderRadius: "4px",
      color: "#fff !important",
      fontSize: "11px !important",
      marginRight: theme.spacing(4),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2.75),
      paddingRight: theme.spacing(4),
      width: 121,
      "& svg": {
        width: 22,
        height: 22,
        fill: "#fff",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    householdCheckbox: {
      color: theme.palette.grey[400],
    },
    panelCard: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(6),
      paddingRight: theme.spacing(8),
      paddingLeft: theme.spacing(8),
    },
    panelLine: {
      marginBottom: theme.spacing(2),
    },
    greyText: {
      color: theme.palette.grey[400],
    },
    smallInput: {
      width: 60,
    },
    fixedContainer: {
      position: "fixed",
      top: 0,
      borderRadius: 5,
      padding: `${theme.spacing(6)}px 0px 0px 0px`,
      maxWidth: "21%",
    },
    attributesButtonContainer: {},
    errorText: {
      marginBottom: theme.spacing(4),
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  State &
  WithTranslation &
  WithStyles<typeof styles>;

export interface AttributeConfig {
  title: string;
  options: OptionsType[];
}

class StepPanel extends React.Component<Props, State> {
  intersector: React.RefObject<HTMLDivElement>;
  observer: IntersectionObserver;
  sidebar: React.RefObject<any>;

  state: State = {
    fixedContainer: false,
    sidebarWidth: 0,
  };

  constructor(props: Props) {
    super(props);
    this.intersector = React.createRef();
    this.sidebar = React.createRef();
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0.0, 1.0],
    };

    this.observer = new IntersectionObserver(this.callback, options);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    window.addEventListener("resize", this.handleResize);

    if (this.sidebar && this.sidebar.current) {
      this.setState({
        sidebarWidth: this.sidebar.current.offsetWidth,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (this.sidebar && this.sidebar.current) {
      this.setState({
        sidebarWidth: this.sidebar.current.offsetWidth,
      });
    }
  };

  callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        this.setState({
          fixedContainer: false,
        });
      } else {
        this.setState({
          fixedContainer: true,
        });
      }
    });
  };

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    if (!isEqual(nextProps, this.props) || !isEqual(nextState, this.state)) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps: any) {
    if (
      this.props.panel.criteria.length !== 0 &&
      this.props.panel.id !== undefined &&
      this.props.panel.age.attributeId.trim().length > 0 &&
      (!isEqual(prevProps.panel, this.props.panel) ||
        !isEqual(prevProps.surveyTitle, this.props.surveyTitle))
    ) {
      this.handleOnSaveClick();
      if (this.intersector.current !== null) {
        this.observer.observe(this.intersector.current);
      }
    }

    if (this.sidebar && this.sidebar.current) {
      this.setState({
        sidebarWidth: this.sidebar.current.offsetWidth,
      });
    }

    if (this.intersector.current !== null) {
      this.observer.observe(this.intersector.current);
    }
  }

  handleOnSaveClick = () => {
    const {
      updateTransientSurvey,
      transientQuestionnaire,
      surveyTitle,
      panel,
      surveyId,
      token,
      autoPricing,
    } = this.props;
    if (this.props.panel.criteria.length !== 0 && this.props.panel.id !== undefined) {
    autoPricing &&
      updateTransientSurvey(
        new Survey({
          id: surveyId,
          title: surveyTitle,
          questionnaire: transientQuestionnaire,
          panel,
          token,
          autoPricing,
        }).toDataJson()
      );
    }
  };

  nextStep = (step: string) => {
    const { handleNext, setVisitedStep, visitedSteps } = this.props;
    handleNext();
    if (!visitedSteps.includes(step)) {
      setVisitedStep(step);
    }
  };

  generateErrorTooltipMessage = () => {
    const {
      panelConfiguration,
      globalQuestionnaireErrors,
      questionQuestionnaireErrors,
      globalPanelErrors,
      criteriaPanelErrors,
      classes,
      t,
    } = this.props;

    const errors = globalPanelErrors.concat(criteriaPanelErrors).map((error) => {
      const attribute: any = panelConfiguration.find((item: any) => item.id === error.elementId);
      return {
        message: error.message,
        element: attribute ? `${attribute.name}:` : "",
      };
    });

    const questionnaireErrors = globalQuestionnaireErrors
      .concat(questionQuestionnaireErrors)
      .map((item) => {
        return {
          message: item.message.toString(),
          element: t(translations.panel.questionnairePageError),
        };
      });

    return errors.concat(questionnaireErrors).map((error, index) => (
      <Typography key={`error-${index}`} className={classes.errorText}>
        {`${error.element} ${error.message} `}
      </Typography>
    ));
  };

  render() {
    const {
      stepConfiguration,
      t,
      classes,
      panel,
      isLoading,
      setPanelRegion,
      setIndividualAttributes,
      panelConfiguration,
    } = this.props;

    if (isLoading) {
      return <LoadingOverlay />;
    }

    const panelErrors = this.props.globalPanelErrors.concat(this.props.criteriaPanelErrors);


    return (
      <>
        {stepConfiguration && (
          <Introduction
            title={stepConfiguration.title}
            description={stepConfiguration.description}
          />
        )}
        <Grid container={true}>
          <ContentWrapper>
            <Container>
              <DemographicsCard
                sectionAttributes={panelConfiguration.filter(
                  (attribute: any) => attribute.panelOptions.panelGroup === 1
                )}
              />

              <Card isRaised={true} className={classes.panelCard}>
                <RegionalPanelDefinition
                  handleChange={setPanelRegion}
                  selectedOptions={[
                    ...panel.county.answer,
                    ...panel.state.answer,
                    ...panel.leitregion.answer,
                    ...panel.populationDensity.answer,
                    ...panel.touristArea.answer,
                  ]}
                  sectionAttributes={panelConfiguration.filter(
                    (attribute: any) => attribute.panelOptions.panelGroup === 2
                  )}
                />
              </Card>

              <Card isRaised={true} className={classes.panelCard}>
                <StaticAttributesCard
                  sectionAttributes={panelConfiguration.filter(
                    (attribute: any) => attribute.panelOptions.panelGroup === 3
                  )}
                />
              </Card>

              <Card isRaised={true} className={classes.panelCard}>
                <Grid container={true} direction="column" className={classes.panelLine}>
                  <Typography variant="h3" color="primary" style={{ textTransform: "none" }}>
                    {t(translations.summary.panel.freeAttributes.title)}
                  </Typography>
                  <Typography variant="body1" style={{ textTransform: "none", marginTop: 8 }}>
                    {t(translations.summary.panel.freeAttributes.text)}
                    <br />
                    {t(translations.summary.panel.freeAttributes.text2)}
                  </Typography>

                  <IndividualAttributesDefinition
                    handleChange={setIndividualAttributes}
                    selectedOptions={panel.individual}
                  />
                </Grid>
              </Card>
              <Grid container={true} direction="row" justifyContent="flex-end" alignItems="center">
                {this.props.isAuthenticated && (
                  <Button
                    color="secondary"
                    size="medium"
                    onClick={this.handleOnSaveClick}
                    style={{ marginRight: 24 }}
                  >
                    {t(translations.action.save)}
                  </Button>
                )}
                <Button
                  color="secondary"
                  size="big"
                  contained={true}
                  disabled={
                    Boolean(panelErrors.length)
                  }
                  onClick={() => {
                    this.nextStep("questionnaire");
                  }}
                >
                  {t(translations.action.next)}
                </Button>
              </Grid>
            </Container>

            <SidebarContainer>
              <div ref={this.sidebar}>
                <div ref={this.intersector} style={{ height: 20 }} />
                <div
                  className={this.state.fixedContainer ? classes.fixedContainer : ""}
                  style={{
                    width: this.state.sidebarWidth > 0 ? `${this.state.sidebarWidth}px` : "auto",
                  }}
                >
                  {this.intersector && (
                    <ErrorToolTip
                      open={Boolean(this.generateErrorTooltipMessage().length)}
                      placement="bottom"
                      title={<>{this.generateErrorTooltipMessage()}</>}
                      style={{
                        width: this.state.sidebarWidth > 0 ? this.state.sidebarWidth : "auto",
                      }}
                    >
                      <div>
                        <LivePriceContainer widthForFixedPosition={this.state.sidebarWidth} />
                      </div>
                    </ErrorToolTip>
                  )}
                  <SupportContainer
                    style={{
                      width: this.state.sidebarWidth > 0 ? `${this.state.sidebarWidth}px` : "auto",
                    }}
                  />
                </div>
              </div>
            </SidebarContainer>
          </ContentWrapper>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ survey, uiState, surveyError, session }: RootState) => ({
  isAuthenticated: session.authenticated,
  transientQuestionnaire: survey.questionnaire,
  panel: survey.panel,
  surveyTitle: survey.title,
  surveyId: survey.id,
  isLoading: survey.isLoading,
  autoPricing: survey.autoPricing,
  panelConfiguration: survey.panelConfiguration,
  visitedSteps: uiState.visitedSteps,
  token: survey.token && survey.token,
  globalPanelErrors: surveyError.globalPanelErrors,
  criteriaPanelErrors: surveyError.criteriaPanelErrors,
  globalQuestionnaireErrors: surveyError.globalQuestionnaireErrors,
  questionQuestionnaireErrors: surveyError.questionQuestionnaireErrors,
});

const mapDispatchToProps = {
      setSurveyTitle,
      updateTransientSurvey,
      setPanelFamilyStatus,
      setPanelEducation,
      setPanelChildren,
      setPanelRegion,
      setVisitedStep: uiStateActions.setVisitedStep,
      setIndividualAttributes,
      fetchPanelConfiguration,
    };

export default compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles)
)(StepPanel);
