import React from "react";
import { compose } from "recompose";
import { createStyles, withStyles, WithStyles, Grid, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../constants/lang/translation";

import { QuopinionTheme } from "../../constants/Theme";
import Button from "../Layout/components/Button";
import { HashLink as Link } from "react-router-hash-link";
import { route as ParticipantLandingpageRoute } from "../../pages/participant/LandingPage";
import QuopinionLogo from "../../assets/logo.png";

interface OwnProps {
  title: string;
  description: React.ReactNode;
  backgroundColor: string;
  firstButtonText: string;
  firstButtonLink: string;
  children: React.ReactNode;
  video: string;
  posterImage: string;
  subtitle: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
      "@media (min-width: 1024px)": {
        padding: `${theme.spacing(8)}px ${theme.spacing(0)}px ${theme.spacing(
          12.5
        )}px ${theme.spacing(10.5)}px`,
      },
    },
    description: {
      color: theme.palette.grey[400],
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(6)}px`,
      "@media (min-width: 600px)": {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(6)}px`,
      },
      maxWidth: theme.spacing(91.75),
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.71,
    },
    logo: {
      maxWidth: theme.spacing(30),
      "@media(min-width: 600px)": {
        marginBottom: theme.spacing(6),
      },
    },
    image: {
      maxHeight: 504,
      position: "absolute",
      display: "none",
      marginLeft: theme.spacing(4),
      "@media (min-width: 768px)": {
        display: "block",
      },
      "@media(min-width: 995px)": {
        right: 0,
      },
    },
    wrapper: {
      backgroundColor: (props: OwnProps) => props.backgroundColor,
      position: "relative",
    },
    pictureButtonWrapper: {
      flexGrow: 1,
      position: "relative",
    },
    link: {
      textDecoration: "none",
    },
    participantLink: {
      textDecoration: "none",
    },
    participantButton: {
      textDecoration: "none",
      position: "absolute",
      top: theme.spacing(6),
      right: theme.spacing(10.5),
    },
    buttonContainer: {
      display: "flex",
      margin: `0px ${theme.spacing(-2)}px`,
      "& button": {
        margin: `0px ${theme.spacing(2)}px`,
      },
      flexDirection: "column",
      height: 154,
      marginBottom: theme.spacing(10),
      justifyContent: "space-evenly",
      flexWrap: "wrap",
      "@media(min-width:765px)": {
        flexDirection: "row",
        height: 104,
        justifyContent: "unset",
      },
      "@media(min-width:1127px)": {
        height: "unset",
      },
    },
    headline: {
      fontFamily: "catamaranBold",
      fontSize: "32px",
      fontWeight: 200,
      lineHeight: 1.25,
      "@media(min-width: 1024px)": {
        fontSize: "40px",
        fontWeight: 200,
        lineHeight: 1.1,
      },
      letterSpacing: "normal",
      maxWidth: theme.spacing(106.25),
    },
    subHeadline: {
      fontFamily: "catamaranBold",
      fontSize: "32px",
      fontWeight: 200,
      lineHeight: 1.25,
      "@media(min-width: 1024px)": {
        fontWeight: 200,
        lineHeight: 1.1,
      },
      letterSpacing: "normal",
      maxWidth: theme.spacing(106.25),
    },
    videoWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "@media(min-width:600px)": {
        display: "block",
        justifyContent: "unset",
      },
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
    },
    videoItem: {
      width: 163,
      height: 92,
      "@media (min-width:280px)": {
        width: 256,
        height: 146,
      },
      "@media (min-width:310px)": {
        width: 280,
        height: 160,
      },
      "@media(min-width:400px)": {
        width: 355,
        height: 200,
      },
    },
    participantLPLinkWrapper: {
      flexGrow: 1,
      display: "flex",
      "@media(min-width: 767px)": {
        justifyContent: "flex-end",
      },
      justifyContent: "flex-start",
      padding: `0 ${theme.spacing(10.5)}px 0 ${theme.spacing(4)}px`,
      marginBottom: theme.spacing(5),
      color: theme.palette.grey[400],
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const LandingpageHeaderItem: React.FC<Props> = ({
  title,
  description,
  classes,
  t,
  firstButtonText,
  firstButtonLink,
  children,
  video,
  posterImage,
  subtitle,
}) => {
  const calendlyRoute = "https://calendly.com/quopinion/intro";
  return (
    <Grid container={true} className={classes.wrapper}>
      {/*left side text*/}
      <Grid item={true} xs={12} sm={6} md={6} className={classes.root}>
        <img src={QuopinionLogo} alt="Quopinion Logo" className={classes.logo} />

        <Typography className={classes.headline} color="primary">
          {title}
        </Typography>
        <Typography className={classes.subHeadline} color="primary">
          {subtitle}
        </Typography>
        <div className={classes.videoWrapper}>
          <video controls poster={posterImage} autoPlay={false} className={classes.videoItem}>
            <source src={video} type="video/mp4" />
          </video>
        </div>
        {description && (
          <Typography variant="body1" color="inherit" className={classes.description}>
            {description}
          </Typography>
        )}
        <div className={classes.buttonContainer}>
          <Link to={firstButtonLink} smooth={true} className={classes.link}>
            <Button size="medium" color="secondary" contained={true}>
              {firstButtonText}
            </Button>
          </Link>
          <a href={calendlyRoute} className={classes.link}>
            <Button size="medium" color="secondary" contained={true}>
              {t(translations.pages.landingpage.researcher.bookCall)}
            </Button>
          </a>
          <a href={ParticipantLandingpageRoute} className={classes.link}>
            <Button size="medium" color="primary" contained={true}>
              {t(translations.pages.landingpage.researcher.participantArea)}
            </Button>
          </a>
        </div>
      </Grid>
      {/*child right element*/}
      <Grid item={true} xs={12} sm={6}>
        {children && children}
      </Grid>
    </Grid>
  );
};

export const LandingpageHeader = compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(LandingpageHeaderItem);
