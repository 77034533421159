import React from "react";
import { BarChart, XAxis, YAxis, Bar, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { DataItem } from "./QuestionResultItem";
import XAxisValueTooltip from "./XAxisValueTooltip";

interface Props {
  data: DataItem[];
  colors: string[];
}

const BarDiagram: React.FC<Props> = ({ data, colors }: Props) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data} barCategoryGap="15%" margin={{ bottom: 30 }}>
      {/*//@ts-ignore*/}
        <XAxis type="category" dataKey="name" interval={0} tick={XAxisValueTooltip} />
        <YAxis type="number" />
        <Tooltip />
        <Bar background={true} label={true} dataKey="value" fill="#0b3b5f">
          {data.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarDiagram;
