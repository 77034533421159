import * as React from "react";
import { QuopinionTheme } from "../../../constants/Theme";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";

import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";

import Header from "../../../common/Layout/Header";
import { IconButton, Typography } from "@material-ui/core";
import { translations } from "../../../constants/lang/translation";
import { PureComponent } from "react";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import { PriceItem } from "./PriceItem";
import { RootState } from "../../../modules";
import { connect } from "react-redux";
import { clearTransientPrices } from "../../../modules/opinionValue";
import { Edit } from "@material-ui/icons";
import PriceItemForm from "./PriceItemForm";
import OpinionValue from "../../../entities/OpinionValue";
import Container from "../../../common/Layout/Container";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import { fetchParticipantInfo } from "../../../modules/user";
import LoadingOverlay from "../../../common/LoadingOverlay";

interface OwnProps {}
interface State {
  inEditMode: boolean;
}

interface StateProps {
  priceData: OpinionValue;
  opinionValues: any;
  isLoading: boolean;
}
interface DispatchProps {
  fetchParticipantInfo: typeof fetchParticipantInfo;
  clearTransientPrices: () => { type: string };
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    textItem: {
      marginTop: theme.spacing(2),
      color: "#fff",
    },
    savedPrice: {
      marginLeft: theme.spacing(2),
    },
    priceWrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: theme.spacing(21), //adapting to max value in priceItemForm
    },
  });

type Props = OwnProps & StateProps & DispatchProps & WithTranslation & WithStyles<typeof styles>;

export const route: string = "/participant/opinionValue";
class OpinionValuePage extends PureComponent<Props, State> {
  state: State = {
    inEditMode: false,
  };

  constructor(props: Props) {
    super(props);
    this.props.fetchParticipantInfo();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  cancelPriceChanges = () => {
    this.props.clearTransientPrices();
    this.toggleEditMode();
  };

  toggleEditMode = () => {
    this.setState({
      inEditMode: !this.state.inEditMode,
    });
  };

  renderPriceItems = (props: Props) => {
    const { t, classes, opinionValues, isLoading } = this.props;

    if (isLoading) {
      return null;
    }

    return (
      <>
        {/*Preis S*/}
        <PriceItem
          title={`${t(translations.register.participant.opinion.shortQuestionnaires)} (5 ${t(
            translations.participant.opinionValue.minutesAbbrev
          )}.)`}
          implicationsInfo={t(translations.participant.opinionValue.mediumSelection)}
        >
          <div className={classes.priceWrapper}>
            <Typography className={classes.savedPrice} id={"savedSmallPrice"}>
              {opinionValues.participationPrice &&
                opinionValues.participationPrice.small.toString()}
            </Typography>
            <Typography>{t(translations.register.participant.opinion.cent)}</Typography>
          </div>
        </PriceItem>
        {/*Preis M*/}
        <PriceItem
          title={`${t(translations.register.participant.opinion.mediumQuestionnaires)} (15 ${t(
            translations.participant.opinionValue.minutesAbbrev
          )}.)`}
          implicationsInfo={t(translations.participant.opinionValue.mediumSelection)}
        >
          <div className={classes.priceWrapper}>
            <Typography className={classes.savedPrice}>
              {opinionValues.participationPrice && opinionValues.participationPrice.medium}
            </Typography>
            <Typography>{t(translations.register.participant.opinion.cent)}</Typography>
          </div>
        </PriceItem>
        {/*Preis L*/}
        <PriceItem
          title={`${t(translations.register.participant.opinion.longQuestionnaires)} (30 ${t(
            translations.participant.opinionValue.minutesAbbrev
          )}.)`}
          implicationsInfo={t(translations.participant.opinionValue.mediumSelection)}
        >
          <div className={classes.priceWrapper}>
            <Typography className={classes.savedPrice}>
              {opinionValues.participationPrice && opinionValues.participationPrice.large}
            </Typography>
            <Typography>{t(translations.register.participant.opinion.cent)}</Typography>
          </div>
        </PriceItem>
      </>
    );
  };

  render() {
    const { t, classes, isLoading } = this.props;
    const { inEditMode } = this.state;

    const editIcon = (
      <IconButton onClick={this.toggleEditMode} id={"editOpinionValue"}>
        <Edit />
      </IconButton>
    );

    if (isLoading) {
      return <LoadingOverlay />;
    }

    return (
      <>
        <LogoBarParticipant backButton={true} hideLogo={true} />
        <Header
          headline={t(translations.register.participant.opinion.title)}
          hasLargeMarginBelowTitle={Boolean(inEditMode)}
          actionNode={!inEditMode && editIcon}
        >
          <Typography className={classes.textItem}>
            {t(translations.participant.opinionValue.questionForValue)}
          </Typography>
          <Typography className={classes.textItem}>
            {t(translations.participant.opinionValue.impactOnSurveysText)}
          </Typography>
        </Header>
        {/*PriceComponent*/}
        <ContentWrapper blockDisplay={true}>
          <Container centered={true}>
            {inEditMode ? (
              <PriceItemForm
                toggleEditModeFunction={this.toggleEditMode}
                cancelPriceChanges={this.cancelPriceChanges}
              />
            ) : (
              this.renderPriceItems(this.props)
            )}
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

const mapStateToProps = ({ opinionValue, user }: RootState) => ({
  opinionValues: user.participantInfo,
  isLoading: user.isLoading,
  priceData: opinionValue.priceData,
});
const mapDispatchToProps = {
  clearTransientPrices,
  fetchParticipantInfo,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(OpinionValuePage);
