import React, {PureComponent} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {compose} from "recompose";
import {Typography, TextField, createStyles, WithStyles, withStyles} from "@material-ui/core";
import {translations} from "../../../constants/lang/translation";
import {QuopinionTheme} from "../../../constants/Theme";
import Button from "../../../common/Layout/components/Button";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import Services from "../../../services/Services";
import AttributeSelect, {OptionsType} from "../../../common/Layout/components/AttributeSelect";
import FormCheckbox from "../../../common/Preview/components/FormCheckbox";
import Card from "../../../common/Layout/components/Card";

interface OwnProps {
}

interface State {
  error: boolean;
  success: boolean;
  send: boolean;
  isVerified: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    formWrapper: {
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing(8)}px ${theme.spacing(6)}px`,
      marginTop: theme.spacing(6),
    },
    input: {
      marginBottom: theme.spacing(6),
      width: "100%",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    itemTitle: {
      fontWeight: "initial",
      fontSize: "16px",
      lineHeight: 1.33,
    },
    seperateError: {
      marginBottom: theme.spacing(6),
    },
    phoneBackWrapper: {
      marginBottom: theme.spacing(8),
    },
    message: {
      marginTop: theme.spacing(4),
      borderRadius: 4,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[200],
    },
    backgroundHP: {
      opacity: 0,
      position: "absolute",
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      zIndex: -1
  },
});
const TitleOptions: OptionsType[] = [
  {label: "Herr", value: "HERR"},
  {label: "Frau", value: "FRAU"},
];

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

class ContactForm extends PureComponent<Props, State> {
  state = {
    error: false,
    success: false,
    send: false,
    isVerified: false,
  };

  readonly initialValues = {
    title: {label: "", value: ""},
    firstName: "",
    lastName: "",
    mail: "",
    phoneNr: "",
    companyName: "",
    companySector: "",
    position: "",
    message: "",
    phoneBack: false,
    email: "",
    name: ""
  };

  readonly validationSchema = () => {
    const {t} = this.props;
    return Yup.object().shape({
      title: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().required(
          t(translations.pages.contact.form.researcher.validation.requiredTitle)
        ),
      }),
      firstName: Yup.string().min(
        2,
        t(translations.pages.contact.form.researcher.validation.firstNameLength)
      ),
      lastName: Yup.string()
        .min(2, t(translations.pages.contact.form.researcher.validation.lastNameLength))
        .required(t(translations.pages.contact.form.researcher.validation.requiredLastName)),
      mail: Yup.string()
        .trim()
        .email(t(translations.validation.login.emailNotValid))
        .matches(/^[\w]*[^áéíóúäöü]*$/, {
          message: "Die Eingabe darf nicht aus Zeichen wie: á,é,í,ó,ú,ä,ö,ü bestehen.",
        }) //regex adapted from regex in researcher RegisterForm
        .required(t(translations.validation.login.emailRequired)),
      phoneNr: Yup.number()
        .typeError(t(translations.pages.contact.form.researcher.validation.phoneNrType))
        .when("phoneBack", {
          is: true,
          then: Yup.number().required(
            t(translations.pages.contact.form.researcher.validation.requiredPhoneNr)
          ),
        }),
      companyName: Yup.string()
        .trim()
        .min(2, t(translations.pages.contact.form.researcher.validation.companyNameLength)),
      companySector: Yup.string()
        .trim()
        .min(2, t(translations.pages.contact.form.researcher.validation.companySectorLength)),
      position: Yup.string()
        .trim()
        .min(2, t(translations.pages.contact.form.researcher.validation.positionLength)),
      message: Yup.string().required(
        t(translations.pages.contact.form.researcher.validation.requiredMessage)
      ),
      name: Yup.string(),
      email: Yup.string()
    });
  };

  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  handleChange(handleChange: (field: string) => (value: string) => void, field: string) {
    return (values: string[]) => {
      if (values.length) {
        handleChange(field)(values[0]);
      }
    };
  }

  render() {
    const {t, classes} = this.props;
    const {send, error, success} = this.state;
    return (
      <>
        <Card className={classes.formWrapper}>
          <Formik
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            onSubmit={(values, {setSubmitting, resetForm}) => {
              setSubmitting(true);
              Services.user
                .sendContactMailRequestResearcher({
                  salutationType: values.title.value,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  emailAddress: values.mail,
                  phone: values.phoneNr,
                  companyName: values.companyName,
                  companyIndustry: values.companySector,
                  companyPosition: values.position,
                  phoneBack: values.phoneBack,
                  message: values.message,
                  name: values.name,
                  email: values.email
                })
                .then(() => {
                  setSubmitting(false);
                  resetForm();
                  this.setState({send: true});
                  this.setState({success: true});
                })
                .catch(() => {
                  setSubmitting(false);
                  this.setState({send: false});
                  this.setState({error: true});
                });
            }}
          >
            {(formikProps) => {
              const {
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                isSubmitting,
              } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  {/*Title*/}
                  <label className={classes.itemTitle}>
                    {t(translations.pages.contact.form.researcher.title)}
                  </label>
                  <Field
                    name={"titleValue"}
                    render={({field}: any) => (
                      <AttributeSelect
                        {...field}
                        placeholder={t(translations.pages.contact.form.researcher.title)}
                        handleSelection={this.handleChange(
                          handleChange as (field: string) => (value: string) => void,
                          "title.value"
                        )}
                        selectOptions={TitleOptions}
                        selectedValues={values.title.value.length ? [values.title.value] : []}
                        onBlur={handleBlur("title.value")}
                        required={true}
                        multiple={false}
                        error={touched.title && Boolean(errors.title)}
                      />
                    )}
                  />
                  <Typography color="error" className={classes.seperateError}>
                    {touched.title && errors.title ? `${errors.title.value}` : ""}
                  </Typography>
                  {/*FirstName*/}
                  <label className={classes.itemTitle}>
                    {t(translations.pages.contact.form.researcher.firstName)}
                  </label>
                  <TextField
                    name={t(translations.pages.contact.form.researcher.firstName)}
                    required={false}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.researcher.firstName)}
                    value={values.firstName}
                    onChange={handleChange("firstName")}
                    onBlur={handleBlur("firstName")}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={
                      errors.firstName && touched.firstName ? (
                        <Typography variant="body1">{errors.firstName}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  {/*LastName*/}
                  <label className={classes.itemTitle}>
                    {t(translations.pages.contact.form.researcher.lastName)}
                  </label>
                  <TextField
                    name={"lastName"}
                    required={true}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.researcher.lastName)}
                    value={values.lastName}
                    onChange={handleChange("lastName")}
                    onBlur={handleBlur("lastName")}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={
                      errors.lastName && touched.lastName ? (
                        <Typography variant="body1">{errors.lastName}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  {/*Mail*/}
                  <label className={classes.itemTitle}>
                    {t(translations.pages.contact.form.researcher.mail)}
                  </label>
                  <TextField
                    name={"mail"}
                    required={true}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.researcher.mail)}
                    value={values.mail}
                    onChange={handleChange("mail")}
                    onBlur={handleBlur("mail")}
                    error={touched.mail && Boolean(errors.mail)}
                    helperText={
                      errors.mail && touched.mail ? (
                        <Typography variant="body1">{errors.mail}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  {/*Phone number*/}
                  <label className={classes.itemTitle}>
                    {t(translations.pages.contact.form.researcher.phoneNr)}
                  </label>
                  <TextField
                    type="tel"
                    name={"phoneNr"}
                    required={false}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.researcher.phoneNr)}
                    value={values.phoneNr}
                    onChange={handleChange("phoneNr")}
                    onBlur={handleBlur("phoneNr")}
                    error={touched.phoneNr && Boolean(errors.phoneNr)}
                    helperText={
                      errors.phoneNr && touched.phoneNr ? (
                        <Typography variant="body1">{errors.phoneNr}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  {/*CompanyName*/}
                  <label className={classes.itemTitle}>
                    {t(translations.pages.contact.form.researcher.companyName)}
                  </label>
                  <TextField
                    name={"companyName"}
                    required={false}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.researcher.companyName)}
                    value={values.companyName}
                    onChange={handleChange("companyName")}
                    onBlur={handleBlur("companyName")}
                    error={touched.companyName && Boolean(errors.companyName)}
                    helperText={
                      errors.companyName && touched.companyName ? (
                        <Typography variant="body1">{errors.companyName}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  {/* CompanySector*/}
                  <label className={classes.itemTitle}>
                    {t(translations.pages.contact.form.researcher.companySector)}
                  </label>
                  <TextField
                    name={"companySector"}
                    required={false}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.researcher.companySector)}
                    value={values.companySector}
                    onChange={handleChange("companySector")}
                    onBlur={handleBlur("companySector")}
                    error={touched.companySector && Boolean(errors.companySector)}
                    helperText={
                      errors.companySector && touched.companySector ? (
                        <Typography variant="body1">{errors.companySector}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  {/*  Position*/}
                  <label className={classes.itemTitle}>
                    {t(translations.pages.contact.form.researcher.position)}
                  </label>
                  <TextField
                    name={"position"}
                    required={false}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.researcher.position)}
                    value={values.position}
                    onChange={handleChange("position")}
                    onBlur={handleBlur("position")}
                    error={touched.position && Boolean(errors.position)}
                    helperText={
                      errors.position && touched.position ? (
                        <Typography variant="body1">{errors.position}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  {/*  Message*/}
                  <label className={classes.itemTitle}>
                    {t(translations.pages.contact.form.message)}
                  </label>
                  <TextField
                    name={"message"}
                    required={true}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.message)}
                    multiline={true}
                    rows={10}
                    value={values.message}
                    onChange={handleChange("message")}
                    onBlur={handleBlur("message")}
                    error={touched.message && Boolean(errors.message)}
                    helperText={
                      errors.message && touched.message ? (
                        <Typography variant="body1">{errors.message}</Typography>
                      ) : (
                        ""
                      )
                    }
                  />
                  <div className={classes.phoneBackWrapper}>
                    <FormCheckbox
                      required={false}
                      label={t(translations.pages.contact.researcher.phoneBack)}
                      answerValue="phoneBack"
                      checked={values.phoneBack}
                      key="data"
                      error={Boolean(errors.phoneBack)}
                      onChange={handleChange("phoneBack")}
                      onBlur={handleBlur("phoneBack")}
                      value={values.phoneBack}
                      helperText={
                        errors.phoneBack && touched.phoneBack ? `${errors.phoneBack}` : ""
                      }
                    />
                  </div>
                  <div className={classes.backgroundHP}>
                    <label htmlFor="name"/>
                    <input autoComplete="off" type="text" id="name" name="name" value={values.name}
                           onChange={handleChange("name")} placeholder="Your name here"/>
                    <label htmlFor="email"/>
                    <input autoComplete="off" type="email" id="email" name="email" value={values.email}
                           onChange={handleChange("email")} placeholder="Your e-mail here"/>
                  </div>
                  <div className={classes.buttonWrapper}>
                    <Button
                      color="secondary"
                      contained={true}
                      size="big"
                      onClick={handleSubmit}
                      type="submit"
                      disabled={isSubmitting || send}
                    >
                      {t(translations.action.send)}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
          {send && (
            <div className={classes.message}>
              {success && (
                <Typography color="primary">
                  {t(translations.pages.contact.researcher.success)}
                </Typography>
              )}
              {error && (
                <Typography color="primary">
                  {t(translations.pages.contact.researcher.error)}
                </Typography>
              )}
            </div>
          )}
        </Card>
      </>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(ContactForm);
