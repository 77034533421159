import React from "react";
import ContentWrapper from "../../common/Layout/ContentWrapper";
import ErrorPageHeader from "../ErrorExpiredAccess/ErrorPageHeader";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../constants/lang/translation";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

export const route: string = "/error/:errorCode";
interface OwnProps {}

type Props = OwnProps & WithTranslation & RouteComponentProps<{ errorCode: string }>;

const GeneralErrorPage: React.FC<Props> = ({
  t,
  match: {
    params: { errorCode },
  },
}) => {
  return (
    <ContentWrapper>
      <ErrorPageHeader
        title={t(translations.pages.Error.title)}
        description={t(translations.pages.Error.description)}
        errorCode={errorCode}
      />
    </ContentWrapper>
  );
};
export default compose<Props, OwnProps>(
  withRouter,
  withTranslation()
)(GeneralErrorPage);
