import React, { PureComponent } from "react";
import { Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { QuopinionTheme } from "../../../../constants/Theme";
import { compose } from "recompose";
import { translations } from "../../../../constants/lang/translation";
import * as Table from "../../../../common/Layout/components/CardTable";
import Survey, { formatDuration } from "../../../../entities/Survey";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    priceTable: {
      width: "100%",
    },
    priceTableRow: {
      paddingTop: theme.spacing(6),
      "&:first-of-type": {
        paddingTop: theme.spacing(0),
      },
    },
    priceValue: {
      textAlign: "end",
    },
    buttonLine: {
      marginTop: theme.spacing(8),
    },
  });

interface OwnProps {
  survey: Survey;
}

interface DispatchProps {}

type Props = OwnProps & DispatchProps & WithStyles<typeof styles> & WithTranslation;

class PriceSummary extends PureComponent<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes, t, survey } = this.props;

    return (
      <>
        <Table.HeaderRow title={t(translations.summary.price.headline)} />
        <Table.Row>
          <table className={classes.priceTable}>
            <tbody>
              <tr className={classes.priceTableRow}>
                <td>
                  <Typography variant="body2" color="primary">
                    {t(translations.summary.price.participants)}
                  </Typography>
                  <Typography variant="body1">
                    {survey.panel.size} {t(translations.infoSurveyItem.participants.title)} <br />
                    {/* TODO: add condition for rendering title (long, medium or short length)
                              when data is available */}
                    {t(translations.summary.price.surveyLength[survey.complexity!])} (
                    {formatDuration(survey.duration, t, true)} ,{" "}
                    {survey.questionnaire.questions.length}&nbsp;
                    {t(translations.questions.question, {
                      count: survey.questionnaire.questions.length,
                    })}
                    )
                  </Typography>
                </td>
                <td className={classes.priceValue}>
                  <Typography variant="body1">{survey.price}</Typography>
                </td>
              </tr>
              <tr className={classes.priceTableRow}>
                <td>
                  <Typography variant="body2" color="primary">
                    {t(translations.summary.price.taxes)}
                  </Typography>
                </td>
                <td className={classes.priceValue}>
                  <Typography variant="body1">{survey.taxes}</Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Table.Row>
        <Table.Row style={{ justifyContent: "space-between" }}>
          <Typography variant="h3" style={{ textTransform: "capitalize" }}>
            {t(translations.summary.price.finalPrice)}
          </Typography>
          <Typography variant="h3" id="price-with-taxes">
            {survey.priceWithTaxes}
          </Typography>
        </Table.Row>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(PriceSummary);
