import React, { ReactNode } from "react";
import { createStyles, useMediaQuery, withStyles, WithStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../constants/Theme";
import Button from "../Layout/components/Button";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      position: "relative",
      zIndex: 2,
      justifyContent: "flex-end",
      marginRight: theme.spacing(6.75),
      marginTop: theme.spacing(11),
      margin: `0px ${theme.spacing(-2)}px`,
      "& button": {
        margin: `0px ${theme.spacing(2)}px`,
      },
      flexDirection: "row",
      height: "unset",
      marginBottom: 40,
      "@media(min-width:765px)": {
        flexDirection: "row",
        height: "unset",
      },
    },
    link: {
      textDecoration: "none",
    },
  });

interface OwnProps {
  headerImage: ReactNode;
  firstButtonText: string;
  firstRoute: string;
  firstButtonTextAuthenticated: string;
  firstRouteAuthenticated: string;
  secondButtonText: string;
  secondRoute: string;
  isAuthenticated: boolean | undefined;
}
type Props = OwnProps & WithStyles<typeof styles> & WithTranslation & RouteComponentProps;

const HeaderChild: React.FC<Props> = ({
  classes,
  headerImage,
  firstButtonText,
  firstRoute,
  firstButtonTextAuthenticated,
  firstRouteAuthenticated,
  secondButtonText,
  secondRoute,
  isAuthenticated,
  history,
}) => {
  const desktop = useMediaQuery("(min-width:1020px)");
  return (
    <>
      <div className={classes.buttonContainer}>
        <Button
          size={desktop ? "big" : "medium"}
          color="primary"
          onClick={() => history.push(isAuthenticated ? firstRouteAuthenticated : firstRoute)}
          contained={true}
        >
          {isAuthenticated ? firstButtonTextAuthenticated : firstButtonText}
        </Button>

        <Button
          size={desktop ? "big" : "medium"}
          color="secondary"
          contained={true}
          onClick={() => history.push(secondRoute)}
        >
          {secondButtonText}
        </Button>
      </div>
      {headerImage}
    </>
  );
};

export const HeaderChildParticipant = compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter
)(HeaderChild);
