import React from "react";
import { QuopinionTheme, secondary } from "../../../../../constants/Theme";
import { createStyles, withStyles, WithStyles, Typography, IconButton } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../../../constants/lang/translation";
import classNames from "classnames";
import { DropdownArrowGrey } from "../../../../../assets/icon";
import Badge from "../../../../../common/Layout/components/Badge";
import { EvaluatedQuestion } from "../../../../../entities/SurveyResult";
import Button from "../../../../../common/Layout/components/Button";
import QuestionResult from "../ResultComponent";
import { QuestionTypes } from "../../../../../entities/Question";
import { compose } from "recompose";
import UploadedMediaItem from "../../../../../common/Layout/components/UploadedMediaItem";
import Media from "../../../../../entities/Media";
import ID from "../../../../../entities/ID";
import MatrixSubQuestion from "../../../../../entities/MatrixSubQuestion";
import { FieldTypes } from "../../../../../entities/AnswerOption";

export interface DataItem {
  name: string;
  value: number;
  fill?: string;
  media?: Media;
  key: number;
}

export interface NumberDataItem {
  name: number;
  value: number;
  fill?: string;
  media?: Media;
}

export interface AdditionalFieldItem {
  answerOptionName: string;
  data: DataItem[];
}

interface OwnProps {
  question: EvaluatedQuestion;
  index: number;
  totalCompletedParticipations: number;
}

interface State {
  showResults: boolean;
  openSubQuestions: ID[];
}

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    summaryQuestionItem: {
      display: "flex",
      border: "1px solid",
      borderColor: theme.palette.grey[200],
      borderRight: "none",
      borderLeft: "none",
      padding: theme.spacing(5),
      backgroundColor: "#fff",
      alignItems: "center",
      justifyContent: "space-between",
    },
    summaryQuestionInfo: {
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(7.5),
    },
    toggleWrapper: {
      display: "flex",
      alignItems: "center",
    },
    toggleIcon: {
      transform: "rotate(180deg)",
    },
    questionInfo: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
    },
    text: {
      maxWidth: theme.spacing(100),
      overflowWrap: "break-word",
    },
    linkText: {
      overflowWrap: "break-word",
      marginTop: theme.spacing(4),
    },
    link: {
      color: secondary,
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class QuestionResultItem extends React.Component<Props, State> {
  state: State = {
    showResults: false,
    openSubQuestions: [],
  };

  componentDidMount(): void {
    window.addEventListener("quopinionResultsPrint", this.openPrintMode);
  }

  componentWillUnmount(): void {
    window.removeEventListener("quopinionResultsPrint", this.openPrintMode);
  }

  openResults = () => {
    this.setState({
      showResults: true,
    });
  };

  openAllSubQuestions = () => {
    this.setState({
      openSubQuestions: this.props.question.question.body.subQuestions.map(
        (subQItem) => subQItem.id
      ),
    });
  };

  openPrintMode = () => {
    this.openResults();
    this.openAllSubQuestions();
  };

  toggleResults = () => {
    this.setState({
      showResults: !this.state.showResults,
    });
  };

  toggleSubQuestion = (subQuestionId: ID) => {
    if (this.state.openSubQuestions.includes(subQuestionId)) {
      const remainingOpenSubQ = this.state.openSubQuestions.filter(
        (openItemId) => openItemId !== subQuestionId
      );
      this.setState({
        openSubQuestions: remainingOpenSubQ,
      });
    } else {
      this.setState({
        openSubQuestions: [...this.state.openSubQuestions, subQuestionId],
      });
    }
  };

  getAnswerOptionsDescription = () => {
    const { t, question } = this.props;
    if (
      question.question.type === QuestionTypes.SINGLECHOICE ||
      question.question.type === QuestionTypes.MULTIPLECHOICE
    ) {
      return `| ${question.question.body.answerOptions.length} ${t(
        translations.summary.questionnaire.replies
      )}`;
    }
    if (question.question.type === QuestionTypes.STARRATING) {
      return `| ${question.question.body.starCount} ${t(translations.summary.questionnaire.stars)}`;
    }
    if (question.question.type === QuestionTypes.MATRIX) {
      return `| ${question.question.body.subQuestions.length} ${t(
        translations.summary.questionnaire.subQuestions
      )}`;
    }

    return null;
  };

  isEven = (num: number) => {
    if (num % 2 === 0) {
      return true;
    } else {
      return false;
    }
  };

  createAdditionalFreeTextData = () => {
    const { question } = this.props;
    const specialTypes = ["stepRating", "freeText", "starRating"];

    let result: AdditionalFieldItem[];
    if (
      !specialTypes.includes(question.question.type) &&
      question.additionalFreeTextResults !== undefined
    ) {
      const optionsWithFreeText = question.question.body.answerOptions.filter(
        (option) =>
          option.additionalFieldType === FieldTypes.TEXT &&
          question.additionalFreeTextResults[option.id] !== undefined
      );

      if (Object.keys(optionsWithFreeText).length > 0) {
        result = optionsWithFreeText.map((option, index) => {
          const freeTextResults = Object.keys(question.additionalFreeTextResults[option.id]);

          const dataPerAnswerOption = freeTextResults.map((text) => {
            return {
              name: text,
              value: question.additionalFreeTextResults[option.id][text],
              key: index,
            };
          });

          return {
            answerOptionName: option.label,
            data: dataPerAnswerOption,
            key: index,
          };
        });
      } else {
        result = [];
      }
    } else {
      result = [];
    }
    return result;
  };
  createAdditionalNumberData = () => {
    const { question } = this.props;
    const specialTypes = ["stepRating", "freeText", "starRating"];

    let result: AdditionalFieldItem[];
    if (
      !specialTypes.includes(question.question.type) &&
      question.additionalNumberResults !== undefined
    ) {
      const optionsWithNumber = question.question.body.answerOptions.filter(
        (option) =>
          option.additionalFieldType === FieldTypes.NUMBER &&
          question.additionalNumberResults[option.id] !== undefined
      );

      if (Object.keys(optionsWithNumber).length > 0) {
        result = optionsWithNumber.map((option, index) => {
          const numberResults = Object.keys(question.additionalNumberResults[option.id]);

          const dataPerAnswerOption = numberResults.map((text) => {
            return {
              name: text,
              value: question.additionalNumberResults[option.id][text],
              key: index,
            };
          });

          return {
            answerOptionName: option.label,
            data: dataPerAnswerOption,
            key: index,
          };
        });
      } else {
        result = [];
      }
    } else {
      result = [];
    }
    return result;
  };

  createAbsoluteRankingData = () => {
    const { question } = this.props;
    let result: any; //may not be defined better as attributes are variable depending on answerOptions
    if (question.question.type === "ranking" && question.rankingResults !== undefined) {
      const answerOptionsOrder = question.question.body.answerOptions.map(
        (option, index) => question.rankingResults[option.id]
      );

      result = question.question.body.answerOptions.map((answerOption, index) => {
        const answerOptionRankingResults = question.rankingResults[answerOption.id];
        let arr;
        arr = answerOptionsOrder.map((obj: any, index: number) => index + 1);
        //an obj may look for 4 items like this {1:0, 2:2, 3:0, 4:0}
        //the nr of the objects' attributes depends on the question

        const orderValues = arr.map((item: number, index: number) => {
          return {
            id: index + 1,
            value: answerOptionRankingResults[index + 1],
          };
        });

        const resultObj = {
          //including: decision, previous only option.label until real fix
          name: `${index + 1}: ${answerOption.label}`,
          media: answerOption.media[0],
          key: index,
        };

        orderValues.forEach((orderItem, index: number) => {
          Object.defineProperties(resultObj, {
            [index + 1]: {
              value: orderItem,
            },
          });
        });

        orderValues.forEach((orderItem, index: number) => {
          Object.defineProperties(resultObj, {
            [index + 1]: {
              value: orderItem,
            },
          });
        });
        return resultObj;
      });
    }
    return result;
  };

  createAbsoluteData = (subQuestionId: ID) => {
    const { question } = this.props;
    const specialTypes = ["stepRating", "freeText", "starRating", "password"];

    let result: DataItem[];
    if (specialTypes.includes(question.question.type)) {
      const keys = Object.keys(question.results);
      result = keys.map((result: any, index) => ({
        name: result,
        value: question.results[result],
        key: index,
      }));
    } else if (question.question.type === "matrix") {
      result = question.question.body.answerOptions.map((option, index) => ({
        //via index until real fix of answer code
        name: `${index + 1}: ${option.label}`,
        value:
          question.matrixResults &&
          question.matrixResults[subQuestionId] !== undefined &&
          question.matrixResults[subQuestionId][option.id],
        media: option.media[0],
        key: index,
      }));
    } else {
      result = question.question.body.answerOptions.map((option, index) => ({
        //including: decision, previous only option.label until real fix
        name: `${index + 1}: ${option.label}`,
        value: question.results[option.id],
        media: option.media[0],
        key: index,
      }));
    }
    return result;
  };

  createRelativeData = (subQuestionId: ID) => {
    const { question } = this.props;
    const specialTypes = ["stepRating", "freeText", "starRating"];
    const multipleChoiceTyp = "multipleChoice";
    let result;
    if (specialTypes.includes(question.question.type)) {
      const keys = Object.keys(question.relativeResults);
      result = keys.map((result: any, index) => {
        const resultValue = question.relativeResults[result];
        return {
          name: result,
          value: !isNaN(resultValue) ? Math.round(resultValue * 100) : 0,
          key: index,
        };
      });
    } else if (multipleChoiceTyp === question.question.type) {
      result = question.question.body.answerOptions.map((option, index) => {
        const valueOfTotalBase =
          question.results[option.id] / this.props.totalCompletedParticipations;
        return {
          name: `${index + 1}: ${option.label}`,
          value: !isNaN(valueOfTotalBase) ? Math.round(valueOfTotalBase * 100) : 0,
          media: option.media[0],
          key: index,
        };
      });
    } else if (question.question.type === "matrix") {
      result = question.question.body.answerOptions.map((option, index) => {
        const resultValue =
          question.matrixRelativeResults &&
          question.matrixRelativeResults[subQuestionId && subQuestionId] !== undefined &&
          question.matrixRelativeResults[subQuestionId && subQuestionId][option.id];
        return {
          name: `${index + 1}: ${option.label}`,
          value: !isNaN(resultValue) ? Math.round(resultValue * 100) : 0,
          media: option.media[0],
          key: index,
        };
      });
    } else {
      result = question.question.body.answerOptions.map((option, index) => {
        const resultValue = question.relativeResults[option.id];
        return {
          name: `${index + 1}: ${option.label}`,
          value: !isNaN(resultValue) ? Math.round(resultValue * 100) : 0,
          media: option.media[0],
          key: index,
        };
      });
    }
    return result;
  };

  createRelativeRankingData = () => {
    const { question } = this.props;
    let result: any;
    if (question.question.type === "ranking" && question.rankingRelativeResults !== undefined) {
      const answerOptionsOrder = question.question.body.answerOptions.map(
        (option, index) => question.rankingRelativeResults[option.id]
      );

      result = question.question.body.answerOptions.map((answerOption, index) => {
        const answerOptionRankingResults = question.rankingRelativeResults[answerOption.id];
        let arr;
        arr = answerOptionsOrder.map((obj: any, index: number) => Array.from(obj));

        const orderValues = arr.map((item: any, index: number) => {
          return {
            id: index + 1,
            value: !isNaN(answerOptionRankingResults[index + 1])
              ? Math.round(answerOptionRankingResults[index + 1] * 100)
              : 0,
          };
        });

        const resultObj = {
          //including: decision, previous only option.label until real fix
          name: `${index + 1}: ${answerOption.label}`,
          media: answerOption.media[0],
          key: index,
        };

        orderValues.forEach((orderItem, index: number) => {
          Object.defineProperties(resultObj, {
            [index + 1]: {
              value: orderItem,
            },
          });
        });

        orderValues.forEach((orderItem, index: number) => {
          Object.defineProperties(resultObj, {
            [index + 1]: {
              value: orderItem,
            },
          });
        });
        return resultObj;
      });
    }
    return result;
  };

  renderSubQuestionInfo = (subQuestion: MatrixSubQuestion, subQuestionIndex: number) => {
    const { classes, question, t } = this.props;
    return (
      <div className={classes.wrapper} key={subQuestionIndex}>
        <div className={classes.summaryQuestionItem}>
          <div className={classes.questionInfo}>
            <Badge
              title={`${t(translations.summary.questionnaire.subPrefix)}${t(
                translations.questions.sg
              )} ${subQuestionIndex + 1}`}
              color="eighth"
            />
            <div className={classes.summaryQuestionInfo}>
              <Typography variant="subtitle2" className={classes.text}>
                {subQuestion.label}
              </Typography>
              {subQuestion.media.length > 0 && (
                <UploadedMediaItem media={question.question.media[0]} />
              )}
              <div style={{ display: "flex" }}>
                <Typography variant="body1">
                  {`${t(translations.questionnaire.questionTypes[question.question.type])}-${t(
                    translations.summary.questionnaire.subQuestion
                  )}`}{" "}
                  &nbsp;
                </Typography>

                {question.question.body.answerOptions.length > 0 && (
                  <Typography variant="body1">{`| ${
                    question.question.body.answerOptions.length
                  } ${t(translations.summary.questionnaire.replies)}`}</Typography>
                )}
              </div>
            </div>
          </div>
          <div
            className={classes.toggleWrapper}
            onClick={() => this.toggleSubQuestion(subQuestion.id)}
          >
            <Button color="primary" size="small" underline={true} id={"seeGraphs"}>
              {t(translations.action.seeResults)}
            </Button>
            <IconButton
              className={classNames({
                [classes.toggleIcon]: this.state.openSubQuestions.includes(subQuestion.id),
              })}
            >
              <DropdownArrowGrey />
            </IconButton>
          </div>
        </div>
        {this.state.openSubQuestions.includes(subQuestion.id) && (
          <QuestionResult
            absoluteData={this.createAbsoluteData(subQuestion.id)}
            relativeData={this.createRelativeData(subQuestion.id)}
            additionalFreeTextData={this.createAdditionalFreeTextData()}
            additionalNumberData={this.createAdditionalNumberData()}
            question={question.question}
          />
        )}
      </div>
    );
  };

  render() {
    const { showResults } = this.state;
    const { classes, question, index, t } = this.props;

    return (
      <div className={classes.wrapper}>
        <div className={classes.summaryQuestionItem}>
          <div className={classes.questionInfo}>
            <Badge title={`${t(translations.questions.sg)} ${index + 1}`} color="secondary" />
            <div className={classes.summaryQuestionInfo}>
              <Typography variant="subtitle2" className={classes.text}>
                {question.question.text}
              </Typography>
              {question.question.body.link !== null &&
                question.question.body.link &&
                question.question.body.link.length > 0 && (
                  <a
                    href={question.question.body.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    <Typography variant="subtitle2" className={classes.linkText}>
                      {question.question.body.link}
                    </Typography>
                  </a>
                )}
              {question.question.media.length > 0 && (
                <UploadedMediaItem media={question.question.media[0]} />
              )}
              <div style={{ display: "flex" }}>
                <Typography variant="body1">
                  {t(translations.questionnaire.questionTypes[question.question.type])} &nbsp;
                </Typography>

                {question.question.conditions.length > 0 && (
                  <Typography variant="body1">
                    {`| ${t(translations.pages.surveyResults.withCondition)}`} &nbsp;
                  </Typography>
                )}
                {question.question.body.answerOptions.length > 0 && (
                  <Typography variant="body1">{this.getAnswerOptionsDescription()}</Typography>
                )}
              </div>
            </div>
          </div>
          {question.question.type !== "instruction" && (
            <div className={classes.toggleWrapper} onClick={this.toggleResults}>
              <Button color="primary" size="small" underline={true} id={"seeGraphs"}>
                {t(translations.action.seeResults)}
              </Button>
              <IconButton
                className={classNames({
                  [classes.toggleIcon]: showResults,
                })}
              >
                <DropdownArrowGrey />
              </IconButton>
            </div>
          )}
        </div>
        {showResults && (
          <>
            {question.question.type !== "matrix" ? (
              <QuestionResult
                absoluteData={this.createAbsoluteData("")}
                relativeData={this.createRelativeData("")}
                rankingAbsoluteData={this.createAbsoluteRankingData()}
                rankingRelativeData={this.createRelativeRankingData()}
                additionalFreeTextData={this.createAdditionalFreeTextData()}
                additionalNumberData={this.createAdditionalNumberData()}
                question={question.question}
              />
            ) : (
              <>
                {question.question.body.subQuestions.map(
                  (subQuestion: MatrixSubQuestion, subQuestionIndex) => {
                    return this.renderSubQuestionInfo(subQuestion, subQuestionIndex);
                  }
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles), withTranslation())(QuestionResultItem);
