import { QuopinionTheme } from "../../../constants/Theme";
import { createStyles, TextField, Typography, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import React, { PureComponent } from "react";
import { compose, shallowEqual } from "recompose";
import { translations } from "../../../constants/lang/translation";
import { Formik } from "formik";
import Button from "../../../common/Layout/components/Button";
import * as Yup from "yup";
import { PriceItem } from "./PriceItem";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import {
  clearTransientPrices,
  setTransientLargePrice,
  setTransientMediumPrice,
  setTransientSmallPrice,
  savePrices,
} from "../../../modules/opinionValue";
import { updateParticipantPrice, fetchParticipantInfo } from "../../../modules/user";
import OpinionValue from "../../../entities/OpinionValue";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    form: {
      maxWidth: theme.spacing(200),
    },
    input: {
      marginBottom: theme.spacing(6),
      width: theme.spacing(20.25),
      height: theme.spacing(10),
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    priceWrapper: {
      display: "flex",
      width: theme.spacing(29),
      justifyContent: "space-between",
      alignItems: "center",
    },
    body: {
      display: "flex",
      justifyContent: "center",
    },
  });

interface OwnProps {
  cancelPriceChanges: () => void;
  savePrices?: () => void;
  toggleEditModeFunction: () => void;
}

interface StateProps {
  transientPriceS: number;
  transientPriceM: number;
  transientPriceL: number;
  savedData: OpinionValue;
}
interface DispatchProps {
  setTransientSmallPrice: (transSmallPrice: number) => { type: string; transientPrice: number };
  setTransientMediumPrice: (transMediumPrice: number) => { type: string; transientPrice: number };
  setTransientLargePrice: (transMediumPrice: number) => { type: string; transientPrice: number };
  clearTransientPrices: () => { type: string };
  savePrices: (priceData: OpinionValue) => void;
  updateParticipantPrice: (newPrice: any) => Promise<void>;
  fetchParticipantInfo: typeof fetchParticipantInfo;
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation & WithStyles<typeof styles>;

export const route = "/market-research/contact";

class PriceItemForm extends PureComponent<Props> {
  readonly initialValues = {
    priceS: this.props.transientPriceS,
    priceM: this.props.transientPriceM,
    priceL: this.props.transientPriceL,
  };

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      priceS: Yup.number()
        .typeError(t(translations.pages.contact.form.researcher.validation.phoneNrType))
        .min(0)
        .max(10000, t(translations.participant.opinionValue.validation.maxValue)),
      priceM: Yup.number()
        .typeError(t(translations.pages.contact.form.researcher.validation.phoneNrType))
        .min(Yup.ref("priceS"), t(translations.participant.opinionValue.validation.priceMpriceS))
        .max(10000, t(translations.participant.opinionValue.validation.maxValue)),
      priceL: Yup.number()
        .typeError(t(translations.pages.contact.form.researcher.validation.phoneNrType))
        .min(Yup.ref("priceM"), t(translations.participant.opinionValue.validation.priceLpriceM))
        .max(10000, t(translations.participant.opinionValue.validation.maxValue)),
    });
  };
  render() {
    const {
      t,
      classes,
      // savePrices,
      toggleEditModeFunction,
      clearTransientPrices,
      updateParticipantPrice,
      fetchParticipantInfo,
    } = this.props;
    return (
      <>
        <Formik
          initialValues={this.initialValues}
          validationSchema={this.validationSchema}
          onSubmit={(values) => {
            updateParticipantPrice({
              small: values.priceS,
              medium: values.priceM,
              large: values.priceL,
            }).then(() => {
              fetchParticipantInfo();
              clearTransientPrices();
              toggleEditModeFunction();
            });
            // savePrices(values);
          }}
          onReset={this.props.cancelPriceChanges}
        >
          {(formikProps) => {
            const {
              handleBlur,
              handleSubmit,
              handleReset,
              values,
              errors,
              touched,
              setFieldValue,
              isSubmitting,
            } = formikProps;
            const changeTransientPriceS = (fieldName: string, value: number) => {
              setFieldValue(fieldName, value || 0); //here important not the value.priceS as not current nr
              this.props.setTransientSmallPrice(value || 0);
            };
            const changeTransientPriceM = (fieldName: string, value: number) => {
              setFieldValue(fieldName, value || 0);
              this.props.setTransientMediumPrice(value || 0);
            };
            const changeTransientPriceL = (fieldName: string, value: number) => {
              setFieldValue(fieldName, value || 0);
              this.props.setTransientLargePrice(value || 0);
            };
            return (
              <form onSubmit={handleSubmit}>
                {/*Price S*/}
                <PriceItem
                  title={`${t(
                    translations.register.participant.opinion.shortQuestionnaires
                  )} (5 ${t(translations.participant.opinionValue.minutesAbbrev)}.)`}
                  errorMessage={touched.priceS && Boolean(errors.priceS) ? errors.priceS : ""}
                  implicationsInfo={t(translations.participant.opinionValue.mediumSelection)}
                >
                  <div className={classes.priceWrapper}>
                    <TextField
                      name={"priceS"}
                      required={true}
                      className={classes.input}
                      placeholder={t(translations.participant.opinionValue.priceS)}
                      value={values.priceS}
                      onChange={(event) =>
                        changeTransientPriceS("priceS", parseInt(event.target.value, 10))
                      }
                      onBlur={handleBlur("priceS")}
                      error={touched.priceS && Boolean(errors.priceS)}
                    />
                    <Typography>{t(translations.register.participant.opinion.cent)}</Typography>
                  </div>
                </PriceItem>
                {/*Price M*/}
                <PriceItem
                  title={`${t(
                    translations.register.participant.opinion.mediumQuestionnaires
                  )} (15 ${t(translations.participant.opinionValue.minutesAbbrev)}.)`}
                  implicationsInfo={t(translations.participant.opinionValue.mediumSelection)}
                  errorMessage={touched.priceM && Boolean(errors.priceM) ? errors.priceM : ""}
                >
                  <div className={classes.priceWrapper}>
                    <TextField
                      name={"priceM"}
                      required={false}
                      className={classes.input}
                      placeholder={t(translations.participant.opinionValue.priceM)}
                      value={values.priceM}
                      onChange={(event) =>
                        changeTransientPriceM("priceM", parseInt(event.target.value, 10))
                      }
                      onBlur={handleBlur("priceM")}
                      error={touched.priceM && Boolean(errors.priceM)}
                    />

                    <Typography>{t(translations.register.participant.opinion.cent)}</Typography>
                  </div>
                </PriceItem>
                <PriceItem
                  title={`${t(
                    translations.register.participant.opinion.longQuestionnaires
                  )} (30 ${t(translations.participant.opinionValue.minutesAbbrev)}.)`}
                  implicationsInfo={t(translations.participant.opinionValue.mediumSelection)}
                  errorMessage={touched.priceL && Boolean(errors.priceL) ? errors.priceL : ""}
                >
                  <div className={classes.priceWrapper}>
                    <TextField
                      name={"priceL"}
                      required={false}
                      className={classes.input}
                      placeholder={t(translations.participant.opinionValue.priceL)}
                      value={values.priceL}
                      onChange={(event) =>
                        changeTransientPriceL("priceL", parseInt(event.target.value, 10))
                      }
                      onBlur={handleBlur("priceL")}
                      error={touched.priceL && Boolean(errors.priceL)}
                    />
                    <Typography>{t(translations.register.participant.opinion.cent)}</Typography>
                  </div>
                </PriceItem>
                <div className={classes.buttonWrapper}>
                  <Button size="small" color="secondary" onClick={handleReset} type="button">
                    {t(translations.action.cancel)}
                  </Button>
                  <Button
                    contained={true}
                    size="small"
                    color="secondary"
                    onClick={handleSubmit}
                    disabled={shallowEqual(this.props.savedData, values) || isSubmitting}
                    type="submit"
                    id={"saveOpinionValueChange"}
                  >
                    {t(translations.action.save)}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = ({ opinionValue, user }: RootState) => ({
  transientPriceS: user.participantInfo.participationPrice.small,
  transientPriceM: user.participantInfo.participationPrice.medium,
  transientPriceL: user.participantInfo.participationPrice.large,
  savedData: user.participantInfo.participationPrice,
});

const mapDispatchToProps = {
  setTransientSmallPrice,
  setTransientMediumPrice,
  setTransientLargePrice,
  clearTransientPrices,
  savePrices,
  updateParticipantPrice,
  fetchParticipantInfo,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(PriceItemForm);
