import React, { PureComponent } from "react";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../../constants/lang/translation";
import { Typography, WithStyles } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/styles";
import { QuopinionTheme } from "../../../../constants/Theme";
import StepIntro from "../components/StepIntro";
import Button from "../../../../common/Layout/components/Button";
import ValueSelector from "./ValueSelector";
import i18next from "i18next";
import * as Yup from "yup";
import { Formik } from "formik";
import { actions as uiStateActions } from "../../../../modules/uiState";
import { fetchPotentialOpinionValues, actions } from "../../../../modules/session";
import { connect } from "react-redux";
import { RootState } from "../../../../modules";
import { withRouter, RouteComponentProps } from "react-router";
import { route as ParticipantRegisterPageRoute } from "../";
import {
  OpinionValueResponse,
  OpinionValueItem,
  OpinionValueKeys,
  OpinionValueRangeKeys,
  TransientOpinionValues,
  ResultOpinionValues,
} from "../../../../services/Session";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import { clearState } from "../../../../util/localStorage";

interface OwnProps {}

interface StateProps {
  potentialOpinionValues?: OpinionValueResponse;
  registrationSteps: string[];
  isLoading: boolean;
}

interface DispatchProps {
  setRegistrationStep: typeof uiStateActions.setRegistrationStep;
  fetchPotentialOpinionValues(): any;
  selectOpinionValues(opinionValues: ResultOpinionValues): void;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    button: {
      borderRadius: 0,
      maxWidth: theme.spacing(73),
      marginBottom: theme.spacing(24),
    },
    hint: {
      margin: `${theme.spacing(8)}px 0`,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps;

export interface OpinionValueGui {
  label: string;
  valueIndicator: OpinionValueRangeKeys;
  value: number;
  height: "big" | "medium" | "small";
}

interface QuestionnaireTypes {
  title: string;
  values: OpinionValueGui[];
  duration: number;
  indicator: OpinionValueKeys;
}

class RegistrationOpinion extends PureComponent<Props> {
  readonly initialValues: TransientOpinionValues = {
    low: "",
    medium: "",
    high: "",
  };

  async componentDidMount() {
    clearState();
    this.props.fetchPotentialOpinionValues();
  }

  generateValues(idx: 0 | 1 | 2): OpinionValueGui[] {
    const keyMapping: OpinionValueKeys[] = ["low", "medium", "high"];
    const { potentialOpinionValues } = this.props;
    const values: OpinionValueItem = potentialOpinionValues
      ? potentialOpinionValues[keyMapping[idx]]
      : { small: 0, medium: 0, large: 0 };
    return [
      {
        label: i18next.t(translations.register.participant.opinion.bigSelection),
        valueIndicator: "small",
        value: values.small,
        height: "big",
      },
      {
        label: i18next.t(translations.register.participant.opinion.mediumSelection),
        valueIndicator: "medium",
        value: values.medium,
        height: "medium",
      },
      {
        label: i18next.t(translations.register.participant.opinion.smallSelection),
        valueIndicator: "large",
        value: values.large,
        height: "small",
      },
    ];
  }
  generateConfig(): QuestionnaireTypes[] {
    return [
      {
        title: i18next.t(translations.register.participant.opinion.shortQuestionnaires),
        values: this.generateValues(0),
        duration: 5,
        indicator: "low",
      },
      {
        title: i18next.t(translations.register.participant.opinion.mediumQuestionnaires),
        values: this.generateValues(1),
        duration: 15,
        indicator: "medium",
      },
      {
        title: i18next.t(translations.register.participant.opinion.longQuestionnaires),
        values: this.generateValues(2),
        duration: 30,
        indicator: "high",
      },
    ];
  }

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      low: Yup.string().required(t(translations.register.participant.opinion.validation)),
      medium: Yup.string().required(t(translations.register.participant.opinion.validation)),
      high: Yup.string().required(t(translations.register.participant.opinion.validation)),
    });
  };

  render() {
    const { t, classes, selectOpinionValues, potentialOpinionValues, isLoading } = this.props;

    if (isLoading) {
      return <LoadingOverlay />;
    }

    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={this.validationSchema}
        onSubmit={(values) => {
          if (potentialOpinionValues && values.low && values.medium && values.high) {
            // this will be made sure by validation
            selectOpinionValues({
              small: potentialOpinionValues.low[values.low],
              medium: potentialOpinionValues.medium[values.medium],
              large: potentialOpinionValues.high[values.high],
            });
            this.props.setRegistrationStep("opinion");
            this.props.history.push(ParticipantRegisterPageRoute);
          }
        }}
      >
        {({ handleSubmit, errors, touched, handleChange, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <StepIntro
                badgeTitle={t(translations.register.participant.step) + " 1"}
                headline={t(translations.register.participant.opinion.headline)}
                description={t(translations.register.participant.opinion.description)}
              />
              {this.generateConfig().map((type: QuestionnaireTypes) => (
                <ValueSelector
                  key={type.title}
                  title={type.title}
                  opinionValues={type.values}
                  duration={type.duration}
                  name={type.indicator}
                  onChange={handleChange(type.indicator)}
                  value={values[type.indicator as keyof TransientOpinionValues] || undefined}
                  errors={touched[type.indicator] && errors[type.indicator]}
                />
              ))}

              <Typography variant="body1" className={classes.hint}>
                {t(translations.register.participant.opinion.hint)}
              </Typography>
              <div className={classes.buttonWrapper}>
                <Button
                  contained={true}
                  color="secondary"
                  fullWidth={true}
                  size="big"
                  className={classes.button}
                  type="submit"
                >
                  {t(translations.action.save)}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = ({ uiState, session }: RootState) => {
  return {
    registrationSteps: uiState.registrationSteps,
    potentialOpinionValues: session.potentialOpinionValues,
    isLoading: session.isLoading,
  };
};

const mapDispatchToProps = {
  fetchPotentialOpinionValues,
  setRegistrationStep: uiStateActions.setRegistrationStep,
  selectOpinionValues: actions.selectOpinionValues,
};
export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(RegistrationOpinion);
