import React from "react";
import Question from "../../../../entities/Question";
import MediaDisplay from "../../../../common/Preview/components/MediaDisplay";
import { withTranslation, WithTranslation } from "react-i18next";
import Checkbox from "../../../../common/Preview/components/Checkbox";
import { TextField, Typography } from "@material-ui/core";
import { translations } from "../../../../constants/lang/translation";
import { Answer, ChoiceAnswer } from "../../../../entities/SurveyAnswers";
import { isChecked } from "../isChecked";
import ID from "../../../../entities/ID";
import { AnsweredAttribute } from "../../../../services/Accounts";
import AttributeQuestion from "../../../../entities/AttributeQuestion";
import AnswerOption, { FieldTypes } from "../../../../entities/AnswerOption";

interface OwnProps {
  question: Question | AttributeQuestion;
  currentAnswer?: ChoiceAnswer | AnsweredAttribute;
  handleUserAnswer: (questionID: ID, answer?: Answer) => void;
  isScreener?: boolean;
}

type Props = OwnProps & WithTranslation;

const MultipleChoice: React.FC<Props> = ({ currentAnswer, handleUserAnswer, question, t }) => {
  const handleInputChange = (
    answer: AnswerOption,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    if (answer.additionalFieldType === FieldTypes.TEXT) {
      if (
        (currentAnswer as ChoiceAnswer) &&
        (currentAnswer as ChoiceAnswer).selectedOptions &&
        (currentAnswer as ChoiceAnswer).selectedOptions.length > 1 &&
        (currentAnswer as ChoiceAnswer).selectedOptionsFreeText !== undefined
      ) {
        //more than one answer option is selected in general
        if ((currentAnswer as ChoiceAnswer).selectedOptionsFreeText !== undefined) {
          // @ts-ignore
          const remainingFreeTextItems = (currentAnswer as ChoiceAnswer).selectedOptionsFreeText.filter(
            (freeTextItem) => freeTextItem.answerOptionID !== answer.id
          );
          handleUserAnswer(question.id, {
            ...currentAnswer,
            questionID: question.id,
            type: "choice",
            selectedOptions: [...(currentAnswer as ChoiceAnswer).selectedOptions],
            selectedOptionsFreeText: [
              //@ts-ignore
              ...remainingFreeTextItems,
              {
                answerOptionID: answer.id,
                freeText: event.target.value.length ? event.target.value : "",
              },
            ],
          });
        }
      }
      //so far already freeText answer set, but change input, also for first char in freetext
      if (
        (currentAnswer as ChoiceAnswer) &&
        (currentAnswer as ChoiceAnswer).selectedOptionsFreeText &&
        (currentAnswer as ChoiceAnswer).selectedOptions.length === 1
      ) {
        handleUserAnswer(question.id, {
          questionID: question.id,
          type: "choice",
          selectedOptions: [...(currentAnswer as ChoiceAnswer).selectedOptions],
          selectedOptionsFreeText: [
            {
              answerOptionID: answer.id,
              freeText: event.target.value.length ? event.target.value : "",
            },
          ],
        });
      }
    }
    if (answer.additionalFieldType === FieldTypes.NUMBER) {
      if (
        (currentAnswer as ChoiceAnswer) &&
        (currentAnswer as ChoiceAnswer).selectedOptions &&
        (currentAnswer as ChoiceAnswer).selectedOptions.length > 1 &&
        (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput !== undefined
      ) {
        //more than one answer option is selected in general
        if ((currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput !== undefined) {
          // @ts-ignore
          const remainingNumericalItems = (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput.filter(
            (numberItem) => numberItem.answerOptionID !== answer.id
          );
          handleUserAnswer(question.id, {
            ...currentAnswer,
            questionID: question.id,
            type: "choice",
            selectedOptions: [...(currentAnswer as ChoiceAnswer).selectedOptions],
            selectedOptionsNumericalInput: [
              //@ts-ignore
              ...remainingNumericalItems,
              {
                answerOptionID: answer.id,
                numberEntry:
                  event.target.value.length && typeof Number(event.target.value) === "number"
                    ? Number(event.target.value)
                    : null,
              },
            ],
          });
        }
      }
      //so far already freeText answer set, but change input, also for first char in freetext
      if (
        (currentAnswer as ChoiceAnswer) &&
        (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput &&
        (currentAnswer as ChoiceAnswer).selectedOptions.length === 1
      ) {
        handleUserAnswer(question.id, {
          questionID: question.id,
          type: "choice",
          selectedOptions: [...(currentAnswer as ChoiceAnswer).selectedOptions],
          selectedOptionsNumericalInput: [
            {
              answerOptionID: answer.id,
              numberEntry:
                event.target.value.length && typeof Number(event.target.value) === "number"
                  ? Number(event.target.value)
                  : null,
            },
          ],
        });
      }
    }
  };
  return (
    <>
      {question.body.answerOptions.map((answer) => (
        <Checkbox
          key={answer.id}
          answerValue={answer.id}
          label={answer.label}
          checked={isChecked(answer.id, currentAnswer)}
          onChange={(_, checked) => {
            if (currentAnswer) {
              if (checked) {
                // check if it is a screener question, this part only for attribute questions
                if (currentAnswer instanceof AnsweredAttribute) {
                  if (
                    currentAnswer.answer.type !== "rating" &&
                    currentAnswer.answer.type !== "text" &&
                    currentAnswer.answer.type !== "matrix" &&
                    currentAnswer.answer.type !== "ranking"
                  ) {
                    // add new attribute answer option
                    handleUserAnswer(question.id, {
                      ...currentAnswer,
                      selectedOptions: [...currentAnswer.answer.selectedOptions, answer.id],
                      questionID: currentAnswer.answer.questionID,
                      type: currentAnswer.answer.type,
                    });
                  } else {
                    return;
                  }
                } else {
                  //in case that survey answer:
                  // already other answer option given, we need to add this new answer option
                  if (
                    answer.additionalFieldType === FieldTypes.NUMBER ||
                    answer.additionalFieldType === FieldTypes.TEXT
                  ) {
                    //in case that new answer has freeText
                    if (answer.additionalFieldType === FieldTypes.TEXT) {
                      //so far already freeText answer selected
                      if (currentAnswer.selectedOptionsFreeText) {
                        handleUserAnswer(question.id, {
                          ...currentAnswer,
                          selectedOptions: [...currentAnswer.selectedOptions, answer.id],
                          selectedOptionsFreeText: [
                            ...currentAnswer.selectedOptionsFreeText,
                            {
                              answerOptionID: answer.id,
                              freeText: "",
                            },
                          ],
                        });
                      }
                      //so far no freeText answer selected
                      else {
                        handleUserAnswer(question.id, {
                          ...currentAnswer,
                          selectedOptions: [...currentAnswer.selectedOptions, answer.id],
                          selectedOptionsFreeText: [
                            {
                              answerOptionID: answer.id,
                              freeText: "",
                            },
                          ],
                        });
                      }
                    }
                    //in case that new answer has additional Numberfield
                    if (answer.additionalFieldType === FieldTypes.NUMBER) {
                      //so far already freeText answer selected
                      if (currentAnswer.selectedOptionsNumericalInput) {
                        handleUserAnswer(question.id, {
                          ...currentAnswer,
                          selectedOptions: [...currentAnswer.selectedOptions, answer.id],
                          selectedOptionsNumericalInput: [
                            ...currentAnswer.selectedOptionsNumericalInput,
                            {
                              answerOptionID: answer.id,
                              numberEntry: null,
                            },
                          ],
                        });
                      }
                      //so far no numberfield answer selected
                      else {
                        handleUserAnswer(question.id, {
                          ...currentAnswer,
                          selectedOptions: [...currentAnswer.selectedOptions, answer.id],
                          selectedOptionsNumericalInput: [
                            {
                              answerOptionID: answer.id,
                              numberEntry: null,
                            },
                          ],
                        });
                      }
                    }
                  } else {
                    //in case that new answer hasn't freeText nor numberEntry
                    handleUserAnswer(question.id, {
                      ...currentAnswer,
                      selectedOptions: [...currentAnswer.selectedOptions, answer.id],
                    });
                  }
                }
              } else if (!checked) {
                if (!(currentAnswer instanceof AnsweredAttribute)) {
                  // we need to remove this answer option
                  const newSelectedOptions = currentAnswer.selectedOptions.filter(
                    (selectedOption) => selectedOption !== answer.id
                  );
                  if (newSelectedOptions.length) {
                    // there is at least one other answer options, we want to remove only one answer let's send it up
                    if (
                      currentAnswer.selectedOptionsNumericalInput ||
                      currentAnswer.selectedOptionsFreeText
                    ) {
                      if (currentAnswer.selectedOptionsFreeText) {
                        const newSelectedOptionsFreeText = currentAnswer.selectedOptionsFreeText.filter(
                          (selectedOptionsFreeTextItem) =>
                            selectedOptionsFreeTextItem.answerOptionID !== answer.id
                        );
                        if (newSelectedOptionsFreeText.length) {
                          // there is still a selectedAnswer with freetext selected
                          handleUserAnswer(question.id, {
                            ...currentAnswer,
                            selectedOptions: newSelectedOptions,
                            selectedOptionsFreeText: newSelectedOptionsFreeText,
                          });
                        } else {
                          // there is still a selectedAnswer, but without freetext
                          // for the case of: 2 answers left, one with the other without freetext and then only
                          // the one without freetext remains
                          delete currentAnswer.selectedOptionsFreeText;
                          handleUserAnswer(question.id, {
                            ...currentAnswer,
                            selectedOptions: newSelectedOptions,
                          });
                        }
                      }
                      // there is at least one other answer options, we want to remove only one answer let's send it up
                      if (currentAnswer.selectedOptionsNumericalInput) {
                        const newSelectedOptionsNumericalField = currentAnswer.selectedOptionsNumericalInput.filter(
                          (selectedOptionsFreeTextItem) =>
                            selectedOptionsFreeTextItem.answerOptionID !== answer.id
                        );
                        if (newSelectedOptionsNumericalField.length) {
                          // there is still a selectedAnswer with freetext selected
                          handleUserAnswer(question.id, {
                            ...currentAnswer,
                            selectedOptions: newSelectedOptions,
                            selectedOptionsNumericalInput: newSelectedOptionsNumericalField,
                          });
                        } else {
                          // there is still a selectedAnswer, but without numberfield
                          // for the case of: 2 answers left, one with the other without freetext and then only
                          // the one without numberfield remains
                          delete currentAnswer.selectedOptionsNumericalInput;
                          handleUserAnswer(question.id, {
                            ...currentAnswer,
                            selectedOptions: newSelectedOptions,
                          });
                        }
                      }
                    } else {
                      // there is at least another answer option, but without freetext
                      delete currentAnswer.selectedOptionsFreeText;
                      handleUserAnswer(question.id, {
                        ...currentAnswer,
                        selectedOptions: newSelectedOptions,
                      });
                    }
                  } else {
                    // no other answer options - let's remove the whole answer
                    handleUserAnswer(question.id);
                  }
                } else if (currentAnswer instanceof AnsweredAttribute) {
                  if (
                    currentAnswer.answer.type !== "rating" &&
                    currentAnswer.answer.type !== "text" &&
                    currentAnswer.answer.type !== "matrix" &&
                    currentAnswer.answer.type !== "ranking"
                  ) {
                    const newSelectedOptions = currentAnswer.answer.selectedOptions.filter(
                      (selectedOption) => selectedOption !== answer.id
                    );
                    if (newSelectedOptions.length) {
                      // there is another answer options, let's send it up
                      handleUserAnswer(question.id, {
                        ...currentAnswer,
                        selectedOptions: newSelectedOptions,
                        questionID: currentAnswer.answer.questionID,
                        type: currentAnswer.answer.type,
                      });
                    } else {
                      // remove attribute for attribute answer
                      if (question instanceof AttributeQuestion) {
                        handleUserAnswer(question.attributeID);
                      }
                    }
                  }
                }
              }
            } else if (checked) {
              // question wasn't answered before, first mc answer
              if (
                answer.additionalFieldType === FieldTypes.TEXT ||
                answer.additionalFieldType === FieldTypes.NUMBER
              ) {
                if (answer.additionalFieldType === FieldTypes.TEXT) {
                  handleUserAnswer(question.id, {
                    questionID: question.id,
                    type: "choice",
                    selectedOptions: [answer.id],
                    selectedOptionsFreeText: [
                      {
                        answerOptionID: answer.id,
                        freeText: "",
                      },
                    ],
                  });
                }
                if (answer.additionalFieldType === FieldTypes.NUMBER) {
                  handleUserAnswer(question.id, {
                    questionID: question.id,
                    type: "choice",
                    selectedOptions: [answer.id],
                    selectedOptionsNumericalInput: [
                      {
                        answerOptionID: answer.id,
                        numberEntry: null,
                      },
                    ],
                  });
                }
              } else {
                handleUserAnswer(question.id, {
                  questionID: question.id,
                  type: "choice",
                  selectedOptions: [answer.id],
                });
              }
            }
          }}
          children={
            <>
              {answer.media.length > 0 && <MediaDisplay media={answer.media} answerOption={true} />}
              {answer.additionalFieldType === FieldTypes.TEXT && (
                <TextField
                  placeholder={t(translations.questionnaire.placeholder.pleaseEnterText)}
                  fullWidth={true}
                  multiline={true}
                  rows={5}
                  style={{ marginTop: 2, marginBottom: 2 }}
                  autoFocus={true}
                  disabled={!isChecked(answer.id, currentAnswer)}
                  value={
                    (currentAnswer as ChoiceAnswer) &&
                    (currentAnswer as ChoiceAnswer).selectedOptionsFreeText !== undefined &&
                    //@ts-ignore
                    (currentAnswer as ChoiceAnswer).selectedOptionsFreeText.length &&
                    //@ts-ignore
                    (currentAnswer as ChoiceAnswer).selectedOptionsFreeText.find(
                      //@ts-ignore
                      (freetextItem) => freetextItem.answerOptionID === answer.id
                    )
                      ? (currentAnswer as ChoiceAnswer) &&
                        (currentAnswer as ChoiceAnswer).selectedOptionsFreeText !== undefined &&
                        //@ts-ignore
                        (currentAnswer as ChoiceAnswer).selectedOptionsFreeText.length &&
                        //@ts-ignore
                        (currentAnswer as ChoiceAnswer).selectedOptionsFreeText.find(
                          //@ts-ignore
                          (freetextItem) => freetextItem.answerOptionID === answer.id
                        ).freeText
                      : ""
                  }
                  onChange={(event) => {
                    handleInputChange(answer, event);
                  }}
                />
              )}
              {answer.additionalFieldType === FieldTypes.NUMBER && (
                <TextField
                  type={"number"}
                  placeholder={t(translations.questionnaire.placeholder.pleaseEnterNumber)}
                  fullWidth={true}
                  style={{ marginTop: 2, marginBottom: 2 }}
                  autoFocus={true}
                  disabled={!isChecked(answer.id, currentAnswer)}
                  value={
                    (currentAnswer as ChoiceAnswer) &&
                    (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput !== undefined &&
                    //@ts-ignore
                    (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput.length &&
                    //@ts-ignore
                    (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput.find(
                      //@ts-ignore
                      (freetextItem) => freetextItem.answerOptionID === answer.id
                    )
                      ? (currentAnswer as ChoiceAnswer) &&
                        (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput !==
                          undefined &&
                        //@ts-ignore
                        (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput.length &&
                        //@ts-ignore
                        (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput.find(
                          //@ts-ignore
                          (numberItem) => numberItem.answerOptionID === answer.id
                        ).numberEntry
                      : ""
                  }
                  onChange={(event) => {
                    handleInputChange(answer, event);
                  }}
                />
              )}
              {/*   information to insert number*/}
              {(currentAnswer as ChoiceAnswer) &&
                (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput &&
                //@ts-ignore
                (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput.find(
                  //@ts-ignore
                  (numberItem) =>
                    numberItem.answerOptionID === answer.id && numberItem.numberEntry === null
                ) && (
                  <Typography style={{ marginLeft: 16 }}>
                    {t(translations.questionnaire.validation.additionalNumberField)}
                  </Typography>
                )}
            </>
          }
          headerAlignItems={"center"}
        />
      ))}
      {question.body.additionalAnswerEnabled && (
        <Checkbox
          key={"answer.id"}
          answerValue={"other"}
          label={"Sonstiges"}
          checked={false}
          onChange={() => {
            return;
          }}
          children={
            <TextField
              placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
              fullWidth={true}
              multiline={true}
              rows={5}
              style={{ marginTop: 16 }}
            />
          }
          headerAlignItems={"center"}
        />
      )}
    </>
  );
};

export default withTranslation()(MultipleChoice);
