import React, { ChangeEvent, ComponentType, Component } from "react";
import {
  Typography,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Tooltip,
  Menu,
  List,
  ListItemText,
} from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import {
  QuopinionTheme,
  blueGradient,
  secondary,
  backgroundWhite,
} from "../../../../../constants/Theme";
import { compose } from "recompose";
import * as Yup from "yup";
import Button from "../../../../../common/Layout/components/Button";

import {
  QuestionFormSingleChoice,
  QuestionPreviewSingleChoice,
  QuestionValidationSingleChoice,
} from "./QuestionTypeSingleChoice";
import {
  QuestionFormMultipleChoice,
  QuestionPreviewMultipleChoice,
  QuestionValidationMultipleChoice,
} from "./QuestionTypeMultipleChoice";
import {
  QuestionFormStarRating,
  QuestionPreviewStarRating,
  QuestionValidationStarRating,
} from "./QuestionTypeStarRating";
import { translations } from "../../../../../constants/lang/translation";

import {
  QuestionFormFreeText,
  QuestionPreviewFreeText,
  QuestionValidationFreeText,
} from "./QuestionTypeFreeText";
import {
  QuestionFormRating,
  QuestionPreviewRating,
  QuestionValidationRating,
} from "./QuestionTypeRating";
import {
  QuestionPreviewInstruction,
  QuestionTypeInstruction,
  QuestionValidationInstruction,
} from "./QuestionTypeInstruction";
import {
  QuestionFormMatrix,
  QuestionPreviewMatrix,
  QuestionValidationMatrix,
} from "./QuestionTypeMatrix";
import {
  QuestionFormPassword,
  QuestionPreviewPassword,
  QuestionValidationPassword,
} from "./QuestionTypePassword";
import {
  QuestionFormRanking,
  QuestionPreviewRanking,
  QuestionValidationRanking,
} from "./QuestionTypeRanking";
import { withTranslation, WithTranslation } from "react-i18next";

import Badge from "../../../../../common/Layout/components/Badge";
import Question, {
  QuestionBody,
  QuestionCondition,
  QuestionType,
} from "../../../../../entities/Question";

import classNames from "classnames";
import * as Icons from "../../../../../assets/icon.js";
import ModalComponent from "../../../../../common/Layout/components/Modal";
import UploadedMediaItem from "../../../../../common/Layout/components/UploadedMediaItem";
import TextFieldWithMediaUpload from "../../../../../common/Layout/components/TextFieldWithMediaUpload";
import Media from "../../../../../entities/Media";
import DialogComponent from "../../../../../common/Layout/components/Dialog";
import Card from "../../../../../common/Layout/components/Card";
import i18next from "i18next";
import { SkipLogic } from "../../../../../assets/icon";
import ID from "../../../../../entities/ID";
import AnswerOption from "../../../../../entities/AnswerOption";
import { SkipToInfo } from "../index";
import { ErrorMessage } from "../../../../../modules/surveyErrors";
import { NotInterestedOutlined } from "@material-ui/icons";
import isEqual from "lodash/isEqual";

export type QuestionTypeProps = {
  type: QuestionType;
  body: QuestionBody;
  conditions: QuestionCondition[];
  deprivedFromComplexity: boolean;
  otherQuestions: Question[];
  questionIndex: number;
  id: string;
  preventMediaUpload?: boolean;
  preventAlterationOfAnswerCount?: boolean;
  preventQuestionSkip?: boolean;
  preventAdditionalFreeText?: boolean;
} & {
  handleChange: (newBody: Omit<QuestionBody, "toDataJson">) => void;
};

interface State {
  previewOpen: boolean;
  deleteDialogOpen: boolean;
  valid: boolean;
  errors: string[];
  questionBodyOpen: boolean;
  tooltipOpen: boolean;
  mediaTooltipOpen: boolean;
  uploadError: string | null;
  conditionMenuOpen: boolean;
  questionsWithOpenAnswers: ID[];
}

export interface OwnProps {
  question: Question;
  questionIndex: number;
  otherQuestions: Question[];
  onChangeValidity: (formValid: boolean, question: Omit<Question, "toDataJson">) => void;
  onQuestionChange: (question: Omit<Question, "toDataJson">) => void;
  onQuestionDelete?: (question: Omit<Question, "toDataJson">) => void;
  onQuestionCopy?: (question: Omit<Question, "toDataJson">) => void;
  questionBadge: string;
  onQuestionMoveUp?: (question: Omit<Question, "toDataJson">) => void;
  onQuestionMoveDown?: (question: Omit<Question, "toDataJson">) => void;
  firstQuestion: boolean;
  lastQuestion: boolean;
  removeQuestionFromValidation: (id: string) => void;
  preventRandomizeAnswers?: boolean;
  preventMediaUpload?: boolean;
  preventAlterationOfAnswerCount?: boolean;
  preventQuestionSkip?: boolean;
  questionTypeWhitelist?: QuestionType[];
  preventAdditionalFreeText?: boolean;
  questionnaireSkipToEndInfo: SkipToInfo[];
  questionnaireSkpiToIdInfo: SkipToInfo[];
  questionQuestionnaireErrors: ErrorMessage[];
  user?: any;
}

interface QuestionTypeConfig {
  Body: ComponentType<QuestionTypeProps>;
  Preview: ComponentType<Omit<Question, "toDataJson">>;
  validation: (props: QuestionProps) => Yup.ObjectSchema<any>;
  titlePlaceholderText: string;
  // are answerOptions randomizable?
  randomizable: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    questionComponent: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(7.5),
      marginBottom: theme.spacing(7.5),
    },
    questionHeader: {
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.grey[200],
      color: "#fff",
      position: "relative",
      paddingTop: theme.spacing(7.75),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginLeft: theme.spacing(8),
      "@media(max-width: 414px)": {
        width: "92%",
      },
      "@media(min-width:415px)": {
        width: "94%",
      },
      "@media(min-width:769px)": {
        width: "100%",
      },
      flexDirection: "column",
    },
    headerInputs: {
      display: "flex",
      width: "100%",
      alignItems: "center",
    },
    questionNumber: {
      position: "absolute",
      top: "-20px",
      left: "12px",
    },
    questionBody: {
      padding: theme.spacing(5),
      position: "relative",
      marginLeft: theme.spacing(8),
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      width: "100%",
      "@media(max-width:768px)": {
        width: "unset",
      },
    },
    questionActionBar: {
      display: "flex",
      position: "absolute",
      right: "25px",
      borderRadius: 6,
      height: 35,
    },
    questionActionBarItem: {
      borderLeft: "1px solid",
      borderColor: theme.palette.grey[100],
      "&:first-of-type": {
        borderLeft: "none",
      },
    },
    modalStyles: {
      top: 100,
      left: "calc(50% - 200px)",
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      padding: theme.spacing(1.75),
    },
    questionNavigation: {
      backgroundColor: theme.palette.primary.main,
      width: 32,
      height: "100%",
      position: "absolute",
      left: "-32px",
      display: "flex",
      flexDirection: "column",
      justifyContent: (props: OwnProps) => (props.firstQuestion ? "flex-end" : "space-between"),
      top: 0,
    },
    questionNavigationArrow: {
      color: "#fff",
      height: 32,
      padding: theme.spacing(1),
    },
    questionBodyOpen: {
      transform: "rotate(180deg)",
    },
    questionBodyToggle: {
      color: "#fff",
      height: 32,
      width: 32,
      padding: theme.spacing(1),
      transition: "transform 0.3s ease-in-out",
      marginLeft: theme.spacing(4),
      "@media(max-width:414px)": {
        marginLeft: theme.spacing(2.5),
      },
    },
    toggleIcon: {
      width: 16,
    },
    validationBadge: {
      position: "absolute",
      right: 12,
      top: -15,
    },
    questionSelect: {
      paddingTop: theme.spacing(5.5),
      paddingBottom: theme.spacing(5.75),
      paddingRight: theme.spacing(12),
      paddingLeft: theme.spacing(4),
      fontSize: "14px",
      border: "none",
      color: "#fff",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      background: blueGradient,
      "@media(max-width: 414px)": {
        width: theme.spacing(7.5),
      },
      "@media(min-width: 415px)": {
        width: theme.spacing(17),
      },
      "@media(min-width: 1025px)": {
        width: 110,
      },
      "&:hover": {
        border: "none",
      },
      "&:active": {
        borderRadius: "3px",
        border: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
      "&:focus": {
        borderRadius: "3px",
        border: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    selectIcon: {
      color: "#fff",
      marginRight: theme.spacing(3),
      top: "calc(50% - 9px)",
      position: "relative",
      "& svg": {
        position: "absolute",
        top: theme.spacing(6),
        right: theme.spacing(3),
        pointerEvents: "none",
      },
    },
    icon: {
      position: "absolute",
      top: theme.spacing(6),
      right: theme.spacing(8),
      pointerEvents: "none",
    },
    questionInput: {
      height: "100%",
      width: "100%",
      fontSize: "14px",
      "& div": {
        borderColor: "#fff",
        padding: theme.spacing(3.75),
      },
    },
    itemFooter: {
      marginRight: theme.spacing(20),
      "@media (max-width: 1024px)": {
        marginRight: theme.spacing(18),
      },
      "@media (max-width: 870px)": {
        marginRight: theme.spacing(10.75),
      },
    },
    questionFeatures: {
      padding: theme.spacing(1.5),
      justifyContent: "space-between",
    },
    conditionTitle: {
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginBottom: theme.spacing(1.25),
      fontWeight: 600,
      position: "fixed",
      backgroundColor: backgroundWhite,
      zIndex: 1,
      width: 235,
      paddingTop: theme.spacing(2.5),
      height: 65,
    },
    answerItemList: {
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    conditionMenuItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "baseline",
      justifyContent: "center",
      border: "none",
    },
    conditionMenu: {
      height: "250px",
      overflowY: "scroll",
      width: "250px",
      border: "none",
    },
    conditionList: {
      paddingTop: "unset",
    },
    menuItemsWrapper: {
      paddingTop: theme.spacing(17.5),
    },
    deprivedComplexityIcon: {
      color: (props: OwnProps) => (props.question.deprivedFromComplexity ? "#fff" : "#6d7278"),
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    questionIconWrapper: {
      display: "flex",
      minWidth: 100,
      justifyContent: "flex-end",
    },
    complexityButton: {
      marginRight: theme.spacing(1),
    },
  });

export type QuestionProps = OwnProps & WithStyles<typeof styles> & WithTranslation;

class QuestionForm extends Component<QuestionProps, State> {
  static questionTypes: { [type in QuestionType]: QuestionTypeConfig } = {
    instruction: {
      Body: QuestionTypeInstruction,
      Preview: QuestionPreviewInstruction,
      validation: QuestionValidationInstruction,
      titlePlaceholderText: i18next.t(translations.questionnaire.formPlaceholderInstruction),
      randomizable: false,
    },
    freeText: {
      Body: QuestionFormFreeText,
      Preview: QuestionPreviewFreeText,
      validation: QuestionValidationFreeText,
      titlePlaceholderText: i18next.t(translations.questionnaire.formPlaceholder),
      randomizable: false,
    },
    starRating: {
      Body: QuestionFormStarRating,
      Preview: QuestionPreviewStarRating,
      validation: QuestionValidationStarRating,
      titlePlaceholderText: i18next.t(translations.questionnaire.formPlaceholder),
      randomizable: false,
    },
    stepRating: {
      Body: QuestionFormRating,
      Preview: QuestionPreviewRating,
      validation: QuestionValidationRating,
      titlePlaceholderText: i18next.t(translations.questionnaire.formPlaceholder),
      randomizable: false,
    },
    singleChoice: {
      Body: QuestionFormSingleChoice,
      Preview: QuestionPreviewSingleChoice,
      validation: QuestionValidationSingleChoice,
      titlePlaceholderText: i18next.t(translations.questionnaire.formPlaceholder),
      randomizable: true,
    },
    multipleChoice: {
      Body: QuestionFormMultipleChoice,
      Preview: QuestionPreviewMultipleChoice,
      validation: QuestionValidationMultipleChoice,
      titlePlaceholderText: i18next.t(translations.questionnaire.formPlaceholder),
      randomizable: true,
    },
    matrix: {
      Body: QuestionFormMatrix,
      Preview: QuestionPreviewMatrix,
      validation: QuestionValidationMatrix,
      titlePlaceholderText: i18next.t(translations.questionnaire.formPlaceholder),
      randomizable: false,
    },
    password: {
      Body: QuestionFormPassword,
      Preview: QuestionPreviewPassword,
      validation: QuestionValidationPassword,
      titlePlaceholderText: i18next.t(translations.questionnaire.formPlaceholder),
      randomizable: false,
    },
    ranking: {
      Body: QuestionFormRanking,
      Preview: QuestionPreviewRanking,
      validation: QuestionValidationRanking,
      titlePlaceholderText: i18next.t(translations.questionnaire.formPlaceholder),
      randomizable: true,
    },
  };

  anchorEl?: HTMLDivElement;

  state: State = {
    previewOpen: false,
    deleteDialogOpen: false,
    valid: true,
    errors: [],
    questionBodyOpen: true,
    tooltipOpen: false,
    mediaTooltipOpen: false,
    uploadError: null,
    conditionMenuOpen: false,
    questionsWithOpenAnswers: [],
  };

  componentDidMount() {
    this.validate(this.props.question);
  }

  shouldComponentUpdate(nextProps: Readonly<QuestionProps>, nextState: Readonly<State>): boolean {
    if (
      !isEqual(nextProps.question, this.props.question) ||
      !isEqual(nextProps.questionBadge, this.props.questionBadge) ||
      !isEqual(nextProps.otherQuestions, this.props.otherQuestions) ||
      !isEqual(nextProps.questionQuestionnaireErrors, this.props.questionQuestionnaireErrors) ||
      !isEqual(nextProps.questionnaireSkipToEndInfo, this.props.questionnaireSkipToEndInfo) ||
      !isEqual(nextProps.questionnaireSkpiToIdInfo, this.props.questionnaireSkpiToIdInfo) ||
      !isEqual(nextProps.firstQuestion, this.props.firstQuestion) ||
      !isEqual(nextProps.lastQuestion, this.props.lastQuestion) ||
      !isEqual(nextState, this.state)
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps: QuestionProps) {
    if (prevProps.questionIndex !== this.props.questionIndex) {
      this.validate(this.props.question);
    }
  }

  handleQuestionTypeChange = (
    event:
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { question } = this.props;
    const newQuestion: Omit<Question, "toDataJson"> = {
      ...question,
      type: event.target.value as QuestionType,
    };

    this.validate(newQuestion);

    this.props.onQuestionChange(newQuestion);
  };

  toggleQuestionPreview = () => {
    this.setState({
      previewOpen: !this.state.previewOpen,
    });
  };

  toggleDeleteDialog = () => {
    this.setState({
      deleteDialogOpen: !this.state.deleteDialogOpen,
    });
  };

  toggleQuestionBody = () => {
    this.setState({
      questionBodyOpen: !this.state.questionBodyOpen,
    });
  };

  async validate(question: Omit<Question, "toDataJson">) {
    const questionConfig = QuestionForm.questionTypes[question.type];
    if (!questionConfig) {
      this.setState({ valid: false, errors: [] });
      this.props.onChangeValidity(false, question);
    } else {
      const validation = questionConfig.validation;
      try {
        await validation(this.props).validate(question, {
          abortEarly: false,
        });
        this.setState({ valid: true, errors: [] });
        this.props.onChangeValidity(true, question);
      } catch (e) {
        this.setState({ valid: false, errors: e.errors });
        this.props.onChangeValidity(false, question);
      }
    }
  }

  handleChangeOfQuestionInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { question } = this.props;
    const newQuestion: Omit<Question, "toDataJson"> = {
      ...question,
      text: event.target.value,
    };
    this.validate(newQuestion);
    const comparingQuestion: Omit<Question, "toDataJson"> = {
      ...question,
    };

    if (!isEqual({ ...comparingQuestion }, { ...newQuestion })) {
      this.props.onQuestionChange(newQuestion);
    }
  };

  handleNewBody = (body: Omit<QuestionBody, "toDataJson">) => {
    const { question } = this.props;
    const newQuestion: Omit<Question, "toDataJson"> = {
      ...question,
      // @ts-ignore
      body,
    };

    this.validate(newQuestion);
    //create questionBody object to ensure focus on only content differences ignoring specific type differences
    const newQuestionBody = new QuestionBody(body);

    if (!isEqual(question.body, newQuestionBody)) {
      this.props.onQuestionChange(newQuestion);
    }
  };

  handleMediaUploaded = (media: Media) => {
    this.props.onQuestionChange({
      ...this.props.question,
      media: [media],
    });
  };

  handleMediaUploadFail = (reason: string) => {
    this.setState({
      uploadError: reason,
    });
  };

  handleMediaRemove = () => {
    this.props.onQuestionChange({
      ...this.props.question,
      media: [],
    });
  };

  handleConditionSelection = (conditions: QuestionCondition[]) => {
    this.props.onQuestionChange({
      ...this.props.question,
      conditions: conditions,
    });
  };

  handleConditionCompleteDelete = () => {
    this.props.onQuestionChange({
      ...this.props.question,
      conditions: [],
    });
  };

  toggleDeprivedFromComplexity = () => {
    this.props.onQuestionChange({
      ...this.props.question,
      deprivedFromComplexity: !this.props.question.deprivedFromComplexity,
    });
  };

  handleDeleteClick = () => {
    const { question } = this.props;
    this.props.onQuestionDelete!(question);
    this.toggleDeleteDialog();
    this.props.removeQuestionFromValidation(question.id);
  };

  handleCopyClick = () => {
    const { question } = this.props;
    this.props.onQuestionCopy!(question);
  };

  handleMoveUpClick = () => {
    const { question } = this.props;
    this.props.onQuestionMoveUp!(question);
  };

  handleMoveDownClick = () => {
    const { question } = this.props;
    this.props.onQuestionMoveDown!(question);
  };

  toggleRandomizeAnswerOptions = () => {
    const { question } = this.props;
    this.handleNewBody({
      ...question.body,
      randomizeAnswerOptions: !question.body.randomizeAnswerOptions,
    });
  };

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  hideTooltip = () => {
    this.setState({
      tooltipOpen: false,
    });
  };

  showTooltip = () => {
    this.setState({
      tooltipOpen: true,
    });
  };

  renderQuestionBody() {
    const { question, otherQuestions, questionIndex } = this.props;
    const QuestionTypeBody = QuestionForm.questionTypes[question.type].Body;
    return (
      <QuestionTypeBody
        body={question.body}
        type={question.type}
        handleChange={this.handleNewBody}
        otherQuestions={otherQuestions}
        questionIndex={questionIndex}
        id={question.id}
        preventMediaUpload={this.props.preventMediaUpload}
        preventAlterationOfAnswerCount={this.props.preventAlterationOfAnswerCount}
        preventQuestionSkip={this.props.preventQuestionSkip}
        preventAdditionalFreeText={this.props.preventAdditionalFreeText}
        conditions={question.conditions}
        deprivedFromComplexity={question.deprivedFromComplexity}
      />
    );
  }

  renderQuestionPreview() {
    const { question } = this.props;
    const QuestionTypePreview = QuestionForm.questionTypes[question.type].Preview;
    return <QuestionTypePreview {...question} />;
  }

  renderQuestionTypeSelect() {
    const { classes, question, t, questionTypeWhitelist } = this.props;

    const questionTypes = Object.keys(QuestionForm.questionTypes) as QuestionType[];
    const filteredQuestionTypes = questionTypeWhitelist
      ? questionTypes.filter((questionType) => questionTypeWhitelist.includes(questionType))
      : questionTypes;

    return (
      <Select
        value={question.type}
        onChange={this.handleQuestionTypeChange}
        classes={{
          select: classes.questionSelect,
          root: classes.selectIcon,
          icon: classes.icon,
        }}
        IconComponent={Icons.DropdownArrowSmall}
      >
        {filteredQuestionTypes.map((questionType) => {
          const translationKey = translations.questionnaire.questionTypes[questionType];
          return (
            <MenuItem key={questionType} value={questionType}>
              {t(translationKey)}
            </MenuItem>
          );
        })}
      </Select>
    );
  }

  toggleConditionMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
  ) => {
    this.setState({
      conditionMenuOpen: !this.state.conditionMenuOpen,
    });
  };

  toggleAnswers = (questionId: ID) => {
    const alreadyIncluded =
      this.state.questionsWithOpenAnswers.filter((questionIdItem) => questionIdItem === questionId)
        .length > 0;

    const previousSelection = [...this.state.questionsWithOpenAnswers];

    if (alreadyIncluded) {
      this.setState({
        questionsWithOpenAnswers: previousSelection.filter((qIdItem) => qIdItem !== questionId),
      });
    } else {
      const enlargedSelection = [...previousSelection, questionId];
      this.setState({
        questionsWithOpenAnswers: [...enlargedSelection],
      });
    }
  };

  setAnswer = (questionId: ID, answerId: ID) => {
    const { question } = this.props;
    const questionObj = question.conditions.find(
      (selectionItem) => selectionItem.questionId === questionId
    );
    const remainingSelection = question.conditions.filter(
      (selectedObj) => selectedObj !== questionObj
    );
    //if question found -> edit and remove old
    if (questionObj) {
      //answerId not included yet
      if (Boolean(questionObj.answers.filter((answer) => answer === answerId).length === 0)) {
        questionObj.answers.push(answerId);
        const editedItem = questionObj;
        const newConditions = [...remainingSelection, editedItem];
        this.handleConditionSelection(newConditions);
      } else {
        //answerId already included -> remove
        const reducedAnswers = questionObj.answers.filter((answerItem) => answerItem !== answerId);
        const reducedQuestionObj = { questionId: questionId, answers: reducedAnswers };
        reducedAnswers.length
          ? this.handleConditionSelection([...remainingSelection, reducedQuestionObj])
          : this.handleConditionSelection([...remainingSelection]);
      }
    } else {
      //if not found, create new
      const newItem = { questionId: questionId, answers: [answerId] };
      this.handleConditionSelection([...question.conditions, newItem]);
    }
  };

  renderConditionIcon(props: QuestionProps) {
    const { otherQuestions, questionIndex, t, classes, questionnaireSkipToEndInfo } = props;

    return (
      <Tooltip
        title={<>{i18next.t(translations.questionnaire.toolTip.questionSkipCondition)}</>}
        aria-label="preview"
        placement={"left"}
      >
        <div>
          <Button
            contained={Boolean(props.question.conditions.length)}
            size="small"
            onClick={this.toggleConditionMenuOpen}
            white={false}
            color="primary"
            icon={<SkipLogic fill={props.question.conditions.length ? "#fff" : "#6d7278"} />}
          />
          <Menu
            disableAutoFocus={true}
            open={this.state.conditionMenuOpen}
            onClose={this.toggleConditionMenuOpen}
            anchorEl={document.getElementById("stylingWrapper")}
            classes={{ paper: classes.conditionMenu, list: classes.conditionList }}
          >
            <Typography className={classes.conditionTitle}>
              {`${this.props.questionBadge} ${t(
                translations.questionnaire.questionCondition.for
              )} ${t(translations.questionnaire.questionCondition.title)}`}
            </Typography>
            <div className={classes.menuItemsWrapper}>
              {props.question.conditions.length > 0 && (
                <MenuItem value={""} onClick={this.handleConditionCompleteDelete}>
                  {t(translations.questionnaire.questionCondition.removeCompleteSelection)}
                </MenuItem>
              )}

              {otherQuestions
                //only show previous questions
                .filter(
                  (question: Question, index: number) =>
                    index < questionIndex &&
                    (question.type === "singleChoice" || question.type === "multipleChoice") &&
                    questionnaireSkipToEndInfo.find(
                      (infoItem: SkipToInfo) =>
                        infoItem.questionId === question.id &&
                        infoItem.answerSkipToInfo.length === question.body.answerOptions.length
                    ) === undefined
                )
                .map((question: Question) => {
                  const selectedQuestion = props.question.conditions.filter(
                    (item) => item.questionId === question.id
                  );
                  return (
                    <div key={question.id}>
                      {/*question*/}
                      <MenuItem
                        id={question.id}
                        value={question.id}
                        onClick={() => {
                          this.toggleAnswers(question.id);
                        }}
                        className={classes.conditionMenuItem}
                        style={{
                          ...(selectedQuestion.length ? { backgroundColor: secondary } : {}),
                        }}
                      >
                        {t(translations.questionnaire.question)}{" "}
                        {otherQuestions.indexOf(question) + 1}: {question.text}
                      </MenuItem>
                      {/*answerOptions*/}
                      {this.state.questionsWithOpenAnswers.filter(
                        (itemId) => itemId === question.id
                      ).length > 0 && (
                        <List component={"div"} className={classes.answerItemList}>
                          {question.body.answerOptions.map(
                            (answerOptionItem: AnswerOption) =>
                              questionnaireSkipToEndInfo.find(
                                (item: SkipToInfo) =>
                                  item.questionId === question.id &&
                                  item.answerSkipToInfo.find(
                                    (answerInfo) => answerInfo.answerId === answerOptionItem.id
                                  )
                              ) === undefined && (
                                <ListItemText
                                  key={answerOptionItem.id}
                                  primary={answerOptionItem.label}
                                  onClick={() => this.setAnswer(question.id, answerOptionItem.id)}
                                  style={{
                                    ...(selectedQuestion.length &&
                                    selectedQuestion[0].answers.filter(
                                      (answer) => answer === answerOptionItem.id
                                    ).length
                                      ? { color: secondary }
                                      : {}),
                                  }}
                                />
                              )
                          )}
                        </List>
                      )}
                    </div>
                  );
                })}
            </div>
          </Menu>
        </div>
      </Tooltip>
    );
  }

  renderComplexityIcon(props: QuestionProps) {
    const { classes, t } = props;

    return (
      <Tooltip
        title={<>{t(translations.questionnaire.toolTip.questionDiscardFromComplexity)}</>}
        aria-label="preview"
        placement={"left"}
      >
        <div>
          <Button
            contained={props.question.deprivedFromComplexity}
            size="small"
            onClick={this.toggleDeprivedFromComplexity}
            white={false}
            color={"quarternary"}
            className={classes.complexityButton}
            icon={<NotInterestedOutlined className={classes.deprivedComplexityIcon} />}
          />
        </div>
      </Tooltip>
    );
  }

  render() {
    const {
      classes,
      question,
      t,
      questionBadge,
      lastQuestion,
      firstQuestion,
      otherQuestions,
      questionIndex,
      questionnaireSkpiToIdInfo,
      questionnaireSkipToEndInfo,
      questionQuestionnaireErrors,
      user,
    } = this.props;
    const { deleteDialogOpen, previewOpen, valid, errors, questionBodyOpen } = this.state;

    const questionConfig = QuestionForm.questionTypes[question.type];
    if (!questionConfig) {
      return <p>Dieser Fragetyp kann aktuell leider nicht dargestellt werden.</p>;
    }

    const checkForEmptySelectionOptionsThroughType = otherQuestions.filter(
      (question: Question, index: number) =>
        index < questionIndex &&
        (question.type === "singleChoice" || question.type === "multipleChoice")
    );

    const checkPrevQuestionsLength = otherQuestions.filter(
      (prevQuestion, index) =>
        index < questionIndex &&
        questionnaireSkipToEndInfo.find(
          (item: SkipToInfo) =>
            item.questionId === prevQuestion.id &&
            item.answerSkipToInfo.length === prevQuestion.body.answerOptions.length
        ) === undefined
    ).length;

    return (
      <div className={classes.questionComponent}>
        {/* Header */}
        <div className={classes.questionHeader}>
          <div className={classes.headerInputs}>
            {/* Navigate Question up or down */}
            <div className={classes.questionNavigation}>
              {!firstQuestion && this.props.onQuestionMoveUp && (
                <IconButton
                  aria-label="up"
                  className={classes.questionNavigationArrow}
                  onClick={this.handleMoveUpClick}
                >
                  <Icons.ArrowUp fill="#fff" height={15} />
                </IconButton>
              )}
              {!lastQuestion && this.props.onQuestionMoveDown && (
                <IconButton
                  aria-label="down"
                  className={classes.questionNavigationArrow}
                  onClick={this.handleMoveDownClick}
                >
                  <Icons.ArrowDown fill="#fff" height={15} />
                </IconButton>
              )}
            </div>
            {/* Validation Hints */}
            <Tooltip
              open={this.state.tooltipOpen}
              title={
                valid && questionQuestionnaireErrors && questionQuestionnaireErrors.length === 0 ? (
                  <>
                    <Typography>{t(translations.panel.generalInfo.validStatus)}</Typography>
                  </>
                ) : errors.length > 0 ? (
                  <>
                    {errors.map((error, idx) => (
                      <Typography key={idx}>{error}</Typography>
                    ))}
                  </>
                ) : (
                  <>
                    {questionQuestionnaireErrors &&
                      questionQuestionnaireErrors.length > 0 &&
                      questionQuestionnaireErrors[0] && (
                        <Typography>
                          {t(translations.errorhandling[questionQuestionnaireErrors[0].code])}
                        </Typography>
                      )}
                  </>
                )
              }
              placement="top"
              // {/* Validity */}
              children={
                <Badge
                  color={
                    valid && questionQuestionnaireErrors && questionQuestionnaireErrors.length === 0
                      ? "senary"
                      : "quinary"
                  }
                  title={""}
                  width={40}
                  className={classes.validationBadge}
                  children={
                    valid &&
                    questionQuestionnaireErrors &&
                    questionQuestionnaireErrors.length === 0 ? (
                      <Icons.Check fill={"#fff"} />
                    ) : (
                      <Icons.Flash fill={"#fff"} />
                    )
                  }
                  onMouseEnter={this.showTooltip}
                  onMouseLeave={this.hideTooltip}
                  onClick={this.toggleTooltip}
                />
              }
              onOpen={this.toggleTooltip}
            />

            {/* Label */}
            <div className={classes.questionNumber}>
              <Badge color={"secondary"} title={`${questionBadge}`} />
            </div>
            {/* Inputs: Question + QuestionType */}
            <Grid container={true} spacing={3} id={`container-${question.id}`}>
              <Grid item={true} xs={8}>
                <TextFieldWithMediaUpload
                  id="question"
                  disabled={question.type === "instruction"}
                  value={question.type === "instruction" ? "" : question.text}
                  placeholder={questionConfig.titlePlaceholderText}
                  onBlur={this.handleChangeOfQuestionInput}
                  onMediaUploaded={this.handleMediaUploaded}
                  onMediaUploadFailed={this.handleMediaUploadFail}
                  required={true}
                  multiline={true}
                  className={classes.questionInput}
                  mediaReference={{ questionId: question.id }}
                  uploadError={this.state.uploadError}
                  preventMediaUpload={this.props.preventMediaUpload}
                />
              </Grid>
              <Grid item={true} xs={3}>
                {this.renderQuestionTypeSelect()}
              </Grid>

              <Grid item={true} xs={1} style={{ marginTop: 17 }}>
                {/* Toggle QuestionBody */}
                <IconButton
                  aria-label="up"
                  className={classNames({
                    [classes.questionBodyToggle]: true,
                    [classes.questionBodyOpen]: questionBodyOpen,
                  })}
                  onClick={this.toggleQuestionBody}
                  classes={{ label: classes.toggleIcon }}
                >
                  <Icons.AccordionArrow fill="#6d7278" height={25} width={32} />
                </IconButton>
              </Grid>
              <Grid container={true} className={classes.questionFeatures}>
                {/* Media */}
                <Grid item={true}>
                  {!!question.media.length && (
                    <UploadedMediaItem
                      media={question.media[0]}
                      onMediaRemove={this.handleMediaRemove}
                    />
                  )}
                </Grid>
                <Grid item={true} className={classes.itemFooter}>
                  <div className={classes.questionIconWrapper}>
                    {/*complexity integration for admin only*/}
                    {user && user.current.isAdmin() && this.renderComplexityIcon(this.props)}
                    {/*condition*/}
                    {!firstQuestion &&
                      checkForEmptySelectionOptionsThroughType.length > 0 &&
                      questionnaireSkpiToIdInfo.length <= 0 &&
                      checkPrevQuestionsLength > 0 &&
                      this.renderConditionIcon(this.props)}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Body */}
        {questionBodyOpen && (
          <Card isRaised={true} className={classes.questionBody}>
            {/* Dynamic depending on selected Question Type */}
            {this.renderQuestionBody()}
            {/* ActionBar */}
            <Card isRaised={false} className={classes.questionActionBar}>
              {questionConfig.randomizable && !this.props.preventRandomizeAnswers && (
                <Tooltip title={"Antwortmöglichkeiten werden randomisiert"} aria-label="preview">
                  <div className={classes.questionActionBarItem}>
                    <Button
                      size="small"
                      contained={question.body.randomizeAnswerOptions}
                      onClick={this.toggleRandomizeAnswerOptions}
                      color="tertiary"
                      icon={
                        <Icons.Shuffle
                          fill={question.body.randomizeAnswerOptions ? "white" : "#6D7278"}
                        />
                      }
                      style={{
                        backgroundColor: question.body.randomizeAnswerOptions ? "#6D7278" : "white",
                      }}
                    />
                  </div>
                </Tooltip>
              )}
              <Tooltip
                title={t(translations.questionnaire.toolTip.previewMobileView).toString()}
                aria-label="preview"
              >
                <div className={classes.questionActionBarItem}>
                  <Button
                    white={true}
                    size="small"
                    color="tertiary"
                    onClick={this.toggleQuestionPreview}
                    icon={<Icons.Preview />}
                  />
                </div>
              </Tooltip>
              {this.props.onQuestionCopy && (
                <Tooltip title={"Frage kopieren"} aria-label="copy">
                  <div className={classes.questionActionBarItem}>
                    <Button
                      white={true}
                      size="small"
                      color="tertiary"
                      onClick={this.handleCopyClick}
                      icon={<Icons.Copy />}
                    />
                  </div>
                </Tooltip>
              )}
              {this.props.onQuestionDelete && (
                <Tooltip title={"Frage löschen"} aria-label="delete">
                  <div className={classes.questionActionBarItem}>
                    <Button
                      white={true}
                      size="small"
                      color="tertiary"
                      onClick={this.toggleDeleteDialog}
                      icon={<Icons.Delete fill="#6D7278" />}
                    />
                  </div>
                </Tooltip>
              )}
            </Card>
          </Card>
        )}
        {/* Preview Modal */}
        <ModalComponent
          label="question-preview"
          description="open preview for maintained question"
          open={previewOpen}
          onClose={this.toggleQuestionPreview}
          isPreview={true}
          showArrow={true}
        >
          {this.renderQuestionPreview()}
        </ModalComponent>
        {/* Delete Dialog */}
        <DialogComponent
          aria-labelledby="question-delete"
          aria-describedby="open delete dialog"
          open={deleteDialogOpen}
          closeFunction={this.toggleDeleteDialog}
          disableAutoFocus={true}
          confirmFunction={this.handleDeleteClick}
          title={t(translations.dialog.deleteQuestion)}
          buttonText={t(translations.action.delete)}
        />
      </div>
    );
  }
}

export default compose<QuestionProps, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(QuestionForm);
