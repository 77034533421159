import { ActionType } from "typesafe-actions";

interface InitalState {
  visitedSteps: string[];
  mediaUploadError: string | null;
  registrationSteps: string[];
  errors?: boolean;
  showMobileMenu: boolean;
  participationNotAllowed: boolean;
}

const defaultState = {
  visitedSteps: [] as string[],
  mediaUploadError: null,
  registrationSteps: [],
  errors: false,
  showMobileMenu: false,
  participationNotAllowed: false,
};

const SET_VISITED_STEP = "uiState/SET_VISITED_STEP";
const SET_REGISTRATION_STEP = "uiState/SET_REGISTRATION_STEP";
const SHOW_MOBILE_MENU = "uiState/SHOW_MOBILE_MENU";
const HIDE_MOBILE_MENU = "uiState/HIDE_MOBILE_MENU";
const SET_ERROR = "uiState/SET_ERROR";
const REMOVE_ERROR = "uiState/REMOVE_ERROR";
const SET_PARTICIPATION_CURRENTLY_NOT_ALLOWED = "uiState/SET_PARTICIPATION_CURRENTLY_NOT_ALLOWED";
const REMOVE_PARTICIPATION_CURRENTLY_NOT_ALLOWED =
  "uiState/REMOVE_PARTICIPATION_CURRENTLY_NOT_ALLOWED";

export const actions = {
  setVisitedStep: (step: string) => {
    return {
      type: SET_VISITED_STEP,
      payload: step,
    } as const;
  },
  setRegistrationStep: (step: string) => {
    return {
      type: SET_REGISTRATION_STEP,
      payload: step,
    } as const;
  },
  showMobileMenu: () => {
    return {
      type: SHOW_MOBILE_MENU,
    } as const;
  },
  hideMobileMenu: () => {
    return {
      type: HIDE_MOBILE_MENU,
    } as const;
  },
  setError: () => {
    return {
      type: SET_ERROR,
    } as const;
  },
  removeError: () => {
    return {
      type: REMOVE_ERROR,
    } as const;
  },
  setParticipationCurrentlyNotAllowed: () => {
    return {
      type: SET_PARTICIPATION_CURRENTLY_NOT_ALLOWED,
    } as const;
  },
  removeParticipationCurrentlyNotAllowed: () => {
    return {
      type: REMOVE_PARTICIPATION_CURRENTLY_NOT_ALLOWED,
    } as const;
  },
};

export type UiStateAction = ActionType<typeof actions>;
export type State = typeof defaultState;

// TODO: create error store for different error types
export const setError = () => {
  return {
    type: SET_ERROR,
  };
};

export const removeError = () => {
  return {
    type: REMOVE_ERROR,
  };
};

export const setParticipationCurrentlyNotAllowed = () => {
  return {
    type: SET_PARTICIPATION_CURRENTLY_NOT_ALLOWED,
  };
};
export const removeParticipationCurrentlyNotAllowed = () => {
  return {
    type: REMOVE_PARTICIPATION_CURRENTLY_NOT_ALLOWED,
  };
};

export const reducer = (state: InitalState = defaultState, action: UiStateAction) => {
  switch (action.type) {
    case SET_VISITED_STEP:
      return { ...state, visitedSteps: [...state.visitedSteps, action.payload] };
    case SET_REGISTRATION_STEP:
      return { ...state, registrationSteps: [...state.registrationSteps, action.payload] };
    case SHOW_MOBILE_MENU:
      return { ...state, showMobileMenu: true };
    case HIDE_MOBILE_MENU:
      return { ...state, showMobileMenu: false };
    case SET_ERROR:
      return { ...state, errors: true };
    case REMOVE_ERROR:
      return { ...state, errors: false };
    case SET_PARTICIPATION_CURRENTLY_NOT_ALLOWED:
      return { ...state, participationNotAllowed: true };
    case REMOVE_PARTICIPATION_CURRENTLY_NOT_ALLOWED:
      return { ...state, participationNotAllowed: false };
    default:
      return state;
  }
};
