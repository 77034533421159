import React from "react";
import { PieChart as PieWrapper, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts";
import { DataItem } from "./QuestionResultItem";

interface Props {
  data: DataItem[];
  colors: string[];
}

const PieChart: React.FC<Props> = ({ data, colors }: Props) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieWrapper>
        <Pie
          dataKey="value"
          nameKey="name"
          data={data}
          label={true}
          fill="fill"
          cy={200}
          cx={200}
          labelLine={false}
        >
          {data.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend verticalAlign={"middle"} wrapperStyle={{ marginLeft: 180 }} layout={"vertical"} />
      </PieWrapper>
    </ResponsiveContainer>
  );
};

export default PieChart;
