import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import Container from "../../../../common/Layout/Container";
import Introduction from "../IntroductionForStep";
import { CreationStep } from "../index";
import { QuopinionTheme } from "../../../../constants/Theme";
import { compose } from "recompose";
import { translations } from "../../../../constants/lang/translation";
import Card from "../../../../common/Layout/components/Card";
import Button from "../../../../common/Layout/components/Button";
import SidebarContainer from "../../../../common/Layout/SidebarContainer";
import SupportContainer from "../../../../common/Layout/SupportContainer";
import PanelSummary from "./PanelSummary";
import ContentWrapper from "../../../../common/Layout/ContentWrapper";
import SummaryAuthentification from "./AuthentificationSummary";
import { RootState } from "../../../../modules";
import {
  publishTransientSurvey,
  updateTransientSurvey,
  fetchTransientSurvey,
  requestReleaseOfSurvey,
  actions as surveyActions,
} from "../../../../modules/survey";
import Survey from "../../../../entities/Survey";
import PriceSummary from "./PriceSummary";
import QuestionnaireSummary from "./QuestionnaireSummary";
import Panel from "../../../../entities/Panel";
import { withRouter, RouteComponentProps } from "react-router";
import { route as researcherDashboardRoute } from "../../Dashboard";
import Questionnaire from "../../../../entities/Questionnaire";
import ID from "../../../../entities/ID";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import User from "../../../../entities/User";
import DialogComponent from "../../../../common/Layout/components/Dialog";
import { ErrorMessage } from "../../../../modules/surveyErrors";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      position: "relative",
    },
    priceTable: {
      width: "100%",
    },
    priceTableRow: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(6),
      "&:first-of-type": {
        paddingTop: theme.spacing(0),
      },
    },
    buttonLine: {
      marginTop: theme.spacing(8),
    },
    disabledButtonInfoWrapper: {
      textAlign: "end",
      marginTop: theme.spacing(6),
    },
    activeButtonInfoWrapper: {},
  });

interface OwnProps {
  stepConfiguration?: CreationStep;
  handlePrev(): void;
}

interface State {
  requestPublishLoading: boolean;
  adminChangeDialgOpen: boolean;
}

interface StateProps {
  transientQuestionnaire: Questionnaire;
  surveyTitle: string;
  transientSurvey: Survey;
  isAuthenticated: boolean;
  panel: Panel;
  panelConfiguration: [];
  surveyId: ID;
  isLoggedIn: boolean;
  dictionary: { [key: string]: string };
  user: User;
  globalPanelErrors: ErrorMessage[];
  criteriaPanelErrors: ErrorMessage[];
  globalQuestionnaireErrors: ErrorMessage[];
  questionQuestionnaireErrors: ErrorMessage[];
  autoPricing: boolean;
}

interface DispatchProps {
  updateTransientSurvey: (transientSurvey: any) => Promise<void>;
  publishTransientSurvey: () => Promise<void>;
  fetchTransientSurvey: (id: ID) => Promise<void>;
  requestReleaseOfSurvey: (id: ID) => Promise<void>;
  fetchPanelConfiguration: () => Promise<void>;

  /// ///
  setGenderAttributeId: typeof surveyActions.setGenderAttributeId;
  setAgeAttributeId: typeof surveyActions.setAgeAttributeId;
  setHouseholdSizeAttributeId: typeof surveyActions.setHouseholdSizeAttributeId;
  setIncomeAttributeId: typeof surveyActions.setIncomeAttributeId;
  setCountyAttributeId: typeof surveyActions.setCountyAttributeId;
  setStateAttributeId: typeof surveyActions.setStateAttributeId;
  setTouristAreaAttributeId: typeof surveyActions.setTouristAreaAttributeId;
  setPopulationDensityAttributeId: typeof surveyActions.setPopulationDensityAttributeId;
  setLeitregionAttributeId: typeof surveyActions.setLeitregionAttributeId;
  setEducationAttributeId: typeof surveyActions.setEducationAttributeId;
  setChildrenAttributeId: typeof surveyActions.setChildrenAttributeId;
  setFamilyStatusAttributeId: typeof surveyActions.setFamilyStatusAttributeId;
}

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps;

class StepSummary extends PureComponent<Props, State> {
  state = {
    requestPublishLoading: false,
    adminChangeDialgOpen: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handlePublishClick = async () => {
    const { requestReleaseOfSurvey, surveyId } = this.props;

    // TODO: change to requestReleaseOfSurvey when architecture ready to handle requestRelease and ReleaseOfSurvey
    //  decided by researcher separately

    this.setState({
      requestPublishLoading: true,
    });
    try {
      await requestReleaseOfSurvey(surveyId);
      this.props.history.push(researcherDashboardRoute);
    } catch (error) {
      this.setState({
        requestPublishLoading: false,
      });
    }
  };

  handleOnSaveClick = () => {
    const {
      updateTransientSurvey,
      transientQuestionnaire,
      surveyTitle,
      panel,
      surveyId,
    } = this.props;

    updateTransientSurvey(
      new Survey({
        id: surveyId,
        title: surveyTitle,
        questionnaire: transientQuestionnaire,
        panel,
      }).toDataJson()
    );
  };

  toggleAdminChangeDialog = () => {
    this.setState({
      adminChangeDialgOpen: !this.state.adminChangeDialgOpen,
    });
  };

  render() {
    const {
      classes,
      dictionary,
      isAuthenticated,
      panel,
      stepConfiguration,
      t,
      transientSurvey,
      user,
      globalQuestionnaireErrors,
      globalPanelErrors,
      criteriaPanelErrors,
      questionQuestionnaireErrors,
    } = this.props;

    const { requestPublishLoading, adminChangeDialgOpen } = this.state;

    return (
      <>
        {stepConfiguration && (
          <Introduction
            title={stepConfiguration.title}
            description={stepConfiguration.description}
          />
        )}
        <Grid container={true}>
          <ContentWrapper>
            <Container>
              <Card isRaised={false} className={classes.root}>
                {requestPublishLoading && <LoadingOverlay />}
                <PanelSummary
                  modificationOption={true}
                  panel={panel}
                  panelConfiguration={dictionary}
                />
                <QuestionnaireSummary survey={transientSurvey} />
                <PriceSummary survey={transientSurvey} />
              </Card>
              {!this.props.isLoggedIn && <SummaryAuthentification />}
              <Grid
                container={true}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className={classes.buttonLine}
              >
                {this.props.isAuthenticated && (
                  <Button
                    size="medium"
                    color="secondary"
                    onClick={this.handleOnSaveClick}
                    style={{ marginRight: 24 }}
                    disabled={!isAuthenticated || requestPublishLoading}
                  >
                    {t(translations.action.save)}
                  </Button>
                )}
                  <div
                  className={
                    this.props.autoPricing
                      ? classes.activeButtonInfoWrapper
                      : classes.disabledButtonInfoWrapper
                  }
                >
                {user.isAdmin() ? (
                  <>
                    <Button
                      disabled={
                        !isAuthenticated ||
                        requestPublishLoading ||
                        globalQuestionnaireErrors.length >= 1 ||
                        globalPanelErrors.length >= 1 ||
                        questionQuestionnaireErrors.length >= 1 ||
                        criteriaPanelErrors.length >= 1
                      }
                      contained={true}
                      size="big"
                      color="secondary"
                      onClick={this.toggleAdminChangeDialog}
                    >
                      {t(translations.action.requestPublish)}
                    </Button>
                    <DialogComponent
                      aria-labelledby="survey-change-by-admin"
                      aria-describedby="open change dialog"
                      open={adminChangeDialgOpen}
                      closeFunction={this.toggleAdminChangeDialog}
                      disableAutoFocus={true}
                      confirmFunction={this.handlePublishClick}
                      title={t(translations.pages.Survey.Admin.adminChanges.warning)}
                      buttonText={t(translations.action.requestPublish)}
                      description={t(translations.pages.Survey.Admin.adminChanges.priceChanges)}
                    />
                  </>
                ) : (
                  <Button
                    disabled={
                      !isAuthenticated ||
                      requestPublishLoading ||
                      globalQuestionnaireErrors.length >= 1 ||
                      globalPanelErrors.length >= 1 ||
                      questionQuestionnaireErrors.length >= 1 ||
                      criteriaPanelErrors.length >= 1 ||
                      !this.props.autoPricing
                    }
                    contained={true}
                    size="big"
                    color="secondary"
                    onClick={this.handlePublishClick}
                  >
                    {t(translations.action.requestPublish)}
                  </Button>
                )}
                 {!this.props.autoPricing && (
                    <Typography>
                      {t(translations.summary.price.reactiveAutomaticPriceCalculation)}
                    </Typography>
                  )}
                </div>
              </Grid>
            </Container>
            <SidebarContainer>
              <SupportContainer />
            </SidebarContainer>
          </ContentWrapper>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ survey, session, user, surveyError }: RootState) => {
  return {
    dictionary: survey.answerDictionary,
    transientQuestionnaire: survey.questionnaire,
    surveyTitle: survey.title,
    transientSurvey: survey,
    isAuthenticated: session.authenticated,
    isLoggedIn: Boolean(session.token),
    panel: survey.panel,
    panelConfiguration: survey.panelConfiguration,
    surveyId: survey.id,
    user: user.current,
    globalPanelErrors: surveyError.globalPanelErrors,
    criteriaPanelErrors: surveyError.criteriaPanelErrors,
    globalQuestionnaireErrors: surveyError.globalQuestionnaireErrors,
    questionQuestionnaireErrors: surveyError.questionQuestionnaireErrors,
    autoPricing: survey.autoPricing,
  };
};

const mapDispatchToProps = {
  publishTransientSurvey,
  updateTransientSurvey,
  fetchTransientSurvey,
  requestReleaseOfSurvey,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StepSummary);
