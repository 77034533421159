import React from "react";
import Badge from "../../../../common/Layout/components/Badge";
import * as Icons from "../../../../assets/icon";

const CompletedBadge = () => (
  <Badge color="senary" width="30" textId={"completed"}>
    <Icons.Check fill={"#fff"} />
  </Badge>
);

export default CompletedBadge;
