import React, { ChangeEvent, useState } from "react";
import { createStyles, TextField, WithStyles, withStyles } from "@material-ui/core";
import { translations } from "../../../constants/lang/translation";
import { primary, QuopinionTheme } from "../../../constants/Theme";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";

interface OwnProps {
  savedSurveyTitle: string;
  handleTitleChange: (event: any) => void;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    inputRoot: {
      color: primary,
      paddingLeft: theme.spacing(8),
      flexGrow: 1,
      "&>div": {
        border: "none",
      },
      "& input:focus": {
        color: `${primary} !important`,
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
      },
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const SurveyTitelModification: React.FC<Props> = ({
  classes,
  t,
  savedSurveyTitle,
  handleTitleChange,
}) => {
  const [editedTitle, setEditedTitle] = useState(savedSurveyTitle);

  return (
    <TextField
      type="text"
      placeholder={t(translations.pages.Survey.surveyTitle)}
      value={editedTitle ? editedTitle : savedSurveyTitle}
      classes={{ root: classes.inputRoot }}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setEditedTitle(event.target.value);
      }}
      onBlur={handleTitleChange}
    />
  );
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(SurveyTitelModification);
