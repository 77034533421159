import React from "react";
import { compose } from "recompose";
import { createStyles, withStyles, WithStyles, Grid, Typography } from "@material-ui/core";
import { QuopinionTheme } from "../../constants/Theme";
import { Link } from "react-router-dom";
import { Logo } from "../../assets/icon";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../constants/lang/translation";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "space-around",
      height: theme.spacing(25),
      "@media(min-width: 600px)": {
        flexDirection: "row",
      },
      "@media(min-width: 630px)": {
        padding: `${theme.spacing(6)}px ${theme.spacing(10.5)}px ${theme.spacing(4)}px`,
        height: theme.spacing(16),
      },
    },
    firstLink: {
      marginRight: 0,
      color: theme.palette.common.white,
      padding: 5,
      textAlign: "center",
      "@media(min-width: 630px)": {
        marginRight: theme.spacing(6),
      },
    },
    link: {
      color: theme.palette.common.white,
      padding: 5,
      textAlign: "center",
    },
    copyright: {
      color: theme.palette.common.white,
      padding: 5,
      textAlign: "center",
      lineHeight: 1.14,
    },
    logo: {
      marginRight: theme.spacing(15),
      marginTop: theme.spacing(1),
    },
    linkWrapper: {
      fontWeight: 400,
      lineHeigt: 1.14,
      width: "372px",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      alignItems: "flex-end",
      marginTop: "-5px",
      "@media(min-width: 630px)": {
        alignItems: "center",
        flexDirection: "row",
      },
    },
    copyrightWrapper: {
      display: "flex",
      alginItems: "center",
      position: "absolute",
      top: "32px",
      "@media(min-width: 630px)": {
        top: 0,
        right: "10px",
      },
    },
    logoWrapper: {
      display: "flex",
      width: "100%",
    },
    footerWrapper: {
      width: "100%",
      position: "relative",
      "@media(min-width: 465px)": {
        alignItems: "center",
        flexDirection: "row",
        display: "flex",
      },
      justifyContent: "space-between",
      alignItems: "center",
    },
  });

interface OwnProps {
  dataSecurityLink: string;
  imprintLink: string;
}

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const FooterItem: React.FC<Props> = ({ classes, t, dataSecurityLink, imprintLink }) => {
  return (
    <Grid item={true} xs={12} className={classes.root}>
      <div className={classes.footerWrapper}>
        <div className={classes.logoWrapper}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <div className={classes.linkWrapper}>
            <Link to={dataSecurityLink} className={classes.firstLink}>
              {t(translations.pages.landingpage.dataPrivacy)}
            </Link>
            <Link to={imprintLink} className={classes.link}>
              {t(translations.pages.Impressum.introHeadline)}
            </Link>
          </div>
        </div>
        <div className={classes.copyrightWrapper}>
          <Typography variant="body1" className={classes.copyright}>
            &copy; 2022 Quotas GmbH
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

export const Footer = compose<Props, OwnProps>(withTranslation(), withStyles(styles))(FooterItem);
