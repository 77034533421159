import React, { PureComponent } from "react";
import { createStyles, Typography, withStyles, WithStyles } from "@material-ui/core";
import { compose } from "recompose";
import { blueGradient, QuopinionTheme } from "../../../../constants/Theme";
import {
  formatDuration,
  SurveyAdminInformation,
  SurveyInformation,
  SurveyStatus,
} from "../../../../entities/Survey";
import { fetchCurrentResearcherSurveys } from "../../../../modules/user";
import Card from "../../../../common/Layout/components/Card";
import Button from "../../../../common/Layout/components/Button";
import Badge from "../../../../common/Layout/components/Badge";
import { translations } from "../../../../constants/lang/translation";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { route as SurveyCreatorPageRoute } from "../../SurveyCreator";
import moment from "moment";
import { publishTransientSurvey } from "../../../../modules/survey";
import { connect } from "react-redux";
import Services from "../../../../services/Services";
import { RootState } from "../../../../modules";
import AdditionalSurveyInformation from "../../../admin/common/AdditionalSurveyInformation";

interface OwnProps {
  survey: SurveyInformation;
  status: SurveyStatus;
  withDetailsButton: boolean;
  additionalInformation?: SurveyAdminInformation;
}

interface DispatchProps {
  publishTransientSurvey: () => Promise<void>;
  fetchCurrentResearcherSurveys: () => Promise<void>;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      background: (props: Props) => (props.status === SurveyStatus.NEW ? blueGradient : "#fff"),
      position: "relative",
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    },
    buttonLine: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    button: {
      marginLeft: theme.spacing(2),
    },
    badge: {
      width: "fit-content",
      color: (props: OwnProps) =>
        props.status === SurveyStatus.NEW ? theme.palette.primary.main : "#fff",
      right: 12,
      lineHeight: 0,
      textTransform: "capitalize",
    },
    date: {
      display: "flex",
      flexWrap: "wrap",
    },
    info: {
      margin: (props: OwnProps) =>
        props.status === SurveyStatus.NEW
          ? `${theme.spacing(2)}px 0 ${theme.spacing(4)}px`
          : `${theme.spacing(2)}px 0 ${theme.spacing(6)}px`,
    },
    progressArea: {
      position: "relative",
      display: "flex",
      flexGrow: 1,
      "& progress[value]": {
        appearance: "none",
        WebkitAppearance: "none",
        height: 24,
        width: "100%",
        "&::-webkit-progress-bar": {
          backgroundColor: theme.palette.grey[100],
          opacity: (props: OwnProps) =>
            props.status === SurveyStatus.PUBLISH_REQUESTED ? 0.25 : 1,
          borderRadius: 4,
        },
        "&::-webkit-progress-value": {
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 4,
        },
      },
    },
    progressValue: {
      position: "absolute",
      left: "8px",
      opacity: (props: OwnProps) => (props.status === SurveyStatus.PUBLISH_REQUESTED ? 0.25 : 1),
    },
    wrapper: {
      display: "flex",
      width: "100%",
      "@media screen and (min-width: 768px)": {
        width: "75%",
      },
      "@media screen and (min-width: 1024px)": {
        width: "50%",
      },
    },
    priceTitle: {
      color: theme.palette.grey[400],
      marginBottom: theme.spacing(1),
    },
    title: {
      margin: `${theme.spacing(2)}px 0 0`,
    },
    linkText: {
      textDecoration: "none",
    },
    headerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    priceValue: {
      textAlign: "right", //ToDo check if marginRight needed, when save survey available
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& svg": {
        fill: "#6d7278",
        marginRight: theme.spacing(2),
      },
    },
    absoluteValues: {
      marginLeft: theme.spacing(4),
      fontSize: "11px",
      lineHeight: "16px",
      letterSpacing: "1px",
      fontFamily: "robotoregular",
      alignSelf: "center",
    },
  });

type Props = OwnProps &
  DispatchProps &
  WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps;

const PriceComponent = (props: Props) => (
  <div>
    <Typography variant="caption" className={props.classes.priceTitle}>
      {props.t(translations.summary.price.finalPrice)}{" "}
      {props.t(translations.summary.price.taxesGeneral)}
    </Typography>
    <Typography variant="h3" className={props.classes.priceValue}>
      {props.survey.totalPriceWithTaxes}
    </Typography>
  </div>
);

class SurveyCard extends PureComponent<Props> {
  calculateProgress = () => {
    const total = this.props.survey.panelSize;
    const current = this.props.survey.completedParticipants;
    const result = (current / total) * 100;
    return Math.round(result);
  };

  routeToDetailsPage = () => {
    this.props.history.push(`/market-research/survey-details/${this.props.survey.id}`);
  };

  publishSurvey = () => {
    this.props.publishTransientSurvey();
  };

  deleteSurvey = async () => {
    await Services.survey
      .deleteSurvey(this.props.survey.id)
      .then(() => this.props.fetchCurrentResearcherSurveys());
  };

  renderBottomArea = () => {
    const { classes, t, status, withDetailsButton, survey } = this.props;

    switch (this.props.status) {
      case "NEW":
        return (
          <div className={classes.wrapper}>
            <Link to={`${SurveyCreatorPageRoute}/panel/${survey.id}`} className={classes.linkText}>
              <Button size="small" color="primary" white={true}>
                {t(translations.action.finalizeQuestionnaire)}
              </Button>
            </Link>
            <Button
              size="small"
              color="tertiary"
              className={classes.button}
              onClick={this.deleteSurvey}
            >
              {t(translations.action.delete)}
            </Button>
          </div>
        );
      case "ENDED":
        return (
          <>
            <Link to={`/market-research/survey-details/${survey.id}`} className={classes.linkText}>
              <Button
                contained={true}
                color="secondary"
                size="small"
                id={`seeResults-${survey.id}`}
              >
                {t(translations.action.seeResults)}
              </Button>
            </Link>
            <PriceComponent {...this.props} />
          </>
        );
      case "ACCEPTED":
        return (
          <div className={classes.wrapper}>
            {" "}
            {/*to change later, MVP direct Admin publish*/}
            {/*    <Link to={`${SurveyCreatorPageRoute}/panel/${survey.id}`} className={classes.linkText}>
              <Button size="small" color="primary" white={true}>
                {t(translations.action.finalizeQuestionnaire)}
              </Button>
            </Link>
            <Button
              size="small"
              color="primary"
              contained={true}
              className={classes.button}
              onClick={this.publishSurvey}
            >
              {t(translations.action.goLive)}
            </Button>*/}
          </div>
        );
      case "REJECTED":
        return (
          <div className={classes.wrapper}>
            <Link to={`${SurveyCreatorPageRoute}/panel/${survey.id}`} className={classes.linkText}>
              <Button size="small" color="primary" white={true}>
                {t(translations.action.reworkSurvey)}
              </Button>
            </Link>
            {/* TODO: add functionality to buttons when corresponding components are available */}
            <Button
              size="small"
              color="secondary"
              contained={true}
              className={classes.button}
              onClick={this.deleteSurvey}
            >
              {t(translations.action.delete)}
            </Button>
          </div>
        );

      default:
        return (
          <>
            <div className={classes.wrapper}>
              <div className={classes.progressArea}>
                <progress
                  value={status === SurveyStatus.PUBLISH_REQUESTED ? 0 : this.calculateProgress()}
                  max={100}
                />
                <Typography className={classes.progressValue}>
                  {status === SurveyStatus.PUBLISH_REQUESTED
                    ? "0 %"
                    : this.calculateProgress() + " %"}{" "}
                  {t(translations.pages.dashboard.researcher.complete)}
                </Typography>
              </div>
              <div className={classes.absoluteValues}>
                {this.props.survey.completedParticipants}/{survey.panelSize}{" "}
                {t(translations.summary.panel.size.participantsShort)}
              </div>
              {/* TODO: add functionality to buttons when corresponding components are available */}

              {withDetailsButton === false ? (
                ""
              ) : (
                <Button
                  size="small"
                  color="primary"
                  underline={true}
                  className={classes.button}
                  onClick={this.routeToDetailsPage}
                  id={survey.id}
                >
                  {t(translations.pages.dashboard.researcher.details)}
                </Button>
              )}
            </div>
            <PriceComponent {...this.props} />
          </>
        );
    }
  };

  render() {
    const { classes, status, survey, t } = this.props;
    const daysUntilEnd = moment(survey.endDate).diff(moment(), "day");

    return (
      <Card isRaised={status !== SurveyStatus.NEW} className={classes.root}>
        {this.props.additionalInformation && (
          <AdditionalSurveyInformation information={this.props.additionalInformation} />
        )}
        {status !== SurveyStatus.NEW && (
          <div className={classes.headerWrapper}>
            <div className={classes.date}>
              {status === SurveyStatus.PUBLISHED && (
                <Typography color="primary" variant="subtitle2">
                  {survey.endDate !== null &&
                    survey.endDate !== undefined &&
                    daysUntilEnd === 1 &&
                    `${t(translations.days.approxLeft)} ${daysUntilEnd} ${t(translations.days.sg)}`}
                  {survey.endDate !== null &&
                    survey.endDate !== undefined &&
                    daysUntilEnd !== 1 &&
                    `${t(translations.days.approxLeft)} ${daysUntilEnd} ${t(translations.days.pl)}`}
                </Typography>
              )}
              {status === SurveyStatus.ENDED && (
                <Typography color="primary" variant="subtitle1">
                  {t(translations.pages.dashboard.researcher.beginning)}{" "}
                  {moment(survey.startDate).format("DD.MM.YYYY")}
                </Typography>
              )}
              {status === SurveyStatus.PUBLISH_REQUESTED && (
                <Typography color="primary" variant="subtitle2">
                  {survey.startDate !== null && moment(survey.startDate).format("DD.MM.YYYY")}
                </Typography>
              )}

              <Typography color="primary" variant="subtitle1">
                {survey.endDate !== null &&
                  survey.endDate !== undefined &&
                  survey.status !== SurveyStatus.ENDED && (
                    <>
                      &nbsp;- {t(translations.headerSurvey.till)}{" "}
                      {moment(survey.endDate).format("DD.MM.YYYY")}{" "}
                      {t(translations.headerSurvey.answerTime.at)}{" "}
                      {moment(survey.endDate).format("HH:mm")}{" "}
                      {t(translations.headerSurvey.answerTime.oClock)}
                    </>
                  )}
                {survey.endDate !== null &&
                  survey.endDate !== undefined &&
                  survey.status === SurveyStatus.ENDED && (
                    <>&nbsp;- {moment(survey.endDate).format("DD.MM.YYYY")}</>
                  )}
              </Typography>
            </div>
            <Badge
              color={
                status === SurveyStatus.PUBLISHED
                  ? "senary"
                  : status === SurveyStatus.REJECTED
                  ? "quinary"
                  : "secondary"
              }
              className={classes.badge}
              textId={survey.id}
            >
              {t(translations.pages.dashboard.researcher.states[status])}
            </Badge>
          </div>
        )}
        <div className={classes.headerWrapper}>
          <Typography
            variant="h3"
            color={status === SurveyStatus.NEW ? "textSecondary" : "textPrimary"}
            className={classes.title}
          >
            {survey.title}
          </Typography>
          {status === SurveyStatus.NEW && (
            <Badge color="quarternary" className={classes.badge}>
              {t(translations.pages.dashboard.researcher.states[status])}
            </Badge>
          )}
        </div>
        <Typography
          variant="subtitle1"
          color={status === SurveyStatus.NEW ? "textSecondary" : "textPrimary"}
          className={classes.info}
        >
          {survey.questions}&nbsp;
          {t(translations.questions.question, { count: survey.questions })}
          &nbsp;| {formatDuration(survey.duration, t)} |{" "}
          {survey.status === SurveyStatus.ENDED && `${this.props.survey.completedParticipants}/`}
          {survey.panelSize} {t(translations.summary.panel.size.participantsShort)}
          &nbsp;| {t(translations.pages.dashboard.researcher.createdAt)}{" "}
          {survey.createdAt !== null && moment(survey.createdAt).format("DD.MM.YYYY")}
        </Typography>
        <div className={classes.buttonLine}>{this.renderBottomArea()}</div>
      </Card>
    );
  }
}

const mapStateToProps = ({ survey }: RootState) => ({});

const mapDispatchToProps = {
  fetchCurrentResearcherSurveys,
  publishTransientSurvey,
};

export default compose<Props, OwnProps>(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SurveyCard);
