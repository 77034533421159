import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import Card from "../../../../common/Layout/components/Card";
import PanelItem from "../../../../common/Layout/components/PanelItem";
import PanelModal from "../../../../common/Layout/components/PanelModal";
import { WithTranslation, withTranslation } from "react-i18next";
import { blueGradient, QuopinionTheme } from "../../../../constants/Theme";
import { translations } from "../../../../constants/lang/translation";
import AttributeSelect, { OptionsType } from "../../../../common/Layout/components/AttributeSelect";
import { compose } from "recompose";
import { connect } from "react-redux";

import {
  actions as surveyActions,
  deletePanelGenderAll,
  removePanelGender,
  setHouseHoldSize,
  setPanelGender,
  setPanelGenderAll,
  setPanelIncome,
  updatePanelAgeRange,
} from "../../../../modules/survey";
import Panel, {
  Gender,
  GenderShare,
  genderValues,
  Household,
  houseHoldValues,
  Income,
} from "../../../../entities/Panel";

import { RootState } from "../../../../modules";
import Questionnaire from "../../../../entities/Questionnaire";
import PanelSize from "./PanelTypes/PanelSize";
import GenderSelection from "./PanelTypes/GenderSelection";
import AgeRange from "./PanelTypes/AgeRange";
import ID from "../../../../entities/ID";

export interface OwnProps {
  sectionAttributes: any[];
}
export interface DispatchProps {
  removePanelGender: (gender: GenderShare) => { type: string; gender: GenderShare };
  setPanelSize: typeof surveyActions.setPanelSize;
  setPanelGender: typeof setPanelGender;
  setHouseholdAttributeId: typeof surveyActions.setHouseholdSizeAttributeId;
  setIncomeAttributeId: typeof surveyActions.setIncomeAttributeId;
  setHouseHoldSize: typeof setHouseHoldSize;
  setPanelGenderAll: typeof setPanelGenderAll;
  deletePanelGenderAll: typeof deletePanelGenderAll;
  updatePanelAgeRange: typeof updatePanelAgeRange;
  setPanelIncome: typeof setPanelIncome;
  setAgeAttributeId: typeof surveyActions.setAgeAttributeId;
}

interface StateProps {
  panel: Panel;
  demographic: any;
  panelConfiguration: [];
  transientQuestionnaire: Questionnaire;
  criteriaPanelErrors: [];
}

interface OwnState {
  extendAgeOptions: boolean;
  showHouseHoldIncome: boolean;
}

type State = OwnState;

const styles = (theme: QuopinionTheme) =>
  createStyles({
    greyText: {
      color: theme.palette.grey[400],
    },
    checkBox: {
      background: blueGradient,
      borderRadius: "4px",
      color: "#fff !important",
      fontSize: "11px !important",
      marginRight: theme.spacing(4),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2.75),
      paddingRight: theme.spacing(4),
      width: 121,
      "& svg": {
        width: 22,
        height: 22,
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    householdCheckbox: {
      color: theme.palette.grey[400],
    },
    smallInput: { width: 70 },
    moreButton: {
      marginTop: theme.spacing(4),
    },
    distributionContainer: {
      marginTop: theme.spacing(4),
    },
    selectionTitle: {
      marginRight: theme.spacing(3),
      width: 50,
    },
    input: {
      width: 70,
      margin: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
      "& input": {
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
    },
  });

type Props = OwnProps & WithTranslation & DispatchProps & StateProps & WithStyles<typeof styles>;

export interface AttributeConfig {
  title: string;
  values?: any;
  options: OptionsType[];
}

export type AttributeType = "household" | "gender";

class DemographicsCard extends React.Component<Props, State> {
  panelAttributes: { [type in AttributeType]: AttributeConfig } = {
    household: {
      title: `${this.props.t(translations.summary.panel.household.title)}`,
      options: houseHoldValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.household[value]),
      })),
    },
    gender: {
      title: `${this.props.t(translations.summary.panel.gender.title)}`,
      options: genderValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.gender[value]),
      })),
    },
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      extendAgeOptions: false,
      showHouseHoldIncome: false,
    };
    this.props.sectionAttributes.forEach((item: any) => {
      if (item.name === "Haushaltsgröße" && !this.props.panel.household.attributeId) {
        this.props.setHouseholdAttributeId(item.id);
      } else if (item.question.type === "number" && !this.props.panel.age.attributeId) {
        this.props.setAgeAttributeId(item.id);
      } else if (item.name === "Haushaltseinkommen" && !this.props.panel.income.attributeId) {
        this.props.setIncomeAttributeId(item.id);
      }
    });
  }

  handleAgeRangeChange = (values: number | number[], share: number = 100) => {
    if (Array.isArray(values)) {
      const range = { min: values[0], max: values[1], share };

      console.log(range);
    }
  };

  isElementChecked = (value?: any) => {
    const { panel } = this.props;

    if (value === "all") {
      return panel.gender.answer.length === 3;
    }
    return !!panel.gender.answer.find((entry) => entry.answer === value);
  };

  handleAllGenderSelect = (selectionIds: ID[], checked: boolean) => {
    const { setPanelGenderAll, deletePanelGenderAll } = this.props;

    if (checked) {
      const selectedGender = selectionIds.map((id) => ({ answer: id, percentage: 100 }));
      setPanelGenderAll(selectedGender);
    } else {
      deletePanelGenderAll();
    }
  };

  handleGenderSelect = (element: Gender, value: string, checked: boolean, weight: number = 100) => {
    const { removePanelGender, setPanelGender } = this.props;
    if (checked) {
      const gender = { answer: element, percentage: weight, label: value };
      setPanelGender(gender);
    } else {
      const gender = { answer: element, percentage: weight, label: value };
      removePanelGender(gender);
    }
  };

  handleShowHouseHoldIncome = () => {
    this.setState({ showHouseHoldIncome: !this.state.showHouseHoldIncome });
  };

  sum = (input: any[]) => {
    let sumValue = 0;
    for (const item of input) {
      sumValue += Number(item);
    }
    return sumValue;
  };

  checkShareValues = (array: any[]) => {
    const shareValues = array.map((item) => item.percentage);
    if (this.sum(shareValues) === 100) {
      return true;
    } else {
      return false;
    }
  };

  checkErrorMessages = (array: any[]) => {
    const errorCode = array.map((item) => {
      return item.code;
    });
    if (errorCode[0] === 4) {
      return false;
    } else {
      return true;
    }
  };

  handleSizeChange = (size: number) => {
    const { setPanelSize } = this.props;
    setPanelSize(size);
  };

  renderPanelItem = (item: any) => {
    const { panel } = this.props;
    /*Gender*/
    if (item.panelOptions.displayType === "CHECKBOX") {
      return (
        <PanelItem
          key={item.id}
          withInfoIcon={true}
          showLine={true}
          title={this.props.t(translations.summary.panel.gender.title)}
          modalChildren={
            <PanelModal
              title={this.props.t(translations.summary.panel.gender.title)}
              description={this.props.t(translations.summary.panel.gender.modalText)}
            />
          }
        >
          <GenderSelection
            question={item}
            selectionConfig={this.panelAttributes.gender}
            selectedGender={this.props.panel.gender.answer}
            onHandleAllGenderSelect={this.handleAllGenderSelect}
            onHandleGenderSelect={this.handleGenderSelect}
          />
        </PanelItem>
      );
    }
    /*Age*/
    if (item.question.type === "number") {
      return (
        <PanelItem
          key={item.id}
          withInfoIcon={true}
          showLine={true}
          title={this.props.t(translations.summary.panel.age.title)}
          modalChildren={
            <PanelModal
              title={this.props.t(translations.summary.panel.age.title)}
              description={this.props.t(translations.summary.panel.age.modalText, {
                newParagraph: "<br/><br/>",
                space: "&nbsp",
                break: "<br/>",
              })}
            />
          }
          withShareInfo={true}
          valid={
            this.checkShareValues(this.props.panel.age.answer) &&
            this.checkErrorMessages(this.props.criteriaPanelErrors)
          }
        >
          <AgeRange panel={panel} question={item} />
        </PanelItem>
      );
    }
    /*HousholdSize*/
    if (item.name === "Haushaltsgröße") {
      return (
        <PanelItem
          key={item.id}
          withInfoIcon={panel.household.answer.length > 1}
          showLine={true}
          title={item.name}
          modalChildren={
            <PanelModal
              title={this.props.t(translations.summary.panel.household.title)}
              description={this.props.t(translations.panel.generalInfo.multiSelectHousehold, {
                break: "<br/><br/>",
              })}
            />
          }
          singleRow={true}
        >
          <AttributeSelect
            selectOptions={item.question.answerOptions.map((option: any) => ({
              label: option.label,
              value: option.id,
            }))}
            selectedValues={this.props.panel.household.answer}
            handleSelection={(values: string[]) => {
              this.props.setHouseHoldSize(values as Household[]);
            }}
            allowSelectAll={true}
          />
        </PanelItem>
      );
    }
    /*Income*/
    if (item.name === "Haushaltseinkommen") {
      return (
        <PanelItem
          key={item.id}
          withInfoIcon={false}
          showLine={true}
          title={this.props.t(translations.summary.panel.household.income)}
          modalChildren={
            <PanelModal
              title={this.props.t(translations.summary.panel.income.title)}
              description={this.props.t(translations.summary.panel.income.modalText)}
            />
          }
          singleRow={true}
        >
          <AttributeSelect
            selectOptions={item.question.answerOptions.map((option: any) => ({
              label: option.label,
              value: option.id,
            }))}
            selectedValues={this.props.panel.income.answer}
            handleSelection={(values: string[]) => {
              this.props.setPanelIncome(values as Income[]);
            }}
            allowSelectAll={true}
          />
        </PanelItem>
      );
    }
    return null;
  };

  render() {
    const { t, sectionAttributes } = this.props;

    return (
      <Card isRaised={true}>
        {/*Size*/}
        <PanelItem
          withInfoIcon={true}
          showLine={true}
          title={t(translations.summary.panel.size.title)}
          modalChildren={
            <PanelModal
              title={t(translations.summary.panel.size.title)}
              description={t(translations.summary.panel.size.modalText, {
                newParagraph: "<br/><br/>",
              })}
            />
          }
        >
          <PanelSize onHandleChange={this.handleSizeChange} amount={this.props.panel.size} />
        </PanelItem>

        {/* Render Components due to Panelconfig */}
        {sectionAttributes.map((attribute: any) => {
          return this.renderPanelItem(attribute);
        })}
      </Card>
    );
  }
}

const mapDispatchToProps = {
  setHouseholdAttributeId: surveyActions.setHouseholdSizeAttributeId,
  setIncomeAttributeId: surveyActions.setIncomeAttributeId,
  setPanelGender,
  setPanelSize: surveyActions.setPanelSize,
  setHouseHoldSize,
  removePanelGender,
  setPanelGenderAll,
  deletePanelGenderAll,
  updatePanelAgeRange,
  setPanelIncome,
  setAgeAttributeId: surveyActions.setAgeAttributeId,
};

const mapStateToProps = ({ survey, surveyError }: RootState) => ({
  panel: survey.panel,
  panelConfiguration: survey.panelConfiguration,
  transientQuestionnaire: survey.questionnaire,
  isLoading: survey.isLoading,
  demographic: survey.panelConfiguration,
  criteriaPanelErrors: surveyError.criteriaPanelErrors,
});

export default compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles)
)(DemographicsCard);
