import React, { ChangeEvent, useState } from "react";
import {
  createStyles,
  Grid,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import StepSlider from "../../../../../common/Layout/components/StepSlider";
import { translations } from "../../../../../constants/lang/translation";
import { QuopinionTheme } from "../../../../../constants/Theme";
import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    greyText: {
      color: theme.palette.grey[400],
    },
    leftText: {
      "@media (max-width: 600px)": {
        display: "none",
      },
    },
    middleText: {
      position: "absolute",
      left: "85px",
      "@media (max-width: 600px)": {
        display: "none",
      },
    },
    rightText: {
      "@media (max-width: 600px)": {
        display: "none",
      },
    },
    inputHeadline: {
      fontSize: "14px",
      lineHeight: 1.14,
      marginBottom: theme.spacing(2),
      color: theme.palette.grey[400],
    },
    input: {
      width: 100,
      "& input": {
        fontSize: "14px",
        lineHeight: 1.14,
        textAlign: "right",
      },
    },
  });

interface OwnProps {
  onHandleChange: (value: number) => void;
  amount: number;
}

// interface State {}

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const PanelSize = ({ t, classes, onHandleChange, amount }: Props) => {
  const [size, setSize] = useState(amount || 400);

  return (
    <Grid container={true} wrap="nowrap" alignItems="center" style={{ position: "relative" }}>
      <Grid item={true} xs={12} sm={10} style={{ paddingRight: 25 }}>
        <StepSlider
          step={3}
          min={0}
          max={5000}
          value={size || amount}
          defaultValue={amount}
          aria-label="sizeslider"
          valueLabelDisplay="on"
          marks={[
            { value: 400, label: "" },
            { value: 1000, label: "" },
          ]}
          marksVisible={true}
          //@ts-ignore
          onChange={(event: ChangeEvent<{}>, value: number | number[]) => setSize(value as number)}
          onChangeCommitted={(event, value) => onHandleChange(value as number)}
        />
        <Grid
          container={true}
          justify="space-between"
          style={{ position: "absolute", top: "70%", left: 0, width: "80%" }}
        >
          <Typography
            variant="caption"
            classes={{ caption: classes.greyText }}
            style={{ flexGrow: 1 }}
            className={classes.leftText}
          >
            {t(translations.summary.panel.size.trend)}
          </Typography>
          <Typography
            style={{ flexGrow: 2, textAlign: "center" }}
            variant="caption"
            classes={{ caption: classes.greyText }}
            className={classes.middleText}
          >
            {t(translations.summary.panel.size.wellGrounded)}
          </Typography>
          <Typography
            variant="caption"
            classes={{ caption: classes.greyText }}
            style={{ flexGrow: 1, textAlign: "right" }}
            className={classes.rightText}
          >
            {t(translations.summary.panel.size.detailed)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} sm={2}>
        <Typography className={classes.inputHeadline}>
          {t(translations.summary.panel.size.participants)}
        </Typography>
        <TextField
          name={"panelSize"}
          value={size || 0}
          onBlur={(event) => {
            onHandleChange(parseInt(event.target.value, 10));
          }}
          onChange={(event) => {
            setSize(parseInt(event.target.value, 10));
          }}
          className={classes.input}
        />
      </Grid>
    </Grid>
  );
};

export default compose<Props, OwnProps>(withStyles(styles), withTranslation())(PanelSize);
