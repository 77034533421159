import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Question from "../../../../entities/Question";
import ID from "../../../../entities/ID";
import { Answer, RankingAnswer } from "../../../../entities/SurveyAnswers";
import { QuopinionTheme } from "../../../../constants/Theme";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import AnswerOption from "../../../../entities/AnswerOption";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MediaDisplay from "../../../../common/Preview/components/MediaDisplay";

interface OwnProps {
  question: Question;
  currentAnswer?: RankingAnswer;
  handleUserAnswer: (questionID: ID, answer?: Answer) => void;
}

type Props = OwnProps & WithTranslation & WithStyles;

const styles = (theme: QuopinionTheme) =>
  createStyles({
    rankingItem: {
      backgroundColor: "#fff",
      padding: theme.spacing(4),
      marginLeft: 0,
      marginBottom: theme.spacing(0.5),
      lineHeight: 1.71,
      fontWeight: 400,
    },

    list: {
      paddingLeft: 0,
    },
  });

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result as AnswerOption[];
};

const Ranking: React.FC<Props> = ({ question, handleUserAnswer, t, currentAnswer, classes }) => {
  let reorderedAnswerOptions: any[] = [];
  if (
    currentAnswer !== undefined &&
    currentAnswer.type === "ranking" &&
    currentAnswer.selectedOrder.length
  ) {
    reorderedAnswerOptions = currentAnswer.selectedOrder.map((item) => {
      if (
        question.body.answerOptions.filter((answerOption) => answerOption.id === item) !== undefined
      ) {
        return question.body.answerOptions.filter((answerOption) => answerOption.id === item)[0];
      } else {
        return null;
      }
    });
  }

  const [selectedOrder, setSelectedOrder] = useState<AnswerOption[]>(
    reorderedAnswerOptions.length ? reorderedAnswerOptions : question.body.answerOptions
  );

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.indenx === result.source.index) {
      return;
    }

    const reorderedAnswerOptions = reorder(
      selectedOrder,
      result.source.index,
      result.destination.index
    );

    setSelectedOrder(reorderedAnswerOptions);

    const selectedOrderIds = reorderedAnswerOptions.map((orderedItem) => orderedItem.id);

    handleUserAnswer(question.id, {
      questionID: question.id,
      type: "ranking",
      selectedOrder: [...selectedOrderIds],
    });
  };

  const answerList = (answerOptions: AnswerOption[]) => {
    return answerOptions.map((answerOption, index) => (
      <div key={answerOption.id}>
        <Draggable draggableId={answerOption.id} index={index}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={classes.rankingItem}
            >
              {answerOption.label}
              {answerOption.media.length > 0 && (
                <MediaDisplay
                  media={answerOption.media}
                  answerOption={true}
                  withoutMediaMargin={true}
                />
              )}
            </div>
          )}
        </Draggable>
      </div>
    ));
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"list"}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {answerList(selectedOrder)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
export default compose<Props, OwnProps>(withStyles(styles), withTranslation())(Ranking);
