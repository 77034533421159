import * as React from "react";
import { createStyles, withStyles, WithStyles, Grid } from "@material-ui/core";
import { QuopinionTheme } from "../../constants/Theme";

interface OwnProps {
  centered?: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: "40px 8.33% 0",
      "@media (max-width: 768px)": {
        padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      },
    },
  });

const Container: React.FunctionComponent<OwnProps & WithStyles<typeof styles>> = ({
  classes,
  children,
  centered,
}) => {
  return (
    <Grid item={true} className={classes.root} xs={12} sm={centered ? 12 : 9}>
      {children}
    </Grid>
  );
};

export default withStyles(styles)(Container);
