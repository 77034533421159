import React, { ReactNode, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Panel, { MinMaxShareNumber } from "../../../../entities/Panel";
import { withTranslation, WithTranslation } from "react-i18next";
import { withStyles, createStyles, WithStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../../constants/Theme";
import { translations } from "../../../../constants/lang/translation";
import Button from "../../../../common/Layout/components/Button";
import Region from "../../../../entities/Region";
import * as Table from "../../../../common/Layout/components/CardTable";
import { route as SurveyCreatorPageRoute } from "../";
import { And } from "../partial/OperatorButtons";
import { RootState } from "../../../../modules";
import { getRegions } from "../../../../modules/definePanel";
import ID from "../../../../entities/ID";
import { connect } from "react-redux";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    panelValue: {
      paddingLeft: theme.spacing(5),
      fontWeight: 600,
    },
    priceTable: {
      width: "100%",
    },
    priceTableRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    linkText: {
      textDecoration: "none",
    },
  });

const PanelRow = withStyles(styles)(
  ({
    title,
    children,
    classes,
  }: { title: string; children: ReactNode } & WithStyles<typeof styles>) => (
    <tr>
      <td>
        <Typography variant="body1">{title}</Typography>
      </td>
      <td className={classes.panelValue}>
        <Typography variant="body2" color="primary">
          {children}
        </Typography>
      </td>
    </tr>
  )
);
interface OwnProps {
  modificationOption: boolean;
  panel: Panel;
  panelConfiguration: { [key: string]: string };
}

interface DispatchProps {}

interface StateProps {
  id: ID;
  answerDictionary: string[];
}

type Props = OwnProps & DispatchProps & StateProps & WithTranslation & WithStyles<typeof styles>;

const ageMapper = (age: MinMaxShareNumber, idx: number, ageArr: MinMaxShareNumber[]) => {
  // only show age-share information if more than one age-range is selected
  const shareSuffix = ageArr.length > 1 ? ` ${Math.round(age.percentage)}%` : "";
  return `${age.min}-${age.max} Jahre` + shareSuffix;
};

const PanelSummary = ({
  panel,
  t,
  modificationOption,
  classes,
  panelConfiguration: answerDictionary,
  id,
  ...props
}: Props) => {
  const [regions, setRegions] = useState<(Region | undefined)[]>([
    ...panel.county.answer,
    ...panel.state.answer,
    ...panel.leitregion.answer,
    ...panel.populationDensity.answer,
    ...panel.touristArea.answer,
  ]);

  useEffect(() => {
    let panelRegions = [
      ...panel.county.answer,
      ...panel.state.answer,
      ...panel.leitregion.answer,
      ...panel.populationDensity.answer,
      ...panel.touristArea.answer,
    ];

    if (panelRegions.length && !(panelRegions[0] instanceof Region)) {
      // This only happens when reloading the PanelSummary page!
      // We need to fetch all regions to get the name of the region then
      getRegions().then((result: Region[]) => {
        let regionsWithName = panelRegions.map((item: any) =>
          result.find((region) => region.id === parseInt(item, 10))
        );
        setRegions(regionsWithName);
      });
    }
  }, [panel]);

  return (
    <>
      {modificationOption ? (
        <Table.HeaderRow
          title={t(translations.summary.panel.headline)}
          actionArea={
            <Link to={`${SurveyCreatorPageRoute}/panel/${id}`} className={classes.linkText}>
              <Button contained={true} size="small" color="primary">
                {t(translations.action.modify)}
              </Button>
            </Link>
          }
        />
      ) : (
        <Table.HeaderRow title={t(translations.summary.panel.headline)} />
      )}

      <Table.Row>
        <table>
          <tbody>
            <PanelRow title={t(translations.summary.panel.size.title)}>
              {panel.size} {t(translations.infoSurveyItem.participants.title)}
            </PanelRow>

            {Boolean(panel.gender.answer.length) && (
              <PanelRow title={t(translations.summary.panel.gender.title)}>
                {panel.gender.answer.map((item) => answerDictionary[item.answer]).join(", ")}
              </PanelRow>
            )}
            {Boolean(panel.age.answer.length) && (
              <PanelRow title={t(translations.summary.panel.age.title)}>
                {panel.age.answer.map(ageMapper).join(", ")}
              </PanelRow>
            )}
            {Boolean(panel.household.answer.length) && (
              <PanelRow title={t(translations.summary.panel.household.title)}>
                {panel.household.answer.map((item: string) => answerDictionary[item]).join(", ")}
              </PanelRow>
            )}
            {Boolean(panel.income.answer.length) && (
              <PanelRow title={t(translations.summary.panel.income.title)}>
                {panel.income.answer.map((item: string) => answerDictionary[item]).join(", ")}
              </PanelRow>
            )}
            {Boolean(regions.length) && (
              <PanelRow title={t(translations.summary.panel.region.title)}>
                {regions
                  .map(
                    (region) =>
                      t(translations.summary.panel.region.characteristic.title[region!.typeLabel]) +
                      ": " +
                      region!.name
                  )
                  .join(", ")}
              </PanelRow>
            )}
            {Boolean(panel.education.answer.length) && (
              <PanelRow title={t(translations.summary.panel.education)}>
                {panel.education.answer.map((item: string) => answerDictionary[item]).join(", ")}
              </PanelRow>
            )}
            {Boolean(panel.family.answer.length) && (
              <PanelRow title={t(translations.summary.panel.familyStatus)}>
                {panel.family.answer.map((item: string) => answerDictionary[item]).join(", ")}
              </PanelRow>
            )}
            {Boolean(panel.children.answer.length) && (
              <PanelRow title={t(translations.summary.panel.children)}>
                {panel.children.answer.map((item: string) => answerDictionary[item]).join(", ")}
              </PanelRow>
            )}
            {Boolean(panel.individual.length) && (
              <PanelRow title={t(translations.summary.panel.freeAttributes.title)}>
                {panel.individual.map((individualAttributeObj, index) => {
                  const headline = individualAttributeObj.question;
                  const signature = individualAttributeObj.answerOptions.map((answer) => {
                    const label = answer.label;
                    const value = answer.val;
                    if (value === true) {
                      return `${label} (eingeschlossen)`;
                    }
                    if (value === false) {
                      return `${label} (ausgeschlossen)`;
                    }
                    return `${label} `;
                  });
                  const title = `"${headline}": ${signature.join(" oder ")}`;

                  return index > 0 ? (
                    <div>
                      <And />
                      {title}
                    </div>
                  ) : (
                    <div>{title}</div>
                  );
                })}
              </PanelRow>
            )}
          </tbody>
        </table>
      </Table.Row>
    </>
  );
};

const mapStateToProps = ({ survey }: RootState) => ({
  id: survey.id,
  answerDictionary: survey.answerDictionary,
});

const mapDispatchToProps = {};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(PanelSummary);
