import React, { ChangeEvent, Component } from "react";
import { compose } from "recompose";
import { createStyles, Grid, TextField, Typography, withStyles } from "@material-ui/core";
import StepSlider from "../../../../../common/Layout/components/StepSlider";
import WithShareContainer from "../../../../../common/Layout/components/WithShareContainer";
import Button from "../../../../../common/Layout/components/Button";
import { translations } from "../../../../../constants/lang/translation";
import {
  actions as surveyActions,
  addPanelAgeRange,
  removePanelAgeRange,
  setLivePriceUpdate,
  updatePanelAgeRange,
} from "../../../../../modules/survey";
import { RootState } from "../../../../../modules";
import { connect } from "react-redux";
import { QuopinionTheme } from "../../../../../constants/Theme";
import { WithStyles } from "@material-ui/styles";
import Panel, { MinMaxShareNumber } from "../../../../../entities/Panel";
import { withTranslation, WithTranslation } from "react-i18next";
import LoadingOverlay from "../../../../../common/LoadingOverlay";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    smallInput: { width: 70 },
    input: {
      width: 70,
      margin: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
      "& input": {
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
    },
    greyText: {
      color: theme.palette.grey[400],
    },
    moreButton: {
      marginTop: theme.spacing(4),
    },
    ageRangeDeviationInfo: {
      marginTop: theme.spacing(5),
      textAlign: "justify",
      fontWeight: 400,
    },
  });

interface OwnProps {
  panel: Panel;
  question: any;
}

interface DispatchProps {
  addPanelAgeRange: typeof addPanelAgeRange;
  removePanelAgeRange: typeof removePanelAgeRange;
  setAttributeId: typeof surveyActions.setAgeAttributeId;
  setLivePriceUpdate: typeof setLivePriceUpdate;
  updatePanelAgeRange: typeof updatePanelAgeRange;
}

interface StateProps {
  isLoading: boolean;
}

interface State {
  sliderRange: MinMaxShareNumber;
}

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles> & WithTranslation;

class AgeRange extends Component<Props, State> {
  state: State = {
    sliderRange: this.props.panel.age.answer[0],
  };

  deleteAgeOption = (index: number) => {
    const { removePanelAgeRange } = this.props;
    removePanelAgeRange(index);
  };

  handleAgeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    index: number
  ) => {
    const { panel, updatePanelAgeRange } = this.props;
    if (id === "share") {
      const { min, max } = panel.age.answer[index];
      const newRange = { min, max, percentage: parseInt(event.target.value, 10) };
      updatePanelAgeRange(newRange, index);
    }
    if (id === "min") {
      const { max, percentage } = panel.age.answer[index];
      const newRange = { min: parseInt(event.target.value, 10), max, percentage };
      updatePanelAgeRange(newRange, index);
    }
    if (id === "max") {
      const { min, percentage } = panel.age.answer[index];
      const newRange = { min, max: parseInt(event.target.value, 10), percentage };
      updatePanelAgeRange(newRange, index);
    }
  };

  handleAgeRangeChange = (values: number | number[], percentage: number = 100) => {
    if (Array.isArray(values)) {
      const { updatePanelAgeRange } = this.props;
      const range = { min: values[0], max: values[1], percentage };
      updatePanelAgeRange(range, 0);
    }
  };

  handleStateChange = (values: number | number[], percentage: number = 100) => {
    if (Array.isArray(values)) {
      const range = { min: values[0], max: values[1], percentage };
      this.setState({
        sliderRange: range,
      });
    }
  };
  handleStateChangeTextField = (value: number, id: string, percentage: number = 100) => {
    const { panel, updatePanelAgeRange } = this.props;
    if (id === "min") {
      const { max } = panel.age.answer[0];
      const range = { min: value, max, percentage };
      this.setState({
        sliderRange: range,
      });
      updatePanelAgeRange(range, 0);
    }
    if (id === "max") {
      const { min } = panel.age.answer[0];
      const range = { min, max: value, percentage };
      this.setState({
        sliderRange: range,
      });
      updatePanelAgeRange(range, 0);
    }
  };

  addAgeOption = () => {
    const { addPanelAgeRange } = this.props;
    const range = { min: 16, max: 20, percentage: 50 };
    addPanelAgeRange(range);
  };

  render() {
    const { classes, t, question, isLoading } = this.props;
    return (
      <>
        {isLoading && <LoadingOverlay />}
        <Grid container={true} alignItems="center">
          {this.props.panel.age.answer.length < 2 && (
            <>
              <Grid item={true} xs={12} sm={9} style={{ paddingRight: 24 }}>
                <StepSlider
                  value={[
                    (this.props.panel.age.answer[0] !== undefined &&
                      this.props.panel.age.answer[0].min) ||
                      (this.state.sliderRange !== undefined && this.state.sliderRange.min) ||
                      16,
                    (this.props.panel.age.answer[0] !== undefined &&
                      this.props.panel.age.answer[0].max) ||
                      (this.state.sliderRange !== undefined && this.state.sliderRange.max) ||
                      100,
                  ]}
                  aria-labelledby="age-slider"
                  step={question.step}
                  min={16}
                  max={100}
                  valueLabelDisplay="on"
                  marks={[
                    {
                      value:
                        (this.props.panel.age.answer[0] !== undefined &&
                          this.props.panel.age.answer[0].min) ||
                        16,
                      label:
                        (this.props.panel.age.answer[0] !== undefined &&
                          this.props.panel.age.answer[0].min.toString()) ||
                        "16",
                    },
                    {
                      value:
                        (this.props.panel.age.answer[0] !== undefined &&
                          this.props.panel.age.answer[0].max) ||
                        100,
                      label:
                        (this.props.panel.age.answer[0] !== undefined &&
                          this.props.panel.age.answer[0].max.toString()) ||
                        "100",
                    },
                  ]}
                  //@ts-ignore
                  onChange={(event: ChangeEvent<{}>, value: number | number[]) =>
                    this.handleStateChange(value)
                  }
                  onChangeCommitted={(event, values) => this.handleAgeRangeChange(values)}
                />
              </Grid>
              <Grid item={true} xs={12} sm={3}>
                <Typography variant="subtitle1" classes={{ subtitle1: classes.greyText }}>
                  {t(translations.summary.panel.age.title)}
                </Typography>
                <Grid container={true} direction="row" alignItems="center">
                  <TextField
                    style={{ marginRight: 10 }}
                    className={classes.smallInput}
                    value={
                      (this.props.panel.age.answer[0] !== undefined &&
                        this.props.panel.age.answer[0].min) ||
                      (this.state.sliderRange !== undefined && this.state.sliderRange.min) ||
                      16
                    }
                    onChange={(event) =>
                      this.handleStateChangeTextField(Number(event.target.value), "min")
                    }
                    onBlur={(event) => this.handleAgeRangeChange(Number(event.target.value))}
                  />
                  -
                  <TextField
                    style={{ marginLeft: 10 }}
                    className={classes.smallInput}
                    value={
                      (this.props.panel.age.answer[0] !== undefined &&
                        this.props.panel.age.answer[0].max) ||
                      (this.state.sliderRange !== undefined && this.state.sliderRange.max) ||
                      100
                    }
                    onChange={(event) =>
                      this.handleStateChangeTextField(Number(event.target.value), "max")
                    }
                    onBlur={(event) => this.handleAgeRangeChange(Number(event.target.value))}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {this.props.panel.age.answer.length > 1 && (
            <>
              {this.props.panel.age.answer.map((age, index) => (
                <WithShareContainer
                  key={`age-select-${index}`}
                  share={age.percentage}
                  changeValue={"share"}
                  changeShareFunction={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleAgeInputChange(event, "share", index)
                  }
                  index={index}
                  deleteFunction={this.deleteAgeOption}
                  customElement={
                    <>
                      <TextField
                        value={age.min}
                        classes={{ root: classes.input }}
                        type="number"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleAgeInputChange(event, "min", index)
                        }
                        InputProps={{ inputProps: { min: 16, max: 100 } }}
                      />
                      -
                      <TextField
                        value={age.max}
                        classes={{ root: classes.input }}
                        type="number"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleAgeInputChange(event, "max", index)
                        }
                        InputProps={{ inputProps: { min: 16, max: 100 } }}
                      />
                    </>
                  }
                />
              ))}
            </>
          )}
        </Grid>
        {this.props.panel.age.answer.length >= 2 && (
          <div className={classes.ageRangeDeviationInfo}>
            {t(translations.summary.panel.age.generalDeviationInfo)}
          </div>
        )}
        {this.props.panel.age.answer.length <= 6 && (
          <Button
            contained={false}
            color="primary"
            size="small"
            onClick={this.addAgeOption}
            className={classes.moreButton}
          >
            {t(translations.action.addMore)}
          </Button>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ survey }: RootState) => ({
  panel: survey.panel,
  isLoading: survey.isLoading,
});

const mapDispatchToProps = {
  addPanelAgeRange,
  updatePanelAgeRange,
  removePanelAgeRange,
  setAttributeId: surveyActions.setAgeAttributeId,
  setLivePriceUpdate,
};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect<{}, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(AgeRange);
