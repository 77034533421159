import React, { Component } from "react";
import AttributeSelect, {
  AttributeSelectOwnProps,
} from "../../../../common/Layout/components/AttributeSelect";
import { IndividualAttributeOption } from "../../../../entities/IndividualAttribute";
import { MenuItem } from "@material-ui/core";
import { PositiveCheckBox, NegativeCheckBox } from "../../../../common/Layout/components/Checkbox";

interface State {}
interface Props extends AttributeSelectOwnProps {
  attributes: IndividualAttributeOption[];
  selectedAttributes: IndividualAttributeOption[];
  handleAttributeSelection: (attribute: IndividualAttributeOption[]) => void;
  name: string;
}

export default class IndividualAttributeSelect extends Component<Props, State> {
  handleSelection = (
    attribute: IndividualAttributeOption,
    which: string,
    event: any,
    value: boolean
  ) => {
    if (
      this.props.selectedAttributes.find(
        (attributeInQuestion) => attributeInQuestion.id === attribute.id
      )
    ) {
      if (!value) {
        // remove attribute option from selection
        this.props.handleAttributeSelection(
          this.props.selectedAttributes.filter((selectOption) => selectOption.id !== attribute.id)
        );
      } else {
        // alter attribute option in selection
        this.props.handleAttributeSelection(
          this.props.selectedAttributes.map((selectOption) =>
            selectOption.id === attribute.id
              ? new IndividualAttributeOption({
                  ...attribute,
                  val: which === "positive",
                })
              : selectOption
          )
        );
      }
    } else {
      // add attribute option to selection
      this.props.handleAttributeSelection([
        ...this.props.selectedAttributes,
        new IndividualAttributeOption({
          ...attribute,
          val: which === "positive",
        }),
      ]);
    }
  };

  render() {
    const { ref, attributes, selectedAttributes, handleSelection, ...props } = this.props;
    return (
      <AttributeSelect
        {...props}
        handleSelection={(values: string[]) => {
          return;
        }}
        customValue={selectedAttributes.map(
          (attribute) =>
            attribute.label + (attribute.val ? " (eingeschlossen)" : " (ausgeschlossen)")
        )}
      >
        {attributes.map((attribute) => {
          const selectedAttributeTwin = selectedAttributes.find(
            (selectedAttribute) => selectedAttribute.id === attribute.id
          );

          return (
            <MenuItem
              key={attribute.id + (selectedAttributeTwin || attribute).val}
              value={attribute.id}
            >
              <PositiveCheckBox
                name={`included-${attribute.id}`}
                checked={selectedAttributeTwin && selectedAttributeTwin.val === true}
                onChange={this.handleSelection.bind(this, attribute, "positive")}
              />
              <NegativeCheckBox
                name={`excluded-${attribute.id}`}
                checked={selectedAttributeTwin && selectedAttributeTwin.val === false}
                onChange={this.handleSelection.bind(this, attribute, "negative")}
              />
              <span style={{ marginLeft: 8 }}>{attribute.label}</span>
            </MenuItem>
          );
        })}
      </AttributeSelect>
    );
  }
}
