import * as React from "react";
import { Typography, Grid, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import Container from "../../../common/Layout/Container";
import { QuopinionTheme } from "../../../constants/Theme";
import { translations } from "../../../constants/lang/translation";
import { withRouter, RouteComponentProps } from "react-router";
import { RootState } from "../../../modules";
import { connect } from "react-redux";
import { actions } from "../../../modules/session";

interface OwnProps {
  route: string;
  orderNumber?: number; //ToDo later when real connection there, then mandatory
}
interface StateProps {
  registrationMail: { registrationMail: string };
}
interface DispatchProps {
  removeMail: () => void;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    infoText: {
      marginTop: theme.spacing(2),
    },
    problemText: {
      borderTop: "1px solid",
      borderColor: theme.palette.grey[100],
      paddingTop: theme.spacing(6),
      marginTop: theme.spacing(12),
    },
  });

type Props = OwnProps &
  WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps<{ token: string }> &
  StateProps &
  DispatchProps;

export const route: string = "/market-research/registration-confirmation";
class RegisterConfirmation extends React.Component<Props> {
  componentWillUnmount() {
    this.props.removeMail();
  }
  render() {
    const { registrationMail, orderNumber, classes, t } = this.props;

    return (
      <>
        <LogoBarResearcher />
        <Container>
          <Grid container={true} direction="row" alignItems="center">
            <Grid item={true} xs={12} md={8}>
              <Typography variant="h2" color="primary">
                {t(translations.confirm.researcherHeadline)}
              </Typography>
              <div className={classes.infoText}>
                <Typography variant="body1">
                  {t(translations.confirm.email1Researcher)} {registrationMail.registrationMail}{" "}
                  {t(translations.confirm.eMail2)}
                  <br />
                  <br />
                  {t(translations.confirm.check)}
                  <br />
                  {t(translations.confirm.spamCheck)}
                  <br />
                  <br /> {t(translations.confirm.postCheck)}
                  <br />
                  <br />
                  {t(translations.quopinion.your)} <br />
                  {t(translations.quopinion.team)}
                </Typography>
              </div>
              <div className={classes.problemText}>
                <Typography variant="body1" color="primary">
                  {t(translations.confirm.question1)}
                  {orderNumber} {t(translations.confirm.under)}{" "}
                  {t(translations.quopinion.phoneResearcherDirect)}
                  <br />
                  <br />
                  {t(translations.quopinion.openingHours)}{" "}
                  {t(translations.quopinion.researcher.openingHoursSuffix)}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  connect(({ session }: RootState) => ({ registrationMail: session.registrationMail }), {
    removeMail: actions.removeRegistrationMail,
  }),
  withRouter,
  withTranslation(),
  withStyles(styles)
)(RegisterConfirmation);
