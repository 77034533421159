import {Dispatch} from "redux";
import {onlineStatus, checkOfflineError} from "./error";
import {Screener} from "../entities/Screener";
import {createAsyncAction, getType, ActionType} from "typesafe-actions";
import Services from "../services/Services";
import {ThunkResult} from ".";
import {AnsweredAttribute, DailyScreenerList} from "../services/Accounts";
import {getAttributeQuestionsForLevel, generateScreenerListByCategory} from "./util";
import Attribute, {AttributeType} from "../entities/Attribute";
import {FlatCategory} from "../entities/Category";
import RequestedAttribute, {NewRequestedAttribute} from "../entities/RequestedAttribute";

const DELETE_ATTRIBUTE = "attribute/DELETE_ATTRIBUTE";
const UPDATE_ATTRIBUTES_LOCALLY = "attributes/UPDATE_ATTRIBUTES_LOCALLY";

export interface ScreenerCollection {
  category: Screener;
}

interface AttributeState {
  attributes: Attribute[];
  isLoadingAttributes: boolean;
  screenerCollection: Screener[];
  isLoading: boolean;
  screenerAnswers: AnsweredAttribute[];
  dailyScreenerAll?: Screener[]; // TODO: this property should be obligatory
  answeredDailyScreeners: AnsweredAttribute[];
  dailyScreenerAmount?: number; // TODO: this property should be obligatory
  dailyScreenerLeft?: Screener[]; // TODO: this property should be obligatory
  dailyScreenerLeftAmount?: number; // TODO: this property should be obligatory
  dailyScreenersSorted?: any; // TODO: this property should be obligatory
  flatCategories: FlatCategory[];
  requestedAttributes: RequestedAttribute[];
  isLoadingCategories: boolean;
}

const defaultState: AttributeState = {
  attributes: [],
  isLoadingAttributes: false,
  isLoading: false,
  screenerCollection: [],
  screenerAnswers: [],
  answeredDailyScreeners: [],
  flatCategories: [],
  requestedAttributes: [],
  isLoadingCategories: false,
};

const actions = {
  fetchAttribute: createAsyncAction(
    "attribute/FETCH_ATTRIBUTE_REQUEST",
    "attribute/FETCH_ATTRIBUTE_SUCCESS",
    "attribute/FETCH_ATTRIBUTE_FAILURE"
  )<void, Attribute, void>(),
  fetchAttributes: createAsyncAction(
    "attribute/FETCH_ATTRIBUTES_REQUEST",
    "attribute/FETCH_ATTRIBUTES_SUCCESS",
    "attribute/FETCH_ATTRIBUTES_FAILURE"
  )<void, Attribute[], void>(),
  fetchFlatCategories: createAsyncAction(
    "attribute/FETCH_FLAT_CATEGORIES_REQUEST",
    "attribute/FETCH_FLAT_CATEGORIES_SUCCESS",
    "attribute/FETCH_FLAT_CATEGORIES_FAILURE"
  )<void, FlatCategory[], void>(),
  updateScreenerAnswer: createAsyncAction(
    "attribute/UPDATE_ATTRIBUTE_ANSWER_REQUEST",
    "attribute/UPDATE_ATTRIBUTE_ANSWER_SUCCESS",
    "attribute/UPDATE_ATTRIBUTE_ANSWER_FAILURE"
  )<void, AnsweredAttribute, void>(),
  updateDailyScreenerAnswer: createAsyncAction(
    "attribute/UPDATE_DAILY_SCREENER_ATTRIBUTE_ANSWER_REQUEST",
    "attribute/UPDATE_DAILY_SCREENER_ATTRIBUTE_ANSWER_SUCCESS",
    "attribute/UPDATE_DAILY_SCREENER_ATTRIBUTE_ANSWER_FAILURE"
  )<void, AnsweredAttribute[], void>(),
  fetchScreenerCategories: createAsyncAction(
    "attribute/FETCH_ATTRIBUTE_CATEGORIES_REQUEST",
    "attribute/FETCH_ATTRIBUTE_CATEGORIES_SUCCESS",
    "attribute/FETCH_ATTRIBUTE_CATEGORIES_FAILURE"
  )<void, Screener[], void>(),
  fetchUserAttributes: createAsyncAction(
    "attribute/FETCH_USER_ATTRIBUTES_REQUEST",
    "attribute/FETCH_USER_ATTRIBUTES_SUCCESS",
    "attribute/FETCH_USER_ATTRIBUTES_FAILURE"
  )<void, AnsweredAttribute[], void>(),
  fetchDailyScreenerAttributes: createAsyncAction(
    "attribute/FETCH_DAILY_SCREENER_ATTRIBUTES_REQUEST",
    "attribute/FETCH_DAILY_SCREENER_ATTRIBUTES_SUCCESS",
    "attribute/FETCH_DAILY_SCREENER_ATTRIBUTES_FAILURE"
  )<void, DailyScreenerList, void>(),
  requestNewAttribute: createAsyncAction(
    "attribute/REQUEST_NEW_ATTRIBUTE_REQUEST",
    "attribute/REQUEST_NEW_ATTRIBUTE_SUCCESS",
    "attribute/REQUEST_NEW_ATTRIBUTE_FAILURE"
  )<void, RequestedAttribute, void>(),
  fetchNewExternalAttribute: createAsyncAction(
    "attribute/FETCH_NEW_EXTERNAL_ATTRIBUTE_REQUEST",
    "attribute/FETCH_NEW_EXTERNAL_ATTRIBUTE_SUCCESS",
    "attribute/FETCH_NEW_EXTERNAL_ATTRIBUTE_FAILURE"
  )<void, RequestedAttribute[], void>(),
  updateNewExternalAttribute: createAsyncAction(
    "attribute/UPDATE_NEW_EXTERNAL_ATTRIBUTE_REQUEST",
    "attribute/UPDATE_NEW_EXTERNAL_ATTRIBUTE_SUCCESS",
    "attribute/UPDATE_NEW_EXTERNAL_ATTRIBUTE_FAILURE"
  )<void, RequestedAttribute, void>(),
  deleteAttribute: (id: string) => ({
    type: DELETE_ATTRIBUTE,
    payload: id
  } as const),
  updateUserAttributesLocally: (newAttribute: AnsweredAttribute) => ({
    type: UPDATE_ATTRIBUTES_LOCALLY,
    payload: newAttribute
  })
};

export const deleteAttribute = (id: string) => {
  return {
    type: DELETE_ATTRIBUTE,
    id
  } as const;
};

export const updateAttributeLocally = (newAttribute: AnsweredAttribute) => {
  return {
    type: UPDATE_ATTRIBUTES_LOCALLY,
    newAttribute
  } as const;
};

export const updateUserAttributesLocally = (newAttribute: AnsweredAttribute) => {
  return (dispatch: Dispatch) => {
    //@ts-ignore
    dispatch(actions.updateUserAttributesLocally(newAttribute));
  };
};

export const requestNewAttribute = (newAttribute: NewRequestedAttribute) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.requestNewAttribute.request());

      const response = await Services.attributes.requestNewAttribute(newAttribute);

      dispatch(actions.requestNewAttribute.success(response));
      return Promise.resolve();
    } catch (error) {
      dispatch(actions.requestNewAttribute.failure());

      return Promise.reject();
    }
  };
};

export const fetchNewExternalAttributes = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchNewExternalAttribute.request());

      const response = await Services.attributes.fetchRequestedNewAttributes();

      dispatch(actions.fetchNewExternalAttribute.success(response));
      return Promise.resolve();
    } catch (error) {
      dispatch(actions.requestNewAttribute.failure());

      return Promise.reject();
    }
  };
};

export const updateNewExternalAttributes = (updatedAttribute: RequestedAttribute) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateNewExternalAttribute.request());

      const response = await Services.attributes.updateRequestedNewAttributes(updatedAttribute);

      dispatch(actions.updateNewExternalAttribute.success(response));
      return Promise.resolve();
    } catch (error) {
      dispatch(actions.updateNewExternalAttribute.failure());

      return Promise.reject();
    }
  };
};

export const deleteNewExternalAttributes = (updatedAttribute: RequestedAttribute) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateNewExternalAttribute.request());

      const response = await Services.attributes.updateRequestedNewAttributes(updatedAttribute);

      dispatch(actions.updateNewExternalAttribute.success(response));
      return Promise.resolve();
    } catch (error) {
      dispatch(actions.updateNewExternalAttribute.failure());

      return Promise.reject();
    }
  };
};

export const removeAttribute = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchUserAttributes.request());

      //ToDo implement deleteAttribut which may throw offline error when BE
      const result = await Services.accounts.fetchAttributesForUser();
      // remove all answers
      const newScreeners = result.map((attribute) =>
        attribute.attributeId === id
          ? {...attribute, answer: {...attribute.answer, selectedOptions: []}}
          : attribute
      );
      Services.accounts.updateAttributes(newScreeners);
      dispatch(actions.fetchUserAttributes.success(newScreeners));
      dispatch(onlineStatus());
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while requesting questionnaire.");
    }
  };
};

export const updateUserAttributes = (newAttribute: AnsweredAttribute) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateScreenerAnswer.request());

      await Services.accounts.updateAttributes([newAttribute]);
      dispatch(actions.updateScreenerAnswer.success(newAttribute));

      return Promise.resolve(newAttribute);
    } catch (error) {
      // catch network error

      return Promise.reject(error);
    }
  };
};

export const fetchAttributes = (filter: { type?: AttributeType } = {}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchAttributes.request());

      const attributes = await Services.attributes.fetchAllAttributes(filter);
      dispatch(actions.fetchAttributes.success(attributes));

      return Promise.resolve(attributes);
    } catch (error) {
      // catch network error

      return Promise.reject(error);
    }
  };
};
export const fetchAttribute = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchAttribute.request());

      const attribute = await Services.attributes.fetchAttribute(id);
      dispatch(actions.fetchAttribute.success(attribute));

      return Promise.resolve(attribute);
    } catch (error) {
      // catch network error

      return Promise.reject(error);
    }
  };
};

export const fetchFlatCategories = () => {
  return async (dispatch: Dispatch): Promise<FlatCategory[]> => {
    try {
      dispatch(actions.fetchFlatCategories.request());
      const flatAttributes = await Services.categories.fetchFlattenedCategories();
      dispatch(actions.fetchFlatCategories.success(flatAttributes));
      return Promise.resolve(flatAttributes);
    } catch (error) {
      dispatch(actions.fetchFlatCategories.failure());
      return Promise.reject(error);
    }
  };
};

export const saveAttribute = (attribute: Attribute) => {
  return async (dispatch: Dispatch): Promise<Attribute> => {
    try {
      dispatch(actions.fetchAttributes.request());

      const savedAttribute = await Services.attributes.saveAttribute(attribute);
      const attributes = await Services.attributes.fetchAllAttributes();
      dispatch(actions.fetchAttributes.success(attributes));

      return Promise.resolve(savedAttribute);
    } catch (error) {
      // catch network error
      console.error(error);
      dispatch(actions.updateScreenerAnswer.failure());

      return Promise.reject(error);
    }
  };
};

export const getUserAttributes = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchUserAttributes.request());
      const result = await Services.accounts.fetchAttributesForUser();
      dispatch(actions.fetchUserAttributes.success(result));
      return result;
    } catch (e) {
      return console.log("fetchUserAttributes failure", e);
    }
  };
};

export const getIndividualAttributes = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchScreenerCategories.request());
      dispatch(actions.fetchUserAttributes.request());

      // fetch attributes that are available for current user
      const result = await Services.accounts.fetchAvailableAttributesForUser();
      const newScreeners = getAttributeQuestionsForLevel(result);
      const answeredAttributes = await Services.accounts.fetchAttributesForUser();

      dispatch(actions.fetchScreenerCategories.success(newScreeners));
      dispatch(actions.fetchUserAttributes.success(answeredAttributes));

      return Promise.resolve();
    } catch (error) {
      console.log("getIndividualAttributes failure", error);
      return Promise.reject();
    }
  };
};

export const fetchDailyScreener = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchDailyScreenerAttributes.request());

      const result = await Services.accounts.fetchInternalAttributes();

      dispatch(actions.fetchDailyScreenerAttributes.success(result));
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  };
};

export const updateDailyScreener = (
  answeredAttributes: AnsweredAttribute[]
): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateDailyScreenerAnswer.request());

      await Services.accounts.updateAttributes(answeredAttributes);
      dispatch(actions.updateDailyScreenerAnswer.success(answeredAttributes));

      Promise.resolve(answeredAttributes);
    } catch (error) {
      // catch network error

      Promise.reject(error);
    }
  };
};

type Action = ActionType<typeof actions>;

export const reducer = (state: AttributeState = defaultState, action: Action): AttributeState => {
  switch (action.type) {
    case getType(actions.fetchAttribute.request):
      return {...state, isLoadingAttributes: true};
    case getType(actions.fetchAttribute.success):
      const newAttribute = !state.attributes.find(
        (attribute) => attribute.id === (action.payload as Attribute).id
      );

      // new attribute, or do we have to update an existing one
      const updatedAttributes = newAttribute
        ? [...state.attributes, action.payload]
        : state.attributes.map((attribute) =>
          attribute.id === (action.payload as Attribute).id ? action.payload : attribute
        );

      return {
        ...state,
        isLoadingAttributes: false,
        attributes: (updatedAttributes as Attribute[]),
      };
    case getType(actions.fetchAttribute.failure):
      return {...state, isLoadingAttributes: false};
    case getType(actions.fetchAttributes.request):
      return {...state, isLoadingAttributes: true};
    case getType(actions.fetchAttributes.success):
      return {...state, isLoadingAttributes: false, attributes: action.payload as Attribute[]};
    case getType(actions.fetchAttributes.failure):
      return {...state, isLoadingAttributes: false};

    case getType(actions.fetchFlatCategories.request):
      return {...state, isLoadingCategories: true};
    case getType(actions.fetchFlatCategories.success):
      return {...state, isLoadingCategories: false, flatCategories: action.payload as FlatCategory[]};
    case getType(actions.fetchFlatCategories.failure):
      return {...state, isLoadingCategories: false};

    case getType(actions.updateScreenerAnswer.request):
      return {...state, isLoading: true};
    case getType(actions.updateScreenerAnswer.success):
      let newAnswers;
      if (
        state.screenerAnswers.some((answer) => answer.attributeId === action.payload.attributeId)
      ) {
        newAnswers = state.screenerAnswers.map((answer) =>
          answer.attributeId === action.payload.attributeId ? action.payload : answer
        );
      } else {
        newAnswers = [...state.screenerAnswers, action.payload];
      }
      return {...state, screenerAnswers: newAnswers, isLoading: false};
    case getType(actions.updateScreenerAnswer.failure):
      return {...state, isLoading: false};
    case getType(actions.fetchScreenerCategories.request):
      return {...state, isLoading: true};
    case getType(actions.fetchScreenerCategories.success):
      return {...state, isLoading: false, screenerCollection: action.payload as Screener[]};
    case getType(actions.fetchUserAttributes.request):
      return {...state, isLoading: true};
    case getType(actions.fetchUserAttributes.success):
      return {...state, isLoading: false, screenerAnswers: (action.payload as AnsweredAttribute[])};
    case DELETE_ATTRIBUTE:
      return {
        ...state,
        screenerAnswers: state.screenerAnswers.filter(
          (answers) => answers.attributeId !== action.payload as string
        ),
      };
    case UPDATE_ATTRIBUTES_LOCALLY:
      let newAttributes;
      if (
        state.screenerAnswers.some((answer) => answer.attributeId === (action.payload as AnsweredAttribute).attributeId)
      ) {
        newAttributes = state.screenerAnswers.map((answer) =>
          answer.attributeId === (action.payload as AnsweredAttribute).attributeId ? action.payload as AnsweredAttribute : answer
        );
      } else {
        newAttributes = [...state.screenerAnswers, action.payload];
      }
      return {
        ...state,
        screenerAnswers: newAttributes as AnsweredAttribute[],
      };
    case getType(actions.fetchDailyScreenerAttributes.success):
      const answeredScreenerIds = (action.payload as DailyScreenerList).answeredScreenerAttributes.map(
        (answeredAttribute) => answeredAttribute.attributeId
      );

      const screenersLeft = (action.payload as DailyScreenerList).screenerAttributesToday.filter(
        (screener) => !answeredScreenerIds.includes(screener.id)
      );

      const dailyScreenersSorted = (action.payload as DailyScreenerList).screenerAttributesHistorically.reduce(
        generateScreenerListByCategory,
        {}
      );

      const combinedScreener = Object.entries(state.screenerCollection)
        .map(([key, value]) => {
          const dailyScreener = dailyScreenersSorted[key];
          const screener = {...value};

          if (dailyScreener) {
            screener.questions = screener.questions.concat(dailyScreener.questions);
          }

          return screener;
        })
        .reduce((result, current) => {
          result = {
            ...result,
            [current.id]: {
              ...current,
            },
          };

          return result;
        }, {});

      return {
        ...state,
        dailyScreenerAll: (action.payload as DailyScreenerList).screenerAttributesHistorically,
        dailyScreenersSorted,
        screenerCollection: combinedScreener as Screener[],
        dailyScreenerLeft: screenersLeft,
        dailyScreenerLeftAmount: screenersLeft.length,
        answeredDailyScreeners: (action.payload as DailyScreenerList).answeredScreenerAttributes,
      };
    case getType(actions.updateNewExternalAttribute.success):
      return {
        ...state,
        requestedAttributes: [
          ...state.requestedAttributes.filter(
            (attribute: any) => attribute.id !== (action.payload as RequestedAttribute).id
          ),
          (action.payload as RequestedAttribute),
        ],
      };
    case getType(actions.fetchNewExternalAttribute.request):
      return {...state, isLoading: true};
    case getType(actions.fetchNewExternalAttribute.success):
      return {...state, isLoading: false, requestedAttributes: action.payload as RequestedAttribute[]};
    case getType(actions.requestNewAttribute.request):
      return {...state, isLoading: true};
    case getType(actions.requestNewAttribute.success):
      return {...state, isLoading: false};
    default:
      return state;
  }
};

