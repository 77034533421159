import React, { PureComponent, ChangeEvent } from "react";
import {
  TextField,
  Typography,
  Grid,
  createStyles,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { translations } from "../../../../../constants/lang/translation";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";
import * as Yup from "yup";
import { QuestionTypeProps, QuestionProps } from "./Question";
import Question from "../../../../../entities/Question";
import { QuopinionTheme } from "../../../../../constants/Theme";
import SwitchComponent from "../../../../../common/Layout/components/Switch";
import QuestionPreview from "../../../../../common/Preview/QuestionPreview";

export const QuestionValidationRating = ({ t }: QuestionProps) => {
  return Yup.object().shape({
    text: Yup.string()
      .required(t(translations.questionnaire.validation.title))
      .max(240, t(translations.questionnaire.validation.titleMaxLength)),
    body: Yup.object().shape({
      labels: Yup.object().shape({
        left: Yup.string()
          .required(t(translations.questionnaire.validation.rating.left))
          .max(30, t(translations.questionnaire.validation.answers.length)),
        middle: Yup.string().max(30, t(translations.questionnaire.validation.answers.length)),
        right: Yup.string()
          .required(t(translations.questionnaire.validation.rating.right))
          .max(30, t(translations.questionnaire.validation.answers.length)),
      }),
      scale: Yup.object()
        .shape({
          min: Yup.number()
            .min(0, t(translations.questionnaire.slider.validation.valueTooSmall))
            .max(1000, t(translations.questionnaire.slider.validation.valueTooBig))
            .typeError(t(translations.questionnaire.slider.validation.onlyNumbers)),
          max: Yup.number()
            .min(0, t(translations.questionnaire.slider.validation.valueTooSmall))
            .max(1000, t(translations.questionnaire.slider.validation.valueTooBig))
            .moreThan(
              Yup.ref("min"),
              t(translations.questionnaire.slider.validation.rightBiggerThanLeft)
            )
            .typeError(t(translations.questionnaire.slider.validation.onlyNumbers)),
        })
        .typeError(t(translations.questionnaire.slider.validation.onlyNumbers)),
    }),
  });
};

const styles = (theme: QuopinionTheme) =>
  createStyles({
    label: {
      width: 70,
    },
    row: {
      marginBottom: theme.spacing(2),
      paddingRight: theme.spacing(26.25),
    },
    input: {
      flexGrow: 1,
      "& label": {
        top: "-8px",
        color: "#000",
        fontSize: "14px",
        lineHeight: 1.71,
      },
    },
    numberInput: {
      width: 100,
      marginRight: theme.spacing(15),
      "& input": {
        "&::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
      "& label": {
        top: "-8px",
        color: "#000",
        fontSize: "14px",
        lineHeight: 1.71,
      },
    },
  });

interface State {
  labels: {
    left: string;
    middle: string;
    right: string;
  };
  scale: {
    min: number;
    max: number;
  };
}

type StateLabels = "left" | "middle" | "right";
type StateScale = "min" | "max";

type Props = QuestionTypeProps & WithTranslation & WithStyles<typeof styles>;
class QuestionFormRatingCore extends PureComponent<Props, State> {
  state = {
    labels: {
      left: this.props.body.labels.left || "",
      middle: this.props.body.labels.middle || "",
      right: this.props.body.labels.right || "",
    },
    scale: {
      min: this.props.body.scale.min || 0,
      max: this.props.body.scale.max || 100,
      step: this.props.body.scale.step || 1,
    },
  };

  handleInputBlur = () => {
    this.props.handleChange({
      ...this.props.body,
      labels: { ...this.state.labels },
    });
  };

  handleInputChange = (event: ChangeEvent<HTMLInputElement>, id: StateLabels) => {
    let labels = { ...this.state.labels, [id]: event.target.value };
    let newState = { ...this.state, labels };
    this.setState(newState);
  };

  handleRangeInputBlur = () => {
    this.props.handleChange({
      ...this.props.body,
      scale: { ...this.state.scale },
    });
  };

  handleRangeInputChange = (event: ChangeEvent<HTMLInputElement>, id: StateScale) => {
    let scale = { ...this.state.scale, [id]: parseInt(event.target.value) };
    let newState = { ...this.state, scale };
    this.setState(newState);
  };

  handleNumericFeedback = () => {
    this.props.handleChange({
      ...this.props.body,
      showNumericFeedback: !this.props.body.showNumericFeedback,
    });
  };
  render() {
    const { body, t, classes } = this.props;
    return (
      <Grid container={true} direction="column">
        <Grid container={true} alignItems="center" style={{ marginBottom: 8 }}>
          <Typography classes={{ root: classes.label }}>
            {t(translations.questionnaire.slider.left)}
          </Typography>
          <TextField
            id="left"
            value={this.state.scale.min}
            placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              this.handleRangeInputChange(event, "min")
            }
            onBlur={this.handleRangeInputBlur}
            key={"left-area"}
            classes={{ root: classes.numberInput }}
            label={t(translations.questionnaire.slider.scala)}
            type="number"
          />
          <TextField
            id="left"
            value={this.state.labels.left}
            placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              this.handleInputChange(event, "left")
            }
            onBlur={this.handleInputBlur}
            key={"left"}
            classes={{ root: classes.input }}
            label={t(translations.questionnaire.slider.label)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid container={true} alignItems="center" style={{ marginBottom: 8 }}>
          <Typography classes={{ root: classes.label }}>
            {t(translations.questionnaire.slider.middle)}
          </Typography>

          <TextField
            id="middle"
            value={this.state.labels.middle}
            placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              this.handleInputChange(event, "middle")
            }
            onBlur={this.handleInputBlur}
            key={"middle"}
            classes={{ root: classes.input }}
            style={{ marginLeft: 160 }}
          />
        </Grid>
        <Grid container={true} alignItems="center" style={{ marginBottom: 8 }}>
          <Typography classes={{ root: classes.label }}>
            {t(translations.questionnaire.slider.right)}{" "}
          </Typography>
          <TextField
            id="left"
            value={this.state.scale.max}
            placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              this.handleRangeInputChange(event, "max")
            }
            onBlur={this.handleRangeInputBlur}
            key={"right-area"}
            classes={{ root: classes.numberInput }}
            type="number"
          />
          <TextField
            id="right"
            value={this.state.labels.right}
            placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              this.handleInputChange(event, "right")
            }
            onBlur={this.handleInputBlur}
            key={"right"}
            classes={{ root: classes.input }}
          />
        </Grid>
        <SwitchComponent
          title={t(translations.questionnaire.slider.showNumeric)}
          label={body.showNumericFeedback ? t(translations.switch.on) : t(translations.switch.off)}
          checked={body.showNumericFeedback}
          handleChange={this.handleNumericFeedback}
          fullWidth={true}
        />
      </Grid>
    );
  }
}

export const QuestionFormRating = compose<Props, QuestionTypeProps>(
  withTranslation(),
  withStyles(styles)
)(QuestionFormRatingCore);

export class QuestionPreviewRating extends PureComponent<Omit<Question, "toDataJson">> {
  render() {
    const { text: title, body, type, media } = this.props;
    return (
      <QuestionPreview
        title={title}
        body={body}
        type={type}
        media={media}
        questionNumber={1}
        totalQuestionNumber={1}
      />
    );
  }
}
