/**
 * Type RegionType
 * that represents the type of the region
 */
export const regionTypeValues = [
  "Kreis",
  "Bundesland",
  "Leitregion",
  "Bevölkerungsdichte",
  "Reisegebiet",
] as const;
export type RegionType = typeof regionTypeValues[number];

/**
 * Domain entity class Region
 * which represents a region that can be used for a panel selection
 */
export default class Region {
  readonly id: number;
  readonly name: string;
  readonly type: number;
  readonly typeLabel: RegionType;
  readonly valid: boolean;
  readonly country: string;

  constructor(obj: any = {}) {
    this.id = obj.id || 0;
    this.name = obj.regionName || "";
    this.type = (obj.regionType && obj.regionType.id) || 0;
    this.typeLabel = ((obj.regionType && obj.regionType.name) || 0) as RegionType;
    this.valid = obj.valid;
    this.country = obj.country;
  }
}
