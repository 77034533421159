import React from "react";
import { TextField } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import Question from "../../../../entities/Question";
import { translations } from "../../../../constants/lang/translation";
import ID from "../../../../entities/ID";
import { Answer, TextAnswer } from "../../../../entities/SurveyAnswers";
import { AnsweredAttribute } from "../../../../services/Accounts";

interface OwnProps {
  question: Question;
  currentAnswer?: TextAnswer | AnsweredAttribute;
  handleUserAnswer: (questionID: ID, answer?: Answer) => void;
}

type Props = OwnProps & WithTranslation;

const FreeText = withTranslation()(({ question, handleUserAnswer, t, currentAnswer }: Props) => {
  let value: string;
  if (currentAnswer) {
    if (currentAnswer instanceof AnsweredAttribute) {
      if (currentAnswer.answer.type === "text") {
        value = currentAnswer.answer.enteredText;
      } else {
        value = "";
      }
    } else {
      value = currentAnswer.enteredText;
    }
  } else {
    value = "";
  }
  return (
    <TextField
      placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
      fullWidth={true}
      multiline={true}
      rows={5}
      autoFocus={true}
      value={value}
      onChange={(event) =>
        handleUserAnswer(question.id, {
          questionID: question.id,
          type: "text",
          enteredText: event.target.value,
        } as TextAnswer)
      }
    />
  );
});

export default FreeText;
