import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { QuopinionTheme, blueGradient, green } from "../../../constants/Theme";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";
import Button from "../../../common/Layout/components/Button";
import Question, { QuestionCondition } from "../../../entities/Question";
import { ArrowBack, Check } from "../../../assets/icon";
import Questionnaire from "../../../entities/Questionnaire";
import ID from "../../../entities/ID";
import { translations } from "../../../constants/lang/translation";
import { EuroSymbol } from "@material-ui/icons";
import QuestionPreviewContainer from "./QuestionPreviewContainer";
import SurveyAnswers, { Answer, ChoiceAnswer } from "../../../entities/SurveyAnswers";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    backIcon: {
      position: "absolute",
      top: 31,
      left: 16,
      padding: 0,
      width: 40,
      height: 40,
      cursor: "pointer",
    },
    navigation: {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(-6),
    },
    wrapper: {
      height: "100%",
      msOverflowStyle: "none",
    },
    root: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      background: blueGradient,
      padding: theme.spacing(6),
    },
    checkBadge: {
      backgroundColor: green,
      position: "relative",
      width: 74,
      height: 74,
      borderRadius: "50%",
      marginBottom: theme.spacing(8),
    },
    check: {
      position: "absolute",
      top: "calc(50% - 18px)",
      left: "calc(50% - 18px)",
      width: 36,
      height: 36,
      fill: "#fff",
    },
    earnedMoney: {
      margin: `${theme.spacing(9)}px 0 ${theme.spacing(25)}px`,
      padding: theme.spacing(4),
      backgroundColor: theme.palette.grey[200],
      width: "50%",
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    header: {
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
      color: theme.palette.grey[400],
      marginBottom: theme.spacing(3),
    },
    icon: {
      marginRight: theme.spacing(1.5),
    },
  });

interface OwnProps {
  questionnaire: Questionnaire;
  handleClose: () => void;
  currentAnswers: SurveyAnswers;
  showDesktopPreview?: boolean;
}

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const QuestionnairePreview: React.FC<Props> = (props: Props) => {
  const { t, questionnaire, classes, handleClose, currentAnswers, showDesktopPreview } = props;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [skipToQuestionId, setSkipToQuestionId] = useState<ID | null | undefined>(undefined);
  const [history, setHistory] = useState<number[]>([0]);
  const [conditionsUnmetCount, setConditionsUnmetCount] = useState<number>(0);

  const getNextQuestion = () => {
    const nextQuestion = questionnaire.questions[currentQuestionIndex + 1 + conditionsUnmetCount];
    return nextQuestion;
  };

  const goToNextSide = () => {
    //finding next question via unmetCount when next question needs to be skipped as its condition is not met
    const nextQuestion = getNextQuestion();
    if (nextQuestion !== undefined) {
      goForward(nextQuestion);
    } else {
      goToCompletedPage();
    }
  };

  const continueNextQuestionSearch = () => {
    //linked to useEffect and there connected to goToNextSide
    setConditionsUnmetCount((prevState) => prevState + 1);
  };

  const checkForFullfilledConditions = (nextQuestion: Question) => {
    let convertedFullfilledConditions: any = [];

    const fullfilledConditions = nextQuestion.conditions.map((conditionItem: QuestionCondition) => {
      const answersCorrelatingByQuestions = currentAnswers.answers.find(
        (currentAnswer: Answer) => currentAnswer.questionID === conditionItem.questionId
      );
      const correlatingAnswers = conditionItem.answers.filter(
        (answerId: ID) =>
          (answersCorrelatingByQuestions as ChoiceAnswer) &&
          (answersCorrelatingByQuestions as ChoiceAnswer).selectedOptions.includes(answerId)
      );
      return correlatingAnswers;
    });

    fullfilledConditions &&
      fullfilledConditions.map(
        (arrItem) =>
          (convertedFullfilledConditions = [...convertedFullfilledConditions, ...arrItem])
      );
    return convertedFullfilledConditions;
  };

  const successfullyRouteToNextQuestion = (nextQuestion: Question) => {
    setConditionsUnmetCount(0);
    if (
      (nextQuestion &&
        questionnaire.questions.findIndex(
          (question: Question) => question.id === nextQuestion.id
        ) === questionnaire.questions.length) ||
      nextQuestion === undefined
    ) {
      goToCompletedPage();
    } else {
      const index = questionnaire.questions.findIndex(
        (question) => question.id === nextQuestion.id
      );
      setCurrentQuestionIndex(index);
      setHistory((history) => [...history, index]);
    }
  };

  const goToCompletedPage = () => {
    setConditionsUnmetCount(0);
    setCurrentQuestionIndex(questionnaire.questions.length);
    setHistory((history: number[]) => [...history, questionnaire.questions.length]);
  };

  const goForward = (nextQ?: Question) => {
    if (skipToQuestionId && skipToQuestionId !== questionnaire.questions[currentQuestionIndex].id) {
      if (skipToQuestionId === "end") {
        goToCompletedPage();
      } else {
        const index = questionnaire.questions.findIndex(
          (question) => question.id === skipToQuestionId
        );
        setCurrentQuestionIndex(index);
        setHistory((history) => [...history, index]);
      }
      setSkipToQuestionId(null);
    } else {
      const nextQuestion = nextQ ? nextQ : questionnaire.questions[currentQuestionIndex + 1];
      if (
        nextQuestion === undefined ||
        (nextQuestion &&
          questionnaire.questions.findIndex((question) => question.id === nextQuestion.id) ===
            questionnaire.questions.length)
      ) {
        goToCompletedPage();
      } else if (nextQuestion.conditions.length) {
        checkForFullfilledConditions(nextQuestion).length > 0
          ? successfullyRouteToNextQuestion(nextQuestion)
          : continueNextQuestionSearch();
      } else {
        successfullyRouteToNextQuestion(nextQuestion);
      }
    }
  };

  const goBack = () => {
    setCurrentQuestionIndex(history[history.length - 2]);
    let newHistory = [...history];
    newHistory.pop();
    setHistory(newHistory);
  };

  const closePreview = () => {
    handleClose();
  };

  useEffect(() => {
    if (conditionsUnmetCount > 0) {
      goToNextSide();
    }
  });

  return (
    <>
      <Grid container={true} justifyContent="space-between" className={classes.navigation}>
        {currentQuestionIndex > 0 && (
          <IconButton onClick={goBack} className={classes.backIcon}>
            <ArrowBack fill={"#fff"} width={24} height={24} />
          </IconButton>
        )}
      </Grid>
      {/* answerTypes: choice, rating, text */}
      <Grid container={true} direction="column" className={classes.wrapper} wrap="nowrap">
        {/*  questionnaire is finished, so we show the final screen */}
        {currentQuestionIndex === questionnaire.questions.length ? (
          <div className={classes.root}>
            <div className={classes.checkBadge}>
              <Check className={classes.check} />
            </div>
            <Typography variant="h2" color="textSecondary">
              {t(translations.questionnaire.endOfQuestionnaireHeadline)}
            </Typography>
            <div className={classes.earnedMoney}>
              <div className={classes.header}>
                <EuroSymbol className={classes.icon} />
                <Typography variant="caption">
                  {t(translations.infoSurveyItem.earnings.title)}
                </Typography>
              </div>
              <Typography variant="h2" id={"earnings"}>
                X,XX {t(translations.summary.price.currency)}
              </Typography>
            </div>
            <Button
              contained={true}
              color="secondary"
              size="big"
              fullWidth={true}
              onClick={closePreview}
            >
              {t(translations.action.toDashboard)}
            </Button>
          </div>
        ) : (
          questionnaire.questions
            .filter(
              (question: Question) =>
                question.id === questionnaire.questions[currentQuestionIndex].id
            )
            .map((question: Question) => (
              <QuestionPreviewContainer
                key={question.id}
                question={question}
                onNextClick={goForward}
                currentQuestionnaireStep={currentQuestionIndex + 1}
                totalQuestionnaireSteps={questionnaire.questions.length}
                setSkipQuestionId={setSkipToQuestionId}
                isPreview={true}
                showDesktopPreview={showDesktopPreview}
              />
            ))
        )}
      </Grid>
    </>
  );
};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(QuestionnairePreview);
