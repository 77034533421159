import { httpClient } from "../services/httpClient";
import { Dispatch } from "redux";
import Questionnaire from "../entities/Questionnaire";
import Question from "../entities/Question";
import Panel, {
  Children,
  Education,
  Family,
  GenderShare,
  MinMaxShareNumber,
  Household,
  Income,
} from "../entities/Panel";
import {v4 as uuidv4} from "uuid";
import Survey, { SurveyAdminInformation} from "../entities/Survey";
import Media, { MediaReference } from "../entities/Media";
import Services from "../services/Services";
import { translations } from "../constants/lang/translation";
import i18next from "i18next";
import Region from "../entities/Region";
import IndividualAttribute from "../entities/IndividualAttribute";
import ID from "../entities/ID";
import { Complexity } from "../entities/Survey";
import { RootState, ThunkResult } from ".";
import { createAsyncAction, ActionType, getType } from "typesafe-actions";
import { SurveyResult } from "../entities/SurveyResult";
import { setErrorElementOfResponses } from "./surveyErrors";
import Attribute from "../entities/Attribute";
import { getRegions } from "./definePanel";
import { regionAttributeIds } from "./util";
import AnswerOption from "../entities/AnswerOption";
import MatrixSubQuestion from "../entities/MatrixSubQuestion";

const SET_PANEL_HOUSEHOLD_SIZE = "survey/SET_PANEL_HOUSEHOLD_SIZE";
const SET_PANEL_AGE_RANGE = "survey/SET_PANEL_AGE_RANGE";
const ADD_QUESTION_TO_QUESTIONNAIRE = "survey/ADD_QUESTION_TO_QUESTIONNAIRE";
const EDIT_QUESTION = "survey/EDIT_QUESTION";
const COPY_QUESTION = "survey/COPY_QUESTION";
const SET_PANEL_GENDER = "survey/SET_PANEL_GENDER";
const DELETE_QUESTION_FROM_QUESTIONNAIRE = "survey/DELETE_QUESTION_FROM_QUESTIONNAIRE";
const DELETE_CONDITION_FROM_QUESTION = "survey/DELETE_CONDITION_FROM_QUESTION";
const MOVE_QUESTION_UP = "survey/MOVE_QUESTION_UP";
const MOVE_QUESTION_DOWN = "survey/MOVE_QUESTION_DOWN";
const SET_SURVEY_TITLE = "survey/SET_SURVEY_TITLE";
const SET_PANEL_SIZE = "survey/SET_PANEL_SIZE";
const SET_PANEL_GENDER_ALL = "survey/SET_PANEL_GENDER_ALL";
const SET_PANEL_INCOME_RANGE = "survey/SET_PANEL_INCOME_RANGE";
const SET_PANEL_REGION = "survey/SET_PANEL_REGION";
const SET_PANEL_EDUCATION = "survey/SET_PANEL_EDUCATION";
const SET_PANEL_FAMILY_STATUS = "survey/SET_PANEL_FAMILY_STATUS";
const SET_PANEL_CHILDREN = "survey/SET_PANEL_CHILDREN";
const SET_LIVE_PRICE_UPDATE = "survey/SET_LIVE_PRICE_UPDATE";
const DELETE_PANEL_GENDER_ALL = "survey/DELETE_PANEL_GENDER_ALL";
const SET_INDIVIDUAL_ATTRIBUTES = "survey/SET_INDIVIDUAL_ATTRIBUTES";
const RESET_QUESTIONNAIRE = "survey/RESET_QUESTIONNAIRE";
const UPDATE_PANEL_AGE_RANGE = "survey/UPDATE_PANEL_AGE_RANGE";
const ADD_PANEL_AGE_RANGE = "survey/ADD_PANEL_AGE_RANGE";
const REMOVE_PANEL_AGE_RANGE = "survey/REMOVE_PANEL_AGE_RANGE";
const GET_PANEL_CONFIGURATION = "survey/GET_PANEL_CONFIGURATION";
const SET_PANEL_CONFIGURATION = "survey/SET_PANEL_CONFIGURATION";
const SET_ASSUMED_QUESTIONNAIRE_COMPLEXITY = "survey/SET_ASSUMED_QUESTIONNAIRE_COMPLEXITY";
const SET_AGE_ATTRIBUTE_ID = "survey/SET_AGE_ATTRIBUTE_ID";
const SET_GENDER_ATTRIBUTE_ID = "survey/SET_GENDER_ATTRIBUTE_ID";
const SET_INCOME_ATTRIBUTE_ID = "survey/SET_INCOME_ATTRIBUTE_ID";
const SET_CHILDREN_ATTRIBUTE_ID = "survey/SET_CHILDREN_ATTRIBUTE_ID";
const SET_EDUCATION_ATTRIBUTE_ID = "survey/SET_EDUCATION_ATTRIBUTE_ID";
const SET_STATE_ATTRIBUTE_ID = "survey/SET_STATE_ATTRIBUTE_ID";
const SET_COUNTY_ATTRIBUTE_ID = "survey/SET_COUNTY_ATTRIBUTE_ID";
const SET_LEITREGION_ATTRIBUTE_ID = "survey/SET_LEITREGION_ATTRIBUTE_ID";
const SET_CITY_ATTRIBUTE_ID = "survey/SET_CITY_ATTRIBUTE_ID";
const SET_POPULATION_DENSITY_ATTRIBUTE_ID = "survey/SET_POPULATION_DENSITY_ATTRIBUTE_ID";
const SET_TOURIST_AREA_ATTRIBUTE_ID = "survey/SET_TOURIST_AREA_ATTRIBUTE_ID";
const SET_HOUSEHOLD_SIZE_ATTRIBUTE_ID = "survey/SET_HOUSEHOLD_SIZE_ATTRIBUTE_ID";
const SET_HOUSEHOLD_SIZE = "survey/SET_HOUSEHOLD_SIZE";
const SET_FAMILY_STATUS_ATTRIBUTE_ID = "survey/SET_FAMILY_STATUS_ATTRIBUTE_ID";
const EDIT_TITLE = "survey/EDIT_TITLE";
const REMOVE_PANEL_GENDER = "survey/REMOVE_PANEL_GENDER";
const SET_AUTO_PRICING_ON = "survey/SET_AUTO_PRICING_ON";
const SET_AUTO_PRICING_OFF = "survey/SET_AUTO_PRICING_OFF";

interface SurveyState {
  id: string;
  title: string;
  token?: string;
  questionnaire: Questionnaire;
  panel: Panel;
  transientSurvey: Survey;
  isLoading: boolean;
  isSubmitting: boolean;
  isUpdating: boolean;
  panelConfiguration: any[];
  answerDictionary: {};
  researcherSurveys: Survey[];
  assumedQuestionnaireComplexity: string;
  startDate: Date;
  endDate: Date;
  surveyEndedAtDate: Date; // may be before endDate when closed earlier by reached panel size
  duration: number;
  surveyResults: SurveyResult;
  resultFilter: [];
  additionalInformation?: SurveyAdminInformation;
  isPublishing: boolean;
  autoPricing: boolean;
}

const defaultState: SurveyState = {
  id: "",
  title: "",
  questionnaire: new Questionnaire(),
  panel: new Panel(),
  transientSurvey: new Survey(),
  isLoading: false,
  isSubmitting: false,
  isUpdating: false,
  panelConfiguration: [],
  answerDictionary: {},
  researcherSurveys: [],
  isPublishing: false,
  autoPricing: true,

  // TODO: refactor following state fields
  assumedQuestionnaireComplexity: Complexity.MEDIUM, // -> survey meta data
  startDate: new Date(), // -> survey meta data
  endDate: new Date(), // -> survey meta data
  surveyEndedAtDate: new Date(),
  duration: 15, // -> survey meta data
  surveyResults: new SurveyResult({
    panelSize: 0,
    numberOfOpenParticipations: 0,
    numberOfAcceptedParticipations: 0,
    numberOfCompletedParticipations: 0,
    numberOfDeclinedParticipations: 0,
    evaluatedQuestions: [],
  }),
  resultFilter: [],
};

interface PanelCriteria {
  questionId: ID;
  type: string;
  answer: [];
}

export const actions = {
  createTransientSurvey: createAsyncAction(
    "survey/CREATE_TRANSIENT_SURVEY_REQUEST",
    "survey/CREATE_TRANSIENT_SURVEY_SUCCESS",
    "survey/CREATE_TRANSIENT_SURVEY_FAILURE"
  )<{ title: string }, Survey, void>(),
  updateTransientSurvey: createAsyncAction(
    "survey/UPDATE_TRANSIENT_SURVEY_REQUEST",
    "survey/UPDATE_TRANSIENT_SURVEY_SUCCESS",
    "survey/UPDATE_TRANSIENT_SURVEY_FAILURE"
  )<Survey, Survey, void>(),
  publishTransientSurvey: createAsyncAction(
    "survey/PUBLISH_TRANSIENT_SURVEY_REQUEST",
    "survey/PUBLISH_TRANSIENT_SURVEY_SUCCESS",
    "survey/PUBLISH_TRANSIENT_SURVEY_FAILURE"
  )<void, void, void>(),
  requestReleaseOfSurvey: createAsyncAction(
    "survey/REQUEST_RESLEASE_OF_TRANSIENT_SURVEY_REQUEST",
    "survey/REQUEST_RESLEASE_OF_TRANSIENT_SURVEY_SUCCESS",
    "survey/REQUEST_RESLEASE_OF_TRANSIENT_SURVEY_FAILURE"
  )<void, void, void>(),
  acceptReleaseOfSurvey: createAsyncAction(
    "survey/ACCEPT_RELEASE_OF_SURVEY_REQUEST",
    "survey/ACCEPT_RELEASE_OF_SURVEY_SUCCESS",
    "survey/ACCEPT_RELEASE_OF_SURVEY_FAILURE"
  )<void, void, void>(),
  denyReleaseOfSurvey: createAsyncAction(
    "survey/DENY_RELEASE_OF_SURVEY_REQUEST",
    "survey/DENY_RELEASE_OF_SURVEY_SUCCESS",
    "survey/DENY_RELEASE_OF_SURVEY_FAILURE"
  )<string, void, void>(),
  fetchTransientSurvey: createAsyncAction(
    "survey/FETCH_TRANSIENT_SURVEY_REQUEST",
    "survey/FETCH_TRANSIENT_SURVEY_SUCCESS",
    "survey/FETCH_TRANSIENT_SURVEY_FAILURE"
  )<void, { survey: Survey; attributes: Attribute[] }, void>(),
  fetchOrganizationTransientSurveys: createAsyncAction(
    "survey/FETCH_ORGANIZATION_SURVEY_REQUEST",
    "survey/FETCH_ORGANIZATION_SURVEY_SUCCESS",
    "survey/FETCH_ORGANIZATION_SURVEY_FAILURE"
  )<void, Survey[], void>(),
  fetchSurveyResults: createAsyncAction(
    "survey/FETCH_SURVEY_RESULTS_REQUEST",
    "survey/FETCH_SURVEY_RESULTS_SUCCESS",
    "survey/FETCH_SURVEY_RESULTS_FAILURE"
  )<void, SurveyResult, void>(),
  filterSurveyResults: createAsyncAction(
    "survey/FILTER_SURVEY_RESULTS_REQUEST",
    "survey/FILTER_SURVEY_RESULTS_SUCCESS",
    "survey/FILTER_SURVEY_RESULTS_FAILURE"
  )<void, SurveyResult, void>(),
  fetchSurveyInformation: createAsyncAction(
    "survey/FETCH_SURVEY_INFORMATION_REQUEST",
    "survey/FETCH_SURVEY_INFORMATION_SUCCESS",
    "survey/FETCH_SURVEY_INFORMATION_FAILURE"
  )<void, SurveyAdminInformation, void>(),
  resetSurveyStore: () =>
    ({
      type: "survey/RESET_SURVEY_STORE",
    } as const),
  setPanelSize: (amount: number) =>
    ({
      type: SET_PANEL_SIZE,
      payload: amount,
    } as const),
  setPanelGender: (panelCriteria: PanelCriteria) =>
    ({
      type: SET_PANEL_GENDER,
      payload: panelCriteria,
    } as const),
  setPanelGenderAll: (panelCriteria?: PanelCriteria) =>
    ({
      type: SET_PANEL_GENDER_ALL,
      payload: panelCriteria,
    } as const),
  deletePanelGenderAll: (panelCriteria?: PanelCriteria) =>
    ({
      type: DELETE_PANEL_GENDER_ALL,
      payload: panelCriteria,
    } as const),
  setPanelAgeRange: (panelCriteria: PanelCriteria) =>
    ({
      type: SET_PANEL_AGE_RANGE,
      payload: panelCriteria,
    } as const),
  setPanelIncomeRange: (panelCriteria: PanelCriteria) =>
    ({
      type: SET_PANEL_INCOME_RANGE,
      payload: panelCriteria,
    } as const),
  setPanelHousehold: (panelCriteria: PanelCriteria) =>
    ({
      type: SET_PANEL_HOUSEHOLD_SIZE,
      payload: panelCriteria,
    } as const),
  setPanelChildren: (panelCriteria: PanelCriteria) =>
    ({
      type: SET_PANEL_CHILDREN,
      payload: panelCriteria,
    } as const),
  setPanelFamilyStatus: (panelCriteria: PanelCriteria) =>
    ({
      type: SET_PANEL_FAMILY_STATUS,
      payload: panelCriteria,
    } as const),
  setAgeAttributeId: (id: string) => ({ type: SET_AGE_ATTRIBUTE_ID, payload: id } as const),
  setGenderAttributeId: (id: string) =>
    ({
      type: SET_GENDER_ATTRIBUTE_ID,
      payload: id,
    } as const),
  setIncomeAttributeId: (id: string) => ({
    type: SET_INCOME_ATTRIBUTE_ID,
    payload: id,
  }),
  setChildrenAttributeId: (id: string) =>
    ({
      type: SET_CHILDREN_ATTRIBUTE_ID,
      payload: id,
    } as const),
  setHouseholdSizeAttributeId: (id: string) => ({
    type: SET_HOUSEHOLD_SIZE_ATTRIBUTE_ID,
    payload: id,
  }),
  setEducationAttributeId: (id: string) => ({
    type: SET_EDUCATION_ATTRIBUTE_ID,
    payload: id,
  }),
  setFamilyStatusAttributeId: (id: string) => ({
    type: SET_FAMILY_STATUS_ATTRIBUTE_ID,
    payload: id,
  }),
  // regions
  setCountyAttributeId: (id: string) => ({
    type: SET_COUNTY_ATTRIBUTE_ID,
    payload: id,
  }),
  setStateAttributeId: (id: string) => ({
    type: SET_STATE_ATTRIBUTE_ID,
    payload: id,
  }),
  setLeitregionAttributeId: (id: string) => ({
    type: SET_LEITREGION_ATTRIBUTE_ID,
    payload: id,
  }),
  setCityAttributeId: (id: string) => ({
    type: SET_CITY_ATTRIBUTE_ID,
    payload: id,
  }),
  setPopulationDensityAttributeId: (id: string) => ({
    type: SET_POPULATION_DENSITY_ATTRIBUTE_ID,
    payload: id,
  }),
  setTouristAreaAttributeId: (id: string) => ({
    type: SET_TOURIST_AREA_ATTRIBUTE_ID,
    payload: id,
  }),

  //
  setPanelConfiguration: (panelConfiguration: any) =>
    ({
      type: SET_PANEL_CONFIGURATION,
      payload: panelConfiguration,
    } as const),

  setautoPricing: () =>
    ({
      type: SET_AUTO_PRICING_ON,
    } as const),
  setAutoPricingOff: () =>
    ({
      type: SET_AUTO_PRICING_OFF,
    } as const),
};

/*Panel*/

export const setHouseHoldSize = (household: Household[]) => {
  return {
    type: SET_HOUSEHOLD_SIZE,
    household,
  } as const;
};

export const setPanelGenderAll = (selectedGender: any) => {
  return {
    type: SET_PANEL_GENDER_ALL,
    selectedGender,
  };
};

export const getPanelConfiguration = () => {
  return {
    type: GET_PANEL_CONFIGURATION,
  };
};

export const deletePanelGenderAll = () => {
  return {
    type: DELETE_PANEL_GENDER_ALL,
  };
};

export const setPanelGender = (gender: GenderShare) => {
  return {
    type: SET_PANEL_GENDER,
    gender,
  };
};

// TODO: @Adam refactor me -> uiState
export const setLivePriceUpdate = (isUpdating: boolean) => ({
  type: SET_LIVE_PRICE_UPDATE,
  isUpdating,
});

export const addPanelAgeRange = (ageRange: MinMaxShareNumber) => {
  return {
    type: ADD_PANEL_AGE_RANGE,
    ageRange,
  };
};

export const updatePanelAgeRange = (ageRange: MinMaxShareNumber, index: number) =>
  ({
    type: UPDATE_PANEL_AGE_RANGE,
    ageRange,
    index,
  } as const);

export const removePanelAgeRange = (index: number) =>
  ({
    type: REMOVE_PANEL_AGE_RANGE,
    index,
  } as const);

export const setPanelIncome = (income: Income[]) =>
  ({
    type: SET_PANEL_INCOME_RANGE,
    income,
  } as const);

export const setPanelRegion = (attributeRegion: Region[]) =>
  ({
    type: SET_PANEL_REGION,
    attributeRegion,
  } as const);

export const setPanelEducation = (attributeEducation: Education[]) =>
  ({
    type: SET_PANEL_EDUCATION,
    attributeEducation,
  } as const);

export const setPanelFamilyStatus = (attributeFam: Family[]) =>
  ({
    type: SET_PANEL_FAMILY_STATUS,
    attributeFam,
  } as const);

export const setPanelChildren = (attributeChildren: Children[]) =>
  ({
    type: SET_PANEL_CHILDREN,
    attributeChildren,
  } as const);

export const setIndividualAttributes = (attributeIndividual: IndividualAttribute[]) =>
  ({
    type: SET_INDIVIDUAL_ATTRIBUTES,
    attributeIndividual,
  } as const);

export const setAssumedQuestionnaireComplexity = (complexity: Complexity) => {
  return {
    type: SET_ASSUMED_QUESTIONNAIRE_COMPLEXITY,
    complexity,
  };
};

/*Questionnaire*/
export const addQuestionToQuestionnaire = () => {
  return {
    type: ADD_QUESTION_TO_QUESTIONNAIRE,
  };
};

export const editQuestion = (question: Question) => {
  return {
    type: EDIT_QUESTION,
    payload: question,
  };
};

export const setSurveyTitle = (title: string) => {
  return {
    type: SET_SURVEY_TITLE,
    title,
  };
};

export const copyQuestion = (question: Question) => {
  return {
    type: COPY_QUESTION,
    payload: question,
  };
};

export const deleteQuestion = (question: Question) => {
  return {
    type: DELETE_QUESTION_FROM_QUESTIONNAIRE,
    payload: question,
  };
};

export const deleteCondition = (question: Question) => {
  return {
    type: DELETE_CONDITION_FROM_QUESTION,
    payload: question,
  };
};

export const resetQuestionnaire = () => {
  return {
    type: RESET_QUESTIONNAIRE,
  };
};

export const removePanelGender = (gender: GenderShare) => {
  return {
    type: REMOVE_PANEL_GENDER,
    gender,
  };
};

export const setautoPricing = () => {
  return {
    type: SET_AUTO_PRICING_ON,
  };
};

export const setAutoPricingOff = () => {
  return {
    type: SET_AUTO_PRICING_OFF,
  };
};

export const createTransientSurvey = (title: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.createTransientSurvey.request({ title }));

      const result = await Services.survey.createTransientSurvey({ title });
      const newSurvey = new Survey(result.survey);
      dispatch(actions.createTransientSurvey.success(newSurvey));
      setErrorElementOfResponses(result.meta.errors, dispatch, newSurvey.participantPoolSize);
      return Promise.resolve();
    } catch (error) {
      // TODO: add proper error handling
      return Promise.reject();
    }
  };
};

export const updateTransientSurvey = (updatedTransientSurvey: Survey) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { id } = getState().survey;
    if (id) {
      try {
        dispatch(actions.updateTransientSurvey.request(updatedTransientSurvey));

        const result = await Services.survey.updateTransientSurvey(updatedTransientSurvey);
        //here sometimes only due to different error states, that rerender triggered
        //idea: seperate update via put and errors
        const newSurvey = new Survey({ ...result.survey, meta: result.meta });
        dispatch(actions.updateTransientSurvey.success(newSurvey));
        setErrorElementOfResponses(result.meta.errors, dispatch, newSurvey.participantPoolSize);
      } catch (error) {
        // TODO: add proper error handling
        throw error;
      }
    }
  };
};

export const publishTransientSurvey = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { id } = getState().survey;

      dispatch(actions.publishTransientSurvey.request());
      await Services.survey.publishSurvey(id);
      dispatch(actions.publishTransientSurvey.success());
    } catch (error) {
      // TODO: add proper error handling
      throw error;
    }
  };
};

export const fetchOrganizationTransientSurveys = (organizationId: ID) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(actions.fetchOrganizationTransientSurveys.request());

      const result = await Services.survey.fetchSurveysByOrganization(organizationId);

      dispatch(actions.fetchOrganizationTransientSurveys.success(result));
    } catch (error) {
      // TODO: add proper error handling
      throw error;
    }
  };
};

export const fetchTransientSurvey = (id: ID): ThunkResult<void> => {
  return async (dispatch, getState: () => RootState) => {
    const currentUser = getState().user.current;
    try {
      dispatch(actions.fetchTransientSurvey.request());
      const result = await Services.survey.getSurvey(id);
      const attributes = await Services.attributes.fetchAllAttributes({ type: "ALL" });
      if (currentUser.isAdmin()) {
        dispatch(fetchAdditionalSurveyInformation(id));
      }
      const panelCriteria = result.panel.criteria;
      const panelCriteriaRegionItems:
        | { type: string; attributeId: string; percentage: number; value: number[] }[]
        | [

          ] = panelCriteria.filter(
        (panelCriteriaRegionItem: {
          type: string;
          attributeId: string;
          percentage: number;
          value: number[];
        }) =>
          regionAttributeIds.find((regionId) => regionId === panelCriteriaRegionItem.attributeId)
      );

      dispatch(actions.fetchTransientSurvey.success({ survey: result, attributes }));
      if (panelCriteriaRegionItems.length > 0) {
        const regionPool = await getRegions();
        //currently only one region type can be selected at the same time
        const regionValues: number[] = panelCriteriaRegionItems[0].value;
        const convertedRegions = regionValues.map((regionValueItem: number) => {
          const regionObj: Region | undefined = regionPool.find(
            (regionPoolItem) => regionPoolItem.id === regionValueItem
          );
          const defaultRegion = new Region();
          return regionObj ? regionObj : defaultRegion;
        });
        dispatch(setPanelRegion(convertedRegions));
      }
      setErrorElementOfResponses(result.meta.errors, dispatch, result.participantPoolSize);
      return Promise.resolve();
    } catch (error) {
      // TODO: add proper error handling
      dispatch(actions.fetchTransientSurvey.failure());
      throw error;
    }
  };
};

export const fetchAdditionalSurveyInformation = (id: ID) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSurveyInformation.request());
      const result: SurveyAdminInformation = await Services.survey.getSurveyInformation(id);
      dispatch(actions.fetchSurveyInformation.success(result));
    } catch (error) {
      throw error;
    }
  };
};

export const fetchTransientSurveyWithSurveyToken = (id: ID, surveyToken: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchTransientSurvey.request());

      const result = await Services.survey.getSurveyWithToken(id, surveyToken);
      const attributes = await Services.attributes.fetchAllAttributes({ type: "ALL" });
      dispatch(actions.fetchTransientSurvey.success({ survey: result, attributes }));
      setErrorElementOfResponses(result.meta.errors, dispatch, result.participantPoolSize);
    } catch (error) {
      // TODO: add proper error handling
      dispatch(actions.fetchTransientSurvey.failure());
      throw error;
    }
  };
};

export const setAttributeIds = (panelConfiguration: any, dispatch: Dispatch) =>
  panelConfiguration.forEach((item: any) => {
    if (
      item.name === "Geschlecht" &&
      (defaultState.panel.gender.attributeId === null ||
        defaultState.panel.gender.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setGenderAttributeId(item.id));
    }
    if (
      item.name === "Alter" &&
      (defaultState.panel.age.attributeId === null ||
        defaultState.panel.age.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setAgeAttributeId(item.id));
    }
    if (
      item.name === "Haushaltsgröße" &&
      (defaultState.panel.household.attributeId === null ||
        defaultState.panel.household.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setHouseholdSizeAttributeId(item.id));
    }
    if (
      item.name === "Haushaltseinkommen" &&
      (defaultState.panel.income.attributeId === null ||
        defaultState.panel.income.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setIncomeAttributeId(item.id));
    }
    if (
      item.name === "Bundesland" &&
      (defaultState.panel.state.attributeId === null ||
        defaultState.panel.state.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setStateAttributeId(item.id));
    }
    if (
      item.name === "Kreis" &&
      (defaultState.panel.county.attributeId === null ||
        defaultState.panel.county.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setCountyAttributeId(item.id));
    }
    if (
      item.name === "Reisegebiet" &&
      (defaultState.panel.touristArea.attributeId === null ||
        defaultState.panel.touristArea.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setTouristAreaAttributeId(item.id));
    }
    if (
      item.name === "Bevölkerungsdichte" &&
      (defaultState.panel.populationDensity.attributeId === null ||
        defaultState.panel.populationDensity.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setPopulationDensityAttributeId(item.id));
    }
    if (
      item.name === "Leitregion" &&
      (defaultState.panel.leitregion.attributeId === null ||
        defaultState.panel.leitregion.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setLeitregionAttributeId(item.id));
    }
    if (
      item.name === "Bildungsstatus" &&
      (defaultState.panel.education.attributeId === null ||
        defaultState.panel.education.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setEducationAttributeId(item.id));
    }
    if (
      item.name === "Familienstand" &&
      (defaultState.panel.family.attributeId === null ||
        defaultState.panel.family.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setFamilyStatusAttributeId(item.id));
    }
    if (
      item.name === "Kinder" &&
      (defaultState.panel.children.attributeId === null ||
        defaultState.panel.children.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setChildrenAttributeId(item.id));
    }
    if (
      item.name === "Ort" &&
      (defaultState.panel.city.attributeId === null ||
        defaultState.panel.city.attributeId.toString().trim().length === 0)
    ) {
      dispatch(actions.setCityAttributeId(item.id));
    }
  });

export const fetchPanelConfiguration = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getPanelConfiguration());
      const result = await Services.questionnaire.getPanelConfiguration();
      dispatch(actions.setPanelConfiguration(result));
      Promise.resolve();
    } catch (error) {
      Promise.reject();
    }
  };
};

export const fetchPanelConfigurationSettingIds = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getPanelConfiguration());
      const result = await Services.questionnaire.getPanelConfiguration();
      dispatch(actions.setPanelConfiguration(result));
      setAttributeIds(result, dispatch);
      Promise.resolve();
    } catch (error) {
      Promise.reject();
    }
  };
};

/**
 * this is no action thunk - this is just an upload helper
 */
export const uploadMedia = async (file: File, reference: MediaReference): Promise<Media> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    if (reference.questionId) {
      formData.append("questionId", reference.questionId);
    }
    if (reference.answerId) {
      formData.append("answerId", reference.answerId);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const result = await httpClient().post("/media/", formData, config);
    return new Media(result.data);
  } catch (error) {
    console.log({ ...error });
    switch (error.response.status) {
      case 413:
        throw new Error(i18next.t(translations.questionnaire.uploadErrors[413]));
      default:
        throw new Error(i18next.t(translations.questionnaire.uploadErrors.default));
    }
  }
};

export const moveQuestionUp = (question: Question) => {
  return {
    type: MOVE_QUESTION_UP,
    payload: question,
  };
};

export const moveQuestionDown = (question: Question) => {
  return {
    type: MOVE_QUESTION_DOWN,
    payload: question,
  };
};

export const fetchSurveyResults = (id: ID) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSurveyResults.request());
      const result = await Services.survey.fetchSurveyResults(id);
      dispatch(actions.fetchSurveyResults.success(new SurveyResult(result)));
    } catch (error) {
      console.log("fetchSurveyResult failure", error);
    }
  };
};

export const filterSurveyResults = (id: ID, filter: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.filterSurveyResults.request());
      const result = await Services.survey.filterSurveyResults(id, filter);
      dispatch(actions.filterSurveyResults.success(new SurveyResult(result)));
    } catch (error) {
      console.log("filterSurveyResults failure", error);
    }
  };
};

export const requestReleaseOfSurvey = (id: ID) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.requestReleaseOfSurvey.request());
      await Services.survey.requestPublishOfSurvey(id);
      dispatch(actions.requestReleaseOfSurvey.success());
      localStorage.removeItem("surveyToken");
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};
//TODO currently accept and publish, later to separate and cleanup
export const acceptReleaseOfSurvey = (id: ID, invitationTemplateName: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.acceptReleaseOfSurvey.request());
      await Services.survey.acceptPublishOfSurvey(id, invitationTemplateName);
      dispatch(actions.acceptReleaseOfSurvey.success());
      return Promise.resolve();
    } catch (error) {
      dispatch(actions.acceptReleaseOfSurvey.failure());
      return Promise.reject(error);
    }
  };
};
export const denyReleaseOfSurvey = (id: ID, reason: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.denyReleaseOfSurvey.request(reason));
      await Services.survey.denyPublishOfSurvey(id, reason);
      dispatch(actions.denyReleaseOfSurvey.success());
      return Promise.resolve();
    } catch (error) {
      console.log("denyReleaseOfSurvey failure", error);
      return Promise.reject(error);
    }
  };
};

interface Action {
  type?: string;
  payload: Question;
  title: string;
  panel: Panel;
  household: Household[];
  gender: GenderShare;
  complexity: Complexity;
  ageRange: MinMaxShareNumber;
  income: Income[];
  amount: number;
  attributeRegion: Region[];
  attributeFam: Family[];
  attributeEducation: Education[];
  attributeChildren: Children[];
  isUpdating: boolean;
  attributeIndividual: IndividualAttribute[];
  panelConfiguration: [];
  selectedGender: [];
  index: number;
  attributeType: keyof Panel;
  id: string;
  SurveyResults: SurveyResult;
}

export type SurveyAction = ActionType<typeof actions> & Action;
export type State = typeof defaultState;

const mapAttributeToValue = (attributeToMapId: string) => {
  return (attribute: any) => {
    if (attributeToMapId === attribute.attributeId) {
      return attribute.value && attribute.value.length
        ? attribute.value.map((val: any) => val.value || val)
        : [];
    } else {
      return null;
    }
  };
};

export const reducer = (state: State = defaultState, action: SurveyAction): State => {
  switch (action.type) {
    case getType(actions.createTransientSurvey.request):
      return { ...state, isSubmitting: true };
    case getType(actions.createTransientSurvey.success):
      if (action.payload instanceof Survey && action.payload.token) {
        localStorage.setItem("surveyToken", action.payload.token);
      }
      //@ts-ignore
      return { ...state, isSubmitting: false, ...action.payload };
    case getType(actions.updateTransientSurvey.request):
      return { ...state, isSubmitting: true };
    case getType(actions.updateTransientSurvey.success):
      return {
        ...state,
        isSubmitting: false,
        //@ts-ignore
        ...action.payload,
        panel: {
          ...state.panel,
        },
        questionnaire: {
          ...state.questionnaire,
        },
        transientSurvey: action.payload,
      };
    case getType(actions.fetchOrganizationTransientSurveys.request):
      return { ...state, isLoading: true };
    case getType(actions.fetchOrganizationTransientSurveys.success):
      return {
        ...state,
        isLoading: false,
        researcherSurveys: Array.isArray(action.payload) ? action.payload: [],
      };
    case getType(actions.fetchSurveyInformation.request):
      return { ...state, isLoading: true };
    case getType(actions.fetchSurveyInformation.success):
      return { ...state, additionalInformation: (action.payload as SurveyAdminInformation), isLoading: false };
    case getType(actions.fetchTransientSurvey.request):
      return { ...state, isLoading: true };
    case getType(actions.fetchTransientSurvey.success):
      const { survey, attributes } = action.payload as {survey: Survey; attributes: Attribute[]};
      let panelCriteriaGenderSelection = survey.panel.criteria.filter(
        (single: any) => single.attributeId === state.panel.gender.attributeId
      );
      return {
        ...state,
        isLoading: false,
        ...survey,
        panel: {
          ...state.panel,
          ...survey.panel,
          age: {
            attributeId: state.panel.age.attributeId,
            answer:
              [
                ...survey.panel.criteria
                  .filter((single: any) => single.attributeId === state.panel.age.attributeId)
                  .map((item: any) => {
                    return {
                      min: item.value.lower,
                      max: item.value.upper,
                      percentage: item.percentage,
                    };
                  }),
              ] || state.panel.age.answer,
          },
          gender: {
            attributeId: state.panel.gender.attributeId,
            answer:
              panelCriteriaGenderSelection.length > 0
                ? [
                //@ts-ignore
                    ...panelCriteriaGenderSelection[0].value.map((item: any) => {
                      return {
                        id: item.value,
                        answer: item.value,
                        percentage: 100,
                      };
                    }),
                  ]
                : state.panel.gender.answer,
          },
          education: {
            attributeId: state.panel.education.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.education.attributeId))
                .filter(Boolean)[0] || state.panel.education.answer,
          },
          family: {
            attributeId: state.panel.family.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.family.attributeId))
                .filter(Boolean)[0] || state.panel.family.answer,
          },
          household: {
            attributeId: state.panel.household.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.household.attributeId))
                .filter(Boolean)[0] || state.panel.household.answer,
          },
          income: {
            attributeId: state.panel.income.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.income.attributeId))

                .filter(Boolean)[0] || state.panel.income.answer,
          },
          children: {
            attributeId: state.panel.children.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.children.attributeId))
                .filter(Boolean)[0] || state.panel.children.answer,
          },
          leitregion: {
            attributeId: state.panel.leitregion.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.leitregion.attributeId))
                .filter(Boolean)[0] || state.panel.leitregion.answer,
          },
          county: {
            attributeId: state.panel.county.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.county.attributeId))
                .filter(Boolean)[0] || state.panel.county.answer,
          },
          state: {
            attributeId: state.panel.state.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.state.attributeId))
                .filter(Boolean)[0] || state.panel.state.answer,
          },
          touristArea: {
            attributeId: state.panel.touristArea.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.touristArea.attributeId))
                .filter(Boolean)[0] || state.panel.touristArea.answer,
          },
          city: {
            attributeId: state.panel.city.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.city.attributeId))
                .filter(Boolean)[0] || state.panel.city.answer,
          },
          populationDensity: {
            attributeId: state.panel.populationDensity.attributeId,
            answer:
              survey.panel.criteria
                .map(mapAttributeToValue(state.panel.populationDensity.attributeId))
                .filter(Boolean)[0] || state.panel.populationDensity.answer,
          },
          // let's merge the individual attribute with the given criteria to restore the remote panel
          individual: IndividualAttribute.extractIndividualAttributesFromCriteria(
            survey.panel.criteria,
            attributes
          ),
        },
        questionnaire: new Questionnaire(survey.questionnaire),
        transientSurvey: survey,
      };
    case getType(actions.fetchTransientSurvey.failure):
      return { ...state, isLoading: false };
    case getType(actions.fetchSurveyResults.request):
      return { ...state, isLoading: true };
    case getType(actions.fetchSurveyResults.success):
      return { ...state, isLoading: false, surveyResults: action.payload as SurveyResult};
    case getType(actions.filterSurveyResults.request):
      return { ...state, isLoading: true };
    case getType(actions.filterSurveyResults.success):
      return { ...state, isLoading: false, surveyResults: action.payload as SurveyResult};
    case getType(actions.requestReleaseOfSurvey.request):
      return { ...state, isLoading: true };
    case getType(actions.requestReleaseOfSurvey.success):
      return { ...state, isLoading: false };
    case getType(actions.acceptReleaseOfSurvey.request):
      return { ...state, isPublishing: true };
    case getType(actions.acceptReleaseOfSurvey.success):
      return { ...state, isPublishing: false };
    case getType(actions.acceptReleaseOfSurvey.failure):
      return { ...state, isPublishing: false };
    case getType(actions.denyReleaseOfSurvey.request):
      return { ...state, isLoading: true };
    case getType(actions.denyReleaseOfSurvey.success):
      return { ...state, isLoading: false };
    case SET_PANEL_SIZE:
      return { ...state, panel: { ...state.panel, size: action.payload as number } };
    case SET_PANEL_AGE_RANGE:
      return { ...state, panel: { ...state.panel, age: state.panel.age } };
    case SET_PANEL_CONFIGURATION:
      return {
        ...state,
        panelConfiguration: action.payload,
        answerDictionary: action.payload
          .reduce((result: any, item: any) => {
            const array =
              item.question.answerOptions &&
              item.question.answerOptions.map((option: any) => {
                return {
                  id: option.id,
                  label: option.label,
                };
              });

            return result.concat(array);
          }, [])
          .filter(Boolean)
          .reduce((acc: any, cur: any) => ({ ...acc, [cur.id]: cur.label }), {}),
        isLoading: false,
      };
    case SET_PANEL_GENDER_ALL:
      return {
        ...state,
        panel: {
          ...state.panel,
          gender: {
            ...state.panel.gender,
            answer: action.selectedGender,
          },
        },
      };
    case SET_CHILDREN_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          children: {
            ...state.panel.children,
            attributeId: action.payload,
          },
        },
      };
    case SET_EDUCATION_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          education: {
            ...state.panel.education,
            attributeId: action.payload,
          },
        },
      };
    case SET_FAMILY_STATUS_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          family: {
            ...state.panel.family,
            attributeId: action.payload,
          },
        },
      };
    case SET_AGE_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          age: {
            ...state.panel.age,
            attributeId: action.payload,
          },
        },
      };
    case SET_GENDER_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          gender: {
            ...state.panel.gender,
            attributeId: action.payload,
          },
        },
      };
    case SET_HOUSEHOLD_SIZE_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          household: {
            ...state.panel.household,
            attributeId: action.payload,
          },
        },
      };
    case SET_INCOME_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          income: {
            ...state.panel.income,
            attributeId: action.payload,
          },
        },
      };
    case SET_COUNTY_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          county: {
            ...state.panel.county,
            attributeId: action.payload,
          },
        },
      };
    case SET_STATE_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          state: {
            ...state.panel.state,
            attributeId: action.payload,
          },
        },
      };
    case SET_LEITREGION_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          leitregion: {
            ...state.panel.leitregion,
            attributeId: action.payload,
          },
        },
      };
    case SET_CITY_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          city: {
            ...state.panel.city,
            attributeId: action.payload,
          },
        },
      };
    case SET_POPULATION_DENSITY_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          populationDensity: {
            ...state.panel.populationDensity,
            attributeId: action.payload,
          },
        },
      };
    case SET_TOURIST_AREA_ATTRIBUTE_ID:
      return {
        ...state,
        panel: {
          ...state.panel,
          touristArea: {
            ...state.panel.touristArea,
            attributeId: action.payload,
          },
        },
      };
    case GET_PANEL_CONFIGURATION:
      return { ...state, isLoading: true };
    case ADD_QUESTION_TO_QUESTIONNAIRE:
      return {
        ...state,
        questionnaire: new Questionnaire({
          ...state.questionnaire,
          questions: [
            ...state.questionnaire.questions,
            new Question({
              ...new Question(),
              // todo retrieve from server or have placeholder at first
              //@ts-ignore
              id: uuidv4(),
            }),
          ],
        }),
      };
    case COPY_QUESTION:
      let copiedQuestion = { ...(action.payload as Question) };
      let answerOptions = copiedQuestion.body.answerOptions.map((answerOption: AnswerOption) => {
        return new AnswerOption({
          label: answerOption.label,
          media: answerOption.media,
          additionalFieldType: answerOption.additionalFieldType,
          skipTo: answerOption.skipTo,
        });
      });
      let subQuestions = copiedQuestion.body.subQuestions.map((subQuestion: MatrixSubQuestion) => {
        return new MatrixSubQuestion({
          label: subQuestion.label,
          media: subQuestion.media,
        });
      });

      return {
        ...state,
        questionnaire: new Questionnaire({
          ...state.questionnaire,
          questions: [
            ...state.questionnaire.questions,
            {
              ...copiedQuestion,
              // todo retrieve from server or have placeholder at first
              // @ts-ignore
              id: uuidv4(),
              body: {
                ...copiedQuestion.body,
                answerOptions: answerOptions,
                subQuestions: subQuestions,
              },
            },
          ],
        }),
      };
    case DELETE_QUESTION_FROM_QUESTIONNAIRE:
      return {
        ...state,
        questionnaire: new Questionnaire({
          ...state.questionnaire,
          questions: state.questionnaire.questions.filter(
            (question) => question.id !== action.payload!.id
          ),
        }),
      };
    case DELETE_CONDITION_FROM_QUESTION:
      return {
        ...state,
        questionnaire: new Questionnaire({
          ...state.questionnaire,
          questions: state.questionnaire.questions.map((question) => {
            return {
              ...question,
              conditions: question.conditions.filter(
                (condition) => condition.questionId !== action.payload.id
              ),
            };
          }),
        }),
      };
    case EDIT_QUESTION:
      return {
        ...state,
        questionnaire: new Questionnaire({
          ...state.questionnaire,
          questions: state.questionnaire.questions.map((question) =>
            question.id === action.payload.id ? action.payload : question
          ),
        }),
      };

    case EDIT_TITLE:
      return {
        ...state,
        questionnaire: new Questionnaire({
          ...state.questionnaire,
        }),
      };

    case MOVE_QUESTION_UP:
      const questionToMove = state.questionnaire.questions.find(
        (question) => question.id === action.payload!.id
      );
      if (questionToMove) {
        const indexOfQuestionToMove = state.questionnaire.questions.indexOf(questionToMove);
        if (indexOfQuestionToMove === 0) {
          return state;
        } else {
          return {
            ...state,
            questionnaire: new Questionnaire({
              ...state.questionnaire,
              questions: [
                // questions before end position of questionToMove
                ...state.questionnaire.questions.slice(0, indexOfQuestionToMove - 1),
                questionToMove,
                // question that was just before our questionToMove
                state.questionnaire.questions[indexOfQuestionToMove - 1],
                // questions after starting position of questionToMove
                ...state.questionnaire.questions.slice(indexOfQuestionToMove + 1),
              ],
            }),
          };
        }
      } else {
        console.warn("Question with given id not present in questionnaire");
        return state;
      }

    case MOVE_QUESTION_DOWN:
      const questionToMoveDown = state.questionnaire.questions.find(
        (question) => question.id === action.payload.id
      );
      if (questionToMoveDown) {
        const indexOfQuestionToMoveDown = state.questionnaire.questions.indexOf(questionToMoveDown);
        if (indexOfQuestionToMoveDown === state.questionnaire.questions.length - 1) {
          return state;
        } else {
          return {
            ...state,
            questionnaire: new Questionnaire({
              ...state.questionnaire,
              questions: [
                // questions before origin position of questionToMoveDown
                ...state.questionnaire.questions.slice(0, indexOfQuestionToMoveDown),
                // question that came originally after the moved question
                state.questionnaire.questions[indexOfQuestionToMoveDown + 1],
                questionToMoveDown,
                // questions after the origin position of questionToMoveDown
                ...state.questionnaire.questions.slice(indexOfQuestionToMoveDown + 2),
              ],
            }),
          };
        }
      } else {
        console.warn("Question with given id not present in questionnaire");
        return state;
      }

    case SET_SURVEY_TITLE:
      return { ...state, title: action.title };
    case SET_PANEL_GENDER:
      let newGender: GenderShare[];
      if (state.panel.gender.answer.length === 0) {
        newGender = [action.gender];
      } else {
        const percentage = 100;
        const oldGender = state.panel.gender.answer.map((gender) => ({
          ...gender,
          percentage,
        }));
        newGender = [...oldGender, { ...action.gender, percentage }];
      }
      return {
        ...state,
        panel: { ...state.panel, gender: { ...state.panel.gender, answer: newGender } },
      };
    case SET_PANEL_REGION:
      return {
        ...state,
        panel: {
          ...state.panel,
          county: {
            ...state.panel.county,
            answer: action.attributeRegion.filter((region) => region.type === 2),
          },
          state: {
            ...state.panel.state,
            answer: action.attributeRegion.filter((region) => region.type === 1),
          },
          touristArea: {
            ...state.panel.touristArea,
            answer: action.attributeRegion.filter((region) => region.type === 3),
          },
          populationDensity: {
            ...state.panel.populationDensity,
            answer: action.attributeRegion.filter((region) => region.type === 4),
          },
          leitregion: {
            ...state.panel.leitregion,
            answer: action.attributeRegion.filter((region) => region.type === 5),
          },
          city: {
            ...state.panel.populationDensity,
            answer: action.attributeRegion.filter((region) => region.type === 6),
          },
        },
      };
    case SET_PANEL_EDUCATION:
      return {
        ...state,
        panel: {
          ...state.panel,
          education: {
            ...state.panel.education,
            answer: action.attributeEducation,
          },
        },
      };
    case SET_PANEL_FAMILY_STATUS:
      return {
        ...state,
        panel: {
          ...state.panel,
          family: {
            ...state.panel.family,
            answer: action.attributeFam,
          },
        },
      };
    case SET_PANEL_CHILDREN:
      return {
        ...state,
        panel: {
          ...state.panel,
          children: {
            ...state.panel.children,
            answer: action.attributeChildren,
          },
        },
      };
    case ADD_PANEL_AGE_RANGE:
      let newAgeRange: MinMaxShareNumber[];
      if (state.panel.age.answer.length === 0) {
        newAgeRange = [action.ageRange];
      }
      if (state.panel.age.answer.length === 2) {
        const newAgeLength = state.panel.age.answer.length + 1;
        const newShare = 100 / newAgeLength;
        const oldAge = state.panel.age.answer.map((ageItem) => ({
          ...ageItem,
          percentage: Math.round(newShare),
        }));
        newAgeRange = [...oldAge, { ...action.ageRange, percentage: Math.round(newShare) + 1 }];
      } else {
        const newAgeLength = state.panel.age.answer.length + 1;
        const newShare = 100 / newAgeLength;
        const oldAge = state.panel.age.answer.map((age) => ({ ...age, percentage: newShare }));
        newAgeRange = [...oldAge, { ...action.ageRange, percentage: newShare }];
      }
      return {
        ...state,
        panel: {
          ...state.panel,
          age: { ...state.panel.age, answer: newAgeRange },
        },
      };
    case UPDATE_PANEL_AGE_RANGE:
      return {
        ...state,
        panel: {
          ...state.panel,
          age: {
            ...state.panel.age,
            answer: state.panel.age.answer.map((item, index) =>
              index === action.index ? action.ageRange : item
            ),
          },
        },
      };
    case REMOVE_PANEL_AGE_RANGE:
      let shortenedAgeRange;
      if (state.panel.age.answer.length === 2) {
        const newAge = state.panel.age.answer.filter((age, index) => index !== action.index);
        shortenedAgeRange = newAge.map((age) => ({ ...age, percentage: 100 }));
      } else {
        const lengthPlus = state.panel.age.answer.length - 1;
        const newShare = 100 / lengthPlus;
        shortenedAgeRange = state.panel.age.answer
          .filter((age, index) => index !== action.index)
          .map((age) => ({ ...age, percentage: newShare }));
      }
      return {
        ...state,
        panel: {
          ...state.panel,
          age: { ...state.panel.age, answer: shortenedAgeRange },
        },
      };
    case SET_PANEL_INCOME_RANGE:
      return {
        ...state,
        panel: { ...state.panel, income: { ...state.panel.income, answer: action.income } },
      };
    case SET_LIVE_PRICE_UPDATE:
      return { ...state, isUpdating: action.isUpdating };
    case REMOVE_PANEL_GENDER:
      return {
        ...state,
        panel: {
          ...state.panel,
          gender: {
            ...state.panel.gender,
            answer: state.panel.gender.answer.filter(
              (entry) => entry.answer !== action.gender.answer
            ),
          },
        },
      };

    case DELETE_PANEL_GENDER_ALL:
      return {
        ...state,
        panel: {
          ...state.panel,
          gender: {
            ...state.panel.gender,
            answer: [],
          },
        },
      };
    case SET_INDIVIDUAL_ATTRIBUTES:
      return {
        ...state,
        panel: {
          ...state.panel,
          individual: action.attributeIndividual,
        },
      };
    case RESET_QUESTIONNAIRE:
      return {
        ...state,
        panel: defaultState.panel,
        questionnaire: defaultState.questionnaire,
        title: "",
      };

    case SET_HOUSEHOLD_SIZE:
      return {
        ...state,
        panel: {
          ...state.panel,
          household: {
            ...state.panel.household,
            answer: action.household,
          },
        },
      };
    case SET_ASSUMED_QUESTIONNAIRE_COMPLEXITY:
      return {
        ...state,
        assumedQuestionnaireComplexity: action.complexity,
      };
    case SET_AUTO_PRICING_ON:
      return {
        ...state,
        autoPricing: true,
      };
    case SET_AUTO_PRICING_OFF:
      return {
        ...state,
        autoPricing: false,
      };
    case "survey/RESET_SURVEY_STORE":
      return defaultState;
    default:
      return state;
  }
};
