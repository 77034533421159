import React, { PureComponent } from "react";
import { WithStyles, createStyles, withStyles, Typography } from "@material-ui/core";
import { QuopinionTheme, blueGradient } from "../../../../constants/Theme";
import { compose } from "recompose";
import FormControlLabel, { FormControlLabelProps } from "@material-ui/core/FormControlLabel";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../../constants/lang/translation";

interface OwnProps extends FormControlLabelProps {
  cost: number;
  height: "big" | "medium" | "small";
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      alignContent: "flex-end",
      alignItems: "center",
      background: blueGradient,
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      flexGrow: 1,
      height: (props: OwnProps) =>
        props.height === "small" ? theme.spacing(28) : props.height === "medium" ? "90%" : "100%",
      margin: (props: OwnProps) => (props.height === "medium" ? `0 ${theme.spacing(2)}px` : 0),
      opacity: (props: OwnProps) => (props.checked ? 1 : 0.4),
      padding: `${theme.spacing(4)}px ${theme.spacing(1)}px ${theme.spacing(0)}px`,
      textAlign: "center",
      fontSize: "14px",
      fontWeight: (props: OwnProps) => (props.checked ? 600 : 400),
    },
    label: { color: "#fff" },
    root: {
      width: 20,
      "& svg": {
        width: 24,
        height: 24,
      },
    },
    text: {
      height: "fit-content",
      lineHeight: 1,
      margin: `${theme.spacing(3)}px 0`,
      wordBreak: "break-word",
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class ValueSelectItem extends PureComponent<Props> {
  renderLabel = () => {
    const { classes, cost, t, ...props } = this.props;
    return (
      <>
        <Typography color="textSecondary" className={classes.text}>
          {props.label}
        </Typography>
        <Typography color="textSecondary">
          {cost} {t(translations.register.participant.opinion.cent)}
        </Typography>
      </>
    );
  };
  render() {
    const { title, t, classes, cost, ...props } = this.props;
    return (
      <FormControlLabel
        {...props}
        label={this.renderLabel()}
        labelPlacement="top"
        classes={{ label: classes.label, root: classes.root }}
        value={props.value}
        className={classes.wrapper}
      />
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(ValueSelectItem);
