import React, { PureComponent } from "react";
import { createStyles, WithStyles, withStyles, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";

import { translations } from "../../../../constants/lang/translation";
import { QuopinionTheme } from "../../../../constants/Theme";

import Button from "../../../../common/Layout/components/Button";
import Card from "../../../../common/Layout/components/Card";
import { withRouter, RouteComponentProps } from "react-router";
import { route as attributeFormRoute } from "../AttributeForm";
import RequestedAttribute from "../../../../entities/RequestedAttribute";

interface State {}

interface OwnProps {
  externalAttribute: RequestedAttribute;
  handleDeny: () => void;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    customLogobar: {
      display: "flex",
    },
    headline: {
      marginBottom: theme.spacing(4),
    },
    card: {
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing(6),
      opacity: (props: OwnProps) => (props.externalAttribute.status === "REJECTED" ? 0.5 : "unset"),
    },
    cardTitle: {
      textTransform: "uppercase",
    },
    emailText: {
      fontSize: "14px",
      lineHeight: 1.71,
      fontWeight: 600,
      minWidth: 376,
      maxWidth: 376,
      whiteSpace: "pre-wrap",
    },
    textContainer: {
      flexGrow: 2,
      marginBottom: theme.spacing(4),
    },
    buttonContainer: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "space-between",
    },
    align: {
      alignSelf: "flex-end",
    },
  });

type Props = OwnProps & WithTranslation & RouteComponentProps & WithStyles<typeof styles>;

class AttributeListItem extends PureComponent<Props, State> {
  render() {
    const {
      classes,
      t,
      externalAttribute,
      history: { push },
      handleDeny,
    } = this.props;
    return (
      <Card className={classes.card}>
        <div className={classes.textContainer}>
          <Typography variant="body2" className={classes.cardTitle}>
            {externalAttribute.account.firstName} {externalAttribute.account.lastName}
          </Typography>
          <Typography variant="body2" className={classes.cardTitle}>
            {externalAttribute.account.emailAddress}
          </Typography>
          <Typography variant="body2" className={classes.emailText}>
            {externalAttribute.message}
          </Typography>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            className={classes.align}
            size="small"
            color="primary"
            contained={true}
            onClick={() =>
              push(
                `${attributeFormRoute}${externalAttribute.id}/${externalAttribute.organizationId}`
              )
            }
            disabled={externalAttribute.status === "REJECTED"}
          >
            {t(translations.action.accept)}
          </Button>
          <Button
            className={classes.align}
            size="small"
            color="quarternary"
            onClick={() => handleDeny()}
            disabled={externalAttribute.status === "REJECTED"}
          >
            {t(translations.action.deny)}
          </Button>
        </div>
      </Card>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withRouter,
  withStyles(styles)
)(AttributeListItem);
