import React from "react";
import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";
import { Typography, createStyles, withStyles, WithStyles } from "@material-ui/core";
import TextColumn from "./TextColumn";
import { primary, QuopinionTheme } from "../../constants/Theme";

interface OwnProps {
  contentObj: {
    title: string;
    item1: { title: string; description: string };
    item2: { title: string; description: string };
    item3: { title: string; description: string };
  };
  backgroundColor: string;
  centeredItemTitles: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      padding: `${theme.spacing(12)}px ${theme.spacing(4)}px`,
      "@media(min-width: 600px)": {
        padding: `${theme.spacing(14)}px ${theme.spacing(10)}px`,
      },
      backgroundColor: (props: OwnProps) => props.backgroundColor,
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
      "@media (min-width: 600px)": {
        flexDirection: "row",
        paddingLeft: theme.spacing(5.5),
        paddingRight: theme.spacing(5.5),
      },
    },
    headline: {
      color: primary,
      display: "flex",
      justifyContent: "center",
      "@media(min-width:600px)": {
        fontSize: "32px",
        lineHeight: 1.25,
      },
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

const LandingpageBodyItem: React.FC<Props> = ({ classes, contentObj, centeredItemTitles }) => {
  return (
    <div className={classes.root} id="research-teaser">
      <Typography variant="h2" className={classes.headline}>
        {contentObj.title}
      </Typography>
      <div className={classes.wrapper}>
        <TextColumn
          centeredTitle={centeredItemTitles}
          title={contentObj.item1.title}
          description={contentObj.item1.description}
          image={""}
        />
        <TextColumn
          centeredTitle={centeredItemTitles}
          title={contentObj.item2.title}
          description={contentObj.item2.description}
          image={""}
        />
        <TextColumn
          centeredTitle={centeredItemTitles}
          title={contentObj.item3.title}
          description={contentObj.item3.description}
          image={""}
        />
      </div>
    </div>
  );
};

export const LandingpageBody = compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(LandingpageBodyItem);
