import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, withStyles, WithStyles, Grid, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";

import { QuopinionTheme } from "../../constants/Theme";
import { HashLink as Link } from "react-router-hash-link";
import Button from "../../common/Layout/components/Button";
import { translations } from "../../constants/lang/translation";

import QuopinionLogo from "../../assets/logo.png";

interface OwnProps {
  title: string;
  description: string;
  errorCode: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(12)}px`,
      "@media (min-width: 600px)": {
        padding: `${theme.spacing(10)}px ${theme.spacing(0)}px ${theme.spacing(
          14
        )}px ${theme.spacing(10)}px`,
      },
      "@media (min-width: 1280px)": {
        padding: `${theme.spacing(9)}px ${theme.spacing(0)} ${theme.spacing(14)}px ${theme.spacing(
          13
        )}`,
      },
    },
    description: {
      color: theme.palette.grey[400],
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(6)}px`,
      "@media (min-width: 600px)": {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(6)}px`,
      },
    },
    logo: {
      maxWidth: 120,
      "@media(min-width: 600px)": {
        marginBottom: theme.spacing(6),
      },
    },
    image: {
      maxHeight: 504,
      position: "absolute",
      display: "none",
      marginLeft: theme.spacing(4),
      "@media (min-width: 768px)": {
        display: "block",
      },
      "@media(min-width: 995px)": {
        right: 0,
      },
    },
    wrapper: {
      backgroundColor: theme.palette.grey[200],
      position: "relative",
    },
    link: {
      textDecoration: "none",
    },
    buttonContainer: {
      display: "flex",
      margin: "0 -8px",
      "& button": {
        margin: "0 8px",
      },
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class ErrorPageHeader extends PureComponent<Props> {
  render() {
    const { title, description, classes, t, errorCode } = this.props;
    return (
      <Grid container={true} className={classes.wrapper}>
        <Grid item={true} xs={12} sm={7} md={6} lg={5} className={classes.root}>
          <img src={QuopinionLogo} alt="Quopinion Logo" className={classes.logo} />
          <Typography variant="h1" color="primary">
            {title}
          </Typography>
          <Typography variant="h3" color="primary">
            Code: {errorCode}
          </Typography>
          <Typography variant="body1" color="inherit" className={classes.description}>
            {description}
          </Typography>
          <div className={classes.buttonContainer}>
            <Link to="/" smooth={true} className={classes.link}>
              <Button size="medium" color="primary" contained={true}>
                {t(translations.action.startingPage)}
              </Button>
            </Link>
          </div>
        </Grid>
        <Grid item={true} md={1} lg={2} />
        <Grid item={true} sm={5}>
          {/*ToDO change picture*/}
          <picture>
            <source
              media="(min-width: 1280px)"
              srcSet={require("../../assets/images/landingpage@3x.png")}
            />
            <source
              media="(min-width: 1024px)"
              srcSet={require("../../assets/images/landingpage@2x.png")}
            />
            <source
              media="(min-width: 768px)"
              srcSet={require("../../assets/images/landingpage@1x.png")}
            />
            <img
              className={classes.image}
              src={require("../../assets/images/landingpage@1x.png")}
              alt=""
            />
          </picture>
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles), withTranslation())(ErrorPageHeader);
