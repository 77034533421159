import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";
import i18n from "i18next";

import ContentWrapper from "../../../common/Layout/ContentWrapper";
import { translations } from "../../../constants/lang/translation";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { primary, QuopinionTheme, secondary } from "../../../constants/Theme";
import { route as ImprintRoute } from "../Imprint";
import { route as ParticipantDataPrivacyRoute } from "../Privacy";
import headerPicture from "../../../assets/images/ParticipantLandingpage/headerPicture_big_new.png";
import registrationPicture from "../../../assets/images/ParticipantLandingpage/registration.png";
import registrationPictureSmall from "../../../assets/images/ParticipantLandingpage/registrationSmall.png";
import LandingpageHeaderParticipant from "../../../common/Landingpage/LandingpageHeaderPraticipant";
import { route as ParticipantRegistrationRoute } from "../Register";
import LandingpagePictureTextItem from "../../../common/Landingpage/LandingPagePictureTextItem";
import UspOverview from "../../../common/Landingpage/UspOverview";
// @ts-ignore
import participantVideo from "../../../assets/videos/participantSubtitled.mp4";
import { HeaderChildParticipant } from "../../../common/Landingpage/HeaderChildParticipant";
import { route as ParticipantLoginRoute } from "../Login";
import firstUspImage from "../../../assets/images/ParticipantLandingpage/benefit1.png";
import secondUspImage from "../../../assets/images/ParticipantLandingpage/benefit2.png";
import thirdUspImage from "../../../assets/images/ParticipantLandingpage/benefit3.png";
import { route as ParticipantDashboardRoute } from "../Dashboard";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { Footer } from "../../../common/Landingpage/Footer";
import participantLPPosterImage from "../../../assets/videos/posterImages/participantLPPosterImage.png";
import { provisionalLPRoute } from "../../../common/Layout/LogoBarResearcher";

interface OwnProps {}

interface StateProps {
  isAuthenticated: boolean | undefined;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      width: "100%",
      fontFamily: "catamaranBold",
    },
    wrapper: {
      marginBottom: theme.spacing(0),
    },
    button: {
      textDecoration: "none",
    },
    image: {
      position: "absolute",
      marginLeft: theme.spacing(4),
      display: "block",
      width: theme.spacing(80),
      top: 0,
      right: theme.spacing(0),
      "@media (min-width: 760px)": {
        width: theme.spacing(105),
      },
      "@media (min-width: 880px)": {
        width: theme.spacing(125),
      },
      "@media(min-width: 995px)": {
        right: 0,
      },
      "@media(min-width: 1020px)": {
        width: theme.spacing(162.5),
      },
      "@media(min-width:1170px)": {
        width: theme.spacing(200),
        height: theme.spacing(202.5),
      },
    },
    registrationImage: {
      display: "block",
      width: theme.spacing(80),
      top: 0,
      right: theme.spacing(0),
      maxWidth: theme.spacing(80),
      flexGrow: 1,
      "@media (min-width: 465px)": {
        maxWidth: theme.spacing(112.5),
        width: theme.spacing(112.5),
        flexGrow: "unset",
      },
      "@media (min-width: 530px)": {
        maxWidth: theme.spacing(129),
        width: theme.spacing(129),
      },
      "@media (min-width: 600px)": {
        maxWidth: theme.spacing(71.25),
        width: theme.spacing(71.25),
      },
      "@media (min-width: 786px)": {
        maxWidth: theme.spacing(146.25),
        width: theme.spacing(146.25),
      },
      "@media(min-width:1286px)": {
        height: "100%",
      },
    },
    buttonContainer: {
      display: "flex",
      position: "relative",
      zIndex: 2,
      justifyContent: "flex-end",
      marginRight: theme.spacing(6.75),
      marginTop: theme.spacing(11),
      margin: `0px ${theme.spacing(-2)}px`,
      "& button": {
        margin: `0px ${theme.spacing(2)}px`,
      },
      flexDirection: "row",
      height: "unset",
      marginBottom: 40,
      "@media(min-width:765px)": {
        flexDirection: "row",
        height: "unset",
      },
    },
    link: {
      textDecoration: "none",
    },
    leftButton: {
      marginRight: theme.spacing(5),
    },
    footerWrapper: {
      marginTop: theme.spacing(3),
      "@media(min-width: 465px)": {
        marginTop: theme.spacing(30.75),
      },
      "@media(min-width: 531px)": {
        marginTop: theme.spacing(45),
      },
      "@media(min-width:600px)": {
        marginTop: theme.spacing(0),
      },
    },
    registrationPicture: {
      position: "absolute",
      top: "-211px",
      "@media(min-width:600px)": {
        display: "flex",
        position: "unset",
        alignItems: "flex-end",
      },
    },
    uspImage: {
      width: theme.spacing(43.75),
      height: theme.spacing(32.5),
      "@media (min-width: 768px)": {
        width: theme.spacing(43.75),
        height: theme.spacing(32.5),
      },
    },
  });

type Props = OwnProps & StateProps & WithTranslation & WithStyles<typeof styles>;

export const route: string = "/participant";

const HeaderImage = (props: Props) => (
  <picture>
    <source media="(min-width: 1280px)" srcSet={headerPicture} />
    <source media="(min-width: 1024px)" srcSet={headerPicture} />
    <source media="(min-width: 768px)" srcSet={headerPicture} />
    <img className={props.classes.image} src={headerPicture} alt="header" />
  </picture>
);

const RegistrationImage = (props: Props) => (
  <picture className={props.classes.registrationPicture}>
    <source media="(min-width: 1285px)" srcSet={registrationPicture} />
    <source media="(min-width: 1024px)" srcSet={registrationPicture} />
    <source media="(min-width: 785px)" srcSet={registrationPicture} />
    <img
      className={props.classes.registrationImage}
      src={registrationPictureSmall}
      alt="registration"
    />
  </picture>
);

const getUspObject = (props: Props) => {
  return [
    {
      title: i18n.t(translations.pages.landingpage.participant.usp.first),
      subtitle: i18n.t(translations.pages.landingpage.participant.usp.firstSubtitle),
      number: "1",
      color: secondary,
      children: (
        <img className={props.classes.uspImage} src={firstUspImage} alt="Panel Selection" />
      ),
    },
    {
      title: i18n.t(translations.pages.landingpage.participant.usp.second),
      subtitle: i18n.t(translations.pages.landingpage.participant.usp.secondSubtitle),
      number: "2",
      color: primary,
      children: (
        <img className={props.classes.uspImage} src={secondUspImage} alt="Panel Selection" />
      ),
    },
    {
      title: i18n.t(translations.pages.landingpage.participant.usp.third),
      subtitle: i18n.t(translations.pages.landingpage.participant.usp.thirdSubtitle),
      number: "3",
      color: secondary,
      children: (
        <img className={props.classes.uspImage} src={thirdUspImage} alt="Panel Selection" />
      ),
    },
  ];
};

class LandingPageParticipant extends PureComponent<Props> {
  render() {
    const { classes, t, isAuthenticated } = this.props;
    return (
      <ContentWrapper className={classes.wrapper}>
        <LandingpageHeaderParticipant
          title={t(translations.pages.landingpage.participant.headline)}
          description={
            <>
              {t(translations.pages.landingpage.participant.subtitle)}&nbsp;
              {t(translations.pages.landingpage.participant.subtitle2)}
            </>
          }
          backgroundColor={"#fff"}
          logoRouteAuthenticated={ParticipantDashboardRoute}
          logoRouteNotAuthenticated={provisionalLPRoute} //instead of /
          isAuthenticated={isAuthenticated}
          video={participantVideo}
          videoTitle={t(translations.pages.landingpage.participant.subtitle3)}
          posterImage={participantLPPosterImage}
          videoButtonTitle={t(translations.action.registerNow)}
          videoButtonLink={ParticipantRegistrationRoute}
        >
          <HeaderChildParticipant
            headerImage={<HeaderImage {...this.props} />}
            firstButtonText={t(translations.action.loginGeneral)}
            firstRoute={ParticipantLoginRoute}
            firstButtonTextAuthenticated={t(translations.action.startNow)}
            firstRouteAuthenticated={ParticipantDashboardRoute}
            secondButtonText={t(translations.action.register)}
            secondRoute={ParticipantRegistrationRoute}
            isAuthenticated={isAuthenticated}
          />
        </LandingpageHeaderParticipant>
        <UspOverview uspArray={getUspObject(this.props)} />
        <LandingpagePictureTextItem
          title={t(translations.pages.landingpage.participant.headlineRegister)}
          description={""}
          backgroundColor={"#fff"}
          buttonText={t(translations.action.register)}
          buttonLink={ParticipantRegistrationRoute}
        >
          {<RegistrationImage {...this.props} />}
        </LandingpagePictureTextItem>
        <div className={classes.footerWrapper}>
          <Footer imprintLink={ImprintRoute} dataSecurityLink={ParticipantDataPrivacyRoute} />
        </div>
      </ContentWrapper>
    );
  }
}
const mapStateToProps = ({ session }: RootState) => ({
  isAuthenticated: session.authenticated,
});

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps)
)(LandingPageParticipant);
