import React from "react";
import { Grid, createStyles } from "@material-ui/core";
import CheckboxElement from "../../../../../common/Preview/components/Checkbox";
import { AttributeConfig } from "../DemographicsCard";
import { Gender, GenderShare } from "../../../../../entities/Panel";
import { translations } from "../../../../../constants/lang/translation";
import { WithTranslation, withTranslation } from "react-i18next";
import { QuopinionTheme, blueGradient } from "../../../../../constants/Theme";
import { WithStyles, withStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { connect } from "react-redux";
import { RootState } from "../../../../../modules";
import { actions as surveyActions } from "../../../../../modules/survey";
import ID from "../../../../../entities/ID";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    checkBox: {
      background: blueGradient,
      borderRadius: "4px",
      color: "#fff !important",
      fontSize: "11px !important",
      marginRight: theme.spacing(4),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2.75),
      paddingRight: theme.spacing(4),
      width: 121,
      "& .Mui-checked": {
        "& svg": {
          width: 24,
          height: 24,
        },
      },
      "& svg": {
        width: 22,
        height: 22,
      },
      "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
        position: "relative",
        "& svg": {
          position: "absolute",
          top: "-2px",
          left: "-2px",
        },
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    distributionContainer: {
      marginTop: theme.spacing(4),
    },
    selectionTitle: {
      marginRight: theme.spacing(3),
      width: 50,
    },
  });

interface OwnProps {
  question: any;
  selectionConfig: AttributeConfig;
  selectedGender: GenderShare[];
  onHandleAllGenderSelect: (selectedIDs: ID[], checked: boolean) => void;
  onHandleGenderSelect: (gender: Gender, value: string, checked: boolean) => void;
}

interface DispatchProps {
  setGenderAttributeId: typeof surveyActions.setGenderAttributeId;
}

type Props = OwnProps & WithTranslation & DispatchProps & WithStyles<typeof styles>;

const GenderSelection = ({
  t,
  classes,
  selectedGender,
  onHandleAllGenderSelect,
  onHandleGenderSelect,
  question,
}: Props) => {
  return (
    <>
      <Grid container={true}>
        <CheckboxElement
          checked={isElementChecked("all", selectedGender)}
          onChange={(event, checked) => {
            const ids: ID[] = question.question.answerOptions.map(
              (option: { id: ID }) => option.id
            );
            onHandleAllGenderSelect(ids, checked);
          }}
          answerValue="all"
          label={t(translations.summary.panel.gender.all)}
          key="all"
          className={classes.checkBox}
          smallText={true}
          headerAlignItems={"flex-end"}
        />
        {question.question.answerOptions.map((value: any) => (
          <CheckboxElement
            checked={isElementChecked(value.id, selectedGender)}
            onChange={(event, checked) => {
              onHandleGenderSelect(event.target.value as Gender, value.label, checked);
            }}
            answerValue={value.id}
            label={value.label}
            key={value.id}
            className={classes.checkBox}
            smallText={true}
            headerAlignItems={"flex-end"}
          />
        ))}
      </Grid>
    </>
  );
};

const isElementChecked = (value: any, genderArray: GenderShare[]) => {
  if (value === "all") {
    return genderArray.length === 3;
  }
  return !!genderArray.find((entry) => entry.answer === value);
};

const mapDispatchToProps = {
  setGenderAttributeId: surveyActions.setGenderAttributeId,
};

export default compose<Props, OwnProps>(
  connect<{}, DispatchProps, OwnProps, RootState>(undefined, mapDispatchToProps),
  withTranslation(),
  withStyles(styles)
)(GenderSelection);
