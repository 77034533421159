import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createStyles,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../../../constants/Theme";
import { DataItem } from "./QuestionResultItem";
import { translations } from "../../../../../constants/lang/translation";
import UploadedMediaItem from "../../../../../common/Layout/components/UploadedMediaItem";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    table: {},
    tableHeadCell: {
      color: theme.palette.primary.main,
    },
    media: {
      maxWidth: 180,
    },
    tableColumnAnswerOption: {
      maxWidth: 185,
      overflowWrap: "break-word",
    },
    tableColumnValue: {
      maxWidth: 185,
      overflowWrap: "break-word",
    },
  });

interface OwnProps {
  data?: DataItem[];
  forFreetext?: boolean;
  relativeData?: boolean;
  additionalFreeTextData?: DataItem[];
  additionalNumberData?: DataItem[];
  additionalTableHeaderInformation?: string;
}

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;
const DataTable: React.FC<Props> = (props: Props) => {
  const {
    classes,
    data,
    relativeData,
    t,
    additionalFreeTextData,
    additionalNumberData,
    additionalTableHeaderInformation,
  } = props;

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeadCell}>
            {additionalFreeTextData
              ? t(translations.pages.surveyResults.additionalFreeTextAnswer)
              : additionalNumberData
              ? t(translations.pages.surveyResults.additionalNumberAnswer)
              : t(translations.pages.surveyResults.answer)}
            {additionalTableHeaderInformation && ` (${additionalTableHeaderInformation})`}
          </TableCell>
          <TableCell className={classes.tableHeadCell}>
            {t(
              translations.pages.surveyResults[
                relativeData ? "shareInPercent" : "absoluteNrAnswers"
              ]
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data &&
          data.map((data: DataItem, index: number) => (
            <TableRow key={index}>
              <TableCell>
                {data.name}
                {data.media && (
                  <div className={classes.media}>
                    <UploadedMediaItem media={data.media} />
                  </div>
                )}
              </TableCell>
              <TableCell>{data.value || 0}</TableCell>
            </TableRow>
          ))}
        {additionalFreeTextData &&
          additionalFreeTextData.map((data: DataItem, index: number) => (
            <TableRow key={index}>
              <TableCell className={classes.tableColumnAnswerOption}>{data.name}</TableCell>
              <TableCell className={classes.tableColumnValue}>{data.value}</TableCell>
            </TableRow>
          ))}
        {additionalNumberData &&
          additionalNumberData.map((data: DataItem, index: number) => (
            <TableRow key={index}>
              <TableCell className={classes.tableColumnAnswerOption}>{data.name}</TableCell>
              <TableCell className={classes.tableColumnValue}>{data.value}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default compose<Props, OwnProps>(withStyles(styles), withTranslation())(DataTable);
