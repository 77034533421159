import React, { PureComponent } from "react";
import { withStyles, WithStyles, createStyles } from "@material-ui/styles";
import { gold, green, QuopinionTheme, red, secondary } from "../constants/Theme";
import { compose } from "recompose";
import zxcvbn from "zxcvbn";
import { WithTranslation, withTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { translations } from "../constants/lang/translation";
import * as i18next from 'i18next';

interface OwnProps {
  password: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      color: theme.palette.grey[400],
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(4),
      "& progress[value]": {
        appearance: "none",
        WebkitAppearance: "none",
        height: 8,
        width: "50%",
        "&::-webkit-progress-bar": {
          backgroundColor: theme.palette.grey[100],
          borderRadius: 4,
        },
        "&::-webkit-progress-value": {
          borderRadius: 4,
        },
      },
    },
    headline: {
      marginBottom: theme.spacing(2),
    },
    progressBar0: {
      "&::-webkit-progress-value": {
        backgroundColor: red,
      },
    },
    progressBar1: {
      "&::-webkit-progress-value": {
        backgroundColor: red,
      },
    },
    progressBar2: {
      "&::-webkit-progress-value": {
        backgroundColor: gold,
      },
    },
    progressBar3: {
      "&::-webkit-progress-value": {
        backgroundColor: secondary,
      },
    },
    progressBar4: {
      "&::-webkit-progress-value": {
        backgroundColor: green,
        color: "#f25f5c",
      },
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class PasswordStrength extends PureComponent<Props> {
  getStrengthLabel = (result: zxcvbn.ZXCVBNResult, t: i18next.TFunction) => {
    switch (result.score) {
      case 0:
        return t(translations.register.loginDetails.passwortSecurity.fair);
      case 1:
        return t(translations.register.loginDetails.passwortSecurity.fair);
      case 2:
        return t(translations.register.loginDetails.passwortSecurity.medium);
      case 3:
        return t(translations.register.loginDetails.passwortSecurity.good);
      case 4:
        return t(translations.register.loginDetails.passwortSecurity.strong);
      default:
        return t(translations.register.loginDetails.passwortSecurity.fair);
    }
  };
  getProgressClass = (result: zxcvbn.ZXCVBNResult) => {
    switch (result.score) {
      case 0:
        return this.props.classes.progressBar0;
      case 1:
        return this.props.classes.progressBar1;
      case 2:
        return this.props.classes.progressBar2;
      case 3:
        return this.props.classes.progressBar3;
      case 4:
        return this.props.classes.progressBar4;
      default:
        return this.props.classes.progressBar0;
    }
  };
  render() {
    const { password, t, classes } = this.props;
    const testedPassword = zxcvbn(password);
    const progressBarColor = this.getProgressClass(testedPassword);
    return (
      <div className={classes.wrapper}>
        <Typography variant="caption" color="inherit" className={classes.headline}>
          {t(translations.register.loginDetails.passwortSecurity.title)}:{" "}
          {this.getStrengthLabel(testedPassword, t)}
        </Typography>
        <progress className={progressBarColor} value={testedPassword.score} max="4" />
      </div>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(PasswordStrength);
