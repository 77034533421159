import Researcher, { OrganizationDetails } from "../entities/Researcher";

interface ResearcherState {
  basicProfile: Researcher;
}

const SAVE_RESEARCHER_DATA = "profile/SAVE_RESEARCHER_DATA";
const SAVE_BILL_DATA = "profile/SAVE_BILL_DATA";

const mockProfile = {
  firstName: "Johann",
  lastName: "Winter",
  researcherDetails: {
    position: "Software Engineer",
    salutation: "HERR",
  },
  organizationDetails: {
    company: "Quotas",
    adress: "Holstenstraße 20",
    zipCode: "12345",
    city: "Hamburg",
    postBox: "",
    additionalInvoiceInformation: "",
    industry: "IT",
  },
};

const defaultState: ResearcherState = {
  basicProfile: new Researcher(mockProfile),
};

interface ResearcherAction {
  type: string;
  researcher: Researcher;
  organizationDetails: OrganizationDetails;
}

export const saveResearcherData = (researcher: Researcher) => ({
  type: SAVE_RESEARCHER_DATA,
  researcher,
});

export const saveOrganizationDetails = (organizationDetails: OrganizationDetails) => ({
  type: SAVE_BILL_DATA,
  organizationDetails,
});

export const reducer = (
  state: ResearcherState = defaultState,
  action: ResearcherAction
): ResearcherState => {
  switch (action.type) {
    case SAVE_RESEARCHER_DATA:
      return {
        ...state,
        basicProfile: action.researcher,
      };
    case SAVE_BILL_DATA:
      return {
        ...state,
        basicProfile: new Researcher({
          ...state.basicProfile,
          organizationDetails: action.organizationDetails,
        }),
      };
    default:
      return state;
  }
};
