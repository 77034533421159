import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, Grid, WithStyles, withStyles, Typography } from "@material-ui/core";
import { QuopinionTheme } from "../../constants/Theme";

interface OwnProps {
  title: string;
  description?: string;
  image?: React.ReactNode;
  centeredTitle: boolean;
  centeredDescription?: boolean;
  video?: React.ReactNode;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(10),
      "&:first-of-type": {
        marginTop: theme.spacing(6),
      },
      "@media(min-width: 600px)": {
        padding: `0 ${theme.spacing(4)}px 0`,
        "&:first-of-type": {
          marginTop: theme.spacing(10),
          padding: `0 ${theme.spacing(4)}px 0 0`,
        },
      },
      "@media(min-width: 1024px)": {
        padding: `0 ${theme.spacing(6)}px 0`,
        "&:first-of-type": {
          padding: `0 ${theme.spacing(6)}px 0 0`,
        },
      },
      "@media(min-width: 1040px)": {},
    },
    description: {
      color: theme.palette.grey[400],
      width: theme.spacing(72),
      "@media(min-width:341px)": {
        width: "unset",
      },
      "@media(min-width:600px)": {
        textAlign: "center",
        width: theme.spacing(65),
      },
    },
    descriptionWrapper: {
      display: "flex",
      justifyContent: "center",
      textAlign: (props: OwnProps) => (props.centeredDescription ? "center" : "unset"),
    },
    title: {
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2.5),

      "@media(min-width:600px)": {
        textAlign: "center",
      },
    },
    titleWrapper: {
      textAlign: (props: OwnProps) => (props.centeredTitle ? "center" : "start"),
    },
    imageWrapper: {
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(4),
    },
    videoWrapper: {
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(4),
      flexDirection: "column",
      alignItems: "flex-start",
      "@media(min-width:600px)": {
        alignItems: "center",
      },
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class TextColumn extends PureComponent<Props> {
  render() {
    const { title, description, classes, image, video } = this.props;
    return (
      <Grid item={true} xs={12} sm={4} lg={3} className={classes.root}>
        <div className={classes.imageWrapper}>{image && image}</div>
        <div className={classes.videoWrapper}>{video && video}</div>
        <div className={classes.titleWrapper}>
          <Typography variant="subtitle2" color="primary" className={classes.title}>
            {title}
          </Typography>
        </div>
        {description && (
          <div className={classes.descriptionWrapper}>
            <Typography variant="body1" color="inherit" className={classes.description}>
              {description}
            </Typography>
          </div>
        )}
      </Grid>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(TextColumn);
