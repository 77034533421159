import React from "react";
import { createStyles, useMediaQuery, withStyles, WithStyles } from "@material-ui/core";
import theme, { QuopinionTheme } from "../../constants/Theme";
import Button from "../Layout/components/Button";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      margin: "0 -8px",
      "& button": {
        margin: "0 8px",
      },
      flexDirection: "column",
      height: 110,
      marginBottom: theme.spacing(10),
      justifyContent: "flex-start",
      "@media(min-width: 600px)": {
        alignItems: "flex-end",
        marginRight: theme.spacing(0),
        "& button": {
          marginRight: theme.spacing(0),
        },
      },
      "@media(min-width:765px)": {
        flexDirection: "row",
        height: "unset",
        justifyContent: "flex-end",
      },
    },
    link: {
      textDecoration: "none",
    },
    registerButton: {
      marginRight: theme.spacing(0),
    },
  });

interface OwnProps {
  buttonText: string;
  buttonLink: string;
}
type Props = OwnProps & WithStyles<typeof styles> & RouteComponentProps;

const ButtonItem: React.FC<Props> = ({ classes, buttonLink, buttonText, history }) => {
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div className={classes.buttonContainer}>
      <Button
        size={desktop ? "big" : "medium"}
        color="secondary"
        contained={true}
        className={classes.registerButton}
        onClick={() => {
          history.push(buttonLink);
        }}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export const ButtonChild = compose<Props, OwnProps>(
  withRouter,
  withStyles(styles)
)(ButtonItem);
