import Client from "./Client";
import { CreditEntry } from "../entities/User";
import { downloadCSV } from "../util/csv";

export default class User {
  readonly httpClient: Client;

  constructor(httpClient: Client) {
    this.httpClient = httpClient;
  }

  async sendContactMailRequest(contactForm: {
    name: string;
    emailAddress: string;
    phone?: string;
    subject?: string;
    message: string;
    email: string;
    text: string;
  }): Promise<any> {
    return await this.httpClient.post(`/mail/contact`, contactForm);
  }

  async sendContactMailRequestResearcher(contactFormResearcher: {
    salutationType: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phone: string;
    companyName: string;
    companyIndustry: string;
    companyPosition: string;
    phoneBack: boolean;
    message: string;
    name: string;
    email: string;
  }): Promise<any> {
    return await this.httpClient.post(`/mail/contact-researcher`, contactFormResearcher);
  }

  // credits / payment

  async fetchCurrentCredit(): Promise<number> {
    return await this.httpClient.get(`/participants/payment/credit`);
  }

  async fetchCurrentCredits(): Promise<CreditEntry[]> {
    return ((await this.httpClient.get(`/participants/payment`)) as any[])
      .reverse()
      .map((obj: any) => new CreditEntry(obj));
  }

  async requestPayout(amount: number, destination: string, email?: string): Promise<void> {
    return await this.httpClient.post(`/participants/payment/payout`, {
      amount,
      currency: "EUR",
      emailAddress: email || "",
      destination,
    });
  }

  async getPayoutCSV(): Promise<void> {
    return await this.httpClient
      .get(`/participants/payment/payout/csv`)
      .then(downloadCSV("quopinion-payout.csv"));
  }
  async getPayoutHistoryCSV(): Promise<void> {
    return await this.httpClient
      .get(`/csv/ParticipantCredit`)
      .then(downloadCSV("quopinion-payout-history.csv"));
  }

  // researcher billing data
  async getBillingdataCSV(): Promise<void> {
    return await this.httpClient
      .get(`/customer-credit/csv`)
      .then(downloadCSV("researchers-billing-data.csv"));
  }
}
