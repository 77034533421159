import React, { PureComponent } from "react";
import * as Table from "../../../../common/Layout/components/CardTable";
import Card from "../../../../common/Layout/components/Card";
import { Typography, Link } from "@material-ui/core";
import PanelSummary from "../StepSummary/PanelSummary";
import Modal from "../../../../common/Layout/components/Modal";
import { compose } from "recompose";
import { withStyles, createStyles, WithStyles } from "@material-ui/styles";
import { QuopinionTheme } from "../../../../constants/Theme";
import { connect } from "react-redux";
import Panel from "../../../../entities/Panel";
// import LoadingOverlay from "../../../../common/LoadingOverlay";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../../constants/lang/translation";
import Survey, { Complexity, formatPrice, formatDuration } from "../../../../entities/Survey";
import { RootState } from "../../../../modules";
import {
  setAssumedQuestionnaireComplexity,
  setautoPricing,
  setAutoPricingOff,
} from "../../../../modules/survey";
import Questionnaire from "../../../../entities/Questionnaire";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import SwitchComponent from "../../../../common/Layout/components/Switch";
import { Info } from "../../../../assets/icon";
import ModalComponent from "../../../../common/Layout/components/Modal";
import PanelModal from "../../../../common/Layout/components/PanelModal";
import User, {UserRole} from "../../../../entities/User";

interface OwnProps {
  hasPanelModal?: boolean;
  widthForFixedPosition?: number;
}

interface DispatchProps {
  setAssumedQuestionnaireComplexity: (complexity: Complexity) => void; //for later adaption if requiered
  setautoPricing: () => Promise<void>;
  setAutoPricingOff: () => Promise<void>;
}

interface StateProps {
  isLivePriceUpdating: boolean;
  panel: Panel;
  survey: Survey;
  questionnaire: Questionnaire;
  panelConfiguration: [];
  dictionary: { [key: string]: string };
  currentUser: User;
}

interface State {
  modalOpen: boolean;
  autoPricingInfoOpen: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      position: "relative",
      marginBottom: 0,
    },
    greyText: {
      color: theme.palette.grey[400],
    },
    darkBlueText: {
      color: theme.palette.primary.main,
      lineHeight: 1.14,
    },
    overlay: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.common.white,
      opacity: 0.7,
    },
    headline: {
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(
        1.25
      )}px`,
      width: 220,
      "@media(min-width:600px)": {
        fontSize: "17px",
        maxWidth: theme.spacing(30),
      },
      "@media(min-width:780px)": {
        fontSize: "24px",
        maxWidth: "unset",
      },
    },
    twoColumnRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      "@media(min-width:599px)": {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
      "@media(min-width:800px)": {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
    },
    priceInformation: {
      textAlign: "right",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      flexGrow: 1,
      alignItems: "flex-end",
    },
    autoPriceCalculation: {
      textAlign: "right",
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
      alignItems: "flex-end",
      marginBottom: theme.spacing(5),
    },
    wrapper: {
      marginBottom: theme.spacing(5),
      "@media(max-width:599px)": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    complexityRow: {
      "@media(min-width:599px)": {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
      "@media(min-width:800px)": {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
    },
    link: {
      cursor: "pointer",
    },
    panelSizeHeadline: {
      lineHeight: 1.14,
    },
    basedOnText: {
      lineHeight: 1.14,
      maxWidth: theme.spacing(39.75),
    },
    complexityText: {
      marginTop: theme.spacing(5),
      lineHeight: 1.14,
      textAlign: "right",
    },
    complexityContent: {
      maxWidth: "95%",
      wordBreak: "break-word",
    },
    autoPriceSwitchButton: {
      marginTop: theme.spacing(5),
    },
    autoTitle: {
      textAlign: "left",
      marginBottom: theme.spacing(1.75),
      display: "flex",
      flexDirection: "row",
    },
    infoIcon: {
      width: 16,
      height: 16,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  });
type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles> & WithTranslation;

class LivePriceContainer extends PureComponent<Props, State> {
  state = {
    modalOpen: false,
    autoPricingInfoOpen: false,
  };

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  toggleAutoPricingInfo = () => {
    this.setState({ autoPricingInfoOpen: !this.state.autoPricingInfoOpen });
  };

  renderPanelSummaryModal() {
    const { panel } = this.props;
    return (
      <Modal
        label="question-preview"
        description="open preview for maintained question"
        open={this.state.modalOpen}
        onClose={this.toggleModal}
        light={true}
        size={600}
        smallCross={true}
      >
        {/* todo: replace with theme.spacing when styling the component and integration */}
        <Card style={{ paddingTop: 60, paddingLeft: 20, paddingRight: 20 }}>
          <Card isRaised={false}>
            <PanelSummary
              modificationOption={true}
              panel={panel}
              panelConfiguration={this.props.dictionary}
            />
          </Card>
        </Card>
      </Modal>
    );
  }

  renderComplexitySelector() {
    const { t, survey, classes } = this.props;
    return (
      <>
        <div>
          <Typography variant="body1" className={classes.basedOnText}>
            {t(translations.summary.panel.basedOn)}
          </Typography>
        </div>
        <div style={{ width: "100%" }}>
          {/*TODO check live update*/}
          <Typography variant={"body1"} className={classes.complexityText}>
            {t(translations.summary.price.complexity[survey.complexity as Complexity])}
          </Typography>
        </div>
      </>
    );
  }

  renderComplexityPreview() {
    const { t, survey, questionnaire, classes } = this.props;
    return (
      <>
        <Typography variant="body1">
          {t(translations.summary.panel.basedOnYourQuestionnaire)}
        </Typography>
        <Typography variant="body2" className={classes.complexityContent}>
          {t(translations.summary.price.surveyLength[survey.complexity!])} (
          {questionnaire.questions.length}{" "}
          {t(
            questionnaire.questions.length === 1
              ? translations.summary.price.question
              : translations.summary.price.questions
          )}{" "}
          -{" "}
          {`${t(translations.summary.price.approx)} ${formatDuration(survey.duration, t, true)} ${t(
            translations.summary.price.editDuration
          )})`}
        </Typography>
      </>
    );
  }

  renderComplexitySection() {
    const { survey, classes } = this.props;

    return (
      <Table.Row
        style={{
          border: "2px solid #fafafa",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        className={classes.complexityRow}
      >
        {survey.complexity ? this.renderComplexityPreview() : this.renderComplexitySelector()}
      </Table.Row>
    );
  }

  render() {
    const { classes, panel, t, hasPanelModal, survey, widthForFixedPosition, currentUser } = this.props;

    return (
      <div
        className={`${classes.wrapper}`}
        style={{
          width:
            widthForFixedPosition && widthForFixedPosition > 0
              ? `${widthForFixedPosition}px`
              : "auto",
        }}
      >
        <Typography
          color="primary"
          variant="h2"
          className={classes.headline}
          id={"title-predicted-price"}
        >
          {t(translations.summary.price.estimatedPrice)}
        </Typography>

        {currentUser.roles !== undefined && currentUser.roles.find((role: any) => role.type === UserRole.ADMIN) !== undefined &&
        <div className={classes.autoPriceCalculation}>
          <div className={classes.autoTitle}>
            <Typography variant="body1">
              {t(translations.summary.price.automaticPriceCalculation)}
            </Typography>
            <Info className={classes.infoIcon} onClick={this.toggleAutoPricingInfo} />
          </div>
          <ModalComponent
            label="autoPricing-info"
            description="info for autoPricing switch"
            open={this.state.autoPricingInfoOpen}
            onClose={this.toggleAutoPricingInfo}
            showArrow={false}
            size={480}
            light={true}
            smallCross={true}
          >
            <PanelModal
              title={t(translations.summary.price.modalPriceCalculation.realtimePrice)}
              description={t(
                translations.summary.price.modalPriceCalculation.recommendationDeactivation
              )}
            />
          </ModalComponent>
          <SwitchComponent
            label={survey.autoPricing ? t(translations.switch.on) : t(translations.switch.off)}
            checked={survey.autoPricing}
            handleChange={() => {
              survey.autoPricing ? this.props.setAutoPricingOff() : this.props.setautoPricing();
            }}
            fullWidth={false}
          />
        </div>
        }

        {this.renderPanelSummaryModal()}

        <Card isRaised={false} className={classes.root}>
          {this.props.isLivePriceUpdating && <LoadingOverlay />}
          <Table.Row className={classes.twoColumnRow} style={{ borderTop: "none" }}>
            {hasPanelModal && (
              <Link onClick={this.toggleModal} className={classes.link}>
                <Typography variant="body1" style={{ textDecoration: "underline" }}>
                  {t(translations.summary.panel.headline)}
                </Typography>
              </Link>
            )}
            {!hasPanelModal && (
              <Typography variant="body1" className={classes.panelSizeHeadline}>
                {t(translations.summary.panel.headline)}
              </Typography>
            )}
            <Typography variant="body2" className={classes.darkBlueText}>
              {panel.size} {t(translations.summary.panel.size.participantsShort)}
            </Typography>
          </Table.Row>
          {survey.autoPricing && (
            <>
              {this.renderComplexitySection()}
              <Table.Row
                className={classes.twoColumnRow}
                style={{
                  borderBottom: "none",
                }}
              >
                <div className={classes.priceInformation}>
                  <Typography variant="h2" className={classes.darkBlueText} id={"real-time-price"}>
                    {survey.priceWithTaxes === undefined ? formatPrice(0) : survey.priceWithTaxes}
                  </Typography>
                  <Typography variant="caption" className={classes.greyText}>
                    {t(translations.summary.price.taxesIncl)}
                  </Typography>
                </div>
              </Table.Row>
            </>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ survey, user }: RootState) => ({
  isLivePriceUpdating: survey.isSubmitting,
  survey: survey,
  panel: survey.panel,
  dictionary: survey.answerDictionary,
  panelConfiguration: survey.panelConfiguration,
  questionnaire: survey.questionnaire,
  currentUser: user.current,
});

const mapDispatchToProps = { setAssumedQuestionnaireComplexity, setautoPricing, setAutoPricingOff };

export default compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles)
)(LivePriceContainer);
