import React from "react";
import FormInput from "./FormInput";
import * as Yup from "yup";
import { translations } from "../../../../../constants/lang/translation";
import { QuestionTypeProps, QuestionProps } from "./Question";
import AnswerOption from "../../../../../entities/AnswerOption";
import Question from "../../../../../entities/Question";
import QuestionPreview from "../../../../../common/Preview/QuestionPreview";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";

export const QuestionValidationRanking = ({ t }: QuestionProps) => {
  return Yup.object().shape({
    text: Yup.string()
      .required(t(translations.questionnaire.validation.title))
      .max(240, t(translations.questionnaire.validation.titleMaxLength)),
    body: Yup.object().shape({
      answerOptions: Yup.array()
        .required(t(translations.questionnaire.validation.answers.minCount))
        .min(2, t(translations.questionnaire.validation.answers.minCount))
        .max(12, t(translations.questionnaire.validation.answers.maxCount))
        .of(
          Yup.object().shape({
            label: Yup.string()
              .required(t(translations.questionnaire.validation.answers.requiredTitle))
              .max(90, t(translations.questionnaire.validation.answers.length)),
          })
        ),
    }),
  });
};

type Props = QuestionTypeProps & WithTranslation;
export const QuestionFormRankingCore: React.FC<Props> = ({
  body,
  handleChange,
  preventMediaUpload,
  preventAlterationOfAnswerCount,
  id,
  otherQuestions,
  questionIndex,
  type,
  conditions,
  preventQuestionSkip,
}) => {
  const handleInputChange = (index: number, newAnswerOption: AnswerOption) => {
    handleChange({
      ...body,
      answerOptions: body.answerOptions.map((answerOption, answerOptionIndex) =>
        answerOptionIndex === index ? newAnswerOption : answerOption
      ),
    });
  };

  const addAnswerItem = (answerOptions: AnswerOption[], index: number) => {
    answerOptions.splice(index + 1, 0, new AnswerOption());
    return answerOptions;
  };

  const handleAddAnswer = (index: number) => {
    handleChange({
      ...body,
      answerOptions: addAnswerItem([...body.answerOptions], index),
    });
  };

  const handleDeleteAnswer = (indexToDelete: number) => {
    handleChange({
      ...body,
      answerOptions: body.answerOptions.filter(
        (answerOption, answerOptionIndex) => answerOptionIndex !== indexToDelete
      ),
    });
  };

  return (
    <>
      {body.answerOptions.map((answerOption, index) => (
        <FormInput
          answerOption={answerOption}
          otherQuestions={otherQuestions}
          questionIndex={questionIndex}
          handleChange={handleInputChange.bind(answerOption, index)}
          index={index}
          key={answerOption.id}
          deleteAnswer={handleDeleteAnswer.bind(index)}
          addAnswer={handleAddAnswer.bind(answerOption, index)}
          amountOfAnswers={body.answerOptions.length}
          addAdditionalFreetext={() => {}}
          addAdditionalNumericalField={() => {}}
          type={type}
          questionId={id}
          preventMediaUpload={preventMediaUpload}
          preventAlterationOfAnswerCount={preventAlterationOfAnswerCount}
          preventQuestionSkip={preventQuestionSkip}
          preventAdditionalFreeText={true}
          multiline={true}
          conditions={conditions}
        />
      ))}
    </>
  );
};

export const QuestionFormRanking = compose<Props, QuestionTypeProps>(withTranslation())(
  QuestionFormRankingCore
);

export const QuestionPreviewRanking: React.FC<Omit<Question, "toDataJson">> = ({
  text: title,
  body,
  type,
  media,
}) => {
  return (
    <QuestionPreview
      title={title}
      body={body}
      type={type}
      media={media}
      questionNumber={1}
      totalQuestionNumber={1}
    />
  );
};
