import * as React from "react";
import Container from "../../../../common/Layout/Container";
import { connect } from "react-redux";
import QuestionComponent from "./Questions/Question";
import {
  addQuestionToQuestionnaire,
  editQuestion,
  deleteQuestion,
  copyQuestion,
  moveQuestionDown,
  moveQuestionUp,
  resetQuestionnaire,
  updateTransientSurvey,
  deleteCondition,
} from "../../../../modules/survey";
import Button from "../../../../common/Layout/components/Button";
import { translations } from "../../../../constants/lang/translation";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import Introduction from "../IntroductionForStep";
import { CreationStep } from "./../index";
import Question from "../../../../entities/Question";
import SidebarContainer from "../../../../common/Layout/SidebarContainer";
import SupportContainer from "../../../../common/Layout/SupportContainer";
import { Plus } from "../../../../assets/icon";
import { Grid, createStyles, WithStyles, withStyles, Tooltip, Typography } from "@material-ui/core";
import LivePriceContainer from "../partial/LivePriceContainer";
import { actions as uiStateActions } from "../../../../modules/uiState";
import ContentWrapper from "../../../../common/Layout/ContentWrapper";
import DialogComponent from "../../../../common/Layout/components/Dialog";
import ModalComponent from "../../../../common/Layout/components/Modal";
import { withRouter, RouteComponentProps } from "react-router";
import Survey from "../../../../entities/Survey";
import { RootState } from "../../../../modules";
import Questionnaire from "../../../../entities/Questionnaire";
import Panel from "../../../../entities/Panel";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import { ErrorMessage } from "../../../../modules/surveyErrors";
import { ErrorToolTip } from "../../../../common/Layout/components/Tooltip";
import "intersection-observer";
import { QuopinionTheme } from "../../../../constants/Theme";
import { SurveyStatus } from "../../../../entities/Survey";
import { UserRole } from "../../../../entities/User";
import { route as researcherDashboardRoute } from "../../Dashboard";
import QuestionnairePreview from "../../../participant/Questionnaire/QuestionnairePreview";
import { resetPreviewAnswers } from "../../../../modules/surveyPreview";
import ID from "../../../../entities/ID";
import AnswerOption from "../../../../entities/AnswerOption";
import SurveyAnswers from "../../../../entities/SurveyAnswers";
import { fetchCurrentResearcher, fetchUser } from "../../../../modules/user";
import isEqual from "lodash/isEqual";
import { Pagination } from "@material-ui/lab";
import { ChangeEvent } from "react";

export interface OwnProps {
  stepConfiguration?: CreationStep;
  handlePrev(): void;
  handleNext(): void;
}

interface StateProps {
  isAuthenticated: boolean;
  transientSurvey: Survey;
  transientPanel: Panel;
  transientQuestionnaire: Questionnaire;
  visitedSteps: string[];
  isLoading: boolean;
  token: string;
  globalQuestionnaireErrors: ErrorMessage[];
  questionQuestionnaireErrors: ErrorMessage[];
  surveyStatus: SurveyStatus;
  panelConfiguration: [];
  user: any;
  previewAnswers: SurveyAnswers;
  surveyId: string;
  transientSurveyTitle: string;
  globalPanelErrors: ErrorMessage[];
  criteriaPanelErrors: ErrorMessage[];
  autoPricing: boolean;
}

interface DispatchProps {
  resetQuestionnaire: () => { type: string };
  addQuestionToQuestionnaire: () => { type: string };
  editQuestion: (question: Omit<Question, "toDataJson">) => { type: string; payload: Question };
  deleteQuestion: (question: Omit<Question, "toDataJson">) => { type: string; payload: Question };
  deleteCondition: (question: Omit<Question, "toDataJson">) => { type: string; payload: Question };
  copyQuestion: (question: Omit<Question, "toDataJson">) => { type: string; payload: Question };
  moveQuestionDown: (question: Omit<Question, "toDataJson">) => { type: string; payload: Question };
  moveQuestionUp: (question: Omit<Question, "toDataJson">) => { type: string; payload: Question };
  setVisitedStep: typeof uiStateActions.setVisitedStep;
  fetchPanelConfiguration: () => Promise<void>;
  updateTransientSurvey: (transientSurvey: any) => Promise<void>;
  resetPreviewAnswers: () => void;
  fetchUser: () => Promise<void>;
  fetchCurrentResearcher: () => Promise<void>;
}

interface ValidationItem {
  id: string;
  valid: boolean;
}

export interface State {
  formValid: boolean;
  dialogOpen: boolean;
  validQuestion: ValidationItem[];
  fixedContainer: boolean;
  previewOpen: boolean;
  showDesktopPreview: boolean;
  sidebarWidth: number;
  page: number;
}

export interface SkipToInfo {
  questionId: ID;
  answerSkipToInfo: {
    answerId: ID;
    skipToId: boolean;
    skipToEnd: boolean;
  }[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    fixedContainer: {
      position: "fixed",
      top: 0,
      borderRadius: 5,
      padding: `${theme.spacing(6)}px 0px 0px 0px`,
      maxWidth: "21%",
    },
    previewButton: {
      marginRight: theme.spacing(4.5),
    },
    previewModal: {
      outline: "none",
    },
    errorText: {
      marginBottom: theme.spacing(4),
    },
    disabledButtonInfo: {
      textAlign: "end",
    },
    pagination: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(7.5),
    },
    addQuestionButton: {
      marginLeft: theme.spacing(7.5),
    },
  });

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithTranslation &
  RouteComponentProps<{}> &
  WithStyles<typeof styles>;

class SurveyPage extends React.Component<Props, State> {
  autoSafeTick: any;
  intersector: React.RefObject<HTMLDivElement>;
  observer: IntersectionObserver;
  sidebar: React.RefObject<any>;

  state: State = {
    formValid: false,
    dialogOpen: false,
    previewOpen: false,
    validQuestion: [],
    fixedContainer: false,
    showDesktopPreview: false,
    sidebarWidth: 0,
    page: 1,
  };

  constructor(props: Props) {
    super(props);
    this.intersector = React.createRef();
    this.sidebar = React.createRef();
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0.0, 1.0],
    };

    this.observer = new IntersectionObserver(this.callback, options);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.isAuthenticated) {
      this.props.fetchUser();
      this.props.fetchCurrentResearcher();
    }

    if (this.sidebar && this.sidebar.current) {
      this.setState({
        sidebarWidth: this.sidebar.current.offsetWidth,
      });
    }

    if (this.intersector.current !== null) {
      this.observer.observe(this.intersector.current);
    }
  }

  callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        this.setState({
          fixedContainer: false,
        });
      } else {
        this.setState({
          fixedContainer: true,
        });
      }
    });
  };

  //checks allowing only admin role paired with researcher role to modify
  adminCheck = () => {
    if (!this.props.user.current.roles) {
      return true;
    } else {
      const allowed = this.props.user.current.roles.find((userRole: any) => {
        if (userRole.type === UserRole.ADMIN) {
          return false;
        } else {
          return true;
        }
      });
      return Boolean(allowed);
    }
  };

  handleOnSaveClick = () => {
    const {
      updateTransientSurvey,
      transientQuestionnaire,
      transientPanel,
      token,
      surveyId,
      transientSurveyTitle,
      autoPricing,
    } = this.props;

    autoPricing &&
      updateTransientSurvey(
        new Survey({
          id: surveyId,
          title: transientSurveyTitle,
          questionnaire: transientQuestionnaire,
          panel: transientPanel,
          token,
          autoPricing,
        }).toDataJson()
      );
  };

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    if (!isEqual(nextProps, this.props) || !isEqual(nextState, this.state)) {
      //state comparison needed for eg liveprice changes
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (
      !isEqual({ ...prevProps.transientQuestionnaire }, { ...this.props.transientQuestionnaire })
    ) {
      if (this.props.surveyStatus !== SurveyStatus.PUBLISH_REQUESTED && this.adminCheck()) {
        this.handleOnSaveClick();
      }
      if (this.intersector.current !== null) {
        this.observer.observe(this.intersector.current);
      }
    }

    if (this.sidebar && this.sidebar.current) {
      this.setState({
        sidebarWidth: this.sidebar.current.offsetWidth,
      });
    }
  }

  handleQuestionChange = (question: Omit<Question, "toDataJson">) => {
    const { editQuestion } = this.props;
    editQuestion(question);
  };

  handleQuestionDelete = (question: Omit<Question, "toDataJson">) => {
    const { deleteQuestion, deleteCondition } = this.props;
    deleteQuestion(question);
    deleteCondition(question);
  };

  handleQuestionCopy = (question: Omit<Question, "toDataJson">) => {
    const { copyQuestion } = this.props;
    copyQuestion(question);
  };

  addNewQuestion = () => {
    const { addQuestionToQuestionnaire } = this.props;
    addQuestionToQuestionnaire();
  };

  onChangeValidity = (isValid: boolean, question: Omit<Question, "toDataJson">) => {
    const newItem = { id: question.id, valid: isValid };

    let newValidQuestions;
    if (this.state.validQuestion.some((item) => item.id === newItem.id)) {
      newValidQuestions = this.state.validQuestion.map((item) =>
        item.id === newItem.id ? newItem : item
      );
    } else {
      newValidQuestions = [...this.state.validQuestion, newItem];
    }
    this.setState({ validQuestion: newValidQuestions });

    this.checkValidity(newValidQuestions);
  };

  checkValidity = (array: ValidationItem[]) => {
    const { isLoading } = this.props;
    if (isLoading) {
      return;
    } else {
      if (array.some((item) => item.valid === false)) {
        this.setState({
          formValid: false,
        });
      } else {
        this.setState({
          formValid: true,
        });
      }
    }
  };

  removeQuestionFromValidation = (id: string) => {
    const newValidQuestions = this.state.validQuestion.filter((item) => item.id !== id);
    this.setState({
      validQuestion: newValidQuestions,
    });
    this.checkValidity(newValidQuestions);
  };

  handleQuestionMoveUp = (question: Omit<Question, "toDataJson">) => {
    const { moveQuestionUp } = this.props;
    moveQuestionUp(question);
  };

  handleQuestionMoveDown = (question: Omit<Question, "toDataJson">) => {
    const { moveQuestionDown } = this.props;
    moveQuestionDown(question);
  };

  nextStep = (step: string) => {
    const { handleNext, setVisitedStep, visitedSteps } = this.props;
    handleNext();
    if (!visitedSteps.includes(step)) {
      setVisitedStep(step);
    }
  };

  abortQuestionnaireModification = () => {
    const { resetQuestionnaire, history } = this.props;
    this.setState({
      dialogOpen: false,
    });
    resetQuestionnaire();
    history.push(researcherDashboardRoute);
  };

  toggleDialog = () => {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
    });
  };

  generateErrorTooltipMessage = () => {
    const {
      questionQuestionnaireErrors,
      globalQuestionnaireErrors,
      criteriaPanelErrors,
      globalPanelErrors,
      t,
      classes,
    } = this.props;

    const panelErrors = globalPanelErrors.concat(criteriaPanelErrors).map((panelError) => {
      return {
        message: panelError.message,
        element: t(translations.questionnaire.panelPageError),
      };
    });
    const errors = globalQuestionnaireErrors
      .concat(questionQuestionnaireErrors)
      .map((questionQuestionnaireError) => {
        return {
          message: questionQuestionnaireError.message,
          element: t(translations.panel.questionnairePageError),
        };
      });

    return errors.concat(panelErrors).map((error, index) => (
      <Typography key={`error-${index}`} className={classes.errorText}>
        {`${error.element} ${error.message} `}
      </Typography>
    ));
  };

  togglePreview = () => {
    this.setState({
      previewOpen: !this.state.previewOpen,
    });
  };

  closePreview = () => {
    this.props.resetPreviewAnswers();
    this.togglePreview();
  };

  switchModalMode = () => {
    this.setState({
      showDesktopPreview: !this.state.showDesktopPreview,
    });
  };

  renderSurveyPreview() {
    return (
      <QuestionnairePreview
        questionnaire={this.props.transientQuestionnaire}
        handleClose={this.closePreview}
        currentAnswers={this.props.previewAnswers}
        showDesktopPreview={this.state.showDesktopPreview}
      />
    );
  }

  onChangePagination = (event: ChangeEvent<unknown>, page: number) => {
    this.setState({
      page: page,
    });
  };

  getPaginationQuestions = (currentPage: number) => {
    const copiedQuestionsArray: Question[] = Array.from(
      this.props.transientQuestionnaire.questions
    );
    if (copiedQuestionsArray.length > 10) {
      if (currentPage > 1) {
        return copiedQuestionsArray.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10);
      } else {
        return copiedQuestionsArray.slice(0, currentPage * 10);
      }
    } else {
      return copiedQuestionsArray;
    }
  };

  render() {
    const {
      transientQuestionnaire,
      t,
      stepConfiguration,
      classes,
      questionQuestionnaireErrors,
      globalQuestionnaireErrors,
      globalPanelErrors,
      criteriaPanelErrors,
      isLoading,
      user,
    } = this.props;
    const { formValid, dialogOpen, previewOpen } = this.state;

    const questionsModulo = transientQuestionnaire.questions.length % 10;
    const paginationCount =
      questionsModulo === 0
        ? transientQuestionnaire.questions.length / 10
        : Math.round((transientQuestionnaire.questions.length - questionsModulo) / 10) + 1;

    const skipToInfo: SkipToInfo[] = transientQuestionnaire.questions.map((question: Question) => {
      return {
        questionId: question.id,
        answerSkipToInfo: question.body.answerOptions.map((answerOption: AnswerOption) => {
          return {
            answerId: answerOption.id,
            skipToId: answerOption.skipTo !== "end" && answerOption.skipTo !== undefined,
            skipToEnd: answerOption.skipTo === "end",
          };
        }),
      };
    });

    const skipToIdInfo = skipToInfo
      .map((questionItem: SkipToInfo) => {
        return {
          questionId: questionItem.questionId,
          answerSkipToInfo: questionItem.answerSkipToInfo.filter((infoItem) => infoItem.skipToId),
        };
      })
      .filter((returnItem: SkipToInfo) => returnItem.answerSkipToInfo.length);

    const skipToEndInfo = skipToInfo
      .map((questionItem: SkipToInfo) => {
        return {
          questionId: questionItem.questionId,
          answerSkipToInfo: questionItem.answerSkipToInfo.filter((infoItem) => infoItem.skipToEnd),
        };
      })
      .filter((returnItem) => returnItem.answerSkipToInfo.length);

    const getQuestionErrors = (questionId: ID) => {
      const questionQuestionnaireErrors =
        this.props.questionQuestionnaireErrors &&
        this.props.questionQuestionnaireErrors.filter((item) => item.elementId === questionId);
      return questionQuestionnaireErrors;
    };

    return (
      <>
        {stepConfiguration && (
          <Introduction
            title={stepConfiguration.title}
            description={stepConfiguration.description}
          />
        )}
        <DialogComponent
          aria-labelledby="question-delete"
          aria-describedby="open delete dialog"
          open={dialogOpen}
          closeFunction={this.toggleDialog}
          disableAutoFocus={true}
          title={t(translations.dialog.resetQuestionnaire.headline)}
          description={t(translations.dialog.resetQuestionnaire.description)}
          confirmFunction={this.abortQuestionnaireModification}
          buttonText={t(translations.action.delete)}
        />
        <Grid container={true}>
          <ContentWrapper>
            <Container>
              {isLoading && <LoadingOverlay />}
              {this.getPaginationQuestions(this.state.page).map((question, index) => {
                const questionIndex = this.props.transientQuestionnaire.questions.indexOf(question);
                return (
                  <QuestionComponent
                    key={question.id}
                    questionIndex={questionIndex}
                    question={question}
                    otherQuestions={transientQuestionnaire.questions.filter((q) => q !== question)}
                    onChangeValidity={this.onChangeValidity}
                    onQuestionChange={this.handleQuestionChange}
                    onQuestionDelete={this.handleQuestionDelete}
                    onQuestionCopy={this.handleQuestionCopy}
                    onQuestionMoveUp={this.handleQuestionMoveUp}
                    onQuestionMoveDown={this.handleQuestionMoveDown}
                    questionBadge={`${t(translations.questions.sg)} ${questionIndex + 1}`}
                    firstQuestion={questionIndex === 0}
                    lastQuestion={questionIndex === transientQuestionnaire.questions.length - 1}
                    removeQuestionFromValidation={this.removeQuestionFromValidation}
                    questionnaireSkipToEndInfo={skipToEndInfo}
                    questionnaireSkpiToIdInfo={skipToIdInfo}
                    questionQuestionnaireErrors={getQuestionErrors(question.id)}
                    user={user}
                  />
                );
              })}

              <Button
                color="primary"
                size="medium"
                onClick={this.addNewQuestion}
                fullWidth={true}
                icon={<Plus fill="#0b3b5f" />}
                white={true}
                className={classes.addQuestionButton}
              >
                {t(translations.questionnaire.addNewQuestion)}
              </Button>

              {paginationCount > 1 && (
                <div className={classes.pagination}>
                  <Pagination
                    count={paginationCount}
                    page={this.state.page}
                    onChange={this.onChangePagination}
                  />
                </div>
              )}

              <Grid
                container={true}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                style={{ marginTop: 32 }}
              >
                {this.props.isAuthenticated && (
                  <div style={{ flexGrow: 1 }}>
                    <Button color="quarternary" size="medium" onClick={this.toggleDialog}>
                      {t(translations.action.cancel)}
                    </Button>
                  </div>
                )}
                <div style={{ display: "flex" }}>
                  <Tooltip
                    title={t(translations.questionnaire.toolTip.previewMobileView).toString()}
                    aria-label="questionnairePreview"
                  >
                    <div>
                      <Button
                        color="primary"
                        size="medium"
                        onClick={this.togglePreview}
                        className={classes.previewButton}
                      >
                        {t(translations.action.preview)}
                      </Button>
                    </div>
                  </Tooltip>
                  {this.props.isAuthenticated && (
                    <Button
                      color="secondary"
                      onClick={this.handleOnSaveClick}
                      size="medium"
                      style={{ marginRight: 24 }}
                      disabled={!this.props.autoPricing}
                    >
                      {t(translations.action.save)}
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    disabled={
                      !formValid ||
                      questionQuestionnaireErrors.length >= 1 ||
                      globalQuestionnaireErrors.length >= 1 ||
                      globalPanelErrors.length >= 1 ||
                      criteriaPanelErrors.length >= 1 ||
                      !this.props.autoPricing
                    }
                    contained={true}
                    onClick={() => {
                      this.handleOnSaveClick();
                      this.nextStep("summary");
                    }}
                    size="big"
                  >
                    {t(translations.action.nextSummary)}
                  </Button>
                </div>
              </Grid>
            </Container>
            <SidebarContainer>
              <div ref={this.sidebar}>
                <div ref={this.intersector} style={{ height: 20 }} />
                <div
                  className={this.state.fixedContainer ? classes.fixedContainer : ""}
                  style={{ width: this.state.sidebarWidth > 0 ? this.state.sidebarWidth : "auto" }}
                >
                  {this.intersector && (
                    <ErrorToolTip
                      open={Boolean(this.generateErrorTooltipMessage().length)}
                      placement="bottom"
                      title={<>{this.generateErrorTooltipMessage()}</>}
                      style={{
                        width: this.state.sidebarWidth > 0 ? this.state.sidebarWidth : "auto",
                      }}
                    >
                      <div>
                        <LivePriceContainer
                          hasPanelModal={true}
                          widthForFixedPosition={this.state.sidebarWidth}
                        />
                      </div>
                    </ErrorToolTip>
                  )}{" "}
                  <SupportContainer
                    style={{
                      width: "auto",
                    }}
                  />
                </div>
              </div>
            </SidebarContainer>
          </ContentWrapper>
        </Grid>

        <ModalComponent
          label="question-preview"
          description="open preview for survey"
          open={previewOpen}
          onClose={this.closePreview}
          isPreview={true}
          showArrow={false}
          className={classes.previewModal}
          showDesktopPreview={this.state.showDesktopPreview}
          onSwitchModalMode={this.switchModalMode}
          allowDesktopSwitch={true}
        >
          {previewOpen && this.renderSurveyPreview()}
        </ModalComponent>
      </>
    );
  }
}

const mapStateToProps = ({
  survey,
  uiState,
  surveyError,
  session,
  user,
  surveyPreview,
}: RootState) => {
  return {
    isAuthenticated: session.authenticated,
    transientPanel: survey.panel,
    token: survey.token,
    transientQuestionnaire: survey.questionnaire,
    visitedSteps: uiState.visitedSteps,
    isLoading: survey.isLoading,
    globalQuestionnaireErrors: surveyError.globalQuestionnaireErrors,
    questionQuestionnaireErrors: surveyError.questionQuestionnaireErrors,
    globalPanelErrors: surveyError.globalPanelErrors,
    criteriaPanelErrors: surveyError.criteriaPanelErrors,
    surveyStatus: survey.transientSurvey.status,
    surveyId: survey.id,
    transientSurveyTitle: survey.title,
    panelConfiguration: survey.panelConfiguration,
    user,
    previewAnswers: surveyPreview.surveyAnswers,
    autoPricing: survey.autoPricing,
  };
};

const mapDispatchToProps = {
  updateTransientSurvey,
  addQuestionToQuestionnaire,
  editQuestion,
  deleteQuestion,
  deleteCondition,
  copyQuestion,
  moveQuestionDown,
  moveQuestionUp,
  resetQuestionnaire,
  resetPreviewAnswers,
  fetchUser,
  fetchCurrentResearcher,

  setVisitedStep: uiStateActions.setVisitedStep,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SurveyPage);
