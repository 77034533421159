import React, { PureComponent } from "react";
import {
  Typography,
  WithStyles,
  withStyles,
  createStyles,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { QuopinionTheme } from "../../../../constants/Theme";
import { compose } from "recompose";
import ValueSelectItem from "./ValueSelectItem";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../../constants/lang/translation";
import { RadioGroupProps } from "@material-ui/core/RadioGroup";
import { OpinionValueGui } from ".";

interface OwnProps extends RadioGroupProps {
  title: string;
  duration: number;
  opinionValues: OpinionValueGui[];
  errors?: any;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    header: {
      display: "flex",
      "@media(max-width: 360px)": {
        flexDirection: "column",
      },
    },
    headline: {
      marginRight: theme.spacing(2),
    },
    radioGroup: {
      flexDirection: "row",
      flexWrap: "nowrap",
      height: 160,
      alignItems: "flex-end",
      marginTop: theme.spacing(4),
    },
    formControl: {
      display: "flex",
      width: "100%",
    },
    wrapper: {
      marginBottom: theme.spacing(8),
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class ValueSelector extends PureComponent<Props> {
  render() {
    const { title, classes, duration, opinionValues, t, errors } = this.props;
    return (
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Typography variant="h4" className={classes.headline}>
            {title} -
          </Typography>
          <Typography variant="h3">
            {t(translations.summary.price.approx)} &nbsp; {duration}{" "}
            {t(translations.register.participant.opinion.minutes)}
          </Typography>
        </div>
        <FormControl component="fieldset" required={true} className={classes.formControl}>
          <RadioGroup
            aria-label={title}
            name={title}
            value={this.props.value}
            className={classes.radioGroup}
            onChange={this.props.onChange}
          >
            {opinionValues.map((value) => (
              <ValueSelectItem
                key={value.valueIndicator}
                height={value.height}
                cost={value.value}
                label={value.label}
                control={<Radio />}
                value={value.valueIndicator}
                checked={value.valueIndicator === this.props.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {errors && <Typography color="error">{errors}</Typography>}
      </div>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(ValueSelector);
