import React, { PureComponent } from "react";
import {
  createStyles,
  FormControl,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { translations } from "../../../../constants/lang/translation";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../../constants/Theme";
import StepIntro from "../components/StepIntro";
import RadioElement from "../../../../common/Preview/components/RadioButton";
import {
  childrenValues,
  educationValues,
  familyValues,
  genderValues,
  houseHoldValues,
  incomeValues,
} from "../../../../entities/Panel";
import AttributeSelect from "../../../../common/Layout/components/AttributeSelect";
import PasswordStrength from "../../../../common/PasswordStrength";
import Button from "../../../../common/Layout/components/Button";
import FormCheckbox from "../../../../common/Preview/components/FormCheckbox";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  actions,
  registerUser,
  TransientRegistrationObject,
  UsableForQuotasStudies,
} from "../../../../modules/session";
import { RouteComponentProps, withRouter } from "react-router";
import { RootState } from "../../../../modules";
import { connect } from "react-redux";
import { actions as uiStateActions } from "../../../../modules/uiState";
import {
  AttributeConfig,
  AttributeType,
} from "../../../marketintelligence/SurveyCreator/StepPanel";
import { route as ParticipantRegistrationConfirmationPage } from "../../RegisterConfirmation";
import { clearState } from "../../../../util/localStorage";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isValid, subYears } from "date-fns";
import Attribute from "../../../../entities/Attribute";
import { SnackbarComponent } from "../../../../common/Layout/components/Snackbar";
import ModalComponent from "../../../../common/Layout/components/Modal";
import Privacy from "../../Privacy/Privacy";
import TermsOfUse from "../../TermsConditions/TermsOfUse";
import { route as ParticipantLoginRoute } from "../../Login";
import { route as ParticipantPasswordForgottenRoute } from "../../PasswordForgotten";
import { Link } from "react-router-dom";

interface OwnProps {}

interface DispatchProps {
  registerUser: (
    email: string,
    password: string,
    personalData: any,
    userAttributes: any,
    howDidYouHearAboutUs: { value: string; additionalInfo: string },
    usableForQuotasStudies?: UsableForQuotasStudies
  ) => Promise<string>;
  setTransientRegistrationObject: typeof actions.setTransientRegistrationObject;
  removeTransientRegistrationObject: typeof actions.removeTransientRegistrationObject;
  setRegistrationStep: typeof uiStateActions.setRegistrationStep;
  setRegistrationMail: (mail: string) => void;
}

interface StateProps {
  registrationConfiguration: Attribute[];
  registrationSteps: string[];
  transientRegistration: TransientRegistrationObject;
}

interface State {
  showPrivacy: boolean;
  showTerms: boolean;
  showSnackBar: boolean;
  accountAlreadyExists: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    accountArea: {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      paddingTop: theme.spacing(8),
      marginTop: theme.spacing(8),
    },
    bigInput: {
      width: "100%",
    },
    button: {
      borderRadius: 0,
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(24),
      maxWidth: theme.spacing(73),
    },
    categoryHeadline: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(8),
    },
    categoryHeadlineForDropdown: {
      textTransform: "uppercase",
      marginBottom: `-${theme.spacing(2)}px`,
      marginTop: theme.spacing(8),
    },
    headline: {
      paddingBottom: theme.spacing(2),
    },
    hint: {
      marginTop: theme.spacing(8),
    },
    ageHint: {
      margin: `${theme.spacing(3)}px 0`,
    },
    input: {
      width: 111,
      marginRight: theme.spacing(2),
    },
    ageInput: {
      width: 111,
      marginLeft: theme.spacing(2),
    },
    radio: {
      marginBottom: theme.spacing(0.5),
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      position: "relative",
    },
    linkText: {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      "@media(min-width: 768px)": {
        justifyContent: "flex-end",
      },
    },
    radioWithText: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff",
      margin: `0 ${theme.spacing(4)}px ${theme.spacing(0.5)}px 0`,
      padding: `${theme.spacing(3)}px 0`,
      width: "100%",
      "@media(max-width: 600px)": {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    additionalInput: {
      marginLeft: theme.spacing(4),
      minWidth: 220,
    },
    label: {
      display: "flex",
      flexWrap: "wrap",
    },
    modal: {
      backgroundColor: "#fff",
      height: "100%",
      left: 0,
      top: 0,
    },
    modalBody: {
      overflowY: "scroll",
      padding: `${theme.spacing(18)}px ${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(
        4
      )}px`,
      height: "100%",
    },
    numberInput: {
      "& input": {
        "&::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
    },
    link: {
      fontWeight: 700,
      color: "#fff",
      cursor: "pointer",
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps;

type ExtendedAttributeType = AttributeType | "gender" | "income";
class RegistrationAttribute extends PureComponent<Props, State> {
  state = {
    showSnackBar: false,
    showPrivacy: false,
    showTerms: false,
    accountAlreadyExists: false,
  };
  panelAttributes: { [type in ExtendedAttributeType]: AttributeConfig } = {
    household: {
      title: `${this.props.t(translations.summary.panel.household.title)}`,
      options: houseHoldValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.household[value]),
      })),
    },
    educationLevel: {
      title: this.props.t(translations.summary.panel.education),
      options: educationValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.educationLevel[value]),
      })),
    },
    familyStatus: {
      title: this.props.t(translations.summary.panel.familyStatus),
      options: familyValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.familyStatus[value]),
      })),
    },
    children: {
      title: this.props.t(translations.summary.panel.children),
      options: childrenValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.children[value]),
      })),
    },
    gender: {
      title: `${this.props.t(translations.summary.panel.gender.title)}`,
      options: genderValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.gender[value]),
      })),
    },
    income: {
      title: `${this.props.t(translations.summary.panel.income.title)}`,
      options: incomeValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.income[value]),
      })),
    },
  };

  readonly initialValues = {
    personalData: {
      firstName: this.props.transientRegistration
        ? this.props.transientRegistration.personalData.firstName
        : "",
      lastName: this.props.transientRegistration
        ? this.props.transientRegistration.personalData.lastName
        : "",
      zipCode: this.props.transientRegistration
        ? this.props.transientRegistration.personalData.zipCode
        : "",
      city: this.props.transientRegistration
        ? this.props.transientRegistration.personalData.city
        : "",
      countryCode: "DEU",
      birthdayYear: this.props.transientRegistration
        ? this.props.transientRegistration.personalData.birthdayYear
        : subYears(new Date(), 0).getFullYear(),
      birthdayMonth: this.props.transientRegistration
        ? this.props.transientRegistration.personalData.birthdayMonth
        : subYears(new Date(), 0).getMonth() + 1,
      birthdayDayOfMonth: this.props.transientRegistration
        ? this.props.transientRegistration.personalData.birthdayDayOfMonth
        : subYears(new Date(), 0).getDate(),
      birthdayDate: this.props.transientRegistration
        ? this.props.transientRegistration.personalData.birthdayDate
        : subYears(new Date(), 0),
    },
    gender: this.props.transientRegistration
      ? this.props.transientRegistration.gender
      : { attributeId: "", answer: { questionId: "", selectedOptions: [], type: "choice" } },
    household: this.props.transientRegistration
      ? this.props.transientRegistration.household
      : { attributeId: "", answer: { questionId: "", selectedOptions: [], type: "choice" } },
    income: this.props.transientRegistration
      ? this.props.transientRegistration.income
      : { attributeId: "", answer: { questionId: "", selectedOptions: [], type: "choice" } },
    education: this.props.transientRegistration
      ? this.props.transientRegistration.education
      : { attributeId: "", answer: { questionId: "", selectedOptions: [], type: "choice" } },
    familyStatus: this.props.transientRegistration
      ? this.props.transientRegistration.familyStatus
      : {
          attributeId: "",
          answer: { questionId: "", selectedOptions: [], type: "choice" },
        },
    howDidYouHearAboutUs: this.props.transientRegistration
      ? this.props.transientRegistration.howDidYouHearAboutUs
      : { value: "", additionalInfo: "" },
    usableForQuotasStudies: this.props.transientRegistration?.usableForQuotasStudies,
    children: this.props.transientRegistration
      ? this.props.transientRegistration.children
      : { attributeId: "", answer: { questionId: "", selectedOptions: [] } },
    email: "",
    password: "",
    passwordRepeat: "",
    privacy: false,
  };

  toggleSnackBar = () => {
    this.setState({
      showSnackBar: !this.state.showSnackBar,
    });
  };
  toggleAccountAlreadyExists = () => {
    this.setState({
      accountAlreadyExists: !this.state.accountAlreadyExists,
    });
  };

  toggleTerms = () => {
    this.setState({
      showTerms: !this.state.showTerms,
    });
  };

  togglePrivacy = () => {
    this.setState({
      showPrivacy: !this.state.showPrivacy,
    });
  };

  componentDidMount() {
    // clear session
    clearState();
  }

  readonly validationSchema = () => {
    const { t } = this.props;
    const today = new Date();
    const minBirthday = subYears(today, 100);
    const maxBirthday = subYears(today, 16);
    return Yup.object().shape({
      gender: Yup.object()
        .shape({
          attributeId: Yup.string(),
          answer: Yup.object().shape({
            selectedOptions: Yup.array().required(
              t(translations.register.participant.data.validationGender)
            ),
            type: Yup.string(),
          }),
        })
        .required(t(translations.register.participant.data.validationGender)),
      personalData: Yup.object().shape({
        firstName: Yup.string()
          .trim()
          .required(t(translations.register.participant.data.validationFirstName))
          .matches(/^[\w'\-,.]*[^_!"§¡?÷?¿\\+=@#$%ˆ&*/(){}|~<>;:[\]]*$/, {
            message:
              'Die Eingabe darf nicht aus Sonderzeichen wie: _!"§¡?÷¿\\+=@#$%ˆ&*/(){}|~<>;:[] bestehen.',
          }),
        lastName: Yup.string()
          .trim()
          .required(t(translations.register.participant.data.validationLastName))
          .matches(/^[\w'\-,.]*[^_!"§¡?÷?¿\\+=@#$%ˆ&*/(){}|~<>;:[\]]*$/, {
            message:
              'Die Eingabe darf nicht aus Sonderzeichen wie: _!"§¡?÷¿\\+=@#$%ˆ&*/(){}|~<>;:[] bestehen.',
          }), //regex adapted by adding "§ and removing numbers from: Deniss M.
        // https://stackoverflow.com/questions/2385701/regular-expression-for-first-and-last-name/45871742#45871742
        zipCode: Yup.string()
          .trim()
          .min(5, t(translations.register.participant.data.validationZipLengthGeneral))
          .max(5, t(translations.register.participant.data.validationZipLengthGeneral))
          .required(t(translations.register.participant.data.validationZip)),
        city: Yup.string()
          .trim()
          .required(t(translations.register.participant.data.validationHometown)),
        birthdayYear: Yup.string().required(
          t(translations.register.participant.data.validationBirthday)
        ),
        birthdayDate: Yup.date()
          .min(minBirthday, t(translations.register.participant.data.validationMaxAge))
          .max(maxBirthday, t(translations.register.participant.data.validationMinAge))
          .required(t(translations.register.participant.data.validationAge))
          .typeError(t(translations.register.participant.data.validationBirthdayDateType)),
      }),
      household: Yup.object().shape({
        attributeId: Yup.string(),
        answer: Yup.object().shape({
          questionId: Yup.string(),
          selectedOptions: Yup.array().required(
            t(translations.register.participant.data.validationHousehold)
          ),
        }),
      }),
      income: Yup.object().shape({
        attributeId: Yup.string(),
        answer: Yup.object().shape({
          questionId: Yup.string(),
          selectedOptions: Yup.array().required(
            t(translations.register.participant.data.validationIncome)
          ),
        }),
      }),
      education: Yup.object().shape({
        attributeId: Yup.string(),
        answer: Yup.object().shape({
          questionId: Yup.string(),
          selectedOptions: Yup.array().required(
            t(translations.register.participant.data.validationEducation)
          ),
        }),
      }),
      familyStatus: Yup.object().shape({
        attributeId: Yup.string(),
        answer: Yup.object().shape({
          questionId: Yup.string(),
          selectedOptions: Yup.array().required(
            t(translations.register.participant.data.validationFamilyStatus)
          ),
        }),
      }),
      children: Yup.object().shape({
        attributeId: Yup.string(),
        answer: Yup.object().shape({
          questionId: Yup.string(),
          selectedOptions: Yup.array().required(
            t(translations.register.participant.data.validationChildren)
          ),
        }),
      }),
      howDidYouHearAboutUs: Yup.object().shape({
        value: Yup.string().required(
          t(translations.register.participant.data.attention.validation)
        ),
        additionalInfo: Yup.string(),
      }),
      usableForQuotasStudies: Yup.string().required(
        t(translations.register.participant.data.usableForQuotasStudies.validation)
      ),
      email: Yup.string()
        .trim()
        .email(t(translations.validation.login.emailNotValid))
        .matches(/^[\w]*[^áéíóúäöü]*$/, {
          message: "Die Eingabe darf nicht aus Zeichen wie: á,é,í,ó,ú,ä,ö,ü bestehen.",
        }) //regex adapted from regex above
        .required(t(translations.validation.login.emailRequired)),
      password: Yup.string()
        .trim()
        .min(8, t(translations.validation.login.passwordMinLength))
        .required(t(translations.validation.login.passwordRequired)),
      passwordRepeat: Yup.string()
        .oneOf(
          [Yup.ref("password"), ""],
          t(translations.register.loginDetails.passwordRepeatRequired)
        )
        .required(t(translations.register.loginDetails.passwordRepeatRequired)),
      privacy: Yup.mixed()
        .oneOf([true], t(translations.register.loginDetails.privacyTermsConditions))
        .required(t(translations.register.loginDetails.privacyTermsConditions)),
    });
  };

  handleSetTransientObject = (values: TransientRegistrationObject | any) => {
    this.props.setTransientRegistrationObject({
      personalData: values.personalData,
      gender: values.gender,
      household: values.household,
      income: values.income,
      education: values.education,
      familyStatus: values.familyStatus,
      children: values.children,
      howDidYouHearAboutUs: values.howDidYouHearAboutUs,
      usableForQuotasStudies: values.usableForQuotasStudies,
    });
  };

  render() {
    const {
      t,
      classes,
      registerUser,
      setRegistrationStep,
      registrationConfiguration,
      registrationSteps,
      setRegistrationMail,
    } = this.props;

    if (!Boolean(registrationSteps.length)) {
      this.props.history.push("/participant/registration");
    }

    const attention = {
      title: t(translations.register.participant.data.attention.title),
      answerOptions: [
        {
          label: t(translations.register.participant.data.attention.value.facebook),
          value: "facebook",
          type: "radio",
        },
        {
          label: t(translations.register.participant.data.attention.value.instagram),
          value: "instagram",
          type: "radio",
        },
        {
          label: t(translations.register.participant.data.attention.value.google),
          value: "google",
          type: "radio",
        },
        {
          label: t(translations.register.participant.data.attention.value.xing),
          value: "xing",
          type: "radio",
        },
        {
          label: t(translations.register.participant.data.attention.value.linkedin),
          value: "linkedIn",
          type: "radio",
        },
        {
          label: t(translations.register.participant.data.attention.value.youtube),
          value: "youtube",
          type: "radio",
        },
        {
          label: t(translations.register.participant.data.attention.value.quotas),
          value: "quotas",
          type: "radio",
        },
        {
          label: t(translations.register.participant.data.attention.value.otherParticipant),
          value: "otherParticipant",
          type: "withText",
          placeholder: "Bitte E-Mail Adresse eingeben",
        },
        {
          label: t(translations.register.participant.data.attention.value.other),
          value: "other",
          type: "withText",
        },
      ],
    };

    const usableForStudies = {
      info: t(translations.register.participant.data.usableForQuotasStudies.info),
      title: t(translations.register.participant.data.usableForQuotasStudies.title),
      answerOptions: [
        {
          label: t(translations.register.participant.data.usableForQuotasStudies.value.yes),
          value: "YES",
          type: "radio",
        },
        {
          label: t(translations.register.participant.data.usableForQuotasStudies.value.no),
          value: "NO",
          type: "radio",
        },
        {
          label: t(translations.register.participant.data.usableForQuotasStudies.value.alreadyTn),
          value: "ALREADY_TN",
          type: "radio",
        },
      ],
    };
    return (
      <>
        <ModalComponent
          label="registration privacy"
          description="show privacy for participant"
          open={this.state.showPrivacy}
          onClose={this.togglePrivacy}
          light={true}
          className={classes.modal}
          showArrow={true}
          hideCross={true}
        >
          <div className={classes.modalBody}>
            <Privacy />
          </div>
        </ModalComponent>
        <ModalComponent
          label="registration terms of use"
          description="show terms of use for participant"
          open={this.state.showTerms}
          onClose={this.toggleTerms}
          light={true}
          className={classes.modal}
          showArrow={true}
          hideCross={true}
        >
          <div className={classes.modalBody}>
            <TermsOfUse />
          </div>
        </ModalComponent>
        {this.state.showSnackBar &&
          (this.state.accountAlreadyExists ? (
            <SnackbarComponent
              title={"Es ist ein Fehler aufgetreten"}
              message={
                <>
                  Der Account existiert bereits. Bitte gehe auf die&nbsp;
                  <Link to={ParticipantLoginRoute} className={classes.link}>
                    Loginseite
                  </Link>{" "}
                  oder{" "}
                  <Link to={ParticipantPasswordForgottenRoute} className={classes.link}>
                    setze dein Passwort zurück
                  </Link>
                  .
                </>
              }
              type="error"
              onClose={() => {
                this.toggleSnackBar();
                this.toggleAccountAlreadyExists();
              }}
            />
          ) : (
            <SnackbarComponent
              title={"Es ist ein Fehler aufgetreten"}
              message={"Versuche es zu einem späteren Zeitpunkt noch einmal"}
              type="error"
              onClose={() => {
                this.toggleSnackBar();
              }}
            />
          ))}
        <div style={{ width: "100%" }}>
          <Formik
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const {
                email,
                password,
                howDidYouHearAboutUs,
                usableForQuotasStudies,
                personalData,
                ...userAttributes
              } = values;

              setRegistrationStep("attributes");
              registerUser(
                email,
                password,
                personalData,
                userAttributes,
                howDidYouHearAboutUs,
                usableForQuotasStudies
              )
                .then(() => {
                  setSubmitting(false);
                  setRegistrationMail(email);
                  this.props.removeTransientRegistrationObject();
                  this.props.history.push(ParticipantRegistrationConfirmationPage);
                })
                .catch((err: any) => {
                  setSubmitting(false);
                  if (err.error && err.error.response.status === 409) {
                    this.toggleAccountAlreadyExists();
                    this.toggleSnackBar();
                  } else {
                    this.toggleSnackBar();
                  }
                });
            }}
          >
            {({
              handleSubmit,
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <form className={classes.wrapper} onSubmit={handleSubmit}>
                  <StepIntro
                    badgeTitle={t(translations.register.participant.step) + " 2"}
                    headline={t(translations.register.participant.data.headline)}
                    description={t(translations.register.participant.data.text)}
                  />

                  {registrationConfiguration.map((attribute: any, index) => {
                    if (attribute.name === "Geschlecht") {
                      const { question } = attribute;
                      return (
                        <div key={attribute.id}>
                          {/* gender */}
                          <Typography variant="body2" className={classes.categoryHeadline}>
                            {question.text}
                          </Typography>
                          <FormControl
                            component="fieldset"
                            required={true}
                            style={{ width: "100%" }}
                          >
                            <RadioGroup
                              aria-label="gender"
                              name="gender"
                              value={values.gender.answer.selectedOptions[0]}
                              onChange={(ev, value) => {
                                setFieldValue("gender", {
                                  attributeId: attribute.id,
                                  answer: {
                                    selectedOptions: [value],
                                    type: "choice",
                                  },
                                });
                              }}
                            >
                              {question.answerOptions.map((option: any) => (
                                <RadioElement
                                  name="gender"
                                  disabled={isSubmitting}
                                  value={option.id}
                                  key={option.id}
                                  label={option.label}
                                  className={classes.radio}
                                  control={<Radio />}
                                />
                              ))}
                            </RadioGroup>
                            {errors.personalData && errors.gender && touched.gender && (
                              <Typography variant="body1" component="span" color="error">
                                {errors.gender.answer!.selectedOptions}
                              </Typography>
                            )}
                          </FormControl>
                          <Typography variant="body2" className={classes.categoryHeadline}>
                            Vorname
                          </Typography>
                          <TextField
                            className={classes.bigInput}
                            placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                            name="personalData.firstName"
                            value={values.personalData.firstName}
                            onBlur={() => {
                              this.handleSetTransientObject(values);
                              handleBlur("personalData.firstName");
                            }}
                            onChange={(ev) =>
                              setFieldValue("personalData.firstName", ev.target.value)
                            }
                            required={true}
                            disabled={isSubmitting}
                            helperText={
                              errors.personalData &&
                              errors.personalData.firstName &&
                              touched.personalData &&
                              touched.personalData.firstName ? (
                                <Typography variant="body1" component="span">
                                  {errors.personalData.firstName}
                                </Typography>
                              ) : (
                                ""
                              )
                            }
                            error={
                              errors.personalData &&
                              Boolean(errors.personalData.firstName) &&
                              touched.personalData &&
                              Boolean(touched.personalData.firstName)
                            }
                          />
                          <Typography variant="body2" className={classes.categoryHeadline}>
                            Nachname
                          </Typography>
                          <TextField
                            className={classes.bigInput}
                            placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                            name="personalData.lastName"
                            value={values.personalData.lastName}
                            onBlur={() => {
                              this.handleSetTransientObject(values);
                              handleBlur("personalData.lastName");
                            }}
                            onChange={(ev) =>
                              setFieldValue("personalData.lastName", ev.target.value)
                            }
                            required={true}
                            disabled={isSubmitting}
                            helperText={
                              errors.personalData &&
                              errors.personalData.lastName &&
                              touched.personalData &&
                              touched.personalData.lastName ? (
                                <Typography variant="body1" component="span">
                                  {errors.personalData.lastName}
                                </Typography>
                              ) : (
                                ""
                              )
                            }
                            error={
                              errors.personalData &&
                              Boolean(errors.personalData.lastName) &&
                              touched.personalData &&
                              Boolean(touched.personalData.lastName)
                            }
                          />
                          <div>
                            <Typography variant="body2" className={classes.categoryHeadline}>
                              Geburtsdatum
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                error={
                                  errors.personalData &&
                                  Boolean(errors.personalData.birthdayDate) &&
                                  touched.personalData &&
                                  Boolean(touched.personalData.birthdayDate)
                                }
                                disableToolbar={true}
                                variant="inline"
                                format="dd.MM.yyyy"
                                name={"personalData.birthdayDate"}
                                margin="normal"
                                label="Date picker"
                                value={values.personalData.birthdayDate}
                                onBlur={handleBlur("personalData.birthdayDate")}
                                onChange={(date, value) => {
                                  if (!isValid(date) && value) {
                                    const [day, month, year] = value.split(".");
                                    setFieldValue(
                                      "personalData.birthdayDayOfMonth",
                                      parseInt(day, 10)
                                    );
                                    setFieldValue(
                                      "personalData.birthdayMonth",
                                      parseInt(month, 10)
                                    );
                                    setFieldValue("personalData.birthdayYear", parseInt(year, 10));
                                  } else {
                                    setFieldValue(
                                      "personalData.birthdayDayOfMonth",
                                      date && date.getDate()
                                    );
                                    setFieldValue(
                                      "personalData.birthdayMonth",
                                      date && date.getMonth() + 1
                                    );
                                    setFieldValue(
                                      "personalData.birthdayYear",
                                      date && date.getFullYear()
                                    );

                                    setFieldValue(
                                      "personalData.birthdayDate",
                                      date && new Date(date)
                                    );
                                  }
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            {errors.personalData &&
                            errors.personalData.birthdayDate &&
                            touched.personalData &&
                            touched.personalData.birthdayDate ? (
                              <Typography variant="body1" component="div" color="error">
                                {errors.personalData.birthdayDate}
                              </Typography>
                            ) : (
                              ""
                            )}
                          </div>
                          <>
                            {/* zip */}
                            <Typography variant="body2" className={classes.categoryHeadline}>
                              Postleitzahl
                            </Typography>
                            <TextField
                              type="text"
                              className={`${classes.bigInput} ${classes.numberInput}`}
                              placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                              name="personalData.zipCode"
                              value={values.personalData.zipCode}
                              onBlur={() => {
                                this.handleSetTransientObject(values);
                                handleBlur("personalData.zipCode");
                              }}
                              onChange={(event) => {
                                if (
                                  // a valid number
                                  !isNaN(Number(event.target.value)) &&
                                  // and max 5 digits
                                  event.target.value.length <= 5
                                ) {
                                  handleChange(event);
                                }
                              }}
                              required={true}
                              helperText={
                                errors.personalData &&
                                errors.personalData.zipCode &&
                                touched.personalData &&
                                touched.personalData.zipCode ? (
                                  <Typography variant="body1" component="span">
                                    {errors.personalData.zipCode}
                                  </Typography>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                errors.personalData &&
                                Boolean(errors.personalData.zipCode) &&
                                touched.personalData &&
                                Boolean(touched.personalData.zipCode)
                              }
                            />
                          </>
                          <>
                            {/* last name */}
                            <Typography variant="body2" className={classes.categoryHeadline}>
                              Wohnort
                            </Typography>
                            <TextField
                              className={classes.bigInput}
                              placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
                              name="personalData.city"
                              value={values.personalData.city}
                              onBlur={() => {
                                this.handleSetTransientObject(values);
                                handleBlur("personalData.city");
                              }}
                              onChange={handleChange}
                              required={true}
                              helperText={
                                errors.personalData &&
                                errors.personalData.city &&
                                touched.personalData &&
                                touched.personalData.city ? (
                                  <Typography variant="body1" component="span">
                                    {errors.personalData.city}
                                  </Typography>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                errors.personalData &&
                                Boolean(errors.personalData.city) &&
                                touched.personalData &&
                                Boolean(touched.personalData.city)
                              }
                            />
                          </>
                        </div>
                      );
                    }

                    if (attribute.name === "Haushaltsgröße") {
                      const { question } = attribute;
                      return (
                        <div key={attribute.id}>
                          {/* household */}
                          <Typography
                            variant="body2"
                            className={classes.categoryHeadlineForDropdown}
                          >
                            {question.text}
                          </Typography>
                          <Field
                            name="household.value"
                            render={({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.household.answer.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) =>
                                  setFieldValue("household", {
                                    attributeId: attribute.id,
                                    answer: {
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  })
                                }
                                onBlur={() => {
                                  this.handleSetTransientObject(values);
                                  handleBlur("household.answer");
                                }}
                                required={true}
                                multiple={false}
                                disabled={isSubmitting}
                                error={touched.household && Boolean(errors.household)}
                              />
                            )}
                          />
                          {errors.household &&
                            (touched.household ? (
                              <Typography color="error" component="span">
                                {`${errors.household.answer!.selectedOptions}`}
                              </Typography>
                            ) : (
                              ""
                            ))}
                        </div>
                      );
                    }

                    if (attribute.name === "Haushaltseinkommen") {
                      const { question } = attribute;

                      return (
                        <div key={attribute.id}>
                          <Typography
                            variant="body2"
                            className={classes.categoryHeadlineForDropdown}
                          >
                            {question.text}
                          </Typography>
                          <Field
                            name="income.value"
                            render={({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.income.answer.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) => {
                                  setFieldValue("income", {
                                    attributeId: attribute.id,
                                    answer: {
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  });
                                }}
                                onBlur={() => {
                                  this.handleSetTransientObject(values);
                                  handleBlur("income.value");
                                }}
                                required={true}
                                multiple={false}
                                disabled={isSubmitting}
                                error={touched.income && Boolean(errors.income)}
                              />
                            )}
                          />
                          {errors.income && (
                            <Typography color="error" component="span">
                              {touched.income
                                ? `${errors.income.answer && errors.income.answer.selectedOptions}`
                                : ""}
                            </Typography>
                          )}
                        </div>
                      );
                    }

                    if (attribute.name === "Bildungsstatus") {
                      const { question } = attribute;

                      return (
                        <div key={attribute.id}>
                          {}
                          <Typography
                            variant="body2"
                            className={classes.categoryHeadlineForDropdown}
                          >
                            {question.text}
                          </Typography>
                          <Field
                            name="education.value"
                            render={({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.education.answer.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) =>
                                  setFieldValue("education", {
                                    attributeId: attribute.id,
                                    answer: {
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  })
                                }
                                onBlur={() => {
                                  this.handleSetTransientObject(values);
                                  handleBlur("education.value");
                                }}
                                required={true}
                                multiple={false}
                                disabled={isSubmitting}
                                error={touched.education && Boolean(errors.education)}
                              />
                            )}
                          />
                          {errors.education && (
                            <Typography color="error" component="span">
                              {touched.education
                                ? `${errors.education.answer!.selectedOptions}`
                                : ""}
                            </Typography>
                          )}
                        </div>
                      );
                    }

                    if (attribute.name === "Familienstand") {
                      const { question } = attribute;
                      return (
                        <div key={attribute.id}>
                          {/* family status */}
                          <Typography
                            variant="body2"
                            className={classes.categoryHeadlineForDropdown}
                          >
                            {question.text}
                          </Typography>
                          <Field
                            name="familyStatus.value"
                            render={({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.familyStatus.answer.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) =>
                                  setFieldValue("familyStatus", {
                                    attributeId: attribute.id,
                                    answer: {
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  })
                                }
                                onBlur={() => {
                                  this.handleSetTransientObject(values);
                                  handleBlur("familyStatus.value");
                                }}
                                required={true}
                                multiple={false}
                                disabled={isSubmitting}
                                error={touched.familyStatus && Boolean(errors.familyStatus)}
                              />
                            )}
                          />
                          {errors.familyStatus && (
                            <Typography color="error" component="span">
                              {touched.familyStatus
                                ? `${
                                    errors.familyStatus.answer &&
                                    errors.familyStatus.answer.selectedOptions
                                  }`
                                : ""}
                            </Typography>
                          )}
                        </div>
                      );
                    }

                    if (attribute.name === "Kinder") {
                      const { question } = attribute;
                      return (
                        <div key={attribute.id}>
                          {/* family status */}
                          <Typography
                            variant="body2"
                            className={classes.categoryHeadlineForDropdown}
                          >
                            {question.text}
                          </Typography>
                          <Field
                            name="children.value"
                            render={({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.children.answer.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) =>
                                  setFieldValue("children", {
                                    attributeId: attribute.id,
                                    answer: {
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  })
                                }
                                onBlur={() => {
                                  this.handleSetTransientObject(values);
                                  handleBlur("children.value");
                                }}
                                required={true}
                                multiple={false}
                                disabled={isSubmitting}
                                error={touched.children && Boolean(errors.children)}
                              />
                            )}
                          />
                          {errors.familyStatus && (
                            <Typography color="error" component="span">
                              {touched.familyStatus
                                ? `${
                                    errors.familyStatus.answer &&
                                    errors.familyStatus.answer.selectedOptions
                                  }`
                                : ""}
                            </Typography>
                          )}
                        </div>
                      );
                    }

                    if (index === 7) {
                      const { question } = attribute;
                      return (
                        <>
                          {/* family status */}
                          <Typography
                            variant="body2"
                            className={classes.categoryHeadlineForDropdown}
                          >
                            {this.panelAttributes.familyStatus.title}
                          </Typography>
                          <Field
                            name="familyStatus.value"
                            render={({ field }: any) => (
                              <AttributeSelect
                                {...field}
                                selectedValues={values.familyStatus.answer.selectedOptions}
                                selectOptions={question.answerOptions.map((option: any) => ({
                                  label: option.label,
                                  value: option.id,
                                }))}
                                handleSelection={(value) =>
                                  setFieldValue("familyStatus", {
                                    attributeId: attribute.id,
                                    answer: {
                                      selectedOptions: value,
                                      type: "choice",
                                    },
                                  })
                                }
                                onBlur={handleBlur("familyStatus.value")}
                                required={true}
                                multiple={false}
                                error={touched.familyStatus && Boolean(errors.familyStatus)}
                              />
                            )}
                          />
                          {errors.familyStatus && (
                            <Typography color="error" component="span">
                              {touched.familyStatus
                                ? `${errors.familyStatus.answer!.selectedOptions}`
                                : ""}
                            </Typography>
                          )}
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <Typography variant="body2" className={classes.categoryHeadline}>
                    {attention.title}
                  </Typography>
                  <FormControl component="fieldset" required={true} style={{ width: "100%" }}>
                    <RadioGroup
                      aria-label="howDidYouHearAboutUs.value"
                      name="howDidYouHearAboutUs"
                      value={values.howDidYouHearAboutUs.value}
                      onChange={handleChange("howDidYouHearAboutUs.value")}
                    >
                      {attention.answerOptions.map((answer) => (
                        <>
                          {answer.type === "radio" && (
                            <RadioElement
                              name="howDidYouHearAboutUs"
                              onChange={() => {
                                setFieldValue("howDidYouHearAboutUs", {
                                  value: answer.label,
                                  additionalInfo: "",
                                });
                              }}
                              value={answer.label}
                              key={answer.value}
                              label={answer.label}
                              className={classes.radio}
                              control={<Radio />}
                            />
                          )}
                          {answer.type === "withText" && (
                            <RadioElement
                              name="howDidYouHearAboutUs"
                              value={answer.label}
                              key={answer.value}
                              label={answer.label}
                              className={classes.radio}
                              control={<Radio />}
                              wrapperClass={classes.radioWithText}
                            >
                              <TextField
                                name={answer.value}
                                value={
                                  values.howDidYouHearAboutUs.value === answer.label
                                    ? values.howDidYouHearAboutUs.additionalInfo
                                    : ""
                                }
                                onChange={handleChange("howDidYouHearAboutUs.additionalInfo")}
                                placeholder={answer.placeholder || ""}
                              />
                            </RadioElement>
                          )}
                        </>
                      ))}
                    </RadioGroup>
                    {touched.howDidYouHearAboutUs &&
                      errors.howDidYouHearAboutUs &&
                      errors.howDidYouHearAboutUs.value && (
                        <Typography variant="body1" component="span" color="error">
                          {errors.howDidYouHearAboutUs.value}
                        </Typography>
                      )}
                  </FormControl>
                  <Typography variant="body2" className={classes.categoryHeadline}>
                    {usableForStudies.title}
                  </Typography>
                  <Typography variant="body1" className={classes.radioWithText}>
                    {usableForStudies.info}
                  </Typography>
                  <FormControl component="fieldset" required={true} style={{ width: "100%" }}>
                    <RadioGroup
                      aria-label=".value"
                      name="usableForQuotasStudies"
                      value={values.usableForQuotasStudies}
                      onChange={handleChange("usableForQuotasStudies")}
                    >
                      {usableForStudies.answerOptions.map((answer) => (
                        <>
                          {answer.type === "radio" && (
                            <RadioElement
                              name="usableForQuotasStudies"
                              onChange={() => {
                                setFieldValue("usableForQuotasStudies", answer.value);
                              }}
                              value={answer.value}
                              key={answer.value}
                              label={answer.label}
                              className={classes.radio}
                              control={<Radio />}
                            />
                          )}
                        </>
                      ))}
                    </RadioGroup>
                    {touched.usableForQuotasStudies &&
                      errors.usableForQuotasStudies &&
                      errors.usableForQuotasStudies && (
                        <Typography variant="body1" component="span" color="error">
                          {errors.usableForQuotasStudies}
                        </Typography>
                      )}
                  </FormControl>

                  <Typography variant="body1" className={classes.hint}>
                    {t(translations.register.participant.data.hint)}
                  </Typography>
                  <div className={classes.accountArea}>
                    {/* Account registration */}
                    <Typography variant="h3" color="primary" className={classes.headline}>
                      {t(translations.register.participant.account.headline)}
                    </Typography>
                    <Typography variant="subtitle1">
                      {t(translations.register.participant.account.description)}
                    </Typography>
                    {/* E-Mail */}
                    <Typography variant="body2" className={classes.categoryHeadline}>
                      {t(translations.register.loginDetails.eMail)}
                    </Typography>
                    <TextField
                      className={classes.bigInput}
                      name="email"
                      autoComplete="username"
                      placeholder={t(translations.register.loginDetails.eMail)}
                      error={touched.email && Boolean(errors.email)}
                      value={values.email}
                      onBlur={handleBlur("email")}
                      onChange={handleChange("email")}
                      required={true}
                      disabled={isSubmitting}
                      helperText={
                        errors.email && touched.email ? (
                          <Typography variant="body1" component="span">
                            {errors.email}
                          </Typography>
                        ) : (
                          ""
                        )
                      }
                    />
                    {/* Password */}
                    <Typography variant="body2" className={classes.categoryHeadline}>
                      {t(translations.login.passwordPlaceholder)}
                    </Typography>
                    <TextField
                      className={classes.bigInput}
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      placeholder={t(translations.login.passwordPlaceholder)}
                      onChange={handleChange("password")}
                      error={touched.password && Boolean(errors.password)}
                      value={values.password.trim()}
                      onBlur={handleBlur("password")}
                      required={true}
                      disabled={isSubmitting}
                      helperText={
                        errors.password ? (
                          <Typography variant="body1" component="span">
                            {errors.password}
                          </Typography>
                        ) : (
                          ""
                        )
                      }
                    />
                    {/* Password repeat */}
                    <Typography variant="body2" className={classes.categoryHeadline}>
                      {t(translations.register.loginDetails.repeatPassword)}
                    </Typography>
                    <TextField
                      className={classes.bigInput}
                      name="passwordRepeat"
                      type="password"
                      autoComplete="new-password"
                      placeholder={t(translations.register.loginDetails.repeatPassword)}
                      value={values.passwordRepeat}
                      onBlur={handleBlur("passwordRepeat")}
                      onChange={handleChange("passwordRepeat")}
                      disabled={isSubmitting}
                      required={true}
                      error={touched.passwordRepeat && Boolean(errors.passwordRepeat)}
                      helperText={
                        errors.passwordRepeat && touched.passwordRepeat ? (
                          <Typography variant="body1" component="span">
                            {errors.passwordRepeat}
                          </Typography>
                        ) : (
                          ""
                        )
                      }
                    />
                    <PasswordStrength password={values.password} />
                    <FormCheckbox
                      required={true}
                      label={
                        <span className={classes.label}>
                          {t(translations.register.loginDetails.privacy.titlePart1)}&nbsp;
                          <div onClick={() => this.toggleTerms()} className={classes.linkText}>
                            {t(translations.register.loginDetails.privacy.titleTermsConditions)}
                          </div>
                          &nbsp;{t(translations.register.loginDetails.privacy.titlePart2)}&nbsp;
                          <div onClick={() => this.togglePrivacy()} className={classes.linkText}>
                            {t(translations.register.loginDetails.privacy.titlePrivacy)}
                          </div>
                          &nbsp; {t(translations.register.loginDetails.privacy.titlePart3)}
                        </span>
                      }
                      answerValue="privacy"
                      checked={values.privacy}
                      key="privacy"
                      error={Boolean(errors.privacy)}
                      onChange={handleChange("privacy")}
                      onBlur={handleBlur("privacy")}
                      value={values.privacy}
                      helperText={errors.privacy && touched.privacy ? `${errors.privacy}` : ""}
                    />
                  </div>
                  <div className={classes.buttonWrapper}>
                    <Button
                      contained={true}
                      color="secondary"
                      fullWidth={true}
                      size="big"
                      className={classes.button}
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      type="submit"
                    >
                      {t(translations.action.saveAndRegister)}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect<{}, DispatchProps, OwnProps, RootState>(
    ({ session, uiState }: RootState) => ({
      transientRegistration: session.transientRegistration,
      registrationSteps: uiState.registrationSteps,
      registrationConfiguration:
        session.registrationConfiguration && session.registrationConfiguration,
    }),
    //@ts-ignore: something is weird here
    {
      //@ts-ignore
      registerUser,

      setRegistrationStep: uiStateActions.setRegistrationStep,
      setRegistrationMail: actions.setRegistrationMail,
      setTransientRegistrationObject: actions.setTransientRegistrationObject,
      removeTransientRegistrationObject: actions.removeTransientRegistrationObject,
    }
  )
)(RegistrationAttribute);
