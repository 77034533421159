import React from "react";
import { Route, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { RootState } from "../../modules";

interface OwnProps {
  render: any;
}

interface StateProps {
  isAuthenticated: boolean;
}
type Props = OwnProps & StateProps;

const PrivateRoute: React.FC<Props> = ({ render: renderRoute, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          renderRoute(props)
        ) : (
          <Redirect
            to={{
              pathname: "/participant/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = ({ session, user }: RootState) => ({
  isAuthenticated: Boolean(session.authenticated),
});

export default connect<StateProps, {}, OwnProps, RootState>(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(PrivateRoute);
