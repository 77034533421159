import * as React from "react";
import { PureComponent } from "react";
import { primary, QuopinionTheme } from "../../../constants/Theme";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";

import { compose } from "recompose";
import { withTranslation, WithTranslation } from "react-i18next";

import Header from "../../../common/Layout/Header";
import { IconButton, Modal, Typography } from "@material-ui/core";
import { translations } from "../../../constants/lang/translation";
import Button from "../../../common/Layout/components/Button";
import { DataGroup } from "./DataGroup";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import { Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import ProfileDataForm from "./ProfileDataForm";
import { RouteComponentProps, withRouter } from "react-router";
import Container from "../../../common/Layout/Container";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { fetchBasicAttributesAndPersonalData } from "../../../modules/user";
import {
  fetchRegisterConfiguration,
  unsubscribeSessionTokenAfterDelete,
} from "../../../modules/session";
import { PersonalData } from "../../../entities/User";
import Services from "../../../services/Services";
import { SnackbarComponent } from "../../../common/Layout/components/Snackbar";
import { SelfDeleteContent } from "./SelfDeleteModalContent";

interface OwnProps {}

interface DispatchProps {
  fetchBasicAttributesAndPersonalData: typeof fetchBasicAttributesAndPersonalData;
  fetchRegisterConfiguration: typeof fetchRegisterConfiguration;
  unsubscribeSessionTokenAfterDelete: typeof unsubscribeSessionTokenAfterDelete;
}

interface State {
  inEditMode: boolean;
  doubleCheckDeleteOpen: boolean;
  isLoadingDelete: boolean;
  showDeleteInfo: boolean;
}

interface StateProps {
  isLoading: boolean;
  personalData: PersonalData;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    title: {
      color: primary,
      marginBottom: theme.spacing(5),
    },
    button: {
      marginTop: theme.spacing(2),
    },
    editButtonWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    attributesWrapper: {
      backgroundColor: theme.palette.secondary.main,
      padding: `${theme.spacing(8)}px  ${theme.spacing(6)}px`,
    },
    description: {
      margin: `${theme.spacing(4)}px 0`,
    },
    attributesEdit: {
      position: "absolute",
      top: "16px",
      right: "16px",
      color: theme.palette.primary.main,
      "& svg": {
        height: 32,
        width: 32,
      },
    },
    contentWrapper: {
      margin: `${theme.spacing(2)}px`,
    },
    formWrapper: {
      padding: "40px 8.33% 16px",
      "@media (max-width: 768px)": {
        padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      },
    },
    accountDeleteWrapper: {
      padding: `${theme.spacing(8)}px  ${theme.spacing(6)}px`,
    },
    deleteModal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: theme.zIndex.tooltip + 1,
    },
    headline: {
      marginBottom: theme.spacing(2),
    },
    content: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.grey[50],
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 4),
    },
    buttonLine: {
      marginTop: theme.spacing(8),
      display: "flex",
      justifyContent: "space-between",
    },
  });

type Props = OwnProps &
  DispatchProps &
  WithTranslation &
  StateProps &
  WithStyles<typeof styles> &
  RouteComponentProps;

export const route = "/participant/profileData";

class ProfileDataPage extends PureComponent<Props, State> {
  state: State = {
    inEditMode: false,
    doubleCheckDeleteOpen: false,
    isLoadingDelete: false,
    showDeleteInfo: false,
  };

  constructor(props: Props) {
    super(props);
    this.props.fetchBasicAttributesAndPersonalData();
    this.props.fetchRegisterConfiguration();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  toggleEditMode = () => {
    window.scrollTo(0, 0);
    this.setState({
      inEditMode: !this.state.inEditMode,
    });
  };

  answerAttributes = () => {
    this.props.history.push("/participant/attributes");
  };

  modifyAttributes = () => {
    this.props.history.push("/participant/attributes/modify");
  };

  openDeleteModal = () => {
    this.setState({
      doubleCheckDeleteOpen: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      doubleCheckDeleteOpen: false,
    });
  };

  deleteParticipant = () => {
    this.setState({
      isLoadingDelete: true,
      doubleCheckDeleteOpen: false,
    });
    this.openDeleteInfoSnackbar();
    Services.accounts.selfDeleteCurrentParticipant().then(() => {
      this.props.unsubscribeSessionTokenAfterDelete();
    });
  };

  renderPauseAccount = (props: Props) => {
    const { t, classes } = props;
    return (
      <ContentWrapper blockDisplay={true}>
        <Container centered={true}>
          <div className={classes.contentWrapper}>
            <Typography variant="h2" className={classes.title}>
              {t(translations.participant.pauseAccount.headline)}
            </Typography>
            <Typography>{t(translations.participant.pauseAccount.infoText)}</Typography>

            <Button
              size="small"
              color="secondary"
              fullWidth={true}
              outlined={true}
              className={classes.button}
              /*   onClick={handleSubmit}
            disabled={isSubmitting}*/
            >
              {t(translations.participant.pauseAccount.pauseButton)}
            </Button>
            <Button
              size="small"
              color="secondary"
              fullWidth={true}
              className={classes.button}
              /*   onClick={handleSubmit}
            disabled={isSubmitting}*/
            >
              {t(translations.participant.pauseAccount.deregisterAccount)}
            </Button>
          </div>
        </Container>
      </ContentWrapper>
    );
  };

  renderDeleteAccount = (props: Props) => {
    const { t, classes } = props;
    return (
      <div className={classes.accountDeleteWrapper}>
        <Typography variant="h2" className={classes.title}>
          {t(translations.participant.deleteAccount.headline)}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {t(translations.participant.deleteAccount.infoText)}
        </Typography>
        <Button
          size="medium"
          color="secondary"
          fullWidth={true}
          onClick={this.openDeleteModal}
          outlined={true}
        >
          {t(translations.action.delete)}
        </Button>
      </div>
    );
  };

  renderAttributesPart = (props: Props) => {
    const { classes, t } = props;
    return (
      <div className={classes.attributesWrapper}>
        <Typography variant="h2" color="textSecondary">
          {t(translations.participant.screeners.modify.ctaHeadline)}
        </Typography>
        <Typography variant="body1" color="textSecondary" className={classes.description}>
          {t(translations.participant.screeners.modify.ctaDescription)}
        </Typography>
        <Button size="medium" color="primary" contained={true} onClick={this.answerAttributes}>
          {t(translations.action.personalizeNow)}
        </Button>
      </div>
    );
  };

  renderOpenDeleteModal = (props: Props) => {
    const { classes } = props;
    const { doubleCheckDeleteOpen, isLoadingDelete } = this.state;

    return (
      <Modal
        className={classes.deleteModal}
        aria-labelledby={"label"}
        aria-describedby={"description"}
        open={doubleCheckDeleteOpen}
        onClose={this.closeDeleteModal}
        closeAfterTransition={true}
      >
        <div className={classes.content}>
          <SelfDeleteContent
            closeDeleteModal={this.closeDeleteModal}
            deleteParticipant={this.deleteParticipant}
            isLoadingDelete={isLoadingDelete}
          />
        </div>
      </Modal>
    );
  };

  closeSnackbar = () => {
    this.setState({
      showDeleteInfo: false,
    });
  };

  openDeleteInfoSnackbar = () => {
    this.setState({
      showDeleteInfo: true,
    });
  };

  render() {
    const { t, classes, personalData } = this.props;
    const { inEditMode, isLoadingDelete, showDeleteInfo } = this.state;

    const editIcon = (
      <IconButton onClick={this.toggleEditMode}>
        <Edit />
      </IconButton>
    );

    if (this.props.isLoading) {
      return <LoadingOverlay />;
    }
    return (
      <>
        {showDeleteInfo && (
          <SnackbarComponent
            title={"Account löschen"}
            message={t(translations.participant.deleteAccount.confirmDelete)}
            type="info"
            onClose={() => {
              this.closeSnackbar();
            }}
          />
        )}

        <LogoBarParticipant backButton={true} hideLogo={true} />
        <Header
          headline={t(translations.participant.profile.yourProfile)}
          actionNode={!inEditMode && editIcon}
          hasLargeMarginBelowTitle={inEditMode}
        >
          {!inEditMode && (
            <>
              <DataGroup headline={t(translations.participant.profile.furtherData)}>
                <Typography>
                  {personalData.firstName} {personalData.lastName}
                </Typography>

                <Typography>{personalData.city}</Typography>
              </DataGroup>
            </>
          )}
        </Header>
        {/* Modify personal Attributes */}
        <div>
          {!inEditMode && this.renderAttributesPart(this.props)}
          {/* Profileform*/}
          {inEditMode && (
            <div className={classes.formWrapper}>
              <ProfileDataForm handleViewMode={this.toggleEditMode} />
            </div>
          )}
          {/*PauseAccount*/}
          {/*to add when functionality there, recommended to emphasize pause option*/}
          {/*ToDo add functionality to pause account, then comment in code part again*/}
          {/* {!inEditMode && this.renderPauseAccount(this.props)}*/}

          {/*DeleteAccount*/}
          {!inEditMode && this.renderDeleteAccount(this.props)}
          {this.renderOpenDeleteModal(this.props)}
          {isLoadingDelete && <LoadingOverlay />}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user }: RootState) => ({
  isLoading: user.isLoading,
  personalData: user.personalData,
});

const mapDispatchToProps = {
  fetchBasicAttributesAndPersonalData,
  fetchRegisterConfiguration,
  unsubscribeSessionTokenAfterDelete,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProfileDataPage);
