import ID from "./ID";
import AttributeQuestion from "./AttributeQuestion";

export enum ScreenerValueType {
  GOLD = "GOLD",
  SILVER = "SILVER",
  BRONZE = "BRONZE",
  BASIC = "BASIC",
}

const levelValues = ["BRONZE", "SILVER", "GOLD"] as const;
export type Level = typeof levelValues[number];

const categoryValues = ["home", "health", "freetime", "mobility", "technology"] as const;

export type ScreenerCategory = typeof categoryValues[number];

export class Screener {
  readonly id: ID;
  level: ScreenerValueType;
  questions: AttributeQuestion[];
  category: ScreenerCategory;
  title: string;
  isAnswered: boolean;
  isAvailable: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.level = obj.level || ScreenerValueType.BRONZE;
    this.questions = [new AttributeQuestion({ ...obj.question, attributeID: obj.id })] || [
      new AttributeQuestion(),
    ];
    this.category = obj.categories.length ? obj.categories[0].name : "home";
    this.title = obj.title;
    this.isAnswered = obj.isAnswered || false;
    this.isAvailable = obj.isAvailable || true;
  }
}
