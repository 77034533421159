import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";

import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import IntroductionForStep from "../../marketintelligence/SurveyCreator/IntroductionForStep";
import { translations } from "../../../constants/lang/translation";
import FAQResearcher from "../../../common/Landingpage/FAQResearcher";
import Container from "../../../common/Layout/Container";

interface OwnProps {}

type Props = OwnProps & WithTranslation;

export const route: string = "/market-research/faq";

class FAQResearcherPage extends PureComponent<Props> {
  render() {
    const { t } = this.props;
    const descriptionText = t(translations.pages.FAQ.researcher.introDescription, {
      email: '<a href="mailto:support@quopinion.de">support@quopinion.de</a>',
    });
    return (
      <>
        <LogoBarResearcher backButton={true} />
        <ContentWrapper>
          <IntroductionForStep
            title={t(translations.pages.FAQ.researcher.introHeadline)}
            description={descriptionText}
          />
          <Container centered={true}>
            <FAQResearcher whiteBackgroundColor={true} />
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation())(FAQResearcherPage);
