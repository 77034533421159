import React, { PureComponent } from "react";
import { Typography, createStyles, WithStyles, withStyles, TextField } from "@material-ui/core";
import { compose } from "recompose";
import { Formik } from "formik";
import * as Yup from "yup";

import Card from "../../../../common/Layout/components/Card";
import { QuopinionTheme } from "../../../../constants/Theme";
import { translations } from "../../../../constants/lang/translation";
import { WithTranslation, withTranslation } from "react-i18next";
import Button from "../../../../common/Layout/components/Button";
import PasswordStrength from "../../../../common/PasswordStrength";

import { saveResearcherData } from "../../../../modules/researcherProfile";
import { RootState } from "../../../../modules";
import Researcher from "../../../../entities/Researcher";
import { connect } from "react-redux";

export const route = "/market-research/personal-information";

interface OwnProps {
  researcherProfile: Researcher;
}

interface DispatchProps {
  saveResearcherData: (researcher: Researcher) => { type: string; researcher: Researcher };
}

interface StateProps {}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(6),
      padding: `${theme.spacing(6)}px ${theme.spacing(6)}px ${theme.spacing(8)}px`,
    },
    cardTitle: {
      marginBottom: theme.spacing(2),
      textTransform: "uppercase",
    },
    input: {
      marginTop: theme.spacing(4),
      width: "100%",
      "@media (min-width: 600px)": {
        "&:first-of-type": {
          marginRight: theme.spacing(5),
        },
      },
    },
    buttonLine: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
    },
  });

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles> & WithTranslation;

class EditLoginDataForm extends PureComponent<Props> {
  readonly initialValues = {
    ...this.props.researcherProfile,
    password: "",
    passwordRepeat: "",
  };

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      email: Yup.string()
        .trim()
        .email(t(translations.validation.login.emailNotValid))
        .required(t(translations.validation.login.emailRequired)),
      password: Yup.string().min(8, t(translations.validation.login.passwordMinLength)),
      passwordRepeat: Yup.string().oneOf(
        [Yup.ref("password"), ""],
        t(translations.register.loginDetails.passwordRepeatRequired)
      ),
    });
  };

  handleChange(handleChange: (field: string) => (value: string) => void, field: string) {
    return (values: string[]) => {
      if (values.length) {
        handleChange(field)(values[0]);
      }
    };
  }

  render() {
    const { classes, t, saveResearcherData } = this.props;
    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={this.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          saveResearcherData(new Researcher(values));
          setSubmitting(false);
        }}
      >
        {(formikProps) => {
          const {
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting,
          } = formikProps;
          return (
            <form>
              <Card isRaised={true} className={classes.card}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.register.loginDetails.headline)}
                </Typography>
                <TextField
                  value={values.email}
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email ? `${errors.email}` : ""}
                  placeholder={t(
                    translations.pages.landingpage.researcher.contact.placeholderEmail
                  )}
                  className={classes.input}
                />
                <TextField
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder={t(translations.login.passwordPlaceholder)}
                  className={classes.input}
                  onChange={handleChange("password")}
                  error={touched.password && Boolean(errors.password)}
                  value={values.password}
                  onBlur={handleBlur("password")}
                  required={false}
                  helperText={
                    errors.password ? (
                      <Typography variant="body1">{errors.password}</Typography>
                    ) : (
                      ""
                    )
                  }
                />
                <TextField
                  name="passwordRepeat"
                  type="password"
                  autoComplete="new-password"
                  placeholder={t(translations.register.loginDetails.repeatPassword)}
                  className={classes.input}
                  value={values.passwordRepeat}
                  onBlur={handleBlur("passwordRepeat")}
                  onChange={handleChange("passwordRepeat")}
                  required={false}
                  error={touched.passwordRepeat && Boolean(errors.passwordRepeat)}
                  helperText={
                    errors.passwordRepeat && touched.passwordRepeat ? (
                      <Typography variant="body1">{errors.passwordRepeat}</Typography>
                    ) : (
                      ""
                    )
                  }
                />
                <PasswordStrength password={values.password} />
              </Card>
              <div className={classes.buttonLine}>
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  color="secondary"
                  size="big"
                  contained={true}
                  type="submit"
                >
                  {t(translations.action.save)}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  saveResearcherData,
};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    mapStateToProps,
    mapDispatchToProps
  )
)(EditLoginDataForm);
