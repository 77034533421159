import React, { PureComponent } from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import i18next from "i18next";
import moment from "moment";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import Button from "../../../common/Layout/components/Button";
import { Plus, Lock } from "../../../assets/icon";
import { translations } from "../../../constants/lang/translation";
import AccountSelect from "./components/AccountButton";
import { QuopinionTheme } from "../../../constants/Theme";
// import Introduction from "../../marketintelligence/SurveyCreator/IntroductionForStep";
import Dashboard from "./Dashboard";
import SurveyBoard from "./SurveyBoard";
import { route as SurveyCreatorRoute } from "../SurveyCreator";
// import DetailedInformation from "./components/DetailedInformation";
import {
  fetchUser,
  fetchCurrentResearcher,
  fetchCurrentResearcherSurveys,
} from "../../../modules/user";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { actions } from "../../../modules/survey";
import { withAuthorization } from "../../../common/hoc/withAuthorization";
import { UserRole } from "../../../entities/User";
import { SurveyInformation } from "../../../entities/Survey";

export const route = "/market-research/dashboard";
export const stepRoute = "/market-research/dashboard/:step";

export const Links = [
  {
    title: i18next.t(translations.pages.dashboard.researcher.links.dashboard),
    link: route,
  },
  {
    title: i18next.t(translations.pages.dashboard.researcher.links.surveys),
    link: `${route}/surveys`,
  },
];

export const surveyDateComparator = {
  /**
   * Returns a Comparator function that can be used to sort surveys by start date (a smaller index means newer date)
   * @param {SurveyInformation} a
   * @param {SurveyInformation} b
   * @returns {boolean}
   */
  compareByStartDate: (a: SurveyInformation, b: SurveyInformation) =>
    moment(a.startDate).isAfter(b.startDate)
      ? -1
      : moment(b.startDate).isAfter(a.startDate)
      ? 1
      : 0,
  /**
   * Returns a Comparator function that can be used to sort surveys by createdAt data (a smaller index means newer date)
   * @param {SurveyInformation} a
   * @param {SurveyInformation} b
   * @returns {boolean}
   */
  compareByCreatedAtDate: (a: SurveyInformation, b: SurveyInformation) =>
    moment(a.createdAt).isAfter(b.createdAt)
      ? -1
      : moment(b.createdAt).isAfter(a.createdAt)
      ? 1
      : 0,
};

interface State {
  showIntroduction: boolean;
}

interface OwnProps {}

interface DispatchProps {
  fetchUser: () => Promise<void>;
  fetchCurrentResearcher: () => Promise<void>;
  fetchCurrentResearcherSurveys: () => Promise<void>;
  resetSurveyStore: typeof actions.resetSurveyStore;
}

interface StateProps {
  isAuthenticated: boolean | undefined;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    closeButton: {
      height: 50,
      width: 50,
      position: "absolute",
      right: 0,
      "@media(max-width: 375px)": {
        top: "-309px",
      },
      "@media(min-width: 411px)": {
        top: "-306px",
      },
      "@media(min-width: 414px)": {
        top: "-302px",
      },
      "@media(min-width: 768px)": {
        top: "-220px",
      },
      "@media(min-width: 769px)": {
        top: "-38px",
      },
    },
    customLogobar: {
      display: "flex",
    },
    details: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(4),
    },
    detailNumber: {
      fontSize: "32px",
      marginRight: theme.spacing(7),
    },
    headline: {
      marginBottom: theme.spacing(3),
    },
    introductionCustom: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      paddingTop: theme.spacing(3),
    },
    linkText: {
      textDecoration: "none",
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{ step: string }>;

class DashboardResearcher extends PureComponent<Props, State> {
  state: State = {
    showIntroduction: true,
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.fetchUser();
      this.props.fetchCurrentResearcher();
      this.props.fetchCurrentResearcherSurveys();
    }
  }

  componentWillUnmount() {
    this.props.resetSurveyStore();
  }

  // TODO: save hide somewhere so researcher doesn´t have to see it again
  // Local storage?
  hideIntroduction = () => {
    this.setState({
      showIntroduction: false,
    });
  };

  goToSurveyCreator = () => {
    this.props.history.push(SurveyCreatorRoute);
  };

  renderLogoBarCustomArea = (props: Props) => {
    const { t, classes } = this.props;
    return (
      <div className={classes.customLogobar}>
        <Button
          onClick={this.goToSurveyCreator}
          contained={true}
          color="secondary"
          size="medium"
          icon={<Plus fill="#fff" />}
          style={{ marginRight: 16 }}
        >
          {t(translations.action.newSurvey)}
        </Button>
        {this.props.isAuthenticated ? (
          <AccountSelect />
        ) : (
          <Button
            onClick={() => this.props.history.push("/market-research/login")}
            contained={true}
            color="primary"
            size="medium"
            icon={<Lock fill="#fff" height="24" width="24" />}
          >
            {t(translations.action.login)}
          </Button>
        )}
      </div>
    );
  };

  // this.props.history.push(researcherLoginPageRoute);

  renderContent() {
    switch (this.props.match.params.step) {
      case "dashboard":
        return <Dashboard />;
      case "surveys":
        return <SurveyBoard />;
      default:
        return <Dashboard />;
    }
  }

  render() {
    return (
      <>
        <LogoBarResearcher links={Links} children={this.renderLogoBarCustomArea(this.props)} />
        {this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = ({ session }: RootState) => ({
  isAuthenticated: session.authenticated,
});

const mapDispatchToProps = {
  fetchUser,
  fetchCurrentResearcher,
  fetchCurrentResearcherSurveys,
  resetSurveyStore: actions.resetSurveyStore,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  withAuthorization(UserRole.RESEARCHER),
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(DashboardResearcher);
