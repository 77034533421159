import React, { PureComponent } from "react";
import { createStyles, WithStyles, withStyles, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";

import { translations } from "../../../../constants/lang/translation";
import { green, QuopinionTheme, red } from "../../../../constants/Theme";

import Button from "../../../../common/Layout/components/Button";
import Card from "../../../../common/Layout/components/Card";
import Attribute from "../../../../entities/Attribute";
import { withRouter, RouteComponentProps } from "react-router";
import { route as attributeFormRoute } from "../AttributeForm";
import moment from "moment";

interface State {}

interface OwnProps {
  attribute: Attribute;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    customLogobar: {
      display: "flex",
    },
    headline: {
      marginBottom: theme.spacing(4),
    },
    card: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: theme.spacing(6),
    },
    cardTitle: {
      textTransform: "uppercase",
    },
    cardRow: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: theme.spacing(6),
      },
    },
    date: {
      textAlign: "right",
      display: "flex",
      justifyContent: "flex-end",
      fontSize: "12px",
      marginBottom: theme.spacing(1.5),
    },
    activationStatus: (props:Props) => ({
      backgroundColor: props.attribute.enabled ? green : red,
      borderRadius: 50,
      display: "flex",
      height: 20,
      width: 20,
    }),
    detailsWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end"
    } ,
    attributeText:{
      maxWidth: 555,
    }
  });

type Props = OwnProps & WithTranslation & RouteComponentProps & WithStyles<typeof styles>;

class AttributeCard extends PureComponent<Props, State> {
  render() {
    const {
      classes,
      t,
      attribute,
      history: { push },
    } = this.props;

    return (
      <Card className={classes.card}>
        <div>
          <Typography variant="body2" className={classes.cardTitle}>
            {attribute.name}
          </Typography>
          <Typography variant="body2" className={classes.attributeText}>{attribute.question.text}</Typography>
        </div>
        <div>
        <div className={classes.detailsWrapper}>
          <div className={classes.activationStatus}/>
          <div className={classes.date}>{moment(attribute.createdAt).format("DD.MM.YYYY")}</div>
        </div>
          <Button
            size="small"
            color="primary"
            contained={true}
            onClick={() => push(attributeFormRoute + attribute.id)}
          >
            {t(translations.action.modify)}
          </Button>
        </div>
      </Card>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withRouter,
  withStyles(styles)
)(AttributeCard);
