import React, { PureComponent } from "react";
import { QuopinionTheme } from "../../../../constants/Theme";
import { createStyles, WithStyles, withStyles, Typography, IconButton } from "@material-ui/core";
import { compose } from "recompose";
import { DropdownArrowGrey } from "../../../../assets/icon";
import i18next from "i18next";
import { translations } from "../../../../constants/lang/translation";
import Button from "../../../../common/Layout/components/Button";
import { route as AccountRoute } from "../../Account";
import { WithTranslation, withTranslation } from "react-i18next";
import Researcher from "../../../../entities/Researcher";
import { RootState } from "../../../../modules";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import classNames from "classnames";
import { unsubscribeSessionToken, requestSessionToken } from "../../../../modules/session";
import { route as researcherLoginPageRoute } from "../../Login/LoginPage";
import { route as adminDashboardRoute } from "../../../admin/Dashboard";

interface OwnProps {}

interface DispatchProps {
  unsubscribeSessionToken: typeof unsubscribeSessionToken;
  requestSessionToken: typeof requestSessionToken;
}

interface StateProps {
  currentResearcher: Researcher;
  loggedIn: boolean;
}

interface State {
  showMenu: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      border: `1px solid ${theme.palette.secondary.main}`,
      minWidth: theme.spacing(30),
      borderRadius: 4,
      paddingRight: theme.spacing(10),
      paddingLeft: theme.spacing(4),
      display: "flex",
      alignItems: "center",
      position: "relative",
      marginLeft: theme.spacing(4),
      padding: theme.spacing(3),
    },
    icon: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      right: 0,
      pointerEvents: "none",
      "& svg": {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
      "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
        top: "4px",
      },
    },
    menu: {
      backgroundColor: "#fff",
      border: `1px solid ${theme.palette.secondary.main}`,
      borderTop: "none",
      position: "absolute",
      top: "94%",
      right: "-1px",
      width: "calc(100% + 2px)",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      borderRadius: "0 0 4px 4px",
    },
    menuItem: {
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      color: "#000",
      fontWeight: 400,
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.secondary.main,
      },
      "&:active": {
        color: theme.palette.secondary.main,
      },
    },
    logoutButton: {
      margin: `${theme.spacing(0)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    openedIcon: {
      transform: "rotate(180deg)",
    },
    name: {
      marginRight: theme.spacing(4),
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps;

const menuPoints = [
  {
    title: i18next.t(translations.pages.dashboard.researcher.details),
    link: AccountRoute,
  },
];

class AccountSelect extends PureComponent<Props, State> {
  state: State = {
    showMenu: false,
  };

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  handleLogOutUser = () => {
    const { unsubscribeSessionToken } = this.props;
    unsubscribeSessionToken();
    if (this.props.match.url.includes("admin")) {
      this.props.history.push(researcherLoginPageRoute);
    }
  };
  handleLogInUser = () => {
    // const { unsubscribeSessionToken } = this.props;
    // unsubscribeSessionToken();
    this.props.history.push(researcherLoginPageRoute);
  };

  render() {
    const { classes, t, currentResearcher } = this.props;
    const { showMenu } = this.state;
    return (
      <div className={classes.root} onClick={this.toggleMenu}>
        <Typography className={classes.name} id={"fullName"}>
          {currentResearcher.firstName.charAt(0)}. {currentResearcher.lastName}
        </Typography>
        <div className={classes.icon}>
          <IconButton
            className={classNames({
              [classes.openedIcon]: showMenu,
            })}
          >
            <DropdownArrowGrey />
          </IconButton>
        </div>
        {showMenu && (
          <div className={classes.menu}>
            {currentResearcher.isAdmin() && (
              <Link className={classes.menuItem} to={adminDashboardRoute}>
                Admin
              </Link>
            )}
            {menuPoints.map((point) => (
              <Link key={point.title} className={classes.menuItem} to={point.link}>
                {point.title}
              </Link>
            ))}
            {this.props.loggedIn ? (
              <Button
                size="small"
                color="secondary"
                contained={true}
                className={classes.logoutButton}
                onClick={this.handleLogOutUser}
              >
                {t(translations.action.logout)}
              </Button>
            ) : (
              <Button
                size="small"
                color="secondary"
                contained={true}
                className={classes.logoutButton}
                onClick={this.handleLogInUser}
              >
                {t(translations.action.login)}
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user, session }: RootState) => ({
  currentResearcher: user.currentResearcher,
  loggedIn: Boolean(session.token),
});

const mapDispatchToProps = {
  unsubscribeSessionToken,
  requestSessionToken,
};

export default compose<Props, OwnProps>(
  withRouter,
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AccountSelect);
