import React, { ChangeEvent, PureComponent } from "react";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import {
  CircularProgress,
  createStyles,
  Grid,
  MenuItem,
  Select,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";
import SurveyCard from "./components/SurveyCard";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import Survey, {
  SurveyAdminInformation,
  SurveyInformation,
  SurveyStatus,
} from "../../../entities/Survey";
import Panel from "../../../entities/Panel";
import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import ModalComponent from "../../../common/Layout/components/Modal";
import PanelSummary from "../SurveyCreator/StepSummary/PanelSummary";
import Card from "../../../common/Layout/components/Card";
import SurveyResults from "./Result";
import { RouteComponentProps, withRouter } from "react-router";
import {
  acceptReleaseOfSurvey,
  actions,
  actions as surveyActions,
  denyReleaseOfSurvey,
  fetchPanelConfiguration,
  fetchSurveyResults,
  fetchTransientSurvey,
  publishTransientSurvey,
} from "../../../modules/survey";
import { fetchCurrentResearcherSurveys } from "../../../modules/user";
import ID from "../../../entities/ID";
import Button from "../../../common/Layout/components/Button";
import { translations } from "../../../constants/lang/translation";
import { route as ResearcherDashboardRoute } from "../Dashboard";
import User from "../../../entities/User";
import DenyForm from "./DenyForm";
import { Link } from "react-router-dom";
import PriceSummary from "../SurveyCreator/StepSummary/PriceSummary";
import QuestionnairePreview from "../../participant/Questionnaire/QuestionnairePreview";
import { resetPreviewAnswers } from "../../../modules/surveyPreview";
import SurveyAnswers from "../../../entities/SurveyAnswers";
import Tooltip from "../../../common/Layout/components/Tooltip";
import { fetchSurveyInvitationTemplates } from "../../../modules/mailTemplates";
import * as Icons from "../../../assets/icon";

interface OwnProps {}

interface State {
  error: boolean;
  previewOpen: boolean;
  showDesktopPreview: boolean;
  selectedInvitationTemplate: string;
}

interface DispatchProps {
  fetchSurveyResults: (id: ID) => Promise<void>;
  fetchTransientSurvey: (id: ID) => Promise<void>;
  fetchCurrentResearcherSurveys: () => Promise<void>;
  resetSurveyStore: () => void;
  acceptReleaseOfSurvey: (id: ID, invitationTemplateName: string) => Promise<void>;
  publishTransientSurvey: () => Promise<void>;
  denyReleaseOfSurvey: (id: ID) => Promise<void>;
  fetchSurveyInformation: (id: ID) => Promise<void>;
  fetchPanelConfiguration: () => Promise<void>;
  resetPreviewAnswers: () => void;
  fetchSurveyInvitationTemplates: () => Promise<void>;

  /// ///
  setGenderAttributeId: typeof surveyActions.setGenderAttributeId;
  setAgeAttributeId: typeof surveyActions.setAgeAttributeId;
  setHouseholdSizeAttributeId: typeof surveyActions.setHouseholdSizeAttributeId;
  setIncomeAttributeId: typeof surveyActions.setIncomeAttributeId;
  setCountyAttributeId: typeof surveyActions.setCountyAttributeId;
  setStateAttributeId: typeof surveyActions.setStateAttributeId;
  setTouristAreaAttributeId: typeof surveyActions.setTouristAreaAttributeId;
  setPopulationDensityAttributeId: typeof surveyActions.setPopulationDensityAttributeId;
  setLeitregionAttributeId: typeof surveyActions.setLeitregionAttributeId;
  setEducationAttributeId: typeof surveyActions.setEducationAttributeId;
  setChildrenAttributeId: typeof surveyActions.setChildrenAttributeId;
  setFamilyStatusId: typeof surveyActions.setFamilyStatusAttributeId;
}

interface StateProps {
  panel: Panel;
  survey: Survey;
  panelConfiguration: [];
  answerDictionary: { [key: string]: string };
  isLoading: boolean;
  researcherSurveys: SurveyInformation[];
  isLoadingSurveys: boolean;
  surveyId: ID;
  user: User;
  additionalInformation?: SurveyAdminInformation;
  isPublishing: boolean;
  previewAnswers: SurveyAnswers;
  surveyInvitationTemplates: String[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    details: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(4),
    },
    detailNumber: {
      fontSize: "32px",
      marginRight: theme.spacing(7),
    },
    headline: {
      marginBottom: theme.spacing(3),
    },
    introductionCustom: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      paddingTop: theme.spacing(3),
    },
    furtherLink: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(4),
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      paddingBottom: theme.spacing(10),
    },
    reasonText: {
      marginBottom: theme.spacing(4),
    },
    questionnaireButtonWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      marginBottom: theme.spacing(4),
    },
    link: {
      textDecoration: "none",
    },
    surveyCardLoadingWrapper: {
      display: "flex",
      justifyContent: "center",
      height: theme.spacing(48),
    },
    previewButton: {
      marginLeft: theme.spacing(4.5),
    },
    previewModal: {
      outline: "none",
    },
    acceptanceSelections: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    selectRoot: {
      marginBottom: theme.spacing(13),
      width: "100%",
      backgroundColor: "fff",
      "& svg": {
        width: 50,
        top: 1,
        right: 0,
        borderLeft: "1px solid",
        borderColor: theme.palette.grey[100],
        position: "absolute",
        height: "calc(100% - 2px)",
        padding: theme.spacing(3),
        pointerEvents: "none",
      },
    },
    templateSelect: {
      paddingTop: theme.spacing(5.5),
      paddingBottom: theme.spacing(5.75),
      paddingRight: theme.spacing(12),
      paddingLeft: theme.spacing(4),
      fontSize: "14px",
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{ surveyID: ID }>;

export const route: string = "/market-research/survey-details/:surveyID";

class DetailedSurveyBoard extends PureComponent<Props, State> {
  state: State = {
    error: false,
    previewOpen: false,
    showDesktopPreview: false,
    selectedInvitationTemplate: "survey-invitation",
  };

  constructor(props: Props) {
    super(props);

    if (!Boolean(this.props.panelConfiguration.length)) {
      this.props.fetchPanelConfiguration().then(() => {
        this.setAttributeIds();
      });
    }
    if (this.props.user.isAdmin()) {
      this.props.fetchSurveyInvitationTemplates();
    }
  }

  componentWillUnmount = () => {
    this.props.resetSurveyStore();
  };

  componentDidMount = async () => {
    const {
      match,
      fetchSurveyResults,
      fetchTransientSurvey,
      fetchCurrentResearcherSurveys,
      history,
    } = this.props;
    const id = match.params.surveyID;
    try {
      await fetchTransientSurvey(id);
      await fetchSurveyResults(id);
    } catch (err) {
      if (err.error !== undefined && err.error.message === "Forbidden") {
        history.push("/login");
      }
    }
    if (!Boolean(this.props.researcherSurveys.length)) {
      await fetchCurrentResearcherSurveys();
    }
  };

  setAttributeIds() {
    const { panelConfiguration, panel } = this.props;

    panelConfiguration.forEach((item: any) => {
      if (item.name === "Geschlecht" && !panel.gender.attributeId) {
        this.props.setGenderAttributeId(item.id);
      }
      if (item.name === "Alter" && !panel.age.attributeId) {
        this.props.setAgeAttributeId(item.id);
      }
      if (item.name === "Haushaltsgröße" && !panel.household.attributeId) {
        this.props.setHouseholdSizeAttributeId(item.id);
      }
      if (item.name === "Haushaltseinkommen" && !panel.income.attributeId) {
        this.props.setIncomeAttributeId(item.id);
      }
      if (item.name === "Bundesland" && !panel.state.attributeId) {
        this.props.setStateAttributeId(item.id);
      }
      if (item.name === "Kreis" && !panel.county.attributeId) {
        this.props.setCountyAttributeId(item.id);
      }
      if (item.name === "Reisegebiet" && !panel.touristArea.attributeId) {
        this.props.setTouristAreaAttributeId(item.id);
      }
      if (item.name === "Bevölkerungsdichte" && !panel.populationDensity.attributeId) {
        this.props.setPopulationDensityAttributeId(item.id);
      }
      if (item.name === "Leitregion" && !panel.leitregion.attributeId) {
        this.props.setLeitregionAttributeId(item.id);
      }
      if (item.name === "Bildungsstatus" && !panel.education.attributeId) {
        this.props.setEducationAttributeId(item.id);
      }
      if (item.name === "Familienstand" && !panel.family.attributeId) {
        this.props.setFamilyStatusId(item.id);
      }
      if (item.name === "Kinder" && !panel.children.attributeId) {
        this.props.setChildrenAttributeId(item.id);
      }
    });
  }

  handleAcceptSurveyClick = () => {
    const { acceptReleaseOfSurvey, surveyId } = this.props;
    const { selectedInvitationTemplate } = this.state;
    acceptReleaseOfSurvey(surveyId, selectedInvitationTemplate)
      .then(() => {
        this.props.history.push(ResearcherDashboardRoute); //only as far as direct accept and publish
        this.setState({
          error: false,
        });
      })
      .catch((err: any) => {
        this.setState({
          error: true,
        });
      });
  };

  openPreview = () => {
    this.setState({
      previewOpen: true,
    });
  };

  closePreview = () => {
    this.props.resetPreviewAnswers();
    this.setState({
      previewOpen: false,
    });
  };

  switchModalMode = () => {
    this.setState({
      showDesktopPreview: !this.state.showDesktopPreview,
    });
  };

  setTemplateChoice = (selectedTemplateName: string) => {
    this.setState({
      selectedInvitationTemplate: selectedTemplateName,
    });
  };

  renderSurveyPreview() {
    return (
      <QuestionnairePreview
        questionnaire={this.props.survey.questionnaire}
        handleClose={this.closePreview}
        currentAnswers={this.props.previewAnswers}
        showDesktopPreview={this.state.showDesktopPreview}
      />
    );
  }

  renderInvitationTemplateSelect() {
    const { classes } = this.props;
    return (
      <Select
        value={this.state.selectedInvitationTemplate}
        onChange={(event: ChangeEvent<any>) => {
          this.setTemplateChoice(event.target.value);
        }}
        className={classes.selectRoot}
        classes={{
          select: classes.templateSelect,
        }}
        IconComponent={Icons.DropdownArrowGrey}
      >
        {this.props.surveyInvitationTemplates.map((invitationTemplate) => {
          return (
            <MenuItem key={invitationTemplate.toString()} value={invitationTemplate.toString()}>
              {invitationTemplate.toString()}
            </MenuItem>
          );
        })}
      </Select>
    );
  }

  render() {
    const {
      panel,
      researcherSurveys,
      isLoadingSurveys,
      match,
      t,
      classes,
      survey,
      user,
      additionalInformation,
      isPublishing,
      surveyInvitationTemplates,
    } = this.props;
    const surveyInfo = researcherSurveys.find((survey) => survey.id === match.params.surveyID);
    const { previewOpen } = this.state;
    return (
      <>
        <LogoBarResearcher backButton={true} />
        <ContentWrapper>
          <Container centered={true}>
            {surveyInfo ? (
              <SurveyCard
                survey={surveyInfo}
                status={surveyInfo.status}
                withDetailsButton={false}
                additionalInformation={additionalInformation}
              />
            ) : (
              isLoadingSurveys && (
                <div className={classes.surveyCardLoadingWrapper}>
                  <CircularProgress color="secondary" />
                </div>
              )
            )}

            {/*questions of questionnaire  */}
            <SurveyResults urlSurveyId={match.params.surveyID} />
            {user.isAdmin() && (
              <div className={classes.questionnaireButtonWrapper}>
                {survey.status === SurveyStatus.PUBLISH_REQUESTED && (
                  <>
                    <Tooltip
                      title={t(translations.pages.Survey.Admin.adminChanges.tooltip).toString()}
                      aria-label={"admin-questionnaire-edit"}
                    >
                      <Link
                        to={`/market-research/survey-creator/panel/${this.props.surveyId}`}
                        className={classes.link}
                      >
                        <Button color={"secondary"} contained={true} size={"big"}>
                          {t(translations.action.questionnaireDetails)}
                        </Button>
                      </Link>
                    </Tooltip>
                    <Button
                      color={"secondary"}
                      contained={true}
                      size={"big"}
                      onClick={this.openPreview}
                      className={classes.previewButton}
                    >
                      {t(translations.action.preview)}
                    </Button>
                  </>
                )}
              </div>
            )}

            <Grid container={true} spacing={5}>
              <Grid item={true} xs={12} sm={6}>
                <Card isRaised={false}>
                  <PanelSummary
                    modificationOption={false}
                    panel={panel}
                    panelConfiguration={this.props.answerDictionary}
                  />
                </Card>
              </Grid>
              <Grid item={true} xs={12} sm={6}>
                <Card isRaised={false}>
                  <PriceSummary survey={survey} />
                </Card>
              </Grid>
            </Grid>
            {user.isAdmin() && (
              <div className={classes.buttonWrapper}>
                {survey.status === SurveyStatus.PUBLISH_REQUESTED && (
                  <>
                    <div className={classes.acceptanceSelections}>
                      {surveyInvitationTemplates.length > 0 ? (
                        this.renderInvitationTemplateSelect()
                      ) : (
                        <div>
                          {" "}
                          {t(translations.pages.Survey.Admin.templateChoice.noTemplatesAvailable)}
                        </div>
                      )}
                      <Button
                        color={"green"}
                        contained={true}
                        size={"big"}
                        onClick={this.handleAcceptSurveyClick}
                        disabled={isPublishing}
                      >
                        {t(translations.action.acceptAndPublishSurvey)}
                      </Button>
                    </div>
                    {this.state.error && (
                      <Typography> {t(translations.general.anErrorOccured)}</Typography>
                    )}
                    <div>
                      <DenyForm isPublishing={isPublishing} />
                    </div>
                  </>
                )}
              </div>
            )}
          </Container>
        </ContentWrapper>

        <ModalComponent
          label="question-preview"
          description="open preview for survey"
          open={previewOpen}
          onClose={this.closePreview}
          isPreview={true}
          showArrow={false}
          className={classes.previewModal}
          showDesktopPreview={this.state.showDesktopPreview}
          onSwitchModalMode={this.switchModalMode}
          allowDesktopSwitch={true}
        >
          {previewOpen && this.renderSurveyPreview()}
        </ModalComponent>
      </>
    );
  }
}

const mapStateToProps = ({ survey, user, surveyPreview, mailTemplates }: RootState) => ({
  survey,
  panel: survey.panel,
  panelConfiguration: survey.panelConfiguration,
  answerDictionary: survey.answerDictionary,
  isLoading: survey.isLoading,
  researcherSurveys: user.researcherSurveys,
  surveyId: survey.id,
  user: user.current,
  isLoadingSurveys: user.isLoadingSurveys,
  additionalInformation: survey.additionalInformation,
  isPublishing: survey.isPublishing,
  previewAnswers: surveyPreview.surveyAnswers,
  surveyInvitationTemplates: mailTemplates.surveyInvitation,
});

const mapDispatchToProps = {
  fetchSurveyResults,
  fetchTransientSurvey,
  fetchPanelConfiguration,
  fetchCurrentResearcherSurveys,
  resetSurveyStore: actions.resetSurveyStore,
  acceptReleaseOfSurvey,
  publishTransientSurvey,
  denyReleaseOfSurvey,
  resetPreviewAnswers,
  fetchSurveyInvitationTemplates,

  /// ///
  setAgeAttributeId: surveyActions.setAgeAttributeId,
  setGenderAttributeId: surveyActions.setGenderAttributeId,
  setHouseholdSizeAttributeId: surveyActions.setHouseholdSizeAttributeId,
  setIncomeAttributeId: surveyActions.setIncomeAttributeId,
  setCountyAttributeId: surveyActions.setCountyAttributeId,
  setStateAttributeId: surveyActions.setStateAttributeId,
  setTouristAreaAttributeId: surveyActions.setTouristAreaAttributeId,
  setPopulationDensityAttributeId: surveyActions.setPopulationDensityAttributeId,
  setLeitregionAttributeId: surveyActions.setLeitregionAttributeId,
  setEducationAttributeId: surveyActions.setEducationAttributeId,
  setChildrenAttributeId: surveyActions.setChildrenAttributeId,
  setFamilyStatusId: surveyActions.setFamilyStatusAttributeId,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect<{}, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withRouter
)(DetailedSurveyBoard);
