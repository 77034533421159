import React from "react";
import {
  LineChart as LineChartImport,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts";
import { NumberDataItem } from "./QuestionResultItem";
import { QuestionBody } from "../../../../../entities/Question";

interface Props {
  data: NumberDataItem[];
  scale: QuestionBody["scale"];
  labels: QuestionBody["labels"];
}

const LineChart: React.FC<Props> = ({ data, scale, labels }: Props) => {
  interface TickProps {
    x: number;
    y: number;
    payload: any;
  }

  const CustomizedAxisTick: React.FC<TickProps> = ({ x, y, payload }: TickProps) => {
    if (payload.value === scale.min && labels.left) {
      payload.value = `${payload.value} = ${
        labels.left.length > 10 ? labels.left.substr(0, 7) + "..." : labels.left
      }`;
    }
    if (payload.value === scale.max && labels.right) {
      payload.value = `${payload.value} = ${
        labels.right.length > 10 ? labels.right.substr(0, 7) + "..." : labels.right
      }`;
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={10}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          style={{ overflow: "visible" }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChartImport data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          domain={[scale.min, scale.max]}
          interval={0}
          //@ts-ignore
          tick={()=>CustomizedAxisTick}
          height={100}
          type="number"
        />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="value" stroke="#0b3b5f" activeDot={{ r: 8 }} />
      </LineChartImport>
    </ResponsiveContainer>
  );
};

export default LineChart;
