import React from "react";
import { Tooltip } from "@material-ui/core";

interface OwnProps {
  x: number;
  y: number;
  payload: any;
}

type Props = OwnProps;

const YAxisValueTooltip: React.FC<Props> = ({ x, y, payload }: Props) => {
  let value = payload.value as string;
  let valueShort = value.length > 10 ? value.substr(0, 7) + "..." : value;

  return (
    <Tooltip title={value}>
      <text x={x} y={y + 4} dx={-30} textAnchor="middle" fill="#666">
        {valueShort}
      </text>
    </Tooltip>
  );
};

export default YAxisValueTooltip;
