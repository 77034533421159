import React, { PureComponent } from "react";
import { createStyles, WithStyles, withStyles, Grid, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";

import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import { translations } from "../../../constants/lang/translation";
import AccountSelect from "../../marketintelligence/Dashboard/components/AccountButton";
import { QuopinionTheme } from "../../../constants/Theme";

import { fetchUser, fetchCurrentResearcher } from "../../../modules/user";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { withAuthorization } from "../../../common/hoc/withAuthorization";
import { UserRole } from "../../../entities/User";
import { Links } from "../../marketintelligence/Dashboard";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Button from "../../../common/Layout/components/Button";
import Container from "../../../common/Layout/Container";
import Card from "../../../common/Layout/components/Card";
import Services from "../../../services/Services";
import { route as internalScreenerListRoute } from "../Attributes/AttributeList";
import { route as internalScreenerFormRoute } from "../Attributes/AttributeForm";
import { route as externalScreenerOverviewRoute } from "../Attributes/ExternalAttributeOverview";

export const route = "/admin/dashboard";

interface State {
  csvDownloadRequesting: boolean;
  csvBillingDataRequesting: boolean;
  csvBillingDataError: boolean;
}

interface OwnProps {}

interface DispatchProps {
  fetchUser: typeof fetchUser;
  fetchCurrentResearcher: typeof fetchCurrentResearcher;
}

interface StateProps {
  isAuthenticated: boolean | undefined;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    customLogobar: {
      display: "flex",
    },
    headline: {
      marginBottom: theme.spacing(4),
    },
    card: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: theme.spacing(6),
    },
    cardTitle: {
      textTransform: "uppercase",
    },
    cardRow: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: theme.spacing(6),
      },
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{ step: string }>;

class DashboardAdmin extends PureComponent<Props, State> {
  state = {
    csvDownloadRequesting: false,
    csvBillingDataRequesting: false,
    csvBillingDataError: false,
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.fetchUser();
      this.props.fetchCurrentResearcher();
    }
  }

  downloadPayoutData = async ({ history }: { history: boolean }) => {
    this.setState({
      csvDownloadRequesting: true,
    });

    try {
      if (history) {
        await Services.user.getPayoutHistoryCSV();
      } else {
        await Services.user.getPayoutCSV();
      }
    } catch (e) {}

    this.setState({
      csvDownloadRequesting: false,
    });
  };

  downloadBillingData = async () => {
    this.setState({
      csvBillingDataRequesting: true,
    });

    try {
      await Services.user.getBillingdataCSV().then(() => {
        this.setState({
          csvBillingDataRequesting: false,
          csvBillingDataError: false,
        });
      });
    } catch (e) {
      this.setState({
        csvBillingDataRequesting: false,
        csvBillingDataError: true,
      });
    }
  };

  renderLogoBarCustomArea = (props: Props) => {
    const { classes } = this.props;
    return (
      <div className={classes.customLogobar}>
        <AccountSelect />
      </div>
    );
  };

  renderContent() {
    const { classes, t } = this.props;
    const { csvDownloadRequesting, csvBillingDataRequesting } = this.state;
    return (
      <Grid container={true}>
        <ContentWrapper>
          <Container>
            {/*  participant section - payment info*/}
            <Typography variant="h2" className={classes.headline}>
              {t(translations.pages.admin.dashboard.payment.title)}
            </Typography>
            <Card className={classes.card}>
              <div className={classes.cardRow}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.pages.admin.dashboard.payment.payout)}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  contained={true}
                  onClick={this.downloadPayoutData}
                  disabled={csvDownloadRequesting}
                >
                  {t(translations.action.download)}
                </Button>
              </div>
              <div className={classes.cardRow}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.pages.admin.dashboard.payment.paymentHistory)}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  contained={true}
                  onClick={this.downloadPayoutData.bind(this, { history: true })}
                  disabled={csvDownloadRequesting}
                >
                  {t(translations.action.download)}
                </Button>
              </div>
            </Card>
            {/*attributes*/}
            <Typography variant="h2" className={classes.headline}>
              {t(translations.pages.admin.dashboard.attributes.title)}
            </Typography>
            <Card className={classes.card}>
              <div className={classes.cardRow}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.pages.admin.dashboard.attributes.general)}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  contained={true}
                  onClick={() => {
                    this.props.history.push(internalScreenerListRoute + "DEFAULT");
                  }}
                >
                  {t(translations.action.modify)}
                </Button>
              </div>
              <div className={classes.cardRow}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.pages.admin.dashboard.attributes.intern)}&nbsp;
                  {t(translations.pages.admin.dashboard.attributes.create)}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  contained={true}
                  onClick={() => {
                    this.props.history.push(internalScreenerFormRoute + "new");
                  }}
                >
                  {t(translations.pages.admin.dashboard.attributes.create)}
                </Button>
              </div>
              <div className={classes.cardRow}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.pages.admin.dashboard.attributes.externalRequestedDailies)}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  contained={true}
                  onClick={() => {
                    this.props.history.push(internalScreenerListRoute + "SCREENER");
                  }}
                >
                  {t(translations.action.modify)}
                </Button>
              </div>
              <div className={classes.cardRow}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.pages.admin.dashboard.attributes.externalScreenerRequests)}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  contained={true}
                  onClick={() => {
                    this.props.history.push(externalScreenerOverviewRoute);
                  }}
                >
                  Anzeigen
                </Button>
              </div>
            </Card>
            {/* researcher section - bill data*/}
            <Typography variant="h2" className={classes.headline}>
              {t(translations.pages.admin.dashboard.billData.title)}
            </Typography>
            <Card className={classes.card}>
              <div className={classes.cardRow}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.pages.admin.dashboard.billData.allBillingInformation)}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  contained={true}
                  onClick={this.downloadBillingData}
                  disabled={csvBillingDataRequesting}
                >
                  {t(translations.action.download)}
                </Button>
              </div>
            </Card>
          </Container>
        </ContentWrapper>
      </Grid>
    );
  }

  render() {
    return (
      <div>
        <LogoBarResearcher links={Links} children={this.renderLogoBarCustomArea(this.props)} />
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = ({ session }: RootState) => ({ isAuthenticated: session.authenticated });

const mapDispatchToProps = {
  fetchUser,
  fetchCurrentResearcher,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  withAuthorization(UserRole.ADMIN),
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(DashboardAdmin);
