import * as React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { translations } from "../../../constants/lang/translation";
import { compose } from "recompose";
import { createStyles, TextField, Typography, withStyles, WithStyles } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  requestSessionToken,
  requestSessionTokenWithSurveyToken,
  unsubscribeSessionToken,
} from "../../../modules/session";
import { QuopinionTheme } from "../../../constants/Theme";
import { RootState } from "../../../modules";
import Button from "../../../common/Layout/components/Button";
import { route as ResearcherRegistrationRoute } from "../Register";
import { route as ResearcherPasswordForgottenRoute } from "../PasswordForgotten";
import { route as researchDashboardRoute } from "../Dashboard";
import { fetchCurrentResearcher } from "../../../modules/user";
import i18n from "i18next";

interface OwnProps {
  dispatch?: any;
  onLoginPage?: boolean;
  withLoginButtonInside?: boolean;
  bindSubmitForm?: (form: any) => void;
  onSummary?: boolean;
}

interface StateProps {
  nextPage: string;
  passNextPage?: any;
  surveyToken: string | undefined;
}

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    input: {
      maxWidth: "auto",
      marginTop: (props: OwnProps) => (props.onSummary ? 0 : theme.spacing(4)),
    },
    inputWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    buttonLine: {
      marginTop: theme.spacing(6),
      display: "flex",
      justifyContent: (props: OwnProps) => (props.onLoginPage ? "space-between" : "flex-end"),
    },
    inputLabel: {
      color: theme.palette.grey[400],
      top: "-8px",
      "&$focused": {
        top: "0px !important",
      },
    },
  });

export type Props = StateProps &
  OwnProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps;

const LoginComponent: React.FC<Props> = ({
  classes,
  onLoginPage,
  withLoginButtonInside,
  bindSubmitForm,
  surveyToken,
  history,
  dispatch,
}) => {
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .trim()
        .email(i18n.t(translations.validation.login.emailNotValid))
        .required(i18n.t(translations.validation.login.emailRequired)),
      password: Yup.string().required(i18n.t(translations.validation.login.passwordRequired)),
    });
  };

  const directToRegister = () => {
    history.push(ResearcherRegistrationRoute);
  };

  const directToForgottenPassword = () => {
    history.push(ResearcherPasswordForgottenRoute);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        console.log("im submit, values:", values);
        setSubmitting(true);

        if (surveyToken) {
          dispatch(requestSessionTokenWithSurveyToken(values.email, values.password, surveyToken))
            .then(() =>
              dispatch(fetchCurrentResearcher())
                .then(() => {
                  setSubmitting(false);
                })
                .catch((error: any) => {
                  setSubmitting(false);
                  dispatch(unsubscribeSessionToken());
                  setErrors({
                    email: i18n.t(translations.validation.login.emailRequired),
                    password: i18n.t(translations.validation.login.passwordRequired),
                  });
                })
            )
            .catch((error: any) => {
              setSubmitting(false);
              if (error.error.message === "Unauthorized") {
                setErrors({
                  email: i18n.t(translations.validation.login.emailRequired),
                  password: i18n.t(translations.validation.login.passwordRequired),
                });
              } else {
                dispatch(unsubscribeSessionToken());
                setErrors({
                  email: i18n.t(translations.validation.login.emailRequired),
                  password: i18n.t(translations.validation.login.passwordRequired),
                });
              }
            });
        } else {
          dispatch(requestSessionToken(values.email, values.password))
            .then(() => {
              dispatch(fetchCurrentResearcher())
                .then(() => {
                  setSubmitting(false);
                  history.push(researchDashboardRoute);
                })
                .catch((error: any) => {
                  setSubmitting(false);
                  dispatch(unsubscribeSessionToken());
                });
            })
            .catch((error: any) => {
              if (error.error.message === "Unauthorized") {
                setSubmitting(false);
                setErrors({
                  email: i18n.t(translations.validation.login.emailRequired),
                  password: i18n.t(translations.validation.login.passwordRequired),
                });
              }
            });
        }
      }}
    >
      {(formikProps) => {
        const { handleChange, handleBlur, handleSubmit, values, errors, touched, isSubmitting } =
          formikProps;

        if (bindSubmitForm) {
          bindSubmitForm(formikProps.submitForm);
        }

        return (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          >
            <div className={classes.inputWrapper}>
              <TextField
                className={classes.input}
                name="email"
                placeholder={i18n.t(translations.login.userPlaceholder)}
                label={i18n.t(translations.login.userPlaceholder)}
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
                required={true}
                error={touched.email && Boolean(errors.email)}
                InputLabelProps={{ shrink: true, classes: { root: classes.inputLabel } }}
              />
              {errors.email && (
                <Typography color="error">{touched.email ? `${errors.email}` : ""}</Typography>
              )}
              <TextField
                className={classes.input}
                name="password"
                type="password"
                placeholder={"Passwort"}
                label={i18n.t(translations.login.passwordPlaceholder)}
                value={values.password}
                onChange={handleChange("password")}
                onBlur={handleBlur("password")}
                required={true}
                error={touched.password && Boolean(errors.password)}
                InputLabelProps={{ shrink: true, classes: { root: classes.inputLabel } }}
              />
              {errors.password && (
                <Typography color="error">
                  {touched.password ? `${errors.password}` : ""}
                </Typography>
              )}
            </div>
            <div className={classes.buttonLine}>
              {onLoginPage && (
                <Button size="small" color="primary" onClick={directToRegister} type="button">
                  {i18n.t(translations.login.newAtQuopinion)}
                </Button>
              )}
              <Button
                size="small"
                color="secondary"
                onClick={directToForgottenPassword}
                type="button"
              >
                {i18n.t(translations.login.forgottenPassword)}
              </Button>

              {withLoginButtonInside && (
                <Button
                  size="small"
                  color="secondary"
                  contained={true}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {i18n.t(translations.action.login)}
                </Button>
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
//TODO Check to remove tsignore
// @ts-ignore
const mapStateToProps = ({ navigation, participation, survey }: RootState) => ({
  surveyToken: survey.token,
  nextPage: navigation.postLoginPage,
  participationIntention: participation.participationIntention,
});

export const LoginForm = compose<Props, OwnProps>(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(LoginComponent);
