import Client from "./Client";
import Attribute, { AttributeType } from "../entities/Attribute";
import RequestedAttribute from "../entities/RequestedAttribute";
import ID from "../entities/ID";

export default class Attributes {
  readonly httpClient: Client;
  readonly route = "/attributes";

  constructor(httpClient: Client) {
    this.httpClient = httpClient;
  }

  // fetch all Attributes
  async fetchAllAttributes(filter?: { type?: AttributeType | "ALL" }): Promise<Attribute[]> {
    return ((await this.httpClient.get(this.route, filter)) as any[]).map(
      (attribute: any) => new Attribute(attribute)
    );
  }

  // fetch open Attributes for current participant
  async fetchAttribute(id: string): Promise<Attribute> {
    // fetch attributes
    return new Attribute(await this.httpClient.get("attributes/" + id));
  }

  async saveAttribute(attribute: Attribute): Promise<Attribute> {
    if (attribute.organizationId) {
      return new Attribute((await this.httpClient.post(this.route, attribute.toDataJson())) as any);
    } else if (attribute.id) {
      return new Attribute((await this.httpClient.put(this.route, attribute.toDataJson())) as any);
    } else {
      return new Attribute((await this.httpClient.post(this.route, attribute.toDataJson())) as any);
    }
  }

  //delete Attribute
  async deleteAttribute(id: string): Promise<void> {
    return await this.httpClient.delete(`/attributes/${id}`);
  }

 //deactivate Screener
  async deactivateScreener(id: string): Promise<void> {
    return await this.httpClient.put(`${this.route}/external/deactivate/${id}`);
  }

  //activate Screener
  async activateScreener(id: string): Promise<void> {
    return await this.httpClient.put(`${this.route}/external/activate/${id}`);
  }

  // Request new Attribute as Researcher
  async requestNewAttribute(requestedAttribute: {
    surveyId: ID;
    message: string;
  }): Promise<RequestedAttribute> {
    return await this.httpClient
      .post(`${this.route}/external`, requestedAttribute)
      .then((response) => new RequestedAttribute(response));
  }

  // update new Attribute as Admin
  async updateRequestedNewAttributes(
    updatedAttribute: RequestedAttribute
  ): Promise<RequestedAttribute> {
    return await this.httpClient
      .put(`${this.route}/external/${updatedAttribute.id}`, updatedAttribute)
      .then((response) => new RequestedAttribute(response));
  }

  // fetch list containing requested Attributes as Admin
  async fetchRequestedNewAttributes(): Promise<RequestedAttribute[]> {
    return await this.httpClient
      .get(`${this.route}/external`)
      .then((response) =>
        response.map((attribute: RequestedAttribute) => new RequestedAttribute(attribute))
      );
  }

  // delete requested Attribute as Admin
  async deleteRequestedNewAttribute(id: ID): Promise<void> {
    return await this.httpClient
      .delete(`${this.route}/external/${id}`)
      .then((response) => Promise.resolve(response));
  }
}
