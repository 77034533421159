import React from "react";
import { StarBorderOutlined } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import { QuopinionTheme, bronze, silver, gold } from "../../../../constants/Theme";

const getBackgroundColor = (type: number | undefined) => {
  if (type === 1) {
    return bronze;
  }
  if (type === 2) {
    return silver;
  }
  return gold;
};

const Container = styled("div")((props: { theme: QuopinionTheme }) => ({
  width: 27,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: props.theme.palette.secondary.main,
  borderRadius: 11,
}));

const Star = styled(StarBorderOutlined)(({ theme }: { theme: QuopinionTheme }) => ({
  color: theme.palette.common.white,
}));

interface Props {
  type: number | undefined;
}

const PriorityLabel = (props: Props) => (
  <Container style={{ backgroundColor: getBackgroundColor(props.type) }}>
    <Star />
  </Container>
);

export default PriorityLabel;
