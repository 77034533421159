import React from "react";
import { createStyles, WithStyles, Typography } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { withStyles } from "@material-ui/styles";
import { translations } from "../../../constants/lang/translation";
import StartScreener from "./Icons/StartScreener";
import PriorityLabel from "./Icons/PriorityLabel";
import { Link } from "react-router-dom";
import CompletedBadge from "./Icons/CompletedBadge";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      display: "flex",
      position: "relative",
      borderRadius: 10,
      backgroundColor: (props: OwnProps) =>
        props.highlight ? theme.palette.grey[50] : theme.palette.grey[200],
      textDecoration: "none",
      color: theme.palette.common.black,
      marginBottom: theme.spacing(2),
      boxShadow: (props: OwnProps) =>
        props.highlight ? "rgba(0, 0, 0, 0.05) 0px 2px 4px 0px" : "none",
      border: (props: OwnProps) => (props.highlight ? `1px solid rgba(0, 0, 0, 0.05)` : "none"),
    },
    actionIconContainer: {
      minWidth: 80,
      borderRight: `1px solid #9797973B`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: `${theme.spacing(4)}px 0 ${theme.spacing(4)}px ${theme.spacing(6)}px`,
      overflow: "hidden",
    },
    labelContainer: {
      position: "absolute",
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
    headline: {
      marginBottom: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    title: {
      marginBottom: theme.spacing(2),
      "@media screen and (max-width: 600px)": {
        fontSize: "18px",
      },
    },
    questionAmount: {
      fontSize: 16,
      lineHeight: 1.5,
      color: theme.palette.grey[400],
    },
  });

interface OwnProps {
  title: string;
  questionLength: number;
  link: string;
  type?: number | undefined;
  completed: boolean;
  highlight?: boolean;
}

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

const AttributesItem = ({ t, classes, title, type, link, questionLength, completed }: Props) => {
  return (
    <Link to={link} className={classes.root}>
      {type && (
        <div className={classes.labelContainer}>
          <PriorityLabel type={type} />
        </div>
      )}

      <div className={classes.actionIconContainer}>
        {completed ? <CompletedBadge /> : <StartScreener />}
      </div>
      <div className={classes.contentContainer}>
        <Typography variant="body1" className={classes.headline}>
          {t(translations.participant.screeners.attributesItem.headline)}
        </Typography>
        <Typography variant="h3" className={classes.title} id={title.toLowerCase().split(" ")[0]}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.questionAmount}>
          {questionLength > 1
            ? `${questionLength} ${t(translations.participant.screeners.attributesItem.questions)}`
            : `${questionLength} ${t(translations.participant.screeners.attributesItem.question)}`}
        </Typography>
      </div>
    </Link>
  );
};

export default compose<Props, OwnProps>(withStyles(styles), withTranslation())(AttributesItem);
