import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import i18n from "i18next";

import { LandingpageBody } from "../../../common/Landingpage/LandingpageBody";
import { LandingpageHeader } from "../../../common/Landingpage/LandingpageHeader";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import { translations } from "../../../constants/lang/translation";
import { backgroundWhite, lightBlue, QuopinionTheme } from "../../../constants/Theme";
import FAQResearcher from "../../../common/Landingpage/FAQResearcher";
import { SurveyStepOverview } from "../../../common/Landingpage/SurveyStepOverview";
import SurveyStepCarousel from "../../../common/Landingpage/Carousel";
import { route as ImprintRoute } from "../Imprint";
import { route as ResearcherPrivacyRoute } from "../DataProtection/DataProtectionLong";
import headerPicture from "../../../assets/images/ResearcherLandingpage/headerPictureResearcher.png";
import { route as ResearcherDashboardRoute } from "../Dashboard";
import { Footer } from "../../../common/Landingpage/Footer";
// @ts-ignore
import headerVideo from "../../../assets/videos/researcherOpeningVideo.mp4";
// @ts-ignore
import marketerVideo from "../../../assets/videos/forHeadOfMarketing.mp4";
// @ts-ignore
import entrepreneurVideo from "../../../assets/videos/forOnlineEntrepreneur.mp4";
// @ts-ignore
import scienceVideo from "../../../assets/videos/forScience.mp4";
import headerPosterImage from "../../../assets/videos/posterImages/header.jpg";
import marketerPosterImage from "../../../assets/videos/posterImages/marketer.jpg";
import entrepreneurPosterImage from "../../../assets/videos/posterImages/entrepreneurs.jpg";
import sciencePosterImage from "../../../assets/videos/posterImages/science.jpg";
import { TargetgroupOverview } from "../../../common/Landingpage/TargetgroupOverview";
import firstStepIcon from "../../../assets/images/ResearcherLandingpage/icon_step1_selectPanel.png";
import secondStepIcon from "../../../assets/images/ResearcherLandingpage/icon_step2_createQuestionnaire.png";
import thirdStepIcon from "../../../assets/images/ResearcherLandingpage/icon_step3_liveResult.png";

interface OwnProps {}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      marginBottom: 0,
    },
    image: {
      maxHeight: 504,
      position: "absolute",
      display: "none",
      marginLeft: theme.spacing(4),
      "@media (min-width: 768px)": {
        display: "block",
        width: theme.spacing(85),
        top: 0,
        right: theme.spacing(0),
      },
      "@media (min-width: 850px)": {
        width: theme.spacing(100),
        top: 0,
        right: theme.spacing(0),
      },
      "@media (min-width: 960px)": {
        width: theme.spacing(127),
      },
      "@media(min-width: 995px)": {
        right: 0,
      },
      "@media(min-width:1170px)": {
        width: "unset",
      },
    },
    faqWrapper: {
      paddingTop: theme.spacing(9.75),
      backgroundColor: backgroundWhite,
      width: "100%",
    },
    headlineBold: {
      fontFamily: "catamaranBold",
    },
  });

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

export const route: string = "/";

const headerImage = (props: Props) => (
  <picture>
    <source media="(min-width: 1280px)" srcSet={headerPicture} />
    <source media="(min-width: 1024px)" srcSet={headerPicture} />
    <source media="(min-width: 768px)" srcSet={headerPicture} />
    <img className={props.classes.image} src={headerPicture} alt="" />
  </picture>
);
const targetGroupOverviewTexts = () => {
  return {
    title: i18n.t(translations.pages.landingpage.researcher.targetGroup.title),
    item1: {
      title: i18n.t(translations.pages.landingpage.researcher.targetGroup.firstGroup.title),
      description: i18n.t(
        translations.pages.landingpage.researcher.targetGroup.firstGroup.description
      ),
    },
    item2: {
      title: i18n.t(translations.pages.landingpage.researcher.targetGroup.secondGroup.title),
      description: i18n.t(
        translations.pages.landingpage.researcher.targetGroup.secondGroup.description
      ),
    },
    item3: {
      title: i18n.t(translations.pages.landingpage.researcher.targetGroup.thirdGroup.title),
      description: i18n.t(
        translations.pages.landingpage.researcher.targetGroup.thirdGroup.description
      ),
    },
  };
};
const targetGroupVideos = [marketerVideo, entrepreneurVideo, scienceVideo];
const posterImages = [marketerPosterImage, entrepreneurPosterImage, sciencePosterImage];

const surveyStepsIconOverview = (
  iconStepPanel: string,
  iconStepQuestionnaire: string,
  iconStepResults: string
) => {
  return {
    title: i18n.t(translations.pages.landingpage.researcher.steps.title),
    subtitle: i18n.t(translations.pages.landingpage.researcher.whySubheadline),
    item1: {
      image: iconStepPanel,
      title: i18n.t(translations.pages.landingpage.researcher.steps.first.title),
      description: i18n.t(translations.pages.landingpage.researcher.steps.first.description),
    },
    item2: {
      image: iconStepQuestionnaire,
      title: i18n.t(translations.pages.landingpage.researcher.steps.second.title),
      description: i18n.t(translations.pages.landingpage.researcher.steps.second.description),
    },
    item3: {
      image: iconStepResults,
      title: i18n.t(translations.pages.landingpage.researcher.steps.third.title),
      description: i18n.t(translations.pages.landingpage.researcher.steps.third.description),
    },
  };
};
const usageReasonsContent = () => {
  return {
    title: i18n.t(translations.pages.landingpage.researcher.why),
    item1: {
      title: i18n.t(translations.pages.landingpage.researcher.fast.headline),
      description: i18n.t(translations.pages.landingpage.researcher.fast.description),
    },
    item2: {
      title: i18n.t(translations.pages.landingpage.researcher.quality.headline),
      description: i18n.t(translations.pages.landingpage.researcher.quality.description),
    },
    item3: {
      title: i18n.t(translations.pages.landingpage.researcher.transparent.headline),
      description: i18n.t(translations.pages.landingpage.researcher.transparent.description),
    },
  };
};

class LandingPageMarketIntelligence extends PureComponent<Props> {
  render() {
    const { t, classes } = this.props;
    return (
      <ContentWrapper className={classes.wrapper}>
        <LandingpageHeader
          backgroundColor={lightBlue}
          title={t(translations.pages.landingpage.researcher.headline)}
          subtitle={t(translations.pages.landingpage.researcher.subtitle)}
          video={headerVideo}
          posterImage={headerPosterImage}
          firstButtonText={t(translations.pages.landingpage.researcher.createSurvey)}
          firstButtonLink={ResearcherDashboardRoute}
          children={headerImage(this.props)}
          description={
            <>
              {t(translations.pages.landingpage.researcher.description1)}
              {t(translations.pages.landingpage.researcher.description2)}
            </>
          }
        />
        <TargetgroupOverview
          backgroundColor={backgroundWhite}
          videos={targetGroupVideos}
          posterImages={posterImages}
          textObj={targetGroupOverviewTexts()}
        />
        <LandingpageBody
          centeredItemTitles={false}
          backgroundColor={lightBlue}
          contentObj={usageReasonsContent()}
        />
        <SurveyStepOverview
          backgroundColor={backgroundWhite}
          contentObj={surveyStepsIconOverview(firstStepIcon, secondStepIcon, thirdStepIcon)}
        />
        <SurveyStepCarousel backgroundColor={lightBlue} />
        <div className={classes.faqWrapper}>
          {" "}
          {/*added outer style as component used on own page*/}
          <FAQResearcher whiteBackgroundColor={true} />
        </div>
        <Footer imprintLink={ImprintRoute} dataSecurityLink={ResearcherPrivacyRoute} />
      </ContentWrapper>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(LandingPageMarketIntelligence);
