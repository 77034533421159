import { httpClient } from "./httpClient";
import Region from "../entities/Region";
import Services from "./Services";
import Attribute from "../entities/Attribute";
import AnswerOption from "../entities/AnswerOption";
import Panel from "../entities/Panel";
// import { Answer } from "../entities/SurveyAnswers";

// create Options for filter

export const getRegionOptions = async () => {
  const attributes = await Services.questionnaire.getPanelConfiguration();
  const regionsData = await httpClient().get("/regions");
  const regions = regionsData.data.map((region: any) => new Region(region));

  const completeRegionOptions = attributes
    .filter((attribute: any) => attribute.panelOptions.panelGroup === 2)
    .map((attribute: Attribute) => ({
      label: { text: attribute.name, id: attribute.id },
      value: regions
        .filter((region: Region) => region.typeLabel === attribute.name)
        .map((region: Region) => ({ text: region.name, id: region.id })),
    }));

  return completeRegionOptions;
};

interface CriteriaItem {
  attributeId: string;
  percentage: number;
  type: "textList" | "numberRange";
  value: string[];
}

// export const getAgeOption = async (panel: Panel) => {
//   const attributes = await Services.questionnaire.getPanelConfiguration();

//   const ageAttribute = panel.criteria.filter((item: CriteriaItem) => item.type === "numberRange");

//   let ageOption;
//   if (ageAttribute.length > 0) {
//     ageOption = ageAttribute.map((item: CriteriaItem) => ({
//       label: {
//         text: attributes.find((attribute: Attribute) => attribute.id === item.attributeId).label,
//         id: item.attributeId,
//       },
//       value: attributes
//         .find((attribute: Attribute) => attribute.id === item.attributeId)
//         .question.body.answerOptions.filter((option: AnswerOption) =>
//           item.value.includes(option.id)
//         )
//         .map((option: AnswerOption) => ({
//           text: option.label,
//           id: option.id,
//         })),
//     }));
//   } else {
//     console.log(attributes.filter((attribute: Attribute) => attribute.name === "Alter"));
//     ageOption = attributes
//       .filter((attribute: Attribute) => attribute.name === "Alter")
//       .map((attribute: any) => ({
//         label: { text: attribute.name, id: attribute.id },
//         value:
//         {
//           text: "Eigene Altersrange setzen",
//           id: "set",
//         }),
//       }));
//   }

//   console.log(attributes, panel.criteria, ageAttribute, ageOption);
// };

export const getGenderOptions = async (panel: Panel, dictionary: any) => {
  const attributes = await Services.questionnaire.getPanelConfiguration();

  const genderAttribute = attributes.find(
    (attribute: Attribute) => attribute.name === "Geschlecht"
  );

  let genderOptions;
  if (panel.criteria.some((item: CriteriaItem) => item.attributeId === genderAttribute.id)) {
    const genderCriteria: CriteriaItem | never[] | undefined = panel.criteria.filter(
      (item: CriteriaItem) => item.attributeId === genderAttribute.id
    );

    genderOptions = attributes
      .filter((attribute: Attribute) => attribute.name === "Geschlecht")
      .map((attribute: any) => {
        return {
          label: { text: attribute.name, id: attribute.id },
          value: genderCriteria.map((option: CriteriaItem) => ({
            text: dictionary[option.value[0]],
            id: option.value[0],
          })),
        };
      });
  } else {
    genderOptions = attributes
      .filter((attribute: Attribute) => attribute.name === "Geschlecht")
      .map((attribute: any) => ({
        label: { text: attribute.name, id: attribute.id },
        value: attribute.question.answerOptions.map((option: AnswerOption) => ({
          text: option.label,
          id: option.id,
        })),
      }));
  }

  return genderOptions;
};
