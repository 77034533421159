import { Screener } from "../entities/Screener";
import AttributeQuestion from "../entities/AttributeQuestion";
import Attribute from "../entities/Attribute";

export const getAttributeQuestionsForLevel = (attributeArray: Attribute[]) => {
  return attributeArray
    .map((attribute) => new Screener(attribute))
    .filter((screener) => screener.level !== "BASIC")
    .reduce(generateScreenerListByCategory, {});
};

export const generateScreenerListByCategory = (result: any, current: Screener) => {
  const categories = Object.keys(result);
  if (!categories.includes(current.category)) {
    result = {
      ...result,
      [current.category]: {
        id: current.category,
        level: current.level,
        category: current.category,
        questions: [
          ...current.questions.map(
            (question) => new AttributeQuestion({ ...question, attributeID: current.id })
          ),
        ],
      },
    };
  } else {
    result = {
      ...result,
      [current.category]: {
        ...result[current.category],
        category: current.category,
        questions: [
          ...result[current.category].questions!,
          ...current.questions.map(
            (question) => new AttributeQuestion({ ...question, attributeID: current.id })
          ),
        ],
      },
    };
  }
  return result;
};

export const regionAttributeIds = [
  "00000000-0000-0000-0002-000000000001", //state
  "00000000-0000-0000-0002-000000000002", //county
  "00000000-0000-0000-0002-000000000003", //touristarea
  "00000000-0000-0000-0002-000000000004", //population density
  "00000000-0000-0000-0002-000000000005", //leitregion
];

export const panelConfigAttributeIds = {
  age: "00000000-0000-0000-0001-000000000010",
  gender: "00000000-0000-0000-0001-000000000001",
  household: "00000000-0000-0000-0001-000000000009",
  income: "00000000-0000-0000-0001-000000000004",
  education: "00000000-0000-0000-0001-000000000007",
  family: "00000000-0000-0000-0001-000000000002",
  children: "00000000-0000-0000-0001-000000000006",
  county: "00000000-0000-0000-0002-000000000002",
  state: "00000000-0000-0000-0002-000000000001",
  leitregion: "00000000-0000-0000-0002-000000000005",
  city: "00000000-0000-0000-0002-000000000007",
  populationDensity: "00000000-0000-0000-0002-000000000004",
  touristArea: "00000000-0000-0000-0002-000000000003",
};
