import React from "react";
import Question from "../../../../entities/Question";
import MediaDisplay from "../../../../common/Preview/components/MediaDisplay";
import { Radio, TextField, Typography } from "@material-ui/core";
import RadioElement from "../../../../common/Preview/components/RadioButton";
import Checkbox from "../../../../common/Preview/components/Checkbox";
import { translations } from "../../../../constants/lang/translation";
import { withTranslation, WithTranslation } from "react-i18next";
import { Answer, ChoiceAnswer } from "../../../../entities/SurveyAnswers";
import { isChecked } from "../isChecked";
import ID from "../../../../entities/ID";
import { AnsweredAttribute } from "../../../../services/Accounts";
import AnswerOption, { FieldTypes } from "../../../../entities/AnswerOption";

interface OwnProps {
  question: Question;
  currentAnswer?: ChoiceAnswer | AnsweredAttribute;
  handleUserAnswer: (questionID: ID, answer?: Answer, skipTo?: ID) => void;
  isScreener?: boolean;
}

type Props = OwnProps & WithTranslation;

const SingleChoice: React.FC<Props> = ({
  question,
  t,
  handleUserAnswer,
  currentAnswer,
  isScreener,
}) => {
  const handleInputChange = (
    answer: AnswerOption,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    if (currentAnswer) {
      if (!(currentAnswer instanceof AnsweredAttribute)) {
        //all other already given answers (to other questions) should be kept
        if (answer.additionalFieldType === FieldTypes.TEXT) {
          handleUserAnswer(
            question.id,
            {
              ...currentAnswer,
              selectedOptions: [answer.id],
              selectedOptionsFreeText: [
                {
                  answerOptionID: answer.id,
                  freeText: event.target.value.length ? event.target.value : "",
                },
              ],
            },
            answer.skipTo
          );
        }
      }
    }
  };

  const handleNumberChange = (
    answer: AnswerOption,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    if (currentAnswer) {
      if (!(currentAnswer instanceof AnsweredAttribute)) {
        //all other already given answers (to other questions) should be kept
        if (answer.additionalFieldType === FieldTypes.NUMBER) {
          handleUserAnswer(
            question.id,
            {
              ...currentAnswer,
              selectedOptions: [answer.id],
              selectedOptionsNumericalInput: [
                {
                  answerOptionID: answer.id,
                  numberEntry:
                    event.target.value.length && typeof Number(event.target.value) === "number"
                      ? Number(event.target.value)
                      : null,
                },
              ],
            },
            answer.skipTo
          );
        }
      }
    }
  };

  return (
    <>
      {question.body.answerOptions.map((answer) => (
        <RadioElement
          id={`checked-${answer.id}-${isChecked(
            answer.id,
            isScreener ? (currentAnswer as AnsweredAttribute) : (currentAnswer as ChoiceAnswer)
          )}`}
          key={answer.id}
          value={answer.id}
          label={answer.label}
          control={<Radio />}
          checked={isChecked(
            answer.id,
            isScreener ? (currentAnswer as AnsweredAttribute) : (currentAnswer as ChoiceAnswer)
          )}
          onChange={(_, checked) => {
            if (currentAnswer) {
              if (checked) {
                //  check if it is a normal question
                if (!(currentAnswer instanceof AnsweredAttribute)) {
                  if (
                    answer.additionalFieldType === FieldTypes.NUMBER ||
                    answer.additionalFieldType === FieldTypes.TEXT
                  ) {
                    if (answer.additionalFieldType === FieldTypes.TEXT) {
                      delete currentAnswer.selectedOptionsNumericalInput;
                      // we need to add this answer option with freetext and replace the former choice
                      handleUserAnswer(
                        question.id,
                        {
                          ...currentAnswer,
                          selectedOptions: [answer.id],
                          selectedOptionsFreeText: [
                            {
                              answerOptionID: answer.id,
                              freeText: "",
                            },
                          ],
                        },
                        answer.skipTo
                      );
                    }
                    if (answer.additionalFieldType === FieldTypes.NUMBER) {
                      // we need to add this answer option with additional numerical field and replace the former choice
                      delete currentAnswer.selectedOptionsFreeText;
                      handleUserAnswer(
                        question.id,
                        {
                          ...currentAnswer,
                          selectedOptions: [answer.id],
                          selectedOptionsNumericalInput: [
                            {
                              answerOptionID: answer.id,
                              numberEntry: null,
                            },
                          ],
                        },
                        answer.skipTo
                      );
                    }
                  } else {
                    // we need to add new answer option without freetext and delete the freetext/ number entry from the former selection
                    delete currentAnswer.selectedOptionsFreeText;
                    delete currentAnswer.selectedOptionsNumericalInput;
                    handleUserAnswer(
                      question.id,
                      {
                        ...currentAnswer,
                        selectedOptions: [answer.id],
                      },
                      answer.skipTo
                    );
                  }
                } else if (currentAnswer instanceof AnsweredAttribute) {
                  if (
                    currentAnswer.answer.type !== "rating" &&
                    currentAnswer.answer.type !== "text" &&
                    currentAnswer.answer.type !== "matrix" &&
                    currentAnswer.answer.type !== "ranking"
                  ) {
                    handleUserAnswer(question.id, {
                      ...currentAnswer,
                      selectedOptions: [answer.id],
                      questionID: currentAnswer.answer.questionID,
                      type: currentAnswer.answer.type,
                    });
                  }
                }
              } else {
                // we need to remove the whole answer
                handleUserAnswer(question.id);
              }
            } else if (checked) {
              // question wasn't answered before
              if (
                answer.additionalFieldType === FieldTypes.TEXT ||
                answer.additionalFieldType === FieldTypes.NUMBER
              ) {
                if (answer.additionalFieldType === FieldTypes.TEXT) {
                  // we need to add this initial answer option with freetext
                  handleUserAnswer(
                    question.id,
                    {
                      questionID: question.id,
                      type: "choice",
                      selectedOptions: [answer.id],
                      selectedOptionsFreeText: [
                        {
                          answerOptionID: answer.id,
                          freeText: "",
                        },
                      ],
                    },
                    answer.skipTo
                  );
                }
                if (answer.additionalFieldType === FieldTypes.NUMBER) {
                  // we need to add this initial answer option with numerical field
                  handleUserAnswer(
                    question.id,
                    {
                      questionID: question.id,
                      type: "choice",
                      selectedOptions: [answer.id],
                      selectedOptionsNumericalInput: [
                        {
                          answerOptionID: answer.id,
                          numberEntry: null,
                        },
                      ],
                    },
                    answer.skipTo
                  );
                }
              } else {
                //we need to add this initial answer option without freetext
                handleUserAnswer(
                  question.id,
                  {
                    questionID: question.id,
                    type: "choice",
                    selectedOptions: [answer.id],
                  },
                  answer.skipTo
                );
              }
            }
          }}
          children={
            <>
              {answer.media.length > 0 && <MediaDisplay media={answer.media} answerOption={true} />}
              {answer.additionalFieldType === FieldTypes.TEXT && (
                <TextField
                  placeholder={t(translations.questionnaire.placeholder.pleaseEnterText)}
                  fullWidth={true}
                  multiline={true}
                  rows={5}
                  autoFocus={true}
                  disabled={
                    !isChecked(
                      answer.id,
                      isScreener
                        ? (currentAnswer as AnsweredAttribute)
                        : (currentAnswer as ChoiceAnswer)
                    )
                  }
                  value={
                    (currentAnswer as ChoiceAnswer) !== undefined
                      ? answer.id === (currentAnswer as ChoiceAnswer).selectedOptions[0]
                        ? (currentAnswer as ChoiceAnswer).selectedOptionsFreeText &&
                          (currentAnswer as ChoiceAnswer).selectedOptionsFreeText !== undefined &&
                          //@ts-ignore
                          (currentAnswer as ChoiceAnswer).selectedOptionsFreeText[0].freeText
                        : ""
                      : ""
                  }
                  onChange={(event) => {
                    handleInputChange(answer, event);
                  }}
                />
              )}
              {answer.additionalFieldType === FieldTypes.NUMBER && (
                <TextField
                  type={"number"}
                  inputProps={{ step: "0.01" }}
                  placeholder={t(translations.questionnaire.placeholder.pleaseEnterNumber)}
                  fullWidth={true}
                  autoFocus={true}
                  disabled={
                    !isChecked(
                      answer.id,
                      isScreener
                        ? (currentAnswer as AnsweredAttribute)
                        : (currentAnswer as ChoiceAnswer)
                    )
                  }
                  value={
                    (currentAnswer as ChoiceAnswer) !== undefined
                      ? answer.id === (currentAnswer as ChoiceAnswer).selectedOptions[0]
                        ? (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput &&
                          (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput !==
                            undefined &&
                          (currentAnswer as ChoiceAnswer).selectedOptions[0] === answer.id &&
                          isChecked(
                            answer.id,
                            isScreener
                              ? (currentAnswer as AnsweredAttribute)
                              : (currentAnswer as ChoiceAnswer)
                          ) &&
                          //@ts-ignore
                          (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput[0]
                            .numberEntry
                        : ""
                      : null
                  }
                  onChange={(event) => {
                    handleNumberChange(answer, event);
                  }}
                />
              )}
              {/*   information to insert number*/}
              {(currentAnswer as ChoiceAnswer) &&
                (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput &&
                (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput !== undefined &&
                (currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput![0].answerOptionID ===
                  answer.id && (
                  <>
                    {(currentAnswer as ChoiceAnswer).selectedOptionsNumericalInput![0]
                      .numberEntry === null && (
                      <Typography style={{ marginLeft: 16 }}>
                        {t(translations.questionnaire.validation.additionalNumberField)}
                      </Typography>
                    )}
                  </>
                )}
            </>
          }
        />
      ))}
      {question.body.additionalAnswerEnabled && (
        <Checkbox
          answerValue={"other"}
          label={"Sonstiges"}
          // Checkbox is always false, state is not changeable
          // mf should not interact
          checked={false}
          onChange={() => {
            return;
          }}
          boldChecked={true}
          children={
            <TextField
              placeholder={t(translations.questionnaire.placeholder.pleaseEnter)}
              fullWidth={true}
              multiline={true}
              rows={5}
              style={{ marginTop: 16 }}
            />
          }
          headerAlignItems={"center"}
        />
      )}
    </>
  );
};

export default withTranslation()(SingleChoice);
