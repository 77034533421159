import React, { PureComponent } from "react";
import { createStyles } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../../constants/Theme";
import { ArrowRight } from "../../../../assets/icon";
import { Link } from "react-router-dom";

interface OwnProps {
  title: string;
  link: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      color: "#000",
      lineHeight: 1.14,
      marginBottom: theme.spacing(0.5),
      padding: theme.spacing(3),
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontWeight: 400,
      "&$visited": {
        color: "#000",
        textDecoration: "none",
      },
      "&:last-of-type": {
        marginBottom: theme.spacing(11),
      },
    },
    arrow: {
      height: 17,
      width: 17,
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class ButtonLink extends PureComponent<Props> {
  render() {
    const { link, title, classes } = this.props;
    return (
      <Link className={classes.root} to={link}>
        <div>{title}</div>
        <ArrowRight fill="#cdd9e4" className={classes.arrow} />
      </Link>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(ButtonLink);
