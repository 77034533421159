import React from "react";
import {
  Select,
  MenuItem,
  Typography,
  createStyles,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import Panel from "../../../../../entities/Panel";
import { RootState } from "../../../../../modules";
import { connect } from "react-redux";
import { compose } from "recompose";

import { getGenderOptions } from "../../../../../services/Results";
import ID from "../../../../../entities/ID";
import { QuopinionTheme } from "../../../../../constants/Theme";
import { filterSurveyResults, fetchPanelConfiguration } from "../../../../../modules/survey";
import { SurveyResult } from "../../../../../entities/SurveyResult";

interface OptionItemValue {
  text: string;
  id: ID;
}

interface OptionItem {
  label: {
    text: string;
    id: ID;
  };
  value: OptionItemValue[];
}

interface OwnProps {}

interface DispatchProps {
  filterSurveyResults: (id: string, filter: any) => Promise<SurveyResult>;
  fetchPanelConfiguration: () => Promise<void>;
}

interface StateProps {
  panel: Panel;
  id: string;
  answerDictionary: [];
}

interface State {
  selectedValue: string;
  genderOptions: OptionItem[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    select: {
      width: 140,
    },
  });

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>;

class ResultFilter extends React.Component<Props, State> {
  state: State = {
    selectedValue: "all",
    genderOptions: [],
  };

  componentDidMount = async () => {
    this.props.fetchPanelConfiguration();
    const genderOptions = await getGenderOptions(this.props.panel, this.props.answerDictionary);
    this.setState({
      genderOptions,
    });
  };

  componentDidUpdate = async (props: Props) => {
    if (props.panel.criteria.length !== this.props.panel.criteria.length) {
      const genderOptions = await getGenderOptions(props.panel, props.answerDictionary);
      this.setState({
        genderOptions,
      });
    }
  };

  setFilter = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    attributeId: ID
  ) => {
    this.setState({
      selectedValue: event.target.value as string,
    });

    const filterItem = {
      attributeId,
      percentage: 100,
      type: "textList",
      value: [{ value: event.target.value as string, included: true }],
    };
    const filterObj = {
      filters: [filterItem],
    };

    this.props.filterSurveyResults(this.props.id, event.target.value === "all" ? {} : filterObj);
  };

  render() {
    const { selectedValue, genderOptions } = this.state;
    const { classes } = this.props;

    return (
      <>
        {genderOptions.map((option: OptionItem) => (
          <div key={option.label.id}>
            <Typography variant="body1" color="primary">
              {option.label.text}
            </Typography>
            <Select
              value={selectedValue}
              displayEmpty={true}
              onChange={(event) => this.setFilter(event, option.label.id)}
              className={classes.select}
            >
              {option.value.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.text}
                </MenuItem>
              ))}
              <MenuItem value="all">Alle</MenuItem>
            </Select>
          </div>
        ))}
      </>
    );
  }
}

const mapStateToProps = ({ survey }: RootState) => ({
  panel: survey.panel,
  id: survey.id,
  answerDictionary: survey.answerDictionary,
});

const mapDispatchToProps = {
  filterSurveyResults,
  fetchPanelConfiguration,
};

export default compose<Props, OwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ResultFilter);
