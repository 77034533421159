import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { createStyles, withStyles, WithStyles, Typography, Grid } from "@material-ui/core";
import { compose } from "recompose";

import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import { route as SurveyCreatorRoute } from "../SurveyCreator";
import { WithTranslation, withTranslation } from "react-i18next";
import { QuopinionTheme } from "../../../constants/Theme";
import { translations } from "../../../constants/lang/translation";
import Button from "../../../common/Layout/components/Button";
import { Plus } from "../../../assets/icon";
import AccountSelect from "../Dashboard/components/AccountButton";
import { Links } from "../Dashboard";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import Card from "../../../common/Layout/components/Card";
import SupportContainer from "../../../common/Layout/SupportContainer";
import OrderSummary from "./components/OrderSummary";
import { route as PersonalInfoRoute } from "./PersonalInformation";
import { route as BillingRoute } from "./BillingData";
import SidebarContainer from "../../../common/Layout/SidebarContainer";
import { RootState } from "../../../modules";
import { connect } from "react-redux";
import { fetchCurrentResearcher, fetchCurrentResearcherSurveys } from "../../../modules/user";
import { withAuthorization } from "../../../common/hoc/withAuthorization";
import { UserRole } from "../../../entities/User";

interface OwnProps {}

interface DispatchProps {
  fetchCurrentResearcher: () => Promise<void>;
  fetchCurrentResearcherSurveys: () => Promise<void>;
}

interface StateProps {
  isAuthenticated: boolean | undefined;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    customLogobar: {
      display: "flex",
      "@media(max-width: 414px)": {
        alignItems: "center",
      },
    },
    card: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(6),
    },
    cardTitle: {
      textTransform: "uppercase",
    },
    headline: {
      marginBottom: theme.spacing(4),
    },
    wrapper: {
      marginRight: theme.spacing(7.5),
      padding: `${theme.spacing(22.5)}px 0 0 8.33% `,
    },
    supportWrapper: {
      alignItems: "flex-end",
      display: "flex",
      justifyContent: "flex-end",
    },
  });

type Props = OwnProps &
  DispatchProps &
  RouteComponentProps &
  WithTranslation &
  StateProps &
  WithStyles<typeof styles>;

export const route: string = "/market-research/account";

class ResearcherAccountPage extends PureComponent<Props> {
  componentDidMount() {
    this.props.fetchCurrentResearcher();
    this.props.fetchCurrentResearcherSurveys();
  }

  goToSurveyCreator = () => {
    this.props.history.push(SurveyCreatorRoute);
  };

  editPersonalInformation = () => {
    this.props.history.push(PersonalInfoRoute);
  };

  editBillingData = () => {
    this.props.history.push(BillingRoute);
  };

  render() {
    const { classes, t } = this.props;
    return (
      <div>
        <LogoBarResearcher links={Links} children={this.renderLogoBarCustomArea(this.props)} />
        <Grid container={true}>
          <ContentWrapper>
            <Container>
              <Typography variant="h2" className={classes.headline}>
                {t(translations.pages.dashboard.researcher.account.title)}
              </Typography>
              <Card className={classes.card}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.pages.dashboard.researcher.links.personalInfo)}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  contained={true}
                  onClick={this.editPersonalInformation}
                >
                  {t(translations.action.modify)}
                </Button>
              </Card>
              <Card className={classes.card}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.pages.dashboard.researcher.links.billData)}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  contained={true}
                  onClick={this.editBillingData}
                >
                  {t(translations.action.modify)}
                </Button>
              </Card>
            </Container>
            <SidebarContainer>
              <SupportContainer />
            </SidebarContainer>
            <Grid container={true} className={classes.wrapper}>
              <OrderSummary />
            </Grid>
          </ContentWrapper>
        </Grid>
      </div>
    );
  }

  renderLogoBarCustomArea = (props: Props) => {
    const { t, classes } = this.props;
    return (
      <div className={classes.customLogobar}>
        <Button
          onClick={this.goToSurveyCreator}
          contained={true}
          color="secondary"
          size="medium"
          icon={<Plus fill="#fff" />}
        >
          {t(translations.action.newSurvey)}
        </Button>
        {this.props.isAuthenticated && <AccountSelect />}
      </div>
    );
  };
}

const mapDispatchToProps = {
  fetchCurrentResearcher,
  fetchCurrentResearcherSurveys,
};
const mapStateToProps = ({ session }: RootState) => ({
  isAuthenticated: session.authenticated,
});

export default compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation(),
  withStyles(styles),
  withAuthorization(UserRole.RESEARCHER)
)(ResearcherAccountPage);
