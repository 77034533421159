import * as React from "react";
import { createStyles, withStyles, Typography, WithStyles } from "@material-ui/core";
import { compose } from "recompose";
import Card from "../../../../common/Layout/components/Card";
import { QuopinionTheme } from "../../../../constants/Theme";
import { RadioIconFilled, RadioIconNotFilled } from "../../../../assets/icon";

export interface OwnProps {
  title: string;
  description: string;

  selected: boolean;
}

const style = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      display: "block",
      padding: theme.spacing(6),
    },
    rootUnselected: {
      display: "block",
      padding: theme.spacing(6),
      backgroundColor: "transparent",
    },
    container: {
      marginLeft: 45,
    },
    header: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    cardTitle: {
      marginLeft: theme.spacing(6),
    },
    description: {
      color: theme.palette.primary.main,
    },
  });

type Props = OwnProps & WithStyles<typeof style>;

const SelectionCard = (props: Props) => {
  const { classes, selected } = props;

  return (
    <Card isRaised={false} className={selected ? classes.root : classes.rootUnselected}>
      <div className={classes.header}>
        {selected ? (
          <RadioIconFilled width="21px" height="21px" />
        ) : (
          <RadioIconNotFilled width="21px" height="21px" />
        )}
        <Typography variant="subtitle2" className={classes.cardTitle}>
          {props.title}
        </Typography>
      </div>
      <div className={classes.container}>
        <Typography variant="body1" className={classes.description}>
          {props.description}
        </Typography>
      </div>
    </Card>
  );
};

export default compose<Props, OwnProps>(withStyles(style))(SelectionCard);
