import React, { PureComponent } from "react";
import { QuopinionTheme } from "../../../constants/Theme";
import { createStyles, Typography, withStyles, WithStyles } from "@material-ui/core";
import Card from "../../../common/Layout/components/Card";
import { compose } from "recompose";
import StartScreener from "./Icons/StartScreener";
import { Link } from "react-router-dom";
import ID from "../../../entities/ID";
import CompletedBadge from "./Icons/CompletedBadge";
//import CompletedBadge from "./Icons/CompletedBadge";

interface OwnProps {
  question: string;
  link?: ID;
  answered: boolean;
  attributeId: ID;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      textDecoration: "none",
      color: theme.palette.common.black,
    },
    questionCard: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      marginBottom: theme.spacing(0.5),
    },
    itemWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    questionWrapper: {
      display: "flex",
      alignItems: "center",
    },
    question: {
      lineHeight: 1.5,
      fontSize: "16px",
      marginRight: theme.spacing(7.5),
    },
    buttonWrapper: {
      display: "flex",
      alignItems: "center",
    },
    playIcon: {
      color: theme.palette.grey[50],
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class ScreenerQuestionItem extends PureComponent<Props> {
  render() {
    const { question, classes, link, answered } = this.props;

    return (
      <Link to={link || "#"} className={classes.root}>
        <Card className={classes.questionCard} isRaised={true}>
          <div className={classes.itemWrapper}>
            <div className={classes.questionWrapper}>
              <Typography className={classes.question}>{question}</Typography>
            </div>

            <div className={classes.buttonWrapper}>
              {!answered && <StartScreener />}
              {answered && <CompletedBadge />}
            </div>
          </div>
        </Card>
      </Link>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(ScreenerQuestionItem);
