import React, { PureComponent } from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as Yup from "yup";

import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import AccountSelect from "../../marketintelligence/Dashboard/components/AccountButton";
import { QuopinionTheme } from "../../../constants/Theme";

import { fetchUser, fetchCurrentResearcher } from "../../../modules/user";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { withAuthorization } from "../../../common/hoc/withAuthorization";
import { UserRole } from "../../../entities/User";
import { Links } from "../../marketintelligence/Dashboard";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import {
  fetchAttribute,
  saveAttribute,
  fetchFlatCategories,
  fetchNewExternalAttributes,
} from "../../../modules/attribute";
import Attribute from "../../../entities/Attribute";
import LoadingOverlay from "../../../common/LoadingOverlay";
import QuestionComponent from "../../marketintelligence/SurveyCreator/StepQuestionnaire/Questions/Question";
import Button from "../../../common/Layout/components/Button";
import { translations } from "../../../constants/lang/translation";
import Question from "../../../entities/Question";
import Card from "../../../common/Layout/components/Card";
import { FlatCategory } from "../../../entities/Category";
import AttributeSelect from "../../../common/Layout/components/AttributeSelect";
import DialogComponent from "../../../common/Layout/components/Dialog";
import Services from "../../../services/Services";
import SidebarContainer from "../../../common/Layout/SidebarContainer";

export const route = "/admin/attribute/";

interface State {
  transientAttribute?: Omit<Attribute, "toDataJson">;
  originalAttribute?: Attribute;
  questionValid: boolean;
  attributeValid: boolean;
  resetCounter: number;
  deleteDialogOpen: boolean;
  activatedDialogOpen: boolean;
}

interface OwnProps {}

interface DispatchProps {
  fetchUser: typeof fetchUser;
  fetchCurrentResearcher: typeof fetchCurrentResearcher;
  fetchAttribute: typeof fetchAttribute;
  saveAttribute: (attribute: Attribute) => Promise<Attribute>;
  fetchNewExternalAttributes: () => void;
  fetchFlatCategories: typeof fetchFlatCategories;
}

interface StateProps {
  isAuthenticated: boolean | undefined;
  isLoadingAttributes: boolean;
  isLoadingCategories: boolean;
  attributes: Attribute[];
  categories: FlatCategory[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    customLogobar: {
      display: "flex",
    },
    headline: {
      marginBottom: theme.spacing(4),
    },
    card: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: theme.spacing(6),
      marginBottom: theme.spacing(12),
    },
    cardTitle: {
      textTransform: "uppercase",
    },
    cardRow: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: theme.spacing(6),
      },
    },
    input: {
      width: 400,
      marginLeft: theme.spacing(6),
      // flexGrow: 1,
    },
    questionContainer: {
      // the container is overlapping on the right - in this scenario it looks better if it is overlapping on the left
      transform: `translateX(${theme.spacing(-8)}px)`,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps<{ id: string; organizationId: string }>;

const adminScreenerDashboardRoute = `/admin/attributes/SCREENER`;

class AttributeForm extends PureComponent<Props, State> {
  static validateAttribute = async (attribute?: {}) => {
    if (!attribute) {
      return false;
    }
    return await Yup.object()
      .shape({
        name: Yup.string().required(),
        priority: Yup.string().required(),
        categories: Yup.array().required().min(1),
      })
      .isValid(attribute);
  };

  static getDerivedStateFromProps(prevProps: Props, prevState: State) {
    const {
      attributes,
      match: {
        params: { id, organizationId },
      },
    } = prevProps;

    if (id === "new") {
      return {
        ...prevState,
        originalAttribute: { ...new Attribute() },
        transientAttribute: prevState.transientAttribute || { ...new Attribute() },
      };
    } else if (id !== "new" && organizationId) {
      return {
        ...prevState,
        originalAttribute: { ...new Attribute() },
        transientAttribute: prevState.transientAttribute || {
          ...new Attribute(),
        },
      };
    } else {
      const originalAttribute = attributes.find((attribute) => attribute.id === id);
      if (
        originalAttribute &&
        originalAttribute.categories.length &&
        typeof originalAttribute.categories[0] === "object"
      ) {
        // we need a string array (ids)

        originalAttribute.categories = (originalAttribute.categories as FlatCategory[]).map(
          (category) => category.id
        );
      }

      return {
        ...prevState,
        originalAttribute,
        transientAttribute: prevState.transientAttribute || originalAttribute,
        attributeValid: AttributeForm.validateAttribute(
          prevState.transientAttribute || originalAttribute
        ),
      };
    }
  }

  state: State = {
    questionValid: false,
    attributeValid: false,
    resetCounter: 0,
    deleteDialogOpen: false,
    activatedDialogOpen: false,
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.fetchUser();
      this.props.fetchCurrentResearcher();
      this.props.fetchFlatCategories();
      this.props.fetchNewExternalAttributes();

      if (this.props.match.params.id !== "new" && !this.props.match.params.organizationId) {
        this.props.fetchAttribute(this.props.match.params.id);
      }
    }
  }

  validateTransientAttribute = async () => {
    this.setState({
      attributeValid: await AttributeForm.validateAttribute(this.state.transientAttribute),
    });
  };

  handleQuestionChange = (question: Omit<Question, "toDataJson">) => {
    this.setState({
      transientAttribute: {
        ...this.state.transientAttribute!,
        question: new Question(question),
      },
    });
  };

  handleChange = (attr: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    if (id !== "new") {
      this.setState(
        {
          transientAttribute: {
            ...this.state.transientAttribute!,
            id: this.state.transientAttribute!.id
              ? this.state.transientAttribute!.id
              : this.props.match.params.id,
            organizationId: this.state.transientAttribute!.organizationId
              ? this.state.transientAttribute!.organizationId
              : this.props.match.params.organizationId,
            [attr]: e.target.value,
          },
        },
        () => {
          this.validateTransientAttribute();
        }
      );
    } else {
      this.setState(
        {
          transientAttribute: {
            ...this.state.transientAttribute!,
            [attr]: e.target.value,
          },
        },
        () => {
          this.validateTransientAttribute();
        }
      );
    }
  };

  handleCategoriesChange = (categories: string[]) => {
    this.setState({ transientAttribute: { ...this.state.transientAttribute!, categories } }, () => {
      this.validateTransientAttribute();
    });
  };

  resetAttribute = () => {
    this.setState({
      transientAttribute: { ...this.state.originalAttribute! },
      resetCounter: this.state.resetCounter + 1,
    });
  };

  submitAttribute = async () => {
    const {
      match: {
        params: { id, organizationId },
      },
    } = this.props;
    try {
      const attribute = await this.props.saveAttribute(
        new Attribute({
          ...this.state.transientAttribute,
          type: id === "new" ? "DEFAULT" : "SCREENER",
          organizationId,
        })
      );
      // route to new attribute id
      this.props.history.push(route + attribute.id);
      window.location.reload();
    } catch (e) {}
  };

  submitDefaultAttributeChange = async () => {
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;
    try {
      const attribute = await this.props.saveAttribute(
        new Attribute({
          ...this.state.transientAttribute,
          type: "DEFAULT",
          organizationId,
        })
      );
      // route to edited attribute id
      this.props.history.push(route + attribute.id);
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };
  toggleDeleteDialog = () => {
    this.setState({
      deleteDialogOpen: !this.state.deleteDialogOpen,
    });
  };

  handleDeleteAttributeClick = async () => {
    try {
      await Services.attributes.deleteAttribute(this.props.match.params.id);
      this.toggleDeleteDialog();
      this.props.history.push(adminScreenerDashboardRoute);
    } catch (error) {
      console.log(error);
      alert("Da ist leider etwas schiefgelaufen: " + error);
    }
  };

  toggleActivationScreener = () => {
    this.setState({
      activatedDialogOpen: !this.state.activatedDialogOpen
    });
  };

  deactivateScreener = async () => {
    try {
      await Services.attributes.deactivateScreener(this.props.match.params.id);
      this.toggleActivationScreener();
      this.props.history.push(adminScreenerDashboardRoute);
    } catch (err) {
      console.log("err", err);
    }
  };

  activateScreener = async () => {
    try {
      await Services.attributes.activateScreener(this.props.match.params.id);
      this.toggleActivationScreener();
      this.props.history.push(adminScreenerDashboardRoute);
    } catch (err) {
      console.log("err", err);
    }
  };

  renderLogoBarCustomArea = (props: Props) => {
    const { classes } = this.props;
    return (
      <div className={classes.customLogobar}>
        <AccountSelect />
      </div>
    );
  };

  renderAttribute = (attribute: Omit<Attribute, "toDataJson">, editMode: boolean) => {
    const {
      t,
      classes,
      categories: availableCategories,
      match: { params },
    } = this.props;
    const { questionValid, attributeValid, resetCounter } = this.state;
    const { question } = attribute;

    return (
      <>
        <Card className={classes.card}>
          <div className={classes.cardRow}>
            <Typography variant="h4" color="primary">
              {t(translations.pages.admin.RequestAttribute.name)}
            </Typography>
            <TextField
              placeholder={t(translations.payoutPage.payout.pleaseEnter)}
              value={attribute.name}
              onChange={this.handleChange.bind(this, "name")}
              required={true}
              className={classes.input}
            />
          </div>
          <div className={classes.cardRow}>
            <Typography variant="h4" color="primary">
              {t(translations.pages.admin.RequestAttribute.description)}
            </Typography>
            <TextField
              placeholder={t(translations.payoutPage.payout.pleaseEnter)}
              value={attribute.description}
              onChange={this.handleChange.bind(this, "description")}
              required={true}
              className={classes.input}
              multiline={true}
            />
          </div>
          <div className={classes.cardRow}>
            <Typography variant="h4" color="primary">
              {t(translations.pages.admin.RequestAttribute.category)}
            </Typography>
            <div className={classes.input}>
              <AttributeSelect
                selectOptions={availableCategories
                  .map((category) => ({
                    label: category.name,
                    value: category.id,
                  }))
                  .sort((a, b) => (a.label > b.label ? 1 : -1))}
                selectedValues={attribute.categories as string[]}
                handleSelection={this.handleCategoriesChange}
                disabled={editMode}
              />
            </div>
          </div>
          {attribute.type === "SCREENER" && (
            <div className={classes.cardRow}>
              <Typography variant="h4" color="primary">
                {t(translations.pages.admin.RequestAttribute.priority)}
              </Typography>
              <TextField
                placeholder={t(translations.payoutPage.payout.pleaseEnter)}
                value={attribute.priority}
                onChange={this.handleChange.bind(this, "priority")}
                required={true}
                className={classes.input}
                type="number"
              />
            </div>
          )}
          {editMode && (
            <div className={classes.cardRow}>
              <Typography variant="h4" color="primary">
                {t(translations.pages.admin.RequestAttribute.answerAmount)}
              </Typography>
              <Typography>
                {Boolean(this.state.originalAttribute) && this.state.originalAttribute!.usageCount}
              </Typography>
            </div>
          )}
          {attribute.id && (
            <div className={classes.cardRow}>
              <Typography variant="h4" color="primary">
                {t(translations.pages.admin.RequestAttribute.attributeId)}
              </Typography>
              <Typography>{attribute.id}</Typography>
            </div>
          )}
          {attribute.level && attribute.type === "DEFAULT" && (
            <div className={classes.cardRow}>
              <Typography variant="h4" color="primary">
                {t(translations.pages.admin.RequestAttribute.level)}
              </Typography>
              <Typography>{attribute.level}</Typography>
            </div>
          )}

          <div className={classes.cardRow}>
            <Typography variant="h4" color="primary">
              {t(translations.pages.admin.RequestAttribute.activated)}
            </Typography>
            <Typography>{attribute.enabled ? "ja" : "nein"}</Typography>
          </div>
          {params.organizationId && (
            <div className={classes.cardRow}>
              <Typography variant="h4" color="primary">
                {t(translations.pages.admin.RequestAttribute.organization)}
              </Typography>
              <Typography>{params.organizationId}</Typography>
            </div>
          )}
        </Card>

        <div className={classes.questionContainer}>
          <QuestionComponent
            // why? because we want the form to be completely resetted
            // (atm. it has a local state, that cannot be resetted from outside - other than rerendering)
            key={resetCounter}
            questionIndex={0}
            question={question}
            otherQuestions={[]}
            onChangeValidity={(validity) => {
              this.setState({ questionValid: validity });
            }}
            onQuestionChange={this.handleQuestionChange}
            questionBadge={`Frage zum Attribut`}
            firstQuestion={true}
            lastQuestion={true}
            removeQuestionFromValidation={() => {
              return;
            }}
            preventRandomizeAnswers={true}
            preventMediaUpload={true}
            preventAlterationOfAnswerCount={editMode}
            preventQuestionSkip={true}
            questionTypeWhitelist={editMode ? [question.type] : ["singleChoice", "multipleChoice"]}
            preventAdditionalFreeText={true}
            questionnaireSkipToEndInfo={[]}
            questionnaireSkpiToIdInfo={[]}
            questionQuestionnaireErrors={[]} //here no external errors provided- local state used
          />
        </div>
        <div className={classes.cardRow}>
          <Button color="primary" size="medium" contained={false} onClick={this.resetAttribute}>
            Formular zurücksetzen
          </Button>
          <Button
            color="primary"
            size="medium"
            contained={true}
            onClick={
              attribute.type === "DEFAULT"
                ? this.submitDefaultAttributeChange
                : this.submitAttribute
            }
            disabled={!(questionValid && attributeValid)}
          >
            {editMode ? "Attribut speichern" : "Attribut anlegen"}
          </Button>
        </div>
      </>
    );
  };

  renderContent() {
    const { classes, isLoadingAttributes, isLoadingCategories, t } = this.props;
    const { transientAttribute, deleteDialogOpen, originalAttribute, activatedDialogOpen } = this.state;

    const isLoading = isLoadingAttributes || isLoadingCategories;

    // are we editing an existing attribute or do we create a new one?
    const editMode =
      this.props.match.params.id !== "new" && !this.props.match.params.organizationId;
    const newAttribute =
      this.props.match.params.id === "new" && !this.props.match.params.organizationId;
    const externalAttributeRequest = originalAttribute && originalAttribute.type === "SCREENER";

    return (
      <Grid container={true}>
        <ContentWrapper>
          <Container>
            <Typography variant="h2" className={classes.headline}>
              {newAttribute || (editMode && !externalAttributeRequest)
                ? t(translations.pages.admin.dashboard.attributes.attribute)
                : t(translations.participant.screeners.daily)}
              : {transientAttribute ? transientAttribute.name : "Lade Attribut..."}
            </Typography>
            {isLoading && <LoadingOverlay />}
            {!isLoading && transientAttribute && this.renderAttribute(transientAttribute, editMode)}
          </Container>

          {originalAttribute &&
            originalAttribute.type === "SCREENER" &&
            editMode && (
              <SidebarContainer>
                {originalAttribute.usageCount === 0 &&
                <div className={classes.buttonWrapper}>
                  <Button
                    color={"quarternary"}
                    size={"big"}
                    onClick={() => this.toggleDeleteDialog()}
                  >
                    Attribut {t(translations.action.delete)}
                  </Button>
                  <DialogComponent
                    aria-labelledby="question-delete"
                    aria-describedby="open delete dialog"
                    open={deleteDialogOpen}
                    closeFunction={this.toggleDeleteDialog}
                    disableAutoFocus={true}
                    confirmFunction={this.handleDeleteAttributeClick}
                    title={t(translations.pages.admin.dashboard.attributes.deleteAttribute)}
                    buttonText={t(translations.action.delete)}
                  />
                </div>
                }
                <div className={classes.buttonWrapper}>
                  <Button
                    color={originalAttribute.enabled ? "quarternary" : "green"}
                    size={"big"}
                    onClick={() => this.toggleActivationScreener()}
                  >
                  {originalAttribute.enabled ? t(translations.action.deactivate) : t(translations.action.activate)}
                  </Button>
                  <DialogComponent
                    aria-labelledby="daily-de-activate"
                    aria-describedby="open de-activate dialog"
                    open={activatedDialogOpen}
                    closeFunction={this.toggleActivationScreener}
                    disableAutoFocus={true}
                    confirmFunction={originalAttribute.enabled ? this.deactivateScreener : this.activateScreener}
                    title={originalAttribute.enabled ? t(translations.pages.admin.dashboard.attributes.verifyDeactivation) : t(translations.pages.admin.dashboard.attributes.verifyActivation)}
                    buttonText={originalAttribute.enabled ? t(translations.action.deactivate) : t(translations.action.activate)}
                />
              </div>
              </SidebarContainer>
            )}
        </ContentWrapper>
      </Grid>
    );
  }

  render() {
    return (
      <div>
        <LogoBarResearcher links={Links} children={this.renderLogoBarCustomArea(this.props)} />
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = ({ session, attribute }: RootState, ownProps: OwnProps) => ({
  isAuthenticated: session.authenticated,
  attributes: attribute.attributes,
  isLoadingAttributes: attribute.isLoadingAttributes,
  categories: attribute.flatCategories,
  isLoadingCategories: attribute.isLoadingCategories,
});

const mapDispatchToProps = {
  fetchUser,
  fetchCurrentResearcher,
  fetchAttribute,
  saveAttribute,
  fetchFlatCategories,
  fetchNewExternalAttributes,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  withAuthorization(UserRole.ADMIN),
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(AttributeForm);
