import { Dispatch } from "redux";
const INVALID_CREDENTIALS = "error/INVALID_CREDENTIALS";
const OFFLINE_STATUS = "error/OFFLINE_STATUS";
const VALID_CREDENTIALS = "error/VALID_CREDENTIALS";
const ONLINE_STATUS = "error/ONLINE_STATUS";

interface ErrorState {
  invalidCredentials?: boolean;
  offline?: boolean;
}

const defaultState: ErrorState = {
  invalidCredentials: false,
  offline: false,
};

interface ErrorAction {
  type: string;
  payload?: string;
}

export const invalidCredentials = () => ({
  type: INVALID_CREDENTIALS,
});

export const validCredentials = () => ({
  type: VALID_CREDENTIALS,
});

export const offlineStatus = () => ({
  type: OFFLINE_STATUS,
});

export const onlineStatus = () => ({
  type: ONLINE_STATUS,
});

export const setToOffline = () => {
  return async (dispatch: Dispatch) => {
    dispatch(offlineStatus());
  };
};

export const setToInvalidCredentials = () => {
  return async (dispatch: Dispatch) => {
    dispatch(invalidCredentials());
  };
};

export const setToValidCredentials = () => {
  return async (dispatch: Dispatch) => {
    dispatch(validCredentials());
  };
};

export const setToOnline = () => {
  return async (dispatch: Dispatch) => {
    dispatch(onlineStatus());
  };
};
export const checkOfflineError = async (dispatch: Dispatch, err: any, message: string) => {
  if (err.message && ["NetworkError", "Failed to fetch"].indexOf(err.message) !== -1) {
    dispatch(offlineStatus());
    console.log(message, err);
    return true;
  } else {
    console.log(message, err);
    return false;
  }
};

export const reducer = (state: ErrorState = defaultState, action: ErrorAction): ErrorState => {
  switch (action.type) {
    case INVALID_CREDENTIALS:
      return { ...state, invalidCredentials: true, offline: false };
    case VALID_CREDENTIALS:
      return { ...state, invalidCredentials: false };
    case OFFLINE_STATUS:
      return { ...state, offline: true };
    case ONLINE_STATUS:
      return { ...state, offline: false };
    default:
      return state;
  }
};
