import {createTheme, Theme} from "@material-ui/core/styles";

import "../styles.css";

export const primary = "#0b3b5f";
export const secondary = "#fc8535";
export const paleOrange = "#fec29a";
export const red = "#e02020";
export const green = "#65c400";
export const gold = "#f7b500";
export const silver = "#bfbfbf";
export const bronze = "#e39652";
export const lightBlue = "#f1f9ff";
export const backgroundWhite = "#fbfdff";
export const blueGradient = "linear-gradient(255deg, #265d87, #0b3b5f)";
export const finishedBlueGradient = "linear-gradient(247deg,  #60809C, #516F88)";
export const disabledBlueGradient = "linear-gradient(247deg,  #9FB0BD, #A9BCCA)";

const theme: Theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: red,
    },
    grey: {
      50: "#fafafa", //  off-white
      100: "#e8e8e8", //  light-grey
      200: "#e3e7eb", //  pale-blue
      300: "#bfbfbf", //  grey
      400: "#6d7278", //  grey-blue
    },
    text: {
      primary: "#313131",
      secondary: "#fff",
    },
  },
  typography: {
    fontWeightRegular: 400,
    fontWeightBold: 600,
    fontFamily: "catamaranregular",
    subtitle2: {
      fontSize: "16px",
      lineHeight: 1.33,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "16px",
      lineHeight: 1.33,
      fontWeight: 400,
    },
    body2: {
      fontSize: "14px",
      lineHeight: 1.71,
      fontWeight: 600,
    },
    body1: {
      fontSize: "14px",
      lineHeight: 1.71,
      fontWeight: 400,
    },
    h1: {
      fontSize: "32px",
      lineHeight: 1.25,
      fontWeight: 600,
    },
    h2: {
      fontSize: "24px",
      lineHeight: 1.33,
      fontWeight: 600,
    },
    h3: {
      fontSize: "20px",
      lineHeight: 1.2,
      fontWeight: 600,
    },
    h4: {
      fontSize: "20px",
      lineHeight: 1.2,
      fontWeight: 400,
    },
    h5: {
      fontSize: "18px",
      lineHeight: 1.33,
      fontWeight: 600,
    },
    h6: {
      fontSize: "18px",
      lineHeight: 1.33,
      fontWeight: 400,
    },
    caption: {
      fontSize: "12px",
      lineHeight: 1.33,
    },
    button: {
      fontSize: "16px",
      lineHeight: "16px",
      letterSpacing: "1px",
      fontFamily: "robotoregular",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        width: "100%",
        fontWeight: 600,
        "&:hover": {
          backgroundColor: "#fff !important",
        },
      },
      sizeSmall: {
        fontSize: 12,
        width: 120,
      },
      outlined: {
        "&:hover": {
          backgroundColor: "#fff !important",
          borderWidth: "2px !important",
        },
      },
      outlinedPrimary: {
        borderWidth: 2,
        fontSize: 14,
        borderColor: primary,
      },
      outlinedSecondary: {
        borderWidth: 2,
        borderColor: secondary,
      },
      contained: {
        boxShadow: "none",
        "&$disabled": {
          backgroundColor: "#9b9b9b",
          color: "#fff",
        },
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: `${primary} !important`,
        },
      },
      containedSecondary: {
        color: "#ffffff",
        "&:hover": {
          backgroundColor: `${primary} !important`,
        },
      },
    },
    MuiBottomNavigation: {
      root: {
        borderTop: "solid 1px #dedede",
      },
    },
    MuiSvgIcon: {
      root: {
        height: "17px",
        width: "17px",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        padding: 0,
        color: "#fff",
        "&$selected": {
          paddingTop: 0,
          color: "#ff",
        },
        "&$iconOnly": {
          paddingTop: 0,
        },
      },
      iconOnly: {
        paddingTop: 0,
        "&$selected": {
          paddingTop: 0,
        },
      },
    },
    MuiInput: {
      root: {
        fontFamily: "catamaranregular",
      },
      formControl: {
        backgroundColor: "#fff",
        border: "1px solid #e8e8e8",
        color: "#bfbfbf",
        padding: 16,
        paddingTop: 10,
        paddingBottom: 9,
        flexGrow: 1,
        "&:focus": {
          color: "#000",
        },
        "&:hover": {
          borderColor: "#6d7278",
        },
        "&:active": {
          borderColor: "#0091ff",
        },
        "&$disabled": {
          backgroundColor: "#e8e8e8",
          borderColor: "#bfbfbf",
        },
        "&$error": {
          borderColor: red,
        },
      },
      input: {
        color: "#2f2f2f",
        fontWeight: 400,
        "&:focus": {
          color: "#2f2f2f",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "none !important",
        },
        "&:after": {
          borderBottom: "none !important",
        },
      },
    },
    MuiTab: {
      labelIcon: {
        minHeight: 30,
      },
      root: {
        borderRadius: "4px 4px 0 0",
        fontSize: 10,
        fontWeight: 600,
        height: 30,
        minHeight: 30,
        padding: "9px 14px 7px",
        "&$selected": {
          background: disabledBlueGradient,
          borderBottom: 0,
          color: "#fff",
          padding: "9px 20px 7px",
        },
      },
      textColorInherit: {
        color: "#99a9b9",
      },
      wrapper: {
        flexDirection: "row",
      },
    },
    MuiTabs: {
      root: {
        minHeight: 30,
      },
      flexContainer: {
        width: "100%",
        justifyContent: "space-evenly",
      },
      indicator: {
        display: "none",
      },
    },
    MuiRadio: {
      root: {
        height: 20,
        width: 20,
        border: "1px solid #e3e7eb",
        marginRight: 12,
        backgroundColor: "#fff",
        color: "transparent",
        "&$checked": {
          color: secondary,
        },
        position: "relative",
        "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
          width: 24,
          height: 24,
          "& div": {
            position: "absolute",
            top: "-1px",
            left: "-1px",
          },
        },
      },
      colorSecondary: {
        backgroundColor: "#fff !important",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "auto",
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#e3e7eb",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.05)",
        color: "#000",
      },
    },
    MuiSelect: {
      select: {
        paddingLeft: "8px",
        paddingRight: "60px",
        backgroundColor: "#fafafa",
        fontWeight: 400,
        borderRadius: "3px",
        border: "1px solid #e8e8e8",
        "&:hover": {
          border: "1px solid #6d7278",
        },
        "&:active": {
          border: "1px solid #0091ff",
          backgroundColor: "#fafafa",
        },
        "&:focus": {
          border: "1px solid #0091ff",
          backgroundColor: "#fafafa",
        },
        "&$disabled": {
          border: "1px solid #6d7278",
          backgroundColor: "#e3e7eb",
        },
      },
    },
    MuiListItem: {
      button: {
        "&$selected": {
          color: secondary,
          backgroundColor: "#fff",
        },
        "&:hover": {
          backgroundColor: paleOrange,
        },
      },
      root: {
        "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
          height: 48,
        },
      },
    },
    MuiSwitch: {
      root: {
        padding: 8,
        width: "auto",
      },
      switchBase: {
        color: primary,
        "&$checked": {
          transform: "translateX(calc(50% + 9px))",
          "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
            left: "25px",
          },
        },
      },
      colorSecondary: {
        "&$checked": {
          color: green,
          "&:hover": {
            backgroundColor: "#e1f0d1",
          },
        },
      },
      track: {
        width: 50,
        height: 24,
        backgroundColor: "#e3e7eb",
        borderRadius: "15.5px",
        opacity: 1,
        "&$checked": {
          backgroundColor: "#e1f0d1",
        },
      },
      thumb: {
        "&:hover": {
          backgroundColor: primary,
          opacity: 0.08,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: "#BFBFBF",
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, -20.5px)",
        display: "none",
      },
    },
    MuiInputAdornment: {
      root: {
        width: "auto",
        padding: "0px !important",
      },
    },
    MuiChip: {
      root: {
        fontWeight: "normal",
        fontSize: 12,
        paddingTop: 8,
        paddingBottom: 8,

        backgroundColor: "#fafafa",
        border: "1px solid #e3e7eb",
        borderRadius: 4,
        color: "#313131",
        "&:focus": {
          backgroundColor: "#fafafa",
        },
      },
      deletable: {
        backgroundColor: "#fafafa",
        borderRadius: 4,
        color: "#313131",
        "&:focus": {
          backgroundColor: "#fafafa",
        },
      },
      deleteIcon: {
        color: "rgba(255,255,255,0.5)",
        "&:hover": {
          color: "rgba(255,255,255,1)",
        },
      },
      label: {
        paddingRight: 14,
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#6d7278",
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export interface QuopinionTheme extends Theme {}
export interface WithQuopinionTheme extends Theme {
  theme: QuopinionTheme;
}

const enrichedTheme: QuopinionTheme = {
  ...theme,
};

export default enrichedTheme;
