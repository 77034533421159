import React from "react";
import { Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { primary, QuopinionTheme } from "../../../constants/Theme";
import Card from "../../../common/Layout/components/Card";
import { grey } from "@material-ui/core/colors";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    headline: {
      marginTop: theme.spacing(2),
      color: primary,
      textTransform: "uppercase",
    },
    card: {
      backgroundColor: grey[200],
      marginBottom: theme.spacing(4),
      padding: theme.spacing(2),
    },
    info: {
      display: "flex",
      justifyContent: "flex-end",
      width: theme.spacing(25),
      alignItems: "center",
    },
    errorMessage: {
      color: theme.palette.error.main,
    },
  });

interface OwnProps {
  title: string;
  implicationsInfo?: string;
  children?: React.ReactNode;
  errorMessage?: string;
}
type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

const PriceCard: React.FC<Props> = ({
  classes,
  title,
  errorMessage,
  t,
  children,
  implicationsInfo,
}) => {
  return (
    <Card className={classes.card}>
      <Typography variant="body1" className={classes.headline}>
        {title}
      </Typography>
      <div className={classes.wrapper}>
        {children && children}
        {/*TODO add again when functionality there*/}
        {/* <div className={classes.info}>
          {implicationsInfo && <Typography>{implicationsInfo}</Typography>}
        </div>*/}
      </div>
      <Typography className={classes.errorMessage}>{errorMessage}</Typography>
    </Card>
  );
};

export const PriceItem = compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(PriceCard);
