import React, { ChangeEvent, useState } from "react";
import { compose } from "recompose";
import { createStyles, WithStyles, withStyles, Typography, TextField } from "@material-ui/core";
import { QuopinionTheme } from "../../../../constants/Theme";
import Card from "../../../../common/Layout/components/Card";
import StepSlider from "../../../../common/Layout/components/StepSlider";
import Question from "../../../../entities/Question";
import ID from "../../../../entities/ID";
import { Answer, RatingAnswer } from "../../../../entities/SurveyAnswers";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    scaleCard: {
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    },
    scaleNaming: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "-25px",
    },
    scaleValue: {
      display: "flex",
      marginTop: theme.spacing(3),
      alignItems: "center",
      "&::-webkit-scrollbar": "none",
    },
    scaleInput: {
      width: 70,
      marginLeft: theme.spacing(4),
    },
  });

interface OwnProps {
  question: Question;
  currentAnswer?: RatingAnswer;
  handleUserAnswer: (questionID: ID, answer?: Answer) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

const Scale: React.FC<Props> = ({ question, classes, handleUserAnswer, currentAnswer }: Props) => {
  const defaultValue = currentAnswer
    ? currentAnswer.rating
    : // => scale middle point
      question.body.scale.max - (question.body.scale.max - question.body.scale.min) / 2;

  const [value, setValue] = useState<number>(defaultValue);

  const correctValue = (value: number) => {
    if (!value) {
      return 0;
    }
    if (value < question.body.scale.min) {
      return question.body.scale.min;
    }
    if (value > question.body.scale.max) {
      return question.body.scale.max;
    }
    return value;
  };

  const correctValuePreview = (value: number) => {
    if (!value || value < 0) {
      return 0;
    }

    return value;
  };

  return (
    <Card isRaised={true} className={classes.scaleCard}>
      <StepSlider
        min={question.body.scale.min}
        max={question.body.scale.max}
        value={value}
        //@ts-ignore
        onChange={(event: ChangeEvent<{}>, value: number | number[]) => setValue(value as number)}
        onChangeCommitted={(_, rating) =>
          handleUserAnswer(question.id, {
            questionID: question.id,
            type: "rating",
            rating,
          } as RatingAnswer)
        }
      />
      <div className={classes.scaleNaming}>
        <Typography>{question.body.labels.left}</Typography>
        <Typography>{question.body.labels.middle}</Typography>
        <Typography>{question.body.labels.right}</Typography>
      </div>
      {question.body.showNumericFeedback && (
        <div className={classes.scaleValue}>
          <Typography>Gewählter Wert:</Typography>
          <TextField
            value={value || 0}
            type="decimal"
            classes={{ root: classes.scaleInput }}
            //@ts-ignore
            onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(correctValue(parseInt(event.target.value, 10)));
              handleUserAnswer(question.id, {
                questionID: question.id,
                type: "rating",
                rating: correctValue(parseInt(event.target.value, 10)),
              } as RatingAnswer);
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(correctValuePreview(parseInt(event.target.value, 10)));
            }}
          />
        </div>
      )}
    </Card>
  );
};

export default compose<Props, OwnProps>(withStyles(styles))(Scale);
