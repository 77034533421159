import React, { ReactNode } from "react";
import { FormControlLabel, Radio } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../../constants/Theme";
import Card from "../../../../common/Layout/components/Card";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    form: {
      padding: theme.spacing(6),
    },
    control: {
      marginLeft: theme.spacing(0),
    },
    label: {
      textTransform: "uppercase",
      fontWeight: 600,
    },
    children: {
      padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    },
  });

interface OwnProps {
  children?: ReactNode;
  title: string;
  checked: boolean;
  handleSelection: () => void;
}
type Props = OwnProps & WithStyles<typeof styles>;

const CardWithStyles: React.FC<Props> = ({
  classes,
  children,
  title,
  checked,
  handleSelection,
}) => {
  return (
    <Card isRaised={false} className={classes.form}>
      <FormControlLabel
        control={<Radio color="secondary" onChange={handleSelection} />}
        label={title}
        checked={checked}
        classes={{ root: classes.control, label: classes.label }}
      />
      {children && <div className={classes.children}>{children}</div>}
    </Card>
  );
};

export const RadioCard = withStyles(styles)(CardWithStyles);
