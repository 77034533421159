import React, { Component, PureComponent } from "react";
import FormInput from "./FormInput";
import * as Yup from "yup";
import { translations } from "../../../../../constants/lang/translation";
import { QuestionProps, QuestionTypeProps } from "./Question";
import AnswerOption, { FieldTypes } from "../../../../../entities/AnswerOption";
import Question from "../../../../../entities/Question";
import QuestionPreview from "../../../../../common/Preview/QuestionPreview";
//import SwitchComponent from "../../../../../common/Layout/components/Switch";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import isEqual from "lodash/isEqual";

export const QuestionValidationSingleChoice = ({
  t,
  otherQuestions,
  questionIndex,
}: QuestionProps) => {
  return Yup.object().shape({
    text: Yup.string()
      .required(t(translations.questionnaire.validation.title))
      .max(240, t(translations.questionnaire.validation.titleMaxLength)),
    body: Yup.object().shape({
      answerOptions: Yup.array()
        .required(t(translations.questionnaire.validation.answers.minCount))
        .min(2, t(translations.questionnaire.validation.answers.minCount))
        .max(12, t(translations.questionnaire.validation.answers.maxCount))
        .of(
          Yup.object().shape({
            label: Yup.string()
              .required(t(translations.questionnaire.validation.answers.requiredTitle))
              .max(90, t(translations.questionnaire.validation.answers.length)),
            skipTo: Yup.mixed().oneOf(
              [
                ...otherQuestions
                  .filter((q, index) => index >= questionIndex)
                  .map((question) => question.id),
                "end",
              ],
              t(translations.questionnaire.validation.answers.skipTo)
            ),
          })
        ),
    }),
  });
};

type Props = QuestionTypeProps & WithTranslation;

class QuestionFormSingleChoiceCore extends Component<Props> {
  shouldComponentUpdate(nextProps: Readonly<QuestionTypeProps>): boolean {
    return !isEqual(nextProps, this.props);
  }

  handleInputChange(index: number, newAnswerOption: AnswerOption) {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.props.body.answerOptions.map((answerOption, answerOptionIndex) =>
        answerOptionIndex === index ? newAnswerOption : answerOption
      ),
    });
  }

  addAnswerItem = (answerOptions: AnswerOption[], index: number) => {
    answerOptions.splice(index + 1, 0, new AnswerOption());
    return answerOptions;
  };

  handleAddAnswer = (index: number) => {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.addAnswerItem([...this.props.body.answerOptions], index),
    });
  };

  handleDeleteAnswer = (indexToDelete: number) => {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.props.body.answerOptions.filter(
        (answerOption, answerOptionIndex) => answerOptionIndex !== indexToDelete
      ),
    });
  };

  handleToggleAdditionalFreetext = (index: number) => {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.props.body.answerOptions.map((answerOption, answerOptionIndex) => {
        if (answerOptionIndex === index) {
          return this.props.body.answerOptions[index].additionalFieldType === FieldTypes.TEXT
            ? {
                ...answerOption,
                additionalFieldType: FieldTypes.NONE,
              }
            : { ...answerOption, additionalFieldType: FieldTypes.TEXT };
        } else {
          return answerOption;
        }
      }),
    });
  };

  handleToggleAdditionalNumericalField = (index: number) => {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.props.body.answerOptions.map((answerOption, answerOptionIndex) => {
        if (answerOptionIndex === index) {
          return this.props.body.answerOptions[index].additionalFieldType === FieldTypes.NUMBER
            ? {
                ...answerOption,
                additionalFieldType: FieldTypes.NONE,
              }
            : { ...answerOption, additionalFieldType: FieldTypes.NUMBER };
        } else {
          return answerOption;
        }
      }),
    });
  };

  handleOtherPossibilities = () => {
    this.props.handleChange({
      ...this.props.body,
      additionalAnswerEnabled: !this.props.body.additionalAnswerEnabled,
    });
  };

  render() {
    const { /*body, t, */ id } = this.props;
    return (
      <>
        {this.props.body.answerOptions.map((answerOption, index) => (
          <FormInput
            answerOption={answerOption}
            otherQuestions={this.props.otherQuestions}
            questionIndex={this.props.questionIndex}
            handleChange={this.handleInputChange.bind(this, index)}
            index={index}
            key={answerOption.id}
            deleteAnswer={this.handleDeleteAnswer.bind(this, index)}
            addAnswer={this.handleAddAnswer.bind(this, index)}
            amountOfAnswers={this.props.body.answerOptions.length}
            addAdditionalFreetext={this.handleToggleAdditionalFreetext}
            type={this.props.type}
            questionId={id}
            preventMediaUpload={this.props.preventMediaUpload}
            preventAlterationOfAnswerCount={this.props.preventAlterationOfAnswerCount}
            preventQuestionSkip={this.props.preventQuestionSkip}
            multiline={true}
            conditions={this.props.conditions}
            addAdditionalNumericalField={this.handleToggleAdditionalNumericalField.bind(
              this,
              index
            )}
          />
        ))}
      </>
    );
  }
}

export const QuestionFormSingleChoice = compose<Props, QuestionTypeProps>(withTranslation())(
  QuestionFormSingleChoiceCore
);

export class QuestionPreviewSingleChoice extends PureComponent<Omit<Question, "toDataJson">> {
  render() {
    const { text: title, body, type, media } = this.props;
    return (
      <QuestionPreview
        title={title}
        body={body}
        type={type}
        media={media}
        questionNumber={1}
        totalQuestionNumber={1}
      />
    );
  }
}
