import React, { PureComponent } from "react";
import { QuopinionTheme } from "../../../constants/Theme";
import { createStyles, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import Container from "../../../common/Layout/Container";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "../../../common/Layout/components/Button";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { requestSessionToken } from "../../../modules/session";
import { route as ParticipantPasswordForgottenRoute } from "../PasswordForgotten";
import { route as ParticipantRegistrationRoute } from "../Register";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import { SnackbarComponent } from "../../../common/Layout/components/Snackbar";
import { clearState } from "../../../util/localStorage";

interface OwnProps {
  dispatch?: any;
}

interface DispatchProps {
  requestSessionToken: any;
}

interface StateProps {
  participationIntention?: boolean;
  nextPage: string;
  passNextPage?: any;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      textAlign: "center",
    },
    inputLabel: {
      color: theme.palette.grey[400],
      top: "-8px",
      "&$focused": {
        top: "0px !important",
      },
    },
    input: {
      width: 290,
      "@media(min-width: 350px)": {
        width: 323,
      },
      marginBottom: theme.spacing(4),
    },
    inputArea: {
      margin: `${theme.spacing(6)}px 0`,
      display: "flex",
      flexDirection: "column",
    },
    buttonArea: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    forgottenButton: {
      marginTop: theme.spacing(4),
    },
    headline: {
      marginBottom: theme.spacing(6),
    },
    version: {
      color: "#999",
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps;

export const route: string = "/participant/login";

class ParticipantLoginPage extends PureComponent<Props, { showNotification: boolean, showDeleteInfo: boolean }> {
  state = { showNotification: true, showDeleteInfo: true};
  readonly initialValues = {
    email: "",
    password: "",
  };

  componentDidMount() {
    // clear session
    clearState();
  }

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      email: Yup.string()
        .trim()
        .email(t(translations.validation.login.emailNotValid))
        .required(t(translations.validation.login.emailRequiredParticipant)),
      password: Yup.string().required(t(translations.validation.login.passwordRequiredParticipant)),
    });
  };

  openPasswordForgotten = () => {
    this.props.history.push(ParticipantPasswordForgottenRoute);
  };
  openRegistration = () => {
    this.props.history.push(ParticipantRegistrationRoute);
  };

  closeNotification = () => {
    this.setState({ showNotification: false });
  };

  closeDeleteAccountSnackbar = () => {
    this.setState({ showDeleteInfo: false })
    localStorage.removeItem("deleted");
  }

  render() {
    const {
      classes,
      t,
      participationIntention,
      history,
      nextPage,
      location: { search },
    } = this.props;

    const emailVerified = new URLSearchParams(search).get("emailVerified");
   const deleted =  localStorage.getItem("deleted");

    return (
      <>
        {this.state.showNotification && emailVerified ? (
          <SnackbarComponent
            title={t(translations.login.emailVerified.title)}
            message={t(translations.login.emailVerified.message)}
            type="success"
            onClose={() => {
              this.closeNotification();
            }}
          />
        ) : null}

        {this.state.showDeleteInfo && deleted ===  "true" && (
          <SnackbarComponent
            title={"Account gelöscht"}
            message={"Bitte schau in dein Postfach. Du erhälst eine Bestätigungsemail. Dies kann einige Minuten dauern."}
            type="success"
            onClose={() => {
              this.closeDeleteAccountSnackbar();
            }}
          />
        )}

        <LogoBarParticipant />
        <Container centered={true}>
          <div className={classes.root}>
            <Typography variant="h1" color="secondary" className={classes.headline}>
              {t(translations.login.headline)}
            </Typography>
            <Typography variant="body1">{t(translations.login.text)}</Typography>
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(true);
                this.props
                  .requestSessionToken(values.email, values.password)
                  .then(() => {
                    history.push(participationIntention ? "/questionnaire" : nextPage);
                    setSubmitting(false);
                  })
                  .catch(() => {
                    setSubmitting(false);
                    setErrors({
                      email: t(translations.validation.login.emailCheckParticipant),
                      password: t(translations.validation.login.passwordCheckParticipant),
                    });
                  });
              }}
            >
              {(formikProps) => {
                const {
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  isSubmitting,
                } = formikProps;
                return (
                  <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                  >
                    <div className={classes.inputArea}>
                      <TextField
                        name="email"
                        onChange={handleChange("email")}
                        onBlur={handleBlur("email")}
                        value={values.email}
                        required={true}
                        error={touched.email && Boolean(errors.email)}
                        placeholder={t(translations.login.userPlaceholder)}
                        label={t(translations.login.userPlaceholder)}
                        InputLabelProps={{ shrink: true, classes: { root: classes.inputLabel } }}
                        className={classes.input}
                        helperText={
                          errors.email && touched.email ? (
                            <Typography variant="body1">{errors.email}</Typography>
                          ) : (
                            ""
                          )
                        }
                      />
                      <TextField
                        name="password"
                        type="password"
                        autoComplete={"password"}
                        onChange={handleChange("password")}
                        onBlur={handleBlur("password")}
                        value={values.password}
                        required={true}
                        error={touched.password && Boolean(errors.password)}
                        placeholder={t(translations.login.passwordPlaceholder)}
                        label={t(translations.login.passwordPlaceholder)}
                        InputLabelProps={{ shrink: true, classes: { root: classes.inputLabel } }}
                        className={classes.input}
                        helperText={
                          errors.password && touched.password ? (
                            <Typography variant="body1">{errors.password}</Typography>
                          ) : (
                            ""
                          )
                        }
                      />
                    </div>
                    <div className={classes.buttonArea}>
                      <Button
                        contained={true}
                        color="secondary"
                        size="big"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                        fullWidth={true}
                      >
                        {t(translations.action.login)}
                      </Button>
                      <Button
                        color="secondary"
                        size="medium"
                        disabled={isSubmitting}
                        onClick={this.openPasswordForgotten}
                        fullWidth={true}
                        className={classes.forgottenButton}
                        type="submit"
                      >
                        {t(translations.login.forgottenPassword)}
                      </Button>
                      <Button
                        color="secondary"
                        size="medium"
                        disabled={isSubmitting}
                        onClick={this.openRegistration}
                        fullWidth={true}
                        className={classes.forgottenButton}
                        type="submit"
                      >
                        Als neuer Benutzer registrieren
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          {process.env.NODE_ENV === "development" && (
            <Typography variant="body2" className={classes.version}>
              v{process.env.REACT_APP_VERSION}
            </Typography>
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ navigation, participation }: RootState) => ({
  nextPage: navigation.postLoginPage,
  participationIntention: participation.participationIntention,
});

const mapDispatchToProps = {
  requestSessionToken,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(ParticipantLoginPage);
