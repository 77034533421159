import React, {Component} from "react";
import {createStyles, Typography, WithStyles, withStyles} from "@material-ui/core";
import {blueGradient, primary, QuopinionTheme} from "../../../constants/Theme";
import {translations} from "../../../constants/lang/translation";
import {compose} from "recompose";
import {withTranslation, WithTranslation} from "react-i18next";
import {de} from "date-fns/locale";

import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {getParticipations, intendParticipation, startParticipation,} from "../../../modules/participation";
import Card from "../../../common/Layout/components/Card";
import Button from "../../../common/Layout/components/Button";
import Badge from "../../../common/Layout/components/Badge";
import {Check, Cross, Play} from "../../../assets/icon";
import Survey, {formatDuration} from "../../../entities/Survey";
import {route as SurveyOverviewRoute} from "../../SurveyOverview";
import {route as QuestionnaireRoute} from "../Questionnaire";
import {ParticipationState} from "../../../services/Survey";
import {addSeconds, format, formatDistance} from "date-fns";
import {Restore} from "@material-ui/icons";
import moment from "moment";
import classNames from "classnames";

interface OwnProps {
  participationState: ParticipationState;
  credit?: boolean;
  survey: Survey;
}

interface StateProps {
  isAuthenticated: boolean;
  intendParticipation: typeof intendParticipation;
  startParticipation: typeof startParticipation;
  getParticipations: typeof getParticipations;
  openSurveys: Survey[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    actionWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    attributeTitle: {
      color: (props: OwnProps) =>
        props.participationState === "PARTICIPATION_ACCEPTED" ? "#fff" : "#000",
      display: "flex",
      alignItems: "center",
      marginLeft: "-4px",
      alignSelf: "right",
    },
    iconSpace: {
      marginRight: theme.spacing(2),
      height: 24,
      width: 24,
      color: (props: OwnProps) =>
        props.participationState === "PARTICIPATION_ACCEPTED" ? "#fff" : theme.palette.grey[400],
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 20,
      padding: theme.spacing(4),
      background: (props: OwnProps) =>
        props.participationState === "PARTICIPATION_ACCEPTED" ? blueGradient : "#fff",
      "&:last-of-type": {
        marginBottom: theme.spacing(12.5),
      },
    },
    duration: {
      color: (props: OwnProps) =>
        props.participationState === "PARTICIPATION_ACCEPTED" ? "#fff" : theme.palette.grey[400],
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(4)}px`,
    },
    badge: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      width: "fit-content",
      float: "right",
      position: "absolute",
      right: 0,
    },
    waitlistedText: {
      opacity: 0.8,
      width: "70%",
    },
    header: {
      height: (props: OwnProps) =>
        props.participationState === "PARTICIPATION_ACCEPTED" ? 40 : 20,
      position: "relative",
      display: "flex",
    },
    title: {
      wordBreak: "break-word",
      marginTop: theme.spacing(3),
    },
    declined: {
      textDecoration: "line-through",
    },
  });

type Props = OwnProps &
  StateProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps;

class SurveyItem extends Component<Props> {
  openSurveyDescription = async () => {
    const {
      survey: { id },
      history,
      openSurveys,
    } = this.props;
    try {
      this.props.getParticipations();
      if (openSurveys.length >= 1) {
        this.props.intendParticipation();
        history.push(`${SurveyOverviewRoute}/${id}`);
      }
    } catch (e) {
      console.log(e);
      history.push("/participant/dashboard");
    }
  };

  handleCardClick = (
    participationState: ParticipationState,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    if (participationState === "PARTICIPATION_OPEN") {
      this.openSurveyDescription();
    }
    if (participationState === "PARTICIPATION_ACCEPTED") {
      this.startParticipation(event);
    }
  };

  startParticipation = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const {
      survey: {id},
      history,
    } = this.props;
    this.props.startParticipation(id, new Set());

    history.push(`${QuestionnaireRoute}/${id}`);
  };

  render() {
    const {
      survey: {
        title,
        duration,
        durationSecondsToComplete,
        numberOfQuestions,
        price,
        endDate,
        surveyEndedAt,
        acceptedAt,
        completedAt,
        id,
      },
      classes,
      participationState,
      t,
    } = this.props;

    let countdownString = "";
    switch (participationState) {
      case "PARTICIPATION_ACCEPTED":
        countdownString = formatDistance(
          new Date(),
          addSeconds(new Date(acceptedAt!), durationSecondsToComplete!),
          {
            locale: de,
          }
        );
        break;
      case "PARTICIPATION_OPEN":
        countdownString = formatDistance(new Date(endDate), new Date(), {locale: de});
        break;
    }
    return (
      <Card isRaised={true} className={classes.wrapper}>
        <div
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            this.handleCardClick(participationState, event)
          }
        >
          <div className={classes.header}>
            {participationState === "PARTICIPATION_OPEN" && (
              <>
                <Typography color="primary" variant="body2">
                  Noch {countdownString}
                </Typography>
                <Typography color="primary" variant="body1" style={{marginLeft: 4}}>
                  - {t(translations.headerSurvey.till)} {format(new Date(endDate), "dd.MM.yyyy")}
                </Typography>
              </>
            )}
            {participationState === "PARTICIPATION_COMPLETED" && (
              <Badge
                title={"abgeschlossen"}
                color="senary"
                className={classes.badge}
                icon={<Check fill="#fff" />}
              />
            )}
            {participationState === "PARTICIPATION_MISSED" && (
              <Badge
                title={t(translations.headerSurvey.over)}
                color="quinary"
                className={classes.badge}
                icon={<Restore style={{ color: "#fff" }} />}
              />
            )}
            {participationState === "PARTICIPATION_DECLINED" && (
              <Badge
                title={"abgelehnt"}
                color="quinary"
                className={classes.badge}
                icon={<Cross fill="#fff"/>}
              />
            )}
            {participationState === "PARTICIPATION_REJECTED" && (
              <Badge
                title={"abgewiesen"}
                color="quinary"
                className={classes.badge}
                icon={<Cross fill="#fff"/>}
              />
            )}
          </div>
          <Typography
            className={classes.title}
            variant="subtitle2"
            color={
              participationState === "PARTICIPATION_ACCEPTED" ? "textSecondary" : "textPrimary"
            }
          >
            {title}
          </Typography>
          <Typography variant="subtitle1" color="inherit" className={classes.duration}>
            {numberOfQuestions}&nbsp;
            {numberOfQuestions !== undefined &&
              t(translations.questions.question, { count: numberOfQuestions })}{" "}
            - {formatDuration(duration, t, true)}
          </Typography>
          <div className={classes.actionWrapper}>
            {participationState === "PARTICIPATION_OPEN" && (
              <>
                <Button
                  contained={true}
                  size="small"
                  color="secondary"
                  icon={<Play fill="#fff" width={12} height={12}/>}
                  id={`Participate-in-${id}`}
                >
                  {t(translations.action.takepart)}
                </Button>
              </>
            )}
            {participationState === "PARTICIPATION_ACCEPTED" && (
              <>
                <Button
                  contained={false}
                  size="small"
                  color="primary"
                  icon={<Play fill={primary} width={12} height={12}/>}
                  white={true}
                >
                  {t(translations.action.continue)}
                </Button>
              </>
            )}
            {participationState === "PARTICIPATION_WAITLISTED" && (
              <Typography variant="body1" className={classes.waitlistedText}>
                {t(translations.action.waitlistedSurvey)}
              </Typography>
            )}
            {participationState === "PARTICIPATION_COMPLETED" && (
              <Typography variant="body1" className={classes.waitlistedText}>
                {moment(completedAt).format("DD.MM.YYYY")} {moment(completedAt).format("HH:mm")}
              </Typography>
            )}
            {participationState === "PARTICIPATION_DECLINED" && (
              <div>{/* Align following right */}</div>
            )}
            {participationState === "PARTICIPATION_MISSED" && (
              <Typography variant="body1" className={classes.waitlistedText}>
                {t(translations.headerSurvey.answerTime.missedSurvey, {
                  end: `${moment(surveyEndedAt).format("DD.MM.YYYY")} ${t(
                    translations.headerSurvey.answerTime.at
                  )} ${moment(surveyEndedAt).format("HH:mm")} ${t(
                    translations.headerSurvey.answerTime.oClock
                  )}`,
                })}
              </Typography>
            )}
            {participationState === "PARTICIPATION_REJECTED" && (
              <Typography variant="body1" className={classes.waitlistedText}>
                Die Angaben passten nicht zu den Angaben im
                Profil {moment(acceptedAt).format("DD.MM.YYYY")} {moment(acceptedAt).format("HH:mm")}
              </Typography>
            )}

            <div className={classes.attributeTitle}>
              <Typography
                variant="h4"
                color="inherit"
                className={classNames({
                  [classes.declined]:
                  participationState === "PARTICIPATION_REJECTED" ||
                  participationState === "PARTICIPATION_DECLINED" ||
                  participationState === "PARTICIPATION_MISSED",
                })}
              >
                {price}
              </Typography>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = ({ session, participation }: { session: any; participation: any }) => ({
  isAuthenticated: Boolean(session.authenticated),
  openSurveys: participation.openSurveys,
});

export default compose<Props, OwnProps>(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, { intendParticipation, startParticipation, getParticipations })
)(SurveyItem);
