import React, { PureComponent } from "react";
import { QuopinionTheme } from "../../../../constants/Theme";
import { createStyles, TextField, Typography, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { translations } from "../../../../constants/lang/translation";
import { Field, Formik } from "formik";
import Card from "../../../../common/Layout/components/Card";
import AttributeSelect from "../../../../common/Layout/components/AttributeSelect";
import { SalutationOptions } from "../../Register/RegisterForm";
import Button from "../../../../common/Layout/components/Button";
import { RootState } from "../../../../modules";
import { connect } from "react-redux";
import { compose } from "recompose";
import Researcher from "../../../../entities/Researcher";
import { fetchCurrentResearcher, updateCurrentResearcher } from "../../../../modules/user";
import IndustryOptions from "../../../../constants/IndustryOptions";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import { Tip } from "../components/TipComponent";

interface OwnProps {}
interface StateProps {
  currentResearcher: Researcher;
  isLoading: boolean;
}

interface DispatchProps {
  fetchCurrentResearcher: () => Promise<void>;
  updateCurrentResearcher: (researcher: Researcher) => Promise<void>;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(6),
      padding: `${theme.spacing(6)}px ${theme.spacing(6)}px ${theme.spacing(8)}px`,
    },
    cardTitle: {
      marginBottom: theme.spacing(2),
      textTransform: "uppercase",
    },
    input: {
      marginTop: theme.spacing(4),
      width: "100%",
      "@media (min-width: 600px)": {
        "&:first-of-type": {
          marginRight: theme.spacing(5),
        },
      },
    },
    buttonLine: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(7),
      alignItems: "center",
    },
  });

interface State {
  send: boolean;
  error: boolean;
  success: boolean;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WithTranslation;

class PersonalDataForm extends PureComponent<Props, State> {
  state: State = {
    send: false,
    error: false,
    success: false,
  };

  initialValues = {
    firstName: this.props.currentResearcher.firstName,
    lastName: this.props.currentResearcher.lastName,
    salutation: {
      label:
        SalutationOptions!.find(
          (option) => option.value === this.props.currentResearcher!.researcherDetails!.salutation
        )!.label || "Frau",
      value: this.props.currentResearcher.researcherDetails.salutation || "FRAU",
    },
    position: this.props.currentResearcher.researcherDetails.position,
    industry: {
      label:
        (this.props.currentResearcher.organizationDetails.industry !== "" &&
          IndustryOptions.find(
            (option) => option.value === this.props.currentResearcher.organizationDetails.industry
          )!.label) ||
        "",
      value: this.props.currentResearcher.organizationDetails.industry || "",
    },
  };

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      firstName: Yup.string()
        .min(2, t(translations.register.personalInformation.validationName))
        .required(t(translations.register.personalInformation.fornameRequired)),
      lastName: Yup.string()
        .min(2, t(translations.register.personalInformation.validationName))
        .required(t(translations.register.personalInformation.surnameRequired)),
      salutation: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().required(
          t(translations.register.personalInformation.salutationRequired)
        ),
      }),
      position: Yup.string().required(
        t(translations.register.personalInformation.positionRequired)
      ),
      industry: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().required(t(translations.register.personalInformation.industryRequired)),
      }),
    });
  };

  handleChange(handleChange: (field: string) => (value: string) => void, field: string) {
    return (values: string[]) => {
      if (values.length) {
        handleChange(field)(values[0]);
      }
    };
  }

  componentDidMount = async () => {
    await this.props.fetchCurrentResearcher();
  };

  render() {
    const { classes, t, updateCurrentResearcher, isLoading } = this.props;
    if (isLoading) {
      return <LoadingOverlay />;
    }

    this.initialValues = {
      firstName: this.props.currentResearcher.firstName,
      lastName: this.props.currentResearcher.lastName,
      salutation: {
        label:
          SalutationOptions!.find(
            (option) => option.value === this.props.currentResearcher!.researcherDetails!.salutation
          )!.label || "Frau",
        value: this.props.currentResearcher.researcherDetails.salutation || "FRAU",
      },
      position: this.props.currentResearcher.researcherDetails.position,
      industry: {
        label:
          (this.props.currentResearcher.organizationDetails.industry !== "" &&
            IndustryOptions.find(
              (option) => option.value === this.props.currentResearcher.organizationDetails.industry
            )!.label) ||
          "",
        value: this.props.currentResearcher.organizationDetails.industry || "",
      },
    };

    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={this.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const newResearcherData = {
            ...this.props.currentResearcher,
            firstName: values.firstName,
            lastName: values.lastName,
            researcherDetails: {
              salutation: values.salutation.value,
              position: values.position,
            },
            organizationDetails: {
              ...this.props.currentResearcher.organizationDetails,
              industry: values.industry.value,
            },
          };
          updateCurrentResearcher(new Researcher(newResearcherData))
            .then(() =>
              this.setState({
                send: true,
                success: true,
              })
            )
            .catch(() =>
              this.setState({
                send: false,
                error: true,
              })
            );
          setSubmitting(false);
        }}
      >
        {(formikProps) => {
          const {
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting,
          } = formikProps;
          return (
            <form>
              <Card isRaised={true} className={classes.card}>
                <Typography variant="body2" className={classes.cardTitle}>
                  {t(translations.pages.dashboard.researcher.links.personalInfo)}
                </Typography>
                <Field
                  name="salutation.value"
                  render={({ field }: any) => (
                    <AttributeSelect
                      {...field}
                      placeholder={t(translations.register.personalInformation.salutation)}
                      handleSelection={this.handleChange(
                        handleChange as (field: string) => (value: string) => void,
                        "salutation.value"
                      )}
                      selectOptions={SalutationOptions}
                      selectedValues={
                        values.salutation.value.length ? [values.salutation.value] : []
                      }
                      onBlur={handleBlur("salutation.value")}
                      required={true}
                      multiple={false}
                      error={touched.salutation && Boolean(errors.salutation)}
                    />
                  )}
                />
                <TextField
                  name={"firstName"}
                  value={values.firstName}
                  onChange={handleChange("firstName")}
                  onBlur={handleBlur("firstName")}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName ? `${errors.firstName}` : ""}
                  placeholder={t(translations.register.personalInformation.forename)}
                  className={classes.input}
                />
                <TextField
                  name={"lastName"}
                  value={values.lastName}
                  onChange={handleChange("lastName")}
                  onBlur={handleBlur("lastName")}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName ? `${errors.lastName}` : ""}
                  placeholder={t(translations.register.personalInformation.surname)}
                  className={classes.input}
                />
                <Field
                  name="industry.value"
                  render={({ field }: any, form: any) => (
                    <AttributeSelect
                      {...field}
                      {...form}
                      multiple={false}
                      placeholder={t(translations.register.personalInformation.industry)}
                      handleSelection={this.handleChange(
                        handleChange as (field: string) => (value: string) => void,
                        "industry.value"
                      )}
                      selectOptions={IndustryOptions}
                      selectedValues={values.industry.value.length ? [values.industry.value] : []}
                      onBlur={handleBlur("industry.value")}
                      required={true}
                      error={touched.industry && Boolean(errors.industry)}
                    />
                  )}
                />
                <TextField
                  name="position"
                  value={values.position}
                  onChange={handleChange("position")}
                  onBlur={handleBlur("position")}
                  error={touched.position && Boolean(errors.position)}
                  helperText={touched.position && errors.position ? `${errors.position}` : ""}
                  placeholder={t(translations.register.personalInformation.position)}
                  className={classes.input}
                />
              </Card>

              <div className={classes.buttonLine}>
                {this.state.send && (
                  <>
                    {this.state.success && (
                      <Tip text={t(translations.confirmation.researcher.success)} />
                    )}
                    {this.state.error && (
                      <Tip text={t(translations.confirmation.researcher.failure)} />
                    )}
                  </>
                )}
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  color="secondary"
                  size="big"
                  contained={true}
                  type="submit"
                >
                  {t(translations.action.save)}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}
const mapStateToProps = ({ user }: RootState) => ({
  currentResearcher: user.currentResearcher,
  isLoading: user.isLoading,
});
const mapDispatchToProps = {
  fetchCurrentResearcher,
  updateCurrentResearcher,
};

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation(),
  connect<{}, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(PersonalDataForm);
