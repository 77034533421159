import ID from "./ID";
import Attribute from "./Attribute";
import { FlatCategory } from "./Category";

export class IndividualAttributeOption {
  id: ID;
  label: string;
  val: boolean;

  constructor(obj: any = {}) {
    this.id = typeof obj === "object" ? obj.id : obj;
    this.label = typeof obj.label === "string" ? obj.label : obj;
    this.val = typeof obj.val === "boolean" ? obj.val : true;
  }
}

/**
 * Domain entity class IndividualAttribute
 * which represents a region that can be used for a panel selection
 */
export default class IndividualAttribute {
  /*
   ** accepts a panel criteria and extracts the individual attributes as IndividualAttribute[]
   */
  static extractIndividualAttributesFromCriteria(
    criteria: any,
    attributes: Attribute[]
  ): IndividualAttribute[] {
    // first we take ALL individual attributes in order to enrich the criteria attributes
    // (because we have only ids in the criteria attributes
    // which are not easy to read for humans - unfortunately)

    // individual attributes are all that are not level BASIC
    const individualAttributePool = attributes.filter(
      (attribute: Attribute) => attribute.level !== "BASIC"
    );

    // criteria are the ones from the actual Panel
    // every panelAttribute is prefixed with criteria from here on
    return criteria
      .map((criteria: any) => {
        // is there an equivalent individual attribute for this criteria?
        const attributeMatch: Attribute | undefined = individualAttributePool.find(
          (attribute: Attribute) => attribute.id === criteria.attributeId
        );
        if (attributeMatch) {
          // if so... we need to transform it to a so called IndividualAttribute,
          // that our (frontend) Panel understands
          return new IndividualAttribute({
            ...attributeMatch,
            answerOptions: attributeMatch.question.body.answerOptions
              .map((answerOption: any) => {
                // wat?
                // the frontend structure only considers included or excluded values
                // so we want to add all answerOptions, that are actually answered
                // we also pass the val (the answer) which can be true or false (for included or excluded)
                const matchedCriteriaValue = criteria.value.find(
                  (criteriaAnswer: any) => criteriaAnswer.value === answerOption.id
                );

                if (matchedCriteriaValue) {
                  // answerOption was answered - let's restore it
                  return {
                    ...answerOption,
                    val: matchedCriteriaValue.included,
                  };
                } else {
                  // this answerOption wasn't answered - so we don't need it
                  return null;
                }
              })
              .filter(Boolean),
          });
        } else {
          // nevermind... this attribute is probably a BASIC attribute and will be handled below
          return null;
        }
      })
      .filter(Boolean);
  }

  id: ID;
  categories: string[];
  question: string;
  questionText: string;
  answerOptions: IndividualAttributeOption[];

  constructor(obj: any) {
    this.id = obj.id;
    this.categories = obj.categories.map((category: FlatCategory | string) =>
      typeof category === "string" ? category : category.name
    );
    this.questionText = obj.question && obj.question.text;
    this.question = obj.name || obj.question;
    this.answerOptions =
      obj.answerOptions ||
      (obj.question && obj.question.body && obj.question.body.answerOptions) ||
      [];
  }

  toDataJson = () => {
    return {
      attributeId: this.id,
      type: "textList",
      value: this.answerOptions.map((answerOption) => ({
        value: answerOption.id,
        included: answerOption.val,
      })),
      percentage: 100,
    };
  };
}
