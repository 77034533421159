import React from "react";
import { withStyles, WithStyles, createStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../../constants/Theme";
import { Typography } from "@material-ui/core";

interface OwnProps {
  title: string;
  value: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px ${theme.spacing(0.75)}px`,
      "&:last-of-type": {
        borderRight: `1px solid ${theme.palette.grey[100]}`,
      },
      "@media(max-width:1024px)": {
        "&:last-of-type": {
          borderRight: 0,
        },
      },
      flexBasis: "33.3%",
    },
    title: {
      color: theme.palette.grey[400],
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

const OrderInfoItem: React.FC<Props> = ({ classes, title, value }) => {
  return (
    <div className={classes.root}>
      <Typography variant="caption" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="subtitle2">{value}</Typography>
    </div>
  );
};

export const OrderInfo = compose<Props, OwnProps>(withStyles(styles))(OrderInfoItem);
