import { Dispatch } from "redux";

const SET_POST_LOGIN_PAGE = "navigation/SET_POST_LOGIN_PAGE";
const CLEAR_POST_LOGIN_PAGE = "navigation/CLEAR_POST_LOGIN_PAGE";

interface NavigationState {
  postLoginPage: string;
}

const defaultState: NavigationState = {
  postLoginPage: "/dashboard",
};

interface NavigationAction {
  type: string;
  pathname: string;
}

export const setPostLoginPage = (nextPage: string) => ({
  type: SET_POST_LOGIN_PAGE,
  pathname: nextPage,
});

export const clearPostLoginPage = () => ({
  type: CLEAR_POST_LOGIN_PAGE,
  pathname: "",
});

export const passNextPage = (nextPage: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setPostLoginPage(nextPage));
  };
};

export const reducer = (
  state: NavigationState = defaultState,
  action: NavigationAction
): NavigationState => {
  switch (action.type) {
    case SET_POST_LOGIN_PAGE:
      return { ...state, postLoginPage: action.pathname };
    case CLEAR_POST_LOGIN_PAGE:
      return { ...state, postLoginPage: action.pathname };
    default:
      return state;
  }
};
