import React, { PureComponent } from "react";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import Container from "../../../common/Layout/Container";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";
import DataProtectionParticipant from "./Privacy";

interface OwnProps {}

const styles = (theme: QuopinionTheme) => createStyles({});

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

export const route = "/participant/privacy";

class ParticipantPrivacy extends PureComponent<Props> {
  render() {
    return (
      <>
        <LogoBarParticipant backButton={true} hideLogo={true} />
        <ContentWrapper>
          <Container centered={true}>
            <DataProtectionParticipant />
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(ParticipantPrivacy);
