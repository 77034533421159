import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Router } from "react-router-dom";
import { fetchUser, fetchUserAndFetchBasicAttributes } from "./modules/user";
import "./styles.css";

import AdminDashboardPage, { route as AdminDashboardRoute } from "./pages/admin/Dashboard";
import AdminAttributeListPage, {
  route as AdminAttributeListRoute,
} from "./pages/admin/Attributes/AttributeList";
import AdminAttributeFormPage, {
  route as AdminAttributeFormRoute,
} from "./pages/admin/Attributes/AttributeForm";
import AdminExternalAttributeOverview, {
  route as AdminExternalAttributeRoute,
} from "./pages/admin/Attributes/ExternalAttributeOverview";
import DashboardPage, { route as ParticipantDashboardRoute } from "./pages/participant/Dashboard";
import SurveyOverviewPage, { route as SurveyOverviewRoute } from "./pages/SurveyOverview";
import AttributePage, { route as AttributeRoute } from "./pages/participant/Attributes";
import CategoryLevelOverview, {
  route as CategoryLevelOverviewRoute,
} from "./pages/participant/Attributes/CategoryOverview/";
import LevelQuestion, {
  route as LevelQuestionRoute,
} from "./pages/participant/Attributes/LevelQuestion";
import DailyScreener, {
  route as DailyScreenerRoute,
} from "./pages/participant/Attributes/DailyScreener";
import PrivateRoute from "./common/Layout/PrivateRoute";
import QuestionnairePage, {
  route as ParticipantQuestionnaireRoute,
} from "./pages/participant/Questionnaire/";
import SurveyCreatorPage, {
  route as SurveyCreatorPageRoute,
} from "./pages/marketintelligence/SurveyCreator";
import ResearchRegistrationConfirmation, {
  route as ResearchRegistrationConfirmationRoute,
} from "./pages/marketintelligence/RegisterConfirmation/";
import Services from "./services/Services";
import LoginPage, {
  route as ResearcherLoginRoute,
} from "./pages/marketintelligence/Login/LoginPage";
import ParticipantRegisterPage, {
  route as ParticipantRegisterRoute,
  stepRoute as ParticipantRegisterStepRoute,
} from "./pages/participant/Register";
import ResearcherRegisterPage, {
  route as ResearcherRegisterPageRoute,
} from "./pages/marketintelligence/Register";
import RegistrationConfirmationParticipant, {
  route as RegistrationConfirmationParticipantRoute,
} from "./pages/participant/RegisterConfirmation";
import ParticipantLoginPage, { route as ParticipantLoginRoute } from "./pages/participant/Login";
import DashboardResearcher, {
  route as ResearcherDashboardRoute,
  stepRoute as ResearcherDashboardStepRoute,
} from "./pages/marketintelligence/Dashboard";
import ImprintParticipant, { route as ParticipantImprintRoute } from "./pages/participant/Imprint";
import FAQParticipant, { route as ParticipantFAQRoute } from "./pages/participant/FAQ";
import FAQResearcherPage, { route as FAQResearcherRoute } from "./pages/marketintelligence/FAQ";
import ResearcherImprintPage, { route as ImpressumRoute } from "./pages/marketintelligence/Imprint";
import OpinionValuePage, { route as OpinionValueRoute } from "./pages/participant/OpinionValue";
import ProfileDataPage, { route as ProfileDataRoute } from "./pages/participant/ProfileData";
import Page from "./common/Layout/Page";
import DataProtectionResearcher, {
  route as ResearcherDataProtectionRoute,
} from "./pages/marketintelligence/DataProtection";
import DataProtectionResearcherLong, {
  route as ResearcherDataProtectionLongRoute,
} from "./pages/marketintelligence/DataProtection/DataProtectionLong";
import TermsConditionsResearcher, {
  route as ResearcherTermsConditionsRoute,
} from "./pages/marketintelligence/TermsConditions";
import {
  ContactFormResearcher,
  route as ContactFormResearcherRoute,
} from "./pages/marketintelligence/Contact";
import ForgottenPasswordParticipant, {
  route as ForgottenPasswordRouteParticipant,
} from "./pages/participant/PasswordForgotten";
import PasswordResetPage, {
  route as PasswordResetdRouteParticipant,
} from "./pages/participant/PasswordReset";
import LandingPageResearcher, {
  route as LandingPageResearcherRoute,
} from "./pages/marketintelligence/LandingPage";
import LandingPageParticipant, {
  route as LandingPageParticipantRoute,
} from "./pages/participant/LandingPage";
import PersonalInformation, {
  route as PersonalInformationRoute,
} from "./pages/marketintelligence/Account/PersonalInformation";
import BillingData, {
  route as BillingDataRoute,
} from "./pages/marketintelligence/Account/BillingData";
import ParticipantPrivacy, { route as ParticipantPrivacyRoute } from "./pages/participant/Privacy";
import ParticipantTermsOfUse, {
  route as ParticipantTermsOfUseRoute,
} from "./pages/participant/TermsConditions";
import ParticipantAccount, { route as ParticipantAccountRoute } from "./pages/participant/Account";
import ResearcherAccountPage, {
  route as ResearcherAccountRoute,
} from "./pages/marketintelligence/Account";
import DetailedSurveyBoard, {
  route as DetailedSurveyBoardRoute,
} from "./pages/marketintelligence/Dashboard/DetailedSurveyBoard";
import ForgottenPasswordResearcher, {
  route as ResearcherForgottenPasswordRoute,
} from "./pages/marketintelligence/PasswordForgotten";
import AccountVerification, {
  route as AccountVerificationRoute,
} from "./pages/AccountVerification";
import GeneralErrorPage, { route as GeneralErrorRoute } from "./pages/GeneralError";
import { connect } from "react-redux";
import User from "./entities/User";
import { RootState } from "./modules";
import { compose } from "recompose";
import ID from "./entities/ID";
import LogoBarParticipant from "./common/Layout/LogoBarParticipant";
import { history } from "./services/httpClient";

export enum PageType {
  RESEARCH,
  PARTICIPANT,
  ADMIN,
}

interface RouteConfig {
  path?: string;
  name: string;
  isPrivate: boolean;
  Component: React.ReactType;
  type: PageType;
}

const routes: RouteConfig[] = [
  {
    path: LandingPageResearcherRoute,
    name: "Quopinion.com",
    isPrivate: false,
    Component: LandingPageResearcher,
    type: PageType.RESEARCH,
  },
  {
    path: ImpressumRoute,
    name: "Impressum",
    Component: ResearcherImprintPage,
    isPrivate: false,
    type: PageType.PARTICIPANT,
  },
  {
    path: GeneralErrorRoute,
    name: "General Error",
    Component: GeneralErrorPage,
    isPrivate: false,
    type: PageType.RESEARCH,
  },

  {
    path: AccountVerificationRoute,
    name: "Account Verifikation",
    Component: AccountVerification,
    isPrivate: false,
    type: PageType.PARTICIPANT,
  },

  /* market intelligence */
  /* auth */
  {
    path: ResearcherLoginRoute,
    name: "Login",
    isPrivate: false,
    Component: LoginPage,
    type: PageType.RESEARCH,
  },
  {
    path: ResearcherForgottenPasswordRoute,
    name: "Researcher Forgotten Password",
    Component: ForgottenPasswordResearcher,
    isPrivate: false,
    type: PageType.RESEARCH,
  },
  {
    path: ResearcherRegisterPageRoute,
    name: "Register",
    isPrivate: false,
    Component: ResearcherRegisterPage,
    type: PageType.RESEARCH,
  },
  {
    path: ResearchRegistrationConfirmationRoute,
    name: "Register Confirmation",
    isPrivate: false,
    Component: ResearchRegistrationConfirmation,
    type: PageType.RESEARCH,
  },
  {
    path: DetailedSurveyBoardRoute,
    name: "Researcher Survey Details",
    isPrivate: false,
    Component: DetailedSurveyBoard,
    type: PageType.RESEARCH,
  },
  {
    path: ResearcherDashboardRoute,
    name: "Researcher Dashboard",
    isPrivate: false,
    Component: DashboardResearcher,
    type: PageType.RESEARCH,
  },
  {
    path: ResearcherDashboardStepRoute,
    name: "Researcher Dashboard Steps",
    isPrivate: false,
    Component: DashboardResearcher,
    type: PageType.RESEARCH,
  },

  {
    path: ResearcherAccountRoute,
    name: "Researcher Account",
    isPrivate: true,
    Component: ResearcherAccountPage,
    type: PageType.RESEARCH,
  },
  {
    path: PersonalInformationRoute,
    name: "Personal Information",
    isPrivate: true,
    Component: PersonalInformation,
    type: PageType.RESEARCH,
  },
  {
    path: BillingDataRoute,
    name: "Billing Data",
    isPrivate: false,
    Component: BillingData,
    type: PageType.RESEARCH,
  },
  /* survey creator */
  {
    path: SurveyCreatorPageRoute,
    name: "Survey",
    isPrivate: false,
    Component: SurveyCreatorPage,
    type: PageType.RESEARCH,
  },
  {
    path: `${SurveyCreatorPageRoute}/:step/:surveyId?`,
    name: "Survey Step",
    isPrivate: false,
    Component: SurveyCreatorPage,
    type: PageType.RESEARCH,
  },
  {
    path: FAQResearcherRoute,
    name: "FAQ Researcher",
    isPrivate: false,
    Component: FAQResearcherPage,
    type: PageType.RESEARCH,
  },
  {
    path: ResearcherDataProtectionRoute,
    name: "Researcher Dataprotection",
    isPrivate: false,
    Component: DataProtectionResearcher,
    type: PageType.RESEARCH,
  },
  {
    path: ResearcherTermsConditionsRoute,
    name: "Researcher Terms and Conditions",
    isPrivate: false,
    Component: TermsConditionsResearcher,
    type: PageType.RESEARCH,
  },
  {
    path: ResearcherDataProtectionLongRoute,
    name: "Researcher Dataprotection Long",
    isPrivate: false,
    Component: DataProtectionResearcherLong,
    type: PageType.RESEARCH,
  },
  {
    path: ContactFormResearcherRoute,
    name: "Researcher Contact Form",
    isPrivate: false,
    Component: ContactFormResearcher,
    type: PageType.RESEARCH,
  },

  /* auth - not implemented yet */
  // { name: "/password-reset", Component: RegisterConfirmPage },
  // { name: "/password-confirm", Component: RegisterConfirmPage },

  /* participant */
  {
    path: LandingPageParticipantRoute,
    name: "Landingpage Participant",
    isPrivate: false,
    Component: LandingPageParticipant,
    type: PageType.PARTICIPANT,
  },
  {
    path: ParticipantImprintRoute,
    name: "Imprint Participant",
    isPrivate: false,
    Component: ImprintParticipant,
    type: PageType.PARTICIPANT,
  },
  /* auth */
  {
    path: ParticipantLoginRoute,
    name: "Participant Login",
    isPrivate: false,
    Component: ParticipantLoginPage,
    type: PageType.PARTICIPANT,
  },
  {
    path: RegistrationConfirmationParticipantRoute,
    name: "Participant Registration Confirmation",
    isPrivate: false,
    Component: RegistrationConfirmationParticipant,
    type: PageType.PARTICIPANT,
  },
  {
    path: ParticipantRegisterRoute,
    name: "Participant Registration",
    isPrivate: false,
    Component: ParticipantRegisterPage,
    type: PageType.PARTICIPANT,
  },
  {
    path: ParticipantRegisterStepRoute,
    name: "Participant Registration Step",
    isPrivate: false,
    Component: ParticipantRegisterPage,
    type: PageType.PARTICIPANT,
  },
  {
    path: ParticipantPrivacyRoute,
    name: "Participant Privacy",
    isPrivate: false,
    Component: ParticipantPrivacy,
    type: PageType.PARTICIPANT,
  },
  {
    path: ParticipantTermsOfUseRoute,
    name: "Participant Terms of Use",
    isPrivate: false,
    Component: ParticipantTermsOfUse,
    type: PageType.PARTICIPANT,
  },

  /* startpage/dashboard */
  {
    path: ParticipantDashboardRoute,
    name: "Dashboard",
    isPrivate: false,
    Component: DashboardPage,
    type: PageType.PARTICIPANT,
  },
  {
    path: AttributeRoute,
    name: "Attributes",
    isPrivate: false,
    Component: AttributePage,
    type: PageType.PARTICIPANT,
  },
  {
    path: CategoryLevelOverviewRoute,
    name: "AttributeCategoryLevelQuestions",
    isPrivate: false,
    Component: CategoryLevelOverview,
    type: PageType.PARTICIPANT,
  },
  {
    path: LevelQuestionRoute,
    name: "AttributeCategoryLevelQuestion",
    isPrivate: true,
    Component: LevelQuestion,
    type: PageType.PARTICIPANT,
  },
  {
    path: DailyScreenerRoute,
    name: "AttributeCategoryLevelQuestion",
    isPrivate: true,
    Component: DailyScreener,
    type: PageType.PARTICIPANT,
  },
  {
    path: `${SurveyOverviewRoute}/:id`,
    name: "SurveyOverview",
    isPrivate: false,
    Component: SurveyOverviewPage,
    type: PageType.PARTICIPANT,
  },
  {
    path: `${ParticipantQuestionnaireRoute}/:id/:questionID?`,
    name: "Questionnaire",
    isPrivate: false,
    Component: QuestionnairePage,
    type: PageType.PARTICIPANT,
  },
  {
    path: ParticipantAccountRoute,
    name: "Account",
    isPrivate: false,
    Component: ParticipantAccount,
    type: PageType.PARTICIPANT,
  },
  {
    path: OpinionValueRoute,
    name: "OpinionValue",
    isPrivate: false,
    Component: OpinionValuePage,
    type: PageType.PARTICIPANT,
  },
  {
    path: ProfileDataRoute,
    name: "ProfileData",
    isPrivate: false,
    Component: ProfileDataPage,
    type: PageType.PARTICIPANT,
  },
  {
    path: ParticipantFAQRoute,
    name: "FAQ Participant",
    isPrivate: false,
    Component: FAQParticipant,
    type: PageType.PARTICIPANT,
  },
  {
    path: ForgottenPasswordRouteParticipant,
    name: " Participant Password forgotten",
    Component: ForgottenPasswordParticipant,
    isPrivate: false,
    type: PageType.PARTICIPANT,
  },
  {
    path: PasswordResetdRouteParticipant,
    name: " Participant Password Reset",
    Component: PasswordResetPage,
    isPrivate: false,
    type: PageType.PARTICIPANT,
  },
  {
    path: PasswordResetdRouteParticipant,
    name: " Participant Password Reset",
    Component: PasswordResetPage,
    isPrivate: false,
    type: PageType.PARTICIPANT,
  },
  // Admin
  {
    path: AdminDashboardRoute,
    name: "Admin Dashboard",
    Component: AdminDashboardPage,
    isPrivate: true,
    type: PageType.RESEARCH,
  },
  {
    path: AdminAttributeListRoute + ":type",
    name: "Admin Attributes",
    Component: AdminAttributeListPage,
    isPrivate: true,
    type: PageType.RESEARCH,
  },
  {
    path: `${AdminAttributeFormRoute}:id/:organizationId?`,
    name: "Admin Attribute",
    Component: AdminAttributeFormPage,
    isPrivate: true,
    type: PageType.RESEARCH,
  },
  {
    path: AdminExternalAttributeRoute,
    name: "Admin External Attribute",
    Component: AdminExternalAttributeOverview,
    isPrivate: true,
    type: PageType.RESEARCH,
  },
];

interface OwnProps {}
interface State {}

interface StateProps {
  currentUser: User;
  token: ID;
  authenticated: boolean;
}

interface DispatchProps {
  fetchUser: () => Promise<User>;
  fetchUserAndFetchBasicAttributes: () => Promise<void>;
}

type Props = OwnProps & DispatchProps & StateProps;

class App extends Component<Props, State> {
  state: State = {};
  constructor(props: Props) {
    super(props);
    Services.init();
    if (this.props.token && !this.props.currentUser.id) {
      this.props.fetchUserAndFetchBasicAttributes();
    }
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          {routes.map(({ path, Component, isPrivate, type }, idx) => (
            <Route path={path} exact={true} key={path || idx}>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={300}
                  classNames="page"
                  unmountOnExit={true}
                >
                  <Page>
                    {isPrivate ? (
                      this.props.authenticated ? (
                        <PrivateRoute render={(props: any) => <Component {...props} />} />
                      ) : (
                        <LogoBarParticipant />
                      )
                    ) : (
                      <Component />
                    )}
                  </Page>
                </CSSTransition>
              )}
            </Route>
          ))}
          <Redirect to={"/participant/dashboard"} />
        </Switch>
      </Router>
    );
  }
}

const mapDispatchToProps = {
  fetchUser,
  fetchUserAndFetchBasicAttributes,
};

const mapStateToProps = ({ session, user }: RootState) => ({
  currentUser: user.current,
  token: session.token,
  authenticated: Boolean(session.authenticated),
});

export default compose<Props, OwnProps>(connect(mapStateToProps, mapDispatchToProps))(App);
