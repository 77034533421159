import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";

import LogoBarResearcher from "../../../common/Layout/LogoBarResearcher";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Container from "../../../common/Layout/Container";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import ImprintContent from "../../../common/Imprint/ImprintContent";

interface OwnProps {}

const styles = (theme: QuopinionTheme) => createStyles({});

type Props = OwnProps & WithTranslation & WithStyles<typeof styles>;

export const route = "/market-research/imprint";

class ResearcherImprintPage extends PureComponent<Props> {
  render() {
    return (
      <>
        <LogoBarResearcher backButton={true} />
        <ContentWrapper>
          <Container centered={true}>
            <ImprintContent />
          </Container>
        </ContentWrapper>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles)
)(ResearcherImprintPage);
