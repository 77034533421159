import React, { PureComponent } from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../constants/Theme";
import MenuParticipant from "../../pages/participant/Menu/MenuParticipant";
import { compose } from "recompose";
import { connect } from "react-redux";
import { RootState } from "../../modules";
import { actions as uiStateActions } from "../../modules/uiState";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100vh",
    },
  });

interface OwnProps {}

interface StateProps {
  mobileMenuIsActive: boolean;
}
interface DispatchProps {
  closeMobileMenu: typeof uiStateActions.hideMobileMenu;
  openMobileMenu: typeof uiStateActions.showMobileMenu;
}

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>;

class Page extends PureComponent<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes, children, mobileMenuIsActive, closeMobileMenu, openMobileMenu } = this.props;

    return (
      <div className={classes.root}>
        <MenuParticipant
          visible={mobileMenuIsActive}
          closeMenu={closeMobileMenu}
          openMenu={openMobileMenu}
        />
        {children}
      </div>
    );
  }
}

const mapStateToProps = ({ uiState }: RootState) => ({
  mobileMenuIsActive: uiState.showMobileMenu,
});

const mapDispatchToProps = {
  closeMobileMenu: uiStateActions.hideMobileMenu,
  openMobileMenu: uiStateActions.showMobileMenu,
};

export default compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Page);
