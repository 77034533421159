import React, { PureComponent } from "react";
import FormInput from "./FormInput";
import { QuestionProps, QuestionTypeProps } from "./Question";
import * as Yup from "yup";
import { translations } from "../../../../../constants/lang/translation";
import AnswerOption, { FieldTypes } from "../../../../../entities/AnswerOption";
import Question from "../../../../../entities/Question";
import QuestionPreview from "../../../../../common/Preview/QuestionPreview";
//import SwitchComponent from "../../../../../common/Layout/components/Switch";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "recompose";

export const QuestionValidationMultipleChoice = ({ t }: QuestionProps) => {
  return Yup.object().shape({
    text: Yup.string()
      .required(t(translations.questionnaire.validation.title))
      .max(240, t(translations.questionnaire.validation.titleMaxLength)),
    body: Yup.object().shape({
      answerOptions: Yup.array()
        .required(t(translations.questionnaire.validation.answers.minCount))
        .min(2, t(translations.questionnaire.validation.answers.minCount))
        .max(12, t(translations.questionnaire.validation.answers.maxCount))
        .of(
          Yup.object().shape({
            label: Yup.string()
              .required(t(translations.questionnaire.validation.answers.requiredTitle))
              .max(90, t(translations.questionnaire.validation.answers.length)),
          })
        ),
    }),
  });
};

type Props = QuestionTypeProps & WithTranslation;

class QuestionFormMultipleChoiceCore extends PureComponent<Props> {
  handleInputChange(index: number, newAnswerOption: AnswerOption) {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.props.body.answerOptions.map((answerOption, answerOptionIndex) =>
        answerOptionIndex === index ? newAnswerOption : answerOption
      ),
    });
  }

  addAnswerItem = (answerOptions: AnswerOption[], index: number) => {
    answerOptions.splice(index + 1, 0, new AnswerOption());
    return answerOptions;
  };

  handleAddAnswer = (index: number) => {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.addAnswerItem([...this.props.body.answerOptions], index),
    });
  };
  handleDeleteAnswer = (indexToDelete: number) => {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.props.body.answerOptions.filter(
        (answerOptions, answerOptionIndex) => answerOptionIndex !== indexToDelete
      ),
    });
  };

  handleToggleAdditionalFreetext = (index: number) => {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.props.body.answerOptions.map((answerOption, answerOptionIndex) => {
        if (answerOptionIndex === index) {
          return this.props.body.answerOptions[index].additionalFieldType === FieldTypes.TEXT
            ? {
                ...answerOption,
                additionalFieldType: FieldTypes.NONE,
              }
            : { ...answerOption, additionalFieldType: FieldTypes.TEXT };
        } else {
          return answerOption;
        }
      }),
    });
  };

  handleToggleAdditionalNumericalField = (index: number) => {
    this.props.handleChange({
      ...this.props.body,
      answerOptions: this.props.body.answerOptions.map((answerOption, answerOptionIndex) => {
        if (answerOptionIndex === index) {
          return this.props.body.answerOptions[index].additionalFieldType === FieldTypes.NUMBER
            ? {
                ...answerOption,
                additionalFieldType: FieldTypes.NONE,
              }
            : { ...answerOption, additionalFieldType: FieldTypes.NUMBER };
        } else {
          return answerOption;
        }
      }),
    });
  };

  handleOtherPossibilities = () => {
    this.props.handleChange({
      ...this.props.body,
      additionalAnswerEnabled: !this.props.body.additionalAnswerEnabled,
    });
  };

  render() {
    const { /*body, t, */ id } = this.props;
    return (
      <>
        {this.props.body.answerOptions.map((answerOption: AnswerOption, index) => (
          <FormInput
            answerOption={answerOption}
            otherQuestions={this.props.otherQuestions}
            questionIndex={this.props.questionIndex}
            handleChange={this.handleInputChange.bind(this, index)}
            index={index}
            key={answerOption.id}
            deleteAnswer={this.handleDeleteAnswer.bind(this, index)}
            addAnswer={this.handleAddAnswer.bind(this, index)}
            amountOfAnswers={this.props.body.answerOptions.length}
            addAdditionalFreetext={this.handleToggleAdditionalFreetext.bind(
              this,
              index,
              answerOption.additionalFieldType
            )}
            type={this.props.type}
            questionId={id}
            preventMediaUpload={this.props.preventMediaUpload}
            preventAlterationOfAnswerCount={this.props.preventAlterationOfAnswerCount}
            preventQuestionSkip={this.props.preventQuestionSkip}
            preventAdditionalFreeText={this.props.preventAdditionalFreeText}
            multiline={true}
            conditions={this.props.conditions}
            addAdditionalNumericalField={this.handleToggleAdditionalNumericalField.bind(
              this,
              index,
              answerOption.additionalFieldType
            )}
          />
        ))}
        {/*TODO add this part when connection for "Sonstiges" field is there BE and FE to save it as choice incl text*/}
        {/*   <SwitchComponent
          title={t(translations.questionnaire.singleChoice.addAdditionalAnswer)}
          label={
            body.additionalAnswerEnabled ? t(translations.switch.on) : t(translations.switch.off)
          }
          checked={body.additionalAnswerEnabled}
          handleChange={this.handleOtherPossibilities}
        />*/}
      </>
    );
  }
}

export const QuestionFormMultipleChoice = compose<Props, QuestionTypeProps>(withTranslation())(
  QuestionFormMultipleChoiceCore
);

export class QuestionPreviewMultipleChoice extends PureComponent<Omit<Question, "toDataJson">> {
  render() {
    const { text: title, body, type, media } = this.props;

    return (
      <QuestionPreview
        title={title}
        body={body}
        type={type}
        media={media}
        questionNumber={1}
        totalQuestionNumber={1}
      />
    );
  }
}
