import React, { PureComponent } from "react";
import {
  Switch,
  Typography,
  Grid,
  createStyles,
  WithStyles,
  withStyles,
  FormControlLabel,
} from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import { compose } from "recompose";

interface OwnProps {
  title?: string;
  label?: string;
  handleChange: () => void;
  checked: boolean;
  fullWidth: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(6),
      width: (props: OwnProps) => (props.fullWidth ? "100%" : "unset"),
    },
    root: {
      position: "relative",
      marginRight: (props: OwnProps) => (props.fullWidth ? theme.spacing(4) : 0),
      paddingRight: (props: OwnProps) => (props.fullWidth ? 9 : 0),
    },
    label: {
      position: "absolute",
      right: (props: OwnProps) => (props.checked ? 34 : 14),
      top: 8,
      userSelect: "none",
    },
    switchRoot: {
      paddingRight: (props: OwnProps) => (props.checked ? 0 : props.fullWidth ? 0 : 9),
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class SwitchComponent extends PureComponent<Props> {
  render() {
    const { title, classes, label, handleChange, checked } = this.props;
    return (
      <Grid container={true} alignItems="center" className={classes.container}>
        <FormControlLabel
          control={
            <Switch
              onChange={handleChange}
              checked={checked}
              classes={{ root: classes.switchRoot }}
            />
          }
          label={label}
          classes={{ root: classes.root, label: classes.label }}
        />
        {title && <Typography>{title}</Typography>}
      </Grid>
    );
  }
}
export default compose<Props, OwnProps>(withStyles(styles))(SwitchComponent);
