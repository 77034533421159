import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { Typography, createStyles, WithStyles, withStyles, Grid } from "@material-ui/core";

import Card from "./components/Card";
import Button from "./components/Button";
import { translations } from "../../constants/lang/translation";
import { QuopinionTheme } from "../../constants/Theme";

import { route as ResearcherFAQRoute } from "../../pages/marketintelligence/FAQ";
import { route as ResearcherContactRoute } from "../../pages/marketintelligence/Contact";
import { Link } from "react-router-dom";

interface SupportProps {
  style?: any;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      textAlign: "center",
      float: "right",
    },
    supportText: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
    supportHeadline: {
      marginBottom: theme.spacing(2),
      lineHeight: 1.14,
    },
    linkFAQText: {
      textDecoration: "none",
    },
    cardWrapper: {
      "@media(max-width:780px)": {
        display: "flex",
        justifyContent: "center",
      },
    },
    gridWrapper: {
      maxWidth: "266px",
    },
  });

type Props = SupportProps & WithTranslation & WithStyles<typeof styles>;

class SupportContainer extends PureComponent<Props> {
  render() {
    const { t, classes, style } = this.props;
    return (
      <div className={classes.cardWrapper} style={{ ...style }} id={"supportContainer"}>
        <Card isRaised={false} className={classes.root} style={{ ...style }}>
          <Grid
            container={true}
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.gridWrapper}
          >
            <Typography variant="body2" color="primary" className={classes.supportHeadline}>
              {t(translations.support.headline)}
            </Typography>
            <div className={classes.supportText}>
              <Typography variant="caption">{t(translations.support.text)}</Typography>
            </div>
            <Link to={ResearcherContactRoute} className={classes.linkFAQText}>
              <Button contained={true} color="primary" size="small" fullWidth={true}>
                {t(translations.support.button)}
              </Button>
            </Link>
            <Link to={ResearcherFAQRoute}>
              <Button underline={true} color="primary" size="small" fullWidth={true}>
                {t(translations.support.faq)}
              </Button>
            </Link>
          </Grid>
        </Card>
      </div>
    );
  }
}

export default compose<Props, SupportProps>(
  withTranslation(),
  withStyles(styles)
)(SupportContainer);
