import React, { PureComponent } from "react";
import { createStyles, TextField, Typography, withStyles, WithStyles } from "@material-ui/core";
import * as Yup from "yup";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../../../constants/lang/translation";
import { compose } from "recompose";
import { QuestionTypeProps, QuestionProps } from "./Question";
import Question from "../../../../../entities/Question";
import QuestionPreview from "../../../../../common/Preview/QuestionPreview";
import { Info, Minus, Plus } from "../../../../../assets/icon";
import { QuopinionTheme } from "../../../../../constants/Theme";
import ModalComponent from "../../../../../common/Layout/components/Modal";
import PanelModal from "../../../../../common/Layout/components/PanelModal";
import Button from "../../../../../common/Layout/components/Button";

export const QuestionValidationPassword = ({ t }: QuestionProps) => {
  return Yup.object({
    text: Yup.string()
      .required(t(`${translations.questionnaire.validation.title}`))
      .max(240, t(`${translations.questionnaire.validation.titleMaxLength}`)),
    body: Yup.object().shape({
      passwords: Yup.array()
        .required(t(translations.questionnaire.validation.password.required))
        .min(1, t(translations.questionnaire.validation.password.minCount))
        .max(5, t(translations.questionnaire.validation.password.maxCount))
        .of(
          Yup.string()
            .min(2, t(translations.questionnaire.validation.password.length))
            .required(t(translations.questionnaire.validation.password.required))
        ),
    }),
  });
};

const styles = (theme: QuopinionTheme) =>
  createStyles({
    infoIcon: {
      width: 16,
      height: 16,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    passwordInfoWrapper: {
      display: "flex",
      alignItems: "center",
    },
    inputWrapper: {
      display: "flex",
      alignItems: "center",
    },
    addAndDeleteIcon: {
      marginTop: theme.spacing(2),
    },
  });

type Props = QuestionTypeProps & WithTranslation & WithStyles<typeof styles>;

class QuestionFormPasswordCore extends PureComponent<Props> {
  static MAX_PASSWORD_AMOUNT = 5;

  handleInputChange = (event: React.ChangeEvent<{ value: string }>, itemIndex: number) => {
    this.props.handleChange({
      ...this.props.body,
      passwords: this.props.body.passwords.map((password, passwordIndex) => {
        return passwordIndex === itemIndex ? event.target.value.trim() : password;
      }),
    });
  };

  addPasswordItem = (passwordOptions: string[], index: number) => {
    passwordOptions.splice(index + 1, 0, "");
    return passwordOptions;
  };

  addPassword = (index: number) => {
    this.props.handleChange({
      ...this.props.body,
      passwords: this.addPasswordItem([...this.props.body.passwords], index),
    });
  };

  deletePassword = (itemIndexToDelete: number) => {
    this.props.handleChange({
      ...this.props.body,
      passwords: this.props.body.passwords.filter(
        (passwordVariant, passwordVariantIndex) => passwordVariantIndex !== itemIndexToDelete
      ),
    });
  };

  state = {
    infoOpen: false,
  };

  openInfo = () => {
    this.setState({
      infoOpen: true,
    });
  };

  closeInfo = () => {
    this.setState({
      infoOpen: false,
    });
  };

  renderPasswordInputField = (passwordItem: string, itemIndex: number) => {
    const { t, body, classes } = this.props;
    return (
      <div key={itemIndex}>
        <div className={classes.inputWrapper}>
          <TextField
            id="standard-name"
            placeholder={t(translations.questionnaire.password.inputPlaceholder)}
            value={body.passwords[itemIndex]}
            margin="normal"
            fullWidth={true}
            multiline={false}
            rows={1}
            disabled={false}
            onChange={(event: any) => {
              this.handleInputChange(event, itemIndex);
            }}
          />
          <Button
            white={true}
            color="primary"
            icon={
              body.passwords !== undefined && body.passwords.length < 5 ? (
                <Plus fill="#6d7278" />
              ) : (
                <Plus fill="#fff" />
              )
            }
            size="small"
            onClick={() => {
              this.addPassword(itemIndex);
            }}
            className={classes.addAndDeleteIcon}
            disabled={body.passwords !== undefined && body.passwords.length >= 5}
          />

          <Button
            white={true}
            color="primary"
            icon={
              body.passwords !== undefined && body.passwords.length <= 1 ? (
                <Minus fill="#fff" />
              ) : (
                <Minus fill="#6d7278" />
              )
            }
            size="small"
            onClick={() => {
              this.deletePassword(itemIndex);
            }}
            disabled={body.passwords !== undefined && body.passwords.length <= 1}
            className={classes.addAndDeleteIcon}
          />
        </div>
      </div>
    );
  };

  render() {
    const { t, classes, body } = this.props;
    return (
      <div>
        <TextField
          id="standard-name"
          placeholder={t(translations.questionnaire.freeText.infoBodyText)}
          value={""}
          margin="normal"
          fullWidth={true}
          multiline={true}
          rows={5}
          disabled={true}
        />
        <div className={classes.passwordInfoWrapper}>
          <Typography>{`${t(translations.questionnaire.password.title)}:`}</Typography>
          <Info className={classes.infoIcon} onClick={this.openInfo} />
          <ModalComponent
            label="password-question-info"
            description="info for password question"
            open={this.state.infoOpen}
            onClose={this.closeInfo}
            showArrow={false}
            size={480}
            light={true}
            smallCross={true}
          >
            <PanelModal
              title={this.props.t(translations.questionnaire.password.infoTitle)}
              description={this.props.t(translations.questionnaire.password.infoModalBody, {
                newParagraph: "<br/><br/>",
                space: "&nbsp",
                break: "<br/>",
              })}
            />
          </ModalComponent>
        </div>

        {body.passwords !== undefined &&
          body.passwords.map((passwordItem, index) =>
            this.renderPasswordInputField(passwordItem, index)
          )}
      </div>
    );
  }
}

export const QuestionFormPassword = compose<Props, QuestionTypeProps>(
  withTranslation(),
  withStyles(styles)
)(QuestionFormPasswordCore);

type PreviewProps = Omit<Question, "toDataJson"> & WithTranslation & WithStyles<typeof styles>;

class QuestionPreviewPasswordCore extends PureComponent<PreviewProps> {
  render() {
    const { text: title, body, type, media } = this.props;
    return (
      <QuestionPreview
        title={title}
        body={body}
        type={type}
        media={media}
        questionNumber={1}
        totalQuestionNumber={1}
      />
    );
  }
}

export const QuestionPreviewPassword = compose<PreviewProps, Omit<Question, "toDataJson">>(
  withTranslation(),
  withStyles(styles)
)(QuestionPreviewPasswordCore);
