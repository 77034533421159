import React from "react";
import { QuopinionTheme, blueGradient } from "../../../../constants/Theme";
import { createStyles, WithStyles, withStyles, Typography } from "@material-ui/core";
import { compose } from "recompose";

interface OwnProps {
  text: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      background: blueGradient,
      borderRadius: 4,
      padding: theme.spacing(2),
      width: "fit-content",
      marginRight: theme.spacing(4),
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

const TipComponent: React.FC<Props> = ({ text, classes }) => {
  return (
    <div className={classes.root}>
      <Typography color="textSecondary" variant="body1">
        {text}
      </Typography>
    </div>
  );
};

export const Tip = compose<Props, OwnProps>(withStyles(styles))(TipComponent);
