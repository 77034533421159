import React, { PureComponent } from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { QuopinionTheme, red } from "../../../constants/Theme";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";

const styles = (theme: QuopinionTheme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
    errorMessage: {
      width: 250,
      backgroundColor: red,
    },
  });

interface OwnProps {
  audio: string;
  type: string;
}

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class AudioPlayer extends PureComponent<Props> {
  render() {
    const { classes, audio, type, t } = this.props;
    return (
      <div className={classes.container}>
        <audio controls={true}>
          <source src={audio} type={type} />
          <div className={classes.errorMessage}>{t(translations.questionnaire.audioError)}</div>
        </audio>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withTranslation(), withStyles(styles))(AudioPlayer);
