import React from "react";
import { QuopinionTheme } from "../../constants/Theme";
import { createStyles, withStyles, WithStyles, Grid } from "@material-ui/core";
import { compose } from "recompose";

interface OwnProps {}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      paddingRight: theme.spacing(7),
      "@media(max-width: 599px)": {
        paddingRight: "unset",
      },
      position: "sticky",
      right: 0,
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class SidebarContainer extends React.Component<Props> {
  render() {
    return (
      <Grid item={true} xs={12} sm={3} className={this.props.classes.root} id={"sidebarContainer"}>
        {this.props.children}
      </Grid>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(SidebarContainer);
