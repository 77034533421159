import * as React from "react";
import { Typography, Grid, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { compose } from "recompose";
import { WithTranslation, withTranslation } from "react-i18next";
import Container from "../../../common/Layout/Container";
import { QuopinionTheme } from "../../../constants/Theme";
import { translations } from "../../../constants/lang/translation";
import { withRouter, RouteComponentProps } from "react-router";
import LogoBarParticipant from "../../../common/Layout/LogoBarParticipant";
import { RootState } from "../../../modules";
import { actions } from "../../../modules/session";
import { connect } from "react-redux";

interface OwnProps {
  route: string;
  eMail?: string; //ToDo later when real connection there, then mandatory
  orderNumber?: number; //ToDo later when real connection there, then mandatory
}

interface StateProps {
  registrationMail: { registrationMail: string };
}

interface DispatchProps {
  removeMail: () => void;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    infoText: {
      marginTop: theme.spacing(2),
    },
    supportText: {
      borderTop: "1px solid",
      borderColor: theme.palette.grey[100],
      paddingTop: theme.spacing(6),
      marginTop: theme.spacing(12),
    },
    clientData: {
      color: "primary",
      fontWeight: 600,
    },
    gridContainer: {
      justifyContent: "center",
    },
  });

type Props = OwnProps &
  WithStyles<typeof styles> &
  WithTranslation &
  StateProps &
  DispatchProps &
  RouteComponentProps<{ token: string }>;

export const route: string = "/participant/registration-confirmation";
class RegisterConfirmationParticipant extends React.Component<Props> {
  componentWillUnmount() {
    this.props.removeMail();
  }

  handleVerifyClick = () => {
    const {
      history,
      match: {
        params: { token },
      },
    } = this.props;

    history.push("/verifyAccount/" + token);
  };

  render() {
    const { registrationMail, classes, t } = this.props;
    console.log(registrationMail);

    return (
      <>
        <LogoBarParticipant />
        <Container centered={true}>
          <Grid
            container={true}
            direction="row"
            alignItems="center"
            className={classes.gridContainer}
          >
            <Grid item={true} xs={12} md={8}>
              <Typography variant="h2" color="primary">
                {t(translations.confirm.headline)}
              </Typography>
              <div className={classes.infoText}>
                <Typography variant="body1">
                  {t(translations.confirm.eMail1)}{" "}
                  <span className={classes.clientData}>{registrationMail.registrationMail}</span>{" "}
                  {t(translations.confirm.eMail2)}
                  <br />
                  <br />
                  {t(translations.confirm.participant.check)}
                  <br />
                  {t(translations.confirm.participant.postCheck)}
                  <br />
                  <br /> {t(translations.confirm.participant.spamCheck)}
                  <br />
                  <br />
                  {t(translations.quopinion.participant.your)} <br />
                  {t(translations.quopinion.team)}
                </Typography>
              </div>
              <div className={classes.supportText}>
                <Typography variant="body1" color="primary">
                  {t(translations.confirm.participant.question1)}&nbsp;
                  {t(translations.confirm.under)} {t(translations.quopinion.supportPhone)}.
                  <br />
                  <br />
                  {t(translations.quopinion.openingHours)}{" "}
                  {t(translations.quopinion.participant.openingHoursSuffix)}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  connect(
    ({ session }: RootState) => ({
      registrationMail: session.registrationMail,
    }),
    { removeMail: actions.removeRegistrationMail }
  ),

  withRouter,
  withTranslation(),
  withStyles(styles)
)(RegisterConfirmationParticipant);
