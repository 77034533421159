import ID from "./ID";
import Region from "./Region";
import IndividualAttribute from "./IndividualAttribute";
import { panelConfigAttributeIds } from "../modules/util";

export interface MinMaxShareNumber {
  min: number;
  max: number;
  percentage: number;
}
//export type Gender = "male" | "female" | "divers";

export const genderValues = ["male", "female", "divers"] as const;
export type Gender = typeof genderValues[number];

export interface GenderShare {
  id?: ID;
  answer: Gender;
  label?: string;
  percentage: number;
}

export const educationValues = [
  "noDegree",
  "lowerSecondarySchool",
  "secondarySchool",
  "advancedTechnicalCollege",
  "highschool",
  "completedStudies",
] as const;
export type Education = typeof educationValues[number];

export const familyValues = [
  "single",
  "engaged",
  "livingWithPartner",
  "married",
  "seperated",
  "divorced",
  "widowed",
] as const;
export type Family = typeof familyValues[number];

export const houseHoldValues = [
  "single",
  "twoPerson",
  "threePerson",
  "fourPerson",
  "fivePerson",
  "sixPerson",
  "moreThanSixPerson",
] as const;
export type Household = typeof houseHoldValues[number];

export const incomeValues = [
  "lessThanOneFive",
  "oneFiveToTwoFive",
  "twoFiveToThreeFive",
  "threeFiveToFourFive",
  "moreThanFourFive",
  "notSpecified",
] as const;

export type Income = typeof incomeValues[number];

export const childrenValues = [
  "none",
  "one",
  "two",
  "three",
  "four",
  "fiveOrMore", // last value means 'value and more'
] as const;
export type Children = typeof childrenValues[number];

export interface AttributeAnswer {
  readonly id: ID;
  weight: number;
  min: number;
  max: number;
}

/**
 * Entity class Panel
 * that represents the the panel of a Survey
 */
export default class Panel {
  readonly id: ID;
  size: number;
  createdAt: string;
  title: string;
  criteria: [];
  gender: { attributeId: ID; answer: GenderShare[] };
  age: { attributeId: ID; answer: MinMaxShareNumber[] };
  household: { attributeId: ID; answer: Household[] };
  income: { attributeId: ID; answer: Income[] };
  education: { attributeId: ID; answer: Education[] };
  family: { attributeId: ID; answer: Family[] };
  children: { attributeId: ID; answer: Children[] };
  individual: IndividualAttribute[];

  // regions
  county: { attributeId: ID; answer: Region[] };
  state: { attributeId: ID; answer: Region[] };
  leitregion: { attributeId: ID; answer: Region[] };
  city: { attributeId: ID; answer: Region[] };
  populationDensity: { attributeId: ID; answer: Region[] };
  touristArea: { attributeId: ID; answer: Region[] };

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.size = obj.size || 400;
    this.title = obj.title || "default Panel";
    this.createdAt = obj.createdAt;
    this.gender = obj.gender || {
      attributeId: panelConfigAttributeIds["gender"] || "",
      answer: [],
    };
    this.criteria = obj.criteria || [];
    this.age = obj.age
      ? obj.age.answer.length > 0
        ? obj.age
        : {
            attributeId: panelConfigAttributeIds["age"] || "",
            answer: [{ min: 16, max: 100, percentage: 100 }],
          }
      : {
          attributeId: panelConfigAttributeIds["age"] || "",
          answer: [{ min: 16, max: 100, percentage: 100 }],
        };
    this.household = obj.household || {
      attributeId: panelConfigAttributeIds["household"],
      answer: [],
    };
    this.income = obj.income || {
      attributeId: panelConfigAttributeIds["income"],
      answer: [],
    };
    this.education = obj.education || {
      attributeId: panelConfigAttributeIds["education"],
      answer: [],
    };
    this.family = obj.family || {
      attributeId: panelConfigAttributeIds["family"],
      answer: [],
    };
    this.children = obj.children || {
      attributeId: panelConfigAttributeIds["children"],
      answer: [],
    };
    this.individual = obj.individual || [];

    // regions
    this.county = {
      attributeId: obj.county ? obj.county.attributeId : panelConfigAttributeIds["county"],
      answer:
        obj.county && obj.county.answer instanceof Array
          ? obj.county.answer.map((region: any) => new Region(region))
          : [],
    };
    this.state = {
      attributeId: obj.state ? obj.state.attributeId : panelConfigAttributeIds["state"] || null,
      answer:
        obj.state && obj.state.answer instanceof Array
          ? obj.state.answer.map((region: any) => new Region(region))
          : [],
    };
    this.leitregion = {
      attributeId: obj.leitregion
        ? obj.leitregion.attributeId
        : panelConfigAttributeIds["leitregion"],
      answer:
        obj.leitregion && obj.leitregion.answer instanceof Array
          ? obj.leitregion.answer.map((region: any) => new Region(region))
          : [],
    };
    this.populationDensity = {
      attributeId: obj.populationDensity
        ? obj.populationDensity.attributeId
        : panelConfigAttributeIds["populationDensity"],
      answer:
        obj.populationDensity && obj.populationDensity.answer instanceof Array
          ? obj.populationDensity.answer.map((region: any) => new Region(region))
          : [],
    };
    this.touristArea = {
      attributeId: obj.touristArea
        ? obj.touristArea.attributeId
        : panelConfigAttributeIds["touristArea"],
      answer:
        obj.touristArea && obj.touristArea.answer instanceof Array
          ? obj.touristArea.answer.map((region: any) => new Region(region))
          : [],
    };
    this.city = {
      attributeId: obj.city ? obj.city.attributeId : panelConfigAttributeIds["city"],
      answer:
        obj.city && obj.city.answer instanceof Array
          ? obj.city.answer.map((region: any) => new Region(region))
          : [],
    };
  }
}
