import React, { PureComponent, ChangeEvent, ReactNode } from "react";
import {
  Select,
  Typography,
  MenuItem,
  createStyles,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { Star } from "../../../../../assets/icon";
import * as Yup from "yup";
import { translations } from "../../../../../constants/lang/translation";
import { QuestionTypeProps, QuestionProps } from "./Question";
import Question from "../../../../../entities/Question";
import { QuopinionTheme } from "../../../../../constants/Theme";
import { compose } from "recompose";
import QuestionPreview from "../../../../../common/Preview/QuestionPreview";
import { withTranslation, WithTranslation } from "react-i18next";

export const QuestionValidationStarRating = ({ t }: QuestionProps) => {
  return Yup.object().shape({
    text: Yup.string()
      .required(t(translations.questionnaire.validation.title))
      .max(240, t(translations.questionnaire.validation.titleMaxLength)),
    body: Yup.object().shape({
      starCount: Yup.number()
        .min(2, t(translations.questionnaire.validation.starRating.required))
        .required(t(translations.questionnaire.validation.starRating.required)),
    }),
  });
};

const styles = (theme: QuopinionTheme) =>
  createStyles({
    select: {
      fontSize: "18px",
    },
    input: {
      backgroundColor: "#fff",
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(5),
      marginLeft: theme.spacing(2),
      borderRadius: 0,
      minWidth: 0,
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&:active": {
        backgroundColor: "#fff",
      },
      "&:focus": {
        backgroundColor: "#fff",
      },
    },
  });

interface FormProps {}

type Props = QuestionTypeProps & FormProps & WithTranslation & WithStyles<typeof styles>;
class QuestionFormStarRatingCore extends PureComponent<Props> {
  handleStarCountChange = (
    event:
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: ReactNode
  ) => {
    this.props.handleChange({
      ...this.props.body,
      starCount: Number(event.target.value),
    });
  };

  showStars = () => {
    const stars = [];
    for (let i = 0; i < this.props.body.starCount; i++) {
      stars.push(<Star fill="#f7b500" width={24} height={24} />);
    }
    return stars;
  };

  render() {
    const { t, classes } = this.props;
    return (
      <div style={{ display: "flex", alignItems: "center", marginBottom: 12 }}>
        <Typography variant="subtitle1">
          {t(translations.questionnaire.starRating.starsCount)}
        </Typography>
        <Select
          value={this.props.body.starCount}
          onChange={this.handleStarCountChange}
          classes={{ root: classes.select, select: classes.input }}
        >
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="7">7</MenuItem>
        </Select>
        <div style={{ marginLeft: 8 }}>{this.showStars()}</div>
      </div>
    );
  }
}

export const QuestionFormStarRating = compose<Props, QuestionTypeProps>(
  withStyles(styles),
  withTranslation()
)(QuestionFormStarRatingCore);

export class QuestionPreviewStarRating extends PureComponent<Omit<Question, "toDataJson">> {
  render() {
    const { text: title, body, type, media } = this.props;
    return (
      <QuestionPreview
        title={title}
        body={body}
        type={type}
        media={media}
        questionNumber={1}
        totalQuestionNumber={1}
      />
    );
  }
}
