import React, { ReactNode } from "react";
import { Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    wrapper: {
      marginBottom: theme.spacing(5),
    },
    headline: {
      marginTop: theme.spacing(2),
      color: theme.palette.grey[400],
      textTransform: "uppercase",
      fontSize: "16px",
    },
    textItem: {
      marginTop: theme.spacing(2),
      color: "#fff",
    },
  });

interface OwnProps {
  headline: string;
  children?: ReactNode;
}
type Props = OwnProps & WithStyles<typeof styles>;

const DataItem: React.FC<Props> = ({ classes, headline, children }) => {
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.headline}>{headline}</Typography>
      {children && <div className={classes.textItem}>{children}</div>}
    </div>
  );
};

export const DataGroup = withStyles(styles)(DataItem);
