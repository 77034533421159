import Client from "./Client";
import Survey from "./Survey";
import Questionnaire from "./Questionnaire";
import Session from "./Session";
import Accounts from "./Accounts";
import Attributes from "./Attributes";

import User from "./User";
import Categories from "./Categories";
import MailTemplate from "./MailTemplate";

class ServicesContainer {
  httpClient!: Client;
  survey!: Survey;
  questionnaire!: Questionnaire;
  session!: Session;
  accounts!: Accounts;
  attributes!: Attributes;
  categories!: Categories;
  user!: User;
  mailTemplate!: MailTemplate;

  init() {
    this.httpClient = new Client(process.env.REACT_APP_API_BASE_URL || "");
    this.survey = new Survey(this.httpClient);
    this.questionnaire = new Questionnaire(this.httpClient);
    this.session = new Session(this.httpClient);
    this.accounts = new Accounts(this.httpClient);
    this.attributes = new Attributes(this.httpClient);
    this.categories = new Categories(this.httpClient);
    this.user = new User(this.httpClient);
    this.mailTemplate = new MailTemplate(this.httpClient);
  }
}

const Services = new ServicesContainer();

export default Services;
