import Client from "./Client";
import Survey, {SurveyAdminInformation, SurveyInformation} from "../entities/Survey";
import ID from "../entities/ID";
import SurveyMetaData from "../entities/SurveyMetaData";
import Question from "../entities/Question";
import {SurveyResult} from "../entities/SurveyResult";
import {downloadCSV} from "../util/csv";

interface SurveyResponse {
  survey: Survey;
  meta: SurveyMetaData;
}

export type ParticipationResponseType =
  | "acceptedSurveys"
  | "completedSurveys"
  | "openSurveys"
  | "missedSurveys"
  | "declinedSurveys"
  | "waitlistedSurveys";
type SurveysParticipationResponse = {
  [title in ParticipationResponseType]: {
    surveyID: ID;
    surveyTitle: string;
  }[];
};
export type ParticipationType =
  | "acceptedSurveys"
  | "completedSurveys"
  | "openSurveys"
  | "declinedSurveys";
export type SurveysParticipationObject = {
  [title in ParticipationType]: Survey[];
};

export type ParticipationState =
  | "PARTICIPATION_OPEN"
  | "PARTICIPATION_ACCEPTED"
  | "PARTICIPATION_REJECTED"
  | "PARTICIPATION_COMPLETED"
  | "PARTICIPATION_DECLINED"
  | "PARTICIPATION_MISSED"
  | "PARTICIPATION_WAITLISTED";

export interface ParticipationSurveyState {
  state: ParticipationState;
  questions: Question[];
  givenAnswers: GivenAnswers;
}

interface GivenAnswers {
  [title: string]: {
    questionId: ID;
  }[];
}

export default class SurveyService {
  readonly httpClient: Client;
  route: string = "/surveys";

  constructor(httpClient: Client) {
    this.httpClient = httpClient;
  }

  async getSurveys(): Promise<SurveyInformation[]> {
    const result = await this.httpClient.get(this.route);
    return result.map((survey: any) => survey);
  }

  async getSurvey(id: ID): Promise<Survey> {
    const result = await this.httpClient.get("/surveys/" + id);
    return new Survey({...result.survey, meta: result.meta});
  }

  async getSurveyWithToken(id: ID, token: string): Promise<Survey> {
    const result = await this.httpClient.get(`/surveys/${id}?token=${token}`);
    return new Survey({ ...result.survey, meta: result.meta });
  }

  async getSurveyInformation(id: ID): Promise<SurveyAdminInformation> {
    const result = await this.httpClient.get(`/surveys/${id}/info`);
    return new SurveyAdminInformation(result);
  }

  async createTransientSurvey(title: { title: string }): Promise<SurveyResponse> {
    return this.httpClient.post(this.route, title).then((result: SurveyResponse) => {
      return result;
    });
  }

  async updateTransientSurvey(survey: Survey): Promise<SurveyResponse> {
    return this.httpClient
      .put(`${process.env.REACT_APP_API_BASE_URL}${this.route}/${survey.id}`, survey)
      .then((body: SurveyResponse) => {
        return body;
      });
  }

  /** last stored state from back end */
  getSurveyParticipationState(surveyID: ID): Promise<ParticipationSurveyState> {
    return this.httpClient.get(`/surveys/${surveyID}/participation`);
  }

  async getSurveyParticipationList(): Promise<SurveysParticipationObject> {
    return this.httpClient
      .get("/surveys/participations")
      .then((body: SurveysParticipationResponse) => ({
        openSurveys: body.openSurveys.map((data) => new Survey(data)),
        acceptedSurveys: body.acceptedSurveys.map((data) => new Survey(data)),
        completedSurveys: body.completedSurveys.map((data) => new Survey(data)),
        declinedSurveys: body.declinedSurveys.map((data) => new Survey(data)),
        missedSurveys: body.missedSurveys.map((data) => new Survey(data)),
        waitlistedSurveys: body.waitlistedSurveys.map((data) => new Survey(data)),
      }));
  }

  async fetchSurveysByOrganization(organizationId: ID): Promise<Survey[]> {
    return this.httpClient
      .get(`/surveys/organization/${organizationId}`)
      .then((body: Survey[]) => body.map((item) => new Survey(item)));
  }

  async requestPublishOfSurvey(surveyID: ID): Promise<Survey> {
    return this.httpClient
      .post(`/surveys/${surveyID}/request-release`)
      .then((body: Survey) => new Survey(body));
  }

  async acceptPublishOfSurvey(surveyID: ID, invitationTemplateName: string): Promise<Survey> {
    return this.httpClient
      .post(`/surveys/${surveyID}/accept-survey/${invitationTemplateName}`)
      .then((body: Survey) => new Survey(body));
  }

  async denyPublishOfSurvey(surveyID: ID, reason: string): Promise<Survey> {
    return this.httpClient
      .post(`/surveys/${surveyID}/deny-survey`, reason)
      .then((body: Survey) => new Survey(body));
  }

  async publishSurvey(surveyID: ID): Promise<Survey> {
    return this.httpClient
      .post(`/surveys/${surveyID}/publish`)
      .then((body: Survey) => new Survey(body));
  }

  async deleteSurvey(surveyID: ID): Promise<void> {
    return this.httpClient.delete(`/surveys/${surveyID}`);
  }

  async acceptSurveyParticipation(surveyID: ID, answers: any): Promise<ParticipationSurveyState> {
    return this.httpClient
      .post(`/surveys/${surveyID}/participation/accept`, answers)
      .then((body: ParticipationSurveyState) => body);
  }

  async declineSurveyParticipation(surveyID: ID): Promise<ParticipationSurveyState> {
    return this.httpClient
      .post(`/surveys/${surveyID}/participation/decline`)
      .then((body: ParticipationSurveyState) => body);
  }

  async completeSurveyParticipation(surveyID: ID): Promise<ParticipationSurveyState> {
    return this.httpClient
      .post(`/surveys/${surveyID}/participation/complete`)
      .then((body: ParticipationSurveyState) => body);
  }

  async updateAnswers(
    surveyID: ID,
    updatedAnswers: GivenAnswers
  ): Promise<ParticipationSurveyState> {
    return this.httpClient
      .put(`/surveys/${surveyID}/participation/answers`, updatedAnswers)
      .then((body: ParticipationSurveyState) => body);
  }

  async fetchSurveyResults(surveyID: ID): Promise<SurveyResult> {
    return this.httpClient.post(`/surveys/${surveyID}/evaluate`);
  }

  async filterSurveyResults(surveyID: ID, filter: any): Promise<SurveyResult> {
    return this.httpClient.post(`/surveys/${surveyID}/evaluate`, filter);
  }

  async getCSVResultData(surveyID: ID, body: {}): Promise<void> {
    return this.httpClient
      .post(`/surveys/${surveyID}/raw-data`, body)
      .then(downloadCSV("quopinion-survey-results.csv"));
  }

  async getCSVPanelData(surveyID: ID, body: {}): Promise<void> {
    return this.httpClient
      .post(`/surveys/${surveyID}/panel-data`, body)
      .then(downloadCSV("quopinion-panel-information.csv"));
  }
}
