import React from "react";
import { styled } from "@material-ui/core";
import { QuopinionTheme } from "../../../constants/Theme";
import AttributesItem from "./AttributesItem";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";
import { RootState } from "../../../modules";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";
import { AnsweredAttribute } from "../../../services/Accounts";
import { Screener } from "../../../entities/Screener";
import LoadingOverlay from "../../../common/LoadingOverlay";
import CategoryTitle from "./CategoryTitle";
import { route } from ".";

const Container = styled("div")(({ theme }: { theme: QuopinionTheme }) => ({
  // paddingBottom: theme.spacing(20),
}));

const ListContainer = styled("div")(({ theme }: { theme: QuopinionTheme }) => ({
  marginBottom: theme.spacing(4),
}));

interface OwnProps {
  screenerCollection: Screener[];
}

interface DispatchProps {}

interface StateProps {
  currentLevel: number | undefined;
  screenerAnswers: AnsweredAttribute[];
  isLoading: boolean;
}

type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps & WithTranslation;

class AttributesList extends React.Component<Props> {
  render() {
    const { screenerCollection, currentLevel, t, isLoading, screenerAnswers } = this.props;
    const categories = screenerCollection;

    if (isLoading) {
      return <LoadingOverlay />;
    }
    return (
      <Container>
        <CategoryTitle>
          {t(translations.participant.screeners.level.title)} {currentLevel}
        </CategoryTitle>
        <ListContainer>
          {Object.entries(categories).map(([key, value]) => {
            const categoryAttributeIDs = value.questions.map((question) => question.attributeID);
            const answeredAttributeIDs = screenerAnswers.map((answer) => answer.attributeId);

            if (
              categoryAttributeIDs.length !==
              answeredAttributeIDs.filter((id) => categoryAttributeIDs.includes(id)).length
            ) {
              return (
                <AttributesItem
                  key={value.id}
                  type={currentLevel}
                  title={key}
                  questionLength={value.questions.length}
                  link={`${route}/${key}`}
                  completed={
                    categoryAttributeIDs.length ===
                    answeredAttributeIDs.filter((id) => categoryAttributeIDs.includes(id)).length
                  }
                />
              );
            } else {
              return null;
            }
          })}
        </ListContainer>
        <ListContainer>
          {Object.entries(categories).map(([key, value], index) => {
            return (
              index === 0 && (
                <CategoryTitle>{t(translations.participant.screeners.completed)}</CategoryTitle>
              )
            );
          })}
          {Object.entries(categories).map(([key, value]) => {
            const categoryAttributeIDs = value.questions.map((question) => question.attributeID);
            const answeredAttributeIDs = screenerAnswers.map((answer) => answer.attributeId);
            if (
              categoryAttributeIDs.length ===
              answeredAttributeIDs.filter((id) => categoryAttributeIDs.includes(id)).length
            ) {
              return (
                <div key={value.id}>
                  <AttributesItem
                    type={currentLevel}
                    title={key}
                    questionLength={value.questions.length}
                    link={`${route}/${key}`}
                    completed={
                      categoryAttributeIDs.length ===
                      answeredAttributeIDs.filter((id) => categoryAttributeIDs.includes(id)).length
                    }
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
        </ListContainer>
      </Container>
    );
  }
}

const mapStateToProps = ({ user, attribute }: RootState) => ({
  currentLevel: user.currentLevel,
  screenerAnswers: [...attribute.screenerAnswers, ...attribute.answeredDailyScreeners],
  isLoading: attribute.isLoading,
});

const mapDispatchToProps = {};

export default compose<Props, OwnProps>(
  withTranslation(),
  withRouter,
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(AttributesList);
