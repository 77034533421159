import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import PanelItem from "../../../../common/Layout/components/PanelItem";
import PanelModal from "../../../../common/Layout/components/PanelModal";
import { WithTranslation, withTranslation } from "react-i18next";
import { blueGradient, QuopinionTheme } from "../../../../constants/Theme";
import { translations } from "../../../../constants/lang/translation";
import AttributeSelect, { OptionsType } from "../../../../common/Layout/components/AttributeSelect";
import { compose } from "recompose";
import { connect } from "react-redux";
import Panel, {
  Children,
  childrenValues,
  Education,
  educationValues,
  Family,
  familyValues,
} from "../../../../entities/Panel";
import {
  actions as surveyActions,
  setPanelChildren,
  setPanelEducation,
  setPanelFamilyStatus,
} from "../../../../modules/survey";
import { RootState } from "../../../../modules";
import Questionnaire from "../../../../entities/Questionnaire";

export interface OwnProps {
  sectionAttributes: any[];
}

export interface DispatchProps {
  setPanelEducation: typeof setPanelEducation;
  setPanelFamilyStatus: typeof setPanelFamilyStatus;
  setPanelChildren: typeof setPanelChildren;
  setChildrenId: typeof surveyActions.setChildrenAttributeId;
  setEducationId: typeof surveyActions.setEducationAttributeId;
  setFamilyId: typeof surveyActions.setFamilyStatusAttributeId;
}

interface StateProps {
  panel: Panel;
  isLoading: boolean;
  staticPanelAttributes: any;
  panelConfiguration: [];
  transientQuestionnaire: Questionnaire;
}

interface OwnState {
  extendAgeOptions: boolean;
  showHouseHoldIncome: boolean;
}

type State = OwnState;

const styles = (theme: QuopinionTheme) =>
  createStyles({
    greyText: {
      color: theme.palette.grey[400],
    },
    checkBox: {
      background: blueGradient,
      borderRadius: "4px",
      color: "#fff !important",
      fontSize: "11px !important",
      marginRight: theme.spacing(4),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2.75),
      paddingRight: theme.spacing(4),
      width: 121,
      "& svg": {
        width: 22,
        height: 22,
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    householdCheckbox: {
      color: theme.palette.grey[400],
    },
    smallInput: { width: 70 },
    moreButton: {
      marginTop: theme.spacing(4),
    },
    distributionContainer: {
      marginTop: theme.spacing(4),
    },
    selectionTitle: {
      marginRight: theme.spacing(3),
      width: 50,
    },
    input: {
      width: 70,
      margin: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
      "& input": {
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
    },
  });

type Props = OwnProps & WithTranslation & DispatchProps & StateProps & WithStyles<typeof styles>;

export interface AttributeConfig {
  title: string;
  values?: any;
  options: OptionsType[];
}

export type AttributeType = "educationLevel" | "familyStatus" | "children";

class StaticAttributesCard extends React.Component<Props, State> {
  panelAttributes: { [type in AttributeType]: AttributeConfig } = {
    educationLevel: {
      title: this.props.t(translations.summary.panel.education),
      options: educationValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.educationLevel[value]),
      })),
    },
    familyStatus: {
      title: this.props.t(translations.summary.panel.familyStatus),
      options: familyValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.familyStatus[value]),
      })),
    },
    children: {
      title: this.props.t(translations.summary.panel.children),
      options: childrenValues.map((value) => ({
        value,
        label: this.props.t(translations.panel.children[value]),
      })),
    },
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      extendAgeOptions: false,
      showHouseHoldIncome: false,
    };
    props.sectionAttributes.forEach((item: any) => {
      if (item.name === "Kinder" && !this.props.panel.children.attributeId) {
        this.props.setChildrenId(item.id);
      } else if (item.name === "Bildungsstatus" && !this.props.panel.education.attributeId) {
        this.props.setEducationId(item.id);
      } else if (item.name === "Familienstand" && !this.props.panel.family.attributeId) {
        this.props.setFamilyId(item.id);
      }
    });
  }

  renderPanelItems = (item: any) => {
    const { panel, t } = this.props;

    if (item.name === "Kinder") {
      return (
        <PanelItem
          key={item.id}
          withInfoIcon={panel.children.answer && panel.children.answer.length > 1}
          orIconLabel={true}
          showLine={false}
          title={t(translations.summary.panel.children)}
          modalChildren={
            <PanelModal
              title={t(translations.summary.panel.children)}
              description={t(translations.panel.generalInfo.multiSelect)}
            />
          }
          singleRow={true}
          smallSpacing={true}
        >
          <AttributeSelect
            handleSelection={(values: string[]) =>
              this.props.setPanelChildren(values as Children[])
            }
            selectOptions={item.question.answerOptions.map((option: any) => ({
              label: option.label,
              value: option.id,
            }))}
            selectedValues={panel.children.answer}
            allowSelectAll={true}
          />
        </PanelItem>
      );
    } else if (item.name === "Bildungsstatus") {
      return (
        <PanelItem
          key={item.id}
          showLine={false}
          withInfoIcon={panel.education.answer && panel.education.answer.length > 1}
          orIconLabel={true}
          title={t(translations.panel.educationLevel.title)}
          modalChildren={
            <PanelModal
              title={t(translations.panel.educationLevel.title)}
              description={t(translations.panel.generalInfo.multiSelect)}
            />
          }
          singleRow={true}
          smallSpacing={true}
        >
          <AttributeSelect
            handleSelection={(values: string[]) =>
              this.props.setPanelEducation(values as Education[])
            }
            selectOptions={item.question.answerOptions.map((option: any) => ({
              label: option.label,
              value: option.id,
            }))}
            selectedValues={panel.education.answer}
            allowSelectAll={true}
          />
        </PanelItem>
      );
    } else if (item.name === "Familienstand") {
      return (
        <PanelItem
          key={item.id}
          withInfoIcon={panel.family.answer && panel.family.answer.length > 1}
          orIconLabel={true}
          showLine={false}
          title={item.name}
          modalChildren={
            <PanelModal
              title={t(translations.summary.panel.familyStatus)}
              description={t(translations.panel.generalInfo.multiSelect)}
            />
          }
          singleRow={true}
          smallSpacing={true}
        >
          <AttributeSelect
            handleSelection={(values: string[]) =>
              this.props.setPanelFamilyStatus(values as Family[])
            }
            selectOptions={item.question.answerOptions.map((option: any) => ({
              label: option.label,
              value: option.id,
            }))}
            selectedValues={panel.family.answer}
            allowSelectAll={true}
          />
        </PanelItem>
      );
    } else {
      return;
    }
  };

  render() {
    const { sectionAttributes } = this.props;

    return <>{sectionAttributes.map((item: any) => this.renderPanelItems(item))}</>;
  }
}

const mapStateToProps = ({ survey }: RootState) => ({
  panel: survey.panel,
  panelConfiguration: survey.panelConfiguration,
  transientQuestionnaire: survey.questionnaire,
  isLoading: survey.isLoading,
  staticPanelAttributes: survey.panelConfiguration,
});

const mapDispatchToProps = {
  setPanelFamilyStatus,
  setPanelChildren,
  setPanelEducation,
  setChildrenId: surveyActions.setChildrenAttributeId,
  setEducationId: surveyActions.setEducationAttributeId,
  setFamilyId: surveyActions.setFamilyStatusAttributeId,
};

export default compose<Props, OwnProps>(
  connect<{}, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles)
)(StaticAttributesCard);
