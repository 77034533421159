import Question from "./Question";
import ID from "./ID";

export default class AttributeQuestion extends Question {
  attributeID: ID;

  constructor(obj: any = {}) {
    super(obj);
    this.attributeID = obj.attributeID;
  }
}
