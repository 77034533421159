import * as React from "react";
import { Field, Formik } from "formik";
import Card from "../../../common/Layout/components/Card";
import { Grid, TextField, Typography } from "@material-ui/core";
import AttributeSelect, { OptionsType } from "../../../common/Layout/components/AttributeSelect";
import { translations } from "../../../constants/lang/translation";
import { compose } from "recompose";
import { RootState } from "../../../modules";
import {
  actions,
  registerResearcher,
  registerResearcherWithSurveyToken,
  TransientResearcherRegistrationObject,
} from "../../../modules/session";
import { connect } from "react-redux";
import * as Yup from "yup";
import { withTranslation, WithTranslation } from "react-i18next";
import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import FormCheckbox from "../../../common/Preview/components/FormCheckbox";
import { QuopinionTheme } from "../../../constants/Theme";
import PasswordStrength from "../../../common/PasswordStrength";
import Button from "../../../common/Layout/components/Button";
import { RouteComponentProps, withRouter } from "react-router";
import { route as RegistrationConfirmationRoute } from "../RegisterConfirmation";
import { Link } from "react-router-dom";
import { clearState } from "../../../util/localStorage";
import { route as ResearcherPrivacyRoute } from "../DataProtection/DataProtectionLong";
import { route as ResearcherTermsConditionsRoute } from "../TermsConditions";
import IndustryOptions from "../../../constants/IndustryOptions";
import { SnackbarComponent } from "../../../common/Layout/components/Snackbar";
import { route as ResearcherLoginRoute } from "../../marketintelligence/Login";
import { route as ResearcherPasswordForgottenRoute } from "../../marketintelligence/PasswordForgotten";

export const SalutationOptions: OptionsType[] = [
  { label: "Herr", value: "HERR" },
  { label: "Frau", value: "FRAU" },
];

interface State {
  showSnackBar: boolean;
  accountAlreadyExists: boolean;
}

export interface ResearcherRegistrationForm {
  emailAddress: string;
  password: string;
  firstName: string;
  lastName: string;
  researcherDetails: {
    position: string;
    salutation: any;
  };
  organizationDetails: {
    name: string;
    streetAndNumber: string;
    zipCode: string;
    city: string;
    postBox: string;
    additionalInvoiceInformation: string;
    industry: string;
  };
}

interface OwnProps {}

interface DispatchProps {
  registerResearcher: (researcherData: ResearcherRegistrationForm) => Promise<string>;
  registerResearcherWithSurveyToken: (
    researcherData: ResearcherRegistrationForm,
    surveyToken: string
  ) => Promise<string>;
  setTransientResearcherRegistrationObject: typeof actions.setTransientResearcherRegistrationObject;
  removeTransientResearcherRegistrationObject: typeof actions.removeTransientResearcherRegistrationObject;
  setRegistrationMailResearcher: (mail: string) => void;
}

interface StateProps {
  transientResearcherRegistration: TransientResearcherRegistrationObject;
  surveyToken: string | undefined;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    card: {
      padding: theme.spacing(6),
      marginTop: theme.spacing(6),
    },
    cardHeadline: {
      textTransform: "uppercase",
    },
    input: {
      width: "100%",
      marginTop: theme.spacing(4),
      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
    },
    furtherBillDataInput: {
      width: "100%",
      marginTop: theme.spacing(4),
      "& div": {
        "& textarea": {
          lineHeight: 1.6,
        },
      },
    },
    buttonLine: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(8),
    },
    select: {
      width: "100%",
      backgroundColor: "#fff",
      height: 50,
      border: "1px solid #e8e8e8",
      borderRadius: 4,
      fontSize: 16,
      marginTop: theme.spacing(4),
      color: theme.palette.grey[300],
    },
    linkText: {
      color: "inherit",
    },
    numberInput: {
      "& input": {
        "&::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
    },
    link: {
      fontWeight: 700,
      color: "#fff",
      cursor: "pointer",
    },
  });

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps;

class RegisterForm extends React.PureComponent<Props, State> {
  SalutationOptions: OptionsType[] = [
    { label: "Herr", value: "HERR" },
    { label: "Frau", value: "FRAU" },
  ];

  readonly initialValues = {
    salutation: this.props.transientResearcherRegistration
      ? {
          label:
            this.SalutationOptions &&
            this.props.transientResearcherRegistration.researcherDetails.salutation
              ? this.SalutationOptions.find(
                  (item) =>
                    item.value ===
                    this.props.transientResearcherRegistration.researcherDetails.salutation
                )!.label
              : "",
          value: this.props.transientResearcherRegistration.researcherDetails.salutation,
        }
      : { label: "", value: "" },
    firstName: this.props.transientResearcherRegistration
      ? this.props.transientResearcherRegistration.firstName
      : "",
    lastName: this.props.transientResearcherRegistration
      ? this.props.transientResearcherRegistration.lastName
      : "",
    email: this.props.transientResearcherRegistration
      ? this.props.transientResearcherRegistration.email
      : "",
    password: "",
    passwordRepeat: "",
    privacy: false,
    industry: this.props.transientResearcherRegistration
      ? {
          label:
            IndustryOptions &&
            this.props.transientResearcherRegistration.organizationDetails.industry
              ? IndustryOptions.find(
                  (item) =>
                    item.value ===
                    this.props.transientResearcherRegistration.organizationDetails.industry
                )!.label
              : "",
          value: this.props.transientResearcherRegistration.organizationDetails.industry,
        }
      : { label: "", value: "" },
    position: this.props.transientResearcherRegistration
      ? this.props.transientResearcherRegistration.researcherDetails.position
      : "",
    companyName: this.props.transientResearcherRegistration
      ? this.props.transientResearcherRegistration.organizationDetails.company
      : "",
    companyAdress: this.props.transientResearcherRegistration
      ? this.props.transientResearcherRegistration.organizationDetails.companyAdress
      : "",
    companyZip: this.props.transientResearcherRegistration
      ? this.props.transientResearcherRegistration.organizationDetails.companyZip
      : "",
    city: this.props.transientResearcherRegistration
      ? this.props.transientResearcherRegistration.organizationDetails.companyCity
      : "",
    postBox: this.props.transientResearcherRegistration
      ? this.props.transientResearcherRegistration.organizationDetails.companyPostBox
      : "",
    furtherBillData: this.props.transientResearcherRegistration
      ? this.props.transientResearcherRegistration.organizationDetails.additionalInvoiceInformation
      : "",
  };

  state = {
    showSnackBar: false,
    accountAlreadyExists: false,
  };

  componentDidMount() {
    // clear session
    clearState();
  }

  readonly validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      salutation: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().required(
          t(translations.register.personalInformation.salutationRequired)
        ),
      }),
      firstName: Yup.string()
        .min(2, t(translations.register.personalInformation.validationName))
        .required(t(translations.register.personalInformation.fornameRequired)),
      lastName: Yup.string()
        .min(2, t(translations.register.personalInformation.validationName))
        .required(t(translations.register.personalInformation.surnameRequired)),
      email: Yup.string()
        .trim()
        .email(t(translations.validation.login.emailNotValid))
        .matches(/^[\w]*[^áéíóúäöü]*$/, {
          message: "Die Eingabe darf nicht aus Zeichen wie: á,é,í,ó,ú,ä,ö,ü bestehen.",
        }) //regex adapted from regex above
        .required(t(translations.validation.login.emailRequired)),
      password: Yup.string()
        .min(8, t(translations.validation.login.passwordMinLength))
        .required(t(translations.validation.login.passwordRequired)),
      passwordRepeat: Yup.string()
        .oneOf(
          [Yup.ref("password"), ""],
          t(translations.register.loginDetails.passwordRepeatRequired)
        )
        .required(t(translations.register.loginDetails.passwordRepeatRequired)),
      privacy: Yup.mixed()
        .oneOf([true], t(translations.register.loginDetails.privacy.validation))
        .required(t(translations.register.loginDetails.privacy.validation)),
      industry: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().required(t(translations.register.personalInformation.industryRequired)),
      }),
      position: Yup.string().required(
        t(translations.register.personalInformation.positionRequired)
      ),
      companyName: Yup.string()
        .trim()
        .min(2, t(translations.register.personalInformation.companyNameLength))
        .required(t(translations.register.personalInformation.companyNameRequired)),
      companyAdress: Yup.string()
        .min(2, t(translations.register.personalInformation.addressLength))
        .required(t(translations.register.personalInformation.addressDataRequired)),
      companyZip: Yup.string()
        .trim()
        .min(5, t(translations.register.personalInformation.postalCodeLength))
        .required(t(translations.register.personalInformation.postalCodeRequired)),
      city: Yup.string()
        .min(2, t(translations.register.personalInformation.cityLength))
        .required(t(translations.register.personalInformation.cityRequired)),
      postBox: Yup.string(),
      furtherBillData: Yup.string(),
    });
  };

  handleChange(handleChange: (field: string) => (value: string) => void, field: string) {
    return (values: string[]) => {
      if (values.length) {
        handleChange(field)(values[0]);
      }
    };
  }

  handleSetTransientResearcherObject = (values: TransientResearcherRegistrationObject | any) => {
    this.props.setTransientResearcherRegistrationObject({
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      researcherDetails: {
        salutation: values.salutation.value,
        position: values.position,
      },
      organizationDetails: {
        company: values.companyName,
        companyAdress: values.companyAdress,
        companyZip: values.companyZip,
        companyCity: values.city,
        companyPostBox: values.postBox,
        additionalInvoiceInformation: values.furtherBillData,
        industry: values.industry.value,
      },
    });
  };

  toggleSnackBar = () => {
    this.setState({
      showSnackBar: !this.state.showSnackBar,
    });
  };

  render() {
    const {
      t,
      classes,
      registerResearcher,
      registerResearcherWithSurveyToken,
      surveyToken,
      setRegistrationMailResearcher,
    } = this.props;

    return (
      <>
        {this.state.showSnackBar &&
          (this.state.accountAlreadyExists ? (
            <SnackbarComponent
              title={"Es ist ein Fehler aufgetreten"}
              message={
                <>
                  Der Account existiert bereits. Bitte gehen Sie auf die&nbsp;
                  <Link to={ResearcherLoginRoute} className={classes.link}>
                    Loginseite
                  </Link>{" "}
                  oder{" "}
                  <Link to={ResearcherPasswordForgottenRoute} className={classes.link}>
                    setzen Sie Ihr Passwort zurück
                  </Link>
                  .
                </>
              }
              type="error"
              onClose={() => {
                this.toggleSnackBar();
              }}
            />
          ) : (
            <SnackbarComponent
              title={"Es ist ein Fehler aufgetreten"}
              message={"Versuche es zu einem späteren Zeitpunkt noch einmal"}
              type="error"
              onClose={() => {
                this.toggleSnackBar();
              }}
            />
          ))}
        <Formik
          initialValues={this.initialValues}
          validationSchema={this.validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const researcherData = {
              emailAddress: values.email,
              password: values.password,
              firstName: values.firstName,
              lastName: values.lastName,
              researcherDetails: {
                salutation: values.salutation.value,
                position: values.position,
              },
              organizationDetails: {
                name: values.companyName,
                streetAndNumber: values.companyAdress,
                zipCode: values.companyZip,
                city: values.city,
                postBox: values.postBox,
                additionalInvoiceInformation: values.furtherBillData,
                industry: values.industry.value,
              },
            };

            if (surveyToken) {
              registerResearcherWithSurveyToken(researcherData, surveyToken)
                .then(() => {
                  setSubmitting(false);
                  setRegistrationMailResearcher(values.email);
                  clearState();
                  this.props.history.push(RegistrationConfirmationRoute);
                  this.props.removeTransientResearcherRegistrationObject();
                })
                .catch((err: any) => {
                  setSubmitting(false);
                  if (err.error !== undefined && err.error.response.status === 409) {
                    this.setState({ accountAlreadyExists: true, showSnackBar: true });
                  } else {
                    this.setState({ showSnackBar: true });
                  }
                });
            } else {
              registerResearcher(researcherData)
                .then(() => {
                  setSubmitting(false);
                  setRegistrationMailResearcher(values.email);
                  clearState();
                  this.props.history.push(RegistrationConfirmationRoute);
                  this.props.removeTransientResearcherRegistrationObject();
                })
                .catch((err: any) => {
                  setSubmitting(false);
                  if (
                    err.error !== undefined &&
                    err.error.response !== undefined &&
                    err.error.response.status === 409
                  ) {
                    this.setState({ accountAlreadyExists: true, showSnackBar: true });
                  } else {
                    this.setState({ showSnackBar: true });
                  }
                });
            }
          }}
        >
          {(formikProps) => {
            const {
              handleSubmit,
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              isSubmitting,
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                {/*Personal Data*/}
                <Card isRaised={true} className={classes.card}>
                  <Typography variant="body2" className={classes.cardHeadline}>
                    {t(translations.register.personalInformation.headline)}
                  </Typography>
                  <Field
                    name="salutationValue"
                    render={({ field }: any) => (
                      <AttributeSelect
                        {...field}
                        placeholder={t(translations.register.personalInformation.salutation)}
                        handleSelection={this.handleChange(
                          handleChange as (field: string) => (value: string) => void,
                          "salutation.value"
                        )}
                        selectOptions={SalutationOptions}
                        selectedValues={
                          values.salutation.value.length ? [values.salutation.value] : []
                        }
                        onBlur={() => {
                          this.handleSetTransientResearcherObject(values);
                          handleBlur("salutation.value");
                        }}
                        required={true}
                        multiple={false}
                        error={touched.salutation && Boolean(errors.salutation)}
                      />
                    )}
                  />
                  <Typography color="error">
                    {touched.salutation && errors.salutation ? `${errors.salutation.value}` : ""}
                  </Typography>
                  <TextField
                    name="firstName"
                    placeholder={t(translations.register.personalInformation.forename)}
                    className={classes.input}
                    error={touched.firstName && Boolean(errors.firstName)}
                    value={values.firstName}
                    onBlur={() => {
                      this.handleSetTransientResearcherObject(values);
                      handleBlur("firstName");
                    }}
                    onChange={handleChange("firstName")}
                    required={true}
                    helperText={errors.firstName && touched.firstName ? `${errors.firstName}` : ""}
                  />
                  <TextField
                    name="lastName"
                    placeholder={t(translations.register.personalInformation.surname)}
                    className={classes.input}
                    error={touched.lastName && Boolean(errors.lastName)}
                    value={values.lastName}
                    onBlur={() => {
                      this.handleSetTransientResearcherObject(values);
                      handleBlur("lastName");
                    }}
                    onChange={handleChange("lastName")}
                    required={true}
                    helperText={errors.lastName && touched.lastName ? `${errors.lastName}` : ""}
                  />
                  <Field
                    name="industryValue"
                    render={({ field }: any, form: any) => (
                      <AttributeSelect
                        {...field}
                        {...form}
                        multiple={false}
                        placeholder={t(translations.register.personalInformation.industry)}
                        handleSelection={this.handleChange(
                          handleChange as (field: string) => (value: string) => void,
                          "industry.value"
                        )}
                        selectOptions={IndustryOptions}
                        selectedValues={values.industry.value.length ? [values.industry.value] : []}
                        onBlur={() => {
                          this.handleSetTransientResearcherObject(values);
                          handleBlur("industry.value");
                        }}
                        required={true}
                        error={touched.industry && Boolean(errors.industry)}
                      />
                    )}
                  />
                  <Typography color="error">
                    {touched.industry && errors.industry ? `${errors.industry.value}` : ""}
                  </Typography>
                  <TextField
                    name="position"
                    placeholder={t(translations.register.personalInformation.position)}
                    className={classes.input}
                    value={values.position}
                    onBlur={() => {
                      this.handleSetTransientResearcherObject(values);
                      handleBlur("position");
                    }}
                    onChange={handleChange("position")}
                    required={true}
                    error={touched.position && Boolean(errors.position)}
                    helperText={errors.position && touched.position ? `${errors.position}` : ""}
                  />
                </Card>
                {/* Bill Data*/}
                <Card isRaised={true} className={classes.card}>
                  <Typography variant="body2" className={classes.cardHeadline}>
                    {t(translations.summary.authSection.billData)}
                  </Typography>
                  {/*CompanyName*/}
                  <TextField
                    name={"companyName"}
                    required={false}
                    className={classes.input}
                    placeholder={t(translations.pages.contact.form.researcher.companyName)}
                    value={values.companyName}
                    onChange={handleChange("companyName")}
                    onBlur={() => {
                      this.handleSetTransientResearcherObject(values);
                      handleBlur("companyName");
                    }}
                    error={touched.companyName && Boolean(errors.companyName)}
                    helperText={
                      errors.companyName && touched.companyName ? `${errors.companyName}` : ""
                    }
                  />
                  {/* Street and HouseNr*/}
                  <TextField
                    name={"companyAdress"}
                    required={false}
                    className={classes.input}
                    placeholder={t(translations.register.personalInformation.addressWithHouseNr)}
                    value={values.companyAdress}
                    onChange={handleChange("companyAdress")}
                    onBlur={() => {
                      this.handleSetTransientResearcherObject(values);
                      handleBlur("companyAdress");
                    }}
                    error={touched.companyAdress && Boolean(errors.companyAdress)}
                    helperText={
                      errors.companyAdress && touched.companyAdress ? `${errors.companyAdress}` : ""
                    }
                  />
                  <Grid container={true} spacing={5}>
                    {/* Postal code */}
                    <Grid item={true} xs={12} sm={3}>
                      <TextField
                        name={"companyZip"}
                        required={true}
                        type="text"
                        className={`${classes.input} ${classes.numberInput}`}
                        placeholder={t(translations.register.personalInformation.postalCode)}
                        value={values.companyZip}
                        onChange={(event) => {
                          if (
                            // a valid number
                            !isNaN(Number(event.target.value)) &&
                            // and max 5 digits
                            event.target.value.length <= 5
                          ) {
                            handleChange(event);
                          }
                        }}
                        onBlur={() => {
                          this.handleSetTransientResearcherObject(values);
                          handleBlur("companyZip");
                        }}
                        error={touched.companyZip && Boolean(errors.companyZip)}
                        helperText={
                          errors.companyZip && touched.companyZip ? `${errors.companyZip}` : ""
                        }
                      />
                    </Grid>
                    {/* City */}
                    <Grid item={true} xs={12} sm={9}>
                      <TextField
                        name={"city"}
                        required={true}
                        className={classes.input}
                        placeholder={t(translations.participant.profile.edit.city)}
                        value={values.city}
                        onChange={handleChange("city")}
                        onBlur={() => {
                          this.handleSetTransientResearcherObject(values);
                          handleBlur("city");
                        }}
                        error={touched.city && Boolean(errors.city)}
                        helperText={errors.city && touched.city ? `${errors.city}` : ""}
                      />
                    </Grid>
                  </Grid>
                  {/* Postfach */}
                  <TextField
                    name={"postBox"}
                    required={true}
                    className={classes.input}
                    placeholder={t(translations.register.personalInformation.postBox)}
                    value={values.postBox}
                    onChange={handleChange("postBox")}
                    onBlur={() => {
                      this.handleSetTransientResearcherObject(values);
                      handleBlur("postBox");
                    }}
                    error={touched.postBox && Boolean(errors.postBox)}
                    helperText={errors.postBox && touched.postBox ? `${errors.postBox}` : ""}
                  />
                  {/* Textfield for further bill Data */}
                  <TextField
                    name={"furtherBillData"}
                    required={false}
                    className={classes.furtherBillDataInput}
                    placeholder={t(translations.register.personalInformation.furtherData)}
                    multiline={true}
                    rows={10}
                    value={values.furtherBillData}
                    onChange={handleChange("furtherBillData")}
                    onBlur={() => {
                      this.handleSetTransientResearcherObject(values);
                      handleBlur("furtherBillData");
                    }}
                    error={touched.furtherBillData && Boolean(errors.furtherBillData)}
                    helperText={
                      errors.furtherBillData && touched.furtherBillData
                        ? `${errors.furtherBillData}`
                        : ""
                    }
                  />
                </Card>
                {/*Login Data*/}
                <Card isRaised={true} className={classes.card}>
                  <Typography variant="body2" className={classes.cardHeadline}>
                    {t(translations.register.loginDetails.headline)}
                  </Typography>
                  <TextField
                    name="email"
                    autoComplete="username"
                    placeholder={t(translations.register.loginDetails.eMail)}
                    className={classes.input}
                    error={touched.email && Boolean(errors.email)}
                    value={values.email}
                    onBlur={() => {
                      this.handleSetTransientResearcherObject(values);
                      handleBlur("email");
                    }}
                    onChange={handleChange("email")}
                    required={true}
                    helperText={errors.email && touched.email ? `${errors.email}` : ""}
                  />
                  <TextField
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    placeholder={t(translations.login.passwordPlaceholder)}
                    className={classes.input}
                    onChange={handleChange("password")}
                    error={touched.password && Boolean(errors.password)}
                    value={values.password}
                    onBlur={() => {
                      this.handleSetTransientResearcherObject(values);
                      handleBlur("password");
                    }}
                    required={true}
                    helperText={errors.password ? `${errors.password}` : ""}
                  />
                  <TextField
                    name="passwordRepeat"
                    type="password"
                    autoComplete="new-password"
                    placeholder={t(translations.register.loginDetails.repeatPassword)}
                    className={classes.input}
                    value={values.passwordRepeat}
                    onBlur={() => {
                      this.handleSetTransientResearcherObject(values);
                      handleBlur("passwordRepeat");
                    }}
                    onChange={handleChange("passwordRepeat")}
                    required={true}
                    error={touched.passwordRepeat && Boolean(errors.passwordRepeat)}
                    helperText={
                      errors.passwordRepeat && touched.passwordRepeat
                        ? `${errors.passwordRepeat}`
                        : ""
                    }
                  />
                  <PasswordStrength password={values.password} />
                  <FormCheckbox
                    required={true}
                    label={
                      <span>
                        {t(translations.register.loginDetails.privacy.titlePart1)}
                        <Link to={ResearcherTermsConditionsRoute} className={classes.linkText}>
                          {t(translations.register.loginDetails.privacy.titleTermsConditions)}
                        </Link>
                        {t(translations.register.loginDetails.privacy.titlePart2)}
                        <Link to={ResearcherPrivacyRoute} className={classes.linkText}>
                          {t(translations.register.loginDetails.privacy.titlePrivacy)}
                        </Link>
                        {t(translations.register.loginDetails.privacy.titlePart3)}
                      </span>
                    }
                    answerValue="privacy"
                    checked={values.privacy}
                    key="data"
                    error={Boolean(errors.privacy)}
                    onChange={handleChange("privacy")}
                    onBlur={handleBlur("privacy")}
                    value={values.privacy}
                    helperText={errors.privacy && touched.privacy ? `${errors.privacy}` : ""}
                    smallLabel={true}
                  />
                </Card>
                <div className={classes.buttonLine}>
                  <Button
                    size="big"
                    color="secondary"
                    contained={true}
                    onClick={handleSubmit}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t(translations.action.registerNow)}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = ({ session, survey }: RootState) => ({
  transientResearcherRegistration: session.transientResearcherRegistration,
  surveyToken: survey.token,
});

const mapDispatchToProps = {
  registerResearcher,
  registerResearcherWithSurveyToken,
  setRegistrationMailResearcher: actions.setRegistrationMailResearcher,
  setTransientResearcherRegistrationObject: actions.setTransientResearcherRegistrationObject,
  removeTransientResearcherRegistrationObject: actions.removeTransientResearcherRegistrationObject,
};

export default compose<Props, OwnProps>(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect<{}, {}, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(RegisterForm);
