import React, { PureComponent } from "react";
import { compose } from "recompose";
import { createStyles, WithStyles, withStyles, Typography } from "@material-ui/core";
import Moment from "moment";
import { QuopinionTheme } from "../../../constants/Theme";
import Card from "../../../common/Layout/components/Card";
import { CreditEntry } from "../../../entities/User";
import { formatPrice } from "../../../entities/Survey";
import { withTranslation, WithTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";

interface OwnProps {
  credit: CreditEntry;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    card: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    wrapper: {
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      width: "-webkit-fill-available",
      flexGrow: 1,
    },
    payout: {
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 120,
      color: theme.palette.grey[400],
    },
  });

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class PaidSurvey extends PureComponent<Props> {
  generateTitle(credit: CreditEntry) {
    const { t } = this.props;
    switch (credit.type) {
      case "SURVEY_COMPLETION":
        return credit.reason;
      case "TRANSFER":
        return credit.reason || t(translations.payoutPage.creditHistory.reason.TRANSFER);
      case "PAYOUT":
        //@ts-ignore
        return t(translations.payoutPage.creditHistory.reason[credit.reason]);
    }
  }

  render() {
    const { classes, credit, t } = this.props;
    return (
      <Card isRaised={true} className={classes.card}>
        <div className={classes.wrapper}>
          <Typography variant="body1" color="primary">
            {Moment(credit.createdAt).format("DD.MM.YYYY")}
          </Typography>
          <Typography variant="body2" color="primary">
            {t(translations.payoutPage.creditHistory.type[credit.type], {
              id: credit.id.substr(0, 6),
            })}
          </Typography>
          <Typography variant="subtitle2">{this.generateTitle(credit)}</Typography>
        </div>
        <div className={classes.payout}>
          <Typography variant="h4" color="inherit">
            € &nbsp;
          </Typography>
          <Typography variant="h4" color="textPrimary">
            {formatPrice(credit.amount, "")}
          </Typography>
        </div>
      </Card>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withTranslation()
)(PaidSurvey);
