import React, {CSSProperties, PureComponent, ReactNode} from "react";
import { WithStyles, withStyles, createStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { QuopinionTheme } from "../../../constants/Theme";

interface CardProps {
  children: ReactNode;
  // card with border => false, with box-shadow => true
  isRaised?: boolean;
  // card with white background => false, with blue background => true
  dark?: boolean;
  className?: any;
  style?: CSSProperties;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      backgroundColor: (props: CardProps) => (props.dark ? theme.palette.primary.main : "#fff"),
      borderWidth: (props: CardProps) => (props.isRaised ? 0 : 1),
      borderStyle: (props: CardProps) => (props.isRaised ? "none" : "solid"),
      borderColor: (props: CardProps) => (props.isRaised ? "transparent" : theme.palette.grey[100]),
      borderRadius: 4,
      boxShadow: (props: CardProps) =>
        props.isRaised ? "0 2px 4px 0 rgba(0, 0, 0, 0.05)" : "none",
      marginBottom: theme.spacing(5),
    },
  });

type Props = CardProps & WithStyles<typeof styles>;

class Card extends PureComponent<Props> {
  render() {
    const { children, classes, className, style } = this.props;
    const cardClassNames = `${classes.root} ${className}`;
    return (
      <div className={cardClassNames} style={style}>
        {children}
      </div>
    );
  }
}

export default compose<Props, CardProps>(withStyles(styles))(Card);
