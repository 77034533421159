import {Dispatch} from "redux";
import {checkOfflineError, invalidCredentials, onlineStatus, validCredentials} from "./error";
import Services from "../services/Services";
import {RootState, ThunkResult} from ".";
import {fetchCurrentResearcher, fetchUser} from "./user";
import {clearState} from "../util/localStorage";
import {OpinionValueResponse, ResultOpinionValues} from "../services/Session";
import {action, ActionType, createAction, createAsyncAction, getType} from "typesafe-actions";
import {ResearcherRegistrationForm} from "../pages/marketintelligence/Register/RegisterForm";
import {ParticipantRegistrationForm} from "../entities/ParticipantRegistrationForm";

const SET_SESSION_TOKEN = "session/SET_SESSION_TOKEN";
const DELETE_SESSION_TOKEN = "session/DELETE_SESSION_TOKEN";
const AUTHENTICATED = "session/AUTHENTICATED_USER";
const UNAUTHENTICATED = "session/UNAUTHENTICATED_USER";
const SET_CURRENT_USER = "session/SET_CURRENT_USER";
const SET_REGISTRATION_CONFIGURATION = "session/SET_REGISTRATION_CONFIGURATION";
const SET_REFRESHED_TOKEN = "session/SET_REFRESHED_TOKEN";
const SET_POTENTIAL_OPINION_VALUES = "session/SET_POTENTIAL_OPINION_VALUES";
const FETCH_OPINION_VALUES = "session/FETCH_OPINION_VALUES";
const SELECT_OPINION_VALUES = "session/SELECT_OPINION_VALUES";

export enum UsableForQuotasStudies {
  YES = "YES",
  NO = "NO",
  ALREADY_TN = "ALREADY_TN",
}

export interface TransientRegistrationObject {
  personalData: {
    firstName: string;
    lastName: "";
    zipCode: "";
    city: "";
    countryCode: "DEU";
    birthdayYear: Date;
    birthdayMonth: Date;
    birthdayDayOfMonth: Date;
    birthdayDate: Date;
  };
  gender: {
    attributeId: string;
    answer: { questionId: string; selectedOptions: string[]; type: "choice" };
  };
  household: {
    attributeId: string;
    answer: { questionId: string; selectedOptions: string[]; type: "choice" };
  };
  income: {
    attributeId: string;
    answer: { questionId: string; selectedOptions: string[]; type: "choice" };
  };
  education: {
    attributeId: string;
    answer: { questionId: string; selectedOptions: string[]; type: "choice" };
  };
  familyStatus: {
    attributeId: string;
    answer: { questionId: string; selectedOptions: string[]; type: "choice" };
  };
  children: {
    attributeId: string;
    answer: { questionId: string; selectedOptions: string[]; type: "choice" };
  };
  howDidYouHearAboutUs: { value: string; additionalInfo: string };
  usableForQuotasStudies?: UsableForQuotasStudies;
}

export interface TransientResearcherRegistrationObject {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  researcherDetails: {
    salutation: string;
    position: string;
  };
  organizationDetails: {
    company: string;
    companyAdress: string;
    companyZip: string;
    companyCity: string;
    companyPostBox: string;
    additionalInvoiceInformation: string;
    industry: string;
  };
}

export interface SessionState {
  authenticated?: boolean;
  token?: string;
  refreshedToken?: string;
  registrationConfiguration?: ParticipantRegistrationForm;
  potentialOpinionValues?: OpinionValueResponse;
  selectedOpinionValues?: { small: number; medium: number; large: number };
  isLoading: boolean;
  isUpdating: boolean;
  registrationMail: string;
  transientRegistration?: TransientRegistrationObject;
  transientResearcherRegistration?: TransientResearcherRegistrationObject;
}

const defaultState: SessionState = {
  authenticated: false,
  token: "",
  isLoading: true,
  isUpdating: false,
  registrationMail: "",
};

export interface Action {
  type: string;
  payload?: string;
}

export const actions = {
  requestPasswordReset: createAsyncAction(
    "session/REQUEST_PASSWORD_RESET_REQUEST",
    "session/REQUEST_PASSWORD_RESET_SUCCESS",
    "session/REQUEST_PASSWORD_RESET_FAILURE"
  )<void, void, void>(),
  resetPassword: createAsyncAction(
    "session/PASSWORD_RESET_REQUEST",
    "session/PASSWORD_RESET_SUCCESS",
    "session/PASSWORD_RESET_FAILURE"
  )<void, void, void>(),
  setRegistrationMail: (registrationMail: string) =>
    action("session/SET_REGISTRATION_MAIL", {registrationMail}),
  setRegistrationMailResearcher: (registrationMail: string) =>
    action("session/SET_REGISTRATION_MAIL_RESEARCHER", {registrationMail}),
  removeRegistrationMail: () => action("session/REMOVE_REGISTRATION_MAIL"),
  removeRegistrationMailResearcher: () => action("session/REMOVE_REGISTRATION_MAIL_RESEARCHER"),
  setSessionToken: (token: string) =>
    ({
      type: SET_SESSION_TOKEN,
      payload: token,
    } as const),
  authenticatedUser: (loggedIn?: boolean) =>
    ({
      type: AUTHENTICATED,
    } as const),
  unauthenticatedUser: () =>
    ({
      type: UNAUTHENTICATED,
    } as const),
  deleteSessionToken: () =>
    ({
      type: DELETE_SESSION_TOKEN,
    } as const),
  setLoading: () =>
    ({
      type: "session/SET_LOADING_STATE",
    } as const),
  setLoadingToFalse: () =>
    ({
      type: "session/SET_LOADING_STATE_FALSE",
    } as const),
  setUser: (person: any) =>
    ({
      type: SET_CURRENT_USER,
      payload: person,
    } as const),
  setTransientRegistrationObject: (object: TransientRegistrationObject) =>
    ({
      type: "session/SET_TRANSIENT_REGESTRATION_OBJECT",
      payload: object,
    } as const),
  removeTransientRegistrationObject: () =>
    ({
      type: "session/REMOVE_TRANSIENT_REGESTRATION_OBJECT",
    } as const),
  setTransientResearcherRegistrationObject: (object: TransientResearcherRegistrationObject) =>
    ({
      type: "session/SET_TRANSIENT_RESEARCHER_REGISTRATION_OBJECT",
      payload: object,
    } as const),
  removeTransientResearcherRegistrationObject: () =>
    ({
      type: "session/REMOVE_TRANSIENT_RESEARCHER_REGISTRATION_OBJECT",
    } as const),
  setRefreshedToken: (token: any) =>
    ({
      type: SET_REFRESHED_TOKEN,
      payload: token,
    } as const),
  setRegistrationConfiguration: createAction(
    SET_REGISTRATION_CONFIGURATION
  )<ParticipantRegistrationForm>(),
  setPotentialOpinionValues: createAction(SET_POTENTIAL_OPINION_VALUES)<OpinionValueResponse>(),
  requestPotentialOpinionValues: createAction(FETCH_OPINION_VALUES)<void>(),
  selectOpinionValues: createAction(SELECT_OPINION_VALUES)<ResultOpinionValues>(),
};

export type SessionAction = ActionType<typeof actions> & Action;

export const requestSessionToken = (
  emailAddress: string,
  password: string,
  surveyToken?: string
): ThunkResult<Promise<void>> => {
  return async (dispatch) => {
    dispatch(actions.setLoading());
    try {
      clearState();

      dispatch(validCredentials());

      let result: any;

      if (surveyToken) {
        result = await Services.session.getUserSessionWithToken(
          emailAddress,
          password,
          surveyToken
        );
      } else {
        result = await Services.session.getUserSession(emailAddress, password);
      }

      dispatch(actions.setSessionToken(result));
      dispatch(fetchUser());
      return Promise.resolve();
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while requesting user token.").then(
        (offlineStatus) => {
          if (!offlineStatus && err.message === "Login: Email or Password Error") {
            dispatch(invalidCredentials());
          }
        }
      );

      dispatch(actions.setLoadingToFalse());
      return Promise.reject(err); // TODO: @Adam validate Errors
    }
  };
};

export const verifyUser = (verificationToken: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const response = await Services.session.verifyUser(verificationToken);
      return Promise.resolve(response);
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  };
};

export const unsubscribeSessionToken = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      await Services.session.deleteUserSession();
      dispatch(actions.unauthenticatedUser());
      dispatch(onlineStatus());
      dispatch(actions.deleteSessionToken());
      clearState();
      localStorage.removeItem("surveyToken");
      window.location.reload();
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while logout.");
    }
  };
};

export const unsubscribeSessionTokenAfterDelete = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      await Services.session.deleteUserSession();
      dispatch(actions.unauthenticatedUser());
      dispatch(onlineStatus());
      dispatch(actions.deleteSessionToken());
      clearState();
      localStorage.removeItem("surveyToken");
      window.location.reload();
      localStorage.setItem("deleted", "true");
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while logout.");
    }
  };
};
export const deleteSession = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.unauthenticatedUser());
      dispatch(actions.deleteSessionToken());
      clearState();
      localStorage.removeItem("surveyToken");
      window.location.reload();
    } catch (err) {
      checkOfflineError(dispatch, err, "Error while deleting session due to unauthorization.");
    }
  };
};

export const recreateUserSession = (expiredToken: string): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      const result = await Services.session.recreateUserSession(expiredToken);
      dispatch(actions.setSessionToken(result));
      dispatch(fetchUser());
    } catch (error) {
      Promise.reject(error);
    }
  };
};
//for jwt only
export const refreshUserSession = async (dispatch: Dispatch, token: string) => {
  try {
    const result = await Services.session.recreateUserSession(token);
    dispatch(actions.setSessionToken(result));
    dispatch(actions.setRefreshedToken(result));
    return token
      ? Promise.resolve(token)
      : Promise.reject({
        message: "could not refresh token",
      });
  } catch (error) {
    dispatch(actions.deleteSessionToken());
    dispatch(actions.unauthenticatedUser());
    return Promise.reject({
      message: "could not refresh token",
    });
  }
};

export const registerUser = (
  emailAddress: string,
  password: string,
  personalData: any,
  userAttributes: { [key: string]: { attributeId: string; answer: any } },
  howDidYouHearAboutUs: { value: string; additionalInfo: string },
  usableForQuotasStudies?: UsableForQuotasStudies
): ThunkResult<Promise<string>> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const {birthdayDate, ...registrationData} = personalData;
      const {session} = getState();
      const attributeAnswers = Object.entries(userAttributes)
        .map(([key, value]) => value)
        .filter((item) => Boolean(typeof item === "object"));

      let gotAttention: string;
      if (howDidYouHearAboutUs.additionalInfo !== "") {
        gotAttention = `${howDidYouHearAboutUs.value}: ${howDidYouHearAboutUs.additionalInfo}`;
      } else {
        gotAttention = `${howDidYouHearAboutUs.value}`;
      }

      const personal = {
        ...registrationData,
        birthdayYear: birthdayDate.getFullYear(),
        birthdayMonth: birthdayDate.getMonth() + 1,
        birthdayDayOfMonth: birthdayDate.getDate(),
        usableForQuotasStudies,
      };

      const response = await Services.session.registerUser(
        emailAddress,
        password,
        personal,
        session.selectedOpinionValues,
        attributeAnswers,
        gotAttention
      );
      return Promise.resolve(response.emailAddressVerificationToken);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const registerResearcher = (
  registrationData: ResearcherRegistrationForm
): ThunkResult<Promise<string>> => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await Services.session.registerResearcher(registrationData);
      return Promise.resolve(response.emailAddressVerificationToken);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const registerResearcherWithSurveyToken = (
  registrationData: ResearcherRegistrationForm,
  surveyToken: string
): ThunkResult<Promise<string>> => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await Services.session.registerResearcherWithSurveyToken(
        registrationData,
        surveyToken
      );
      // localStorage.removeItem("surveyToken");
      return Promise.resolve(response.emailAddressVerificationToken);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const requestPasswordReset = (emailAddress: string,
                                     name: string,
                                     message: string): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.requestPasswordReset.request());
      const response = await Services.session.resetPasswordRequest(emailAddress, name, message);
      console.log(response);
      dispatch(actions.requestPasswordReset.success());
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const resetPassword = (password: string, token: string): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.resetPassword.request());
      const response = await Services.session.resetPassword(password, token);
      dispatch(actions.resetPassword.success(response));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchRegisterConfiguration = () => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Services.session.fetchParticipantRegistrationConfiguration();

      dispatch(actions.setRegistrationConfiguration(result));
      return Promise.resolve(result);
    } catch (error) {
      console.warn(error);
      return Promise.reject(error);
    }
  };
};

export const fetchPotentialOpinionValues = (): ThunkResult<Promise<OpinionValueResponse>> => {
  return async (dispatch) => {
    try {
      dispatch(actions.requestPotentialOpinionValues());
      const opinionValues = await Services.session.fetchPotentialOpinionValues();

      dispatch(actions.setPotentialOpinionValues(opinionValues));
      return Promise.resolve(opinionValues);
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const requestSessionTokenWithSurveyToken = (
  email: string,
  password: string,
  surveyToken: string
): ThunkResult<any> => {
  return async (dispatch) => {
    return dispatch(requestSessionToken(email, password, surveyToken)).then(() => {
      return dispatch(fetchCurrentResearcher());
    });
  };
};

export const reducer = (
  state: SessionState = defaultState,
  action: SessionAction
): SessionState => {
  switch (action.type) {
    case "session/SET_REGISTRATION_MAIL":
      return {...state, registrationMail: action.payload as string};
    case "session/SET_REGISTRATION_MAIL_RESEARCHER":
      return {...state, registrationMail: action.payload as string};
    case "session/REMOVE_REGISTRATION_MAIL":
      return {...state, registrationMail: ""};
    case "session/SET_TRANSIENT_REGESTRATION_OBJECT":
      return {...state, transientRegistration: action.payload};
    case "session/REMOVE_TRANSIENT_REGESTRATION_OBJECT":
      return {...state};
    case "session/SET_LOADING_STATE":
      return {...state, isUpdating: true};
    case "session/SET_LOADING_STATE_FALSE":
      return {...state, isUpdating: false};
    case SET_SESSION_TOKEN:
      return {...state, token: action.payload, authenticated: true, isUpdating: false};
    case AUTHENTICATED:
      return {...state, authenticated: true};
    case UNAUTHENTICATED:
      return {...state, authenticated: false};
    case DELETE_SESSION_TOKEN:
      return {...state, token: "", authenticated: false};
    case SET_REFRESHED_TOKEN:
      return {...state, refreshedToken: action.payload};
    case SET_CURRENT_USER:
      return {...state};
    case "session/SET_TRANSIENT_RESEARCHER_REGISTRATION_OBJECT": {
      return {...state, transientResearcherRegistration: action.payload};
    }
    case "session/REMOVE_TRANSIENT_RESEARCHER_REGISTRATION_OBJECT": {
      return {...state};
    }
    case getType(actions.setRegistrationConfiguration):
      return {
        ...state,
        registrationConfiguration: action.payload.sort(
          (a, b) => a.panelOptions.registrationSort - b.panelOptions.registrationSort
        ),
      };
    case getType(actions.setPotentialOpinionValues):
      return {...state, potentialOpinionValues: action.payload, isLoading: false};
    case getType(actions.selectOpinionValues):
      return {...state, selectedOpinionValues: action.payload};
    case getType(actions.requestPotentialOpinionValues):
      return {...state, isLoading: true};
    case getType(actions.requestPasswordReset.request):
      return {...state, isLoading: true};
    case getType(actions.requestPasswordReset.success):
      return {...state, isLoading: true};
    default:
      return state;
  }
};
