import React, { PureComponent, ReactNode } from "react";
import { Typography, createStyles, WithStyles, withStyles } from "@material-ui/core";
import {
  QuopinionTheme,
  blueGradient,
  finishedBlueGradient,
  disabledBlueGradient,
} from "../../../../constants/Theme";
import { compose } from "recompose";
import Badge from "../../../../common/Layout/components/Badge";
import { Check, ArrowBack } from "../../../../assets/icon";

interface OwnProps {
  title: string;
  description: string;
  icon: ReactNode;
  badgeTitle: string;
  finished?: boolean;
  onClick: (arg1?: any) => void;
  disabled?: boolean;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    badge: {
      position: "absolute",
      top: "-16px",
      "& p": { lineHeight: 1.2 },
    },
    card: {
      borderRadius: 10,
      padding: theme.spacing(6),
      background: (props: OwnProps) =>
        props.finished
          ? finishedBlueGradient
          : props.disabled
          ? disabledBlueGradient
          : blueGradient,
      position: "relative",
      marginTop: theme.spacing(8),
      display: "flex",
      alignItems: "center",
      pointerEvents: (props: OwnProps) =>
        props.disabled ? "none" : props.finished ? "none" : "all",
      cursor: (props: OwnProps) => (props.disabled ? "none" : props.finished ? "none" : "pointer"),
    },
    description: {
      margin: `${theme.spacing(2)}px 0`,
    },
    iconWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      width: "auto",
      paddingRight: theme.spacing(4),
    },
    arrow: {
      transform: "rotate(180deg)",
      width: 25,
      height: 25,
    },
  });
type Props = OwnProps & WithStyles<typeof styles>;

class CardWithIcon extends PureComponent<Props> {
  render() {
    const { title, description, icon, classes, finished, badgeTitle, onClick } = this.props;
    return (
      <div className={classes.card} onClick={() => onClick()}>
        <Badge
          title={!finished && badgeTitle ? badgeTitle : ""}
          color={finished ? "senary" : "secondary"}
          className={classes.badge}
        >
          {finished ? <Check fill="#fff" /> : ""}
        </Badge>
        <div className={classes.iconWrapper}>{icon}</div>
        <div>
          <Typography color="textSecondary" variant="h3">
            {title}
          </Typography>
          <Typography color="textSecondary" variant="body1" className={classes.description}>
            {description}
          </Typography>
          {!finished && <ArrowBack className={classes.arrow} />}
        </div>
      </div>
    );
  }
}

export default compose<Props, OwnProps>(withStyles(styles))(CardWithIcon);
