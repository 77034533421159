import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { translations } from "../../../../../constants/lang/translation";
import QuestionSummaryItem from "./QuestionSummaryItem";
import { Link } from "react-router-dom";
import Button from "../../../../../common/Layout/components/Button";
import ModalComponent from "../../../../../common/Layout/components/Modal";
import * as Table from "../../../../../common/Layout/components/CardTable";
import Survey from "../../../../../entities/Survey";
import { route as SurveyCreatorPageRoute } from "../../";
import { QuopinionTheme } from "../../../../../constants/Theme";
import { createStyles, Tooltip, WithStyles, withStyles } from "@material-ui/core";
import ID from "../../../../../entities/ID";
import { RootState } from "../../../../../modules";
import { connect } from "react-redux";
import QuestionnairePreview from "../../../../participant/Questionnaire/QuestionnairePreview";
import { resetPreviewAnswers } from "../../../../../modules/surveyPreview";
import SurveyAnswers from "../../../../../entities/SurveyAnswers";

export const styles = (theme: QuopinionTheme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    previewButton: {
      marginRight: theme.spacing(4.5),
    },
    previewModal: {
      outline: "none",
    },
  });

interface OwnProps {
  survey: Survey;
}

interface DispatchProps {
  resetPreviewAnswers: () => void;
}

interface StateProps {
  id: ID;
  previewResponse: SurveyAnswers;
}

export interface State {
  previewOpen: boolean;
  showDesktopPreview: boolean;
}

type Props = OwnProps & DispatchProps & StateProps & WithTranslation & WithStyles<typeof styles>;

class QuestionnaireSummary extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      previewOpen: false,
      showDesktopPreview: false,
    };
  }

  togglePreview = () => {
    this.setState({
      previewOpen: !this.state.previewOpen,
    });
  };

  closePreview = () => {
    this.props.resetPreviewAnswers();
    this.togglePreview();
  };

  switchModalMode = () => {
    this.setState({
      showDesktopPreview: !this.state.showDesktopPreview,
    });
  };

  renderSurveyPreview() {
    return (
      <QuestionnairePreview
        questionnaire={this.props.survey.questionnaire}
        handleClose={this.closePreview}
        currentAnswers={this.props.previewResponse}
        showDesktopPreview={this.state.showDesktopPreview}
      />
    );
  }

  render() {
    const { t, survey, classes, id } = this.props;
    const { previewOpen } = this.state;
    return (
      <>
        {/* headline */}
        <Table.HeaderRow
          title={t(translations.questionnaire.yourQuestionnaire)}
          actionArea={
            <div style={{ display: "flex" }}>
              <Tooltip
                title={t(translations.questionnaire.toolTip.previewMobileView).toString()}
                aria-label="questionnairePreview"
              >
                <div>
                  <Button
                    contained={false}
                    size="small"
                    color="primary"
                    onClick={this.togglePreview}
                    className={classes.previewButton}
                  >
                    {t(translations.action.preview)}
                  </Button>
                </div>
              </Tooltip>

              <Link to={`${SurveyCreatorPageRoute}/questionnaire/${id}`} className={classes.link}>
                <Button contained={true} size="small" color="primary">
                  {t(translations.action.modify)}
                </Button>
              </Link>
            </div>
          }
        />
        {/* body : render each question*/}
        {survey.questionnaire.questions.map((question, index) => (
          <QuestionSummaryItem key={index} index={index} question={question} />
        ))}

        <ModalComponent
          label="question-preview"
          description="open preview for survey"
          open={previewOpen}
          onClose={this.closePreview}
          isPreview={true}
          showArrow={false}
          className={classes.previewModal}
          showDesktopPreview={this.state.showDesktopPreview}
          onSwitchModalMode={this.switchModalMode}
          allowDesktopSwitch={true}
        >
          {previewOpen && this.renderSurveyPreview()}
        </ModalComponent>
      </>
    );
  }
}

const mapStateToProps = ({ survey, surveyPreview }: RootState) => ({
  id: survey.id,
  previewResponse: surveyPreview.surveyAnswers,
});

const mapDispatchToProps = {
  resetPreviewAnswers,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(QuestionnaireSummary);
