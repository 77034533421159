import React, { PureComponent } from "react";
import { Grid, IconButton, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "recompose";
import { generateCategoryObject } from "../CategoryOverview";
import { withRouter, RouteComponentProps } from "react-router";
import Question from "../../../../entities/Question";
import {
  getIndividualAttributes,
  updateUserAttributes,
  getUserAttributes,
  fetchDailyScreener,
} from "../../../../modules/attribute";
import { connect } from "react-redux";
import { RootState } from "../../../../modules";
import QuestionContainer from "../../Questionnaire/QuestionContainer";
import { AnsweredAttribute } from "../../../../services/Accounts";
import { Screener } from "../../../../entities/Screener";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import { translations } from "../../../../constants/lang/translation";
import { SnackbarComponent } from "../../../../common/Layout/components/Snackbar";
import { QuopinionTheme } from "../../../../constants/Theme";
import { ArrowBack } from "../../../../assets/icon";

interface State {
  error?: string;
}

interface OwnProps {}

interface StateProps {
  screenerCollection: Screener[];
  isLoading: boolean;
}

interface DispatchProps {
  getIndividualAttributes: () => Promise<void>;
  updateUserAttributes: (newAttribute: AnsweredAttribute) => Promise<AnsweredAttribute>;
  getUserAttributes: () => Promise<void>;
  fetchDailyScreener: () => Promise<void>;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    contentWrapper: {
      height: "100vh",
      width: "100%",
      marginBottom: 0,
    },
    backIcon: {
      position: "fixed",
      top: 31,
      left: 4,
      padding: 0,
      width: 40,
      height: 40,
      cursor: "pointer",
      zIndex: 999,
    },
    navigation: {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(-6),
    },
  });

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps<{ category: string; questionId: string }>;

export const route: string = "/participant/attributes/:category/:questionId";

class LevelQuestion extends PureComponent<Props, State> {
  state: State = {};

  async componentDidMount() {
    if (Object.entries(this.props.screenerCollection).length === 0) {
      await this.props.getIndividualAttributes();
      await this.props.getUserAttributes();
      await this.props.fetchDailyScreener();
    }
  }

  async componentWillUnmount() {
    await this.props.getIndividualAttributes();
    await this.props.fetchDailyScreener();
  }

  getSelectedQuestion = (categoryObj: any) => {
    const selectedQuestion = categoryObj.questions.find(
      (question: Question) => question.id === this.props.match.params.questionId
    );
    return selectedQuestion;
  };

  updateAttribute = async (newAttribute: AnsweredAttribute) => {
    try {
      await this.props.updateUserAttributes(newAttribute);
      this.props.history.goBack();
    } catch (e) {
      this.setState({
        error: e.message,
      });
    }
  };

  render() {
    const { isLoading, classes, t } = this.props;
    const { error } = this.state;

    if (Object.keys(this.props.screenerCollection).length === 0) {
      return null;
    }

    const getSelectedCategoryObj = generateCategoryObject(
      this.props.screenerCollection,
      this.props.match.params.category
    );

    const selectedQuestion = this.getSelectedQuestion(getSelectedCategoryObj);

    if (!selectedQuestion) {
      return <LoadingOverlay />;
    }

    return (
      <>
        <Grid container={true} className={classes.contentWrapper}>
          {isLoading ||
            (Object.keys(this.props.screenerCollection).length === 0 && <LoadingOverlay />)}
          {error && (
            <SnackbarComponent
              title={`${t(translations.general.anErrorOccured)}`}
              message={`${t(translations.errorhandling["0"])}  (Details: '${error}')`}
              type="error"
              onClose={() => {
                this.setState({ error: undefined });
              }}
            />
          )}
          <Grid container={true} justify="space-between" className={classes.navigation}>
            <IconButton onClick={() => this.props.history.goBack()} className={classes.backIcon}>
              <ArrowBack fill={"#fff"} width={24} height={24} />
            </IconButton>
          </Grid>
          <QuestionContainer
            question={selectedQuestion}
            onNextClick={this.updateAttribute}
            currentQuestionnaireStep={1}
            totalQuestionnaireSteps={1}
            isScreener={true}
            attributeId={selectedQuestion.attributeID}
          />
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ attribute }: RootState) => ({
  screenerCollection: attribute.screenerCollection,
  isLoading: attribute.isLoading,
});

const mapDispatchToProps = {
  getIndividualAttributes,
  updateUserAttributes,
  getUserAttributes,
  fetchDailyScreener,
};

export default compose<Props, OwnProps>(
  withRouter,
  withStyles(styles),
  withTranslation(),
  connect<StateProps, any, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)
)(LevelQuestion);
