import React, { PureComponent, ChangeEvent } from "react";
import { Typography, Input, Grid } from "@material-ui/core";
import { compose } from "recompose";
import Button from "../../../common/Layout/components/Button";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { createTransientSurvey, fetchPanelConfigurationSettingIds } from "../../../modules/survey";
import { withStyles, WithStyles, createStyles } from "@material-ui/styles";
import { QuopinionTheme } from "../../../constants/Theme";
import Survey from "../../../entities/Survey";
import { actions as uiStateActions } from "../../../modules/uiState";
import ContentWrapper from "../../../common/Layout/ContentWrapper";
import Questionnaire from "../../../entities/Questionnaire";
import { WithTranslation, withTranslation } from "react-i18next";
import { translations } from "../../../constants/lang/translation";

interface OwnProps {
  handleNext(): void;
}

interface DispatchProps {
  createTransientSurvey: (title: string) => Promise<void>;
  setVisitedStep: typeof uiStateActions.setVisitedStep;
  fetchPanelConfigurationSettingIds: () => Promise<void>;
}

interface StateProps {
  transientSurvey: Survey;
  transientQuestionnaire: Questionnaire;
  visitedSteps: string[];
  title: string;
}

interface State {
  title: string;
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    root: {
      fontSize: "32px",
      width: 610,
      marginBottom: theme.spacing(14),
      marginTop: theme.spacing(4),
    },
    input: {
      color: (props: StateProps) =>
        props.title === "" ? theme.palette.grey[300] : theme.palette.primary.main,
      fontWeight: 600,
      "&:focus": {
        color: theme.palette.primary.main,
      },
    },
    underline: {
      borderBottom: "2px solid #e8e8e8",
    },
    header: {
      position: "relative",
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(10),
      paddingLeft: theme.spacing(13.25),
      paddingRight: theme.spacing(13.25),
    },
    button: {
      position: "absolute",
      bottom: theme.spacing(-20.75),
    },
  });

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles> & WithTranslation;

class StepStart extends PureComponent<Props, State> {
  state = { title: "" };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: event.target.value });
  };

  handleCreate = (
    event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const { handleNext, setVisitedStep, visitedSteps, createTransientSurvey } = this.props;
    const { title } = this.state;
    createTransientSurvey(title).then(() => {
      this.props.fetchPanelConfigurationSettingIds();
      handleNext();
      if (!visitedSteps.includes("panel")) {
        setVisitedStep("panel");
      }
    });
  };

  render() {
    const { classes, t } = this.props;
    return (
      <>
        <ContentWrapper>
          <Grid container={true} direction="column" className={classes.header}>
            <Typography variant="body1">{t(translations.headerSurvey.newSurvey)}</Typography>
            <form onSubmit={this.handleCreate}>
              <Input
                required={true}
                type="text"
                name="title"
                value={this.state.title}
                onChange={this.handleTitleChange}
                placeholder={t(translations.pages.Survey.surveyTitle)}
                classes={{ root: classes.root, input: classes.input, underline: classes.underline }}
              />
              <Button
                size="medium"
                contained={true}
                color="secondary"
                onClick={this.handleCreate}
                className={classes.button}
                disabled={!Boolean(this.state.title.length)}
              >
                {t(translations.action.save)}
              </Button>
            </form>
          </Grid>
        </ContentWrapper>
      </>
    );
  }
}

const mapStateToProps = ({ survey, uiState }: RootState, ownProps: OwnProps) => ({
  transientSurvey: survey,
  title: survey.title,
  transientQuestionnaire: survey.questionnaire,
  visitedSteps: uiState.visitedSteps,
});

const mapDispatchToProps = {
  createTransientSurvey,
  setVisitedStep: uiStateActions.setVisitedStep,
  fetchPanelConfigurationSettingIds,
};

export default compose<Props, OwnProps>(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(StepStart);
