import axios from "axios";
import { loadState, clearState } from "../util/localStorage";
import { createBrowserHistory } from "history";
import { deleteSession } from "../modules/session";

export const getSessionToken = () => (loadState() ? loadState().session : "");

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const history = createBrowserHistory();

// 2xx
export const HTTP_OK = 200;
// 4xx
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;
// 5xx
export const HTTP_INTERNAL_SERVER_ERROR = 500;

// handle error codes
const addErrorInterceptor = (client: any) =>
  client.interceptors.response.use(null, (err: any) => {
    const { pathname } = history && history.location;
    const isMarketResearch = pathname && pathname.includes("/market-research/");
    const isParticipant = pathname && pathname.includes("/participant");

    // worst case / show message and cancel request
    if (err.message === "Network Error") {
      // TODO: @Adam Show Error Notification
      return;
    }

    if (err.response && err.response.status === HTTP_UNAUTHORIZED) {
      clearState();
      deleteSession();
      const isMarketResearchSurveyCreator =
        pathname && pathname.includes("/market-research/survey-creator");
      if (!isMarketResearchSurveyCreator) {
        const target = isMarketResearch
          ? "market-research/login"
          : isParticipant
          ? "participant/login"
          : "";
        history.push(`/${target}`);
      }

      throw new Error("Unauthorized");
    }
    if (err.response && err.response.status === HTTP_FORBIDDEN) {
      const isMarketResearchSurveyCreator =
        pathname && pathname.includes("/market-research/survey-creator");
      const isOnSurveyOverviewpage = pathname && pathname.includes("/participant/surveyOverview");
      const isOnQuestionnairePage = pathname && pathname.includes("/participant/questionnaire");
      if (
        (isMarketResearch && !isMarketResearchSurveyCreator) ||
        (isParticipant && !isOnQuestionnairePage && isParticipant && !isOnSurveyOverviewpage)
      ) {
        history.push("/error/403");
        throw new Error("Forbidden");
      }
    }
    return Promise.reject(err);
  });

export const httpClient = () => {
  const { token } = getSessionToken();

  const client = axios.create({
    headers: {
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    baseURL: API_URL,
  });

  addErrorInterceptor(client);

  return client;
};
