import Client from "./Client";

export default class MailTemplateService {
  readonly httpClient: Client;
  route: string = "/mail/template";

  constructor(httpClient: Client) {
    this.httpClient = httpClient;
  }
  //endpoint allows fetching for any template, last param needs to be part of template name

  //here only survey-invitation ones
  async fetchSurveyInvitationMailTemplates(): Promise<String[]> {
    return await this.httpClient
      .get(`${this.route}/DE/templateNames/survey-invitation`)
      .then((response) => {
        return response;
      });
  }
}
